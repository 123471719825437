import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, FormArray, Validators} from '@angular/forms';
import { StreamingService } from 'src/app/services/streaming/streaming.service';
import { RxwebValidators } from '@rxweb/reactive-form-validators';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import swal from 'sweetalert2';

@Component({
  selector: 'app-kafka-settings',
  templateUrl: './kafka-settings.component.html',
  styleUrls: ['./kafka-settings.component.scss']
})

export class KafkaSettingsComponent implements OnInit {
 
  @Input() jsonForm: FormGroup;
  @Input() jsonArray;
  @Output() currentTabEvent: EventEmitter<any>= new EventEmitter();
  
  projectId: string;
  streamTable: string;
  parserProp: string;
  kafkaStreamForm: FormGroup;
  kfkConfig: any[];
  kfkUUID: string;
  streamConfig:any[]
  streamConfigForm: FormGroup;
  brokers: FormArray;
  clusters: FormArray;
  submitted:boolean = false;
  currentTab: number = 2;
  showAddBroker: boolean = true;
  showAddCluster:boolean = true;
  timestampField: object[] = [];
  expand = {
    kafkaSettings: true,
    parserSettings: true,
    advancedSettings: true
  };
  selectedTable:any;
  constructor(private fb: FormBuilder,private route: ActivatedRoute, private streamingService: StreamingService, private location: Location) { }

  ngOnInit() {
    this.projectId = this.route.snapshot.paramMap.get('projectId')
    if(!this.route.snapshot.paramMap.get('streamTableId')) {
      for (let column of this.jsonArray){
        if (column.datatype === 'timestamp' && column.isChecked && column.comments !='derived time dimension') {
          this.timestampField.push(column)
        }
      }
    }else {
      this.streamTable = this.route.snapshot.paramMap.get('streamTableId')
    }
    this.createKafkaStreamSettings()
  }
  createKafkaStreamSettings() {
    if(this.route.snapshot.paramMap.get('streamTableId')){
      this.getKfkConfig()
    } else {
      let parseProp: string = 'tsColName='+this.timestampField[0]["name"];
      this.kafkaStreamForm = this.fb.group({
        name:this.jsonForm.value.tableName,
        topic:['',Validators.required],
        timeout:['60000',Validators.required],
        parserName:['com.ustglobal.qubz.source.kafka.TimedJsonStreamParser',Validators.required],
        parserProperties:[parseProp, Validators.required],
        clusters: this.fb.array([     
        ])
      }) 
    }
  }
  createCluster() {
    if(this.route.snapshot.paramMap.get('streamTableId')) {
      return this.fb.group({
        uuid:null,
        last_modified:0,
        version:this.kfkConfig[0]["version"],
        brokers:this.fb.array([])
      })
    } else {
      return this.fb.group({
        brokers:this.fb.array([])
      })
    }

  }
  createBroker() {
    return this.fb.group({
      id:['', [Validators.required, RxwebValidators.unique()]],
      host:[''],
      port:['']
    })
  }
  addCluster() {
    this.clusters = this.kafkaStreamForm.get('clusters') as FormArray;
    this.clusters.push(this.createCluster());
    this.showAddCluster = true;
    this.showAddBroker = true;
  }
  addBroker(cluster) {
    this.brokers = cluster.get('brokers') as FormArray;
    this.brokers.push(this.createBroker());
    this.showAddCluster = true;
    this.showAddBroker = false;
  }
  saveBroker(brokerEditable,broker) {
    if (this.brokers.status === 'INVALID') {
      swal({
        type:'warning',
        title: 'The new record ID already exists',
        showCancelButton: false,
        confirmButtonText: 'OK'
      })
    } else {
      this.showAddCluster = true;
      this.showAddBroker = true;
      return !brokerEditable
    }
  }
  deleteBroker(id,cluster) {
   this.brokers = cluster.get('brokers') as FormArray;
   this.brokers.removeAt(id);
    this.showAddCluster = true;
    this.showAddBroker = true;
  }
  deleteCluster(clusterIndex) {
          swal({
      type:'warning',
      title: 'Are you sure you want to remove this cluster?',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No'
    }).then((status) => {
      if(status.value==true){
        (this.kafkaStreamForm.get("clusters") as FormArray).removeAt(clusterIndex);
         if(!this.showAddBroker ){
          this.showAddCluster = true;
          this.showAddBroker = true;
   }
      }
    }, (dismiss) => {
      if (dismiss === 'cancel') {
      }
    });
    
  }
  getCluster() {
    return <FormArray>this.kafkaStreamForm.get('brokers')
    
  }
  get kafkaStreamFormData() {
    return <FormArray>this.kafkaStreamForm.get('clusters')
  }
  updateParserProperties(event) {
    this.kafkaStreamForm.get("parserProperties").setValue('tsColName='+event)
  }
  submit() {
    let body;
    swal({
      type:'warning',
      title: 'Are you sure you want to save the streaming table and cluster info?',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No'
    }).then((status) => {
      if(status.value==true){
        if(this.jsonForm){
          for(let item of this.jsonForm.value.tableData){
            item.visible=true;
            item.name=item.colName;
          }
        }
       
        let errors: any = null;
        if(this.route.snapshot.paramMap.get('streamTableId')) {
          const params= {
            table:this.kafkaStreamForm.value.name,
            project: this.route.snapshot.paramMap.get("projectId")
          }
          this.streamingService.getStreamingConfig(params)
            .subscribe(
              (data: any[]) =>{
                this.streamConfig=data
                this.streamConfigForm = this.fb.group({
                  uuid: this.streamConfig[0]["uuid"],
                  last_modified: this.streamConfig[0]["last_modified"],
                  version: this.streamConfig[0]["version"],
                  name: this.streamConfig[0]["name"],
                  type: this.streamConfig[0]["type"]
                })
                body = {
                  kafkaConfig:JSON.stringify({
                    uuid: this.kafkaStreamForm.value.uuid,
                    last_modified: this.kafkaStreamForm.value.last_modified,
                    version: this.kafkaStreamForm.value.version, 
                    name:this.kafkaStreamForm.value.name,
                    clusters:this.kafkaStreamForm.value.clusters,
                    topic:this.kafkaStreamForm.value.topic,
                    timeout:this.kafkaStreamForm.value.timeout,
                    parserName:this.kafkaStreamForm.value.parserName,
                    timestampField:null,
                    margin:0,
                    splitRows:1000000,
                    parserProperties:this.kafkaStreamForm.value.parserProperties
                  }),
                  project: this.route.snapshot.paramMap.get("projectId"),
                  streamingConfig: JSON.stringify({
                    uuid: this.streamConfigForm.value.uuid,
                    last_modified: this.streamConfigForm.value.last_modified,
                    version: this.streamConfigForm.value.version,
                    name: this.streamConfigForm.value.name,
                    type: this.streamConfigForm.value.type
                  }),
                  tableData: JSON.stringify("")
                }
                this.streamingService.updateStreaming(body)
                  .subscribe(
                    result => {
                  
                    },
                    error => {
                      errors=error;
                      swal("Failed to save Stream",errors.error.msg, "error")
                    },
                    ()=> {
                      if(!errors) {
                        swal("Success","Stream was saved", "success").then(
                          (status)=> {
                            if(status.value == true) {
                              this.location.back();
                            }
                          }
                        )
                      }
                    }
                  )
              }
            ) 
        }else {
          body = { 
            kafkaConfig:JSON.stringify({  
              name:this.jsonForm.value.tableName,
              topic:this.kafkaStreamForm.value.topic,
              timeout:this.kafkaStreamForm.value.timeout,
              parserName:this.kafkaStreamForm.value.parserName,
              clusters:this.kafkaStreamForm.value.clusters,
              parserProperties:this.kafkaStreamForm.value.parserProperties
            }),
            project: this.route.snapshot.paramMap.get("projectId"),
            streamingConfig: JSON.stringify({
              name: this.jsonForm.value.tableName,
              type: "kafka"
            }),
            tableData: JSON.stringify({
              name: this.jsonForm.value.tableName,
              source_type:"1",
              columns: this.jsonForm.value.tableData,
              database:"Default"
            }), 
          } 
        this.streamingService.saveStreaming(body).subscribe( 
          result => {
           
          },
          error => {
            errors=error;
            swal("Failed to save Stream",errors.error.msg, "error")
          },
          ()=> {
            if(!errors) {
              swal("Success!","Stream was saved", "success").then(
                (status)=> {
                  if(status.value == true) {
                    this.location.back();
                  }
                }
              )
            }
          }
        ) 
        } 
      }
    }, (dismiss) => {
      if (dismiss === 'cancel') {
      }
    });
  }
  cancel() {
    this.location.back();
  }
  previousTab() {
    this.currentTabEvent.emit(1);
  }
  restrictNumeric(e) {
    let input;
    if (e.metaKey || e.ctrlKey) {
      return true;
    }
    if (e.which === 32) {
     return false;
    }
    if (e.which === 0) {
     return true;
    }
    if (e.which < 33) {
      return true;
    }
    input = String.fromCharCode(e.which);
    return !!/[\d\s]/.test(input);
   }
  isExpanded(section: string) {
     if (section === 'kafkaSettings') {
      this.expand.kafkaSettings = !this.expand.kafkaSettings;
     } else if ( section === 'parserSettings' ) {
      this.expand.parserSettings = !this.expand.parserSettings;
     } else if ( section === 'advancedSettings' ) {
      this.expand.advancedSettings = !this.expand.advancedSettings;
   }
  }
  inEditPage () {
    if(this.route.snapshot.paramMap.get('streamTableId') ) {
      return true
    } else {
      return false
    }
  }
  getKfkConfig() {
    this.selectedTable = JSON.parse(sessionStorage.getItem('currentTable'));
    let projectName= this.projectId.toUpperCase();
    let tableName =  this.route.snapshot.paramMap.get('streamTableId') 
    const params = {
      kafkaConfigName: this.selectedTable.dbName + '.' +tableName,
      project: projectName 
    }
    this.streamingService.getKfkConfig(params).subscribe(
      (data:any[]) => {
        this.kfkConfig = data
        this.kafkaStreamForm = this.fb.group({
          uuid:this.kfkConfig[0]["uuid"],
          last_modified: this.kfkConfig[0]["last_modified"],
          version: this.kfkConfig[0]["version"],
          name:this.kfkConfig[0]["name"],
          topic:[this.kfkConfig[0]["topic"],Validators.required],
          timeout:[this.kfkConfig[0]["timeout"],Validators.required],
          parserName:[this.kfkConfig[0]["parserName"],Validators.required],
          parserProperties:[this.kfkConfig[0]["parserProperties"], Validators.required],
		      timestampField:null,
		      margin:0,
		      splitRows:1000000,
          clusters:this.fb.array([])
        })        
        let index1=0;
        let index2=0;
        for (let cluster of this.kfkConfig[0]["clusters"]) {
          this.addCluster()
          for (let broker of cluster['brokers']) {
            this.addBroker(this.kafkaStreamForm.controls.clusters['controls'][index1])
            this.kafkaStreamForm.controls.clusters['controls'][index1]['controls']['brokers']['controls'][index2]['controls']['id']['value']=broker.id
            this.kafkaStreamForm.controls.clusters['controls'][index1]['controls']['brokers']['controls'][index2]['controls']['host']['value']=broker.host
            this.kafkaStreamForm.controls.clusters['controls'][index1]['controls']['brokers']['controls'][index2]['controls']['port']['value']=broker.port
            this.kafkaStreamForm.controls.clusters['controls'][index1]['controls']['brokers']['controls'][index2].isEditable = true
            this.showAddCluster = true;
            this.showAddBroker = true;
            index2++
          }
          index1++
          index2 =0
        }
      } 
    )
  }
}
