import { Component, OnInit, AfterViewInit,Renderer2 } from '@angular/core';
import { ProjectaccessService } from '../../../services/project-access/projectaccess.service';
import { NgForm } from "@angular/forms";
import { FormBuilder, FormGroup, FormArray, FormControl, Validators } from "@angular/forms";
import { Access } from "./access.model";
import swal from 'sweetalert2';
import * as $ from 'jquery';
import { ActivatedRoute, Router } from '@angular/router'
import { from } from 'rxjs';
import { UserManagementService } from '../../../services/users/user-management.service';
import { CommonServiceService } from '../../../services/common-services/common-service.service';
@Component({
  selector: 'app-project-access',
  templateUrl: './project-access.component.html',
  styleUrls: ['./project-access.component.scss']
})
export class ProjectAccessComponent implements OnInit,AfterViewInit{
  users: Object;
  userAccess: Object;
  access: Access;
  accessGrant: Access;
  show_element: boolean;
  show_grant_element: boolean;
  project: String;
  puid: string;
  form: FormGroup;
  permission: any;
  principal: boolean;
  radioSelected: string;
  p: number = 1;
  projectName: any;
  currentPrincipal: any;
  currentAuthority: any;
  accessEntryId;
  load;
  loggedUser;
  permissions = {
    READ: { name: 'QUERY', value: 'READ', mask: 1 },
    MANAGEMENT: { name: 'MANAGEMENT', value: 'MANAGEMENT', mask: 32 },
    OPERATION: { name: 'OPERATION', value: 'OPERATION', mask: 64 },
    ADMINISTRATION: { name: 'ADMIN', value: 'ADMINISTRATION', mask: 16 }
  };
  userData;
  qubzUserList: any;
  usernames: any;
  userList = [];
  // selectedItems = [];
  // dropdownSettings = {};
  uname;
  accessList = [
    { name: 'ADMIN', value: 'ADMINISTRATION' },
    { name: 'QUERY', value: 'READ' },
    { name: 'OPERATION', value: 'OPERATION' },
    { name: 'MANAGEMENT', value: 'MANAGEMENT' }
  ];
  // accessSettings = {};
  accesspermission = null;
  // accesseditSettings = {};
  qubzLicenseExpire:any;
  removeTabKeyListener: Function;


  constructor(private formBuilder: FormBuilder, private route: ActivatedRoute, private projectaccessService: ProjectaccessService, private userManagementService: UserManagementService, public commonServiceService: CommonServiceService, private renderer: Renderer2) {
    this.loggedUser = JSON.parse(sessionStorage.getItem("loggedUser"));
  }
  
  ngAfterViewInit() {
    $(document).ready(function(){
        var contenth = $( window ).height() - 110 ;
        var sidebarh = $( window ).height() - 111 ;
        $(".pagec").css("height", contenth );
        $(".sidebar-wrapper").css("height", sidebarh );
      });
  }
  
  checkUserExist(){
    this.userList = [];
    for (let i = 0; i < this.usernames.length; i++) {

      this.userList.push({ name: this.usernames[i], value: this.usernames[i] });
    }
    for (let [key, value] of Object.entries(this.users)) {
      let user=this.userList.find(({name}) =>value.sid.principal===name);
      let index;
      index=this.userList.indexOf(user)
  
       if(index!=-1){
        this.userList.splice(index, 1);
      }
    }
 
  }
  ngOnInit() {
    this.projectName = this.route.snapshot.paramMap.get("projectId");
    this.form = this.formBuilder.group({
      uname: new FormControl(null, [Validators.required]),
      principal: new FormControl(null),
    });
    this.puid = localStorage.getItem('puid');
    this.radioSelected = "true";
    this.listAccess();
    this.userManagementService.userList(null, null).subscribe(data => {
      this.userData = data;
      this.qubzUserList = this.userData.qubzUserList;
      this.usernames = [];
      for (let i = 0; i < this.qubzUserList.length; i++) {
        if (this.qubzUserList[i].authorities.some(item => item.authority === "ROLE_ADMIN")) {
        } else {
          this.usernames.push(this.qubzUserList[i].username);
        }
      }
     
    });

    this.accesspermission = null;
    this.qubzLicenseExpire = JSON.parse(sessionStorage.getItem("qubzLicenseExpire"));
    //this.saveLogs();
  }
  //QUBZ-3737-Background should not allow text or any action with using Tab Keyword also-7/7/22
  onShow() {
    this.removeTabKeyListener = this.renderer.listen('document', 'keydown', (event) => {
       if (event.keyCode === 9) { 
         event.preventDefault();
       }
     });
 }
 
 onHide() {
   this.removeTabKeyListener();
 }
 //QUBZ-3737-Background should not allow text or any action with using Tab Keyword also-7/7/22
  onEditBtn(user) {
    this.accessEntryId = user.id;
    this.show_element = true;
    if (user.permission.mask == this.permissions.READ.mask) {
      this.permission = 'READ';
    }
    if (user.permission.mask == this.permissions.OPERATION.mask) {
      this.permission = 'OPERATION';
    }
    if (user.permission.mask == this.permissions.MANAGEMENT.mask) {
      this.permission = 'MANAGEMENT';
    }
    if (user.permission.mask == this.permissions.ADMINISTRATION.mask) {
      this.permission = 'ADMINISTRATION';
    }

    sessionStorage.setItem('user', JSON.stringify(user));
    this.userAccess = JSON.parse(sessionStorage.getItem('user'));
    this.currentPrincipal = user.sid.principal;
    this.currentAuthority = user.sid.grantedAuthority;
  }
  OnCancel() {
    this.show_element = false;
  }
  onSubmit(form: NgForm) {
    if (form.value.type == "User") {
      this.principal = true;
    }
    if (form.value.type == "Role") {
      this.principal = false;
    }

    let access = {
      "accessEntryId": this.accessEntryId,
      "permission": this.permission
    }
    this.projectaccessService.updateProjectAccessPermission(access, "ProjectInstance", this.puid).subscribe(
      data => {
        swal("Success!", "Access granted", "success");
        this.show_element = false;
        this.listAccess();
      },
      error => {
        swal("Error!", error.error.msg, "error");
      });
  }
  onDelete(user) {
    swal(

      {

        type: 'warning',

        title: 'Are you sure you want to delete access?',
        text: 'All information associated to this access will be permanently deleted',
        showCancelButton: true, confirmButtonText: 'Yes',
        cancelButtonText: 'No'

      }

    ).then((status) => {
      if (status.value == true) {
        this.projectaccessService.revokeProjectPermission(user, "ProjectInstance", this.puid).subscribe(
          data => {
            swal("Success!", "The access has been deleted", "success");
            this.userList=[...this.userList,{ name: user.sid.principal, value: user.sid.principal }];
            this.show_element = false;
            this.listAccess();
          },
          error => {
            swal('Oops...', error.error.msg, 'error');
          });
      }
    });
  }
  onAddBtn() {
    this.show_grant_element = true;
    this.onShow();//QUBZ-3737 7/7/22
  }
  OnCancelGrant() {
    this.show_grant_element = false;
    this.form.reset();
    this.onHide();//QUBZ-3737 7/7/22
  }
  onSubmitGrant() {
    // if(this.form.value.accesspermission[0].value==undefined){
    //   this.form.value.accesspermission=this.form.value.accesspermission[0];
    // }else{
    //   this.form.value.accesspermission= this.form.value.accesspermission[0].value;
    // }
    this.accessGrant = new Access(this.form.value.uname, true, 'ADMINISTRATION');

    this.projectaccessService.createProjectAccessPermission(this.accessGrant, "ProjectInstance", this.puid).subscribe(
      data => {
        swal("Success!", "Access granted", "success");
        this.show_grant_element = false;
        this.onHide();//QUBZ-3737 7/7/22
        this.form.reset();
        this.listAccess();
      },
      error => {
        swal("Error!", error.error.msg, "error");
        this.form.reset();
      });
  }
  listAccess() {
    this.load=true;
    this.projectaccessService.getAccessList("ProjectInstance", this.puid).subscribe(
      data => {
        this.users = data;
        this.load=false;
      }
    );
  }

  saveLogs(){
    var today = new Date();
    let param={
      username:this.loggedUser.username,
      targetPage:'projects/'+this.projectName+'/project-access',
      day:today.getDay(),
      month:today.getMonth(),
      year:today.getFullYear()
    };
    this.commonServiceService.saveLogs(param).subscribe(
      data => {

      });
  }
}
