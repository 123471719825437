import { Component, OnInit , AfterViewInit } from '@angular/core';
import { DataCatalogTablesService } from '../../../services/data-catalog/data-catalog-tables.service'
import { DataCatalogService } from '../../../services/data-catalog/data-catalog.service';
import { DataRequestService } from '../../../services/data-access/data-request.service';
import swal from 'sweetalert2';
import * as $ from 'jquery';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { ProjectService } from '../../../services/project/project.service';
@Component({
  selector: 'app-cart',
  templateUrl: './cart.component.html',
  styleUrls: ['./cart.component.scss']
})
export class CartComponent implements OnInit,AfterViewInit {
  cartCount:number;
  cart=[];
  page=1;
  projects;
  project;
  justification;
  projectErr:boolean;
  justificationErr:boolean;
  clickCheck=false;
    projectList=[];
  dropdownSettings = {
    singleSelection: true,
    idField: 'value',
    textField: 'name',
    selectAllText: 'Select All',
    unSelectAllText: 'UnSelect All',
    itemsShowLimit: 2,
    allowSearchFilter: true
  }; 
  constructor(private projectService: ProjectService,private dataCatalogService:DataCatalogService,private dataCatalogTablesService:DataCatalogTablesService,private router:Router,private route: ActivatedRoute,private dataRequestService:DataRequestService) { 
    
    if(this.dataCatalogService.project){
    this.project=this.dataCatalogService.project;
    let prj = this.dataCatalogService.project.split(".",2);
    this.project = prj;
    }
    this.justification=this.dataCatalogService.justification;
  }

  ngAfterViewInit() {
    $(document).ready(function(){
          var contenth = $( window ).height() - 110 ;
          var sidebarh = $( window ).height() - 108 ;
          $(".pagec").css("height", contenth );
          $(".sidebar-wrapper").css("height", sidebarh );
        });
    }
    
  ngOnInit() {
    this.cartCount=this.dataCatalogService.cartCount;
    this.cart=this.dataCatalogService.cart;
    this.projectService.listReadable().subscribe(
      data => {
         this.projects = data;
         this.projectList=[];
         for (let i = 0; i < this.projects.length; i++) {
         this.projectList.push({name:this.projects[i].name,value:this.projects[i].name+'.'+this.projects[i].uuid});
         }
      }
  );
    // this.dataCatalogService.getProject().subscribe(
    //   data=>{
       
    //     this.projects=data;
    //     this.projectList=[];
    //     for (let i = 0; i < this.projects.length; i++) {
    //     this.projectList.push({name:this.projects[i].name,value:this.projects[i].name+'.'+this.projects[i].uuid});
    //     }
    //     });
  }
  removeItem(item){
    swal(
      { 
      type:'warning', 
      title: 'Are you sure you want to delete column '+item.columnName+' from cart?', 
      showCancelButton: true, confirmButtonText: 'Yes', 
      cancelButtonText: 'No'
      } 
      ).then((status) => {
      if(status.value==true){
        let index=this.cart.indexOf(item);
        this.cart.splice(index,1);
        this.dataCatalogService.cart=this.cart;
        this.dataCatalogService.cartCount--;
      }
      }, (dismiss) => {
      if (dismiss === 'cancel') {
      }
      });

  }
  removeAllItem(){
    swal(
      { 
      type:'warning', 
      title: 'Are you sure you want to remove all items from cart?', 
      showCancelButton: true, confirmButtonText: 'Yes', 
      cancelButtonText: 'No'
      } 
      ).then((status) => {
      if(status.value==true){
        this.cart=[];
        this.clearDetails();
        this.dataCatalogService.cart=[];
        this.dataCatalogService.cartCount=0;
      }
      }, (dismiss) => {
      if (dismiss === 'cancel') {
      }
      });

  }
  saveDetails(){
    this.dataCatalogService.project=this.project[0].value;
    this.dataCatalogService.justification=this.justification;
  }
  clearDetails(){
    this.dataCatalogService.project='';
    this.dataCatalogService.justification='';
  }
  confirmRequest(){
    if(!this.project){
      this.projectErr=true;
      return;
    }
    if(!this.justification){
      this.justificationErr=true;
      return;
    }
    this.clickCheck=true;
    let confrimRequest=[];
    for (let cartItem of this.cart)
    {
        var obj = {
            "columnId": cartItem.columnId,
            "columnName": cartItem.columnName,
            "databaseId": cartItem.databaseId,
            "databaseName": cartItem.databaseName,
            "tableId": cartItem.tableId,
            "tableName": cartItem.tableName
        };
        confrimRequest.push(obj);
    }
    let user=JSON.parse(sessionStorage.getItem("loggedUser"));
    let pro=this.project[0].value.split('.',2);
    let request = {
      "DataRequestDetails":confrimRequest,
      "projectId": pro[1],
      "projectName": pro[0],
      "reason": this.justification,
      "requestorId": user.username,
      "requestorName": user.displayName
    }
    this.dataRequestService.saveRequest(request).subscribe(
      data=>{
        swal('Success!', 'Your request has been submitted', 'success');
        this.clearDetails();
        this.cart=[];
        this.dataCatalogService.cart=[];
        this.dataCatalogService.cartCount=0;
        this.backToCatalog();
      }, (error) => {
        swal("Failed",error.error.msg, "error");
      });
    
  }
  backToCatalog(){
    this.router.navigate(['/data-catalog/catalog-dash-board']);
  }

  /* remove the nbsp */
  /* ngAfterViewInit() {
    function myTrim(x) {
      return x.replace(/&nbsp;/g, ' ');
    }

    setTimeout(() =>{ 
      var htmlc = document.getElementById("removenbsp").innerHTML;
      var str = myTrim(htmlc);
      document.getElementById("removenbsp").innerHTML = str;  
    }, 200); 
  }  */

}

