import { Component, OnInit } from '@angular/core';
import { DataCatalogService } from '../../../services/data-catalog/data-catalog.service';
import { Router } from '@angular/router';
import swal from 'sweetalert2';
import { Config } from '../../../config';
import { DataRequestService } from '../../../services/data-access/data-request.service';
import { file } from '@rxweb/reactive-form-validators';
@Component({
  selector: 'app-dash-board',
  templateUrl: './dash-board.component.html',
  styleUrls: ['./dash-board.component.scss']
})
export class DashBoardComponent implements OnInit {
  catalogs:any;
  catalogDetails:any;
  searchType:any;
  search_key:any;
  search_filter_key:any;
  page=1;
  pageNumber:number;
  dataList;
  contentList:any;
  pending:number;
  message:string;
  public imagePath;
  imgURL: any;
  icon: any;
  imageData;
  showModal;
  imageName:string;
  selectedCatalog;
  userData;
  img;
  configUrl=Config.url;
  imageIco=null;
  load: boolean;
  constructor(private dataCatalogService:DataCatalogService,private router:Router,private dataRequestService: DataRequestService,) { }

  ngOnInit() {
    this.getCatalogs(1);
    this.search_filter_key='Filter By Name';
    let param={
      page: 1,
      size:'',
      isCompleted:"false",
      projectId:''
    }
    this.dataRequestService.getPendingRequest(1,false).subscribe(data => {
      this.dataList = data;
      this.contentList = this.dataList.page.content;
      this.pending=this.dataList.page.totalElements;
    });
  }
  getCatalogs(pageNumber){
    this.load=true;
    pageNumber=pageNumber-1;
    this.dataCatalogService.listAll(pageNumber,6,this.search_key,this.searchType).subscribe(
      data=>{
        let res= <any>{};
        res=data;
        this.catalogs=res.page.content;
        this.catalogDetails=res.page;
        this.load=false;
      });
  }
  openCatalogDetails(uuid)
  {
    this.router.navigate(['/manage-catalog/tables/'+uuid]);
  }
  syncDatabase(){
    this.load=true;
     this.dataCatalogService.synchronize(null).subscribe(
      data=>{
        this.load=false;
        swal("Success!",'Data Catalog has been synchronized', "success");
    }, (error) => {
     
      swal("Failed",error.error.msg, "error");
    }); 
  }
  setFilterKey(type,value){
    this.searchType=type;
    this.search_filter_key=value;
    if(type=='status')
      this.search_key=true;
    else if(type=='sourceType')
    this.search_key='hive';
    else
    this.search_key=null;
  }
  clearSearch(){
    this.search_filter_key='Filter By Name';
    this.searchType=null;
    this.search_key=null;
    this.getCatalogs(this.page);
  }
  preview(files) {
    if (files.length === 0) return;

    var mimeType = files[0].type;
    if(!(mimeType=='image/jpeg' || mimeType=='image/png' || mimeType=='image/jpg')){
      this.message = 'Only JPEG,PNG and JPG images are supported.';
      this.icon=null;
      this.imgURL=null;
      return;
    }
    else 
      this.message='';
    var reader = new FileReader();
    this.imagePath = files;
    reader.readAsDataURL(files[0]);
    reader.onload = (_event) => {
      this.imgURL = reader.result;
      this.icon = files[0];
     
    };

  }
  imageUpload() {
    const formData = new FormData();
    formData.append("file", this.icon);
    let res = this.dataCatalogService.updateDataCatalogImage(formData,this.selectedCatalog).subscribe(  
      data =>{
        this.showModal=false;
        swal("Success!", "Profile image has been updated successfully!", "success");   
        this.getCatalogs(this.page);
      },
      error => {
       
        swal("Ooops",error.error.msg, "error");   
      
      });
  }
  openModel(uuid){
    this.message='';
    this.selectedCatalog=uuid;
    this.imgURL=null;
    this.showModal=true; 
    
  }
  modelClose(){
    this.showModal=false;
    this.icon=null;
    this.imageIco=null;
  }
}
