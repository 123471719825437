import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-cube-view-step-three',
  templateUrl: './cube-view-step-three.component.html',
  styleUrls: ['./cube-view-step-three.component.scss']
})
export class CubeViewStepThreeComponent implements OnInit {
  @Input() cubeDesc;
  constructor() { }

  ngOnInit() {
  }
  getNextParameter(func){
    let html;
    html='<span>Value : '+func.parameter.value+', Type : '+func.parameter.type+'</span>';
    if(func.parameter.next_parameter){
      html=this.getNext(func.parameter.next_parameter,html);
    }
    return html;
  }
  getNext(param,html){
    html+='<li>Value : '+param.value+', Type : '+param.type+'</li>';
    if(param.next_parameter){
      this.getNext(param.next_parameter,html)
    }
    return html;
  }
}
