import { Component, OnInit , AfterViewInit} from '@angular/core';
import { ModelService } from '../../../../services/model/model.service';
import { AuthComponent } from '../../../auth/auth.component';
import { ModelDescService } from '../../../../services/model/model-desc.service';
import { TableService } from '../../../../services/table/table.service'
import { ActivatedRoute, Router } from '@angular/router';
import swal from 'sweetalert2';
import * as $ from 'jquery';
import { CommonServiceService } from '../../../../services/common-services/common-service.service';


@Component({
	selector: 'app-model-edit',
	templateUrl: './model-edit.component.html',
	styleUrls: ['./model-edit.component.scss']
})
export class ModelEditComponent implements OnInit,AfterViewInit {
	loggedUser;
	curStep: number = 1;
	projectName: string = '';
	modelName: string = '';
	model: any = {};
	modelObj: any = {};
	tablesList: any = {};
	
	constructor(private modelService: ModelService, private modelDescService: ModelDescService, private tableService: TableService ,private route: ActivatedRoute,
		 private router: Router, public commonServiceService:CommonServiceService) {
			this.loggedUser = JSON.parse(sessionStorage.getItem("loggedUser"));
		  }

	ngAfterViewInit() {
	$(document).ready(function(){
			var contenth = $( window ).height() - 110 ;
			var sidebarh = $( window ).height() - 111;
			$(".pagec").css("height", contenth );
			$(".sidebar-wrapper").css("height", sidebarh );
		});
	}
	
	ngOnInit() {
		this.projectName = this.route.snapshot.paramMap.get("projectId");
		this.modelName = this.route.snapshot.paramMap.get("modelId");
		this.getModel();
		this.getProjectTables();
		//this.saveLogs();
	}

	setStep(step: number) {
		//QUBZ-3935 - In Edit Model Tab not working 16/11/22
		if(step<=this.curStep){
			this.curStep = step;
		}
	}
	getModel() {
		this.modelService.getListByModelNameProject({modelName: this.modelName,projectName:this.projectName}).subscribe(
			data => {
				this.model = data[0];
				this.modelObj = data[0];
			}
		)
	}
	updateModelObj($event: any) {
		this.modelObj = $event;
	}
	
	getProjectTables() {
		const params = {
			ext: true,
			project: this.projectName
		};
		this.tableService.list(params).subscribe(
			data => { 
				this.tablesList = data;
				for (let table of this.tablesList) {
					table.name = table.database + "." + table.name;
				}
		}) 
	}
	next() {
		this.curStep += 1;
		if(this.curStep === 4){
			let flag=false;
			for(let dim of this.modelObj.dimensions){
				if(dim.columns.length>0){
					flag=true;
					break;
				}
			}
			if(!flag){
				swal({
					type: 'warning',
					title: ' OOPS...',
					text: 'No dimensions defined.',
					confirmButtonText: 'Ok',
				}).then((status) => {
					if (status.value == true) {
					}
				}, (dismiss) => {
					if (dismiss === 'cancel') {
					}
	
				});
				this.curStep -= 1;
				return;
			}
			
		}
		
		
	}
	prev() {
		this.curStep -= 1;
	}
	save() {
		if(this.modelObj.filter_condition === "Please input WHERE clause without typing 'WHERE'"){
			this.modelObj.filter_condition = "";
		}
		const modelRequest = {
			modelDescData: JSON.stringify(this.modelObj),
			modelName: this.modelName,
			project: this.projectName
		};
		//QUBZ-3963 - In Edit Model Should not update without Partition Column&Date Format while choose Partition table - 29/11/22
		if(this.tableService.disableUpdate===true) {
			swal({
				type:'warning',
				title: 'Partition Column cant be null.<br/> Date Format cant be null.',
				html:'',
				confirmButtonText: 'OK',
				}).then((status) => {
				if(status.value==true){
				}
				}, (dismiss) => {
				if (dismiss === 'cancel') {
				}
			});
		}else{
			swal(
				{
					type: 'warning',
					title: 'Are you sure you want to update the model?',
					text: 'Please note: If model schema is changed, all cubes of the model will be affected.',
					showCancelButton: true, confirmButtonText: 'Yes',
					cancelButtonText: 'No'
				}).then((status) => {
					let errors: any = null;
					if(status.value == true) {
						this.modelService.update(modelRequest).subscribe(
							data=>{
								 swal("Success!", "Model updated successfully", "success"); 
								   this.router.navigate(['/projects/',this.projectName,'models']);
							},(error) => {
								  swal({
									   type:'error',
									   title: ' OOPS...',
									   text:error.error.msg,
									   confirmButtonText: 'Ok',
							 }).then((status) => {
								   if(status.value==true){
									 this.router.navigate(['/projects/',this.projectName,'models']);
								 }},
								(dismiss) => {
								   if (dismiss === 'cancel') {
								   }
							 });
						   });
					}});
		}
		
		
	}

	saveLogs(){
		var today = new Date();
		let param={
		  username:this.loggedUser.username,
		  targetPage:'projects/'+this.projectName+'/models/'+this.modelName+'/edit',
		  day:today.getDay(),
		  month:today.getMonth(),
		  year:today.getFullYear()
		};
		this.commonServiceService.saveLogs(param).subscribe(
		  data => {
	
		  });
	  }
	
}
