import { Component, OnInit, Input } from '@angular/core';
import { ModelService } from '../../../../../services/model/model.service'

@Component({
  selector: 'app-cube-view-step-six',
  templateUrl: './cube-view-step-six.component.html',
  styleUrls: ['./cube-view-step-six.component.scss']
})
export class CubeViewStepSixComponent implements OnInit {
  @Input() cubeDesc;
  @Input() modelDesc;
  constructor(private modelService: ModelService) { }
  ngOnInit() {
    
            
  }
  


}
