import { Component, OnInit, AfterViewInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormGroup, FormControl, FormBuilder, Validators,FormArray } from '@angular/forms';
import { TableService } from '../../../../services/table/table.service';
import { CubesService } from '../../../../services/cubes/cubes.service';
import { SegmentsService } from '../../../../services/segment/segments.service';
import { StreamingService } from 'src/app/services/streaming/streaming.service';
import * as $ from 'jquery';
import swal from 'sweetalert2';


@Component({
  selector: 'app-segments',
  templateUrl: './segments.component.html',
  styleUrls: ['./segments.component.scss']
})
export class SegmentsComponent implements OnInit, AfterViewInit {
  projectId:any;
  cubeName:any;
  anySegmentSelected=false;
  cubeSegments=[];
  load;
  model;
  cubeDetails;
  cubesAll;
  cubeCount;
  getTotalSize: number;
  projectModels:any;
  showClone: boolean;
  checkAll=false;
  order: string = 'cube.create_time_utc';
  reverse: boolean = false;
  segementSelect: any;
  errorMsg: String;
  projectName;
  segment: any[];
  showModel: boolean = false;
  showDelete: boolean = false;
  showMerge: boolean = false;
  showSegment: boolean = true;
  lookup;
  showSubmit;
  startSegment;
  endSegment;
  segementEnd: any;
  cubeBuildForm: FormGroup;
  assign;
  selectedCube;
  page=1;



  constructor(private route: ActivatedRoute, private router: Router, private tableService:TableService, private cubesService:CubesService, private segmentsService:SegmentsService, private streamingService:StreamingService) { }

  ngOnInit() {
  this.projectId = this.route.snapshot.paramMap.get("projectId");	
  let cubeObj =JSON.parse(localStorage.getItem('selectedCube'));
  if(cubeObj){
    this.cubeName = this.route.snapshot.paramMap.get('cubeId');
    this.selectedCube=cubeObj;
    this.anySegmentSelected=false;
    this.checkAll=false;
    this.getSegments(this.cubeName,this.page)
  }else{
    this.router.navigate(['/projects/' + this.projectId + '/cubes']);
  }
  
 
  }

  ngAfterViewInit() {
    $(document).ready(function () {
      var contenth = $(window).height() - 110;
      var sidebarh = $(window).height() - 111;
      $(".pagec").css("height", contenth);
      $(".sidebar-wrapper").css("height", sidebarh);
    });

    $(document).on("click", ".segment_cb", function(){
      function checkboxactive() {
        if ($('.segment_cb').is(':checked')) {
          $(".delete_disabled").addClass("displaynone");
          $(".delete_segment.all").removeClass("disabledbtn");
          $(".merge_disabled").addClass("displaynone");
          $(".merge_segment").removeClass("disabledbtn");
          
        }      
        else{
          $(".delete_disabled").removeClass("displaynone");
          $(".delete_segment.all").addClass("disabledbtn");
          $(".merge_disabled").removeClass("displaynone");
          $(".merge_segment").addClass("disabledbtn");
        }
      }     

      setTimeout(checkboxactive,2);     

    })

    

  }


  deleteMultiSegments(){
    let segements=[];
   
    for(let segement of this.cubeSegments){
      if(segement.selected){
        segements.push(segement.uuid);
      }
    }
    swal({
      type: 'warning',
      title: "Are you sure you want to delete selected segments?",
      text: ' ',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No'
    }).then((status) => {
      if (status.value == true) {
        this.segmentsService.deleteSegments(this.selectedCube.name, segements).subscribe(
          data => {
            swal("Success!", "Segments deleted successfully", "success");
            this.cubeSegments=[];
            this.anySegmentSelected=false;
            this.getSegments(this.selectedCube.name,this.page);          
          }, (error) => {
           
            swal("Ooops", error.error.msg, "error");
            this.showClone = false;
          });
      }
    });
  }
  getList(event) {
    this.page = event;
    this.checkAll=false;
    this.getSegments(this.selectedCube.name,this.page);
  }

  getSegments(cube,page){
    page=page-1;
    this.segmentsService.getSegments(cube,page).subscribe(
        data => {
          let res:any;
          res=data;
          //QUBZ 3802-Check box issue 8/8/22
          this.cubeSegments=res.page;
        });
  }

  checkAllSegemnts(checkAll){
    for(let segement of this.cubeSegments['content']){
      segement.selected=checkAll;
    }
    this.checkAnySelected();
  }


  checkAnySelected(){
    this.anySegmentSelected=false;
    let selectedAll=true;
    for(let segement of this.cubeSegments){
      if(segement.selected){
        this.anySegmentSelected=true;
 
      }else
      selectedAll=false;
    }
    this.checkAll=selectedAll;
  }

  mergeSegments(){
    let segements=[];

    for(let segement of this.cubeSegments){
      if(segement.selected){
        segements.push(segement);
      }
    }
    if(segements.length<2){
      swal("Ooops!", "Select atleast two segments", "warning");
      return;
    }
    let obj = {
      buildType: "MERGE",
      endTime: segements[segements.length-1].date_range_end,
      startTime: segements[0].date_range_start,
    };
    this.cubesService.rebuildCube(this.selectedCube.name, obj).subscribe(
      data => {
        this.getSegments(this.selectedCube.name,this.page);
        swal("Success", "Rebuild job was submitted successfully", "success");
        this.getSegments(this.selectedCube.name,this.page);
        this.anySegmentSelected=false;
        this.checkAll=false;
      }, (error) => {

        var message = error.error.msg;

        if (message && message.indexOf("Empty cube segment found") != -1) {
          swal({
            type: 'warning',
            title: error.error.msg + ', Do you want to continue?',
            text: ' ',
            showCancelButton: true,
            confirmButtonText: 'Yes',
            cancelButtonText: 'No'
          }).then((status) => {
            if (status.value == true) {
              let obj = {
                buildType: "MERGE",
                endTime: segements[segements.length-1].date_range_end,
                startTime: segements[0].date_range_start,
                forceMergeEmptySegment: true,
              };
              this.cubesService.rebuildCube(this.selectedCube.name, obj).subscribe(
                data => {
                  swal("Success", "Rebuild job was submitted successfully", "success");
                  this.getSegments(this.selectedCube.name,this.page);
                  this.anySegmentSelected=false;
                  this.checkAll=false;
                  this.getSegments(this.selectedCube.name,this.page);
                });
            }
          }, (dismiss) => {
            if (dismiss === 'cancel') {
            }
          });
        } else {
          swal("Error", error.error.msg, "error");
        }

        this.showClone = false;
      });
  }

  setOrder(value: string) {
    if (this.order === value) {
      this.reverse = !this.reverse;
    }

    this.order = value;
  }

  
  jobsubmitctrl(cube) {
 
    this.cubeName = cube.name;
    this.errorMsg = "";
    let segment;

    for (let seg of cube.segments) {
      if (seg.name == this.segementSelect)
        segment = seg;
    }
    this.projectName = cube.project;
    this.segment = cube.segments;
    let obj = {
      buildType: "REFRESH",
      endTime: segment.date_range_end,
      startTime: segment.date_range_start
    };
    let starTime;
    if (obj.startTime >= obj.endTime) {
      this.errorMsg = "WARNING: End time should be later than the start time.";
    }
    swal({
      type: 'warning',
      title: 'Are you sure you want to start the build? ',
      text: ' ',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No'
    }).then((status) => {
      if (status.value == true) {
        this.cubesService.rebuildCube(this.cubeName, obj).subscribe(
          data => {
            swal("Success", "Rebuild job was submitted successfully", "success");
    
          }, (error) => {
    
            swal("Ooops", error.error.msg, "error");
            this.showClone = false;
          });
          }
      });
 
  }


  delSubmit(segementSelect) {

    swal({
      type: 'warning',
      title: "Are you sure you want to delete the segment?",
      text: ' ',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No'
    }).then((status) => {
      if (status.value == true) {
        this.cubesService.deleteSegment(this.cubeName, segementSelect).subscribe(
          data => {
            swal("Success!", "Segment deleted successfully", "success");
            if(this.cubeName){
              this.cubeSegments=[];
              this.getSegments(this.selectedCube.name,this.page);
            }
            this.segementSelect = '';
          }, (error) => {

            swal("Ooops", error.error.msg, "error");
            this.showClone = false;
          });
        this.showDelete = false;
      }
    });

  }

}
