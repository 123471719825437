import { Location } from "@angular/common";
import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { QueryService } from "src/app/services/query/query.service";
import swal from "sweetalert2";

@Component({
  selector: "app-view-query",
  templateUrl: "./view-query.component.html",
  styleUrls: ["./view-query.component.scss"],
})
export class ViewQueryComponent implements OnInit {
  public selectedQuery: any;
  public isLoading: boolean = false;

  constructor(
    private queryService: QueryService,
    private route: ActivatedRoute,
    private location: Location
  ) {}

  ngOnInit() {
    this.isLoading = true;
    this.route.params.subscribe((param) => {
      if (param["queryId"]) {
        const id = param["queryId"];
        this.queryService.getQueryByID(id).subscribe((res) => {
          this.selectedQuery = {
            ...res,
            duration: (res.duration % 60000) / 1000,
            endTime: res.startTime + res.duration,
          };
          this.isLoading = false;
        });
      }
    });
  }

  cancelApi() {
    this.queryService.cancelQueryStatus(this.selectedQuery.queryId).subscribe(
      (res) => {
        swal("Success!", "Query has been cancelled", "success");
        console.log(res);
      },
      (error) => {
        swal({
          type: "error",
          title: " OOPS...",
          text: error.error.msg,
          confirmButtonText: "Ok",
        }).then(
          (status) => {
            if (status.value == true) {
            }
          },
          (dismiss) => {
            if (dismiss === "cancel") {
            }
          }
        );
      }
    );
  }

  goToQueryList() {
    this.location.back();
  }
}
