import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  AfterViewInit,
  AfterContentChecked,
} from "@angular/core";
import { QueryService } from "src/app/services/query/query.service";
import { ActivatedRoute } from "@angular/router";
import { QueryComponent } from "../query.component";
import { Subscription } from "rxjs";
import swal from "sweetalert2";
import * as $ from "jquery";
import { query } from "@angular/core/src/render3";
@Component({
  selector: "app-query-history",
  templateUrl: "./query-history.component.html",
  styleUrls: ["./query-history.component.scss"],
})
export class QueryHistoryComponent
  implements OnInit, AfterViewInit, AfterContentChecked {
  @Input() mainQuery: string;
  @Input() result: string;
  @Output() resultEvent: EventEmitter<any> = new EventEmitter();
  @Output() queryEvent: EventEmitter<any> = new EventEmitter();

  error: string;
  projectId: string;
  queriesHistory: any;
  collapsed: any[] = [];
  limit: number = 50000;

  startTime;
  status;
  cachedQueries = [];
  cachedFilterQueries;
  searchText;
  isQuery: boolean = false;
  isShowquery: boolean = false;
  isShowquerydiv: boolean = false;
  subscription: Subscription;
  stoprun: boolean = true;
  showShortDesciption: boolean = true;
  order: string = "startTime";
  reverse: boolean = true;
  //@Input()searchDate;
  timeStatus = 1;

  page: number = 0;
  totalPageSize: number = 10;
  queriesHistoryCount: number;

  @Output() errorEvent: EventEmitter<any> = new EventEmitter();
  constructor(
    private queryService: QueryService,
    private route: ActivatedRoute,
    private comp: QueryComponent
  ) { }
  submitQuery(sql, project) {
    this.comp.showresult();
    this.comp.queryResult(sql, 50000, project);
  }
  removeQuery(id, index) {
    swal({
      type: "warning",
      title: "Are you sure you want to delete the query?",
      text: "All information associated to this query will be permanently deleted",
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "No",
    }).then(
      (status) => {
        if (status.value === true) {
          this.queryService.deleteQueryHistory(id).subscribe(
            (data) => {
              this.queriesHistory.splice(index, 1);
              localStorage.setItem(
                "queries_history",
                JSON.stringify(this.queriesHistory)
              );
              this.savedQueries(this.page - 1, this.totalPageSize);
              swal("Success!", "Query has been deleted", "success");
            },
            (error) => {
              swal({
                type: "error",
                title: " OOPS...",
                text: error.error.msg,
                confirmButtonText: "Ok",
              }).then(
                (status) => {
                  if (status.value == true) {
                    this.ngOnInit();
                  }
                },
                (dismiss) => {
                  if (dismiss === "cancel") {
                  }
                }
              );
            }
          );
        }
      },
      (dismiss) => {
        if (dismiss === "cancel") {
        }
      }
    );
  }

  ngAfterViewInit() {
    $(document).ready(function () {
      var contenth = $(window).height() - 110;
      var sidebarh = $(window).height() - 111;
      $(".pagec").css("height", contenth);
      $(".sidebar-wrapper").css("height", sidebarh);
    });
  }

  ngOnInit() {
    this.searchText = this.projectId;
    this.page = 1;
    this.subscription = this.queryService
      .getHistory()
      .subscribe((newHistory) => this.savedQueries(this.page - 1, this.totalPageSize));
    // this.savedQueries();
  }
  savedQueries(page, totalPageSize) {
    //this.queriesHistory=JSON.parse(localStorage.getItem('queries_history'));
    // if(this.queriesHistory){
    // this.queriesHistory.sort((a, b) => new Date(b.savedAt).getTime() - new Date(a.savedAt).getTime());
    // }
    this.projectId = this.route.snapshot.paramMap.get("projectId");
    this.setTimeFilter(this.comp.searchDate);
    const params = {
      project: this.projectId,
      timeFilter: this.timeStatus,
      page: page,
      size: totalPageSize
    };
    this.queryService.getQueryHistory(params).subscribe((data: any) => {
      this.queriesHistory = data.content;
      this.queriesHistoryCount = data.totalElements
    });
    //alert(this.queries.length);
    //  if(this.queriesHistory){
    //   this.queriesHistory = this.queriesHistory.filter(query => {
    //     return query.project.includes(this.projectId);
    //   });
    //  }
  }

  cancelQuery(index, st, id, query) {
    this.queryService.stopQuery(id, query).subscribe(
      (data) => {
        this.queriesHistory.splice(index, 1);
        localStorage.setItem(
          "queries_history",
          JSON.stringify(this.queriesHistory)
        );
        this.savedQueries(this.page - 1, this.totalPageSize);
      },
      (error) => {
        swal({
          type: "error",
          title: " OOPS...",
          text: error.error.msg,
          confirmButtonText: "Ok",
        }).then(
          (status) => {
            if (status.value == true) {
              this.ngOnInit();
            }
          },
          (dismiss) => {
            if (dismiss === "cancel") {
            }
          }
        );
      }
    );
  }

  alterDescriptionText(index) {
    this.showShortDesciption = !this.showShortDesciption;
  }

  setOrder(value: string) {
    if (this.order === value) {
      this.reverse = !this.reverse;
    }

    this.order = value;
  }

  setTimeFilter(time: string) {
    if (time === "CURRENT DAY") {
      this.timeStatus = 0;
    }

    if (time === "LAST ONE WEEK") {
      this.timeStatus = 1;
    }
    if (time === "LAST ONE MONTH") {
      this.timeStatus = 2;
    }
    if (time === "LAST SIX MONTH") {
      this.timeStatus = 3;
    }
    if (time === "ALL") {
      this.timeStatus = 4;
    }
  }

  ngAfterContentChecked() {
    if (localStorage.getItem("frmQuery")) {
      this.savedQueries(this.page - 1, this.totalPageSize);
      localStorage.setItem("frmQuery", "");
    }
  }

  copy(item) {
    document.addEventListener("copy", (e: ClipboardEvent) => {
      e.clipboardData.setData("text/plain", item);
      e.preventDefault();
      document.removeEventListener("copy", null);
    });
    document.execCommand("copy");
  }
  getQueryHistoryPage(event: any) {

    this.page = event;

    this.savedQueries(this.page - 1, this.totalPageSize);


  }
}
