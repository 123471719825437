import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  AfterViewInit,
} from "@angular/core";
import { ProjectService } from "../../services/project/project.service";
import { DataCatalogService } from "../../services/data-catalog/data-catalog.service";
import { DataRequestService } from "../../services/data-access/data-request.service";
import { DataAccessService } from "../../services/data-access/data-access.service";
import { DashboardService } from "../../services/dashboard/dashboard.service";
import { DatePipe } from "@angular/common";
import * as d3 from "d3";
import * as moment from "moment";
import { Router } from "@angular/router";
import * as $ from "jquery";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { Chart } from "chart.js";
import { CommonServiceService } from "../../services/common-services/common-service.service";

@Component({
  selector: "app-dashboard",
  templateUrl: "./dashboard.component.html",
  styleUrls: ["./dashboard.component.scss"],
  providers: [DatePipe],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DashboardComponent implements OnInit, AfterViewInit {
  title = "Dashboard";
  toggleState = false;
  toggleStateg = false;
  graphActive: boolean = false;
  projectModel: any = [];
  dataSource = 0;
  models = 0;
  cubes = 0;
  currentPage = 1;
  numPerPage = 8;
  datacatalogList: any = [];
  catalog = 0;
  status = false;
  dataRequestList: any = [];
  dataRequest = 0;
  dataAccessList: any = [];
  dataAccess = 0;
  publishedDBs = 0;
  chart;
  checkgraph;
  // line;
  active = "query";
  showValue;
  icon = "fa-lightbulb-o";
  cubeData: any = {};
  queryData: any;
  jobData: any = {};
  avgBuildTimePerMB = 0;
  dateStart = "";
  dateEnd = "";
  options;
  barChart;
  lineChart;
  polarArea;
  barchartCategory;
  barchartMetric;
  linechartCategory;
  linechartMetric;
  // graphSelected = 'BAR CHART';
  barchartDimension = "PROJECT";
  linechartDimension = "DAY";
  currentSquare = "queryCount";
  graphTitle;
  type = null;
  load = false;
  loadLineChart;
  loadBarChart;
  loadPolarChart;
  dateRangeMaxDate;
  borderColor = [
    "#3A97D3",
    "#00c0ef",
    "#5ca95c",
    "#f39c12",
    "#f56954",
    "#b5bbc8",
  ];
  color = ["#3A97D3", "#00c0ef", "#5ca95c", "#f39c12", "#f56954", "#b5bbc8"];

  newBarChart;
  newLineChart;
  // newPieChart;
  // newPolarChart;
  // newDoughnutChart;
  barChartData = {
    labels: [],
    datasets: [
      {
        label: "Query Count",
        data: [],
        backgroundColor: [],
        borderColor: [],
        borderWidth: 1,
      },
    ],
  };
  // pieChartData = JSON.parse(JSON.stringify(this.barChartData));
  // polarChartData = JSON.parse(JSON.stringify(this.barChartData));
  lineChartData = JSON.parse(JSON.stringify(this.barChartData));
  // doughnutChartData = JSON.parse(JSON.stringify(this.barChartData));
  // pieChartData = {
  //   labels: [],
  //   datasets: [{
  //       label: 'Query Count',
  //       data: [],
  //       backgroundColor: [],
  //       borderColor: [],
  //       borderWidth: 1
  //   }]
  // };
  // polarChartData = {
  //   labels: [],
  //   datasets: [{
  //       label: 'Query Count',
  //       data: [],
  //       backgroundColor: [],
  //       borderColor: [],
  //       borderWidth: 1
  //   }]
  // };
  // lineChartData = {
  //   labels: [],
  //   datasets: [{
  //       label: 'Query Count',
  //       data: [],
  //       backgroundColor: [],
  //       borderColor: [],
  //       borderWidth: 1
  //   }]
  // };
  barGraphOptions = {
    legend: {
      display: false,
    },
    maintainAspectRatio: false,
    responsive: true,
    scales: {
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
          },
        },
      ],
      xAxes: [
        {
          ticks: {
            display: false,
          },
        },
      ],
    },
    plugins: {
      datalabels: {
        anchor: "end",
        display: false,
        backgroundColor: function (context) {
          return context.dataset.backgroundColor;
        },
        //       align: 'top',
        //       formatter: Math.round,
        //       font: {
        //         weight: 'bold'
        //       }
        borderColor: "white",
        borderRadius: 25,
        borderWidth: 1,
        color: "black",
        // font: {
        //   size: 10
        // },
      },
    },
  };
  // pieGraphOptions = {
  //   legend: {
  //     display: false
  //   },
  //   maintainAspectRatio: false,
  //   responsive: true,
  //   plugins: {
  //     datalabels: {
  //       anchor: "end",
  //       display: false,
  //       backgroundColor: function (context) {
  //         return context.dataset.backgroundColor;
  //       },
  //       borderColor: "white",
  //       borderRadius: 25,
  //       borderWidth: 1,
  //       color: "blue",
  //       // font: {
  //       //   size: 10
  //       // },
  //     }
  //   }
  // }
  // polarGraphOptions = JSON.parse(JSON.stringify(this.pieGraphOptions));
  // doughnutGraphOptions = JSON.parse(JSON.stringify(this.pieGraphOptions));
  ranges: any = {
    Today: [moment(), moment()],
    Yesterday: [moment().subtract(1, "days"), moment().subtract(1, "days")],
    "Last 7 Days": [moment().subtract(6, "days"), moment()],
    "Last 30 Days": [moment().subtract(29, "days"), moment()],
    "This Month": [moment().startOf("month"), moment().endOf("month")],
    "Last Month": [
      moment().subtract(1, "month").startOf("month"),
      moment().subtract(1, "month").endOf("month"),
    ],
  };
  selected: any;
  loggedUser;
  invalidDates: moment.Moment[] = [];
  isInvalidDate = (m: moment.Moment) => {
    return this.invalidDates.some((d) => d.isSame(m, "day"));
  };
  metrics = [
    { name: "query count", value: "QUERY_COUNT" },
    { name: "Average query latency", value: "AVG_QUERY_LATENCY" },
    { name: "job count", value: "JOB_COUNT" },
    { name: "Average build time", value: "AVG_JOB_BUILD_TIME" },
  ];
  dimensions = [
    { name: "DAY", value: "DAY" },
    { name: "WEEK", value: "WEEK" },
    { name: "MONTH", value: "MONTH" },
  ];
  // chartDimensions = [
  //   { name: 'COUNT BY DURATION', value: 'LINE CHART' },
  //   { name: 'COUNT BY PROJECT', value: 'BAR CHART' },
  // { name: 'PIE CHART', value: 'PIE CHART' },
  // { name: 'POLAR AREA CHART', value: 'POLAR AREA CHART' },
  // { name: 'DOUGHNUT CHART', value: 'DOUGHNUT CHART' }
  // ];
  categories = ["QUERY", "JOB"];
  // loader;
  dimChangeFlag = false;
  constructor(
    private projectService: ProjectService,
    private dataCatalogService: DataCatalogService,
    private dataRequestService: DataRequestService,
    private dataAccessService: DataAccessService,
    private dashboardService: DashboardService,
    private cd: ChangeDetectorRef,
    public datePipe: DatePipe,
    private router: Router,
    public commonServiceService: CommonServiceService
  ) {
    //this.selected = { startDate: moment().subtract(29, 'days'), endDate: moment()};

    if (JSON.parse(localStorage.getItem("params"))) {
      let selected = JSON.parse(localStorage.getItem("params"));
      this.selected = {
        startDate: new Date(selected.startDate),
        endDate: new Date(selected.endDate),
      };
    } else {
      this.selected = {
        startDate: moment().subtract(29, "days"),
        endDate: new Date(),
      };
    }
  }

  ngAfterViewInit() {
    $(document).ready(function () {
      var contenth = $(window).height() - 110;
      var sidebarh = $(window).height() - 111;
      $(".pagec").css("height", contenth);
      $(".sidebar-wrapper").css("height", sidebarh);

      $(".db_boxec").mouseover(function () {
        $(this).find(".overview-btnc").css("display", "block");
      });

      $(".db_boxec").mouseout(function () {
        $(this).find(".overview-btnc").css("display", "none");
      });

      $(".graphboxc").mouseover(function () {
        $(this).find(".overview-btnc").css("display", "block");
      });

      $(".graphboxc").mouseout(function () {
        $(this).find(".overview-btnc").css("display", "none");
      });

      /* graph box is not availabe for user credential login */
      if ($(".graphboxc").length == 0) {
        $(".leftblocks").css("width", "100%");
        $(".db_boxec").mouseover(function () {
          $(this).find(".overview-btnc").css("display", "none");
        });
      }
    });
    Chart.plugins.unregister(ChartDataLabels);
    //this.initializeLineChart();
  }
  ngOnInit() {
    this.active = "query";
    //this.selected=this.dashboardService.selected;
    this.loggedUser = JSON.parse(sessionStorage.getItem("loggedUser"));
    this.dateRangeMaxDate = moment();
    this.loadProjectList();
    this.loadDataCataloglist();
    this.loadDataRequestList();
    this.loadDataAccessList();
    this.getManageCatalogue();

    this.initializeDates();
    this.cubeData.avgCubeExpansion = 0;
    if (this.isUserAdmin(this.loggedUser.authorities)) {
      this.load = true;
      this.getAvgCubeExpansion();
      this.getQuery(this.dateStart, this.dateEnd);
      this.getJobs(this.dateStart, this.dateEnd);
      const that = this;

      this.barChart = {
        data: [],
      };
      this.lineChart = {
        data: [],
      };
      this.queryCountChart();
    }
    this.initializeBarChart();
    //this.saveLogs();
  }
  initializeLineChart() {
    // this.line = true;
    // this.loader = true;
    var ctx = document.getElementById("lineChart");
    if (ctx)
      this.newLineChart = new Chart(ctx, {
        type: "line",
        data: this.lineChartData,
        options: {
          legend: {
            display: false,
          },
          maintainAspectRatio: false,
          responsive: true,
          scales: {
            yAxes: [
              {
                ticks: {
                  beginAtZero: true,
                },
              },
            ],
            xAxes: [
              {
                type: "time",
                time: {
                  unit: "day",
                  displayFormats: {
                    day: "ll",
                  },
                },
              },
            ],
          },
        },
      });
    // this.loader = false;
  }
  initializeBarChart() {
    // this.line = false;
    // this.loader = true;
    var ctx = document.getElementById("barChart");
    if (ctx)
      this.newBarChart = new Chart(ctx, {
        plugins: [ChartDataLabels],
        type: "bar",
        data: this.barChartData,
        options: this.barGraphOptions,
      });
    // this.loader = false;
  }
  // initializePieChart() {
  //   this.line = false;
  //   this.loader = true;
  //   var ctx = document.getElementById('barChart');
  //   this.newPieChart = new Chart(ctx, {
  //     plugins:
  //       [
  //         ChartDataLabels
  //       ],
  //     type: 'pie',
  //     data: this.pieChartData,
  //     options: this.pieGraphOptions
  //   });
  //   this.loader = false;
  // }
  // initializePolarChart() {
  //   this.line = false;
  //   this.loader = true;
  //   this.loadPolarChart = true;
  //   var ctx = document.getElementById('barChart');
  //   this.newPolarChart = new Chart(ctx, {
  //     plugins:
  //       [
  //         ChartDataLabels
  //       ],
  //     type: 'polarArea',
  //     data: this.polarChartData,
  //     options: this.polarGraphOptions
  //   });
  //   this.loader = false;
  // }
  // initializeDoughnutChart() {
  //   this.line = false;
  //   this.loader = true;
  //   var ctx = document.getElementById('barChart');
  //   this.newDoughnutChart = new Chart(ctx, {
  //     plugins:
  //       [
  //         ChartDataLabels
  //       ],
  //     type: 'doughnut',
  //     data: this.doughnutChartData,
  //     options: this.doughnutGraphOptions
  //   });
  //   this.loader = false;
  // }
  initializeDates() {
    let now = Date.now();
    let earlier = now - 86400000 * 7;

    this.dateEnd = this.datePipe.transform(this.selected.endDate, "yyyy-MM-dd");
    this.dateStart = this.datePipe.transform(
      this.selected.startDate,
      "yyyy-MM-dd"
    );
  }

  buildGraph(dataQuery, chartType) {
    const that = this;
    this.options = {
      lineChartOptions: {
        chart: {
          type: "lineChart",
          height: 300,
          margin: {
            top: 20,
            right: 55,
            bottom: 60,
            left: 55,
          },
          showYAxis: true,
          showXAxis: true,
          useInteractiveGuideline: true,
          // interpolate: 'cardinal',
          x: function (d) {
            return d.label;
          },
          y: function (d) {
            return d.value;
          },
          xAxis: {
            axisLabel: this.linechartDimension,
            axisLabelDistance: 1,
            staggerLabels: true,
            tickFormat: function (d) {
              //d = d3.time.format('%d-%m-%y')(new Date(d));
              if (d.length > 10) {
                return d.substring(0, 10) + "...";
              } else {
                var st = moment(new Date(that.selected.startDate)).valueOf();
                var et = moment(new Date(that.selected.endDate)).valueOf();
                var start = moment(st).unix();
                var end = moment(et).unix();
                if (d < start) d = start;
                if (d > end) d = end;
                return moment.unix(d).format("YYYY-MM-DD");
              }
            },
          },
          yAxis: {
            axisLabel: this.graphTitle,
            tickFormat: function (d) {
              if (d < 1000) {
                return d;
              } else {
                var prefix = d3.formatPrefix(d);
                return prefix.scale(d) + prefix.symbol;
              }
            },
            showMaxMin: false,
          },
          valueFormat: function (d) {
            return d3.format(".2f")(d);
          },

          transitionDuration: 500,
          tooltip: {
            contentGenerator: function (d) {
              // return '<table><tr><td class="legend-color-guide"><div style="background-color: '+d.color+';width:10px;margin-right:5px">&nbsp;</div></td><td class="key">'+d.data.label+' : </td><td class="value">'+d.data.value +'</td></tr></table>';
            },
          },
        },
      },
      barChartOptions: {
        chart: {
          type: "discreteBarChart",
          showValues: this.showValue,
          height: 300,
          margin: {
            top: 20,
            right: 20,
            bottom: 60,
            left: 60,
          },
          tooltip: {
            contentGenerator: function (d) {
              return (
                '<table><tr><td class="legend-color-guide"><div style="background-color: ' +
                d.color +
                ';width:10px;margin-right:5px">&nbsp;</div></td><td class="key">' +
                d.data.label +
                ' : </td><td class="value">' +
                d.data.value +
                "</td></tr></table>"
              );
            },
          },
          x: function (d) {
            return d.label;
          },
          y: function (d) {
            return d.value;
          },
          xAxis: {
            axisLabel: "Project",
            axisLabelDistance: 1,
            staggerLabels: false,
            tickFormat: function (d) {
              if (d.length > 15) {
                return d.substring(0, 15) + "...";
              } else {
                return d;
              }
            },
          },
          yAxis: {
            axisLabel: this.graphTitle,

            tickFormat: function (d) {
              if (that.active == "query" || that.active == "job") {
                if (d < 1000) {
                  return d;
                } else {
                  var prefix = d3.formatPrefix(d);
                  return prefix.scale(d) + prefix.symbol;
                }
              } else {
                if (d < 1000) {
                  return d3.format(".2f")(d);
                } else {
                  var prefix = d3.formatPrefix(d);
                  return prefix.scale(d) + prefix.symbol;
                }
              }
            },
            showMaxMin: false,
          },
          valueFormat: function (d) {
            if (that.active == "query" || that.active == "job") {
              return d;
            } else {
              return d3.format(".2f")(d);
            }
          },
          // transitionDuration: 500,
          tooltipContent: function (key, x, y, e, graph) {
            return "<h3>" + e.point.label + "</h3>" + "<p>" + y + "</p>";
          },
        },
      },
    };
    let param = {
      category: dataQuery.category,
      metric: dataQuery.metric,
      dimension: dataQuery.dimension,
      startTime: this.datePipe.transform(this.selected.startDate, "yyyy-MM-dd"),
      endTime: this.datePipe.transform(this.selected.endDate, "yyyy-MM-dd"),
    };

    if (chartType === "line") {
      this.loadLineChart = true;
      let res = [];
      this.options.lineChartOptions.chart.lineChart = {
        dispatch: {
          elementClick: function (el) {
            //  d3.selectAll('.nvtooltip').remove();
          },
        },
      };
      this.lineChart.data = [];
      this.dashboardService.getChartData(param).subscribe(
        (data) => {
          let result = [];
          this.lineChartData.labels = [];
          this.lineChartData.datasets[0].data = [];
          for (let [key, value] of Object.entries(data)) {
            res.push({ label: moment(key).format("X"), value: value });
            result.push({ label: key, value: value });
          }
          // if(res.length>5){
          //this.options.lineChartOptions.chart.xAxis.rotateLabels = -50;
          // this.options.lineChartOptions.chart.xAxis.axisLabel = '';
          // }

          res = res.sort((a, b) => a.label - b.label);

          result.sort((a, b) => moment(a.label).diff(moment(b.label)));
          result.forEach((item) => {
            this.lineChartData.labels.push(item.label);
            this.lineChartData.datasets[0].data.push(item.value);
          });
          this.lineChart.data = [{ key: dataQuery.category, values: res }];
          if (this.newLineChart) this.newLineChart.destroy();
          this.initializeLineChart();
          if (this.dimChangeFlag == true) {
            this.newLineChart.update();
          }
          this.loadLineChart = false;
          this.cd.detectChanges();
        },
        function (e) {
          this.loadBarChart = false;
          // swal('Oops...', 'Failed to load line chart.', 'error');
        }
      );
    }
    if (chartType === "bar") {
      // this.options.barChartOptions.chart.discretebar = {
      //   dispatch: {
      //     elementClick: function(el) {
      //       var project = el.data.label;
      //       project=project.toLowerCase();
      // this.loadProject(project);
      // var url = '/projects/'+project+'/dashboard';
      // location.href=url;
      //   const that=this.router;

      //   that.navigate([url]);
      //     }
      //   }
      // };
      this.loadBarChart = true;
      this.barChart.data = [];
      this.dashboardService.getChartData(param).subscribe(
        (data) => {
          this.barChartData.labels = [];
          this.barChartData.datasets[0].data = [];
          // this.pieChartData.labels = [];
          // this.pieChartData.datasets[0].data = [];
          // this.polarChartData.labels = [];
          // this.polarChartData.datasets[0].data = [];
          // this.doughnutChartData.labels = [];
          // this.doughnutChartData.datasets[0].data = [];
          let res = [];
          for (let [key, value] of Object.entries(data)) {
            res.push({ label: key, value: value });
            this.barChartData.labels.push(key);
            this.barChartData.datasets[0].data.push(value);
            // this.pieChartData.labels.push(key);
            // this.pieChartData.datasets[0].data.push(value);
            // this.polarChartData.labels.push(key);
            // this.polarChartData.datasets[0].data.push(value);
            // this.doughnutChartData.labels.push(key);
            // this.doughnutChartData.datasets[0].data.push(value);
          }
          let bgColor = [];
          let borColor = [];
          res.forEach((element, index) => {
            bgColor.push(this.color[index % this.color.length]);
            borColor.push(this.borderColor[index % this.borderColor.length]);
          });
          this.barChartData.datasets[0].backgroundColor = bgColor;
          this.barChartData.datasets[0].borderColor = borColor;
          // this.pieChartData.datasets[0].backgroundColor = bgColor;
          // this.pieChartData.datasets[0].borderColor = borColor;
          // this.polarChartData.datasets[0].backgroundColor = bgColor;
          // this.polarChartData.datasets[0].borderColor = borColor;
          // this.doughnutChartData.datasets[0].backgroundColor = bgColor;
          // this.doughnutChartData.datasets[0].borderColor = borColor;

          if (this.showValue) {
            this.barGraphOptions.plugins.datalabels.display = true;
            // this.pieGraphOptions.plugins.datalabels.display = true;
            // this.polarGraphOptions.plugins.datalabels.display = true;
            // this.doughnutGraphOptions.plugins.datalabels.display = true;
          } else {
            this.barGraphOptions.plugins.datalabels.display = false;
            // this.pieGraphOptions.plugins.datalabels.display = false;
            // this.polarGraphOptions.plugins.datalabels.display = false;
            // this.doughnutGraphOptions.plugins.datalabels.display = false;
          }
          if (this.toggleStateg)
            this.barGraphOptions.scales.xAxes[0].ticks.display = true;
          else this.barGraphOptions.scales.xAxes[0].ticks.display = false;

          // if(res.length>4){
          this.options.barChartOptions.chart.xAxis.rotateLabels = -50;
          //this.options.barChartOptions.chart.xAxis.axisLabel = '';
          // }
          this.barChart.data = [{ key: dataQuery.category, values: res }];
          this.graphChange();
          //this.newBarChart.update();
          this.loadBarChart = false;
          this.cd.detectChanges();
        },
        function (e) {
          this.loadBarChart = false;
          // swal('Oops...', 'Failed to load line chart.', 'error');
        }
      );
    }
    //this.cd.detectChanges();
  }
  graphChange() {
    // if (this.newLineChart) this.newLineChart.destroy();
    if (this.newBarChart) this.newBarChart.destroy();
    // if (this.newPieChart) this.newPieChart.destroy();
    // if (this.newPolarChart) this.newPolarChart.destroy();
    // if (this.newDoughnutChart) this.newDoughnutChart.destroy();

    // if (this.graphSelected === 'BAR CHART')
    this.initializeBarChart();
    // if (this.graphSelected === 'LINE CHART')
    // this.initializeLineChart();
    // if (this.graphSelected === 'PIE CHART')
    //   this.initializePieChart();
    // if (this.graphSelected === 'POLAR AREA CHART')
    //   this.initializePolarChart();
    // if (this.graphSelected === 'DOUGHNUT CHART')
    //   this.initializeDoughnutChart();
  }
  loadProject(project) {
    this.router.navigate(["/projects/", project, "dashboard"]);
  }
  calendarChange() {
    if (this.currentSquare == "queryCount") this.queryCountChart();
    else if (this.currentSquare == "queryAvg") this.queryAvgChart();
    else if (this.currentSquare == "jobCount") this.jobCountChart();
    else if (this.currentSquare == "jobBuildTime") this.jobBuildTimeChart();
    this.dateEnd = this.datePipe.transform(this.selected.endDate, "yyyy-MM-dd");
    this.dateStart = this.datePipe.transform(
      this.selected.startDate,
      "yyyy-MM-dd"
    );

    let params = {
      endDate: this.selected.endDate,
      startDate: this.selected.startDate,
    };
    localStorage.setItem("params", JSON.stringify(params));
    this.getQuery(this.dateStart, this.dateEnd);
    this.getJobs(this.dateStart, this.dateEnd);
    // this.load=false;
    this.cd.detectChanges();
  }
  dimChange() {
    this.dimChangeFlag = true;
    this.type = "line_chart";
    this.lineChart.data = [];
    this.dateEnd = this.datePipe.transform(this.selected.endDate, "yyyy-MM-dd");
    this.dateStart = this.datePipe.transform(
      this.selected.startDate,
      "yyyy-MM-dd"
    );
    this.getQuery(this.dateStart, this.dateEnd);
    this.getJobs(this.dateStart, this.dateEnd);
    if (this.currentSquare == "queryCount") this.queryCountChart();
    else if (this.currentSquare == "queryAvg") this.queryAvgChart();
    else if (this.currentSquare == "jobCount") this.jobCountChart();
    else if (this.currentSquare == "jobBuildTime") this.jobBuildTimeChart();
    this.dateEnd = this.datePipe.transform(this.selected.endDate, "yyyy-MM-dd");
    this.dateStart = this.datePipe.transform(
      this.selected.startDate,
      "yyyy-MM-dd"
    );
    this.getQuery(this.dateStart, this.dateEnd);
    this.getJobs(this.dateStart, this.dateEnd);
    const that = this;
    this.options = {
      lineChartOptions: {
        chart: {
          type: "lineChart",
          height: 300,
          margin: {
            top: 20,
            right: 55,
            bottom: 60,
            left: 55,
          },
          showYAxis: true,
          showXAxis: true,
          useInteractiveGuideline: true,
          interpolate: "linear",
          x: function (d) {
            return d.label;
          },
          y: function (d) {
            return d.value;
          },
          xAxis: {
            axisLabel: this.linechartDimension,
            axisLabelDistance: 10,
            staggerLabels: true,
            tickFormat: function (d) {
              //d = d3.time.format('%d-%m-%y')(new Date(d));
              if (d.length > 10) {
                return d.substring(0, 10) + "...";
              } else {
                var st = moment(new Date(that.selected.startDate)).valueOf();
                var et = moment(new Date(that.selected.endDate)).valueOf();
                var start = moment(st).unix();
                var end = moment(et).unix();
                if (d < start) d = start;
                if (d > end) d = end;
                return moment.unix(d).format("YYYY-MM-DD");
              }
            },
          },
          yAxis: {
            axisLabel: this.graphTitle,
            tickFormat: function (d) {
              if (d < 1000) {
                return d3.format(".1")(d.toFixed(2));
              } else {
                var prefix = d3.formatPrefix(d);
                return prefix.scale(d) + prefix.symbol;
              }
            },
            showMaxMin: false,
          },
          valueFormat: function (d) {
            return d3.format(".2f")(d);
          },
          transitionDuration: 500,
          tooltip: {
            contentGenerator: function (d) {
              // return '<table><tr><td class="legend-color-guide"><div style="background-color: '+d.color+';width:10px;margin-right:5px">&nbsp;</div></td><td class="key">'+d.data.label+' : </td><td class="value">'+d.data.value +'</td></tr></table>';
            },
          },
        },
      },
    };
  }
  showBarchartValue(isChecked: boolean) {
    this.showValue = isChecked;
    this.type = "bar";
    if (this.currentSquare == "queryCount") this.queryCountChart();
    else if (this.currentSquare == "queryAvg") this.queryAvgChart();
    else if (this.currentSquare == "jobCount") this.jobCountChart();
    else if (this.currentSquare == "jobBuildTime") this.jobBuildTimeChart();
    this.dateEnd = this.datePipe.transform(this.selected.endDate, "yyyy-MM-dd");
    this.dateStart = this.datePipe.transform(
      this.selected.startDate,
      "yyyy-MM-dd"
    );
    this.getQuery(this.dateStart, this.dateEnd);
    this.getJobs(this.dateStart, this.dateEnd);

    this.options.barChartOptions.chart.xAxis.rotateLabels = -50;
  }
  // This method is to get the count of project,model and cube Lists in the dashboard page
  loadProjectList() {
    this.projectService.listReadable().subscribe((data) => {
      this.projectModel = data;
      if (this.projectModel.length > 0) {
        for (let i in this.projectModel) {
          let project = this.projectModel[i];
          this.dataSource = this.dataSource + project.tables.length;
          this.models = this.models + project.models.length;
          this.cubes = this.cubes + project.realizations.length;
        }
        // this.load = false;
        this.cd.detectChanges();
      }
    });
  }
  queryCountChart() {
    this.currentSquare = "queryCount";
    this.barchartCategory = this.categories[0];
    this.barchartMetric = this.metrics[0].value;
    this.linechartCategory = this.categories[0];
    this.linechartMetric = this.metrics[0].value;
    this.graphTitle = this.metrics[0].name;
    this.active = "query";
    this.icon = "fa-lightbulb-o";
    this.barChartData.datasets[0].label = "query count";
    this.createCharts();

    // if(this.barChart.data.values.length>4){
    //   alert(this.barChart.data.values.length);
    // this.options.barChartOptions.chart.xAxis.rotateLabels = -50;
    // }
    //  this.options.barChartOptions.chart.xAxis.rotateLabels = -50;
  }
  queryAvgChart() {
    this.currentSquare = "queryAvg";
    this.barchartCategory = this.categories[0];
    this.barchartMetric = this.metrics[1].value;
    this.linechartCategory = this.categories[0];
    this.linechartMetric = this.metrics[1].value;
    this.graphTitle = this.metrics[1].name;
    this.active = "query_avg";
    this.icon = "fa-bolt";
    this.barChartData.datasets[0].label = "query avg latency";
    this.createCharts();

    //  this.options.barChartOptions.chart.xAxis.rotateLabels = -50;
  }
  jobCountChart() {
    this.currentSquare = "jobCount";
    this.barchartCategory = this.categories[1];
    this.barchartMetric = this.metrics[2].value;
    this.linechartCategory = this.categories[1];
    this.linechartMetric = this.metrics[2].value;
    this.graphTitle = this.metrics[2].name;
    this.active = "job";
    this.icon = "fa-wrench";
    this.barChartData.datasets[0].label = "job count";
    this.createCharts();
    this.options.barChartOptions.chart.xAxis.rotateLabels = -50;
  }
  jobBuildTimeChart() {
    this.currentSquare = "jobBuildTime";
    this.barchartCategory = this.categories[1];
    this.barchartMetric = this.metrics[3].value;
    this.linechartCategory = this.categories[1];
    this.linechartMetric = this.metrics[3].value;
    this.graphTitle = this.metrics[3].name;
    this.active = "time";
    this.icon = "fa-clock-o";
    this.barChartData.datasets[0].label = "avg build time";
    this.createCharts();

    this.options.barChartOptions.chart.xAxis.rotateLabels = -50;
  }
  createCharts() {
    if (this.type == "bar") {
      this.barChart.data = [];
      this.buildGraph(
        {
          dimension: this.barchartDimension,
          category: this.barchartCategory,
          metric: this.barchartMetric,
        },
        "bar"
      );
      this.type = "";
    } else if (this.type == "line_chart") {
      this.lineChart.data = [];
      this.buildGraph(
        {
          dimension: this.linechartDimension,
          category: this.linechartCategory,
          metric: this.linechartMetric,
        },
        "line"
      );
      this.type = "";
    } else {
      this.buildGraph(
        {
          dimension: this.barchartDimension,
          category: this.barchartCategory,
          metric: this.barchartMetric,
        },
        "bar"
      );
      this.buildGraph(
        {
          dimension: this.linechartDimension,
          category: this.linechartCategory,
          metric: this.linechartMetric,
        },
        "line"
      );
      this.type = "";
    }
  }
  // This method is to get the count of data catalog List in the dashboard page
  loadDataCataloglist() {
    // let param = { page: 0, status: true };
    // this.dataCatalogService.getCount(param).subscribe((data) => {
    //   this.datacatalogList = data;

      
    //   this.catalog = this.datacatalogList.page.totalElements;

    //   this.cd.detectChanges();
    // });
  }

  // This method is to get the count of data request List in the dashboard page
  loadDataRequestList() {
    let param = {
      page: this.currentPage,
      size: "10",
      isCompleted: "",
      projectId: "",
    };

    // this.dataRequestService
    //   .getRequestList(
    //     param.page,
    //     param.size,
    //     param.isCompleted,
    //     param.projectId
    //   )
    //   .subscribe((data) => {
    //     this.dataRequestList = data;
    //     for (let i in this.dataRequestList) {
    //       let totalRequest = this.dataRequestList[i];
    //       this.dataRequest = this.dataRequest + totalRequest.totalElements;
    //     }
    //   });
  }

  // This method is to get the count of data access List  in the dashboard page
  loadDataAccessList() {
    let param = {
      page: this.currentPage,
      size: "10",
      projectName: "",
      databaseName: "",
      tableName: "",
      columnName: "",
    };

    // this.dataAccessService
    //   .getAccessList(
    //     param.page,
    //     param.size,
    //     param.projectName,
    //     param.databaseName,
    //     param.tableName,
    //     param.columnName
    //   )
    //   .subscribe((data) => {
    //     this.dataAccessList = data;
    //     for (let i in this.dataAccessList) {
    //       let totalAccess = this.dataAccessList[i];
    //       this.dataAccess = this.dataAccess + totalAccess.totalElements;
    //     }
    //   });
  }

  getManageCatalogue() {
    // this.dataCatalogService.getDashboardCount().subscribe((data) => {
    //   let resp: any = data;

    //   this.publishedDBs = resp.page.totalElements;
    //   this.cd.detectChanges();
    // });
  }
  getAvgCubeExpansion() {
    this.dashboardService.getCubeMetrics({}).subscribe((data) => {
      this.cubeData = data;
      this.cd.detectChanges();
    });
  }
  getQuery(rangeStart: any, rangeEnd: any) {
    this.checkgraph = false;
    let params = { endTime: rangeEnd, startTime: rangeStart };
    // this.load=true;
    this.dashboardService.getQueryMetrics(params).subscribe(
      (data) => {
        this.queryData = data;
        this.load = false;
        this.checkgraph = true;
        this.cd.detectChanges();
      },
      (error) => {
        this.load = false;
      }
    );
  }
  getJobs(rangeStart: any, rangeEnd: any) {
    let params = { endTime: rangeEnd, startTime: rangeStart };
    this.dashboardService.getJobMetrics(params).subscribe((data) => {
      this.jobData = data;
    });
  }
  clearGraphs() {
    // this.lineChart.data=[];
    this.barChart.data = [];
  }
  isUserAdmin(authorities) {
    for (let authority of authorities) {
      if (authority["authority"] == "ROLE_ADMIN") {
        return true;
      }
    }
    return false;
  }

  toggleButton() {
    this.toggleState = !this.toggleState;
  }

  showContainer() {
    return {
      "show-o": this.toggleState,
    };
  }

  toggleButtong() {
    this.toggleStateg = !this.toggleStateg;
  }

  showContainerg() {
    return {
      "show-og": this.toggleStateg,
    };
  }

  showFullscreenGraph() {
    if (this.currentSquare == "queryCount") this.queryCountChart();
    else if (this.currentSquare == "queryAvg") this.queryAvgChart();
    else if (this.currentSquare == "jobCount") this.jobCountChart();
    else if (this.currentSquare == "jobBuildTime") this.jobBuildTimeChart();
    this.dateEnd = this.datePipe.transform(this.selected.endDate, "yyyy-MM-dd");
    this.dateStart = this.datePipe.transform(
      this.selected.startDate,
      "yyyy-MM-dd"
    );
    this.getQuery(this.dateStart, this.dateEnd);
    this.getJobs(this.dateStart, this.dateEnd);
  }

  saveLogs() {
    var today = new Date();
    let param = {
      username: this.loggedUser.username,
      targetPage: "home",
      day: today.getDay(),
      month: today.getMonth(),
      year: today.getFullYear(),
    };
    this.commonServiceService.saveLogs(param).subscribe((data) => {});
  }
}
