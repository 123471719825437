import {
  Component,
  OnInit,
  AfterViewInit,
  ChangeDetectorRef,
  Input,
  Output,
  EventEmitter,
} from "@angular/core";

import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { CubesService } from "../../../../../services/cubes/cubes.service";
import { ValueTransformer } from "@angular/compiler/src/util";
import { Router, ActivatedRoute } from "@angular/router";
import { CubeConfig } from "../../../../../models/cubes/cube-config";
import swal from "sweetalert2";
import * as $ from "jquery";
import { TableService } from "../../../../../services/table/table.service";
import {
  CdkDragDrop,
  moveItemInArray,
  transferArrayItem,
} from "@angular/cdk/drag-drop";
import { THIS_EXPR } from "@angular/compiler/src/output/output_ast";
import { invalid } from "moment";
@Component({
  selector: "app-visual-cube-step6",
  templateUrl: "./visual-cube-step6.component.html",
  styleUrls: ["./visual-cube-step6.component.scss"],
})
export class VisualCubeStep6Component implements OnInit, AfterViewInit {
  projectId;
  errorShard;
  error;
  streamingConfig = {
    algorithm: "INMEM",
    window: 3600,
    duration: 3600,
    interval: 300,
    policy: 1,
    purgeretention: 86400,
  };

  cubePolicy: boolean = true;

  cubeform: FormGroup;
  @Input() tableInfo: any;
  @Input() visualEditMode: boolean;
  @Output() validateData: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    private cubesService: CubesService,
    private cdr: ChangeDetectorRef,
    private tableService: TableService,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private router: Router
  ) {}

  ngAfterViewInit() {
    this.cdr.detectChanges();

    $(document).ready(function () {
      var contenth = $(window).height() - 110;
      var sidebarh = $(window).height() - 111;
      $(".pagec").css("height", contenth);
      $(".sidebar-wrapper").css("height", sidebarh);
    });

    $(".progresslic").each(function () {
      if ($(".progressbar li ").length > 6) {
        $(".menublock").addClass("tabw_seven");
      }
    });
  }

  ngOnInit() {
    this.projectId = this.route.snapshot.paramMap.get("projectId");

    this.cubeform = new FormGroup(
      {
        retention: new FormControl("", [
          Validators.required,
          Validators.pattern("^[0-9]*$"),
          Validators.min(1),
        ]),
        window: new FormControl("", [
          Validators.required,
          Validators.pattern("^[0-9]*$"),
          Validators.min(1),
        ]),
      },
      validateWindow
    );

    if (this.cubesService.cubeMeta.dimensions.length == 0) {
      this.router.navigate([
        "/projects/" + this.projectId + "/cubes/designer/info",
      ]);
    }
    this.tableInfo = this.cubesService.tableInfo;

    if (
      this.cubesService.cubeMeta.override_qubz_properties["qubz.cube.algorithm"]
    ) {
      this.streamingConfig = {
        algorithm:
          this.cubesService.cubeMeta.override_qubz_properties[
            "qubz.cube.algorithm"
          ],
        window:
          this.cubesService.cubeMeta.override_qubz_properties[
            "qubz.stream.cube.window"
          ],
        duration:
          this.cubesService.cubeMeta.override_qubz_properties[
            "qubz.stream.cube.duration"
          ],
        interval:
          this.cubesService.cubeMeta.override_qubz_properties[
            "qubz.stream.index.checkpoint.intervals"
          ],
        policy:
          this.cubesService.cubeMeta.override_qubz_properties[
            "qubz.stream.segment.retention.policy"
          ] == "fullBuild"
            ? 1
            : 0,
        purgeretention:
          this.cubesService.cubeMeta.override_qubz_properties[
            "qubz.stream.segment.retention.policy.purge.retentionTimeInSec"
          ],
      };
    }

    if (this.visualEditMode) {
      this.cubePolicy = this.streamingConfig.policy ? true : false;

      this.cubeform.setValue({
        retention: this.streamingConfig.policy
          ? this.streamingConfig.duration / 3600
          : this.streamingConfig.purgeretention / 3600,
        window: this.streamingConfig.window / 3600,
      });

      this.setRetentionValidation();
    }
  }

  get f() {
    return this.cubeform.controls;
  }

  checkValidation() {
    this.validateData.emit({
      invalid: this.cubeform.status === "INVALID",
    });
  }

  changeCubeType() {
    this.cubeform.reset();

    console.log("change policy", this.cubePolicy);

    this.validateData.emit({
      invalid: true,
    });

    this.setRetentionValidation();

    this.cubeform.updateValueAndValidity();
  }

  setRetentionValidation() {
    if (!this.cubePolicy) {
      this.f.retention.setValidators([
        Validators.required,
        Validators.pattern("^[0-9]*$"),
        Validators.min(1),
        Validators.max(24),
      ]);
    } else {
      this.f.retention.setValidators([
        Validators.required,
        Validators.pattern("^[0-9]*$"),
        Validators.min(1),
      ]);
    }
  }

  saveConfig() {
    let override_qubz_properties = {};

    if (this.tableInfo.source_type == 20) {
      this.streamingConfig = {
        ...this.streamingConfig,
        window: +this.f.window.value * 3600,
        duration: this.cubePolicy
          ? +this.f.retention.value * 3600
          : +this.f.window.value * 3600,
        purgeretention: +this.f.retention.value * 3600,
        policy: this.cubePolicy ? 1 : 0,
      };

      override_qubz_properties = {
        "qubz.stream.cube.window": this.streamingConfig.window,
        "qubz.stream.cube.duration": this.streamingConfig.duration,
        "qubz.stream.index.checkpoint.intervals": this.streamingConfig.interval,
        "qubz.cube.algorithm": this.streamingConfig.algorithm,
        "qubz.stream.segment.retention.policy": this.streamingConfig.policy
          ? "fullBuild"
          : "purge",
        "qubz.stream.segment.retention.policy.purge.retentionTimeInSec":
          this.streamingConfig.purgeretention,
      };

      if (this.cubePolicy) {
        delete override_qubz_properties[
          "qubz.stream.segment.retention.policy.purge.retentionTimeInSec"
        ];
      }
    }

    this.cubesService.cubeMeta.override_qubz_properties =
      override_qubz_properties;
  }
}

function validateWindow(cubeForm: FormGroup): { [key: string]: boolean } {
  if (
    cubeForm.controls["window"].value &&
    cubeForm.controls["retention"].value
  ) {
    if (+cubeForm.value.window > +cubeForm.value.retention) {
      //this.validateData.emit(true);
      return { incorrect: true };
    } else {
      //this.validateData.emit(false);
      return null;
    }
  }
}
