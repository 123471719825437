import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ModelService } from '../../../../services/model/model.service';
import {ModelStepsService} from '../../../../services/model/model-steps.service';
import swal from 'sweetalert2';




@Component({
  selector: 'app-model-clone',
  templateUrl: './model-clone.component.html',
  styleUrls: ['./model-clone.component.scss']
})
export class ModelCloneComponent implements OnInit {
  projectId:any;
  modelName:any;
  selectedModelName:any;
  showModal:boolean = true;
  constructor(private route: ActivatedRoute, private router: Router, private modelStepsService: ModelStepsService,
    private modelSerivce: ModelService) { }

  ngOnInit() {
    this.projectId = this.route.snapshot.paramMap.get("projectId");
    this.selectedModelName = this.modelStepsService.modelName;
    if(this.selectedModelName !== undefined){
      this.modelName = this.selectedModelName + '_clone';
    } else{
      this.modelName='';
    }
    
  }
  
  checkModelNameEmpty(){
    if(this.modelName === undefined || this.modelName === null || this.modelName=== ''){
      this.modelName='';
      this.showModal= false;
      this.router.navigate(['/projects/',this.projectId,'models']);
    } else{
     
      this.showModal= true;
    }
  }

  cancel(){
    this.showModal = false;
    this.router.navigate(['/projects/',this.projectId,'models']);
  }
  
  cloneService(){
    let params={
      "ccInCheck": "string",
      "isSeekingExprAdvice": true,
      "message": "string",
      "modelDescData": "string",
      "modelName": this.modelName,
      "project": this.projectId,
      "seekingExprAdvice": true,
      "successful": true,
      "uuid": this.modelStepsService.uuid
    }
    this.modelSerivce.clone(this.selectedModelName,params).subscribe(data=>{

        swal("Success!", "Model cloned successfully", "success"); 
        this.router.navigate(['/projects/',this.projectId,'models']);
  
    }, (error) => {
                   swal({
                    type:'error',
                    title: ' OOPS...',
                    text:error.error.msg,
                    confirmButtonText: 'OK',
                  }).then((status) => {
                    if(status.value==true){
                      this.router.navigate(['/projects/',this.projectId,'models']);
                      }
                  }, (dismiss) => {
                    if (dismiss === 'cancel') {
                    }
                    
                  });
                }); 
 
  }

  save(){
    swal(
      { 
      type:'warning', 
      title: 'Are you sure you want to clone the model?', 
      showCancelButton: true, confirmButtonText: 'Yes', 
      cancelButtonText: 'No'
      } 
      ).then((status) => {
      if(status.value==true){
        this.cloneService();
      }
      }, (dismiss) => {
      if (dismiss === 'cancel') {
      }
      });
  }
}
