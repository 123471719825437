import { Component, OnInit } from '@angular/core';
import { DataRequestService } from '../../../../services/data-access/data-request.service';
import { Router, ActivatedRoute } from '@angular/router';
import { ProjectService } from '../../../../services/project/project.service';
import { OrderPipe } from 'ngx-order-pipe';
@Component({
  selector: 'app-data-catalog-request-list',
  templateUrl: './data-catalog-request-list.component.html',
  styleUrls: ['./data-catalog-request-list.component.scss']
})
export class DataCatalogRequestListComponent implements OnInit {
  dataList;
  currentPage = 1;
  contentList: any;
  dataRequest;
  page = 1;
  project;
  totalElements;
  details;
  load;  
  order: string = 'dataRequest.requestedDate';  
  reverse: boolean = false;
  sortedCollection;
  constructor(private projectService: ProjectService, private dataRequestService: DataRequestService, private router: Router, private route: ActivatedRoute,private orderPipe: OrderPipe) {
    this.project = this.route.snapshot.paramMap.get("projectId");
  }
  viewDataRequestDetails(dataRequest) {

    this.router.navigate(["/projects/" + this.project + "/data-catalog/requests/" + dataRequest.uuid]);
  }
  setOrder(value: string) {
    if (this.order === value) {
      this.reverse = !this.reverse;
    }

    this.order = value;
  }
  ngOnInit() {
    this.getList(this.page);
  }
  getList(page) {
    this.load=true;
    let project = this.route.snapshot.paramMap.get("projectId");
    this.projectService.getProjectByName(project).subscribe(data => {
      this.details = data;
      this.load=false;
      page = page - 1;
      let param = {
        page: page,
        size: '10',
        isCompleted: '',
        projectId: this.details.uuid
      }
      this.dataRequestService.getRequests(param.page, param.size, param.projectId).subscribe(data => {
        this.dataList = data;
        this.contentList = this.dataList.page.content;
        this.totalElements = this.dataList.page.totalElements;
    
      });
    });

  }
}