import { Component, OnInit, Input } from '@angular/core';


@Component({
  selector: 'app-cube-view-step-one',
  templateUrl: './cube-view-step-one.component.html',
  styleUrls: ['./cube-view-step-one.component.scss']
})
export class CubeViewStepOneComponent implements OnInit {
	@Input() cubeDesc;

	constructor() { 

	}

  	ngOnInit() {
  	}

}
