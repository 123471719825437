import { Component, OnInit, AfterViewInit } from "@angular/core";
import { ActivatedRoute, Router, ParamMap } from "@angular/router";
import { ProjectDashboardService } from "../../../services/project/project-dashboard.service";
import * as d3 from "d3";
import { getQueryPredicate } from "@angular/compiler/src/render3/view/util";
import swal from "sweetalert2";
import { DatePipe } from "@angular/common";
import * as moment from "moment";
import { Injectable, ChangeDetectorRef } from "@angular/core";
import * as $ from "jquery";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { Chart } from "chart.js";
import { CommonServiceService } from "../../../services/common-services/common-service.service";

@Component({
  selector: "app-project-dashboard",
  templateUrl: "./project-dashboard.component.html",
  styleUrls: ["./project-dashboard.component.scss"],
  providers: [DatePipe],
})
export class ProjectDashboardComponent implements OnInit, AfterViewInit {
  totalDataCount: number[] = [];
  toggleState = false;
  toggleStateg = false;
  graphActive: boolean = false;
  dateStart = "";
  dateEnd = "";
  catalogs$: Object;
  dashboardItems$: Object;
  modelsCount: any;
  projectName: any;
  cubesCount: any;
  catalogCount: any;
  dataSourceCount: any;
  cubeMetricsRefreshTime: any;
  cubedata: any;
  cubeMetrics: any;
  queryMetrics: any;
  jobMetrics: any;
  avgCubeExpansion: any;
  timezone = "GMT";
  alwaysShowCalendars: boolean;
  queryMetricsCount;
  expansionRate: any;
  selectedCube;
  granularityFilter;
  // line:boolean=false;
  barChart;
  lineChart;
  chartObj;
  loadLineChart;
  loadBarChart;
  checkgraph;
  ranges: any = {
    Today: [moment(), moment()],
    Yesterday: [moment().subtract(1, "days"), moment().subtract(1, "days")],
    "Last 7 Days": [moment().subtract(6, "days"), moment()],
    "Last 30 Days": [moment().subtract(29, "days"), moment()],
    "This Month": [moment().startOf("month"), moment().endOf("month")],
    "Last Month": [
      moment().subtract(1, "month").startOf("month"),
      moment().subtract(1, "month").endOf("month"),
    ],
  };
  dateRangeMaxDate;
  invalidDates: moment.Moment[] = [
    moment().add(1, "days"),
    moment().add(1, "months"),
  ];
  startv;
  endv;
  isInvalidDate = (m: moment.Moment) => {
    return this.invalidDates.some((d) => d.isSame(m, "day"));
  };
  selected: any;
  options;
  barchartDimension = "CUBE";
  linechartDimension = "DAY";
  baseOptions;
  barchartCategory;
  linechartCategory;
  barchartMetric;
  linechartMetric;
  granularityValue;
  currentSquare = "queryCount";
  graphTitle;
  name;
  load;
  showValue: boolean;
  metrics = [
    { name: "query count", value: "QUERY_COUNT" },
    { name: "Average query latency", value: "AVG_QUERY_LATENCY" },
    { name: "job count", value: "JOB_COUNT" },
    { name: "Average build time", value: "AVG_JOB_BUILD_TIME" },
  ];
  dimensions = [
    { name: "DAY", value: "DAY" },
    { name: "WEEK", value: "WEEK" },
    { name: "MONTH", value: "MONTH" },
  ];
  categories = ["QUERY", "JOB"];
  active = "query";
  type = null;
  loggedUser;
  icon = "fa-lightbulb-o";
  projectDescription: any;
  // graphSelected = 'BAR CHART';
  borderColor = [
    "#3A97D3",
    "#00c0ef",
    "#5ca95c",
    "#f39c12",
    "#f56954",
    "#b5bbc8",
  ];
  color = ["#3A97D3", "#00c0ef", "#5ca95c", "#f39c12", "#f56954", "#b5bbc8"];
  newBarChart;
  newLineChart;
  // newPieChart;
  // newPolarChart;
  // newDoughnutChart;
  barChartData = {
    labels: [],
    datasets: [
      {
        label: "Query Count",
        data: [],
        backgroundColor: [],
        borderColor: [],
        borderWidth: 1,
      },
    ],
  };
  // pieChartData = JSON.parse(JSON.stringify(this.barChartData));
  // polarChartData = JSON.parse(JSON.stringify(this.barChartData));
  lineChartData = JSON.parse(JSON.stringify(this.barChartData));
  // doughnutChartData = JSON.parse(JSON.stringify(this.barChartData));
  barGraphOptions = {
    legend: {
      display: false,
    },
    maintainAspectRatio: false,
    responsive: true,
    scales: {
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
          },
        },
      ],
      xAxes: [
        {
          ticks: {
            display: false,
          },
        },
      ],
    },
    plugins: {
      datalabels: {
        anchor: "end",
        display: false,
        backgroundColor: function (context) {
          return context.dataset.backgroundColor;
        },
        borderColor: "white",
        borderRadius: 25,
        borderWidth: 1,
        color: "red",
        font: {
          size: 10,
        },
      },
    },
  };
  // pieGraphOptions = {
  //   legend: {
  //     display: false
  // },
  //   maintainAspectRatio: false,
  //   responsive: true,
  //     plugins: {
  //       datalabels: {
  //         anchor: "end",
  //         display: false,
  //         backgroundColor: function(context) {
  //           return context.dataset.backgroundColor;
  //         },
  //         borderColor: "white",
  //         borderRadius: 25,
  //         borderWidth: 1,
  //         color: "blue",
  //         font: {
  //           size: 10
  //         },
  //       }
  //     }
  // }
  // polarGraphOptions = JSON.parse(JSON.stringify(this.pieGraphOptions));
  // doughnutGraphOptions = JSON.parse(JSON.stringify(this.pieGraphOptions));
  // chartDimensions= [
  //   {name: 'COUNT BY DURATION', value: 'LINE CHART'},
  //   {name: 'COUNT BY CUBE', value: 'BAR CHART'},
  // {name: 'PIE CHART', value: 'PIE CHART'},
  // {name: 'POLAR AREA CHART', value: 'POLAR AREA CHART'},
  // {name: 'DOUGHNUT CHART', value: 'DOUGHNUT CHART'}
  // ];
  // loader;
  dimChangeFlag = false;
  constructor(
    private projectDashboardService: ProjectDashboardService,
    private route: ActivatedRoute,
    private router: Router,
    private chRef: ChangeDetectorRef,
    public datePipe: DatePipe,
    public commonServiceService: CommonServiceService
  ) {
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.alwaysShowCalendars = true;
    if (JSON.parse(localStorage.getItem("params"))) {
      let selected = JSON.parse(localStorage.getItem("params"));
      this.selected = {
        startDate: new Date(selected.startDate),
        endDate: new Date(selected.endDate),
      };
    } else {
      this.selected = {
        startDate: moment().subtract(29, "days"),
        endDate: new Date(),
      };
    }
  }
  saveLogs() {
    var today = new Date();
    let param = {
      username: this.loggedUser.username,
      targetPage: "projects/" + this.projectName + "/dashboard",
      day: today.getDay(),
      month: today.getMonth(),
      year: today.getFullYear(),
    };
    this.commonServiceService.saveLogs(param).subscribe((data) => {});
  }
  ngAfterViewInit() {
    $(document).ready(function () {
      var contenth = $(window).height() - 110;
      var sidebarh = $(window).height() - 111;
      $(".pagec").css("height", contenth);
      $(".sidebar-wrapper").css("height", sidebarh);

      $(".db_boxec").mouseover(function () {
        $(this).find(".overview-btnc").css("display", "block");
      });

      $(".db_boxec").mouseout(function () {
        $(this).find(".overview-btnc").css("display", "none");
      });

      $(".graphboxc").mouseover(function () {
        $(this).find(".overview-btnc").css("display", "block");
      });

      $(".graphboxc").mouseout(function () {
        $(this).find(".overview-btnc").css("display", "none");
      });
    });
    Chart.plugins.unregister(ChartDataLabels);
    // this.initializeBarChart();
  }
  initializeLineChart() {
    // this.line=true;
    // this.loader=true;
    var ctx = document.getElementById("lineChart");
    if (ctx)
      this.newLineChart = new Chart(ctx, {
        type: "line",
        data: this.lineChartData,
        options: {
          legend: {
            display: false,
          },
          maintainAspectRatio: false,
          responsive: true,
          scales: {
            yAxes: [
              {
                ticks: {
                  beginAtZero: true,
                },
              },
            ],
            xAxes: [
              {
                type: "time",
                time: {
                  unit: "day",
                  displayFormats: {
                    day: "ll",
                  },
                },
              },
            ],
          },
        },
      });
    // this.loader=false;
  }

  initializeBarChart() {
    // this.line=false;
    // this.loader=true;
    var ctx = document.getElementById("barChart");
    if (ctx)
      this.newBarChart = new Chart(ctx, {
        plugins: [ChartDataLabels],
        type: "bar",
        data: this.barChartData,
        options: this.barGraphOptions,
      });
    // this.loader=false;
  }
  // initializePieChart(){
  //   this.line=false;
  //   this.loader=true;
  //   var ctx = document.getElementById('barChart');
  //   this.newPieChart = new Chart(ctx, {
  //     plugins:
  //     [
  //       ChartDataLabels
  //     ],
  //       type: 'pie',
  //       data: this.pieChartData,
  //       options: this.pieGraphOptions
  //   });
  //   this.loader=false;
  //   }
  //   initializePolarChart(){
  //     this.line=false;
  //     this.loader=true;
  //     var ctx = document.getElementById('barChart');
  //     this.newPolarChart = new Chart(ctx, {
  //       plugins:
  //       [
  //         ChartDataLabels
  //       ],
  //         type: 'polarArea',
  //         data: this.polarChartData,
  //         options: this.polarGraphOptions
  //     });
  //     this.loader=false;
  //     }
  //     initializeDoughnutChart(){
  //       this.line=false;
  //       this.loader=true;
  //       var ctx = document.getElementById('barChart');
  //       this.newDoughnutChart = new Chart(ctx, {
  //         plugins:
  //         [
  //           ChartDataLabels
  //         ],
  //           type: 'doughnut',
  //           data: this.doughnutChartData,
  //           options: this.doughnutGraphOptions
  //       });
  //       this.loader=false;
  //       }

  ngOnInit() {
    //  this.startv= parseInt(moment(this.selected.startDate).format('X'));
    //  this.endv=parseInt(moment(this.selected.endDate).format('X'));
    this.loggedUser = JSON.parse(sessionStorage.getItem("loggedUser"));
    this.dateRangeMaxDate = moment();
    this.route.paramMap.subscribe((params: ParamMap) => {
      this.projectName = params.get("projectId");
    });
    this.selectedCube = "";
    this.selectedCube = localStorage.getItem("selectedCube");
    localStorage.setItem("projectName", this.projectName);
    this.granularityValue = "day";
    this.granularityFilter = [
      { name: "Daily", value: "day" },
      { name: "Weekly", value: "week" },
      { name: "Monthly", value: "month" },
    ];
    this.linechartDimension = "DAY";
    // if(this.isUserAdmin(this.loggedUser.authorities)){
    this.dashBoardItemsCount();
    this.getQuery(
      this.formatDatetime(this.selected.startDate),
      this.formatDatetime(this.selected.endDate)
    );
    this.getJobs(
      this.formatDatetime(this.selected.startDate),
      this.formatDatetime(this.selected.endDate)
    );
    const that = this;
    this.options = {
      lineChartOptions: {
        chart: {
          type: "lineChart",
          height: 295,
          margin: {
            top: 20,
            right: 55,
            bottom: 60,
            left: 55,
          },
          showYAxis: true,
          showXAxis: true,
          useInteractiveGuideline: true,
          // interpolate: 'cardinal',
          x: function (d) {
            return d.label;
          },
          y: function (d) {
            return d.value;
          },
          xAxis: {
            axisLabel: this.linechartDimension,
            axisLabelDistance: 50,

            staggerLabels: true,
            tickFormat: function (d) {
              //d = d3.time.format('%d-%m-%y')(new Date(d));
              if (d.length > 10) {
                return d.substring(0, 10) + "...";
              } else {
                var st = moment(new Date(that.selected.startDate)).valueOf();
                var et = moment(new Date(that.selected.endDate)).valueOf();
                var start = moment(st).unix();
                var end = moment(et).unix();
                if (d < start) d = start;
                if (d > end) d = end;
                return moment.unix(d).format("YYYY-MM-DD");
              }
            },
          },
          yAxis: {
            axisLabel: this.graphTitle,
            axisLabelDistance: 50,
            tickFormat: function (d) {
              if (d < 1000) {
                return d;
              } else {
                var prefix = d3.formatPrefix(d);
                return prefix.scale(d) + prefix.symbol;
              }
            },

            showMaxMin: true,
          },

          valueFormat: function (d) {
            return d;
          },
          transitionDuration: 500,
          tooltip: {
            contentGenerator: function (d) {
              return (
                '<table><tr><td class="legend-color-guide"><div style="background-color: ' +
                d.color +
                ';width:10px;margin-right:5px">&nbsp;</div></td><td class="key">' +
                d.data.label +
                ' : </td><td class="value">' +
                d.data.value +
                "</td></tr></table>"
              );
            },
          },
        },
      },
      barChartOptions: {
        chart: {
          type: "discreteBarChart",
          showValues: this.showValue,
          height: 295,
          margin: {
            top: 20,
            right: 20,
            bottom: 60,
            left: 55,
          },
          tooltip: {
            contentGenerator: function (d) {
              return (
                '<table><tr><td class="legend-color-guide"><div style="background-color: ' +
                d.color +
                ';width:10px;margin-right:5px">&nbsp;</div></td><td class="key">' +
                d.data.label +
                ' : </td><td class="value">' +
                d.data.value +
                "</td></tr></table>"
              );
            },
          },
          showYAxis: true,
          showXAxis: true,
          x: function (d) {
            return d.label;
          },
          y: function (d) {
            return d.value;
          },
          xAxis: {
            axisLabel: "Project",
            axisLabelDistance: 50,
            staggerLabels: true,
            showXAxis: true,
            tickFormat: function (d) {
              if (d.length > 10) {
                return d.substring(0, 10) + "...";
              } else {
                return d;
              }
            },
          },
          yAxis: {
            axisLabel: this.graphTitle,
            axisLabelDistance: 50,
            tickFormat: function (d) {
              if (d < 1000) {
                return d3.format(".2f")(d);
              } else {
                var prefix = d3.formatPrefix(d);
                return prefix.scale(d) + prefix.symbol;
              }
            },
            showMaxMin: true,
            showYAxis: true,
          },
          valueFormat: function (d) {
            return d3.format(".2f")(d);
          },
          transitionDuration: 500,
          tooltipContent: function (key, x, y, e, graph) {
            return "<h3>" + e.point.label + "</h3>" + "<p>" + y + "</p>";
          },
        },
      },
      baseChartOptions: {
        chart: {
          height: 272,
          margin: {
            top: 20,
            right: 40,
            bottom: 60,
            left: 45,
          },
          showYAxis: true,
          showXAxis: true,
          useInteractiveGuideline: false,
          x: function (d) {
            return d.label;
          },
          y: function (d) {
            return d.value;
          },
          xAxis: {
            axisLabelDistance: 50,
            staggerLabels: false,
            tickFormat: function (d) {
              if (d.length > 10) {
                return d.substring(0, 10) + "...";
              } else {
                return d;
              }
            },
          },
          yAxis: {
            axisLabel: this.graphTitle,
            axisLabelDistance: 50,
            tickFormat: function (d) {
              if (d < 1000) {
                return d;
              } else {
                var prefix = d3.formatPrefix(d);
                return prefix.scale(d) + prefix.symbol;
              }
            },
            showMaxMin: false,
          },
          valueFormat: function (d) {
            return d;
          },
          transitionDuration: 500,
          tooltip: {
            contentGenerator: function (d) {
              return (
                '<table><tr><td class="legend-color-guide"><div style="background-color: ' +
                d.color +
                ';"></div></td><td class="key">' +
                d.data.label +
                '</td><td class="value">' +
                d.data.value +
                "</td></tr></table>"
              );
            },
          },
        },
        title: {
          enable: true,
          css: {
            "margin-top": "20px",
          },
        },
      },
    };
    this.queryCountChart();
    // }
    this.projectDescription = localStorage.getItem("projDesc");
    //this.saveLogs();
  }
  queryCountChart() {
    this.currentSquare = "queryCount";
    this.barchartCategory = this.categories[0];
    this.barchartMetric = this.metrics[0].value;
    this.linechartCategory = this.categories[0];
    this.linechartMetric = this.metrics[0].value;
    this.graphTitle = this.metrics[0].name;
    this.icon = "fa-lightbulb-o";
    this.active = "query";
    this.barChartData.datasets[0].label = "query count";
    this.createCharts();
    this.options.barChartOptions.chart.xAxis.rotateLabels = -50;
  }
  queryAvgChart() {
    this.currentSquare = "queryAvg";
    this.barchartCategory = this.categories[0];
    this.barchartMetric = this.metrics[1].value;
    this.linechartCategory = this.categories[0];
    this.linechartMetric = this.metrics[1].value;
    this.graphTitle = this.metrics[1].name;
    this.icon = "fa-bolt";
    this.active = "query_avg";
    this.barChartData.datasets[0].label = "query avg latency";
    this.createCharts();
    this.options.barChartOptions.chart.xAxis.rotateLabels = -50;
  }
  jobCountChart() {
    this.currentSquare = "jobCount";
    this.barchartCategory = this.categories[1];
    this.barchartMetric = this.metrics[2].value;
    this.linechartCategory = this.categories[1];
    this.linechartMetric = this.metrics[2].value;
    this.graphTitle = this.metrics[2].name;
    this.icon = "fa-wrench";
    this.active = "job";
    this.barChartData.datasets[0].label = "job count";
    this.createCharts();
    this.options.barChartOptions.chart.xAxis.rotateLabels = -50;
  }
  jobBuildTimeChart() {
    this.currentSquare = "jobBuildTime";
    this.barchartCategory = this.categories[1];
    this.barchartMetric = this.metrics[3].value;
    this.linechartCategory = this.categories[1];
    this.linechartMetric = this.metrics[3].value;
    this.graphTitle = this.metrics[3].name;
    this.icon = "fa-clock-o";
    this.active = "time";
    this.barChartData.datasets[0].label = "avg build time";
    this.createCharts();
    this.options.barChartOptions.chart.xAxis.rotateLabels = -50;
  }
  formatDatetime = function (dateTime) {
    return moment(dateTime).format("YYYY-MM-DD");
  };
  dashBoardItemsCount() {
    this.load = true;
    this.projectDashboardService
      .getModelsCount(this.projectName)
      .subscribe((data) => {
        this.dashboardItems$ = data;
        this.modelsCount = Object.keys(data).length;

        // this.load=false;
      });
    this.projectDashboardService
      .getCubesCount(this.projectName)
      .subscribe((data) => {
        this.dashboardItems$ = data;
        this.cubesCount = Object.keys(data).length;
      });
    // this.projectDashboardService.getCatalogsCount().subscribe((data) => {
    //   this.dashboardItems$ = data;
    //   this.catalogCount = Object.keys(data).length;
    // });
    this.projectDashboardService
      .getDataSourceCount(this.projectName)
      .subscribe((data) => {
        this.dashboardItems$ = data;
        this.dataSourceCount = Object.keys(data).length;
      });
    this.cubeMetricsRefreshTime = moment().format("YYYY-MM-DD");
    this.projectDashboardService.getCubeMetrics(this.projectName).subscribe(
      (data) => {
        this.cubedata = data;
        if (data) {
          this.cubeMetrics = {
            totalCubes: this.cubedata.totalCube,
            expansionRate: {
              avg: this.cubedata.avgCubeExpansion,
              max: this.cubedata.maxCubeExpansion,
              min: this.cubedata.minCubeExpansion,
            },
          };
        }
      },
      (error) => {
        this.load = false;
        swal("Oops...", "Failed to load cube metrics", "error");
      }
    );
  }
  getQuery(rangeStart: any, rangeEnd: any) {
    this.checkgraph = false;
    this.projectDashboardService
      .getQueryMetrics(
        this.projectName,
        this.selectedCube,
        rangeStart,
        rangeEnd
      )
      .subscribe(
        (data) => {
          this.queryMetrics = data;
          this.checkgraph = true;
        }
        // error => {
        //   swal('Oops...', 'Failed to load query metrics', 'error');
        // }
      );
  }
  getJobs(rangeStart: any, rangeEnd: any) {
    this.projectDashboardService
      .getJobMetrics(this.projectName, this.selectedCube, rangeStart, rangeEnd)
      .subscribe(
        (data) => {
          this.jobMetrics = data;
          this.load = false;
        },
        (error) => {
          this.load = false;
          swal("Oops...", "Failed to load job metrics", "error");
        }
      );
  }
  createCharts() {
    if (this.type == "bar") {
      this.createChart(
        {
          dimension: this.barchartDimension,
          category: this.barchartCategory,
          metric: this.barchartMetric,
        },
        "bar"
      );
      this.type = "";
    } else if (this.type == "line_chart") {
      const that = this;
      this.options = {
        lineChartOptions: {
          chart: {
            type: "lineChart",
            height: 295,
            margin: {
              top: 20,
              right: 55,
              bottom: 60,
              left: 55,
            },
            showYAxis: true,
            showXAxis: true,
            useInteractiveGuideline: true,
            // interpolate: 'cardinal',
            x: function (d) {
              return d.label;
            },
            y: function (d) {
              return d.value;
            },
            xAxis: {
              axisLabel: this.linechartDimension,
              axisLabelDistance: 50,
              staggerLabels: true,
              tickFormat: function (d) {
                //d = d3.time.format('%d-%m-%y')(new Date(d));
                if (d.length > 10) {
                  return d.substring(0, 10) + "...";
                } else {
                  var st = moment(new Date(that.selected.startDate)).valueOf();
                  var et = moment(new Date(that.selected.endDate)).valueOf();
                  var start = moment(st).unix();
                  var end = moment(et).unix();
                  if (d < start) d = start;
                  if (d > end) d = end;
                  return moment.unix(d).format("YYYY-MM-DD");
                }
              },
            },
            yAxis: {
              axisLabel: this.graphTitle,
              tickFormat: function (d) {
                if (d < 1000) {
                  return d;
                } else {
                  var prefix = d3.formatPrefix(d);
                  return prefix.scale(d) + prefix.symbol;
                }
              },
              showMaxMin: false,
            },
            valueFormat: function (d) {
              return d;
            },
            transitionDuration: 500,
            tooltip: {
              contentGenerator: function (d) {
                return (
                  '<table><tr><td class="legend-color-guide"><div style="background-color: ' +
                  d.color +
                  ';width:10px;margin-right:5px">&nbsp;</div></td><td class="key">' +
                  d.data.label +
                  ' : </td><td class="value">' +
                  d.data.value +
                  "</td></tr></table>"
                );
              },
            },
          },
        },
      };
      this.createChart(
        {
          dimension: this.linechartDimension,
          category: this.linechartCategory,
          metric: this.linechartMetric,
        },
        "line"
      );
      this.type = "";
    } else {
      this.createChart(
        {
          dimension: this.linechartDimension,
          category: this.linechartCategory,
          metric: this.linechartMetric,
        },
        "line"
      );
      this.createChart(
        {
          dimension: this.barchartDimension,
          category: this.barchartCategory,
          metric: this.barchartMetric,
        },
        "bar"
      );
      this.type = "";
    }
  }

  // // Create chart option and data
  createChart(dataQuery, chartType) {
    const that = this;
    this.options = {
      lineChartOptions: {
        chart: {
          type: "lineChart",
          height: 295,
          margin: {
            top: 20,
            right: 55,
            bottom: 60,
            left: 55,
          },
          showYAxis: true,
          showXAxis: true,
          useInteractiveGuideline: true,
          // interpolate: 'cardinal',
          x: function (d) {
            return d.label;
          },
          y: function (d) {
            return d.value;
          },
          xAxis: {
            axisLabel: this.linechartDimension,
            axisLabelDistance: 50,
            staggerLabels: true,
            tickFormat: function (d) {
              // alert(moment.unix(d).format('YYYY-MM-DD'))
              //d = d3.time.format('%d-%m-%y')(new Date(d));
              if (d.length > 10) {
                return d.substring(0, 10) + "...";
              } else {
                var st = moment(new Date(that.selected.startDate)).valueOf();
                var et = moment(new Date(that.selected.endDate)).valueOf();
                var start = moment(st).unix();
                var end = moment(et).unix();
                if (d < start) d = start;
                if (d > end) d = end;
                return moment.unix(d).format("YYYY-MM-DD");
              }
            },
          },
          yAxis: {
            axisLabel: this.graphTitle,
            tickFormat: function (d) {
              if (d < 1000) {
                return d;
              } else {
                var prefix = d3.formatPrefix(d);
                return prefix.scale(d) + prefix.symbol;
              }
            },
            showMaxMin: false,
          },
          valueFormat: function (d) {
            return d;
          },
          transitionDuration: 500,
          tooltip: {
            contentGenerator: function (d) {
              return (
                '<table><tr><td class="legend-color-guide"><div style="background-color: ' +
                d.color +
                ';width:10px;margin-right:5px">&nbsp;</div></td><td class="key">' +
                d.data.label +
                ' : </td><td class="value">' +
                d.data.value +
                "</td></tr></table>"
              );
            },
          },
        },
      },
      barChartOptions: {
        chart: {
          type: "discreteBarChart",
          showValues: this.showValue,
          height: 295,
          margin: {
            top: 20,
            right: 20,
            bottom: 60,
            left: 55,
          },
          tooltip: {
            contentGenerator: function (d) {
              return (
                '<table><tr><td class="legend-color-guide"><div style="background-color: ' +
                d.color +
                ';width:10px;margin-right:5px">&nbsp;</div></td><td class="key">' +
                d.data.label +
                ' : </td><td class="value">' +
                d.data.value +
                "</td></tr></table>"
              );
            },
          },
          showYAxis: true,
          showXAxis: true,
          x: function (d) {
            return d.label;
          },
          y: function (d) {
            return d.value;
          },
          xAxis: {
            axisLabel: "Project",
            axisLabelDistance: 50,
            staggerLabels: true,
            showXAxis: true,
            tickFormat: function (d) {
              if (d.length > 10) {
                return d.substring(0, 10) + "...";
              } else {
                return d;
              }
            },
          },
          yAxis: {
            axisLabel: this.graphTitle,
            tickFormat: function (d) {
              if (that.active == "query" || that.active == "job") {
                if (d < 1000) {
                  return d;
                } else {
                  var prefix = d3.formatPrefix(d);
                  return prefix.scale(d) + prefix.symbol;
                }
              } else {
                if (d < 1000) {
                  return d3.format(".2f")(d);
                } else {
                  var prefix = d3.formatPrefix(d);
                  return prefix.scale(d) + prefix.symbol;
                }
              }
            },
            showMaxMin: true,
            showYAxis: true,
          },
          valueFormat: function (d) {
            if (that.active == "query" || that.active == "job") {
              return d;
            } else {
              return d3.format(".2f")(d);
            }
          },
          transitionDuration: 500,
          tooltipContent: function (key, x, y, e, graph) {
            return "<h3>" + e.point.label + "</h3>" + "<p>" + y + "</p>";
          },
        },
      },
      baseChartOptions: {
        chart: {
          height: 272,
          margin: {
            top: 20,
            right: 40,
            bottom: 60,
            left: 45,
          },
          showYAxis: true,
          showXAxis: true,
          useInteractiveGuideline: false,
          x: function (d) {
            return d.label;
          },
          y: function (d) {
            return d.value;
          },
          xAxis: {
            axisLabelDistance: 50,
            staggerLabels: false,
            tickFormat: function (d) {
              if (d.length > 10) {
                return d.substring(0, 10) + "...";
              } else {
                return d;
              }
            },
          },
          yAxis: {
            tickFormat: function (d) {
              if (d < 1000) {
                return d;
              } else {
                var prefix = d3.formatPrefix(d);
                return prefix.scale(d) + prefix.symbol;
              }
            },
            showMaxMin: false,
          },
          valueFormat: function (d) {
            return d;
          },
          transitionDuration: 500,
          tooltip: {
            contentGenerator: function (d) {
              return (
                '<table><tr><td class="legend-color-guide"><div style="background-color: ' +
                d.color +
                ';"></div></td><td class="key">' +
                d.data.label +
                '</td><td class="value">' +
                d.data.value +
                "</td></tr></table>"
              );
            },
          },
        },
        title: {
          enable: true,
          css: {
            "margin-top": "20px",
          },
        },
      },
    };
    this.chartObj = {
      queryObj: dataQuery,
    };
    //   // get base options
    this.baseOptions = this.options.baseChartOptions;
    var title = dataQuery.metric.name + " by " + dataQuery.dimension.name;
    this.chartObj.options = this.baseOptions;
    this.chartObj.options.chart.xAxis.axisLabel = dataQuery.dimension.name;
    this.chartObj.options.title.text = title;

    var groupByOptions = [];
    this.granularityFilter.forEach(function (option) {
      groupByOptions.push(option.value);
    });
    if (groupByOptions.indexOf(dataQuery.dimension.name) > -1) {
      var formatPattern = "%Y-%m-%d";
      if (dataQuery.dimension.name === this.granularityFilter[2].value) {
        formatPattern = "%Y-%m";
      }
      this.chartObj.options.chart.xAxis.tickFormat = function (d) {
        return d3.time.format(formatPattern)(moment.unix(d / 1000).toDate());
      };
      this.chartObj.options.chart.tooltip.contentGenerator = function (d) {
        return (
          '<table><tr><td class="legend-color-guide"><div style="background-color: ' +
          d.point.color +
          ';"></div></td><td class="key">' +
          d3.time.format(formatPattern)(
            moment.unix(d.point.label / 1000).toDate()
          ) +
          '</td><td class="value">' +
          d.point.value.toFixed(2) +
          "</td></tr></table>"
        );
      };

      // chartObj.options.chart.interpolate = 'cardinal';

      this.chartObj.options.chart.legend = {
        margin: {
          left: 15,
        },
      };
    }

    this.chartObj.data = [];

    if (chartType === "line") {
      this.loadLineChart = true;
      // this.selectedCube=localStorage.getItem('selectedCube');
      this.lineChart = {
        data: [],
      };
      this.lineChart = undefined;
      this.chartObj.options.chart.type = "lineChart";
      this.lineChart = this.chartObj;
      let param = {
        category: dataQuery.category,
        metric: dataQuery.metric,
        dimension: dataQuery.dimension,
        projectName: this.projectName,
        cubeName: this.selectedCube,
        startTime: this.formatDatetime(this.selected.startDate),
        endTime: this.formatDatetime(this.selected.endDate),
      };
      this.projectDashboardService.getChartData(param).subscribe(
        (data) => {
          let result = [];
          let res = [];
          this.lineChartData.labels = [];
          this.lineChartData.datasets[0].data = [];
          for (let [key, value] of Object.entries(data)) {
            result.push({
              label: parseInt(moment(key).format("X")),
              value: value,
            });
            res.push({ label: key, value: value });
          }
          if (result.length > 6) {
            this.lineChart.options.chart.xAxis.rotateLabels = -50;
            // this.lineChart.options.chart.xAxis.axisLabel = '';
          }
          // this.lineChart.options.chart.xAxis.xDomain=[this.startv,this.endv];
          res.sort((a, b) => moment(a.label).diff(moment(b.label)));
          res.forEach((item) => {
            this.lineChartData.labels.push(item.label);
            this.lineChartData.datasets[0].data.push(item.value);
          });
          if (this.newLineChart) this.newLineChart.destroy();
          this.initializeLineChart();
          this.lineChart.data = [
            {
              key: dataQuery.category,
              values: result.sort((a, b) => a.label - b.label),
            },
          ];
          if (this.dimChangeFlag == true) {
            this.newLineChart.update();
          }
          this.loadLineChart = false;
        }
        // , error => {
        //   swal('Oops...', 'Failed to load line chart.', 'error');
        // }
      );
    }
    if (chartType === "bar") {
      this.barChart = {
        data: [],
      };
      this.loadBarChart = true;
      this.chartObj.options.chart.type = "discreteBarChart";
      const that = this;
      // this.options.barChartOptions.chart.discretebar = {
      //   dispatch: {
      //     elementClick: function (e) {

      //       this.selectedCube = e.data.label;
      //       localStorage.setItem('selectedCube', this.selectedCube);
      //       this.projectName = localStorage.getItem('projectName');
      //       that.ngOnInit();
      //       // var url = '/projects/' + this.projectName + '/dashboard';
      //       // location.href = url;

      //     }
      //   }
      // };
      this.barChart = this.chartObj;
      let param = {
        category: dataQuery.category,
        metric: dataQuery.metric,
        dimension: dataQuery.dimension,
        projectName: this.projectName,
        cubeName: this.selectedCube,
        startTime: this.formatDatetime(this.selected.startDate),
        endTime: this.formatDatetime(this.selected.endDate),
      };
      this.projectDashboardService.getChartData(param).subscribe(
        (data) => {
          this.barChartData.labels = [];
          this.barChartData.datasets[0].data = [];
          // this.pieChartData.labels = [];
          // this.pieChartData.datasets[0].data = [];
          // this.polarChartData.labels = [];
          // this.polarChartData.datasets[0].data = [];
          // this.doughnutChartData.labels = [];
          // this.doughnutChartData.datasets[0].data = [];

          let result = [];
          for (let [key, value] of Object.entries(data)) {
            result.push({ label: key, value: value });
            this.barChartData.labels.push(key);
            this.barChartData.datasets[0].data.push(value);
            // this.pieChartData.labels.push(key);
            // this.pieChartData.datasets[0].data.push(value);
            // this.polarChartData.labels.push(key);
            // this.polarChartData.datasets[0].data.push(value);
            // this.doughnutChartData.labels.push(key);
            // this.doughnutChartData.datasets[0].data.push(value);
          }
          let bgColor = [];
          let borColor = [];
          result.forEach((element, index) => {
            bgColor.push(this.color[index % this.color.length]);
            borColor.push(this.borderColor[index % this.borderColor.length]);
          });
          this.barChartData.datasets[0].backgroundColor = bgColor;
          this.barChartData.datasets[0].borderColor = borColor;
          // this.pieChartData.datasets[0].backgroundColor = bgColor;
          // this.pieChartData.datasets[0].borderColor = borColor;
          // this.polarChartData.datasets[0].backgroundColor = bgColor;
          // this.polarChartData.datasets[0].borderColor = borColor;
          // this.doughnutChartData.datasets[0].backgroundColor = bgColor;
          // this.doughnutChartData.datasets[0].borderColor = borColor;
          if (this.showValue) {
            this.barGraphOptions.plugins.datalabels.display = true;
            // this.pieGraphOptions.plugins.datalabels.display = true;
            // this.polarGraphOptions.plugins.datalabels.display = true;
            // this.doughnutGraphOptions.plugins.datalabels.display = true;
          } else {
            this.barGraphOptions.plugins.datalabels.display = false;
            // this.pieGraphOptions.plugins.datalabels.display = false;
            // this.polarGraphOptions.plugins.datalabels.display = false;
            // this.doughnutGraphOptions.plugins.datalabels.display = false;
          }
          if (this.toggleStateg)
            this.barGraphOptions.scales.xAxes[0].ticks.display = true;
          else this.barGraphOptions.scales.xAxes[0].ticks.display = false;

          if (result.length > 6) {
            this.barChart.options.chart.xAxis.rotateLabels = -50;
            //  this.barChart.options.chart.xAxis.axisLabel = '';
          }
          this.barChart.data = [{ key: dataQuery.category, values: result }];
          this.graphChange();
          this.loadBarChart = false;
        }
        // , error => {
        //   swal('Oops...', 'Failed to load bar chart.', 'error');
        // }
      );
    }
  }
  graphChange() {
    // if(this.newLineChart)this.newLineChart.destroy();
    if (this.newBarChart) this.newBarChart.destroy();
    // if(this.newPieChart)this.newPieChart.destroy();
    // if(this.newPolarChart)this.newPolarChart.destroy();
    // if(this.newDoughnutChart)this.newDoughnutChart.destroy();
    // if(this.graphSelected === 'LINE CHART')
    // this.initializeLineChart();
    // if(this.graphSelected === 'BAR CHART')
    this.initializeBarChart();
    // if(this.graphSelected === 'PIE CHART')
    // this.initializePieChart();
    // if(this.graphSelected === 'POLAR AREA CHART')
    // this.initializePolarChart();
    // if(this.graphSelected === 'DOUGHNUT CHART')
    // this.initializeDoughnutChart();
  }
  dimChange() {
    this.dimChangeFlag = true;
    this.type = "line_chart";
    if (this.currentSquare == "queryCount") this.queryCountChart();
    else if (this.currentSquare == "queryAvg") this.queryAvgChart();
    else if (this.currentSquare == "jobCount") this.jobCountChart();
    else if (this.currentSquare == "jobBuildTime") this.jobBuildTimeChart();
    // this.dashBoardItemsCount();
    this.getQuery(
      this.formatDatetime(this.selected.startDate),
      this.formatDatetime(this.selected.endDate)
    );
    this.getJobs(
      this.formatDatetime(this.selected.startDate),
      this.formatDatetime(this.selected.endDate)
    );
    const that = this;
    this.options = {
      lineChartOptions: {
        chart: {
          type: "lineChart",
          height: 295,
          margin: {
            top: 20,
            right: 55,
            bottom: 60,
            left: 55,
          },
          showYAxis: true,
          showXAxis: true,
          useInteractiveGuideline: true,
          // interpolate: 'cardinal',
          x: function (d) {
            return d.label;
          },
          y: function (d) {
            return d.value;
          },
          xAxis: {
            axisLabel: this.linechartDimension,
            axisLabelDistance: 50,
            staggerLabels: true,
            tickFormat: function (d) {
              //d = d3.time.format('%d-%m-%y')(new Date(d));
              if (d.length > 10) {
                return d.substring(0, 10) + "...";
              } else {
                var st = moment(new Date(that.selected.startDate)).valueOf();
                var et = moment(new Date(that.selected.endDate)).valueOf();
                var start = moment(st).unix();
                var end = moment(et).unix();
                if (d < start) d = start;
                if (d > end) d = end;
                return moment.unix(d).format("YYYY-MM-DD");
              }
            },
          },
          yAxis: {
            axisLabel: this.graphTitle,
            axisLabelDistance: 50,
            tickFormat: function (d) {
              if (d < 1000) {
                return d;
              } else {
                var prefix = d3.formatPrefix(d);
                return prefix.scale(d) + prefix.symbol;
              }
            },
          },
          valueFormat: function (d) {
            return d;
          },
          transitionDuration: 500,
          tooltip: {
            contentGenerator: function (d) {
              return (
                '<table><tr><td class="legend-color-guide"><div style="background-color: ' +
                d.color +
                ';width:10px;margin-right:5px">&nbsp;</div></td><td class="key">' +
                d.data.label +
                ' : </td><td class="value">' +
                d.data.value +
                "</td></tr></table>"
              );
            },
          },
        },
      },
    };
  }
  calendarChange() {
    this.type = "";
    if (this.currentSquare == "queryCount") this.queryCountChart();
    else if (this.currentSquare == "queryAvg") this.queryAvgChart();
    else if (this.currentSquare == "jobCount") this.jobCountChart();
    else if (this.currentSquare == "jobBuildTime") this.jobBuildTimeChart();
    this.getQuery(
      this.formatDatetime(this.selected.startDate),
      this.formatDatetime(this.selected.endDate)
    );
    this.getJobs(
      this.formatDatetime(this.selected.startDate),
      this.formatDatetime(this.selected.endDate)
    );
    let params = {
      endDate: this.selected.endDate,
      startDate: this.selected.startDate,
    };
    localStorage.setItem("params", JSON.stringify(params));
  }
  showBarchartValue(isChecked: boolean) {
    this.showValue = isChecked;
    this.type = "bar";
    if (this.currentSquare == "queryCount") this.queryCountChart();
    else if (this.currentSquare == "queryAvg") this.queryAvgChart();
    else if (this.currentSquare == "jobCount") this.jobCountChart();
    else if (this.currentSquare == "jobBuildTime") this.jobBuildTimeChart();
    this.getQuery(
      this.formatDatetime(this.selected.startDate),
      this.formatDatetime(this.selected.endDate)
    );
    this.getJobs(
      this.formatDatetime(this.selected.startDate),
      this.formatDatetime(this.selected.endDate)
    );
    this.options.barChartOptions.chart.xAxis.rotateLabels = -50;
  }
  isUserAdmin(authorities) {
    for (let authority of authorities) {
      if (
        authority["authority"] == "ROLE_ADMIN" ||
        authority["authority"] == "ROLE_ANALYST"
      ) {
        return true;
      }
    }
    return false;
  }

  /* toggle overview */
  toggleButton() {
    this.toggleState = !this.toggleState;
  }

  showContainer() {
    return {
      "show-o": this.toggleState,
    };
  }

  toggleButtong() {
    this.toggleStateg = !this.toggleStateg;
  }

  showContainerg() {
    return {
      "show-og": this.toggleStateg,
    };
  }

  showFullscreenGraph() {
    if (this.currentSquare == "queryCount") this.queryCountChart();
    else if (this.currentSquare == "queryAvg") this.queryAvgChart();
    else if (this.currentSquare == "jobCount") this.jobCountChart();
    else if (this.currentSquare == "jobBuildTime") this.jobBuildTimeChart();
    this.dateEnd = this.datePipe.transform(this.selected.endDate, "yyyy-MM-dd");
    this.dateStart = this.datePipe.transform(
      this.selected.startDate,
      "yyyy-MM-dd"
    );
    this.getQuery(this.dateStart, this.dateEnd);
    this.getJobs(this.dateStart, this.dateEnd);
  }
}
