import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormArray, FormBuilder, Validators } from '@angular/forms';

@Component({
  selector: 'app-step-one',
  templateUrl: './step-one.component.html',
  styleUrls: ['./step-one.component.scss']
})
export class StepOneComponent implements OnInit {
  @Input() projectId: string;
  @Input() kafkaFormValue: any;
  @Output() kafkaFormData = new EventEmitter<any>();
  @Output() kafkaFormValid = new EventEmitter<any>();

  kafkaForm: FormGroup;
  load;
  public serverList: FormArray;
  constructor(private fb: FormBuilder) {
    this.kafkaForm = fb.group({
      topic: ['', [Validators.required]],
      servers: this.fb.array([], [Validators.required]) ,
    });
    this.serverList = this.kafkaForm.get('servers') as FormArray;
   }
  ngOnInit() {
    if (this.kafkaFormValue !== undefined) {
      this.kafkaFormValue.servers.forEach(element => {
        this.addServer();
      });
      this.kafkaForm.patchValue(this.kafkaFormValue);
    } else {
       this.addServer();
    }
    this.kafkaForm.valueChanges.subscribe(val => {
      this.kafkaFormValid.emit(this.kafkaForm.valid);
      this.kafkaFormData.emit(this.kafkaForm.value);
    });
  }
  // server() : FormArray {
  //   return this.kafkaForm.get("servers") as FormArray
  // }
  get server() {

    return this.kafkaForm.get('servers') as FormArray;

  }
  newServer(): FormGroup {
    return this.fb.group({
      host: ['', [Validators.required]],
      port: ['', [Validators.required, Validators.pattern('^[0-9]*$')]],
    });
  }

  addServer() {
    this.serverList.push(this.newServer());
  }

  removeServer(i: number) {
    this.serverList.removeAt(i);
  }

}
