import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
@Injectable({
  providedIn: 'root'
})
export class SidebarService {
  menuType='home';
  constructor(private route: ActivatedRoute) {
 
   }
  
   projectItems = [
    { path: 'dashboard', title: 'Dashboard' },
    { path: 'data-sources', title: 'Data Sources' },
    { path: 'models', title: 'Models' },
    { path: 'cubes', title: 'Cubes' },
    { path: 'jobs', title: 'Jobs' },
    { path: 'scheduler', title: 'Scheduler' },
    { path: 'query', title: 'Insights' },
    { path: 'project-access', title: 'Access' }
];

// projectItems = [
//   { path: 'dashboard', title: 'Dashboard' },
//   { path: 'data-sources', title: 'Data Sources' },
//   { path: 'models', title: 'Models' },
//   { path: 'cubes', title: 'Cubes' },
//   { path: 'jobs', title: 'Jobs' },
//   { path: 'query', title: 'Insights' },
//   { path: 'project-access', title: 'Access' }
// ];

adminItems = [
  { path: '/dashboard', title: 'Home' },
  { path: '/projects', title: 'Projects' },
  { path: '/administration', title: 'Administration' },
  { path: '/profile', title: 'Profile' },
];
items = [
  { path: '/dashboard', title: 'Home' },
  { path: '/projects', title: 'Projects' },
  { path: '/profile', title: 'Profile' },
];
SetMenuType(type){
  if(type=='admin' || type=='user')
    this.menuType='home';
  else if(type=='project')
    this.menuType='project';
}
}
