import { Injectable } from '@angular/core';
import { HttpClient,HttpParams } from '@angular/common/http';
import { Config } from '../../config';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

   static url = Config.url + '/notifications';
   dataSource = new BehaviorSubject<boolean>(null);
  constructor(private http: HttpClient) { }
  

  getNotifications(params){
    return this.http.get( NotificationService.url+'/username',{params});
  }
  viewedNotifications(id){
    return this.http.put( NotificationService.url+'/'+id+'/status/true',[]);
  }
  clearAllNotifications(params){
    return this.http.put( NotificationService.url+'/'+params.username+'/true',[]);
  }
}
