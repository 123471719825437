import { Component, OnInit,Input, Output, EventEmitter } from '@angular/core';
import { TableService } from 'src/app/services/table/table.service';
import { Subscription } from 'rxjs';
import { OrderPipe } from 'ngx-order-pipe';
@Component({
  selector: 'app-columns',
  templateUrl: './columns.component.html',
  styleUrls: ['./columns.component.scss']
})
export class ColumnsComponent implements OnInit {
  filter;
  @Input() selectedTable;
  @Output() filterEvent: EventEmitter<any>= new EventEmitter();
  activeColumn;
  active:boolean;
  searchText:string;
  subscription: Subscription;
  order: string = 'columnName';
  reverse: boolean = true;
  sortedCollection;
  constructor(private tableService: TableService,private orderPipe: OrderPipe) { 
   
  }
  setOrder(value: string) {
    if (this.order === value) {
      this.reverse = !this.reverse;
    }

    this.order = value;
  }
  ngOnInit() {
   //localStorage.clear();
    this.searchText=null;
    this.subscription = this.tableService.getActiveColumn().subscribe(column => this.getActiveClass());
  }
  getActiveClass(){
    this.activeColumn=localStorage.getItem('activeColumn');
  }
  searchFilter(filter){
    this.filterEvent.emit(filter)
  }
}
