import { Component, OnInit,Input } from '@angular/core';
import { OrderPipe } from 'ngx-order-pipe';
@Component({
  selector: 'app-snapshot',
  templateUrl: './snapshot.component.html',
  styleUrls: ['./snapshot.component.scss']
})
export class SnapshotComponent implements OnInit {

  @Input() selectedTable;
  snapMessage;
  showModal;
  order: string = 'snapshotLastBuild';
  reverse: boolean = true;
  sortedCollection;
  constructor(private orderPipe: OrderPipe) { }
  setOrder(value: string) {
    if (this.order === value) {
      this.reverse = !this.reverse;
    }

    this.order = value;
  }
  ngOnInit() {
  }
  getUsageInfo(msg){
  
    if(!msg.snapshotCubesAndSegmentUsage || msg.snapshotCubesAndSegmentUsage.length<=0)
      this.snapMessage= 'No Usage Info ';
    else{
      let text='';
      for(let param of msg.snapshotCubesAndSegmentUsage){
        text+=param+'\n';
      }
      this.snapMessage= text;
    }
    this.showModal=true;
  }
  hide(){
    this.showModal=false;
  }
}
