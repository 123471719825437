import { Component, OnInit } from '@angular/core';
import { CubesService } from '../../../../../services/cubes/cubes.service';
@Component({
  selector: 'app-visual-cube-step7',
  templateUrl: './visual-cube-step7.component.html',
  styleUrls: ['./visual-cube-step7.component.scss']
})
export class VisualCubeStep7Component implements OnInit {
  cubeMeta;
  model;
  constructor(private cubesService:CubesService) { }

  ngOnInit() {
    this.cubeMeta=this.cubesService.cubeMeta;
    this.model=this.cubesService.model;
  }
  getCounts(flag){
    if(this.cubesService.visualCubeEditMode == true){
      this.cubeMeta=this.cubesService.cubeMeta;
      this.model=this.cubesService.model;
    }
    let lookup=[];
    let measures=[];
    if(flag=='lookup'){
      for(let param of this.cubeMeta.dimensions)
      {
        if(lookup.indexOf(param.table)==-1)
          lookup.push(param.table);
      }
      return lookup.length;
    } 
    
    if(flag=='dimensions'){
      return (this.cubesService.cubeMeta.dimensions).length;
    }
     if(flag=='measures'){
    
      return (this.cubesService.measure).length;
    }
  }
}
