import { Component, OnInit } from '@angular/core';
import { DataRequestService } from '../../../services/data-access/data-request.service';
import { UserSettingsService } from '../../../services/users/user-settings.service';
import { DataAccessService } from '../../../services/data-access/data-access.service';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, FormArray, FormControl, Validators } from "@angular/forms";
import swal from 'sweetalert2';
import { NgForm } from "@angular/forms";
import { FormsModule } from '@angular/forms';

@Component({
  selector: 'app-data-catalog-details',
  templateUrl: './data-catalog-details.component.html',
  styleUrls: ['./data-catalog-details.component.scss']
})
export class DataCatalogDetailsComponent implements OnInit {
  dataRequest;
  dataList;
  dataListall;
  authUser;
  currentPage = 1;
  selectAll: boolean;
  allSelected: boolean;
  remarks: String;
  contentListall: any;
  columns = [];
  requestObj = [];
  uuid: string;
  checkStatus: boolean;
  remarksError: boolean;
  showRequestcheck: false;
  p:number=1;
  constructor(private formBuilder: FormBuilder, private dataRequestService: DataRequestService, private dataAccessService: DataAccessService, private userSettingService: UserSettingsService, private router: Router) { }
  grantAll() {
    for (var i in this.contentListall) {
      this.contentListall[i].selected = this.selectAll;
    }
  }
  approveRequest(dataRequest) {
 
    this.checkStatus = true;
    var requestId = dataRequest[0].dataAccessRequestId;
    for (var i in dataRequest) {
      if (this.allSelected || dataRequest[i].selected === true) {
       
        var dataRequestDtlObj = {
          "status": dataRequest[i].status,
          "uuid": dataRequest[i].uuid
        };
        
        this.requestObj.push(dataRequestDtlObj);
        dataRequest[i].showRequestcheck = true;
        this.checkStatus = false;
      }
    }
    if (this.checkStatus == false) {
      // if (this.remarks == null) {
      //   this.remarksError = true;
      //   return;
      // }else
      this.remarksError = false;
      var param = {
        "DataRequestDetails": this.requestObj,
        "approverName": this.authUser.username,
        "rejectReason": this.remarks,
        "status": true
      }

      this.dataRequestService.ApproveorRejectRequest(requestId, param).subscribe(data => {
        swal('Success!', 'The request has been approved', 'success');
        this.router.navigate(["requests"]);
      }, (error) => {
        swal("Failed", 'Some error occured', "error");
      }
      );
    }
    else {
      swal('Please select at least one column.');
    }
  }
  rejectRequest(dataRequest) {
    for (var i in dataRequest) {
      var column = {
        "uuid": dataRequest[i].uuid,
        "status": false
      };
      this.columns.push(column);
    }
    var param = {
      "DataRequestDetails": this.columns,
      "rejectedBy": this.authUser.username,
      "rejectReason": this.remarks,
      "status": false
    }

    if (this.remarks == null) {
      this.remarksError = true;
    } else {
      var requestId = dataRequest[0].dataAccessRequestId;
      this.dataRequestService.ApproveorRejectRequest(requestId, param).subscribe(data => {
        swal('Success!', 'The request has been rejected', 'success');
        this.remarksError = false;
        this.router.navigate(["requests"]);
      }, (error) => {
        swal("Failed", 'Some error occured', "error");
      }
      );
    }

  }
  onGoBack() {
    this.router.navigate(["requests"]);
  }
  ngOnInit() {
    this.remarksError = false;
    this.allSelected = false;
    this.authUser = JSON.parse(sessionStorage.getItem("loggedUser"));
    this.dataRequest = JSON.parse(sessionStorage.getItem('dataRequest'));
    this.remarks = this.dataRequest.remarks;
    this.uuid = this.dataRequest.uuid;
    this.dataRequestService.getRequestById(this.dataRequest.uuid).subscribe(data => {
      this.dataListall = data;
      this.contentListall = this.dataListall.dataRequestDetailList;
      if (this.dataRequest.isCompleted === true) {
        this.dataRequest.statusText = 'Completed';
      } else {
        this.dataRequest.statusText = 'Pending';
      }
    });
  }
  getCheckStatus(request){
     
    let status;
    for(let req of request){
      if(!req.selected){
        this.selectAll=false;
        return;
      }
    }
    this.selectAll=true;
  }
}
