import { Component, OnInit, AfterViewInit } from '@angular/core';
import { AdminService } from '../../services/admin/admin.service';
import { CacheService } from '../../services/cache/cache.service';
import swal from 'sweetalert2';
import * as $ from 'jquery';
import { FormGroup,  FormControl, FormBuilder,  Validators } from '@angular/forms';
import { CommonServiceService } from '../../services/common-services/common-service.service';


@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss']
})
export class SettingsComponent implements OnInit,AfterViewInit {
  loggedUser;
  env;
  config;
  showModal:boolean;
  configForm: FormGroup;
  load=false;
  qubzLicenseExpire;
  configSplitted: any;
  lhs: any;
  status: any;
  constructor(private adminService:AdminService,private cacheService:CacheService,private fb: FormBuilder, public commonServiceService:CommonServiceService) {
    this.loggedUser = JSON.parse(sessionStorage.getItem("loggedUser"));
   }

  ngAfterViewInit() {
    $(document).ready(function(){
          var contenth = $( window ).height() - 110 ;
          var sidebarh = $( window ).height() - 111 ;
          $(".pagec").css("height", contenth );
          $(".sidebar-wrapper").css("height", sidebarh );
        });
    }
    
  ngOnInit() {
    this.qubzLicenseExpire=JSON.parse(sessionStorage.getItem('qubzLicenseExpire'));
    this.adminService.env().subscribe(
      data => {
        let res= <any>{};
        res=data;
         this.env = res.env;
      }
  );
    this.serverConfig();
    this.createForm();
    //this.saveLogs();
  }
  serverConfig(){
    this.load=true;
    this.adminService.config().subscribe(
      data => {
        let res= <any>{};
        res=data;
         this.config = res.config;
         //QUBZ-6 - Hided enable cache and disable cache button based on backend status - 22/11/22
         this.configSplitted = this.config.split("\n");
         let i = 0;
         for(i=0;i<this.configSplitted.length;i++){
          if(this.configSplitted[i].split("=")[0] === 'qubz.query.cache-enabled' && this.configSplitted[i].split("=")[1] === 'true'){
            console.log(this.configSplitted[i].split("="));
            this.status = true;
            break;
          }else{
            this.status = false;
          }
         }
        this.load=false;
      }
  );
  }
  hide(configForm){
    this.showModal=false;
    configForm.reset();
  }
  setConfig(){
    this.showModal=true;
  }
  createForm() {
    this.configForm = this.fb.group({
       key: ['', [Validators.required]],
       value: ['',[Validators.required]]
    });
  }
  getEnv() {
    this.adminService.env().subscribe(
      data => {
        let res= <any>{};
        res=data;
         this.env = res.env;
         swal('Success!', 'Server environment refreshed successfully', 'success');
      }
  );
  }
  getConfig() {
    this.adminService.config().subscribe(
      data => {
        let res= <any>{};
        res=data;
         this.config = res.config;
         swal('Success!', 'Server config refreshed successfully', 'success');
      }
  );
  }
  reloadMetaData(){
    swal({
      type:'warning',
      title: 'Are you sure you want to reload metadata and clean cache?',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No'
    }).then((status) => {
      if(status.value==true){
        let event={};
        this.cacheService.clean('all','all','update',event).subscribe(
          data => {
            swal('Success!', 'Cache reload successfully', 'success');
          }, function (e) {
            if (e.data && e.data.exception) {
              var message = e.data.exception;
              var msg = !!(message) ? message : 'Failed to take action.';
              swal('Oops...', msg, 'error');
            } else {
              swal('Oops...', "Failed to take action.", 'error');
            }
          });
      }
    });
  }
  cleanStorage () {
    swal({
      type:'warning',
      title: 'Are you sure you want to clean up unused HDFS and HBase space?',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No'
    }).then((status) => {
      if(status.value==true){
        this.adminService.cleanStorage().subscribe(
          data => {
            swal('Success!', 'Storage cleaned successfully', 'success');
          }, function (e) {
          if (e.data && e.data.exception) {
            var message = e.data.exception;
            var msg = !!(message) ? message : 'Failed to take action.';
            swal('Oops...', msg, 'error');
          } else {
            swal('Oops...', "Failed to take action.", 'error');
          }
        });
      }
    });
  }
  disableCache () {
    swal({
      type:'warning',
      title: 'Are you sure you want to disable query cache?',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No'
    }).then((status) => {
      if(status.value==true){
        const config = {
          "key": 'qubz.query.cache-enabled',
          "value": false
      };
        this.adminService.updateConfig(config).subscribe(
            data => {
              swal('Success!', 'Cache disabled successfully', 'success');
              this.serverConfig();
            }, function (e) {
            if (e.data && e.data.exception) {
              var message = e.data.exception;
              var msg = !!(message) ? message : 'Failed to take action.';
              swal('Oops...', msg, 'error');
              this.serverConfig();
            } else {
              swal('Oops...', "Failed to take action.", 'error');
              this.serverConfig();
            }
          });
        }
      });
  }
  enableCache (){
    swal({
      type:'warning',
      title: 'Are you sure you want to enable query cache?',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No'
    }).then((status) => {
      if(status.value==true){
        const config = {
          "key": 'qubz.query.cache-enabled',
          "value": true
      };
        this.adminService.updateConfig(config).subscribe(
            data => {
              swal('Success!', 'Cache enabled successfully', 'success');
              this.serverConfig();
            }, function (e) {
            if (e.data && e.data.exception) {
              var message = e.data.exception;
              var msg = !!(message) ? message : 'Failed to take action.';
              swal('Oops...', msg, 'error');
              this.serverConfig();
            } else {
              swal('Oops...', "Failed to take action.", 'error');
              this.serverConfig();
            }
          });
        }
      });
  }
  updateConfig () {
    const config = {
      "key": this.configForm.value['key'],
      "value": this.configForm.value['value']
  };
    this.adminService.updateConfig(config).subscribe(
      data => {
        
        swal('Success!', 'Config updated successfully', 'success');
        this.serverConfig();
        this.hide(this.configForm);
      }, function (e) {
        if (e.data && e.data.exception) {
          var message = e.data.exception;
          var msg = !!(message) ? message : 'Failed to take action.';
          swal('Oops...', msg, 'error');
        } else {
          swal('Oops...', "Failed to take action.", 'error');
        }
      });
  }
  reloadConfig() {
    swal({
      type:'warning',
      title: 'Are you sure you want to reload Config?',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No'
    }).then((status) => {
      if(status.value==true){
        let config={};
        this.cacheService.reloadConfig(config).subscribe(
          data => {
            swal('Success!', 'Config reloaded successfully', 'success');
         
        }, function (e) {
          if (e.data && e.data.exception) {
            var message = e.data.exception;
            var msg = !!(message) ? message : 'Failed to take action.';
            swal('Oops...', msg, 'error');
          } else {
            swal('Oops...', "Failed to take action.", 'error');
          }
        });
      }
    });
  }

  saveLogs(){
    var today = new Date();
    let param={
      username:this.loggedUser.username,
      targetPage:'administration/settings',
      day:today.getDay(),
      month:today.getMonth(),
      year:today.getFullYear()
    };
    this.commonServiceService.saveLogs(param).subscribe(
      data => {
  
      });
  }
}
