import { Injectable } from '@angular/core';
import { HttpClient,HttpParams } from '@angular/common/http';
import { Config } from '../../config';

@Injectable({
  providedIn: 'root'
})
export class ProjectDashboardService {

  constructor(private http: HttpClient) { }

 
  getCatalogsCount(){
    return this.http.get(Config.url+'/datacatalogs?status=true');
  }
  getModelsCount(projectName){
    return this.http.get(Config.url+'/models?projectName='+projectName);
  }
  getCubesCount(projectName){
    return this.http.get(Config.url+'/cubes?projectName='+projectName);
  }
  getDataSourceCount(projectName){
    return this.http.get(Config.url+'/tables?project='+projectName);
  }
  getCubeMetrics(projectName){
    return this.http.get(Config.url+'/dashboard/metric/cube?projectName='+projectName);
  }
  getQueryMetrics(projectName,selectedCube,start,end){
    const params = new HttpParams()
    .set('projectName', projectName)
    .set('cubeName', selectedCube)
    .set('startTime', start)
    .set('endTime', end);
  return this.http.get(Config.url+'/dashboard/metric/query',{params});
}
getJobMetrics(projectName,selectedCube,start,end) {
  const params = new HttpParams()
    .set('projectName', projectName)
    .set('cubeName', selectedCube)
    .set('startTime', start)
    .set('endTime', end);
  return this.http.get(Config.url+'/dashboard/metric/job', {params});
}
getChartData(param) {
  const params = new HttpParams()
  .set('startTime', param.startTime)
  .set('cubeName', param.cubeName)
  .set('projectName', param.projectName)
  .set('endTime', param.endTime);
    return this.http.get(Config.url+ '/dashboard/chart/'+param.category+'/'+param.metric+'/'+param.dimension,{params});
 
}
}