import { Component, OnInit, EventEmitter } from "@angular/core";
import { AuthService } from "../../services/auth/auth.service";
import { TemplateServiceService } from "../../services/template/template-service.service";
import {
  FormGroup,
  FormControl,
  Validators,
  FormBuilder,
  ValidatorFn,
  AbstractControl,
} from "@angular/forms";

import { Users } from "../../models/users/users.model";
import { Router } from "@angular/router";
import { AdminService } from "../../services/admin/admin.service";
declare var jQuery: any;
//sonaqube-commented unused object instantiation
declare var setTheme: any;
declare function addPendo(): any;
@Component({
  selector: "app-auth",
  templateUrl: "./auth.component.html",
  styleUrls: ["./auth.component.scss"],
})
export class AuthComponent implements OnInit {
  form: FormGroup;
  errMsg: string = "";
  public user: Users;
  public input1: any;
  config;
  loggedUser;
  timezone;
  userNameDetails;
  public focusSettingEventEmitter = new EventEmitter<boolean>();
  public user1: Users;
  show_pwd: boolean = true;
  typePassword;
  submitted: boolean = false;
  constructor(
    private authService: AuthService,
    fb: FormBuilder,
    private router: Router,
    private templateServiceService: TemplateServiceService,
    private adminService: AdminService
  ) {
    let user = sessionStorage.getItem("loggedUser");
    if (user) {
      this.router.navigateByUrl("");
    }
  }
  //  focusFunction(){
  //   this.errMsg ='';
  //  }

  togglePassword() {
    this.typePassword = !this.typePassword;
  }

  removeError() {
    this.errMsg = "";
  }

  ngOnInit() {
    this.form = new FormGroup({
      username: new FormControl(null, [Validators.required]),
      password: new FormControl(null, [Validators.required]),
    });
    this.form.reset();
    this.errMsg = "";
  }

  ngAfterViewInit() {
    jQuery(".loginInput").focus(function () {
      jQuery(jQuery(this).addClass("increasewidth"));
    });
    jQuery(".loginInput").focus(function () {
      jQuery(jQuery(this).addClass("increasewidth"));
    });

    jQuery(".loginInput").focusout(function () {
      jQuery(jQuery(this).removeClass("increasewidth"));
    });
    this.focusSettingEventEmitter.emit(true);
  }
  setFocus(): void {
    this.focusSettingEventEmitter.emit(true);
  }
  initData(value) {
	
			let user = {
				username:           value.username,   // Required if user is logged in
			  emailId:        value.emailId,// Recommended if using Pendo Feedback, or NPS Email
			  displayName:    value.displayName,// Recommended if using Pendo Feedback
			  authorities:         value.authorities[0].authority,// Optional
			};
			sessionStorage.setItem("loggedUserData", JSON.stringify(user));
			addPendo();
	
	}

  login() {
    this.submitted =true;
    const credentials = {
      username: this.form.value.username,
      password: this.form.value.password,
    };

    sessionStorage.setItem("credentials", JSON.stringify(credentials));

    this.authService.login().subscribe(
      (data) => {
        if (data.hasOwnProperty("userDetails")) {
          this.input1 = data;
          this.user = new Users(this.input1.userDetails);
          if (
            this.user.enabled === true &&
            this.user.disabled === false &&
            this.user.accountNonExpired === true &&
            this.user.credentialsNonExpired === true &&
            this.user.accountNonLocked === true
          ) {
            //sessionStorage.setItem("loggedUser",JSON.stringify(this.user));
            this.authService
              .getDetailsbyUserName(credentials.username)
              .subscribe(
                (data) => {
                  if (data.hasOwnProperty("data")) {
                    this.userNameDetails = data;
                    this.user1 = new Users(this.userNameDetails.data);
                    if (this.user1.disabled === false) {
                      sessionStorage.setItem(
                        "loggedUser",
                        JSON.stringify(this.user1)
                      );
                      
                      if (this.isUserAdmin(this.user1.authorities)) {
                        this.adminService.config().subscribe((data) => {
                          let res = <any>{};
                          res = data;
                          this.config = res.config;
                          let configArray = this.config.split("\n");
                          for (let i = 0; i < configArray.length; ++i) {
                            if (configArray[i].includes("qubz.web.timezone")) {
                              this.timezone = configArray[i].substring(
                                configArray[i].indexOf("=") + 1
                              );
                              this.timezone = this.timezone.trim();
                            }
                          }
                          this.adminService.setTimeZone(this.timezone);
                          localStorage.setItem("configTimezone", this.timezone);
                        });
                      } else {
                        this.adminService.public_Config().subscribe((data) => {
                          let res = <any>{};
                          res = data;
                          this.config = res.config;
                          let configArray = this.config.split("\n");
                          for (let i = 0; i < configArray.length; ++i) {
                            if (configArray[i].includes("qubz.web.timezone")) {
                              this.timezone = configArray[i].substring(
                                configArray[i].indexOf("=") + 1
                              );
                              this.timezone = this.timezone.trim();
                            }
                          }
                          this.adminService.setTimeZone(this.timezone);
                          localStorage.setItem(
                            "configTimezone",
                            this.timezone.trim()
                          );
                        });
                      }
                      this.templateServiceService
                        .getSavedTemplate()
                        .subscribe((data) => {
                          let res = <any>{};
                          res = data;

                          if (res.qubzUser.colorTheme == "BLUE")
                            sessionStorage.setItem("name", "blue_theme");
                          else if (res.qubzUser.colorTheme == "RED")
                            sessionStorage.setItem("name", "red_theme");
                          else if (res.qubzUser.colorTheme == "BLACK")
                            sessionStorage.setItem("name", "grey_theme");
                          else if (res.qubzUser.colorTheme == "TEAL")
                            sessionStorage.setItem("name", "teal_theme");
                            this.initData(this.user1);
                          //sonaqube-commented unused object instantiation
                          new setTheme();
                        });

                      if (!this.userNameDetails.data.eula) {
                        this.router.navigate(["eula"]);
                      } else this.router.navigate(["dashboard"]);
                    }
                  }
                },
                (error) => {
                  console.log("Error while fetching user details", error);
                }
              );
          }
        }
        // else {
        //     this.router.navigate([""])
        //  }
      },

      (error) => {
        this.errMsg = "Unable to login, please check your username/password.";
      }
    );
  }

  isUserAdmin(authorities) {
    for (let authority of authorities) {
      if (authority["authority"] == "ROLE_ADMIN") {
        return true;
      }
    }
    return false;
  }
  ping() {
    this.authService.ping().subscribe();
  }

  //   getAuthorities() {
  //     this.authService.authorities(AuthService.url).subscribe();
  //   }
  //QUBZ-3933-in Login Page two error messages should not display-15/11/22
  clearErrorMsg(){
    this.errMsg = '';
  }
}
