import {
  Component,
  OnInit,
  ViewChild,
  AfterViewInit,
  Input,
  OnDestroy,
  Output, EventEmitter
} from '@angular/core';

import { CompleterService } from '../../services/ace-editor/completer.service';
import { MetaInfoModel } from './../models/metadata.model';

import * as ace from 'brace';
import 'brace/mode/sql';
import 'brace/ext/language_tools';
import 'brace/snippets/sql';
import 'brace/theme/eclipse';

@Component({
  selector: 'app-editor',
  templateUrl: './editor.component.html',
  styleUrls: ['./editor.component.css']
})
export class EditorComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input()
  metaInfo: MetaInfoModel[];
  mainQuery;
  options = {
    enableBasicAutocompletion: true,
    enableLiveAutocompletion: true,
  //  enableSnippets: true
  };
  @Output() queryEvent = new EventEmitter<string>();
  @ViewChild('editor') editor;


  constructor(private completer: CompleterService) {}

  ngOnInit() {
    this.completer.setMetadata(this.metaInfo);
  }
  onChange(mainQuery) {
    this.queryEvent.emit(mainQuery);
}
  ngAfterViewInit() {
    const langTools = ace.acequire('ace/ext/language_tools');

    langTools.setCompleters([this.completer]);
  }

  ngOnDestroy(): void {
    const langTools = ace.acequire('ace/ext/language_tools');
    const { textCompleter, keyWordCompleter, snippetCompleter } = langTools;
    langTools.setCompleters([
      textCompleter,
      keyWordCompleter,
      snippetCompleter
    ]);
  }

  getEditor(): ace.Editor {
    return this.editor._editor;
  }

  clearQuery(){
    this.mainQuery="";
  }
}
