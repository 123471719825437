import { Injectable, isDevMode } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Config } from '../../config';
import { Router, ActivatedRoute } from '@angular/router';
@Injectable({
  providedIn: 'root'
})
export class AuthService {

  static url = Config.url + '/user/authentication';

  constructor(private http: HttpClient, private router: Router,private route: ActivatedRoute) { }
  ping() {
    return this.http.post(AuthService.url, "");
    // return this.http.get(AuthService.url);
  }

  login() {
    return this.http.post(AuthService.url, "");
  }
 
  logout() {
    if(isDevMode()) {
      this.router.navigateByUrl('');
    } else {
      window.location.href=window.location.origin + "/j_spring_security_logout";
    }
  }
  logged_in() {
    return !!sessionStorage.getItem('loggedUser')
  }

  /* may be deprecated*/
  authorities() {
    const params = {
      action: "authorities"
    }
    return this.http.get(AuthService.url, {params});
  }  

  getDetailsbyUserName(userName){
    return this.http.get(Config.url + '/user/' + userName + '/')
  }
}
