import { Component, Input, OnInit, AfterViewInit, Output, EventEmitter, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, FormArray, Validators } from '@angular/forms';
import { StreamingService } from 'src/app/services/streaming/streaming.service';
import * as $ from 'jquery';

@Component({
  selector: 'app-configure-streaming',
  templateUrl: './configure-streaming.component.html',
  styleUrls: ['./configure-streaming.component.scss']
})
export class ConfigureStreamingComponent implements OnInit, AfterViewInit, OnDestroy {
  load;
  @Input() projectId: string;
  @Output() jsonFormData = new EventEmitter<FormGroup>();
  @Output() jsonFormDataValid = new EventEmitter<boolean>();

  jsonCodeSnippet: string;
  jsonCheck: boolean = true;
  jsonInput: boolean = true;
  jsonArray: any[];
  jsonForm: FormGroup;
  tsColumnArr: any[] = [];
  tsParserArr: any[] = ['com.ustglobal.qubz.stream.source.kafka.LongTimeParser', 'com.ustglobal.qubz.stream.source.kafka.DateTimeParser'];
  tsPatternArr: any = [];
  constructor(private fb: FormBuilder, private streamingService: StreamingService) { }

  ngAfterViewInit() {
    $(document).ready(function () {
      function adjustheight() {
        //$(".phasebtn").on("click", function(){
        var puconetntheight = $(window).height() - 360;
        $(".config_table tbody").css("max-height", puconetntheight);
        //});

        // removed below code **** getting ace_content height as 206 from some where 
        // getting the issue after typing in ace editor
        // working fine after window resize. 
        /* var editorheight = $(window).height() - 450;
        $("#json_editor").css("height", editorheight);

        var ace_content = $(window).height() - 446;
        $(".ace_content").css("height", ace_content); */

      }

      setTimeout(adjustheight, 2);

    })


    $(window).resize(function () {
      
      /* var editorheight = $(window).height() - 450;
      $("#json_editor").css("height", editorheight);

      var ace_content = $(window).height() - 446;
      $(".ace_content").css("height", ace_content); */

      var puconetntheight = $(window).height() - 360;
      $(".config_table tbody").css("max-height", puconetntheight);
    })
  }
  ngOnDestroy() {
    this.streamingService.setFormData({ json: this.jsonCodeSnippet, data: this.jsonForm.value });
  }
  ngOnInit() {
    this.createJsonForm();
    if (this.streamingService.getFormData() !== undefined) {
      if (this.streamingService.getFormData() && this.streamingService.getFormData().json !== undefined) {
        this.jsonCodeSnippet = this.streamingService.getFormData().json;
      }
      this.streamingService.getFormData().data.tableColumns.forEach(element => {
        this.addColumn();
      });
      this.jsonForm.patchValue(this.streamingService.getFormData().data);
    }
  }
  createJsonForm() {
    this.jsonForm = this.fb.group({
      tableName: ['', Validators.required],
      tsColumn: ['', Validators.required],
      tsParser: ['', Validators.required],
      tsPattern: ['', Validators.required],
      tableColumns: this.fb.array([])

    });
    this.jsonForm.get('tsParser').valueChanges.subscribe(val => {
      if (val === 'com.ustglobal.qubz.stream.source.kafka.LongTimeParser') {
        this.tsPatternArr = ['MS', 'S'];
        this.jsonForm.get('tsPattern').setValue(this.tsPatternArr[0]);
      } else {
        this.streamingService.getDateTimePatternForV2().subscribe(data => {
          this.tsPatternArr = data;
          this.jsonForm.get('tsPattern').setValue(this.tsPatternArr[2]);
        })
      }
    })
    this.jsonForm.get('tsParser').setValue('com.ustglobal.qubz.stream.source.kafka.LongTimeParser');
    this.jsonForm.valueChanges.subscribe(val => {
      this.jsonFormData.emit(this.jsonForm);
      this.jsonFormDataValid.emit(this.checkValid());

    })
  }
  checkValid() {
    if (this.jsonForm.controls.tableName.valid && this.jsonForm.controls.tsColumn.valid && this.jsonForm.controls.tsParser.valid && this.jsonForm.controls.tsPattern.valid) {
      return false;
    } else {
      return true;
    }
  }


  get tableNameForm() {
    return this.jsonForm.controls;
  }
  tableColumns(): FormArray {
    return this.jsonForm.get('tableColumns') as FormArray;
  }
  newColumn(): FormGroup {
    return this.fb.group({
      columnName: '',
      columnType: '',
      mapping: '',
      comment: '',
      id: ''
    })
  }
  addColumn() {
    this.tableColumns().push(this.newColumn());

  }
  columnData(name, type, mapping, comment, id): FormGroup {
    return this.fb.group({
      columnName: name,
      columnType: type,
      mapping: mapping,
      comment: comment,
      id: id
    })
  }
  removeColumn(i: number) {
    this.tableColumns().removeAt(i);
  }

  parseJSON() {
    this.tsColumnArr = [];
    if (this.jsonArray !== []) {
      this.jsonArray = []
    }
    if (this.jsonCodeSnippet != undefined) {
      this.jsonCheck = true
    } else {
      this.jsonCheck = false
    }
    let table;
    this.jsonInput = false;
    try {
      table = JSON.parse(this.jsonCodeSnippet)
      this.jsonInput = true;
    } catch (e) {
      this.jsonInput = false;
    }

    if (this.jsonInput) {
      this.flattenTables(table, "", false)
    }
  }
  flattenTables(table, sourceCol, recursiveCall) {
    let index = 0;
    for (let colName in table) {
      let dType: string = '';
      let comment = '';
      if (typeof table[colName] === 'object') {
        this.flattenTables(table[colName], colName, true)
      }
      if (typeof table[colName] === 'number') {
        if (table[colName] % 1 === 0 && table[colName].toString().length <= 12) {
          dType = 'int'
        } else if (table[colName] % 1 !== 0 && table[colName].toString().length <= 12) {
          dType = 'decimal'
        } else if (table[colName].toString().length > 12) {
          dType = 'timestamp';
          this.tsColumnArr.push(colName);
        }
      } else if (typeof table[colName] === 'string' && table[colName].length <= 256) {
        //this is where we will check for date string
        let isDate = new Date(table[colName])
        var regexp = new RegExp("^(012[1-9]|3[0-1]){1}[/-]?(0[1-9]|1[0-2]){1}[/-]?((19|20)?[0-9][0-9])")
        var regexp2 = new RegExp("^(0[1-9]|1[0-2]){1}[/-]?(012[1-9]|3[0-1]){1}[/-]?((19|20)?[0-9][0-9])")
        if (isDate.toString() != 'Invalid Date' || regexp.test(table[colName]) || regexp2.test(table[colName])) {
          dType = 'date';
        } else {
          dType = 'varchar(256)';
        }
      } else if ((typeof table[colName] === 'string' && table[colName].length > 256)) {
        dType = 'string';
      }
      if (dType !== '') {
        if (recursiveCall) {
          this.jsonArray.push({ id: index, name: sourceCol + '_' + colName, comments: sourceCol + '|' + colName + ' ' + comment, datatype: dType, isChecked: 'true' })
          index++;
        } else {
          this.jsonArray.push({ id: index, name: colName, comments: comment, datatype: dType, isChecked: 'true' })
          index++;
        }
      }
    }
    if (!recursiveCall) {
      this.jsonArray.push({ id: ++index, name: 'year_start', comments: "derived time dimension", datatype: 'date', isChecked: 'true' })
      this.jsonArray.push({ id: ++index, name: 'quarter_start', comments: "derived time dimension", datatype: 'date', isChecked: 'true' })
      this.jsonArray.push({ id: ++index, name: 'month_start', comments: "derived time dimension", datatype: 'date', isChecked: 'true' })
      this.jsonArray.push({ id: ++index, name: 'week_start', comments: "derived time dimension", datatype: 'date', isChecked: 'true' })
      this.jsonArray.push({ id: ++index, name: 'day_start', comments: "derived time dimension", datatype: 'date', isChecked: 'true' })
      this.jsonArray.push({ id: ++index, name: 'hour_start', comments: "derived time dimension", datatype: 'timestamp', isChecked: 'true' })
      this.jsonArray.push({ id: ++index, name: 'minute_start', comments: "derived time dimension", datatype: 'timestamp', isChecked: 'true' })
    }
    this.mapTableColumn();
  }
  checkTimeTsvalue(){
    let tscolumn=[];
    for(let row of this.jsonForm.controls.tableColumns.value){
      if(row.columnType=='timestamp'){
        tscolumn.push(row.columnName);
      }
    }
    this.tsColumnArr=tscolumn;
  }
  mapTableColumn() {
    while(this.tableColumns().length !== 0) this.tableColumns().removeAt(0);
    this.jsonArray.forEach(item => {
      if (item.comments === '') { item.comments = item.name }
      this.tableColumns().push(this.columnData(item.name, item.datatype, '', item.comments, item.id));
    })
  }
}
