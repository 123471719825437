import { Component, OnInit, AfterViewInit, OnDestroy } from "@angular/core";
import { ModelService } from "../../../../../services/model/model.service";
import { CubesService } from "../../../../../services/cubes/cubes.service";
import { CubeDescriptionService } from "../../../../../services/cubes/cube-description.service";
import { ProjectService } from "../../../../../services/project/project.service";
import swal from "sweetalert2";
import { CubeConfig } from "../../../../../models/cubes/cube-config";
import {
  FormGroup,
  FormControl,
  Validators,
  FormBuilder,
  ValidatorFn,
  AbstractControl,
} from "@angular/forms";
import { Router } from "@angular/router";
import { TableService } from "../../../../../services/table/table.service";
import * as $ from "jquery";
import { ActivatedRoute } from "@angular/router";
import { CommonServiceService } from "../../../../../services/common-services/common-service.service";
import { Subscription } from "rxjs";
import { ValueConverter } from "@angular/compiler/src/render3/view/template";

@Component({
  selector: "app-info",
  templateUrl: "./info.component.html",
  styleUrls: ["./info.component.scss"],
})
export class InfoComponent implements OnInit, AfterViewInit {
  loggedUser;
  public cubeConfig: CubeConfig;
  modelname;
  cubeName;
  cubeDesc;
  cubeInfo;
  cmodelname;
  cubeDetails;
  notifyList;
  selectedModel;
  notifyEvents = ["ERROR", "DISCARDED", "SUCCEED"];
  //getNotifyListString;
  projectName;
  cname;
  dropdownList = ["ERROR", "DISCARDED", "SUCCEED"];
  invalidName;
  errorStatus;
  modelRequired;
  dropdownSettings;
  editCube;
  editMode;
  projectId;
  emailError;
  cubeStatus;
  settings;
  cubeObj: any = {};
  factTableInfo: any;
  targetPage;
  infoForm: FormGroup;
  cubeNames: string[] = [];
  private subscription: Subscription;
  isLoading: boolean = false;
  constructor(
    private modelService: ModelService,
    public cubesdescService: CubeDescriptionService,
    private tableService: TableService,
    private projectService: ProjectService,
    private cubeService: CubesService,
    private router: Router,
    private route: ActivatedRoute,
    public commonServiceService: CommonServiceService
  ) {
    this.cubeConfig = new CubeConfig();
    this.dropdownSettings = {
      singleSelection: false,
      idField: "id",
      textField: "name",
      selectAllText: "Select All",
      unSelectAllText: "Unselect All",
      allowSearchFilter: true,
    };
    this.loggedUser = JSON.parse(sessionStorage.getItem("loggedUser"));
    this.cubeService.projectName =
      this.route.snapshot.paramMap.get("projectId");
    this.projectId = this.route.snapshot.paramMap.get("projectId");
    if (!this.route.snapshot.paramMap.get("projectId"))
      this.router.navigate(["/projects"]);
    this.editCube = this.route.snapshot.paramMap.get("cubeId");
    this.cubeService.cubeName = this.editCube;
    if (!this.editCube) this.cubeService.editMode = false;
    this.settings = {
      singleSelection: true,
      textField: "name",
      idField: "name",
      closeDropDownOnSelection: true,
    };
  }

  ngAfterViewInit() {
    $(document).ready(function () {
      var contenth = $(window).height() - 110;
      var sidebarh = $(window).height() - 111;
      $(".pagec").css("height", contenth);
      $(".sidebar-wrapper").css("height", sidebarh);
    });

    function progressli_check() {
      $(".progresslic ").each(function () {
        if ($(".progressbar li ").length > 6) {
          $(".menublock").addClass("tabw_seven");
        }
      });
    }
    setTimeout(progressli_check, 100);
  }

  onBlurMethod() {
    if ($(".progressbar li ").length > 6) {
      $(".menublock").addClass("tabw_seven");
    }
  }

  ngOnInit() {
    this.factTableInfo = this.cubeService.tableInfo;
    this.isLoading = true;
    this.editCube = this.route.snapshot.paramMap.get("cubeId");
    if (!this.editCube) this.cubeService.editMode = false;

    this.infoForm = new FormGroup({
      modelName: new FormControl("", [Validators.required]),
      cubeName: new FormControl("", [
        Validators.required,
        Validators.pattern("^[a-z0-9A-Z_]{3,100}$"),
      ]),
      notifyList: new FormControl(""),
      cubeDesc: new FormControl(""),
      notifyEvents: new FormControl(""),
    });

    this.list();

    this.cubeStatus = this.cubeService.cubeStatus;
    if (this.editCube) {
      this.targetPage =
        "projects/" +
        this.projectId +
        "/cubes/" +
        this.editCube +
        "/designer/info";
    } else {
      this.targetPage = "projects/" + this.projectId + "/cubes/designer/info";
    }

    this.subscription = this.cubeService.getCube().subscribe((res: any[]) => {
      this.cubeNames = res.map((item) => item.name);

      this.f.cubeName.setValidators([
        Validators.required,
        Validators.pattern("^[a-z0-9A-Z_]{3,100}$"),
        this.forbiddenNameValidator(this.cubeNames),
      ]);
    });
  }

  get f() {
    return this.infoForm.controls;
  }

  forbiddenNameValidator(names: Array<string>): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const value = control.value;
      if (names) {
        const forbidden = names.some(
          (name) => name.trim().toLowerCase() == value.trim().toLowerCase()
        );

        return forbidden ? { DuplicateName: { value: control.value } } : null;
      }
    };
  }

  multipleEmailValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      let emailList = control.value.split(",");

      const pattern: RegExp =
        /^[\W]*([\w+\-.%]+@[\w\-.]+\.[A-Za-z]{2,4}[\W]*,{1}[\W]*)*([\w+\-.%]+@[\w\-.]+\.[A-Za-z]{2,4})[\W]*$/;

      let isInvalid: number = 0;

      emailList.forEach((email) => {
        if (!pattern.test(email)) {
          isInvalid++;
        }
      });

      return isInvalid ? { inValidMail: { value: control.value } } : null;
    };
  }

  setEmailValidator() {
    if (this.f.notifyList.value) {
      this.f.notifyList.setValidators([this.multipleEmailValidator()]);
    } else {
      this.f.notifyList.clearValidators();
    }

    this.f.notifyList.updateValueAndValidity();
  }

  getTableType() {
    let model = this.modelname.find(
      ({ name }) => name == this.f.modelName.value
    );
    if (model) {
      this.tableService
        .get(this.projectId, model.fact_table)
        .subscribe((data: any[]) => {
          if (data) this.cubeService.tableInfo = data;
          this.factTableInfo = this.cubeService.tableInfo;
          if (this.factTableInfo && this.factTableInfo.source_type == 20) {
            $(".menublock").addClass("tabw_seven");
          }
        });
    }
  }

  list() {
    this.isLoading = true;

    if (!this.editCube) {
      this.f.notifyEvents.setValue(["ERROR", "DISCARDED", "SUCCEED"]);
    } else {
      this.cubesdescService.getCubeDetails(this.editCube).subscribe(
        (data) => {
          if (data) {
            this.isLoading = false;

            this.infoForm.setValue({
              modelName: data[0].model_name,
              cubeName: data[0].name,
              cubeDesc: data[0].description,
              notifyList: data[0].notify_list.join(","),
              notifyEvents: data[0].status_need_notify,
            });

            this.cubeService.cubeMeta = {
              ...data[0],
            };

            this.infoForm.controls["cubeName"].disable();
            this.infoForm.controls["modelName"].disable();

            this.infoForm.updateValueAndValidity();

            this.editMode = true;
            this.selectedModel = data[0].model_name;
            this.cubeName = data[0].name;
          } else {
            swal("Failed", "Invalid cube", "error");
            this.router.navigate(["/projects"]);
          }
        },
        (error) => {
          swal("Failed", error.error.msg, "error");
          this.router.navigate(["/projects"]);
        }
      );
    }

    if (this.cubeService.cubeMeta.name) {
      this.infoForm.setValue({
        modelName: this.cubeService.cubeMeta.model_name,
        cubeName: this.cubeService.cubeMeta.name,
        cubeDesc: this.cubeService.cubeMeta.description
          ? this.cubeService.cubeMeta.description
          : "",
        notifyList: this.cubeService.cubeMeta.notify_list
          ? this.cubeService.cubeMeta.notify_list.join(",")
          : null,
        notifyEvents: this.cubeService.cubeMeta.status_need_notify,
      }); // this.infoForm.updateValueAndValidity();
    }
    //else {
    //   this.f.notifyEvents.setValue(["ERROR", "SUCCEED", "DISCARDED"]);
    // }

    this.modelService.list(this.cubeService.projectName).subscribe((data) => {
      this.modelname = data;
      this.getTableType();
      this.isLoading = false;
      if (this.cubeService.cubeMeta.model_name)
        this.selectedModel = this.cubeService.cubeMeta.model_name;
      if (this.cubeService.cubeMeta.model_name)
        this.cubeName = this.cubeService.cubeMeta.name;
      if (this.cubeService.cubeMeta.description)
        this.cubeDesc = this.cubeService.cubeMeta.description;
      if (this.cubeService.cubeMeta.notify_list)
        this.notifyList = this.cubeService.cubeMeta.notify_list.toString();

      if (this.cubeService.cubeMeta.status_need_notify)
        this.notifyEvents = this.cubeService.cubeMeta.status_need_notify;
      if (this.notifyEvents.length == 0)
        this.notifyEvents = ["ERROR", "DISCARDED", "SUCCEED"];
    });
  }

  getNotifyListString(cube: any) {
    if (this.cubeDetails.notify_list) {
      cube.notifyListString = this.cubeDetails.notify_list.join(",");
    } else {
      cube.notifyListString = "";
    }
  }

  updateNotifyList(cube: any) {
    if (cube.notifyListString.length === 0) {
      cube.detail.notify_list = [];
    } else {
      cube.detail.notify_list = cube.notifyListString.split(",");
    }
    this.cubeService
      .updateNotifyList(this.cubeName, cube.detail.notify_list)
      .subscribe((data) => {
        this.list();
        swal("Success!", "Notify List updated successfully!", "success");
      });
  }

  addEvents() {
    if (this.notifyEvents.length <= 0) {
      this.notifyEvents = ["ERROR"];
    }
  }
  nextPage(page) {
    let notifyList: string[] = [];

    if (this.f.notifyList) {
      notifyList = this.f.notifyList.value.split(",");
    }

    this.selectedModel = this.f.modelName.value;
    this.cubeName = this.f.cubeName.value;

    this.cubeService.cubeMeta = {
      ...this.cubeService.cubeMeta,
      name: this.f.cubeName.value,
      model_name: this.f.modelName.value,
      description: this.f.cubeDesc.value,
      status_need_notify: this.f.notifyEvents.value,
      notify_list: notifyList,
    };

    if (!this.cubeService.editMode) {
      for (let [key, value] of Object.entries(this.modelname)) {
        let res = <any>{};
        res = value;
        if (res.name == this.selectedModel) this.cubeService.setModelObj(value);
      }

      this.router.navigate([
        "projects/" + this.projectId + "/cubes/designer/" + page,
      ]);
    } else {
      for (let [key, value] of Object.entries(this.modelname)) {
        let res = <any>{};
        res = value;
        if (res.name == this.selectedModel) this.cubeService.setModelObj(value);
      }
      // this.modelService.getModelByName(this.cubeService.cubeMeta.model_name).subscribe(
      //   data=>{
      //     this.cubeService.setModelObj(data[0]);
      //     this.cubeService.model=data[0];

      //   });

      this.router.navigate([
        "/projects/" +
          this.projectId +
          "/cubes/" +
          this.editCube +
          "/designer/" +
          page,
      ]);
    }
  }

  validateName(cubeName) {
    let pattern = "^[a-z0-9A-Z_]{3,100}$";
    if (!cubeName.match(pattern)) {
      this.invalidName = true;
      this.errorStatus = true;
    } else {
      this.invalidName = false;
      this.errorStatus = false;
    }
  }
  validateEmail(notifyList) {
    // let pattern=
    let pattern =
      /^[\W]*([\w+\-.%]+@[\w\-.]+\.[A-Za-z]{2,4}[\W]*,{1}[\W]*)*([\w+\-.%]+@[\w\-.]+\.[A-Za-z]{2,4})[\W]*$/;
    if (!notifyList.match(pattern) && notifyList != "") {
      this.emailError = true;
      this.errorStatus = true;
    } else {
      this.emailError = false;
      this.errorStatus = false;
    }
  }
  checkModel(selectedModel) {
    if (!selectedModel) {
      this.modelRequired = true;
      this.errorStatus = true;
    } else {
      this.modelRequired = false;
      this.errorStatus = false;
    }
  }

  isEmail(search: string): boolean {
    var serchfind: boolean;

    let regexp = new RegExp(
      /^((\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*)*([,])*([\s])*)*$/
    );

    serchfind = regexp.test(search);

    return serchfind;
  }

  saveLogs() {
    var today = new Date();
    let param = {
      username: this.loggedUser.username,
      targetPage: this.targetPage,
      day: today.getDay(),
      month: today.getMonth(),
      year: today.getFullYear(),
    };
    this.commonServiceService.saveLogs(param).subscribe((data) => {});
  }
}
