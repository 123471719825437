import { Component, OnInit, AfterViewInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import {ModelStepsService} from '../../../../../services/model/model-steps.service';
import * as $ from 'jquery';

@Component({
  selector: 'app-model-measures',
  templateUrl: './model-measures.component.html',
  styleUrls: ['./model-measures.component.scss']
})
export class ModelMeasuresComponent implements OnInit,AfterViewInit {
  projectId:any;
  aliasName:any;
  tableServiceList:any=[];
  measureColumns=[];
  measuresList:any=[];
  dropdownSettings = {};
  measureName:any;
  measureNameList:any=[];
  dimensionName;
  lookupSettings = {};
  measureLookup=[];
  measureColumns1=[];
  lookUps;
  measureName1:any;


  //colMeasure:any=[];
  constructor(public modelStepsService: ModelStepsService, private route: ActivatedRoute, private router: Router) {
    if(this.modelStepsService.modelName ===  undefined || this,modelStepsService.modelDescription === undefined){
      this.projectId = this.route.snapshot.paramMap.get("projectId");
      this.router.navigate(['/projects/',this.projectId ,'models','designer','info']);
    } 
   }

   ngAfterViewInit() {
    $(document).ready(function(){
          var contenth = $( window ).height() - 110 ;
          var sidebarh = $( window ).height() - 111 ;
          $(".pagec").css("height", contenth );
          $(".sidebar-wrapper").css("height", sidebarh );
        });
    }

  ngOnInit() {
    this.projectId = this.route.snapshot.paramMap.get("projectId");
    this.aliasName = this.modelStepsService.factAliasName;

    this.tableServiceList = this.modelStepsService.tableList;
    this.getMeasuresColumns();
    this.getMeasureDetails();
        
    this.dropdownSettings = {
    singleSelection: false,
    textField: "name",
    selectAllText: 'Select All',
    unSelectAllText: 'Unselect All',
    allowSearchFilter: true
    };
    if(this.modelStepsService.metricName!==undefined){
 
       this.measureName = this.modelStepsService.metricName;
 
     }

     if(this.modelStepsService.lookUps!== undefined && this.modelStepsService.lookUps.length>0){
      this.lookUps=this.modelStepsService.lookUps;
      if(this.modelStepsService.measureLookup!== undefined && this.modelStepsService.measureLookup.length>0){
        // this.measureLookup = this.modelStepsService.measureLookup;

        this.measureLookup=[];
        for(let param of this.modelStepsService.measureLookup){
          if(!this.measureLookup.find(({alias}) =>param.alias===alias))
            { 
              if(param.kind=='FACT'){
              this.measureLookup.push({
                "alias":param.alias,
                "measureColumns1":this.getMeasureColumns1(param.alias),
                "measureName":param.measureName
              })
            }
            }
           } 
 
          // this.getMeasureColumns1(param.alias);
          for(let param1 of this.modelStepsService.lookUps){
            if(!this.measureLookup.find(({alias}) =>param1.alias===alias) && param1.kind=='FACT')
            {
              if(param1.kind=='FACT'){
                this.measureLookup.push({
                  "alias":param1.alias,
                  "measureColumns1":this.getMeasureColumns1(param1.alias)
                })
            }
            }
          }
        
      }else{
        this.measureLookup=[];
        for(let param of this.modelStepsService.lookUps){
          if(param.kind=='FACT'){
            this.measureLookup.push({
              "alias":param.alias,
              "measureColumns1":this.getMeasureColumns1(param.alias)
            })
          }
          // this.getMeasureColumns1(param.alias);
        }
      }
     }
  

     this.lookupSettings = {
      singleSelection: false,
      textField: "name",
      selectAllText: 'Select All',
      unSelectAllText: 'Unselect All',
      allowSearchFilter: true
    };

    if(this.modelStepsService.metricName1!==undefined){
       this.measureName1 = this.modelStepsService.metricName1;
     }

     if(this.modelStepsService.measureColumns1!==undefined){
      this.measureColumns1 = this.modelStepsService.measureColumns1;
    }
  
 
    
  }

  getMeasuresColumns(){
    this.measureColumns= [];
    let column=[];

if(this.modelStepsService.dimensionName!==undefined && this.modelStepsService.dimensionName.length > 0 ) {
  for(let param of this.modelStepsService.dimensionName){
    let isMeasureColSame='';
  if(this.modelStepsService.metricName){
    isMeasureColSame = this.modelStepsService.metricName.find(({name}) => param.name === name);
    let ind=this.modelStepsService.metricName.indexOf(isMeasureColSame);
    if(ind>-1){
      this.modelStepsService.metricName.splice(ind,1);
    }

  }
  }
  this.modelStepsService.dimensionColumns.forEach((obj)=>{
    let isDimensionColSame = this.modelStepsService.dimensionName.find(({id,name}) => obj.id === id && obj.name === name);
     if(!isDimensionColSame){
       let flag=true;
       let ind=this.modelStepsService.joinCondition.indexOf(obj.name)
       if(ind==-1){
        this.measureColumns.push(obj);
       }
      
     }
   });

}

else{

  for (let [key, value] of Object.entries(this.tableServiceList)) {
  let res= <any>{};
  res=value;
 
  let databaseName = res.database + '.' + res.name;
 
  if(databaseName == this.modelStepsService.factTable){
  
     for (let param of res.columns){
      let ind=this.modelStepsService.joinCondition.indexOf(param.name);
      if(ind==-1){
        this.measureColumns.push({"id":param.id,"name":param.name});
      }
     
     
     }
  
  }
  
  } 
}
  this.modelStepsService.measureColumns = this.measureColumns;
  }

  getMeasureValues(){
  if(this.measureName!==undefined){
      this.modelStepsService.metricName = this.measureName;
 
  }

   
  if(this.modelStepsService.metricName !== undefined){
     let metricCheck = "MetricAdded";
  this.modelStepsService.checkMetricAdded = metricCheck;
  } else{
    let metricCheck = "MetricNotAdded";
    this.modelStepsService.checkMetricAdded = metricCheck;
  }
   
  }

  getMeasureDetails(){
    
    this.measuresList.push({"aliasName":this.aliasName});
    this.modelStepsService.measureList = this.measuresList;
 
 }

 navigateToSettings(id){
  this.modelStepsService.metricName = this.measureName;
  this.modelStepsService.metricName1 = this.measureName1;
  this.modelStepsService.measureLookup = this.measureLookup;
  this.modelStepsService.measureColumns = this.measureColumns;
  this.router.navigate(['/projects/',id,'models','designer','conditions-settings']);

 }

    // This method navigate to model info page

    loadModelDesigner(id){
      this.router.navigate(['/projects/',id,'models','designer','info']);
       }
   
       
     loadDataModel(id){
      this.router.navigate(['/projects/',id,'models','designer','data']);
     }
   
     navigateToDimensions(id){
        this.modelStepsService.metricName = this.measureName;
        this.modelStepsService.metricName1 = this.measureName1;
        this.modelStepsService.measureLookup = this.measureLookup;
 
        this.modelStepsService.measureColumns = this.measureColumns;
 
         this.router.navigate(['/projects/',id,'models','designer','dimensions']); 
     }


    getMeasureColumns1(tableName){
      this.measureColumns1=[];
      let name;
      let primary_key1=[];
      let primary_key=[];
 
      if(tableName==this.modelStepsService.factTable.split('.',2)[1]){
        name=this.modelStepsService.factTable;
      }else{
         let item=this.modelStepsService.lookUps.find(({alias}) =>tableName===alias)
 
         if(item){
           name=item.table;
           primary_key1=item.join.primary_key;
           for(let key of primary_key1){
            primary_key.push(key.split('.',2)[1]);
           }
         }
          else
          name='';
      }
     
        let dims=this.modelStepsService.tableList.find(({dataSourceName}) =>name===dataSourceName)
 
        let index=null
        if(dims)
        {
          for(let cols of dims.columns){
            let look=this.modelStepsService.dimensionLookup.find(({alias}) =>tableName===alias)
 
            if(look && look.dimesions){
              index=look.dimesions.find(({name}) =>cols.name===name);
 
            }
            if(!index && primary_key.indexOf(cols.name)==-1)
              this.measureColumns1.push({"id":cols.id,"name":cols.name});
        }
      }
      
      return this.measureColumns1;
    }
  
}
