import { Component, OnInit } from '@angular/core';
import { DataCatalogTablesService } from '../../../services/data-catalog/data-catalog-tables.service'
import { DataCatalogService } from '../../../services/data-catalog/data-catalog.service';
import swal from 'sweetalert2';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-catalog-tables',
  templateUrl: './catalog-tables.component.html',
  styleUrls: ['./catalog-tables.component.scss']
})
export class CatalogTablesComponent implements OnInit {
  tables:any;
  catalog:any;
  page = 0;
  pageNumber:number;
  dataCatalogDetails:any;
  editMode:boolean;
  dataCatalog:any;
  catalogEdit:boolean;
  description;
  displayName;
  order: string = 'name';
  reverse: boolean = true;
  constructor( private dataCatalogService:DataCatalogService,private dataCatalogTablesService:DataCatalogTablesService,private router:Router,private route: ActivatedRoute) { 
  }

  ngOnInit() {
    this.getTables(this.page);
  }

getTables(pageNumber){
  let catalogId=this.route.snapshot.paramMap.get("id");
  this.editMode=false;
  this.dataCatalogService.list(catalogId).subscribe(
    data=>{
      let res= <any>{};
      res=data;
      this.dataCatalogDetails=res.dataCatalog;
    //this.dataCatalogDetails=res.content;
     
     this.displayName=this.dataCatalogDetails.displayName;
     this.description=this.dataCatalogDetails.description;
     
    });
  this.dataCatalogTablesService.list(catalogId,pageNumber,10).subscribe(
    data=>{
      let res= <any>{};
      res=data;
      this.catalog=res.page;
      this.tables=res.page.content;
     
    });
}
editDescription(){
  this.editMode=true;
}
closeDescription(){
  this.editMode=false;
  this.ngOnInit();
}
saveDescription(tables){
  let catalogId=this.dataCatalogDetails.uuid;

  this.dataCatalogTablesService.updateTableDescription(catalogId,tables).subscribe(
    data =>{
      swal("Success!",'Details successfully updated', "success");
      this.editMode=false;
      this.getTables(this.page);
  }, (error) => {
   
    swal("Failed",error.error.msg, "error");
  }); 
  
}
PurgeCatalog(){
  swal(
    { 
    type:'warning', 
    title: 'Are you sure you want to purge this catalog?', 
    showCancelButton: true, confirmButtonText: 'Yes', 
    cancelButtonText: 'No'
    } 
    ).then((status) => {
    if(status.value==true){
      this.dataCatalogService.clean(this.dataCatalogDetails.uuid).subscribe(
        data =>{
          swal("Success!",'Catalog Successfully purged', "success");
          this.router.navigate(['/manage-catalog']);
          // this.getTables(this.page);
      }, (error) => {
        swal("Failed",error.error.msg, "error");
      }); 
    }
    }, (dismiss) => {
    if (dismiss === 'cancel') {
    }
    });
}
publishCatalog(status){
  let catalogId=this.dataCatalogDetails.uuid;
  swal(
    { 
    type:'warning', 
    title: this.dataCatalogDetails.status?'Unpublishing this database will hide it from the data catalog. Existing grants will continue and <br>is not affected by this operation.Please confirm to proceed?':'Publishing this database will allow users to see it in the catalog and further updates made will reflect immediately.', 
    showCancelButton: true, confirmButtonText: 'Yes', 
    cancelButtonText: 'No'
    } 
    ).then((status) => {
    if(status.value==true){
      this.dataCatalogDetails.status=!this.dataCatalogDetails.status;
      this.dataCatalogService.updateStatus(catalogId,this.dataCatalogDetails).subscribe(
        data =>{
          swal("Success!",this.dataCatalogDetails.status?'Catalog successfully published':'Catalog successfully unpublished', "success");
          // this.getTables(this.page);
      }, (error) => {
        swal("Failed",error.error.msg, "error");
      }); 
    }
    }, (dismiss) => {
    if (dismiss === 'cancel') {
    }
    });
 
  
}
editCatalog(){
  this.catalogEdit=true;
}
closeCatalog(){
  
  this.catalogEdit=false;
  this.ngOnInit();
}
saveCatalog(){
  let catalogId=this.dataCatalogDetails.uuid;
  this.dataCatalogDetails.description=this.description;
  this.dataCatalogDetails.displayName=this.displayName;
  this.dataCatalogService.updateDescription(catalogId,this.dataCatalogDetails).subscribe(
    data =>{
      swal("Success!","Changes has been saved.", "success");
      // this.getTables(this.page);
  }, (error) => {
    swal("Failed",error.error.msg, "error");
  }); 
  this.catalogEdit=false;
}
viewTable(catalog,table){
  this.router.navigate(['/manage-catalog/tables/'+catalog+'/columns/'+table]);
}
backToCatalog(){
  this.router.navigate(['/manage-catalog']);
}

setOrder(value: string) {
  if (this.order === value) {
    this.reverse = !this.reverse;
  }

  this.order = value;
}
}
