import { Component, OnInit, EventEmitter,ViewChild, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CubesService } from '../../../../../services/cubes/cubes.service';
import { JobSchedulerService } from '../../../../../services/job-scheduler/job-scheduler.service';
import swal from 'sweetalert2';
import { Location } from '@angular/common';
import { ProjectService } from '../../../../../services/project/project.service';
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { NgForm } from "@angular/forms";
import { CommonServiceService } from '../../../../../services/common-services/common-service.service';
import { CronOptions } from "../../../../common/cron-editor/CronOptions";
import { CronGenComponent } from "../../../../common/cron-editor/cron-editor.component";
@Component({
	selector: 'app-create-scheduler',
	templateUrl: './create-scheduler.component.html',
	styleUrls: ['./create-scheduler.component.scss']
})
export class CreateSchedulerComponent implements OnInit {
	title: string;
	editPage: boolean= false;
	triggerKeyGroup: string;
	triggerKeyName: string;
	scheduleId: string;
	jobSch: any;
	projectName: string;
	projectId: string;
	loggedUser;
	projectUUID: string;
	// jobTypes: string[] = [
	// 	'BUILD'
	// ];
	jobTypes=[{ name: 'BUILD', value: 'BUILD' }];
	cubesList=[];
	type;
	job: any = {
		cronString: null,
		cubeName: null,
		jobType: null,
		name: null,
		status: true
	}
	cubes: any = null;
	cube;
	cronString: string;
	selectedCube: any;
	errorCron;
	checkCronExpression;
	generateScheduledTimes;
	validated:boolean;
	listData;
	todayDate;
	isEnable=false;
	cronPattern = "/^(?:[1-9]?\d|\*)(?:(?:[\/-][1-9]?\d)|(?:,[1-9]?\d)+)?$/";
	breadCrumb:any;
	schedulerObj:any={};
	scheduleNameError:any;
	public focusSettingEventEmitter = new EventEmitter<boolean>();
	targetPage;

	public isCronDisabled = false;

	public cronOptions: CronOptions = {
		formInputClass: "form-control cron-editor-input",
		formSelectClass: "form-control cron-editor-select",
		formRadioClass: "cron-editor-radio",
		formCheckboxClass: "cron-editor-checkbox",

		defaultTime: "00:00:00",

		hideMinutesTab: false,
		hideHourlyTab: false,
		hideDailyTab: false,
		hideWeeklyTab: false,
		hideMonthlyTab: false,
		hideYearlyTab: false,
		hideAdvancedTab: false,
		hideSpecificWeekDayTab: false,
		hideSpecificMonthWeekTab: false,

		use24HourTime: true,
		hideSeconds: false,

		cronFlavor: "quartz"
	};

	@ViewChild("cronEditorDemo")
	cronEditorDemo: CronGenComponent;

	@Input() public valueChange : any;
	isschCronString: boolean = false;
	isschViewCronString: boolean = false;
	//scheduleNameError: any;
	//errorCron;
	cronlistData;
	schedulerLisiting = [];
	schInvalidMsg;
	schRequiredMsg;
	//todayDate;
	schCronString;

	constructor(private projectService: ProjectService, private route: ActivatedRoute, public commonServiceService: CommonServiceService, private cubeService: CubesService, private jobSchedulerService: JobSchedulerService, private location: Location) {
		this.loggedUser = JSON.parse(sessionStorage.getItem("loggedUser"));
	 }
	toUTCDate(date) {
        var _utc = new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate(), date.getUTCHours(), date.getUTCMinutes(), date.getUTCSeconds());
        return _utc;
    };

    millisToUTCDate () {
        return this.toUTCDate(new Date());
	};
	millisToUTCDateS(scheduledTime){
		return this.toUTCDate(new Date(scheduledTime));
	}
	buttonStatus(){
		this.isEnable=true;
	}
	ngOnInit() {
		
		this.todayDate = setInterval( () => { 
			this.todayDate = new Date(); 
	 }, 1000);
	 this.job.cronString = "0 0/1 * 1/1 * ? *";
		this.projectName = this.route.snapshot.paramMap.get('projectId');
		let projectList: any = null;
		this.projectService.list().subscribe(
			data => {
				projectList = data;
				for (let project of projectList) {
					if(project.name === this.projectName) {
						this.projectId = project.uuid
					
						if( this.route.snapshot.paramMap.get('schedulerId') ){
							this.scheduleId = this.route.snapshot.paramMap.get('schedulerId')
							this.targetPage='projects/'+this.projectId+'/scheduler/'+this.scheduleId+'/edit',
							this.getJobSchedule(this.scheduleId, this.projectId);
							this.title = "Edit Schedule"
							
							this.validated=true;
							this.editPage = true;
						} else {
							this.title = "Create Schedule";
							this.breadCrumb ="Create Schedule"
							this.targetPage='projects/'+this.projectId+'/scheduler/create';
						}
						//this.saveLogs();
					}
				}
			}
		)
	}

	getJobSchedule(schId: string, projId: string) {
		let userCredentials: any= JSON.parse(sessionStorage.getItem('credentials'))
		this.job = {
				cronString: null,
				cubeName: null,
				jobType: null,
				name: null,
				projectId: null,
				schedulerId: null,
				status: true,
				submitter: null,
				triggerKeyGroup: null,
				triggerKeyName: null
			}
		this.jobSchedulerService.getScheduler(schId).subscribe(
			data => {
				this.jobSch = data;
				this.job.cronString= this.jobSch.schedulerDetails.cronString,
				this.job.cubeName= this.jobSch.schedulerDetails.cubeName,
				this.cube=this.jobSch.schedulerDetails.cubeName,
				this.job.jobType= this.jobSch.schedulerDetails.jobType,
				this.type=this.job.jobType,
				this.job.name= this.jobSch.schedulerDetails.name,
				this.job.status= this.jobSch.schedulerDetails.status,
				this.job.projectId= projId,
				this.job.schedulerId= schId,
				this.job.submitter= userCredentials.username,
				this.job.triggerKeyGroup= this.jobSch.schedulerDetails.triggerKeyGroup,
				this.job.triggerKeyName= this.jobSch.schedulerDetails.triggerKeyName
				this.breadCrumb =this.job.name;
				this.selectChangeHandler(this.job.jobType, 'type')
				this.selectChangeHandler(this.job.cubeName, 'cube')
		})
	}
		
	isActive() {
		if (!!this.job.name) {
			if (!!this.job.jobType && this.job.jobType === 'BUILD' && this.isValidCron() && this.job.cubeName) {
				return true;
			}
		}
		return false;
	}

	isValidCron() {
		if (!!this.job.cronString) {
			return true;
		}
		return false;
	}
	cancel() {
		this.location.back();
	}

	submit(form: NgForm) {
		if(this.job.cronString.match("(?=.*[a-z])")){
			this.errorCron = "Cron String is Invalid.";
			this.generateScheduledTimes="";
		}else{
		this.jobSchedulerService.validateCronString(this.job.cronString).subscribe(
			data => {
				this.listData=data;
				this.validated=true;
				this.errorCron="";
                this.checkCronExpression = true;
				this.generateScheduledTimes = this.listData.longList;
			
				if (!this.listData.errorCode) {
				swal(
					{
						type: 'warning',
						title: 'Are you sure you want to save this job?',
						text: '',
						showCancelButton: true, confirmButtonText: 'Yes',
						cancelButtonText: 'No'
					}
				).then((status) => {
					if(status.value == true) {
						let errors: any = null;
						
						const params = { projectId: this.projectId }
						if (this.route.snapshot.paramMap.get('schedulerId')){
							this.jobSchedulerService.updateScheduler(this.job).subscribe(
								result => {
									
								},
								error => {
									errors = error;
									swal("Failed to create job", "failure", "error")
								},
								() => {
									if(!errors) {
										swal("Success!", "Scheduler has been saved successfully", "success").then((status) => {
											if(status.value == true) {
												this.location.back();
											}
										});
									}
								}
							)
						} else {
							this.jobSchedulerService.createSheduler(params, this.job).subscribe(
							result => {
								
							},
							error => {
								errors = error;
								swal("Failed to create job", "failure", "error")
							},
							() => {
								if(!errors) {
									swal("Success!", "Scheduler has been saved successfully", "success").then((status) => {
										if(status.value == true) {
											this.location.back();
										}
									});
								}
							}
						)
						}
						
					}
				});
			}
                if (this.listData.errorCode) {
                    this.checkCronExpression = false;
					this.errorCron = this.listData.errorMessage;
				
                }
			}
        );
		}
		
	}
	getCubes() {
		this.cubeService.list({
			projectName: this.projectName
		}).subscribe(
			data => {
				this.cubes = data;
				this.cubesList=[];
				for (let i = 0; i < this.cubes.length; i++) {
					this.cubesList.push({name:this.cubes[i].name,value:this.cubes[i].name});
				  }
				
				
				if(this.route.snapshot.paramMap.get('schedulerId')) {
					return;
				} else {
					this.job.cubeName = this.cubes[0].name;
				}
			}
		)
	}
	selectChangeHandler(event, selector) {
		if (selector === 'type') {
			if (!!event && event === 'BUILD') {
				if (!this.cubes) {
					this.getCubes();
				}
			}
			this.job.jobType = event;
		}
		if (selector === 'cube') {
			this.job.cubeName = event;
		}
	}

	isBuildJob() {
		if (!!this.job.jobType && this.job.jobType === 'BUILD') {
			return true;
		}
		return false;
	}

	checkCronScheduleTimes = function (cronString) {
		if(cronString.match("(?=.*[a-z])")){
			this.errorCron = "Cron String is Invalid.";
			this.generateScheduledTimes="";
		}else{
        this.generateScheduledTimes = [];
        this.jobSchedulerService.validateCronString(cronString).subscribe(
			data => {
				this.validated=true;
				this.isEnable=true;
				this.errorCron="";
                this.checkCronExpression = true;
                this.generateScheduledTimes = data.longList;
                if (data.errorCode) {
                    this.checkCronExpression = false;
					this.errorCron = data.errorMessage;
				
                }
			}
		);
		}
	}
	
	checkSchedulerNameExists(){
		let listAllJobs = JSON.parse(sessionStorage.getItem('JobList'));
		let flag = true;
		for (let [key, value] of Object.entries(listAllJobs.schedulerDetailsList)) {
			let res= <any>{};
			res=value;
			if(this.job.name.toUpperCase() == res.name.toUpperCase()){
			  flag = false;
			  break;
			}
		  }
			if(!flag){
				this.scheduleNameError= 'Schedule name is already exist.';
			  return;
			}else{
				this.scheduleNameError= '';
			}
	}

	ngAfterViewInit() {
		
		this.focusSettingEventEmitter.emit(true);
	   
		}

		
		setFocus(): void {
			this.focusSettingEventEmitter.emit(true);
		  }

		  
  saveLogs(){
    var today = new Date();
    let param={
      username:this.loggedUser.username,
      targetPage:this.targetPage,
      day:today.getDay(),
      month:today.getMonth(),
      year:today.getFullYear()
    };
    this.commonServiceService.saveLogs(param).subscribe(
      data => {

      });
  }
  cronFlavorChange() {
	this.cronEditorDemo.options = this.cronOptions;
	this.cronEditorDemo.regenerateCron();
}
disableSave(){
	this.isEnable=false;
	//QUBZ-3926-In Scheduler tab Save option should be disable until select Validate cron string-7/11/22
	this.generateScheduledTimes="";
}
}
