import { Component, OnInit, AfterViewInit, ViewChild } from "@angular/core";
import * as $ from "jquery";
import { ActivatedRoute, Router } from "@angular/router";
import { VisualCubeInfoComponent } from "../../../../components/projects/cubes/visual-cube/visual-cube-info/visual-cube-info.component";
import { ModelService } from "../../../../services/model/model.service";
import { CubesService } from "../../../../services/cubes/cubes.service";
import { VisualCubeStep5Component } from "../../../../components/projects/cubes/visual-cube/visual-cube-step5/visual-cube-step5.component";
import { VisualCubeStep2Component } from "../../../../components/projects/cubes/visual-cube/visual-cube-step2/visual-cube-step2.component";
import { VisualCubeStep4Component } from "../../../../components/projects/cubes/visual-cube/visual-cube-step4/visual-cube-step4.component";
import { VisualCubeStep6Component } from "../../../../components/projects/cubes/visual-cube/visual-cube-step6/visual-cube-step6.component";
import { TableService } from "../../../../services/table/table.service";
import { CommonServiceService } from "../../../../services/common-services/common-service.service";
import { CubeDescriptionService } from "src/app/services/cubes/cube-description.service";

@Component({
  selector: "app-visual-cube",
  templateUrl: "./visual-cube.component.html",
  styleUrls: ["./visual-cube.component.scss"],
  providers: [VisualCubeInfoComponent],
})
export class VisualCubeComponent implements OnInit, AfterViewInit {
  loggedUser;
  isFullscreenvm;
  toggleStatec = false;
  toggleState = true;
  toggleStatefs = false;
  load;
  isActive: boolean;
  curStep: number = 1;
  //showModal=true;
  defaultMeasure;
  modelNames: any = [];
  dimensions: Object;
  addDimension = [];
  editDimension = [];
  projectId: any;
  factTable: any;
  saveEnable: boolean = true;
  fromVisualCube: any;
  toSave: any;
  fromSaveDimension: any;
  disabled = false;
  @ViewChild(VisualCubeStep5Component) visStep5: VisualCubeStep5Component;
  @ViewChild(VisualCubeStep2Component) visStep2: VisualCubeStep2Component;
  @ViewChild(VisualCubeStep4Component) visStep4: VisualCubeStep4Component;
  @ViewChild(VisualCubeStep6Component) visStep6: VisualCubeStep6Component;

  savedDimension = [];
  clicked: boolean;
  editSaveDimension = [];
  selectedModel: any;
  modelList: any = [];
  editDim = [];
  cubeName: any;
  visualCubeEditMode: any;
  cubeStatus: any;
  cubeDesc: any;
  notifyList: any;
  notifyEvents: any = [];
  volatileRange: any;
  //cubeItems:any;
  retentionThreshold: any;
  partitionStartDate: any;
  toInfo: any;
  modelPanelShow: boolean;
  tableInfo: any;
  modelNameis: any;
  tableInfoFrmStep2: any;
  //cubeNodeObj:any;
  targetPage;

  constructor(
    private route: ActivatedRoute,
    private infoModal: VisualCubeInfoComponent,
    private modelService: ModelService,
    private cubesService: CubesService,
    private tableService: TableService,
    public commonServiceService: CommonServiceService,
    private cubeDesService: CubeDescriptionService
  ) {
    this.loggedUser = JSON.parse(sessionStorage.getItem("loggedUser"));
  }

  ngAfterViewInit() {
    $(document).ready(function () {
      var contenth = $(window).height() - 110;
      var sidebarh = $(window).height() - 111;
      $(".pagec").css("height", contenth);
      $(".sidebar-wrapper").css("height", sidebarh);

      function threebox_check() {
        $(".cnvasSection").each(function () {
          if ($(".canvasboxes").length > 2) {
            $(".vmfullc").addClass("threeboxes");
          }
        });
      }
      setTimeout(threebox_check, 100);
    });

    $(window).resize(function () {
      var vmheight = $(window).height() - 120;
      $(".show_vm .canvasData").css("height", vmheight);

      function vmequel_ws() {
        var equelheight = $(".canvasData").height() + 33;
        var inputcheight = $(".canvasData").height() + 30;
        $(".sourcebox").css("height", equelheight);
        $(".canvasinputc").css("height", inputcheight);
      }
      setTimeout(vmequel_ws, 4);
    });

    function loadfs() {
      $(document).on("click", ".fullminbtn .fullsbtn", function () {
        var vmheight = $(window).height() - 120;
        $(".show_vm .canvasData").css("height", vmheight);
      });
    }

    setTimeout(loadfs, 2);

    function vmequel() {
      var equelheight = $(".canvasData").height() + 33;
      var inputcheight = $(".canvasData").height() + 30;
      $(".sourcebox").css("height", equelheight);
      $(".canvasinputc").css("height", inputcheight);

      $(document).on("click", ".fullminbtn .fullsbtn", function () {
        var equelheight = $(".canvasData").height() + 33;
        var inputcheight = $(".canvasData").height() + 30;
        $(".sourcebox").css("height", equelheight);
        $(".canvasinputc").css("height", inputcheight);
      });

      $(document).on("click", ".fullminbtn .minsbtn", function () {
        $(".canvasData").css("height", "526px");
        $(".sourcebox").css("height", "559px");
        $(".canvasinputc").css("height", "554px");
      });
    }

    setTimeout(vmequel, 10);

    $(".canvasContent").mouseover(function () {
      $(this).find(".overview-btnc").css("display", "block");
    });

    $(".canvasContent").mouseout(function () {
      $(this).find(".overview-btnc").css("display", "none");
    });

    /* To show buttons while mouse over on rightside container */
    $(".canvasinputec_ovr").mouseover(function () {
      $(this).find(".overview-btnc").css("display", "block");
    });

    $(".canvasinputec_ovr").mouseout(function () {
      $(this).find(".overview-btnc").css("display", "none");
    });

    /* canvas input container button clicks */

    $(".canvasinput_btn .togglebtn-max").on("click", function () {
      $(this).parents(".vmfullc").removeClass("show-canvas");
      $(this).parents(".vmfullc").addClass("show-canvasinput");
    });

    $(".canvasinput_btn .togglebtn-min").on("click", function () {
      $(this).parents(".vmfullc").removeClass("show-canvasinput");
    });

    $(".canvasinput_btn .togglebtn-fs").on("click", function () {
      $(this).parents(".fulls").removeClass("show-fs");
      $(this).parents(".fulls").addClass("canvasinput-fs");
    });

    $(".canvasinput_btn .togglebtn-close").on("click", function () {
      $(this).parents(".fulls").removeClass("canvasinput-fs");
    });
  }
  ngOnInit() {
    this.cubesService.cubeMeta = {
      name: "",
      uuid: "",
      version: "",
      is_draft: 0,
      last_modified: "",
      signature: "",
      partition_date_end: "",
      parent_forward: 0,
      snapshot_table_desc_list: [],
      model_name: "",
      description: "",
      dimensions: [],
      measures: [],
      dictionaries: [],
      rowkey: {
        rowkey_columns: [],
      },
      aggregation_groups: [],
      mandatory_dimension_set_list: [],
      partition_date_start: 0,
      notify_list: [],
      hbase_mapping: {
        column_family: [],
      },
      volatile_range: "",
      retention_range: "",
      status_need_notify: [],
      auto_merge_time_ranges: [],
      engine_type: "",
      storage_type: "",
      override_qubz_properties: {},
    };
    this.cubesService.editMode = false;
    if (this.cubesService.visualCubeEditMode == false) {
      this.cubesService.cubeStatus = null;
      this.targetPage =
        "projects/" + this.projectId + "/cubes/designer/visual-cube";
    } else {
      if (this.cubesService.visualCubeEditMode == true) {
        this.cubeStatus = this.cubesService.cubeStatus;
      }
    }
    this.projectId = this.route.snapshot.paramMap.get("projectId");
    this.cubesService.visCubeNodeDataOnCreate = undefined;
    this.cubeName = this.route.snapshot.paramMap.get("cubeId");
    this.list();
    this.cubesService.visCubeNodeDataOnEdit = undefined;
    this.cubesService.visCubeSavedDimension = undefined;
    this.visualCubeEditMode = this.cubesService.visualCubeEditMode;

    this.tableInfo = this.cubesService.tableInfo;
    if (this.visualCubeEditMode == false) {
      this.targetPage =
        "projects/" + this.projectId + "/cubes/designer/visual-cube";
    } else {
      this.targetPage =
        "projects/" +
        this.projectId +
        "/cubes/" +
        this.cubeName +
        "/visual-cube";
    }
    //this.saveLogs();
  }

  streaminValidation(event: any) {
    this.saveEnable = event.invalid;
  }

  setStep(step: number) {
    this.curStep = step;
  }

  showCanvas($event) {
    this.toggleStatec = $event;
  }

  showFullScreen($event) {
    this.isFullscreenvm = $event;
  }

  showFullCanvas($event) {
    this.toggleStatefs = $event;
  }

  measureEventHander($event: any) {
    this.defaultMeasure = $event;
  }
  toggleButton() {
    this.toggleState = !this.toggleState;
  }
  showContainer() {
    return {
      "show-o": this.toggleState,
    };
  }

  showContainerc() {
    return {
      "show-canvas": this.toggleStatec,
    };
  }

  list() {
    this.modelService.list(this.projectId).subscribe((data) => {
      this.modelNames = data;
    });
  }

  showCubeInfo() {
    this.visStep4.saveRefreshSettings();
    if (this.tableInfo && this.tableInfo.source_type == 20) {
      this.visStep6.saveConfig();
    }
    this.infoModal.openModal();
  }

  getDimensions($event) {
    this.dimensions = $event;
  }

  saveHandler($event) {
    this.fromVisualCube = $event;
    if (this.visualCubeEditMode || this.tableInfo.source_type !== 20) {
      this.saveEnable = false;
    }
  }

  toSaveHandler(event) {
    console.log(event);
    this.toSave = event;
    if (this.visualCubeEditMode || this.tableInfo.source_type !== 20) {
      this.saveEnable = false;
    }
  }

  toSaveDimension($event) {
    this.addDimension = $event;
  }

  toEditSaveDimension($event) {
    this.editDim = $event;
    this.addDimension = this.editDim;
  }

  toUpdateDimension($event) {
    this.editDimension = $event;
    this.addDimension = this.editDimension;
  }
  getSavedDimension($event) {
    this.savedDimension = $event;
  }

  itemSelectd($event) {
    this.clicked = $event;
  }

  getEditSavedDimension($event) {
    this.editSaveDimension = $event;
    this.savedDimension = this.editSaveDimension;
  }

  getModelList($event) {
    this.modelList = $event;
    this.modelNames = this.modelList;
  }

  getDesc($event) {
    this.cubeDesc = $event;
  }

  getNotifyList($event) {
    this.notifyList = $event;
  }

  getNotify($event) {
    this.notifyEvents = $event;
  }

  getCubeService($event) {
    this.cubesService.cubeMeta = $event;
  }

  getVolatileRange($event) {
    this.volatileRange = $event;
  }

  getRetentionRange($event) {
    this.retentionThreshold = $event;
  }

  getPartitionStartDate($event) {
    this.partitionStartDate = $event;
  }

  goBackToSave($event) {
    this.toInfo = $event;
    this.saveEnable = true;
    if (this.toInfo !== "goBackToSave") {
      this.saveEnable = false;
    }
  }

  modelPanelSelect($event) {
    this.modelPanelShow = $event;
  }

  getTableType(selectedName) {
    let model = this.modelNames.find(({ name }) => name == selectedName);
    if (model) {
      this.tableService
        .get(this.projectId, model.fact_table)
        .subscribe((data: any[]) => {
          if (data) this.cubesService.tableInfo = data;
          this.tableInfo = this.cubesService.tableInfo;
          if (this.tableInfo && this.tableInfo.source_type == 20) {
            $(".menublock").addClass("tabw_seven");
          }
        });
    }
  }

  getSelectedModelName($event) {
    this.modelNameis = $event;
    this.getTableType(this.modelNameis);
  }

  getTableInfo($event) {
    this.tableInfoFrmStep2 = $event;
    this.cubesService.tableInfo = this.tableInfoFrmStep2;
    this.tableInfo = this.cubesService.tableInfo;
  }

  saveLogs() {
    var today = new Date();
    let param = {
      username: this.loggedUser.username,
      targetPage: this.targetPage,
      day: today.getDay(),
      month: today.getMonth(),
      year: today.getFullYear(),
    };
    this.commonServiceService.saveLogs(param).subscribe((data) => {});
  }
}
