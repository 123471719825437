import { BrowserModule } from "@angular/platform-browser";
import { SlimLoadingBarModule } from "ng2-slim-loading-bar";
import { NgModule } from "@angular/core";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { ReactiveFormsModule, FormsModule } from "@angular/forms";
import {
  NgbModule,
  NgbPopoverModule,
  NgbTooltipModule,
  NgbDatepickerModule,
  NgbTabsetModule,
} from "@ng-bootstrap/ng-bootstrap";
import { NgMultiSelectDropDownModule } from "ng-multiselect-dropdown";
import { NgSelectModule } from "@ng-select/ng-select";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { ProjectsComponent } from "./components/projects/projects.component";
import { MenuComponent } from "./components/menu/menu.component";
import { AuthComponent } from "./components/auth/auth.component";
import { HttpConfigInterceptor } from "./interceptor/httpconfig.interceptor";
import { DashboardComponent } from "./components/dashboard/dashboard.component";
import { UsersComponent } from "./components/users/users.component";
import { SettingsComponent } from "./components/settings/settings.component";
import { CubesComponent } from "./components/projects/cubes/cubes.component";
import { ModelsComponent } from "./components/projects/models/models.component";
import { JobsComponent } from "./components/projects/jobs/jobs.component";
import { ProfileComponent } from "./components/profile/profile.component";
import { QueryComponent } from "./components/projects/query/query.component";
import { QueryDetailComponent } from "./components/projects/query/detail/query-detail.component";
import { UserManagementViewComponent } from "./components/users/view/user-management-view.component";

import { ConditionsSettingsComponent } from "./components/projects/models/designer/conditions-settings/conditions-settings.component";
import { DataModelComponent } from "./components/projects/models/designer/data/data-model.component";
import { ModelDimensionsComponent } from "./components/projects/models/designer/dimensions/model-dimensions.component";
import { ModelInfoComponent } from "./components/projects/models/designer/info/model-info.component";
import { ModelMeasuresComponent } from "./components/projects/models/designer/measures/model-measures.component";
import { AdvancedSettingsComponent } from "./components/projects/cubes/designer/advanced-settings/advanced-settings.component";

import { DimensionsComponent } from "./components/projects/cubes/designer/dimensions/dimensions.component";
import { InfoComponent } from "./components/projects/cubes/designer/info/info.component";

import { MeasuresComponent } from "./components/projects/cubes/designer/measures/measures.component";
import { OverviewComponent } from "./components/projects/cubes/designer/overview/overview.component";
import { RefreshSettingsComponent } from "./components/projects/cubes/designer/refresh-settings/refresh-settings.component";
import { StreamingConfigComponent } from "./components/projects/cubes/designer/streaming-config/streaming-config.component";
import { ModelCloneComponent } from "./components/projects/models/clone/model-clone.component";
import { ModelDetailComponent } from "./components/projects/models/detail/model-detail.component";
import { ModelEditComponent } from "./components/projects/models/edit/model-edit.component";
import { ModelJsonEditComponent } from "./components/projects/models/json-edit/model-json-edit.component";

import { CubeDetailComponent } from "./components/projects/cubes/detail/cube-detail.component";

import { HybridEditComponent } from "./components/projects/hybrid-edit/hybrid-edit.component";

import { CreateSchedulerComponent } from "./components/projects/jobs/scheduler/create/create-scheduler.component";

import { JobSchedulerViewComponent } from "./components/projects/jobs/scheduler/view/job-scheduler-view.component";
import { JobSchedulerComponent } from "./components/projects/jobs/scheduler/job-scheduler.component";

import { ProjectDataSourcesComponent } from "./components/projects/data-sources/project-data-sources.component";

import { ProjectAccessComponent } from "./components/projects/access/project-access.component";

import { ProjectDashboardComponent } from "./components/projects/dashboard/project-dashboard.component";
import { CubeDesignerComponent } from "./components/projects/cubes/designer/cube-designer.component";
import { AuthGuard } from "./guard/auth/auth.guard";

import { SweetAlert2Module } from "@toverux/ngx-sweetalert2";
import { NgxPaginationModule } from "ngx-pagination";
import { FooterComponent } from "./components/common/footer/footer.component";
import { BasicLayoutComponent } from "./components/common/layouts/basic-layout/basic-layout.component";

import { ConfigStreamingComponent } from "./components/projects/data-sources/config-streaming/config-streaming.component";
import { DragDropModule } from "@angular/cdk/drag-drop";
import { AceEditorModule } from "ng2-ace-editor";
import { JsonParseComponent } from "./components/projects/data-sources/config-streaming/json-parse/json-parse.component";
import { KafkaSettingsComponent } from "./components/projects/data-sources/config-streaming/kafka-settings/kafka-settings.component";
import { SqlQueryComponent } from "./components/projects/query/sql-query/sql-query.component";
import { SavedQueriesComponent } from "./components/projects/query/saved-queries/saved-queries.component";
import { QueryHistoryComponent } from "./components/projects/query/query-history/query-history.component";
import { CubeViewStepOneComponent } from "./components/projects/cubes/detail/cube-view-step-one/cube-view-step-one.component";
import { CubeViewStepTwoComponent } from "./components/projects/cubes/detail/cube-view-step-two/cube-view-step-two.component";
import { CubeViewStepThreeComponent } from "./components/projects/cubes/detail/cube-view-step-three/cube-view-step-three.component";
import { CubeViewStepFourComponent } from "./components/projects/cubes/detail/cube-view-step-four/cube-view-step-four.component";
import { CubeViewStepSevenComponent } from "./components/projects/cubes/detail/cube-view-step-seven/cube-view-step-seven.component";
import { CubeViewStepFiveComponent } from "./components/projects/cubes/detail/cube-view-step-five/cube-view-step-five.component";
import { CubeViewStepSixComponent } from "./components/projects/cubes/detail/cube-view-step-six/cube-view-step-six.component";
import { CatalogTablesComponent } from "./components/data-catalog/catalog-tables/catalog-tables.component";
import { DashBoardComponent } from "./components/data-catalog/dash-board/dash-board.component";
import { CatalogColumnsComponent } from "./components/data-catalog/catalog-columns/catalog-columns.component";
import { EditUserComponent } from "./components/users/edit-user/edit-user.component";
import { EditComponent } from "./components/profile/edit/edit.component";
import { DataCatalogRequestsComponent } from "./components/data-catalog/data-catalog-requests/data-catalog-requests.component";
import { DataCatalogDetailsComponent } from "./components/data-catalog/data-catalog-details/data-catalog-details.component";
import { BlankLayoutComponent } from "./components/common/layouts/blank-layout/blank-layout.component";
import { NavigationComponent } from "./components/common/navigation/navigation.component";
import { ProfileImageComponent } from "./components/profile/profile-image/profile-image.component";
import { SideNavComponent } from "./components/common/side-nav/side-nav.component";
import { ModelEditStepOneComponent } from "./components/projects/models/edit/model-edit-step-one/model-edit-step-one.component";
import { ModelEditStepTwoComponent } from "./components/projects/models/edit/model-edit-step-two/model-edit-step-two.component";
import { ModelEditStepThreeComponent } from "./components/projects/models/edit/model-edit-step-three/model-edit-step-three.component";
import { ModelEditStepFourComponent } from "./components/projects/models/edit/model-edit-step-four/model-edit-step-four.component";
import { ModelEditStepFiveComponent } from "./components/projects/models/edit/model-edit-step-five/model-edit-step-five.component";
import { CatalogUserDashBoardComponent } from "./components/data-catalog/catalog-user-dash-board/catalog-user-dash-board.component";
import { CatalogUserTablesComponent } from "./components/data-catalog/catalog-user-tables/catalog-user-tables.component";
import { CartComponent } from "./components/data-catalog/cart/cart.component";
import { ModelViewStepOneComponent } from "./components/projects/models/detail/model-view-step-one/model-view-step-one.component";
import { ModelViewStepTwoComponent } from "./components/projects/models/detail/model-view-step-two/model-view-step-two.component";
import { ModelViewStepThreeComponent } from "./components/projects/models/detail/model-view-step-three/model-view-step-three.component";
import { ModelViewStepFourComponent } from "./components/projects/models/detail/model-view-step-four/model-view-step-four.component";
import { ModelViewStepFiveComponent } from "./components/projects/models/detail/model-view-step-five/model-view-step-five.component";
import { ColumnsComponent } from "./components/projects/data-sources/details/columns/columns.component";
import { ExtendInformationComponent } from "./components/projects/data-sources/details/extend-information/extend-information.component";
import { StreamingClusterComponent } from "./components/projects/data-sources/details/streaming-cluster/streaming-cluster.component";
import { SnapshotComponent } from "./components/projects/data-sources/details/snapshot/snapshot.component";
import { DataCatalogAccessComponent } from "./components/data-catalog/data-catalog-access/data-catalog-access.component";
import { DataCatalogRequestDetailsComponent } from "./components/projects/data-catalog/data-catalog-request-details/data-catalog-request-details.component";
import { DataCatalogRequestListComponent } from "./components/projects/data-catalog/data-catalog-request-list/data-catalog-request-list.component";
import { ProjectLayoutComponent } from "./components/common/layouts/project-layout/project-layout.component";
import { ProjectSideNavComponent } from "./components/common/project-side-nav/project-side-nav.component";
import { NvD3Module } from "ngx-nvd3";
import { CubeTreeComponent } from "./components/projects/query/cube-tree/cube-tree.component";
import { Ng2SearchPipeModule } from "ng2-search-filter";
import { OrderModule } from "ngx-order-pipe";
import { CalendarModule } from "primeng/calendar";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { NgxDaterangepickerMd } from "ngx-daterangepicker-material";
// import { AngularDateTimePickerModule } from 'angular2-datetimepicker';
import { OwlDateTimeModule, OwlNativeDateTimeModule } from "ng-pick-datetime";
import { PlannerComponent } from "./components/projects/cubes/detail/planner/planner.component";
import { NotificationComponent } from "./components/projects/cubes/detail/notification/notification.component";
import { PasswordResetComponent } from "./components/auth/password-reset/password-reset.component";
import { PasswordResetPageComponent } from "./components/auth/password-reset-page/password-reset-page.component";
import { GrdFilterPipe } from "./components/projects/grd-filter.pipe";
import { FilterPipe } from "./components/projects/query/filter.pipe";
import { FileUploadModule } from "ng2-file-upload";
import { CatalogHistoryComponent } from "./components/data-catalog/catalog-history/catalog-history.component";
import { FilePickerModule } from "ngx-awesome-uploader";
import { CustomPipe } from "./pipes/custom-pipe";
import { OWL_DATE_TIME_FORMATS } from "ng-pick-datetime";
import { OwlMomentDateTimeModule } from "ng-pick-datetime-moment";
import { AdministrationComponent } from "./components/administration/administration.component";
import { DataSourceComponent } from "./components/administration/data-source/data-source.component";
import { VisualModelComponent } from "./components/projects/models/visual-model/designer/visual-model.component";
import { VisualModelViewComponent } from "./components/projects/models/visual-model/view/visual-model-view.component";
import { AngularDraggableModule } from "angular2-draggable";
import { LicenceComponent } from "./components/licences/licence/licence.component";
import { VisualisationComponent } from "./components/projects/models/detail/visualisation/visualisation.component";
import { LicenceDetailsComponent } from "./components/licences/licence-details/licence-details.component";
import { VisualModelUpdateComponent } from "./components/projects/models/visual-model/update/visual-model-update.component";
import { PageNotFoundComponent } from "./components/page-not-found/page-not-found.component";

import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { library as fontLibrary } from "@fortawesome/fontawesome-svg-core";

import { faCalendar, faClock } from "@fortawesome/free-regular-svg-icons";
import { DateTimePickerComponent } from "./components/common/date-time-picker/date-time-picker.component";
import { NgxTrimDirectiveModule } from "ngx-trim-directive";
import { VisualCubeComponent } from "./components/projects/cubes/visual-cube/visual-cube.component";
import { FocusDirective } from "./components/common/focus.directive";
import { TimezoneFilterPipe } from "./components/projects/timezone-filter.pipe";
import { from } from "rxjs";
import { VisualCubeStep1Component } from "./components/projects/cubes/visual-cube/visual-cube-step1/visual-cube-step1.component";
import { VisualCubeStep2Component } from "./components/projects/cubes/visual-cube/visual-cube-step2/visual-cube-step2.component";
import { VisualCubeDimensionModalComponent } from "./components/projects/cubes/visual-cube/visual-cube-dimension-modal/visual-cube-dimension-modal.component";
import { VisualCubeStep3Component } from "./components/projects/cubes/visual-cube/visual-cube-step3/visual-cube-step3.component";
import { VisualCubeStep4Component } from "./components/projects/cubes/visual-cube/visual-cube-step4/visual-cube-step4.component";
import { VisualCubeStep5Component } from "./components/projects/cubes/visual-cube/visual-cube-step5/visual-cube-step5.component";
import { VisualCubeInfoComponent } from "./components/projects/cubes/visual-cube/visual-cube-info/visual-cube-info.component";
import { VisualCubeCanvasDataComponent } from "./components/projects/cubes/visual-cube/visual-cube-canvas-data/visual-cube-canvas-data.component";
import { VisualCubeEditDimensionModalComponent } from "./components/projects/cubes/visual-cube/visual-cube-edit-dimension-modal/visual-cube-edit-dimension-modal.component";
import { VisualCubeEditComponent } from "./components/projects/cubes/visual-cube/visual-cube-edit/visual-cube-edit.component";
import { StepOneComponent } from "./components/projects/data-sources/step-one/step-one.component";
import { ConfigureStreamingComponent } from "./components/projects/data-sources/configure-streaming/configure-streaming.component";
import { StreamingComponent } from "./components/projects/cubes/detail/streaming/streaming.component";
import { ConfigStreamingVtwoComponent } from "./components/projects/data-sources/config-streaming-vtwo/config-streaming-vtwo.component";
import { StreamingVtwoComponent } from "./components/projects/data-sources/streaming-vtwo/streaming-vtwo.component";
import { VisualCubeStep6Component } from "./components/projects/cubes/visual-cube/visual-cube-step6/visual-cube-step6.component";
import { VisualCubeStep7Component } from "./components/projects/cubes/visual-cube/visual-cube-step7/visual-cube-step7.component";
import { SegmentsComponent } from "./components/projects/cubes/segments/segments.component";
import { CoreServicesComponent } from "./components/core-services/core-services.component";
import { MetadataService } from "./services/ace-editor/metadata.service";
import { EditorComponent } from "./components/editor/editor.component";
import { CompleterService } from "./services/ace-editor/completer.service";
import { UserTrailComponent } from "./components/administration/user-trail/user-trail.component";
import { QueryAdminComponent } from "./components/query-admin/query-admin.component";
import { ViewQueryComponent } from "./components/view-query/view-query.component";
import { AssignmentComponent } from "./components/projects/cubes/assignment/assignment.component";
import { CronEditorModule } from "./components/common/cron-editor/cron-editor.module";
import { StreamConfigComponent } from "./components/projects/cubes/designer/streaming-config-new/stream-config.component";

fontLibrary.add(faCalendar, faClock);

export const MY_MOMENT_FORMATS = {
  parseInput: "LL LT",
  fullPickerInput:
    "YYYY-MM-DD HH:mm:ss" /* <---- Here i've rewrited the format */,
  datePickerInput: "LL",
  timePickerInput: "LT",
  monthYearLabel: "MMM YYYY",
  dateA11yLabel: "LL",
  monthYearA11yLabel: "MM YYYY",
};
@NgModule({
  declarations: [
    AppComponent,
    ProjectsComponent,
    MenuComponent,
    AuthComponent,
    DashboardComponent,
    UsersComponent,
    SettingsComponent,
    CubesComponent,
    JobsComponent,
    ProfileComponent,
    ModelsComponent,
    QueryComponent,
    QueryDetailComponent,
    UserManagementViewComponent,

    ConditionsSettingsComponent,
    DataModelComponent,
    ModelDimensionsComponent,
    ModelInfoComponent,
    ModelMeasuresComponent,
    AdvancedSettingsComponent,

    DimensionsComponent,
    InfoComponent,

    MeasuresComponent,
    OverviewComponent,
    RefreshSettingsComponent,
    StreamingConfigComponent,
    ModelCloneComponent,
    ModelDetailComponent,
    ModelEditComponent,
    ModelJsonEditComponent,
    StreamConfigComponent,
    CubeDetailComponent,

    HybridEditComponent,

    CreateSchedulerComponent,

    JobSchedulerViewComponent,
    JobSchedulerComponent,

    ProjectDataSourcesComponent,

    ProjectAccessComponent,

    CubeViewStepSevenComponent,
    ProjectDashboardComponent,
    CubeDesignerComponent,

    FooterComponent,
    SideNavComponent,
    BasicLayoutComponent,
    BlankLayoutComponent,
    NavigationComponent,
    ProfileImageComponent,
    EditUserComponent,
    EditComponent,
    DataCatalogRequestsComponent,
    DataCatalogDetailsComponent,
    ConfigStreamingComponent,
    ModelEditStepOneComponent,
    ModelEditStepTwoComponent,
    ModelEditStepThreeComponent,
    ModelEditStepFourComponent,
    ModelEditStepFiveComponent,
    JsonParseComponent,
    KafkaSettingsComponent,
    SqlQueryComponent,
    SavedQueriesComponent,
    QueryHistoryComponent,
    GrdFilterPipe,
    CubeViewStepOneComponent,
    CubeViewStepTwoComponent,
    CubeViewStepThreeComponent,
    CubeViewStepFourComponent,
    CubeViewStepFiveComponent,
    CatalogUserDashBoardComponent,
    CatalogUserTablesComponent,
    CartComponent,
    CubeViewStepSixComponent,
    DashBoardComponent,
    CatalogTablesComponent,
    CatalogColumnsComponent,
    FilterPipe,
    ModelViewStepOneComponent,
    ModelViewStepTwoComponent,
    ModelViewStepThreeComponent,
    ModelViewStepFourComponent,
    ModelViewStepFiveComponent,
    ColumnsComponent,
    ExtendInformationComponent,
    StreamingClusterComponent,
    SnapshotComponent,
    DataCatalogAccessComponent,
    DataCatalogRequestDetailsComponent,
    DataCatalogRequestListComponent,
    ProjectLayoutComponent,
    ProjectSideNavComponent,
    CubeTreeComponent,
    PlannerComponent,
    NotificationComponent,
    PasswordResetComponent,
    PasswordResetPageComponent,
    CatalogHistoryComponent,
    CustomPipe,
    AdministrationComponent,
    DataSourceComponent,
    VisualModelComponent,
    VisualModelViewComponent,
    LicenceComponent,
    VisualisationComponent,
    LicenceDetailsComponent,
    VisualModelUpdateComponent,
    PageNotFoundComponent,
    DateTimePickerComponent,
    VisualCubeComponent,
    FocusDirective,
    TimezoneFilterPipe,
    VisualCubeStep1Component,
    VisualCubeStep2Component,
    VisualCubeDimensionModalComponent,
    VisualCubeStep3Component,
    VisualCubeStep4Component,
    VisualCubeStep5Component,
    VisualCubeInfoComponent,
    VisualCubeCanvasDataComponent,
    VisualCubeEditDimensionModalComponent,
    VisualCubeEditComponent,
    StepOneComponent,
    ConfigureStreamingComponent,
    StreamingComponent,
    ConfigStreamingVtwoComponent,
    StreamingVtwoComponent,
    VisualCubeStep6Component,
    VisualCubeStep7Component,
    SegmentsComponent,
    CoreServicesComponent,
    EditorComponent,
    UserTrailComponent,
    QueryAdminComponent,
    ViewQueryComponent,
    AssignmentComponent,
  ],
  imports: [
    NgbModule,
    BrowserModule,
    SlimLoadingBarModule,
    NgbPopoverModule,
    NgbTooltipModule,
    NgbDatepickerModule,
    NgbTabsetModule,
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    ReactiveFormsModule,
    FormsModule,
    SweetAlert2Module.forRoot(),
    NgMultiSelectDropDownModule.forRoot(),
    NgxPaginationModule,
    AceEditorModule,
    DragDropModule,
    NgSelectModule,
    NvD3Module,
    Ng2SearchPipeModule,
    OrderModule,
    CalendarModule,
    BrowserAnimationsModule,
    NgxDaterangepickerMd.forRoot(),
    // AngularDateTimePickerModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    FileUploadModule,
    FilePickerModule,
    OwlDateTimeModule,
    OwlMomentDateTimeModule,
    AngularDraggableModule,
    FontAwesomeModule,
    CronEditorModule,
    NgxTrimDirectiveModule,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpConfigInterceptor,
      multi: true,
    },
    { provide: OWL_DATE_TIME_FORMATS, useValue: MY_MOMENT_FORMATS },
    AuthGuard,
    MetadataService,
    CompleterService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
