export class Users {
    username: string;
    firstName: string;
    lastName: string;
    displayName: string;
    emailId: string;
    authorities: string[];
    disabled: boolean;
    locked: boolean;
    lockedTime: number;
    wrongTime: number;
    image: string;
    imageUrl: string;
    accountNonExpired: boolean;
    accountNonLocked: boolean;
    credentialsNonExpired: boolean;
    enabled: boolean;
    uuid:string;
    colorTheme:string;
    qubzLicenseExpire: boolean;
    qubzLicenseExpireMsg: string;
    qubzLDAPUser: boolean;
    eula: boolean;
    constructor(user_response: any) {
        this.username = user_response.username;
        this.firstName = user_response.firstName;
        this.lastName = user_response.lastName;
        this.displayName = user_response.displayName;
        this.emailId = user_response.emailId;
        this.authorities = user_response.authorities;
        this.disabled = user_response.disabled;
        this.locked = user_response.locked;
        this.lockedTime = user_response.lockedTime;
        this.wrongTime = user_response.wrongTime;
        this.image = user_response.image;
        this.imageUrl = user_response.imageUrl;
        this.accountNonExpired = user_response.accountNonExpired;
        this.accountNonLocked = user_response.accountNonLocked;
        this.credentialsNonExpired = user_response.credentialsNonExpired;
        this.enabled = user_response.enabled;
        this.uuid = user_response.uuid;
        this.colorTheme=user_response.colorTheme;
        this.qubzLicenseExpire=user_response.qubzLicenseExpire;
        this.qubzLicenseExpireMsg=user_response.qubzLicenseExpireMsg;
        this.qubzLDAPUser=user_response.qubzLDAPUser;
        this.eula=user_response.eula;
    }
}
