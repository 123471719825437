import { Component, OnInit, Input, AfterViewInit } from '@angular/core';
import * as $ from 'jquery';

@Component({
  selector: 'app-model-view-step-five',
  templateUrl: './model-view-step-five.component.html',
  styleUrls: ['./model-view-step-five.component.scss']
})
export class ModelViewStepFiveComponent implements OnInit,AfterViewInit {
  @Input() modelDesc;
  constructor() { }

  ngOnInit() {
    if(this.modelDesc.filter_condition ==="Please input WHERE clause without typing 'WHERE'"){
      this.modelDesc.filter_condition ='';
    }
  }

  ngAfterViewInit() {
    $(document).ready(function(){
          var contenth = $( window ).height() - 110 ;
          var sidebarh = $( window ).height() - 111 ;
          $(".pagec").css("height", contenth );
          $(".sidebar-wrapper").css("height", sidebarh );
        });
    }

}
