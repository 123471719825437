import { Component, OnInit, Input, Output, EventEmitter, AfterViewInit } from '@angular/core';
import { QueryService } from 'src/app/services/query/query.service';
import { SqlQueryComponent } from '../sql-query/sql-query.component';
import * as d3 from "d3";
import * as moment from 'moment';
import { isInteger } from '@ng-bootstrap/ng-bootstrap/util/util';
import * as $ from 'jquery';
@Component({
  selector: 'app-query-detail',
  templateUrl: './query-detail.component.html',
  styleUrls: ['./query-detail.component.scss']
})
export class QueryDetailComponent implements OnInit,AfterViewInit {
  @Input() result;
  @Input() query;
  @Input() error;
  @Output() queryEvent: EventEmitter<any> = new EventEmitter();
  @Output() resultEvent: EventEmitter<any> = new EventEmitter();
  @Output() errorEvent: EventEmitter<any> = new EventEmitter();
  data;
  status;
  p: number = 1;
  startTime;
  options;
  chart;
  chartSelected;
  chartTypes = [
    {name: "Bar Chart", value: "bar", dimension: { types: ['date', 'string'], multiple: false }, metrics: { multiple: false }},
    {name: "Line Chart", value: "line", dimension: { types: ['date'], multiple: false }, metrics: { multiple: false }},
    {name: "Pie Chart", value: "pie", dimension: { types: ['date', 'string'], multiple: false }, metrics: { multiple: false }},
   
   
];
  meta;
  dateTypes = [91, 92, 93];
  stringTypes = [-1, 1, 12];
  numberTypes = [-7, -6, -5, 3, 4, 5, 6, 7, 8];
  constructor(private queryService: QueryService, private comp: SqlQueryComponent) { }
  public return(): void {
    this.comp.submit();
  }
  
  buildGraphMetadata(result) {

    if (!result.showgrid) {
      return;
    }
    result.graph={};
     result.graph.meta={
        dimensions : [],
        metrics:[]
      };
    // result.graph.meta.metrics = [];
    var datePattern = /_date|_dt/i;
    result.graph.type = this.chartTypes[0];
    let index=0;
 
    for(let meta of result.columnMetas) {
 
      if ((this.dateTypes.indexOf(meta.columnType) > -1 || datePattern.test(meta.name))) {
        result.graph.type = this.chartTypes[0];
        result.graph.meta.dimensions.push({
          column: meta,
          index: index,
          type: 'date'
        });
       
      }
      if (this.stringTypes.indexOf(meta.columnType) > -1) {
        result.graph.meta.dimensions.push({
          column: meta,
          index: index,
          type: 'string'
        });
        index++;
       
      }
      if (this.numberTypes.indexOf(meta.columnType) > -1) {  
        result.graph.meta.metrics.push({
          column: meta,
          index: index
        });
        
      }
    }
 
  }
  resetGraph(result) {
 
    if(result.graph.type=='line'){
      result.graph.type = this.chartTypes[1];
    }
    if(result.graph.type=='pie'){
      result.graph.type = this.chartTypes[2];
    }
    if(result.graph.type=='bar'){
      result.graph.type = this.chartTypes[0];
    }
    var dimension = (result.graph.meta.dimensions && result.graph.meta.dimensions.length > 0) ? result.graph.meta.dimensions[0] : null;
    var metrics = (result.graph.meta.metrics && result.graph.meta.metrics.length > 0) ? result.graph.meta.metrics[0] : null;
    result.graph.state = {
        dimensions: dimension,
        metrics: ((result.graph.type.metrics.multiple) ? [metrics] : metrics)
    };
    this.refreshGraphData(result);
  }
  refreshGraphData(result){
 
    if(!isNaN(result.graph.state.dimensions)){
      result.graph.state.dimensions=result.graph.meta.dimensions[result.graph.state.dimensions];
     
    }
     if(!isNaN(result.graph.state.metrics)){
      result.graph.state.dimensions=result.graph.meta.metrics[result.graph.state.metrics];
   
    }
    if (1) {
      this.chart = {
        data:[]
      };

      var selectedDimension = result.graph.state.dimensions;
 
      if (selectedDimension && result.graph.type.dimension.types.indexOf(selectedDimension.type) > -1) {
        this.chart = {
          data:[]
        };
 
          var chartType = result.graph.type.value;
          var selectedMetric = result.graph.state.metrics;
 
          var dataValues = [];
          result.results.forEach((res, ind) => {
 
              var data = {
                  label: res[selectedDimension.index],
                  value: parseFloat(res[selectedMetric.index])
              };
              if (selectedDimension.type === 'date' && chartType === 'line') {
                  data.label = parseInt(moment(data.label).format('X'));
              }
              dataValues.push(data);
          });

       //   dataValues = _.sortBy(dataValues, 'label');
          var oldLabel = dataValues[0].label;
          var groupValues = [{label: dataValues[0].label, value: 0}];
          dataValues.forEach((data) => {
              if (data.label === oldLabel) {
                  groupValues[groupValues.length-1].value += data.value;
              } else {
                  groupValues.push(data);
                  oldLabel = data.label;
              }
          });

          this.chart.data = [{
              key: selectedMetric.column.label,
              values: groupValues
          }];

          if (chartType === 'line') {
            this.chart.options = this.options.lineChartOptions;
              if (selectedDimension.type === 'date') {
                this.chart.options.chart.xAxis.tickFormat = function (d) {
                      return d3.time.format('%Y-%m-%d')(moment.unix(d).toDate());
                  };
              }
          } else if (chartType === 'bar') {
            this.chart.options = this.options.barChartOptions;
              if (groupValues.length > 15) {
                this.chart.options.chart.showLegend = false;
                this.chart.options.chart.xAxis.height = 100;
                this.chart.options.chart.margin.bottom =  150;
                this.chart.options.chart.xAxis.rotateLabels = -90;
                  if (groupValues.length > 50) {
                    this.chart.options.chart.showXAxis = false;
                  }
              }
          } else if (chartType === 'pie') {
            this.chart.options = this.options.pieChartOptions;
            this.chart.data = groupValues;
              if (groupValues.length > 15) {
                this.chart.options.chart.showLegend = false;
                this.chart.options.chart.showLabels = false;
              }
          }
      }
  } 
 
  }
  
  ngAfterViewInit() {
    $(document).ready(function(){
          var contenth = $( window ).height() - 110 ;
          var sidebarh = $( window ).height() - 111 ;
          $(".pagec").css("height", contenth );
          $(".sidebar-wrapper").css("height", sidebarh );
        });
    }
    
  ngOnInit() {
    this.startTime = localStorage.getItem('startTime');
    this.status=localStorage.getItem('status');
    this.options = {
      lineChartOptions: {
        chart: {
          type: 'lineChart',
          height: 500,
          margin : {
            top: 20,
            right: 55,
            bottom: 60,
            left: 55
          },
          useInteractiveGuideline: true,
          interpolate: 'cardinal',
          x: function(d){return d.label;},
          y: function(d){return d.value;},
          xAxis: {
            axisLabelDistance: 50,
            staggerLabels: false,
            tickFormat: function(d) {
              if (d.length > 10) {
                return d.substring(0,10) + '...';
              } else {
                return d;
              }
            }
          },
          yAxis: {
            tickFormat: function(d) {
              if (d < 1000) {
                if (parseFloat(d) === d) {
                  return d3.format('.1')(d);
                } else {
                  return d3.format('.2f')(d);
                }
              } else {
                var prefix = d3.formatPrefix(d);
                return prefix.scale(d) + prefix.symbol;
              }
            },
            showMaxMin: false
          },
          valueFormat: function(d){
            return d3.format('.1')(d);
          },
          transitionDuration: 500,
          tooltipContent: function (key, x, y, e, graph) {
            return '<h3>' + e.point.label + '</h3>' + '<p>' +  y + '</p>';
          }
        }
    },
    barChartOptions: {
      chart: {
        type: 'discreteBarChart',
        height: 500,
        margin : {
          top: 20,
          right: 20,
          bottom: 60,
          left: 55
        },
        x: function(d){return d.label;},
        y: function(d){return d.value;},
        xAxis: {
          axisLabelDistance: 50,
          staggerLabels: false,
          tickFormat: function(d) {
            if (d.length > 10) {
              return d.substring(0,10) + '...';
            } else {
              return d;
            }
          }
        },
        yAxis: {
          tickFormat: function(d) {
            if (d < 1000) {
              if (parseFloat(d) === d) {
                return d3.format('.1')(d);
              } else {
                return d3.format('.2f')(d);
              }
            } else {
              var prefix = d3.formatPrefix(d);
              return prefix.scale(d) + prefix.symbol;
            }
          },
          showMaxMin: false
        },
        valueFormat: function(d){
          return d3.format('.1')(d);
        },
        transitionDuration: 500,
        tooltipContent: function (key, x, y, e, graph) {
          return '<h3>' + e.point.label + '</h3>' + '<p>' +  y + '</p>';
        }
      }
    },
    pieChartOptions: {
      chart: {
        type: 'pieChart',
        height: 500,
        showLabels: true,
        duration: 500,
        labelThreshold: 0.01,
        labelSunbeamLayout: true,
        legend: {
          margin : {
            top: 20,
            right: 20,
            bottom: 60,
            left: 55
          }
        },
        x: function(d){return d.label;},
        y: function(d){return d.value;},
        valueFormat: function(d){
          return d3.format('.1')(d);
        }
      }
    }
    
  }
 

 
this.chartSelected=this.options.barChartOptions;
  }
 
 mappingToChartype(dimension) {
  return this.result.graph.type.dimension.types.indexOf(dimension.type) > -1;
}
}
