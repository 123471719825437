import {
  Component,
  OnInit,
  Input,
  AfterViewInit,
  ViewChild,
} from "@angular/core";
import swal from "sweetalert2";
import {
  FormGroup,
  FormControl,
  FormBuilder,
  Validators,
  FormArray,
} from "@angular/forms";
import { ModelStepsService } from "../../../../../services/model/model-steps.service";
import { CubesService } from "../../../../../services/cubes/cubes.service";
import { CubeDescriptionService } from "../../../../../services/cubes/cube-description.service";
import { ActivatedRoute, Router } from "@angular/router";
import * as $ from "jquery";
import { NgSelectComponent } from "@ng-select/ng-select";

@Component({
  selector: "app-model-edit-step-two",
  templateUrl: "./model-edit-step-two.component.html",
  styleUrls: ["./model-edit-step-two.component.scss"],
})
export class ModelEditStepTwoComponent implements OnInit, AfterViewInit {
  projectName: any;
  @Input() modelObj: any;
  @Input() tablesList: any;
  saved: boolean = true;
  joinTypes: string[] = ["Inner Join", "Left Join"];
  factTable: any;
  wizardDetails: any = {
    firstTableSelect: [],
    firstTable: {
      table: "",
      columns: [],
    },
    secondTableSelect: [],
    secondTable: {
      table: "",
      columns: [],
    },
    newLookup: {
      alias: "",
      joins: [],
      table: "",
      type: "",
    },
    joinSelection: "Inner Join",
  };
  searchText: string;
  joinType: any;
  aliasList: any = [];
  showModal: boolean = false;
  editMode: boolean;
  isFirstTblEnabled: boolean;
  isSecondTblEnabled: boolean;
  firstTable;
  lookUpTable;
  foreignkeyDtl: any;
  primarykeyDtl: any;
  lookUp;
  selectedLookup;
  checkJoin: any;
  form: FormGroup;
  dropDownList: FormArray;
  selFromTableColumn: any;
  selLookupColumn: any;
  fkType: any = [];
  pkType: any = [];
  fromTable;
  showAlaisError: boolean = true;
  unamePattern = "^[A-Z0-9_]{1,100}$";
  showjoinTable: boolean = false;
  fromTableColumns: any = [];
  selectLookupColumns: any = [];
  factEnable: boolean = true;
  disabledCubes: any = [];
  selectedAlias: any = {};
  selFromTable: any;
  selLookup: any;
  kind;
  @ViewChild("select") select: NgSelectComponent;

  constructor(
    private fb: FormBuilder,
    private modelStepsService: ModelStepsService,
    private cubesService: CubesService,
    private cubeDescService: CubeDescriptionService,
    private router: Router,
    private route: ActivatedRoute
  ) {
    this.form = fb.group({
      dropDowns: fb.array([]),
    });
    this.dropDownList = this.form.get("dropDowns") as FormArray;
  }

  ngAfterViewInit() {
    $(document).ready(function () {
      var contenth = $(window).height() - 110;
      var sidebarh = $(window).height() - 111;
      $(".pagec").css("height", contenth);
      $(".sidebar-wrapper").css("height", sidebarh);
    });
  }

  ngOnInit() {
    this.setFocus();
    this.projectName = this.route.snapshot.paramMap.get("projectId");
    this.factTable = this.modelObj.fact_table;
    this.searchText = null;
    this.checkCubeDisabled();
    this.joinType = this.joinTypes[0];
    if (
      this.modelObj.lookups !== undefined &&
      this.modelObj.lookups.length > 0
    ) {
      this.showjoinTable = false;
    } else {
      this.showjoinTable = true;
    }

    this.tablesList.sort((a, b) => a.name.localeCompare(b.name));
  }
  checkCubeDisabled() {
    let cubesList = null;

    this.cubesService
      .list({ modelName: this.modelObj.name, projectName: this.projectName })
      .subscribe((data) => {
        cubesList = data;

        for (let cube of cubesList) {
          if (cube.status === "DISABLED") {
            //this.disabledCubes.push(cube);
            this.cubeDescService.getCubeDetails(cube.name).subscribe((data) => {
              let cubeDesc = null;
              cubeDesc = data;
              for (let cubeData of cubeDesc) {
                for (let cubes of cubeData.dimensions) {
                  this.selectedAlias[cubes.table] = true;
                }
              }
            });
          }
        }
      });
  }

  getColumnsByTable(tableName) {
    for (let [key, value] of Object.entries(this.tablesList)) {
      let res = <any>{};
      res = value;
      for (let [key, value] of Object.entries(this.modelObj.lookups)) {
        let res1 = <any>{};
        res1 = value;
        if (res1.alias == tableName && res.name == res1.table) {
          return res.columns;
        }
      }
      if (res.name == tableName) {
        return res.columns;
      }
    }
  }
  onSelectTable(table, isTop) {
    if (isTop) {
      this.wizardDetails.firstTable.table = table;
      this.modelStepsService.editFirstTable =
        this.wizardDetails.firstTable.table;
      this.selFromTableColumn = this.getColumnsByTable(table);
    }
    if (!isTop) {
      this.wizardDetails.secondTable.table = table;
      this.selLookupColumn = this.getColumnsByTable(table);
    }
  }
  onChangeCondition(column, index, pf) {
    if (pf === "p") {
      if (!!column) {
        this.pkType =
          this.removeNamespace(this.wizardDetails.firstTable.table) +
          "." +
          column;
      } else {
        this.pkType = "";
      }
    }
    if (pf === "f") {
      if (!!column) {
        this.fkType =
          this.removeNamespace(this.wizardDetails.secondTable.table) +
          "." +
          column;
      } else {
        this.fkType = "";
      }
    }
  }
  onSelectJoinType(type) {
    if (type === "Inner Join") {
      this.wizardDetails.joinSelection = "inner";
    } else if (type === "Left Join") {
      this.wizardDetails.joinSelection = "left";
    } else {
      this.wizardDetails.joinSelection = "";
    }
  }

  resetWizardDetails() {
    this.wizardDetails = {
      firstTableSelect: [],
      firstTable: {
        table: "",
        columns: [],
      },
      secondTableSelect: [],
      secondTable: {
        table: "",
        columns: [],
      },
      newLookup: {
        alias: "",
        joins: [],
        table: "",
        type: "",
      },
      joinSelection: "inner",
    };
  }

  setWizardDetails(lookup) {
    for (let pk of lookup.join.foreign_key) {
      let nm = pk.split(".", 3);
      if (nm[0] !== this.modelObj.fact_table) {
        this.wizardDetails = {
          firstTableSelect: [],
          firstTable: {
            table: nm[0],
            columns: this.getColumnsByTable(nm[0]),
          },
          secondTableSelect: [],
          secondTable: {
            table: lookup.table,
            columns: this.getColumnsByTable(lookup.table),
          },
          newLookup: {
            alias: lookup.alias,
            kind: lookup.kind == "LOOKUP" ? false : true,
          },
          joinSelection: "inner",
        };
        this.fromTable = nm[0];
        this.lookUpTable = this.wizardDetails.secondTable.table;
      } else {
        this.wizardDetails = {
          firstTableSelect: [],
          firstTable: {
            table: this.modelObj.fact_table,
            columns: this.getColumnsByTable(this.modelObj.fact_table),
          },
          secondTableSelect: [],
          secondTable: {
            table: lookup.table,
            columns: this.getColumnsByTable(lookup.table),
          },
          newLookup: {
            alias: lookup.alias,
            kind: lookup.kind == "LOOKUP" ? false : true,
          },
          joinSelection: "inner",
        };
        this.fromTable = this.modelObj.fact_table;
        this.lookUpTable = this.wizardDetails.secondTable.table;
      }
    }
  }

  readyWizardEdit(lookup: any) {
    if (lookup.join.type == "inner") {
      this.joinType = "Inner Join";
    } else if (lookup.join.type == "left") {
      this.joinType = "Left Join";
    }

    this.clearFormArray();

    this.selectedLookup = lookup;
    let dropdown = [];
    let i = 0;

    this.dropDownList = this.form.get("dropDowns") as FormArray;
    for (let param of lookup.join.primary_key) {
      if (this.dropDownList.length < lookup.join.primary_key.length) {
        this.addJoinCondition();
      }
      let pkey = lookup.join.primary_key[i].split(".", 3);
      let fkey = lookup.join.foreign_key[i].split(".", 3);
      let fact = this.modelObj.fact_table.split(".", 3);

      let x = {
        selFromTableColumn: fkey[1],
        selLookupColumn: pkey[1],
      };
      if (fkey[0] == fact[1]) {
        this.wizardDetails.firstTable.table = this.modelObj.fact_table;
        this.onSelectTable(this.wizardDetails.firstTable.table, true);
        this.wizardDetails.secondTable.table = lookup.table;
        this.onSelectTable(this.wizardDetails.secondTable.table, false);
        this.getfromTableColumns(this.wizardDetails.firstTable.table);
        this.getSelectLookupTableColumns(this.wizardDetails.secondTable.table);
        dropdown.push(x);
      } else {
        this.wizardDetails.firstTable.table = fkey[0];
        this.onSelectTable(this.wizardDetails.firstTable.table, true);
        this.wizardDetails.secondTable.table = lookup.table;
        this.onSelectTable(this.wizardDetails.secondTable.table, false);
        this.getfromTableColumns(this.wizardDetails.firstTable.table);
        this.getSelectLookupTableColumns(this.wizardDetails.secondTable.table);
        dropdown.push(x);
      }

      i++;
    }
    this.form.patchValue({
      dropDowns: dropdown,
    });

    this.showModal = true;
    this.editMode = true;
    this.isFirstTblEnabled = false;
    this.isSecondTblEnabled = false;
    this.resetWizardDetails();
    this.setWizardDetails(lookup);

    this.saved = false;
    this.wizardDetails.firstTableSelect.push(this.modelObj.fact_table);
    for (let table of this.modelObj.lookups) {
      this.wizardDetails.firstTableSelect.push(table.alias);
    }
    for (let table of this.tablesList) {
      this.wizardDetails.secondTableSelect.push(table.name);
    }
  }
  readyWizard() {
    //this.showjoinTable = true;
    this.modelStepsService.dimensionList1 = undefined;
    this.showAlaisError = false;
    this.selFromTable = "-- From Table --";
    this.selLookup = "-- Select Lookup Table --";
    this.showModal = true;
    this.editMode = false;
    this.isFirstTblEnabled = true;
    this.isSecondTblEnabled = true;
    this.resetWizardDetails();
    this.wizardDetails.firstTableSelect.push(this.modelObj.fact_table);
    for (let table of this.modelObj.lookups) {
      this.wizardDetails.firstTableSelect.push(table.alias);
      this.modelStepsService.editFirstSelect =
        this.wizardDetails.firstTableSelect;
    }
    for (let table of this.tablesList) {
      this.wizardDetails.secondTableSelect.push(table.name);
    }
    this.saved = true;
    this.clearFormArray();
  }
  selectChangeHandler(factTable) {
    this.modelObj.lookups = [];
    this.modelObj.fact_table = factTable;
    //QUBZ-3929-In Edit Model Dimensions was not updated-14/11/22
    this.modelObj.fact_table_alias = this.removeNamespace(factTable);
    this.modelObj.partition_desc.partition_date_column = null;
    this.modelObj.partition_desc.partition_time_column = null;
    this.modelObj.dimensions = [];
    this.resetWizardDetails();
    this.modelStepsService.editFirstSelect = undefined;
    this.showjoinTable = true;
  }

  addJoinCondition() {
    this.dropDownList.push(this.createDropDownList());
    this.saveCheck();
  }

  removeJoinCondition(index: number) {
    this.dropDownList.removeAt(index);
    if (this.dropDownList.length <= 0) {
      this.saved = true;
    }
  }
  removeLookup(alias, name) {
    let tempDimensions = [];
    let tempLookups = [];

    let fromIndex = this.modelStepsService.frmTableList.indexOf(alias);

    //add all but table to be removed to temp array.

    for (let dim of this.modelObj.dimensions) {
      if (alias != dim.table) {
        tempDimensions.push(dim);
      }
    }

    if (
      this.modelStepsService.dimensionList1 !== undefined &&
      this.modelStepsService.dimensionList1.length > 0
    ) {
      swal({
        type: "warning",
        title:
          "Once it" +
          "s removed, all relative dimensions will be removed.<br/>Are you sure you want to remove the lookup table?",
        html: " ",
        showCancelButton: true,
        cancelButtonText: "No",
        confirmButtonText: "Yes",
      }).then(
        (status) => {
          if (status.value == true) {
            if (this.modelObj.lookups.length > 0) {
              for (let lookup of this.modelObj.lookups) {
                if (alias != lookup.alias) {
                  tempLookups.push(lookup);
                }
              }
              this.showjoinTable = false;
              this.modelObj.lookups = tempLookups;
            }
            this.modelObj.dimensions = tempDimensions;
          }
        },
        (dismiss) => {
          if (dismiss === "cancel") {
          }
        }
      );
      return;
    } else {
      this.modelObj.dimensions = tempDimensions;
      if (this.modelObj.lookups.length > 0) {
        for (let lookup of this.modelObj.lookups) {
          if (alias != lookup.alias) {
            tempLookups.push(lookup);
          }
        }
        this.showjoinTable = false;
        this.modelObj.lookups = tempLookups;
      } else {
        this.modelStepsService.editFirstSelect = undefined;
        this.showjoinTable = true;
      }
    }
  }
  removeNamespace(str: string) {
    if (str) {
      return str.replace(/([^.\s]+\.)+/, "");
    } else {
      return "";
    }
  }
  removeColname(str: string) {
    if (str) {
      return str.replace(/(\.+.*)+/, "");
    } else {
      return "";
    }
  }
  cancel() {
    this.resetWizardDetails();
    this.showAlaisError = true;
    this.showModal = false;
    this.clearFormArray();
  }

  saveCheck() {
    if (
      !!this.wizardDetails.firstTable.table &&
      !!this.wizardDetails.secondTable.table &&
      this.wizardDetails.secondTable.table !== "-- Select Lookup Table --" &&
      this.form.value.dropDowns.length > 0
    ) {
      if (!this.wizardDetails.newLookup.alias) {
        this.showAlaisError = false;
        this.saved = true;
      } else {
        let found = this.wizardDetails.newLookup.alias.match(this.unamePattern);
        if (!found) {
          this.showAlaisError = true;
          this.saved = true;
        } else {
          this.showAlaisError = false;
          this.saved = false;
        }
      }
    } else {
      if (!this.wizardDetails.newLookup.alias) {
        this.showAlaisError = false;
        this.saved = true;
      } else {
        let found = this.wizardDetails.newLookup.alias.match(this.unamePattern);
        if (found !== null) {
          if (!this.wizardDetails.newLookup.alias) {
            this.showAlaisError = false;
            this.saved = true;
          }
        }
      }
    }
  }

  saveToModelObj() {
    this.checkSameType(this.wizardDetails.newLookup.alias);
  }

  getAliasName() {
    this.aliasList = [];
    for (let table of this.tablesList) {
      if (table.name == this.wizardDetails.secondTable.table) {
        let name = table.name.split(".", 3);
        this.wizardDetails.newLookup.alias = name[1];
        this.aliasList.push(this.wizardDetails.newLookup.alias);
      }
    }
  }

  checkSameType(alias) {
    let frm = this.wizardDetails.firstTable.table.split(".", 3);
    let look = this.wizardDetails.secondTable.table.split(".", 3);
    if (frm[1] == alias) {
      swal({
        type: "warning",
        title: " ",
        text:
          "Table" +
          " " +
          "Alias" +
          " " +
          "[" +
          alias +
          "]" +
          " " +
          "already" +
          " " +
          "exist!",
        confirmButtonText: "OK",
      }).then(
        (status) => {
          if (status.value == true) {
          }
        },
        (dismiss) => {
          if (dismiss === "cancel") {
          }
        }
      );
      return;
    } else if (frm[1] == look[1] && frm[1] == alias && look[1] == alias) {
      swal({
        type: "warning",
        title: " ",
        text:
          "Table" +
          " " +
          "Alias" +
          " " +
          "[" +
          alias +
          "]" +
          " " +
          "already" +
          " " +
          "exist!",
        confirmButtonText: "Ok",
      }).then(
        (status) => {
          if (status.value == true) {
          }
        },
        (dismiss) => {
          if (dismiss === "cancel") {
          }
        }
      );
      return;
    } else if (
      this.modelStepsService.editFirstSelect !== undefined &&
      this.modelStepsService.editFirstSelect.length > 0
    ) {
      for (let param of this.modelStepsService.editFirstSelect) {
        let frm = param.split(".", 3);
        if (frm[1] == alias || frm[0] == alias) {
          swal({
            type: "warning",
            title: " ",
            text:
              "Table" +
              " " +
              "Alias" +
              " " +
              "[" +
              alias +
              "]" +
              " " +
              "already" +
              " " +
              "exist!",
            confirmButtonText: "Ok",
          }).then(
            (status) => {
              if (status.value == true) {
              }
            },
            (dismiss) => {
              if (dismiss === "cancel") {
              }
            }
          );
          return;
        }
      }
    }

    this.savekeysAlert(this.wizardDetails.newLookup.alias);
  }

  checkUpdateSameType(alias, selectedLookup) {
    let fact = this.modelObj.fact_table.split(".", 3);
    if (fact[1] == alias) {
      swal({
        type: "warning",
        title: " ",
        text:
          "Table" +
          " " +
          "Alias" +
          " " +
          "[" +
          alias +
          "]" +
          " " +
          "already" +
          " " +
          "exist!",
        confirmButtonText: "Ok",
      }).then(
        (status) => {
          if (status.value == true) {
          }
        },
        (dismiss) => {
          if (dismiss === "cancel") {
          }
        }
      );
      return;
    }

    let count = 0;
    for (let als of this.modelObj.lookups) {
      if (als.joinTable !== undefined) {
        let joinTb = als.joinTable.split(".", 3);
        if (joinTb[1] == alias) {
          swal({
            type: "warning",
            title: " ",
            text:
              "Table" +
              " " +
              "Alias" +
              " " +
              "[" +
              alias +
              "]" +
              " " +
              "already" +
              " " +
              "exist!",
            confirmButtonText: "Ok",
          }).then(
            (status) => {
              if (status.value == true) {
              }
            },
            (dismiss) => {
              if (dismiss === "cancel") {
              }
            }
          );
          return;
        }
      }

      if (als.alias == alias && als != selectedLookup) {
        swal({
          type: "warning",
          title: " ",
          text:
            "Table" +
            " " +
            "Alias" +
            " " +
            "[" +
            alias +
            "]" +
            " " +
            "already" +
            " " +
            "exist!",
          confirmButtonText: "Ok",
        }).then(
          (status) => {
            if (status.value == true) {
            }
          },
          (dismiss) => {
            if (dismiss === "cancel") {
            }
          }
        );
        return;
      }
    }
    if (count > 1) {
      swal({
        type: "warning",
        title: " ",
        text:
          "Table" +
          " " +
          "Alias" +
          " " +
          "[" +
          alias +
          "]" +
          " " +
          "already" +
          " " +
          "exist!",
        confirmButtonText: "Ok",
      }).then(
        (status) => {
          if (status.value == true) {
          }
        },
        (dismiss) => {
          if (dismiss === "cancel") {
          }
        }
      );
      return;
    }

    this.updatekeysAlert(alias, selectedLookup);
  }

  updateToModelObj(selectedLookup) {
    this.checkUpdateSameType(
      this.wizardDetails.newLookup.alias,
      selectedLookup
    );
  }

  createDropDownList(): FormGroup {
    return this.fb.group({
      selFromTableColumn: [],
      selLookupColumn: [],
    });
  }

  get dropDownFormGroups() {
    return this.form.get("dropDowns") as FormArray;
  }

  clearFormArray() {
    const control = <FormArray>this.form.controls["dropDowns"];
    for (let i = control.length - 1; i >= 0; i--) {
      control.removeAt(i);
    }
  }

  checkAlias(alias) {
    if (
      !!this.wizardDetails.firstTable.table &&
      !!this.wizardDetails.secondTable.table &&
      this.wizardDetails.secondTable.table !== "-- Select Lookup Table --" &&
      this.form.value.dropDowns.length > 0
    ) {
      if (!alias) {
        this.showAlaisError = false;
        this.saved = true;
      } else {
        let found = alias.match(this.unamePattern);
        if (!found) {
          this.showAlaisError = true;
          this.saved = true;
        } else {
          this.showAlaisError = false;
          this.saved = false;
        }
      }
    } else {
      if (!alias) {
        this.showAlaisError = true;
        this.saved = true;
      } else {
        let found = alias.match(this.unamePattern);
        if (!found) {
          this.showAlaisError = true;
          this.saved = true;
        } else {
          this.showAlaisError = false;
          this.saved = true;
        }
      }
    }
  }

  savekeysAlert(alias) {
    for (let param of this.form.value.dropDowns) {
      if (!param.selFromTableColumn && !param.selLookupColumn) {
        swal({
          type: "warning",
          title: "Primary Key cant be null.<br/> Foreign Key cant be null.",
          html: " ",
          confirmButtonText: "Ok",
        }).then(
          (status) => {
            if (status.value == true) {
            }
          },
          (dismiss) => {
            if (dismiss === "cancel") {
            }
          }
        );
        return;
      } else if (!param.selFromTableColumn) {
        swal("Warning", "Foreign key cant be null.", "warning");
        return;
      } else if (!param.selLookupColumn) {
        swal("Warning", "Primary key cant be null.", "warning");
        return;
      }
    }
    let from_table = [];
    let lookup_table = [];
    let from;
    from = this.wizardDetails.firstTable.table.split(".", 3);
    let looks;
    looks = this.wizardDetails.secondTable.table.split(".", 3);
    for (let joins of this.form.value.dropDowns) {
      if (this.wizardDetails.firstTable.table !== this.modelObj.fact_table) {
        from_table.push(from[0] + "." + joins.selFromTableColumn);
      } else {
        from_table.push(from[1] + "." + joins.selFromTableColumn);
      }

      if ((this.modelObj.lookups.alias = alias)) {
        lookup_table.push(alias + "." + joins.selLookupColumn);
      } else {
        lookup_table.push(looks[1] + "." + joins.selLookupColumn);
      }
    }
    let kind;

    if (this.wizardDetails.newLookup.kind) kind = "FACT";
    else kind = "LOOKUP";
    let lookUp = {
      alias: this.wizardDetails.newLookup.alias,
      join: {
        primary_key: lookup_table,
        foreign_key: from_table,
        type: this.wizardDetails.joinSelection,
      },
      kind: kind,
      table: this.wizardDetails.secondTable.table,
    };
    this.modelObj.lookups.push(lookUp);
    this.showjoinTable = false;
    this.saved = false;
    this.showModal = false;
    this.resetWizardDetails();
  }

  updatekeysAlert(alias, selectedLookup) {
    for (let param of this.form.value.dropDowns) {
      if (!param.selFromTableColumn && !param.selLookupColumn) {
        swal({
          type: "warning",
          title: "Primary key cant be null.<br/> Foreign key cant be null.",
          html: " ",
          confirmButtonText: "Ok",
        }).then(
          (status) => {
            if (status.value == true) {
            }
          },
          (dismiss) => {
            if (dismiss === "cancel") {
            }
          }
        );
        return;
      } else if (!param.selFromTableColumn) {
        swal("Warning", "Foreign key cant be null.", "warning");
        return;
      } else if (!param.selLookupColumn) {
        swal("Warning", "Primary key cant be null.", "warning");
        return;
      }
    }

    let from_table = [];
    let lookup_table = [];
    let from;
    from = this.wizardDetails.firstTable.table.split(".", 3);
    let looks;
    looks = this.wizardDetails.secondTable.table.split(".", 3);

    for (let joins of this.form.value.dropDowns) {
      if (from.length > 1) {
        from_table.push(from[1] + "." + joins.selFromTableColumn);
      } else {
        from_table.push(from[0] + "." + joins.selFromTableColumn);
      }

      if (alias) {
        lookup_table.push(
          this.wizardDetails.newLookup.alias + "." + joins.selLookupColumn
        );
      } else {
        lookup_table.push(looks[1] + "." + joins.selLookupColumn);
      }
    }
    let kind;
    if (this.wizardDetails.newLookup.kind) kind = "FACT";
    else kind = "LOOKUP";

    let dimension = this.modelObj.dimensions.find(
      ({ table }) => selectedLookup.alias === table
    );

    if (dimension) {
      dimension.table = this.wizardDetails.newLookup.alias;
    }

    let updatedLookup = {
      alias: this.wizardDetails.newLookup.alias,
      join: {
        primary_key: lookup_table,
        foreign_key: from_table,
        type: this.wizardDetails.joinSelection,
      },
      kind: kind,
      table: this.wizardDetails.secondTable.table,
    };

    let index = this.modelObj.lookups.indexOf(this.selectedLookup);

    if (index != -1) {
      this.modelObj.lookups.splice(index, 1, updatedLookup);
    }
    this.saved = true;
    this.showModal = false;
    this.resetWizardDetails();
  }

  getfromTableColumns(tables) {
    this.fromTableColumns = [];
    for (let [key, value] of Object.entries(this.tablesList)) {
      let res = <any>{};
      res = value;
      let databaseName = res.name;

      if (databaseName == tables) {
        for (let param of res.columns) {
          this.fromTableColumns.push({
            id: param.id,
            name: param.name,
            datatype: param.datatype,
          });
        }
      } else {
        for (let [key, value] of Object.entries(this.modelObj.lookups)) {
          let res1 = <any>{};
          res1 = value;
          if (res1.alias == tables && res.name == res1.table) {
            for (let param of res.columns) {
              this.fromTableColumns.push({
                id: param.id,
                name: param.name,
                datatype: param.datatype,
              });
            }
          }
        }
      }
    }
  }

  getSelectLookupTableColumns(lookTable) {
    this.selectLookupColumns = [];

    for (let [key, value] of Object.entries(this.tablesList)) {
      let res = <any>{};

      res = value;

      let databaseName = res.name;

      if (databaseName == lookTable) {
        for (let param of res.columns) {
          this.selectLookupColumns.push({
            id: res.id,
            name: param.name,
            datatype: param.datatype,
          });
        }
      }
    }
  }

  checkValueType(selectLookupColumns, fromTableColumns, i) {
    if (
      this.form.value.dropDowns[i].selFromTableColumn &&
      this.form.value.dropDowns[i].selLookupColumn
    ) {
      let from = this.form.value.dropDowns[i].selFromTableColumn;
      let lookup = this.form.value.dropDowns[i].selLookupColumn;

      if (from && lookup) {
        let pkey;
        let fkey;
        let fact = this.wizardDetails.firstTable.table.split(".", 3);
        if (fact.length > 1) {
          let looks = this.wizardDetails.secondTable.table.split(".", 3);
          for (let look of selectLookupColumns) {
            if (look.name == lookup) {
              pkey = look.datatype;
              break;
            }
          }
          for (let frm of fromTableColumns) {
            if (frm.name == from) {
              fkey = frm.datatype;
              break;
            }
          }
          if (fkey !== undefined && pkey !== undefined && fkey !== pkey)
            return (
              '<i class="fa fa-exclamation-triangle" aria-hidden="true"> </i>  Column Type incompatible' +
              "\n" +
              fact[1] +
              "." +
              from +
              "[" +
              fkey +
              "]" +
              ", &nbsp;" +
              looks[1] +
              "." +
              lookup +
              "[" +
              pkey +
              "]"
            );
          else {
            return "";
          }
        } else {
          let looks = this.wizardDetails.secondTable.table.split(".", 3);
          for (let look of selectLookupColumns) {
            if (look.name == lookup) {
              pkey = look.datatype;
              break;
            }
          }
          for (let frm of fromTableColumns) {
            if (frm.name == from) {
              fkey = frm.datatype;
              break;
            }
          }
          if (fkey !== undefined && pkey !== undefined && fkey !== pkey)
            return (
              '<i class="fa fa-exclamation-triangle" aria-hidden="true"> </i>  Column Type incompatible' +
              "\n" +
              fact[0] +
              "." +
              from +
              "[" +
              fkey +
              "]" +
              ", &nbsp;" +
              looks[1] +
              "." +
              lookup +
              "[" +
              pkey +
              "]"
            );
          else {
            return "";
          }
        }
      } else return "";
    }
  }

  removeAlias() {
    if (this.wizardDetails.secondTable.table === "-- Select Lookup Table --") {
      this.wizardDetails.newLookup.alias = "";
    }
  }

  setFocus() {
    this.select.focus();
  }
}
