import { Component, OnInit, AfterViewInit, Input } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { CubesService } from '../../../../../services/cubes/cubes.service';
import { Router,ActivatedRoute } from '@angular/router';
import swal from 'sweetalert2';
import { range } from 'rxjs';
import * as $ from 'jquery';
import { DatePipe } from '@angular/common'
@Component({
  selector: 'app-visual-cube-step4',
  templateUrl: './visual-cube-step4.component.html',
  styleUrls: ['./visual-cube-step4.component.scss']
})
export class VisualCubeStep4Component implements OnInit, AfterViewInit  {
  form: FormGroup;
  public thresholdList: FormArray;
  refreshSettings=[];
  projectId;
  editCube;
  settings;
  cubeStatus;
  hours=[0.5,0.15,0.25,1,2,4,8];
  types=[{'id':'Hours',name:'hours'},{'id':'Days',name:'days'}]
  @Input() defaultMeasure:any;
  @Input()visualCubeEditMode:any;
  @Input()volatileRange:any;
  @Input()retentionThreshold:any;
  @Input()partitionStartDate:any;



  constructor(public cubesService:CubesService,private fb: FormBuilder,private route: ActivatedRoute, private router:Router) {
    this.form = fb.group({
      volatileRange:[],
      retentionThreshold:[],
      partitionStartDate: new FormControl([], DateTimeValidator),updatedOn:'change',
      thresholds: fb.array([])
    })
    this.thresholdList = this.form.get('thresholds') as FormArray;
    this.projectId=this.route.snapshot.paramMap.get("projectId");
    // if(this.cubesService.cubeMeta.dimensions.length==0)
    // {
    //   this.router.navigate(['/projects/'+this.projectId+'/cubes/designer/info']);
    // }
    this.cubeStatus=this.cubesService.cubeStatus;
    this.editCube=this.route.snapshot.paramMap.get("cubeId");
    this.settings = {
      bigBanner: true,
      timePicker: true,
      format: 'dd-MMM-yyyy HH:mm:ss',
      defaultOpen: false
  }
    this.form.patchValue({
    partitionStartDate: '1970-01-01 00:00:00'
    });
   }
   ngAfterViewInit() {

    $(document).ready(function(){
      var contenth = $( window ).height() - 110 ;
      var sidebarh = $( window ).height() - 111 ;
      $(".pagec").css("height", contenth );
      $(".sidebar-wrapper").css("height", sidebarh );
    });

    
    setTimeout(function(){ 
    /* custome select option starts*/
    var x, i, j, selElmnt, a, b, c;
    x = document.getElementsByClassName("select-custom");
    for (i = 0; i < x.length; i++) {
      selElmnt = x[i].getElementsByTagName("select")[0];
      a = document.createElement("DIV");
      a.setAttribute("class", "select-selected");
      a.innerHTML = selElmnt.options[selElmnt.selectedIndex].innerHTML;
      x[i].appendChild(a);
      b = document.createElement("DIV");
      b.setAttribute("class", "select-items select-hide");
      for (j = 1; j < selElmnt.length; j++) {
        c = document.createElement("DIV");
        c.innerHTML = selElmnt.options[j].innerHTML;
        c.addEventListener("click", function(e) {
            var y, i, k, s, h;
            s = this.parentNode.parentNode.getElementsByTagName("select")[0];
            h = this.parentNode.previousSibling;
            for (i = 0; i < s.length; i++) {
              if (s.options[i].innerHTML == this.innerHTML) {
                s.selectedIndex = i;
                h.innerHTML = this.innerHTML;
                y = this.parentNode.getElementsByClassName("same-as-selected");
                for (k = 0; k < y.length; k++) {
                  y[k].removeAttribute("class");
                }
                this.setAttribute("class", "same-as-selected");
                break;
              }
            }
            h.click();
        });
        b.appendChild(c);
      }
      x[i].appendChild(b);
      a.addEventListener("click", function(e) {
        e.stopPropagation();
        closeAllSelect(this);
        this.nextSibling.classList.toggle("select-hide");
        this.classList.toggle("select-arrow-active");
      });
    }
    
    function closeAllSelect(elmnt) {
      var x, y, i, arrNo = [];
      x = document.getElementsByClassName("select-items");
      y = document.getElementsByClassName("select-selected");
      for (i = 0; i < y.length; i++) {
        if (elmnt == y[i]) {
          arrNo.push(i)
        } else {
          y[i].classList.remove("select-arrow-active");
        }
      }
      for (i = 0; i < x.length; i++) {
        if (arrNo.indexOf(i)) {
          x[i].classList.add("select-hide");
        }
      }
    }
    document.addEventListener("click", closeAllSelect);
    /* custom select option end */
    }, 3000);
  }
   
  ngOnInit() {
    
    if(this.cubesService.cubeMeta.auto_merge_time_ranges.length){
      let ranges=[];
      for(let param of this.cubesService.cubeMeta.auto_merge_time_ranges)
      {
        this.addThresholds();
        ranges.push(this.convertTimeRange(param));
      }
      var sortedArray = ranges.sort((n1,n2) => {
        if (n1.range > n2.range) {
            return 1;
        }
    
        if (n1.range < n2.range) {
            return -1;
        }
    
        return 0;
    });
      this.form.patchValue({
        thresholds:ranges
      });
    }
    else{
    this.addThresholds();
    this.addThresholds();
    this.addThresholds();
    this.addThresholds();
    this.addThresholds();
    this.form.patchValue({
      thresholds:[{range: "2", rangeType: "hours"},{range: "1", rangeType: "days"},{range: "7", rangeType: "days"},{range: "28", rangeType: "days"},{range: "365", rangeType: "days"}]
    });
  }
  if(this.cubesService.visualCubeEditMode==true){
    if(this.volatileRange){
      this.form.patchValue({
        volatileRange:this.volatileRange
      });
    }
    if(this.retentionThreshold){
      this.form.patchValue({
        retentionThreshold:this.retentionThreshold
      });
    }

    if(this.partitionStartDate){
      let dt=new Date(this.partitionStartDate);
      this.form.patchValue({
      
        partitionStartDate:new Date(dt.getUTCFullYear(), 
        dt.getUTCMonth(), 
        dt.getUTCDate(),  
        dt.getUTCHours(), 
        dt.getUTCMinutes(), 
        dt.getUTCSeconds())
      });
    }
  }
    
   
    //this.saveRefreshSettings();
  } 
  getType(i){
    if(this.form.value.thresholds[i].rangeType=='days')
      return "day";
    else if(this.form.value.thresholds[i].rangeType=='hours')  
      return "hour";
  }
  convertTimeRange(item){
    var _day = Math.floor(item/86400000);
    var _hour = (item%86400000)/3600000;
    if(_day==0){
      return {range: _hour, rangeType: "hours"};
    }else{
      return {range: _day, rangeType: "days"};
    }
  }
  get thresholdFormGroup() {
    return this.form.get('thresholds') as FormArray;
  }
  createThresholdList(): FormGroup {
    return this.fb.group({
      range: [0],
      rangeType:["days"],
    });
  }
  addThresholds(){
    this.thresholdList.push(this.createThresholdList());
    this.saveRefreshSettings();
  }
  deleteThresholds(index){
    swal(
      { 
      type:'warning', 
      title: 'Are you sure you want to remove?', 
      showCancelButton: true, confirmButtonText: 'Yes', 
      cancelButtonText: 'No'
      } 
      ).then((status) => {
      if(status.value==true){
        this.thresholdList.removeAt(index);
         this.saveRefreshSettings();
        // swal("Success!", "Dimension removed from List", "success");
      }
      }, (dismiss) => {
      if (dismiss === 'cancel') {
      }
      });
     
  }
  getTimeRange(item,type){
    if(item>=1)
    item=parseInt(item);
    let mills;
    if(type=='hours'){
      mills = item*3600000;
    }else{
      mills = item*86400000;
    }
    return mills;
  }
  saveRefreshSettings(){
    let auto_merge_time_ranges=[];
    for(let param of this.form.value.thresholds){
      auto_merge_time_ranges.push(this.getTimeRange(param.range,param.rangeType));
    }
      this.cubesService.cubeMeta.volatile_range=this.form.value.volatileRange?this.form.value.volatileRange:0;
      this.cubesService.cubeMeta.retention_range=this.form.value.retentionThreshold?this.form.value.retentionThreshold:0;
      this.cubesService.cubeMeta.auto_merge_time_ranges=auto_merge_time_ranges;
      if(this.form.value.partitionStartDate==""){
        this.form.value.partitionStartDate ='1970-01-01 00:00:00';
      }else{
        this.form.value.partitionStartDate = localStorage.getItem('dtString');
      }
      let time = new Date(this.form.value.partitionStartDate);
      let pdate=Date.UTC(time.getFullYear(), time.getMonth(), time.getDate(), time.getHours(), time.getMinutes(), time.getSeconds());
      this.cubesService.cubeMeta.partition_date_start=pdate?pdate:0;

  }
  next(page){
    this.saveRefreshSettings();
  }
}
export const DateTimeValidator = (fc: FormControl) => { 
  const date = new Date(fc.value);
  const isValid = !isNaN(date.valueOf());
  return isValid ? null : {
      isValid: {
          valid: false
      }
  };
}
