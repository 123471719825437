import { Component, OnInit } from '@angular/core';
import { StreamingService } from 'src/app/services/streaming/streaming.service';
import swal from 'sweetalert2';
import { CommonServiceService } from '../../../../services/common-services/common-service.service';

@Component({
  selector: 'app-streaming-vtwo',
  templateUrl: './streaming-vtwo.component.html',
  styleUrls: ['./streaming-vtwo.component.scss']
})
export class StreamingVtwoComponent implements OnInit {
  loggedUser;
  load;
  showModal;
  node;
  name;
  replica: any;
  interval;
  states;
  receiver_states;
  nodeList = [];
  receivers: any;
  showEditModal = false;
  selectedNode: any;
  dropdownSettings = {
    singleSelection: false,
    idField: "name",
    textField: "name",
    enableCheckAll: false,
    selectAllText: 'Select All',
    allowSearchFilter: true
  };
  showReceiver=false;
  receiverStats:any;
  cubeName;
  cubePartitions:any;
  consumer_stats:any;
  segment_stats:any;
  partition:any;
  cubeStats:any;
  constructor(private streamingService: StreamingService, public commonServiceService:CommonServiceService) {
    this.loggedUser = JSON.parse(sessionStorage.getItem("loggedUser"));
   }

  ngOnInit() {
    this.load=true;
    this.streamingService.getReplica().subscribe(
      data => {
      this.load=false;
        let res = <any>{};
        res = data;
        this.receivers = res.available_receivers;
        this.states = res.rs_states;
        if (res.rs_states)
          this.receiver_states = res.rs_states[0];
        this.replica = data;
        this.nodeList = [];
        for (let replica of res.available_receivers) {
          if (replica.receiver) {
            let host = replica.receiver.host + ':' + replica.receiver.port;
            this.nodeList = [...this.nodeList, { id: host, name: host }];
          }

        }
      }, error => {
      this.load=false;
      }
      );
    // this.interval = setInterval(() => {
    //   this.getReplica();
    // }, 5000);
    
    //this.saveLogs();
  }
  createReplica() {
    this.showModal = true;
    this.node = [];
  }
  hide() {
    this.showModal = false;
    this.showEditModal = false;
    this.showReceiver=false;
  }
  ngOnDestroy() {
    if (this.interval) {
      if (this.states)
        clearInterval(this.interval);
    }
  }
  clearInterval(interval) {
    this.interval = setInterval(() => {

    }, 0);
  }
  getReplica() {
    this.load=true;
    this.streamingService.getReplica().subscribe(
      data => {
      this.load=false;
      swal("Success", "Streaming receiver clusters refreshed succesfully", "success");
        let res = <any>{};
        res = data;
        this.receivers = res.available_receivers;
        this.states = res.rs_states;
        if (res.rs_states)
          this.receiver_states = res.rs_states[0];
        this.replica = data;
        this.nodeList = [];
        for (let replica of res.available_receivers) {
          if (replica.receiver) {
            let host = replica.receiver.host + ':' + replica.receiver.port;
            this.nodeList = [...this.nodeList, { id: host, name: host }];
          }

        }
      }, error => {
      this.load=false;
      }
      );
  }
  getStyle(replica) {
    if (replica == 'HEALTHY') {
      return "success-s";
    }
    if (replica == 'WARN') {
      return "warning-s";
    }
    if (replica == 'DOWN') {
      return "danger-s";
    }
    if (replica == 'UNREACHABLE') {
      return "info-s";
    }
    if (replica == 'AVAILABLE') {
      return "primary-s";
    }

  }
  saveReplica(node) {
    let newNodes = [];
    for (let n of node) {
      let host = n.name.split(':', 2);
      newNodes.push({ host: host[0], port: +host[1], properties: null });
    }

    this.streamingService.saveReplica(newNodes).subscribe(
      data => {
        swal("Success", "New replica successfully created", "success");
        this.getReplica();
        this.showModal = false;
      }, error => {
        swal("Ooops", error.error.msg, "error");
        this.showModal = false;
      });
    this.node = [];
  }
  editeReplica(replica) {
    this.selectedNode = replica;
    this.showEditModal = true;
    this.node=null;
  }
  editeReceiver(host, port, id) {
    swal(
      {
        type: 'warning',
        title: 'Are you sure you want to delete receiver? ',
        showCancelButton: true, confirmButtonText: 'Yes',
        cancelButtonText: 'No'
      }
    ).then((status) => {
      if (status.value == true) {
        this.streamingService.deleteReceiver(host + '_' + port, id).subscribe(
          data => {
            swal("Success", "Receiver successfully deleted", "success");
            this.showEditModal = false;
            this.getReplica();
          }, error => {
            swal("Ooops", error.error.msg, "error");
            this.showEditModal = false;
          });
      }
    }, (dismiss) => {
      if (dismiss === 'cancel') {
      }
    });
  }
  deleteReplica(id) {
    swal(
      {
        type: 'warning',
        title: 'Are you sure you want to delete replica set [' + id + ']? ',
        showCancelButton: true, confirmButtonText: 'Yes',
        cancelButtonText: 'No'
      }
    ).then((status) => {
      if (status.value == true) {
        this.streamingService.deleteReplica(id).subscribe(
          data => {
            swal("Success", "Selected replica successfully deleted", "success");
            this.showModal = false;
          }, error => {
            swal("Ooops", error.error.msg, "error");
            this.showModal = false;
          });
      }
    }, (dismiss) => {
      if (dismiss === 'cancel') {
      }
    });
  }
  addReceiver(node) {
    let newNodes;
    let host = node.split(':', 2);
    newNodes = host[0] + '_' + host[1];
    this.streamingService.addReceiver(newNodes, this.selectedNode.rs_id).subscribe(
      data => {
        swal("Success", "New receiver successfully added", "success");
         this.showEditModal=false;
         this.node=null;
        this.getReplica();
      }, error => {
        swal("Ooops", error.error.msg, "error");
         this.showEditModal=false;
      });
  }
  viewReceiver(host,port){
    this.showReceiver=true;
    this.streamingService.getReceiverStatus(host+'_'+port).subscribe(
      data => {
        
        this.receiverStats=data;
        let data1 = JSON.parse(JSON.stringify(this.receiverStats));
        for (var rsId in data1.assignments) {
         this.cubeName=rsId;
         this.cubePartitions=data1.assignments[rsId];
         this.consumer_stats=data1.cube_stats[rsId].consumer_stats;
         this.cubeStats=data1.cube_stats[rsId];
         let segmentArray = [];  

        for (let [key, value] of Object.entries(data1.cube_stats[rsId].segment_stats)) {
         let item:any=value;
         item.name=key;
         segmentArray.push(item);
        }
        let partitionArray = [];  
        for (let [key, value] of Object.entries(this.consumer_stats.partition_consume_stats)) {
          let item:any=value;
          item.name=key;
          partitionArray.push(item);
         }
         this.segment_stats=segmentArray;
         this.partition=partitionArray;
        }
      },error=>{
        swal("Ooops", 'Receiver assignment details not available', "error");
        this.showReceiver=false;
      });
  }

  saveLogs(){
    var today = new Date();
    let param={
      username:this.loggedUser.username,
      targetPage:'administration/streaming-receivers',
      day:today.getDay(),
      month:today.getMonth(),
      year:today.getFullYear()
    };
    this.commonServiceService.saveLogs(param).subscribe(
      data => {
  
      });
  }
}
