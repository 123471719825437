import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { Config } from '../../config';

@Injectable({
  providedIn: 'root'
})
export class StreamingService {
  showStatus;
  realTimeFormData: any;
  static url = Config.url + '/streaming';

  constructor(private http: HttpClient) { }
  setFormData(formData: any): void {
    this.realTimeFormData = formData;
  }

  getFormData(): any {
    return this.realTimeFormData;
  }

  saveStreaming(body) {
    return this.http.post(StreamingService.url, body);
  }
  updateStreaming(body) {
    return this.http.put(StreamingService.url, body);
  }
  getStreamingConfig(params) {
    return this.http.get(StreamingService.url + '/getConfig', { params });
  }
  getKfkConfig(params) {
    return this.http.get(StreamingService.url + '/getKfkConfig', { params });
  }
  getDateTimePatternForV2() {
    return this.http.get(Config.url + '/tables/supported_datetime_patterns');
  }
  saveStreamingForV2(body) {
    return this.http.post(Config.url + '/streaming_v2', body);
  }
  getConfig(db, table) {
    return this.http.get(Config.url + '/streaming_v2/getConfig?table=' + db + '.' + table);
  }

  getStreamingStatus(cube) {
    return this.http.get(Config.url + '/streaming_v2/cubes/' + cube + '/stats');
  }
  suspendConsume(cube) {
    return this.http.put(Config.url + '/streaming_v2/cubes/' + cube.name + '/suspendConsume', {});
  }
  //Qubz 6 UI - comment unwanted API calls for Realtime Streaming

  // consumeState(cube) {
  //   return this.http.get(Config.url + '/streaming_v2/cubes/' + cube + '/consumeState');
  // }
  resumeConsume(cube) {
    return this.http.put(Config.url + '/streaming_v2/cubes/' + cube.name + '/resumeConsume', {});
  }
  getReplica() {
    return this.http.get(Config.url + '/streaming_v2/cluster/state');
  }
  saveReplica(nodes) {
    let replica = { nodes: nodes };
    return this.http.post(Config.url + '/streaming_v2/replicaSet', replica);
  }
  deleteReplica(replica) {
    return this.http.delete(Config.url + '/streaming_v2/replicaSet/' + replica);
  }
  deleteReceiver(rec, id) {
    return this.http.delete(Config.url + '/streaming_v2/replicaSet/' + id + '/' + rec);
  }
  addReceiver(rec, id) {
    return this.http.put(Config.url + '/streaming_v2/replicaSet/' + id + '/' + rec, {});
  }
  getClusterConfig(project, table) {
    return this.http.get(Config.url + '/streaming/getKfkConfig?project=' + project + '&kafkaConfigName=' + table);
  }
  updateConfig(server) {
    return this.http.put(Config.url + '/streaming_v2/updateConfig', server);
  }
  assignment(cubeName) {
    const params = {
      cubeName: cubeName
    }
    return this.http.get(Config.url + '/streaming_v2/cubeAssignments', { params });
  }
  getClusters() {
    return this.http.get(Config.url + '/streaming_v2/replicaSets');
  }
  reAssign(cube, cubeObj) {

    return this.http.post(Config.url + '/streaming_v2/cubes/' + cube + '/reAssign', { cube_name: cubeObj.cube_name, assignments: cubeObj.assignments });
  }
  getReceiverStatus(receiver) {
    return this.http.get(Config.url + '/streaming_v2/receivers/' + receiver + '/stats');
  }

  deleteAssignment(cubeName: string) {
    return this.http.delete(`${Config.url}/streaming_v2/cubeAssignments/${cubeName}`)
  }
}