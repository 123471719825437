
import { Component, OnInit, Input, Output, EventEmitter, ChangeDetectionStrategy, ChangeDetectorRef, AfterViewInit, ElementRef } from '@angular/core';
import { ModelService } from '../../../../../services/model/model.service';
import { CubesService } from '../../../../../services/cubes/cubes.service';
import { CubeConfig } from '../../../../../models/cubes/cube-config';
import { NgForm } from '@angular/forms';
import swal from 'sweetalert2';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { routerNgProbeToken } from '@angular/router/src/router_module';
import { Router, ActivatedRoute } from '@angular/router';
import { ConstantPool } from '@angular/compiler';
import * as $ from 'jquery';
@Component({
  selector: 'app-dimensions',
  templateUrl: './dimensions.component.html',
  styleUrls: ['./dimensions.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DimensionsComponent implements OnInit, AfterViewInit {
  public cubeConfig: CubeConfig;
  dimensions: Object;
  showModal = false;
  dimensionModal = false;
  dimensionsObj: any;
  displayName: String;
  finalDimension = [];
  enableButton;
  form: FormGroup;
  pattern = "^[a-z0-9A-Z_]{1,100}$";
  projectId;
  editCube;
  factTable;
  cubeStatus;
  old_dimension;
  invalidName;
  currentIndex;
  flag: boolean;
  fkeys = [];
  changedColumns = [];
  factTables = [];
  showType: boolean;
  searchText: string;
  tableInfo: any;
  public models: any;
  page = 1;
  pageNumber: number;
  totalElements;
  factTableInfo: any;
  public focusSettingEventEmitter = new EventEmitter<boolean>();

  constructor(private modelService: ModelService, private cd: ChangeDetectorRef, fb: FormBuilder, private route: ActivatedRoute, public cubesService: CubesService, private router: Router) {
    this.form = fb.group({
      displayName: ['', [Validators.required, Validators.pattern(this.pattern)]],
      type: []
    })
    this.cubeConfig = new CubeConfig();
    this.projectId = this.route.snapshot.paramMap.get("projectId");
    this.editCube = this.route.snapshot.paramMap.get("cubeId");
    this.cubeStatus = this.cubesService.cubeStatus;
  }

  ngAfterViewInit() {
    $(document).ready(function () {
      var contenth = $(window).height() - 110;
      var sidebarh = $(window).height() - 111;
      $(".pagec").css("height", contenth);
      $(".sidebar-wrapper").css("height", sidebarh);
    });

    function progressli_check() {
      $(".progresslic ").each(function () {
        if ($(".progressbar li ").length > 6) {
          $(".menublock").addClass("tabw_seven")
        }
      })
    }
    setTimeout(progressli_check, 100);

    this.focusSettingEventEmitter.emit(true);

  }


  ngOnInit() {
    this.factTableInfo = this.cubesService.tableInfo;
    if (!this.cubesService.model)
      this.router.navigate(['/projects/' + this.projectId + '/cubes/designer/info']);

    else {
      this.searchText = null;
      let fact;
      fact = this.cubesService.model.fact_table.split('.');
      this.factTable = fact[1];

      this.getDimensions();
      this.finalDimension = this.cubesService.cubeMeta.dimensions;
      let dim = [];
      for (let [key, value] of Object.entries(this.dimensions)) {
        if (value.table == this.factTable) {
          for (let tabs of value.columns) {
            tabs.displayName = tabs.table;
          }

          dim.push(value);
        }


      }
      for (let [key, value] of Object.entries(this.dimensions)) {
        if (value.table != this.factTable) {
          for (let tabs of value.columns) {
            tabs.displayName = tabs.table;
          }


          dim.push(value);
        }

      }
      this.dimensions = dim;
    }

    if (this.dimensions) {
      for (let [key, dimension] of Object.entries(this.dimensions)) {
        this.getCheckStatus(dimension);
      }
    }

  }

  enableSubmitButton(dimensions){
    for(let dimension of dimensions){
      for(let dim of dimension.columns){
        if(dim.status==true){
          this.enableButton=true;
          //QUBZ-3931-For Creating cube Save option was disable on Add Dimensions Tad-14/11/22
          this.flag=false;
          this.cd.detectChanges();
          return;
        }
        
      }
    }
    this.enableButton=false;
    this.cd.detectChanges();
  }
  // Should not allow Cube with only Lookup table - code reverted 25/11/22
  // enableSubmitButton(dimensions){
  //   //QUBZ-3946 - Should not allow Cube with only Lookup table - 24/11/22
  //   console.log(dimensions);
  //   for(let dimension of dimensions){
  //     for(let dim of dimension.columns){
  //       if(dimension.kind === undefined){
  //         for(let dim1 of dimension.columns){
  //           if(dim1.status===true){
  //         this.enableButton=true;
  //         //QUBZ-3931-For Creating cube Save option was disable on Add Dimensions Tad-14/11/22
  //         this.flag=false;
  //         this.cd.detectChanges();
  //         return;}}
  //       } else {
  //         this.enableButton=false;
  //         this.flag=true;
  //         this.cd.detectChanges();
  //         return;
  //       }
        
  //     }
  //   }
  // }

  getCheckStatus(dimension) {

    let status = dimension.checkStatus;
    for (let dim of dimension.columns) {
      if (dim.status != true) {
        dimension.checkStatus = false;
        this.cd.detectChanges();
        return;
      }

    }
    dimension.checkStatus = true;
    this.cd.detectChanges();
    return true;
  }
  inputChange(dimensions1) {

    let pattern = "^[a-z0-9A-Z_]{1,100}$";
    this.enableButton = true;
    for (let dimension of dimensions1) {
      for (let param of dimension.columns) {
        if (!param.displayName.match(pattern)) {
          this.flag = true;
          return;
        }
      }
    }
    this.flag = false;


  }
  selectedAllStatus(dimension) {
    if (dimension.columns) {
      for (let dim of dimension.columns) {

        if (dim.status != true) {
          dimension.checkStatus = false;
          return;
        }

      }
      dimension.checkStatus = true;
    }
  }
  checkKind(table) {
    let model = this.cubesService.model;
    let lookups = model.lookups;
    for (let [key, value] of Object.entries(lookups)) {
      let res = <any>{};
      res = value;
      if (res.alias == table) {
        return res.kind;
      }
    }
  }
  getDimensions() {
    let kind;
    let model = this.cubesService.model;

    if (model && model.lookups.length > 0) {
      for (let look of model.lookups) {
        for (let fkeys in look.join.foreign_key) {
          let x = look.join.foreign_key[fkeys];
          if (fkeys.indexOf(x) == -1)
            this.fkeys.push(x);
        }
      }
    }
    let factTable = model.fact_table.split('.');
    this.dimensions = model.dimensions;

    let dimensions = this.cubesService.cubeMeta.dimensions;
    for (let [key, value] of Object.entries(this.dimensions)) {
      let arr = [];
      let status = false;
      let type = 'derived';
      kind = '';
      kind = this.checkKind(value.table);
      value.kind = kind;
      if (kind == 'FACT')
        type = 'Normal';

      for (let entry of value.columns) {

        status = false;
        type = 'derived';
        if (dimensions.length > 0) {
          if (entry.table) {
            for (let item of dimensions) {
              let nm = item.name;
              let xy = item.name.split('.', 2);
              if (xy.length > 0) {

                if (xy[1] == entry.table && value.table == item.table) {


                  status = true;
                  if (item.derived == null || kind == 'FACT')
                    type = 'Normal';
                  else
                    type = "derived";

                }
              }
              else {
                if (item.name == entry.table && value.table == item.table) {


                  status = true;
                  if (item.derived == null || kind == 'FACT')
                    type = 'Normal';
                  else
                    type = "derived";

                }
              }

            }


            let item = value.table + '.' + entry.table;
            if (this.fkeys.indexOf(item) != -1) {
              type = 'Normal'
            }
            arr.push({
              table: entry.table,
              status: status,
              type: factTable[1] == value.table || kind == 'FACT' ? '' : type,
              name: null
            });
          }
          else {


            if (dimensions.find(({ name, table }) => entry === name && value.table === table)) {
              let param = dimensions.find(({ name, table }) => entry === name && value.table === table);


              if (param && param.table == value.table) {
                param.status = true; status = true;
                if (param.derived == null || kind == 'FACT')
                  type = 'Normal';
                else {
                  type = 'derived';
                }
              }
            }


            let item = value.table + '.' + entry;
            if (this.fkeys.indexOf(item) != -1) {
              type = 'Normal'
            }
            arr.push({
              table: entry,
              status: status,
              type: factTable[1] == value.table || kind == 'FACT' ? '' : type,
              name: null
            });

          }
        }
        else {
          let item = value.table + '.' + entry;
          if (this.fkeys.indexOf(item) != -1) {
            type = 'Normal'
          }
          arr.push({
            table: entry,
            status: status,
            type: factTable[1] == value.table || kind == 'FACT' ? '' : type,
            name: null
          });
        }
        status = false;
      }
      value.columns = arr;
    }

    let savedDimesions = this.cubesService.cubeMeta.dimensions;

    if (savedDimesions.length > 0) {
      for (let [key, value] of Object.entries(this.dimensions)) {
        value.kind = kind;
        for (let cols of value.columns) {
          for (let save of savedDimesions) {
            let nm = save.name;
            let xy = save.name.split('.', 2);
            if (xy.length > 0) {
              if (xy[1] == cols.table && value.table == save.table) {
                cols.status = save.status;
                if (save.derived == null || kind == 'FACT')
                  cols.derived = 'normal';
              }
              else {
                if (save.name == cols.table && value.table == save.table) {
                  cols.status = save.status;
                  if (save.derived == null || kind == 'FACT')
                    cols.derived = 'normal';
                  else
                    cols.derived = 'derived';
                }
              }

            }
          }
        }
      }
    }
    this.cubesService.cubeMeta.dimensions = savedDimesions;

  }
  checkForignKey(table, column) {
    let item = table + '.' + column;
    if (this.fkeys.indexOf(item) > -1)
      return true;
    else
      return false;
  }
  checkDimesnionStatus() {

    let der_array = [];
    for (let [key, dimension] of Object.entries(this.dimensions)) {
      for (let dim of dimension.columns) {
        let der_array = [];
        der_array.push(dim.table)
        if (this.finalDimension.find(({ column, table }) => dim.table === column && dimension.table === table)) {
          dim.status = true;
        }

        else
          dim.status = false;
      }
    }
  }
  trackDimension(column, table) {
    column.tableName = table;
    if (this.changedColumns.indexOf(column) == -1)
      this.changedColumns.push(column)
  }
  openDimension() {
    // this.checkDimesnionStatus();

    this.old_dimension = this.dimensions;

    this.enableButton = false;
    this.showModal = true;

  }
  closeDimension() {
    for (let [key, value] of Object.entries(this.changedColumns)) {
      value.displayName = value.table;
      value.status = false;
      //QUBZ 6-QUBZ-3952 For Creating New Cube having issue -->code comment on 24/11/2022
      //if(this.fkeys.indexOf(value.tableName+'.'+value.table)==-1)
      //value.type='derived';
    }
    this.changedColumns = [];
    this.showModal = false;
    if (this.dimensions) {
      for (let [key, dimension] of Object.entries(this.dimensions)) {
        this.getCheckStatus(dimension);
      }
    }
  }
  hideDimension() {
    this.dimensionModal = false;
  }
  confirmDimension(dimensions) {
    this.showModal = false;
    this.finalDimension = [];
    for (let [key, value] of Object.entries(this.dimensions)) {
      let cols = value.columns;
      for (let [keys, values] of Object.entries(cols)) {
        let res = <any>{};
        res = values;
        if (res.status == true) {
          let derived = null;
          if (res.type == 'derived')
            derived = [res.table];
          let column = res.table;
          if (res.type == 'derived')
            column = null;
          let name = res.table.split('.', 2);
          this.finalDimension.push({
            column: column,
            status: res.status,
            table: value.table,
            name: res.displayName,
            derived: derived
          });
        };
      }
    }

  }

  dimensionEdit(dimension) {
    this.isFact(dimension.column);
    this.dimensionModal = true;
    this.dimensionsObj = dimension;
    this.form.patchValue({
      displayName: dimension.name ? dimension.name : dimension.column
    });
    if (dimension.table != this.factTable) {
      this.form.patchValue({
        displayName: dimension.name ? dimension.name : dimension.column,
        type: dimension.derived ? 'derived' : 'Normal'
      });
    }
  }
  isFact(table) {
    this.showType = false;
    let item = this.cubesService.model.lookups.find(({ alias }) => table === alias)
    if (item) {
      if (item.kind == 'FACT')
        return true;
      else
        return false;
    }
  }
  editDimensionData(dimension) {
    if (this.form.invalid) {
      return;

    }

    // dimension.name=this.form.value.displayName;
    // if(dimension.table!=this.factTable)
    // {
    let old_dim = dimension;
    for (let [key, value] of Object.entries(this.dimensions)) {
      for (let column of value.columns) {
        if (value.table == old_dim.table && column.table == (old_dim.derived ? old_dim.derived[0] : old_dim.column)) {
          column.displayName = this.form.value.displayName;
          if (this.form.value.type == 'derived') {
            column.type = 'derived';
            if (old_dim.derived)
              dimension.derived = old_dim.derived;
            else
              dimension.derived = [old_dim.column];
          }
          else {
            column.type = 'Normal';
            if (old_dim.derived)
              dimension.column = old_dim.derived[0];
            else
              dimension.column = old_dim.column;
          }
        }
      }
    }

    if (this.form.value.type == 'derived') {
      let x = [];
      x.push(dimension.name);
      dimension.name = this.form.value.displayName;
      dimension.displayName = this.form.value.displayName;
      if (old_dim.derived)
        dimension.derived = old_dim.derived;
      else
        dimension.derived = [old_dim.column];
      dimension.column = null;
    }
    else {
      if (old_dim.derived)
        dimension.column = old_dim.derived[0];
      else
        dimension.column = old_dim.column;
      dimension.derived = null;
      dimension.name = this.form.value.displayName;
      dimension.displayName = this.form.value.displayName;
    }
    // }
    this.dimensionModal = false;
    swal("Success!", "Dimension updated", "success");
  }
  deleteDimensionData(dimension) {
    swal(
      {
        type: 'warning',
        title: 'Are you sure you want to remove dimension?',
        showCancelButton: true, confirmButtonText: 'Yes',
        cancelButtonText: 'No'
      }
    ).then((status) => {
      if (status.value == true) {
        var index = this.finalDimension.indexOf(dimension);
        this.finalDimension.splice(index, 1);
        let item;
        for (let [key, value] of Object.entries(this.dimensions)) {
          if (value.table == dimension.table) {
            item = value.columns.find(({ displayName }) => dimension.name === displayName);
            break;
          }
        }
        if (item)
          item.status = false;
        swal("Success!", "Dimension removed from list", "success");
        this.cd.detectChanges();
      }
    }, (dismiss) => {
      if (dismiss === 'cancel') {
      }
    });


  }
  selectAll(dimension, status) {

    for (let value of dimension) {
      if (this.changedColumns.indexOf(value) == -1)
        this.changedColumns.push(value)
      value.status = status;
    }


  }
  buttonNext(page) {
    if (this.finalDimension.length == 0) {

      swal("Warning!", "Dimension can't be Null!", "warning");
      return;
    }
    let cubeObj = this.cubesService.getCubeObj();
    this.cubesService.cubeObj = this.finalDimension;
    this.cubesService.cubeMeta.dimensions = this.finalDimension;
    this.cubesService.listDimensionUpdated(this.cubesService.cubeMeta.dimensions)
    if (this.cubesService.editMode)
      this.router.navigate(['/projects/' + this.projectId + '/cubes/' + this.editCube + '/designer/' + page]);
    else
      this.router.navigate(['/projects/' + this.projectId + '/cubes/designer/' + page]);
  }
  buttonPrevious() {
    let cubeObj = this.cubesService.getCubeObj();
    this.cubesService.cubeObj = this.finalDimension;
    this.cubesService.cubeMeta.dimensions = this.finalDimension;
    this.cubesService.listDimensionUpdated(this.cubesService.cubeMeta.dimensions)
    if (this.cubesService.editMode)
      this.router.navigate(['/projects/' + this.projectId + '/cubes/' + this.editCube + '/designer/info']);
    else
      this.router.navigate(['/projects/' + this.projectId + '/cubes/designer/info']);
  }

  setFocus(): void {
    this.focusSettingEventEmitter.emit(true);
  }
}
