import { Component, OnInit, Input } from '@angular/core';

@Component({
	selector: 'app-cube-view-step-seven',
	templateUrl: './cube-view-step-seven.component.html',
	styleUrls: ['./cube-view-step-seven.component.scss']
})
export class CubeViewStepSevenComponent implements OnInit {
	@Input() cubeDesc;
	constructor() { }

	ngOnInit() {
	}


	
}
