import { Component, OnInit, Input, AfterViewInit  } from '@angular/core';
import * as $ from 'jquery';

@Component({
  selector: 'app-model-view-step-two',
  templateUrl: './model-view-step-two.component.html',
  styleUrls: ['./model-view-step-two.component.scss']
})
export class ModelViewStepTwoComponent implements OnInit,AfterViewInit {
  @Input() modelDesc;
  aliasName:any;
  searchText:string;
  constructor() { }

  ngOnInit() {
    this.aliasName = this.modelDesc.fact_table.split(".");
    this.searchText=null;
  }

  ngAfterViewInit() {
    $(document).ready(function(){
          var contenth = $( window ).height() - 110 ;
          var sidebarh = $( window ).height() - 111 ;
          $(".pagec").css("height", contenth );
          $(".sidebar-wrapper").css("height", sidebarh );
        });
    }

}
