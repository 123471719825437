import { Component, OnInit, Input, AfterViewInit, ViewChild } from '@angular/core';
import {ModelStepsService} from '../../../../../services/model/model-steps.service';
import { TableService } from '../../../../../services/table/table.service';
import { Router, ActivatedRoute } from '@angular/router';
import * as $ from 'jquery';
import { NgSelectComponent } from '@ng-select/ng-select';
import swal from 'sweetalert2';

@Component({
	selector: 'app-model-edit-step-five',
	templateUrl: './model-edit-step-five.component.html',
	styleUrls: ['./model-edit-step-five.component.scss']
})
export class ModelEditStepFiveComponent implements OnInit,AfterViewInit {
	@Input() modelObj: any;
	@Input() tablesList: any;
	projectId:any;
    partitionDateTableList:any=[];
 	partitonDateColumnList:any=[];
  	isPartitionDateColEnabled:boolean = true;
  	partitionDateTblName:any;
  	partitionDateColName:any;
  	partitionDateFormats=[
    	{name:"yyyy-MM-dd"},
		{name:"yyyyMMdd"},
		{name:"yyyy-MM-dd HH:mm:ss"},
		{name:"yyyy-MM-dd HH:mm"},
		{name:"yyyy-MM-dd HH"},
		{name:"yyyyMMddHH"},
		{name:"yyyyMMddHHMM"},
		{name:"yyyyMMddHHMMSS"},
 	]
    isPartitionDateFormatEnabled:boolean = true;
 	partitionDateFormatName:any;
  	
  	checkBoxName:boolean = false;
 	isPartitionTimeDetailsEnabled:boolean = true;
 	partitionTimeTblName:any;
  	isPartitionTimeColEnabled:boolean = true;
 	partitionTimeColName:any;
  	partitonTimeColumnList:any=[];
    partitionTimeFormats=[
   	 	{name:"HH:mm:ss"},
   		{name:"HH:mm"},
	    {name:"HH"}

    ]
 	modelRequest:any;
	modelData:any;

	dateColumnTableMap: Map<string, string[]>;
	partitionCheck:any;
	partitionDateList:any=[];
	partitonTimeList:any=[];
	partitionNewName:any;
	partitionTimeFormatName:any;
	tableServiceList;
	@ViewChild('select') select: NgSelectComponent;
	

	constructor(private tableService:TableService,private route: ActivatedRoute) { 
		
	}

	ngAfterViewInit() {
		$(document).ready(function(){
			  var contenth = $( window ).height() - 110 ;
			  var sidebarh = $( window ).height() - 111 ;
			  $(".pagec").css("height", contenth );
			  $(".sidebar-wrapper").css("height", sidebarh );
			});
		}
		
	ngOnInit() {
		this.setFocus();

		let params={
			ext:'true',
			project:this.route.snapshot.paramMap.get("projectId")
		  }
	
		if(this.modelObj.partition_desc.partition_date_column!== null){
			this.partitionDateTblName = this.removeColname(this.modelObj.partition_desc.partition_date_column);
			
			let col = this.modelObj.partition_desc.partition_date_column.split(".",2);
			this.partitionDateColName = col[1];
			this.partitionDateList.push({name:this.removeNamespace(this.modelObj.fact_table)});
			for(let param of this.modelObj.lookups){
				if(param.kind=='FACT'){
				  if(this.partitionDateTableList.indexOf(param.alias)==-1)
					this.partitionDateList.push({name:this.removeNamespace(param.alias)});
				}
			  }
			//this.partitonDateColumnList = this.setCols(this.partitionDateTblName);
			this.partitionDateFormatName = this.modelObj.partition_desc.partition_date_format;
		}else{
			this.partitionDateList.push({name:this.removeNamespace(this.modelObj.fact_table)});
			if(this.modelObj.lookups){
				for(let param of this.modelObj.lookups){
					if(param.kind=='FACT'){
					if(this.partitionDateTableList.indexOf(param.alias)==-1)
						this.partitionDateList.push({name:this.removeNamespace(param.alias)});
					}
				}
			}
			 
			this.partitionDateFormatName = this.partitionDateFormats[0].name;
		}
	
		
		if(!!this.partitionDateTblName && !!this.modelObj.partition_desc.partition_time_column){
				this.checkBoxName = true;
				let nm = this.modelObj.partition_desc.partition_time_column.split(".",3);
				this.partitionTimeTblName = nm[0];
				this.partitonTimeList.push({name:this.removeNamespace(this.modelObj.fact_table)});
				this.isPartitionTimeDetailsEnabled = false;
				this.partitionTimeColName = nm[1];
				this.selectPartitionTimeTblName(nm[0]);
				this.partitionTimeFormatName = this.modelObj.partition_desc.partition_time_format;
			
		}else{
			this.isPartitionTimeDetailsEnabled = true;
			this.partitonTimeList.push({name:this.removeNamespace(this.modelObj.fact_table)});
			this.selectPartitionTimeTblName(this.removeNamespace(this.modelObj.fact_table));
			this.partitionTimeFormatName = this.partitionTimeFormats[0].name;
			
		}
		this.tableService.list(params).subscribe(data=>{
			this.tableServiceList = data;
			if(this.partitionDateTblName){
					this.getDimensionsOnLoad(this.partitionDateTblName);
				let col = this.modelObj.partition_desc.partition_date_column.split(".",2);
				this.partitionDateColName = col[1];
			}
			if(this.partitionTimeTblName){
				this.getDimensionsTime(this.partitionTimeTblName);
				let nm = this.modelObj.partition_desc.partition_time_column.split(".",3);
				this.partitionTimeColName = nm[1];
			}
			});
			
	}

	getDimensionsOnLoad(tableName){
		this.partitonDateColumnList=[];

		let name;

		if(tableName==this.modelObj.fact_table.split('.',2)[1]){
		  name=this.modelObj.fact_table;
		}else{
		   let item=this.modelObj.lookups.find(({alias}) =>tableName===alias)

		   if(item)
			name=item.table;
			else
			name='';
		}

			let db=name.split('.',2);
		  let dims=(this.tableServiceList.find(({database,name}) =>db[0]===database && db[1]===name))

		  if(dims)
		  {
			for(let cols of dims.columns){
				 // QUBZ-3852 : 06/09/2022 QUBZ 6.0.0-In Synapse DB model creation Transaction_Date disappear in Partition column Added fix by cols.datatype=='date'
			if((cols.datatype.startsWith('varchar') || cols.datatype=='timestamp' || cols.datatype=='date' || cols.datatype=='integer') && cols.name!=this.partitionTimeColName)
			this.partitonDateColumnList.push(cols.name);
		  }
		}

	  }
	 
	getDimensions(tableName){
		this.partitonDateColumnList=[];
		this.partitionTimeTblName='';
		this.partitionTimeColName='';
		this.partitionDateColName='';
		this.isPartitionTimeDetailsEnabled = true;
		this.checkBoxName=false;
		let name;

		if(tableName==this.modelObj.fact_table.split('.',2)[1]){
		  name=this.modelObj.fact_table;
		}else{
		   let item=this.modelObj.lookups.find(({alias}) =>tableName===alias)

		   if(item)
			name=item.table;
			else
			name='';
		}

			let db=name.split('.',2);
		  let dims=(this.tableServiceList.find(({database,name}) =>db[0]===database && db[1]===name))

		  if(dims)
		  {
			for(let cols of dims.columns){
				 // QUBZ-3852 : 06/09/2022 QUBZ 6.0.0-In Synapse DB model creation Transaction_Date disappear in Partition column Added fix by cols.datatype=='date'
			if((cols.datatype.startsWith('varchar') || cols.datatype=='timestamp' || cols.datatype=='date' || cols.datatype=='integer') && cols.name!=this.partitionTimeColName)
			this.partitonDateColumnList.push(cols.name);
		  }
		}

	  }
	  getDimensionsTimeonLoad(tableName){
		//this.partitionTimeColName='';
	  this.partitonTimeColumnList=[];

	  let name;

	  if(tableName==this.modelObj.fact_table.split('.',2)[1]){
		name=this.modelObj.fact_table;
	  }else{
		 let item=this.modelObj.lookups.find(({alias}) =>tableName===alias)

		 if(item)
		  name=item.table;
		  else
		  name='';
	  }

		  let db=name.split('.',2);
		let dims=(this.tableServiceList.find(({database,name}) =>db[0]===database && db[1]===name))

		if(dims)
		{
		  for(let cols of dims.columns){
			 // QUBZ-3852 : 06/09/2022 QUBZ 6.0.0-In Synapse DB model creation Transaction_Date disappear in Partition column Added fix by cols.datatype=='date'
		  if((cols.datatype.startsWith('varchar') || cols.datatype=='timestamp' || cols.datatype=='date' || cols.datatype=='integer') && cols.name!=this.partitionDateColName)
		  this.partitonTimeColumnList.push(cols.name);
		}
	  }

	}
	  getDimensionsTime(tableName){
		  this.partitionTimeColName='';
		this.partitonTimeColumnList=[];

		let name;
		if(!tableName){
			this.partitionTimeColName=null;
		
			this.modelObj.partition_desc.partition_time_column = null;
		}
		if(tableName==this.modelObj.fact_table.split('.',2)[1]){
		  name=this.modelObj.fact_table;
		}else{
		   let item=this.modelObj.lookups.find(({alias}) =>tableName===alias)

		   if(item)
			name=item.table;
			else
			name='';
		}

			let db=name.split('.',2);
		  let dims=(this.tableServiceList.find(({database,name}) =>db[0]===database && db[1]===name))

		  if(dims)
		  {
			for(let cols of dims.columns){
				 // QUBZ-3852 : 06/09/2022 QUBZ 6.0.0-In Synapse DB model creation Transaction_Date disappear in Partition column Added fix by cols.datatype=='date'
			if((cols.datatype.startsWith('varchar') || cols.datatype=='timestamp' || cols.datatype=='date' || cols.datatype=='integer') && cols.name!=this.partitionDateColName)
			this.partitonTimeColumnList.push(cols.name);
		  }
		}

	  }
	showDateColumns(){
    if(this.partitionDateTblName!=='Select Partition Table'){
	  this.isPartitionDateColEnabled = false;
    } else {
      this.partitionDateColName = 'Select Partition Column';
      this.isPartitionDateColEnabled = true;
      this.isPartitionDateFormatEnabled = true;
      
	}
	if(!this.partitionDateTblName)
	this.partitionDateColName = null;
  }
	
	showPartitionTimeDetails() {
		this.isPartitionTimeDetailsEnabled = !this.isPartitionTimeDetailsEnabled;
		if (!this.isPartitionTimeDetailsEnabled && !this.modelObj.partition_desc.partition_time_column) {
			this.modelObj.partition_desc.partition_time_column = null;
		}
	}
	removeNamespace(str: string) {

		if(str) {
			return str.replace(/([^.\s]+\.)+/,'');
		} else {
			return ''
		}
	}
	removeColname(str: string) {
		if(str) {
			return str.replace(/(\.+.*)+/,'');
		} else {
			return ''
		}
		
	}
	selectPartitionTable(event) {
	
		this.partitionDateTblName = event;
		this.partitonDateColumnList = this.setCols(event);
		if(!event) {
			this.partitionDateColName=null;
			this.partitionTimeColName=null;
			this.modelObj.partition_desc.partition_date_column = null;
			this.modelObj.partition_desc.partition_time_column = null;
		}
	}
	selectPartitionColumn(event) {
		if(!!event) {
		//	this.partitionDateColName = event;
			this.modelObj.partition_desc.partition_date_column = this.partitionDateTblName+'.'+this.partitionDateColName;	
		} else {
			this.modelObj.partition_desc.partition_date_column = null;
		}
	}
	selectPartitionColumnTime(event){
		
	
		if(!!event) {
		//	this.partitionTimeColName = event;
			this.modelObj.partition_desc.partition_time_column = this.partitionTimeTblName+'.'+this.partitionTimeColName;
		} else {
			this.modelObj.partition_desc.partition_time_column = null;
		}
	}
	selectDateTimeFormat(event, dt: string){
		if(event == "") { 
			event = null;
		}
		if (dt === "D") {
			this.modelObj.partition_desc.partition_date_format = event;
		} else if (dt === "T") {
			this.modelObj.partition_desc.partition_time_format = event;
		}
	}
	selectPartitionTimeTblName(event) {
		//this.partitionTimeTblName = event;

		this.partitonTimeColumnList = [];
		if(!event) {
			this.partitonTimeColumnList = []
		} else {
			for (let col of this.partitonDateColumnList) {
				if (this.removeNamespace(this.modelObj.partition_desc.partition_date_column) !== col.name) {
					this.partitonTimeColumnList.push(col);
				}
			}
		}
	}
	setCols(x) {
		for(let dim of this.tablesList) {
			let name = dim.name.split(".",3);
			let db = this.modelObj.fact_table.split(".",3);
			if (this.partitionDateTblName === x && x == name[1] && this.partitionDateTblName === name[1] && name[0]==db[0]) {
				return dim.columns;
			}
		}
		return [];
	}
	isCheckBoxShow() {
		return !!this.modelObj.partition_desc.partition_date_column;
	}

	disableTimeColumn(){
		if(!this.isCheckBoxShow()){
			this.modelObj.partition_desc.partition_time_column = this.partitionTimeTblName+'.'+this.partitionTimeColName;
		}else{
			this.modelObj.partition_desc.partition_time_column = '';
		}
	}
	//QUBZ-3963 - In Edit Model Should not update without Partition Column&Date Format while choose Partition table - 29/11/22
	checkStatus(){
		//this function checks that either all the partition related inputs are filled or not filled and 
		//sets a boolean variable in service based on which the validation message is show while update is 
		//pressed
		if(((this.partitionDateTblName != 'Select Partition Table' && this.partitionDateTblName != null) && (!this.partitionDateColName || !this.partitionDateFormatName)) || ((this.partitionTimeTblName != "" && this.partitionTimeTblName != null) && (!this.partitionTimeColName || !this.partitionTimeFormatName)))
		{
			this.tableService.disableUpdate = true;
		}
		else{
			this.tableService.disableUpdate = false;
		}
	}
	
	setFocus(){
		this.select.focus();
	  }
}
