import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Config } from '../../config';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ModelService {

  static url = Config.url + '/models';
  constructor(private http: HttpClient) { }
  //QUBZ-3887 Cubes Storage size was not adding initially 23/9/22
  list(projectName): Observable<any> {
    return this.http.get(ModelService.url + '?projectName=' + projectName);
  }
  //api call for paginated models list
  listModels(projectName,page,pageSize): Observable<any> {
      return this.http.get(ModelService.url + '/pagination?projectName=' + projectName + '&page=' + page + '&size=' + pageSize);
  }
  getCubes(model) {
    return this.http.get(ModelService.url + '?modelName=' + model);
}
  getModelByName(model) {
    return this.http.get(ModelService.url + '?modelName=' + model);
}
  get(name) {
    return this.http.get(ModelService.url + '?modelName=' +name);
  }
  getModeldesc(name) {
    return this.http.get(Config.url + '/model/' +name);
  }
  drop(model) {
    return this.http.delete(ModelService.url + '/' + model);
  }
  dropAll(modelNames) {
    return this.http.delete(ModelService.url + '/mutlidelete/' + modelNames );
  }
  clone(modelName,param) {
    return this.http.put(ModelService.url + '/' + modelName + '/clone',param);
  }

 
  save(modelRequest) {
    return this.http.post(ModelService.url,modelRequest);
  }
  update(model) {
    return this.http.put(ModelService.url, model);
  }

  getAllModel(){
    return this.http.get(ModelService.url);
  }

  getListByModelNameProject(params){
    return this.http.get(ModelService.url , {params})
  }

  validateModelName(modelName){
    return this.http.get(ModelService.url + '/'+ 'validate' + '/' + modelName);
  }
}
