import {
  Component,
  OnInit,
  OnChanges,
  Input,
  Output,
  EventEmitter,
  AfterViewInit,
  SimpleChange,
  ViewChild,
} from "@angular/core";
import { QueryService } from "src/app/services/query/query.service";
import { ActivatedRoute } from "@angular/router";
import { Router } from "@angular/router";
import { QueryComponent } from "../query.component";
import * as $ from "jquery";
import { MetadataService } from "../../../../services/ace-editor/metadata.service";
import { CompleterService } from "../../../../services/ace-editor/completer.service";
import { EditorComponent } from "../../../../components/editor/editor.component";
import { CubesService } from "src/app/services/cubes/cubes.service";
import { Alert } from "selenium-webdriver";

@Component({
  selector: "app-sql-query",
  templateUrl: "./sql-query.component.html",
  styleUrls: ["./sql-query.component.scss"],
})
export class SqlQueryComponent implements OnInit, AfterViewInit {
  @ViewChild(EditorComponent) editComp: EditorComponent;
  options;
  @Input() mainQuery: string;
  @Input() result: string;
  @Input() autoCompleteList: any;
  @Output() queryEvent: EventEmitter<any> = new EventEmitter();
  @Output() resultEvent: EventEmitter<any> = new EventEmitter();
  @Output() errorEvent: EventEmitter<any> = new EventEmitter();
  error: string;
  projectId: string;
  limit: number = 50000;
  userName: any[];
  check: boolean = true;
  startTime;
  queryString = "";
  setQueryString;
  status;
  cachedQueries = [];
  queries = [];
  curQuery = null;
  cachedFilterQueries;
  statusList = [
    { name: "All", value: "" },
    { name: "Executing", value: "Executing" },
    { name: "Success", value: "Success" },
    { name: "Failed", value: "Failed" },
  ];
  statusData: string;
  searchText = null;
  listData;
  metadata$ = this.service.getMetadata();
  cubes: any;
  readyCubes: any;
  selectedCube: any = 'Auto';//New changes to add cubez Drop down in Insight query 12/07/1985
  qubzName:any;  //New changes to add cubez Drop down in Insight query 12/07/1985
  constructor(
    private queryService: QueryService,
    private route: ActivatedRoute,
    private router: Router,
    private comp: QueryComponent,
    private service: MetadataService,
    private completer: CompleterService,
    private cubeService: CubesService
    ){}
  getLimit(limit) {
    this.comp.getLimit(limit);
  }
  ngAfterViewInit() {
    $(document).ready(function () {
      var contenth = $(window).height() - 110;
      var sidebarh = $(window).height() - 111;
      $(".pagec").css("height", contenth);
      $(".sidebar-wrapper").css("height", sidebarh);
    });
  }
  ngOnInit() {
    this.projectId = this.route.snapshot.paramMap.get("projectId");
    const userInfo = JSON.parse(sessionStorage.getItem("credentials"));
    this.userName = userInfo.username;
    this.searchText = this.statusList[0].value;
    this.loadCubeDD();//cube dropdown data loaded 11/7/22
  }
  ngOnChanges(inputValueChanges: SimpleChange) {
    if (
      inputValueChanges.hasOwnProperty("autoCompleteList") &&
      inputValueChanges["autoCompleteList"].hasOwnProperty("currentValue")
    ) {
      this.listData = inputValueChanges["autoCompleteList"].currentValue;
    }
    this.getAllData();
  }
  Query = {
    createNew(sql, project) {
      var query = {
        originSql: sql,
        sql: sql,
        project: project,
        status: "Executing",
        acceptPartial: true,
        result: {
          isResponsePartial: false,
        },
        graph: {
          meta: {
            dimensions: [], //Keys metadata
            metrics: [], //Values metadata
          },
          state: {
            dimensions: [], // User selected dimensions
            metrics: [], // User selected metrics
          },
          type: {}, //Graph type
          show: false,
        },
        startTime: new Date(),
      };

      return query;
    },

    resetQuery: function (query) {
      query.status = "executing";
      query.result = {
        isResponsePartial: false,
      };
      query.acceptPartial = true;
      query.graph = {
        meta: {
          dimensions: [], //Keys metadata
          metrics: [], //Values metadata
        },
        state: {
          dimensions: [], // User selected dimensions
          metrics: [], // User selected metrics
        },
        type: {}, //Graph type
        show: false,
      };
      query.startTime = new Date();
    },
  };

  onChange($event) {
    this.mainQuery = $event;
  }
  submit() {
    this.comp.showresult();
    this.getLimit(this.limit);
    //New changes to add cubez Drop down in Insight query 12/07/2022 qubzName added
    this.comp.queryResult(this.mainQuery, this.limit, this.projectId,this.qubzName);

    //this.comp.ngOnInit();
    //this.router.navigate(["query"]);
  }
  onLimitChange(limit) {
    this.comp.queryResult(this.mainQuery, limit, this.projectId);
  }
  isChecked() {
    this.check = !this.check;
    if (this.check) {
      this.limit = 50000;
    } else {
      this.limit = 0;
    }
  }
  getAllData() {
    let meta = [];
    let tables = [];
    if (this.listData && this.listData.length > 0) {
      for (let row of this.listData) {
        let columns = [];
        if (row.columns && row.columns.length > 0) {
          for (let column of row.columns) {
            columns.push({ Name: column.column_NAME, Children: [] });
          }
        }
        tables.push({ Name: row.table_NAME, Children: columns });
      }

      meta.push({ Name: this.listData[0].table_SCHEM, Children: tables });
    }
    this.completer.setMetadata(meta);
  }

  clear() {
    this.mainQuery = "";
    this.editComp.clearQuery();
    this.limit = 50000;
  }
  //Added dropdown for cube data 11/07/22
  loadCubeDD() {
    this.cubeService.getProjectCubes(this.projectId).subscribe(cVal =>{
      this.cubes = cVal;
      this.readyCubes=this.cubes.filter((cube: any) => cube.status === "READY");
      this.readyCubes.unshift({"name":"Auto"});
      //console.log(this.readyCubes);
    })
  }
  getValue(event:any){
  
    this.qubzName=this.selectedCube;
    //saving selected cube name to query service
    this.queryService.ddlcubeName = this.qubzName;
    //calling query component function
    //this.comp.cubeNameAlert();
    
  }
  //Added dropdown for cube data 11/07/22
}
