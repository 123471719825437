import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-cube-view-step-two',
  templateUrl: './cube-view-step-two.component.html',
  styleUrls: ['./cube-view-step-two.component.scss']
})
export class CubeViewStepTwoComponent implements OnInit {
	 @Input() cubeDesc;
	 searchText;
	 page = 1;
	 pageNumber:number;
	 totalElements;
  	constructor() { }

  	ngOnInit() {
		
  	}

	isNormalCol(dimension: any) {
 
		if (dimension.derived) {
			return 'Dynamic'
		}
		return 'Static'
	}
}
