import { Injectable } from '@angular/core';
import { HttpClient,HttpParams } from '@angular/common/http';
import { Config } from '../../config';
@Injectable({
  providedIn: 'root'
})
export class CommonServiceService {
  static url = Config.url + '/useraudit';
  constructor(private http: HttpClient) { }
  isUserAdmin(authorities) {
    for (let authority of authorities){
    if (authority["authority"] == "ROLE_ADMIN") {
      return true;
      } 
    }
    return false;
    
  }
  saveLogs(params) {
    return this.http.post(CommonServiceService.url+'?username='+params.username+'&targetPage='+params.targetPage+'&day='+params.day+'&month='+params.month+'&year='+params.year,{params})
  }
}
