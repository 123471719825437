import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Config } from '../../config';

@Injectable({
  providedIn: 'root'
})
export class CubeDescriptionService {

  static url = Config.url + '/cube_desc';

  constructor(private http: HttpClient) { }
  getCubeDetails(cubeName) {
    return this.http.get(CubeDescriptionService.url+'/'+cubeName);
  }
  getCubeDescription(cubeName) {
    return this.http.get(CubeDescriptionService.url+'/'+cubeName+'/desc/');
  }
}
