import { Injectable } from '@angular/core';
import { HttpClient,HttpParams } from '@angular/common/http';
import { Config } from '../../config';

@Injectable({
  providedIn: 'root'
})
export class DataRequestService {

  static url = Config.url + '/dataaccessrequests';

  constructor(private http: HttpClient) { }

  deleteAllRequest(){
    return this.http.delete(DataRequestService.url);
  }
  getRequestList(page,size,isCompleted,projectId){
    const params = new HttpParams()
      .set('page', page)
      .set('size', size)
      .set('isCompleted', isCompleted)
      .set('deleteFlag','false')
      .set('projectId', projectId);
      return this.http.get(DataRequestService.url,{params});
  }
  deletedRequestList(page,size,isCompleted,projectId){
    const params = new HttpParams()
      .set('page', page)
      .set('size', size)
      .set('isCompleted', isCompleted)
     
      .set('projectId', projectId);
      return this.http.get(DataRequestService.url,{params});
  }
  getPendingRequest(page,isCompleted){
    const params = new HttpParams()
      .set('page', page)
      .set('isCompleted', isCompleted)
      return this.http.get(DataRequestService.url,{params});
  }
  getRequests(page,size,projectId){
    const params = new HttpParams()
      .set('page', page)
      .set('size', size)
      .set('projectId', projectId);
      return this.http.get(DataRequestService.url,{params});
  }
  saveRequest(request){
    return this.http.post(DataRequestService.url,request);
  }
  deleteRequestById(dataAccessRequestId){
    return this.http.delete(DataRequestService.url+'/'+dataAccessRequestId);
  }
  getAccessById(dataAccessRequestId){
      return this.http.get(DataRequestService.url+'/'+dataAccessRequestId);
  }
  getRequest(dataAccessRequestId){
    return this.http.get(DataRequestService.url+'/'+dataAccessRequestId);
  }
  getRequestById(dataAccessRequestId){
    return this.http.get(DataRequestService.url+'/'+dataAccessRequestId+'/dataRequestAllDetails');
  }
  getRequestByRequestId(dataAccessRequestId,page,size){
    const params = new HttpParams()
      .set('page', page)
      .set('size', size);
    return this.http.get(DataRequestService.url+'/'+dataAccessRequestId+'/dataRequestAllDetails',{params});
  }
  ApproveorRejectRequest(dataAccessRequestId,request){
    return this.http.put(DataRequestService.url+'/'+dataAccessRequestId+'/status',request);
  }
}
