import {
  Component,
  OnInit,
  HostListener,
  Input,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  AfterViewInit,
} from "@angular/core";
import { Source } from "webpack-sources";
import { ActivatedRoute, Router } from "@angular/router";
import { Table } from "src/app/models/table/table.model";
import { Columns } from "src/app/models/table/columns/columns.model";
import { TableService } from "src/app/services/table/table.service";
import swal from "sweetalert2";
import { ModelService } from "../../../../../services/model/model.service";
import { ItemsList } from "@ng-select/ng-select/ng-select/items-list";
import { NgForm } from "@angular/forms";
declare var $: any;
import * as $ from "jquery";
import { CommonServiceService } from "../../../../../services/common-services/common-service.service";

@Component({
  selector: "app-visual-model-update",
  templateUrl: "./visual-model-update.component.html",
  styleUrls: ["./visual-model-update.component.scss"],
})
export class VisualModelUpdateComponent implements OnInit, AfterViewInit {
  public redoStack = [];
  public undoStack = [];
  public data = { nodes: [], edges: [], ports: [], groups: [] };
  public canvasDataEdges = {
    top: false,
    bottom: false,
    left: false,
    right: false,
  };
  public canvasBoxTables = [{ position: { x: 0, y: 0 } }];
  public temporaryView: any = null;
  public canvasDataPosition = { x: 0, y: 0 };
  public canvasPreviewPosition = { x: 0, y: 0 };
  public canvasOffsetX: number;
  public canvasOffsetY: number;
  public canvasWidth: number;
  public canvasZoomIndex = 1;
  public canvasDataDraggable = true;
  public editableMode = false;
  public allActivePorts = [];
  public allActiveLines = [];
  public allScrolledTables = [];
  public isIE = false;
  isFullscreenvm;
  public searchText = "";
  /******Variables used in bootstrap models starts hete*****/
  public confirmation = { text: "", type: "", id: "", type_id: "" };
  public tempNodeData = {
    left: 0,
    top: 0,
    name: "",
    type: "",
    columns: [],
    id: "",
    headerText: "",
    alias: "",
    status: false,
    database: "",
    join: "",
    kind: "",
    fromTable: "",
    snapShoat: false,
    measure: false,
    errorMsg: "",
    selected: "",
  };
  public defaultColumn = {
    id: "",
    datatype: "varchar",
    primaryKey: false,
    node_id: "",
    isUpdate: 0,
    previousId: "",
    duplicateIssue: "",
  };
  public endPoint = { top: 0, left: 0 };
  public endPointX = 0;
  public startPointActiveId = "";
  public startPointLine = { top: 0, left: 0 };
  public draggingLine = {
    lineEndNode: null,
    lineStartNode: null,
    isDragging: false,
    clicked: false,
  };
  public tempRelationshipData = { source: null, target: null };
  public relationshipType: string = "LEFT";
  public lineColour = "#f76258";
  /******Variables used in bootstrap models starts hete*****/

  loggedUser;
  projectName: any;
  databases: any[];
  dbList: string[] = [];
  tableColumns: any[];
  load;
  tableList: Table[] = [];
  isActive: any[] = [];
  tableIsActive: any[] = [];
  tabIsActive: any[] = [];
  tableObj;
  checkAll;
  modelName;
  toggleState = true;
  toggleSwitchState = false;
  toggleStatec = false;
  toggleStatefs = false;
  isfontadjust = false;
  modelDescription;
  tableEditMode = false;
  partitionTimeEnable = false;
  joinTypes = [
    { name: "inner", value: "Inner Join" },
    { name: "left", value: "Left Join" },
  ];
  factTable;
  factDb;
  partitionDateTable;
  partitionDateColumnItems = [];
  partitionDateColumns;
  partitionTimeTable;
  partitionTimeColumns;
  activate: boolean;
  mode;
  public options = [
    { value: "Dimension", id: "Dimension" },
    { value: "off", id: "off" },
    { value: "Measure", id: "Measure" },
  ];
  positions = [
    { top: 50, left: 250 },
    { top: 50, left: -250 },
    { top: 250, left: 75 },
    { top: 250, left: -75 },
  ];
  partitionDateFormats = [
    { name: "yyyy-MM-dd" },
    { name: "yyyyMMdd" },
    { name: "yyyy-MM-dd HH:mm:ss" },
    { name: "yyyy-MM-dd HH:mm" },
    { name: "yyyy-MM-dd HH" },
    { name: "yyyyMMddHH" },
    { name: "yyyyMMddHHMM" },
    { name: "yyyyMMddHHMMSS" },
  ];
  partitionTimeFormats = [
    { name: "HH:mm:ss" },
    { name: "HH:mm" },
    { name: "HH" },
  ];
  partitionDateFormatName;
  paritionTimeColName;
  partitionTimeTblName;
  partitionTimeFormatName;
  filter_condition;
  value;
  partitionTimeColumnItems;
  modelObj;
  partition_desc = {
    partition_date_column: null,
    partition_time_column: null,
    partition_date_format: "yyyy-MM-dd",
    partition_time_format: "HH:mm:ss",
    partition_type: "APPEND",
    partition_date_start: null,
    partition_condition_builder: null,
  };
  unamePattern = "^[A-Za-z0-9_]{1,100}$";
  nodeStatus;
  aliasError;
  constructor(
    private route: ActivatedRoute,
    private cd: ChangeDetectorRef,
    private router: Router,
    private tableService: TableService,
    private modelSerivce: ModelService,
    public commonServiceService: CommonServiceService
  ) {
    this.loggedUser = JSON.parse(sessionStorage.getItem("loggedUser"));
  }

  public addEventInUndo(data: any): void {
    this.undoStack.push(data);
  }
  public addEventInRedo(data: any): void {
    this.redoStack.push(data);
  }
  public getItemFromUndo() {
    let lastElement = null;
    if (this.undoStack.length > 0) {
      lastElement = this.undoStack[this.undoStack.length - 1];
      this.undoStack.splice(this.undoStack.length - 1, 1);
    }
    return lastElement;
  }
  public getItemFromRedo() {
    let lastElement = null;
    if (this.redoStack.length > 0) {
      lastElement = this.redoStack[this.redoStack.length - 1];
      this.redoStack.splice(this.redoStack.length - 1, 1);
    }
    return lastElement;
  }
  public onClickRedo(): void {
    if (this.redoStack.length > 0) {
      let event = this.getItemFromRedo();
      if (event && event.type) {
        switch (event.type) {
          case "delete_node":
            if (event.node_id) {
              this.confirmation.type = "node";
              this.confirmation.type_id = event.node_id;
              let node = this.getNodeById(event.node_id);
              let allDeletedEdges = this.geAallEdgesFromNode(
                this.confirmation.type_id
              );
              this.addEventInUndo({
                type: "add_node",
                node: node,
                edges: allDeletedEdges,
              });
              this.deleteItem(false);
            }
            break;
          case "add_node":
            if (event.node) {
              this.data.nodes.push(event.node);
              this.addEventInUndo({
                type: "delete_node",
                node_id: event.node.id,
              });
              this.addEdgesfromColumn(event.edges);
            }
            break;
          case "update_node":
            if (event.node_id && event.name) {
              this.tempNodeData.id = event.node_id;
              this.tempNodeData.name = event.name;
              let node = this.getNodeById(event.node_id);
              this.addEventInUndo({
                type: "update_node",
                node_id: event.node_id,
                name: node.name,
              });
              this.AddTable(false);
            }
            break;
          case "delete_column":
            if (event.node_id && event.column_id) {
              this.confirmation.type = "column";
              this.confirmation.id = event.node_id;
              this.confirmation.type_id = event.column_id;
              let column = this.getColumnByidNodeIdAndColumnId(
                event.node_id,
                event.column_id
              );
              let allDeletedEdges = this.geAallEdgesFromColumn(
                event.node_id,
                event.column_id
              );
              this.addEventInUndo({
                type: "add_column",
                node_id: event.node_id,
                edges: allDeletedEdges,
                column: column,
              });
              this.deleteItem(false);
            }
            break;
          case "add_column":
            if (event.node_id && event.column) {
              this.defaultColumn.node_id = event.node_id;
              this.defaultColumn.id = event.column.id;
              this.defaultColumn.datatype = event.column.datatype;
              if (event.column.primaryKey) {
                this.defaultColumn.primaryKey = true;
              }
              this.defaultColumn.isUpdate = 0;
              this.addEventInUndo({
                type: "delete_column",
                node_id: event.node_id,
                column_id: event.column.id,
              });
              this.addColumn(false);
              this.addEdgesfromColumn(event.edges);
            }
            break;
          case "update_column":
            if (event.node_id && event.old_id && event.new_id) {
              this.defaultColumn.node_id = event.node_id;
              this.defaultColumn.id = event.old_id;
              this.defaultColumn.previousId = event.new_id;
              this.defaultColumn.isUpdate = 1;
              this.addEventInUndo({
                type: "update_column",
                node_id: event.node_id,
                old_id: event.new_id,
                new_id: event.old_id,
              });
              this.addColumn(false);
            }
            break;
          case "delete_line":
            if (event.line) {
              this.removeEdgeFromSourceAndTarget(
                event.line.source,
                event.line.target,
                false
              );
              this.addEventInUndo({ type: "add_line", line: event.line });
            }
            break;
          case "add_line":
            if (event.line) {
              this.addLineFromStack(event.line);
              this.addEventInUndo({ type: "delete_line", line: event.line });
            }
            break;
          case "shift_node":
            if (event.node_id && event.fromPosition && event.toPosition) {
              this.changePositionOfNode(event.node_id, event.fromPosition);
              this.addEventInUndo({
                type: "shift_node",
                node_id: event.node_id,
                fromPosition: event.toPosition,
                toPosition: event.fromPosition,
              });
            }
            break;
          default:
          //Nothing to do
        }
      }
    }
    setTimeout(() => {
      this.drawAllLines();
    }, 100);
  }
  public onClickUndo(): void {
    if (this.undoStack.length > 0) {
      let event = this.getItemFromUndo();
      if (event && event.type) {
        switch (event.type) {
          case "delete_node":
            if (event.node_id) {
              this.confirmation.type = "node";
              this.confirmation.type_id = event.node_id;
              let node = this.getNodeById(event.node_id);
              let allDeletedEdges = this.geAallEdgesFromNode(
                this.confirmation.type_id
              );
              this.addEventInRedo({
                type: "add_node",
                node: node,
                edges: allDeletedEdges,
              });
              this.deleteItem(false);
            }
            break;
          case "add_node":
            if (event.node) {
              this.data.nodes.push(event.node);
              this.addEventInRedo({
                type: "delete_node",
                node_id: event.node.id,
              });
              this.addEdgesfromColumn(event.edges);
            }
            break;
          case "update_node":
            if (event.node_id && event.name) {
              this.tempNodeData.id = event.node_id;
              this.tempNodeData.name = event.name;
              let node = this.getNodeById(event.node_id);
              this.addEventInRedo({
                type: "update_node",
                node_id: event.node_id,
                name: node.name,
              });
              this.AddTable(false);
            }
            break;
          case "delete_column":
            if (event.node_id && event.column_id) {
              this.confirmation.type = "column";
              this.confirmation.id = event.node_id;
              this.confirmation.type_id = event.column_id;
              let column = this.getColumnByidNodeIdAndColumnId(
                event.node_id,
                event.column_id
              );
              this.addEventInRedo({
                type: "add_column",
                node_id: event.node_id,
                column: column,
              });
              this.deleteItem(false);
            }
            break;
          case "add_column":
            if (event.node_id && event.column) {
              this.defaultColumn.node_id = event.node_id;
              this.defaultColumn.id = event.column.id;
              this.defaultColumn.datatype = event.column.datatype;
              if (event.column.primaryKey) {
                this.defaultColumn.primaryKey = true;
              }
              this.defaultColumn.isUpdate = 0;
              this.addEventInRedo({
                type: "delete_column",
                node_id: event.node_id,
                column_id: event.column.id,
              });
              this.addColumn(false);
              this.addEdgesfromColumn(event.edges);
            }
            break;
          case "update_column":
            if (event.node_id && event.old_id && event.new_id) {
              this.defaultColumn.node_id = event.node_id;
              this.defaultColumn.id = event.old_id;
              this.defaultColumn.previousId = event.new_id;
              this.defaultColumn.isUpdate = 1;
              this.addEventInRedo({
                type: "update_column",
                node_id: event.node_id,
                old_id: event.new_id,
                new_id: event.old_id,
              });
              this.addColumn(false);
            }
            break;
          case "delete_line":
            if (event.line) {
              this.removeEdgeFromSourceAndTarget(
                event.line.source,
                event.line.target,
                false
              );
              this.addEventInRedo({ type: "add_line", line: event.line });
            }
            break;
          case "add_line":
            if (event.line) {
              this.addLineFromStack(event.line);
              this.addEventInRedo({ type: "delete_line", line: event.line });
            }
            break;
          case "shift_node":
            if (event.node_id && event.fromPosition && event.toPosition) {
              this.changePositionOfNode(event.node_id, event.fromPosition);
              this.addEventInRedo({
                type: "shift_node",
                node_id: event.node_id,
                fromPosition: event.toPosition,
                toPosition: event.fromPosition,
              });
            }
            break;
          default:
          //Nothing to do//this.addEventInUndo({type:"shift_node", position:{left:event.x, top:event.y}});
        }
      }
    }
    setTimeout(() => {
      this.drawAllLines();
    }, 100);
  }
  /*Stack implementation ends here*/

  public canvasMovableClick = { data: false, table: false };
  public previousPoistion = { x: 0, y: 0 };

  @HostListener("mousemove", ["$event"])
  @HostListener("touchmove", ["$event"])
  dragMove(event) {
    let clientX = event.touches ? event.touches[0].clientX : event.clientX;
    this.endPointX = clientX;
    if (
      event.which == 1 &&
      this.draggingLine.isDragging &&
      this.draggingLine.clicked
    ) {
      let clientY = event.touches ? event.touches[0].clientY : event.clientY;
      this.endPoint = {
        top:
          clientY -
          9 -
          8 * this.canvasZoomIndex +
          Math.round(event.view.pageYOffset) -
          this.canvasOffsetY,
        left:
          clientX -
          9 -
          8 * this.canvasZoomIndex +
          Math.round(event.view.pageXOffset) -
          this.canvasOffsetX,
      };
      if (this.draggingLine.lineStartNode) {
        this.setLineStartPoint(
          this.draggingLine.lineStartNode.node,
          this.draggingLine.lineStartNode.column
        );
      }
      document.getSelection().removeAllRanges();
    }
    if (
      event.which == 1 &&
      !this.editableMode &&
      this.canvasMovableClick.data === true &&
      this.canvasMovableClick.table === false
    ) {
      if (this.previousPoistion.x == 0 && this.previousPoistion.y == 0) {
        this.previousPoistion.x = event.clientX;
        this.previousPoistion.y = event.clientY;
      } else {
        let changeX = this.previousPoistion.x - event.clientX;
        let changeY = this.previousPoistion.y - event.clientY;
        this.canvasDataPosition = {
          x: this.canvasDataPosition.x - changeX,
          y: this.canvasDataPosition.y - changeY,
        };
        this.canvasPreviewPosition = {
          x:
            this.canvasPreviewPosition.x + (changeX * this.canvasZoomIndex) / 8,
          y:
            this.canvasPreviewPosition.y + (changeY * this.canvasZoomIndex) / 8,
        };
        this.previousPoistion.x = event.clientX;
        this.previousPoistion.y = event.clientY;
      }
      setTimeout(() => {
        this.drawAllLines();
      }, 1);
    }
  }
  @HostListener("mousedown", ["$event"])
  @HostListener("touchstart", ["$event"])
  onMouseDownTouchStart(event) {
    if (!this.temporaryView) {
      this.draggingLine.isDragging = true;
      let clientX = event.touches ? event.touches[0].clientX : event.clientX;
      this.endPointX = clientX;
      if (this.draggingLine.clicked) {
        let clientY = event.touches ? event.touches[0].clientY : event.clientY;
        this.endPoint = {
          top:
            clientY + Math.round(event.view.pageYOffset) - this.canvasOffsetY,
          left:
            clientX + Math.round(event.view.pageXOffset) - this.canvasOffsetX,
        };
      }
      this.previousPoistion.x = 0;
      this.previousPoistion.y = 0;
    }
  }
  public setLineStartPoint(node_id, column_id) {
    if (!this.temporaryView) {
      node_id = node_id.toLowerCase();
      column_id = column_id.toLowerCase();
      let lineSpointLeft = $("#" + node_id + "-" + column_id + "-left");
      let lineSpointRight = $("#" + node_id + "-" + column_id + "-right");
      let startCenterX =
        (lineSpointLeft.position().left + lineSpointRight.position().left) / 2;
      if (this.endPointX > startCenterX) {
        //take right point
        this.startPointLine = {
          left: lineSpointRight.position().left - this.canvasOffsetX,
          top: lineSpointRight.position().top - this.canvasOffsetY,
        };
        this.startPointActiveId = node_id + "-" + column_id + "-right";
      } else {
        //take left point
        this.startPointLine = {
          left: lineSpointLeft.position().left - this.canvasOffsetX,
          top: lineSpointLeft.position().top - this.canvasOffsetY,
        };
        this.startPointActiveId = node_id + "-" + column_id + "-left";
      }
    }
  }
  @HostListener("mouseup", ["$event"])
  @HostListener("touchend", ["$event"])
  @HostListener("touchcancel", ["$event"])
  mouseUp(event) {
    let startingPoint = this.draggingLine.lineStartNode;
    let endingPoint = this.draggingLine.lineEndNode;
    let isDragging = this.draggingLine.isDragging;
    let clicked = this.draggingLine.clicked;
    if (
      isDragging &&
      clicked &&
      endingPoint &&
      startingPoint &&
      startingPoint.node != endingPoint.node
    ) {
      let source = startingPoint.node + "." + startingPoint.column;
      let target = endingPoint.node + "." + endingPoint.column;
      let isEdgePresent = false;
      for (let edge in this.data.edges) {
        if (
          (this.data.edges[edge].target == source &&
            this.data.edges[edge].source == target) ||
          (this.data.edges[edge].source == source &&
            this.data.edges[edge].target == target)
        ) {
          isEdgePresent = true;
        }
      }
      if (isEdgePresent == false) {
        this.chooseRelationshipType(startingPoint, endingPoint);
      }
    }
    this.draggingLine.isDragging = false;
    this.draggingLine.clicked = false;
    this.draggingLine.lineStartNode = null;
    this.draggingLine.lineEndNode = null;
    this.startPointLine = { left: 0, top: 0 };
    this.endPoint = { left: 0, top: 0 };
    this.startPointActiveId = "";
    this.canvasMovableClick = { data: false, table: false };
  }

  @HostListener("window:resize", ["$event"])
  onResize(event) {
    // this.drawAllLines();// event.target.innerWidth;
    setTimeout(() => {
      this.setCanvasPosition();
      this.drawAllLines();
    }, 10);
  }

  public chooseRelationshipType(startingPoint, endingPoint) {
    let edge = this.data.edges.find(
      ({ source, target }) =>
        startingPoint.node + "." + startingPoint.column === source &&
        endingPoint.node + "." + endingPoint.column === target
    );
    if (edge) {
      swal("Ooops", "Relationship already exist", "error");
      return;
    }
    for (let table of this.data.nodes) {
      if (table.id == endingPoint.node) {
        if (
          (table.fromTable.toLowerCase() != startingPoint.node &&
            table.fromTable) ||
          endingPoint.node == this.factTable.toLowerCase()
        ) {
          swal("Ooops", "Invalid relationship", "error");

          return;
        }
      }
    }
    let node = this.data.nodes.find(({ id }) => endingPoint.node === id);

    if (node) {
      node.fromTable = startingPoint.node;
    }
    this.tempRelationshipData.source = startingPoint;
    this.tempRelationshipData.target = endingPoint;
    let startNode = this.data.nodes.find(
      ({ id }) => this.tempRelationshipData.target.node.toLowerCase() === id
    );

    if (!startNode.join) {
      this.relationshipType = "inner";
      $("#editRelationship").modal("show");
    } else {
      this.relationshipType = "";
      this.drawLine(true);
    }
  }

  public drawLine(useStack) {
    let node;
    node = this.data.nodes.find(
      ({ id }) => this.tempRelationshipData.target.node === id
    );
    if (this.relationshipType != "") {
      if (node) {
        node.join = this.relationshipType;
      }
    } else {
      this.relationshipType = node.join;
    }
    let source =
      this.tempRelationshipData.source.node +
      "." +
      this.tempRelationshipData.source.column;
    let target =
      this.tempRelationshipData.target.node +
      "." +
      this.tempRelationshipData.target.column;
    let isEdgePresent = false;
    for (let edge in this.data.edges) {
      if (
        (this.data.edges[edge].target == source &&
          this.data.edges[edge].source == target) ||
        (this.data.edges[edge].source == source &&
          this.data.edges[edge].target == target)
      ) {
        isEdgePresent = true;
      }
    }
    if (isEdgePresent == false) {
      this.arrangeRows(source);
      this.arrangeRows(target);
      let data = {
        source: source,
        target: target,
        data: {
          type: this.relationshipType,
        },
      };
      this.data.edges.push(data);
      if (useStack) {
        this.addEventInUndo({ type: "delete_line", line: data });
      }
      this.updateAllScrolledTable();
      setTimeout(() => {
        this.drawAllLines();
      }, 100);
    }
  }
  public arrangeRows(item) {
    let column = item.split(".", 2);
    let table = this.data.nodes.find(
      ({ alias }) => column[0] === alias.toLowerCase()
    );
    if (table) {
      let item = table.columns.find(({ id }) => column[1] === id);

      let index = table.columns.indexOf(item);
      table.columns.splice(index, 1);
      table.columns.splice(0, 0, item);
    }
  }
  public drawAllLines(): void {
    let lines = [];
    let allPort = [];
    for (let line in this.data.edges) {
      let joinType = this.data.edges[line].data.type;
      let source = this.data.edges[line].source;
      let target = this.data.edges[line].target;
      let sourceNodeId = this.data.edges[line].source.split(".")[0];
      let targetNodeId = this.data.edges[line].target.split(".")[0];

      let sourceL = $("#" + source.replace(".", "-") + "-left");
      let sourceR = $("#" + source.replace(".", "-") + "-right");
      let targetL = $("#" + target.replace(".", "-") + "-left");
      let targetR = $("#" + target.replace(".", "-") + "-right");
      let x1L;
      let y1L;
      let x2L;
      let y2L;
      let x1R;
      let y1R;
      let x2R;
      let y2R;
      if (sourceL) {
        x1L = sourceL.position().left;
        y1L = sourceL.position().top;
      }
      if (targetL) {
        x2L = targetL.position().left;
        y2L = targetL.position().top;
      }

      if (sourceR) {
        x1R = sourceR.position().left;
        y1R = sourceR.position().top;
      }
      if (targetR) {
        x2R = targetR.position().left;
        y2R = targetR.position().top;
      }

      let startX = 0;
      let startY = 0;
      let sourcePort = "";
      let endX = 0;
      let endY = 0;
      let targetPort = "";
      let defaultArrow = true;
      if (x1L > x2R) {
        startX = x1L;
        startY = y1L;
        endX = x2R;
        endY = y2R;
        defaultArrow = false;
        sourcePort = "-left";
        targetPort = "-right";
      } else if (x1R < x2L) {
        startX = x1R;
        startY = y1R;
        endX = x2L;
        endY = y2L;
        // defaultArrow = true;
        sourcePort = "-right";
        targetPort = "-left";
      } else {
        let center1 = (x1L + x1R) / 2;
        let center2 = (x2L + x2R) / 2;
        if (center1 < center2) {
          if (y1R < y2R) {
            startX = x1L;
            startY = y1L;
            endX = x2L;
            endY = y2L;
            // defaultArrow = true;
            sourcePort = "-left";
            targetPort = "-left";
          } else {
            startX = x1R;
            startY = y1R;
            endX = x2R;
            endY = y2R;
            // defaultArrow = true;
            sourcePort = "-right";
            targetPort = "-right";
          }
        } else {
          if (y1R < y2R) {
            startX = x1R;
            startY = y1R;
            endX = x2R;
            endY = y2R;
            defaultArrow = false;
            sourcePort = "-right";
            targetPort = "-right";
          } else {
            startX = x1L;
            startY = y1L;
            endX = x2L;
            endY = y2L;
            // defaultArrow = true;
            sourcePort = "-left";
            targetPort = "-left";
          }
        }
      }
      if (this.allScrolledTables.indexOf(sourceNodeId) > -1) {
        //apply algorith for selecting appropriate source
        let leftCenterTop = $("#" + sourceNodeId + "leftCenterTop");
        let leftCenterBott = $("#" + sourceNodeId + "leftCenterBott");
        if (startY < leftCenterTop.position().top) {
          if (sourcePort == "-left") {
            let leftTop = $("#" + sourceNodeId + "leftTop");
            startX = leftTop.position().left;
            startY = leftTop.position().top;
          } else {
            let rightTop = $("#" + sourceNodeId + "rightTop");
            startX = rightTop.position().left;
            startY = rightTop.position().top;
          }
          sourcePort = "";
        } else if (startY > leftCenterBott.position().top) {
          if (sourcePort == "-left") {
            let leftBottom = $("#" + sourceNodeId + "leftBottom");
            startX = leftBottom.position().left;
            startY = leftBottom.position().top;
          } else {
            let rightBottom = $("#" + sourceNodeId + "rightBottom");
            startX = rightBottom.position().left;
            startY = rightBottom.position().top;
          }
          sourcePort = "";
        }
      }
      if (this.allScrolledTables.indexOf(targetNodeId) > -1) {
        //apply algorith for selecting appropriate source
        let leftCenterTop = $("#" + targetNodeId + "leftCenterTop");
        let leftCenterBott = $("#" + targetNodeId + "leftCenterBott");
        if (endY < leftCenterTop.position().top) {
          if (targetPort == "-left") {
            let leftTop = $("#" + targetNodeId + "leftTop");
            endX = leftTop.position().left;
            endY = leftTop.position().top;
          } else {
            let rightTop = $("#" + targetNodeId + "rightTop");
            endX = rightTop.position().left;
            endY = rightTop.position().top;
          }
          targetPort = "";
        } else if (endY > leftCenterBott.position().top) {
          if (targetPort == "-left") {
            let leftBottom = $("#" + targetNodeId + "leftBottom");
            endX = leftBottom.position().left;
            endY = leftBottom.position().top;
          } else {
            let rightBottom = $("#" + targetNodeId + "rightBottom");
            endX = rightBottom.position().left;
            endY = rightBottom.position().top;
          }
          targetPort = "";
        }
      }
      startX -= this.canvasOffsetX;
      startY -= this.canvasOffsetY;
      endX -= this.canvasOffsetX;
      endY -= this.canvasOffsetY;
      let joinData = this.getJoinTrasform(startX, startY, endX, endY);
      lines.push({
        x1: startX,
        y1: startY,
        x2: endX,
        y2: endY,
        joinType: joinType,
        joinData: joinData,
        source: source,
        target: target,
        defaultArrow: defaultArrow,
        targetPort: targetPort,
      });
      allPort.push(source.replace(".", "-") + sourcePort);
      if (targetPort) {
        allPort.push(target.replace(".", "-") + targetPort);
      }
    }
    this.allActiveLines = lines;
    this.allActivePorts = allPort;
  }
  public getJoinTrasform(cx, cy, ex, ey) {
    let radians = Math.atan2(ey - cy, ex - cx); // range (-PI, PI]
    let theta180 = radians * (180 / Math.PI); // rads to degs, range (-180, 180]
    let theta360 = theta180 < 0 ? 360 + theta180 : theta180; // range [0, 360)
    let transform = 0;
    let transformFactor = 1;
    if (theta360 >= 270) {
      //1nd quadrant
      transform = theta360;
      transformFactor = (1.1 * theta360) / 180;
    } else if (theta360 > 180) {
      //2 quadrant
      transform = theta360 - 180;
      transformFactor = 1.5;
    } else if (theta360 > 90) {
      //3 quadrant
      transform = theta360 - 180;
      transformFactor = 3 - theta360 / 100;
    } else {
      //4 quadrant
      transform = theta360;
      transformFactor = 2.2;
    }
    let joinX =
      cx +
      20 *
        transformFactor *
        this.canvasZoomIndex *
        Math.cos((theta360 * Math.PI) / 180);
    let joinY =
      cy +
      20 *
        transformFactor *
        this.canvasZoomIndex *
        Math.sin((theta360 * Math.PI) / 180);
    return { joinX: joinX, joinY: joinY, transform: transform };
  }
  public addEdgesfromColumn(edges) {
    for (let edge in edges) {
      this.data.edges.push(edges[edge]);
    }
  }
  public geAallEdgesFromNode(node_id) {
    let edges = [];
    for (let edge in this.data.edges) {
      let sourceNode = this.data.edges[edge].source.split(".")[0];
      let targetNode = this.data.edges[edge].target.split(".")[0];
      if (node_id == sourceNode || node_id == targetNode) {
        edges.push(this.data.edges[edge]);
      }
    }
    return edges;
  }
  public geAallEdgesFromColumn(node_id, column_id) {
    let edges = [];
    let edgeName = node_id + "." + column_id;
    for (let edge in this.data.edges) {
      if (
        edgeName == this.data.edges[edge].source ||
        edgeName == this.data.edges[edge].target
      ) {
        edges.push(this.data.edges[edge]);
      }
    }
    return edges;
  }
  public checkRelationCounts(target) {
    for (let edges of this.data.edges) {
      if (edges.target.split(".", 2)[0] == target.split(".", 2)[0]) return true;
    }
    return false;
  }
  public removeAllEdgesFromColumn(attribute): void {
    let allEdges = [];
    for (let line in this.data.edges) {
      if (
        this.data.edges[line].source == attribute ||
        this.data.edges[line].target == attribute
      ) {
        //removing the edges
      } else {
        allEdges.push(this.data.edges[line]);
      }
    }
    this.data.edges = allEdges;
    setTimeout(() => {
      this.drawAllLines();
    }, 100);
  }

  public removeAllEdgesFromTbale(node_id): void {
    let allEdges = [];
    for (let line in this.data.edges) {
      let sourceNode = this.data.edges[line].source.split(".")[0];
      let targetNode = this.data.edges[line].target.split(".")[0];
      if (sourceNode == node_id || targetNode == node_id) {
        //removing the edges
      } else {
        allEdges.push(this.data.edges[line]);
      }
    }
    this.data.edges = allEdges;
    setTimeout(() => {
      this.drawAllLines();
    }, 100);
  }

  public removeEdgeFromSourceAndTarget(source, target, useStack): void {
    let allEdges = [];
    let lineData = null;
    for (let line in this.data.edges) {
      let sourceNode = this.data.edges[line].source;
      let targetNode = this.data.edges[line].target;
      if (sourceNode == source && targetNode == target) {
        lineData = this.data.edges[line]; //removing the edges
      } else {
        allEdges.push(this.data.edges[line]);
      }
    }
    if (useStack) {
      this.addEventInUndo({ type: "add_line", line: lineData });
    }
    this.data.edges = allEdges;
    if (!this.checkRelationCounts(target)) {
      let node = this.data.nodes.find(
        ({ id }) => target.split(".", 2)[0] === id
      );
      if (node) {
        node.fromTable = "";
        node.join = "";
      }
    }
    setTimeout(() => {
      this.drawAllLines();
    }, 100);
  }

  public updateSourceAndTargetId(old_id, new_id): void {
    let allEdges = [];
    for (let line in this.data.edges) {
      if (this.data.edges[line].source == old_id) {
        allEdges.push({
          source: new_id,
          target: this.data.edges[line].target,
          data: { type: this.data.edges[line].data.type },
        });
      } else if (this.data.edges[line].target == old_id) {
        allEdges.push({
          source: this.data.edges[line].source,
          target: new_id,
          data: { type: this.data.edges[line].data.type },
        });
      } else {
        allEdges.push({
          source: this.data.edges[line].source,
          target: this.data.edges[line].target,
          data: { type: this.data.edges[line].data.type },
        });
      }
    }
    this.data.edges = allEdges;
    setTimeout(() => {
      this.drawAllLines();
    }, 100);
  }

  public checkDataEdge(event): void {
    this.canvasDataEdges = event;
  }

  public onCBTableStoped(event, cbTable, table) {
    this.tableObj = table;
    this.mode = "new";
    this.nodeStatus = "new";
    this.tempNodeData.name = this.tableObj.name;
    this.tempNodeData.alias = this.tableObj.name;
    this.tempNodeData.database = this.tableObj.database;
    let templeColumns = [];
    for (let param of this.tableObj.columns) {
      templeColumns.push({
        id: param.name.toLowerCase(),
        name: param.name,
        datatype: param.datatype,
        status: false,
      });
    }
    this.tempNodeData.columns = templeColumns;

    cbTable.position = { x: 0, y: 0 };
    if (
      this.canvasDataEdges.top &&
      this.canvasDataEdges.bottom &&
      this.canvasDataEdges.left &&
      this.canvasDataEdges.right
    ) {
      this.tempNodeData.headerText = "Enter table name:";
      $("#mangeNodeModel").modal("show");
      this.tempNodeData.headerText = "Enter table name:";
      let canvasWidthX = (this.canvasWidth - this.canvasOffsetX) / 2;
      canvasWidthX =
        event.x > canvasWidthX
          ? canvasWidthX * this.canvasZoomIndex
          : canvasWidthX / this.canvasZoomIndex;
      let left = event.x - canvasWidthX - this.canvasDataPosition.x;
      let top =
        event.y / this.canvasZoomIndex -
        this.canvasOffsetY -
        this.canvasDataPosition.y;
      this.tempNodeData.left = left;
      this.tempNodeData.top = event.y;
      this.tempNodeData.type = "table";
    }
  }
  public getTemporaryNodeId(): string {
    let nodeName = "Table name";
    for (let i = 1; i <= 100000000; i++) {
      let tempName = nodeName + i;
      if (this.checkDuplicateNameByNodeid("", tempName) === false) {
        nodeName = tempName;
        break;
      }
    }
    return nodeName;
  }
  public showView() {
    this.modelSerivce
      .getListByModelNameProject({
        modelName: this.modelName,
        projectName: this.projectName,
      })
      .subscribe((data) => {
        this.modelObj = data[0];
        this.modelDescription = this.modelObj.description;
        this.factTable = this.modelObj.fact_table.split(".", 2)[1];
        if (this.modelObj.partition_desc.partition_date_column)
          this.partitionDateTable =
            this.modelObj.partition_desc.partition_date_column.split(".", 2)[0];
        if (this.modelObj.partition_desc.partition_time_column) {
          this.partitionTimeTblName =
            this.modelObj.partition_desc.partition_time_column.split(".", 2)[0];
          this.partitionTimeEnable = true;
          this.toggleSwitchBtn();
        }
        this.filter_condition = this.modelObj.filter_condition;

        console.log("The Model Object", this.modelObj);

        this.data.nodes.push({
          left: 0,
          top: 0,
          name: this.modelObj.fact_table.split(".", 2)[1],
          type: "table",
          columns: [],
          id: this.modelObj.fact_table.split(".", 2)[1].toLowerCase(),
          headerText: "",
          alias: this.modelObj.fact_table_alias,
          status: false,
          database: this.modelObj.fact_table.split(".", 2)[0],
          join: "",
          kind: "FACT",
          fromTable: "",
          snapShoat: false,
          measure: false,
        });

        for (let dims of this.modelObj.lookups) {
          console.log("dims", dims);
          this.data.nodes.push({
            left: 0,
            top: 0,
            name: dims.table.split(".", 2)[1],
            type: "table",
            columns: [],
            id: dims.alias.toLowerCase(),
            headerText: "",
            alias: dims.alias,
            status: false,
            database: dims.table.split(".", 2)[0],
            join: dims.join.type,
            kind: dims.kind,
            fromTable: dims.join.foreign_key[0].split(".", 2)[0],
            snapShoat: false,
            measure: false,
          });
          for (let looks in dims.join.primary_key) {
            {
              this.data.edges.push({
                source: dims.join.foreign_key[looks].toLowerCase(),
                target: dims.join.primary_key[looks].toLowerCase(),
                data: {
                  type: dims.join.type,
                },
              });
            }
          }
        }
        let params = {
          ext: true,
          project: this.projectName,
        };

        this.tableService.list(params).subscribe((data: any[]) => {
          this.databases = data;

          let itemNum = 0;
          for (let node of this.data.nodes) {
            let table = this.databases.find(
              ({ name, database }) =>
                node.name == name && node.database == database
            );

            if (table) {
              let dims = this.modelObj.dimensions.find(
                ({ table }) => node.alias === table
              );

              console.log("Dims", dims);

              if (dims.top == null && dims.left == null) {
                dims.top = this.positions[itemNum].top;
                dims.left = this.positions[itemNum].left;
                itemNum++;
              }
              node.top = +dims.top;
              node.left = +dims.left;
              for (let tableItems of table.columns) {
                let item = {
                  id: tableItems.name.toLowerCase(),
                  name: tableItems.name,
                  datatype: tableItems.datatype,
                  status: false,
                  measure: false,
                  selected: "off",
                };
                if (dims.columns.indexOf(tableItems.name) != -1) {
                  item.status = true;
                  item.selected = "dimension";
                }
                if (
                  this.modelObj.metrics.indexOf(
                    node.name + "." + tableItems.name
                  ) != -1
                ) {
                  item.measure = true;
                  item.selected = "measure";
                }
                node.columns.push(item);
              }
            }
          }
          if (this.partitionDateTable)
            this.getPartitionDateColumns(this.partitionDateTable);
          if (this.partitionTimeTblName)
            this.getPartitionTimeColumns(this.partitionTimeTblName);
          this.partition_desc = {
            partition_date_column: this.modelObj.partition_desc
              .partition_date_column
              ? this.modelObj.partition_desc.partition_date_column.split(
                  ".",
                  2
                )[1]
              : null,
            partition_time_column: this.modelObj.partition_desc
              .partition_time_column
              ? this.modelObj.partition_desc.partition_time_column.split(
                  ".",
                  2
                )[1]
              : null,
            partition_date_format:
              this.modelObj.partition_desc.partition_date_format,
            partition_time_format:
              this.modelObj.partition_desc.partition_time_format,
            partition_type: "APPEND",
            partition_date_start: 0,
            partition_condition_builder:
              "org.apache.kylin.metadata.model.PartitionDesc$DefaultPartitionConditionBuilder",
          };

          this.load = false;
          if (this.data.nodes.length > 0) {
            for (let edge of this.data.edges) {
              this.arrangeRows(edge.source);
              this.arrangeRows(edge.target);
            }
            this.setCanvasPosition();

            //this.drawAllLines();
            this.updateAllScrolledTable();

            console.log("Data Node", this.data);
          }
        });
      });
  }

  ngAfterViewInit() {
    $(document).ready(function () {
      var contenth = $(window).height() - 110;
      var sidebarh = $(window).height() - 111;
      $(".pagec").css("height", contenth);
      $(".sidebar-wrapper").css("height", sidebarh);
    });

    $(window).resize(function () {
      var vmheight = $(window).height() - 120;
      $(".show_vm .canvasData").css("height", vmheight);

      function vmequel_ws() {
        var equelheight = $(".canvasData").height() + 21;
        var inputcheight = $(".canvasData").height() - 12;
        $(".sourcebox").css("height", equelheight);
        $(".canvasinput").css("height", inputcheight);
      }

      setTimeout(vmequel_ws, 4);
    });

    function loadfs() {
      $(document).on("click", ".fullminbtn .fullsbtn", function () {
        var vmheight = $(window).height() - 120;
        $(".show_vm .canvasData").css("height", vmheight);
      });

      $(document).on("click", ".togglebtn-fs", function () {
        var vmfullsheight = $(window).height() - 30;
        $(".canvasData").css("height", vmfullsheight);
      });

      $(document).on("click", ".togglebtn-close ", function () {
        var vmheight = $(window).height() - 120;
        $(".show_vm .canvasData").css("height", vmheight);
      });
    }

    setTimeout(loadfs, 2);

    function vmequel() {
      var equelheight = $(".canvasData").height() + 21;
      var inputcheight = $(".canvasData").height() - 12;
      $(".sourcebox").css("height", equelheight);
      $(".canvasinput").css("height", inputcheight);

      $(document).on("click", ".fullminbtn .fullsbtn", function () {
        var equelheight = $(".canvasData").height() + 21;
        var inputcheight = $(".canvasData").height() - 12;
        $(".sourcebox").css("height", equelheight);
        $(".canvasinput").css("height", inputcheight);
      });

      $(document).on("click", ".fullminbtn .minsbtn", function () {
        $(".canvasData").css("height", "526px");
        $(".sourcebox").css("height", "547px");
        $(".canvasinput").css("height", "514px");
      });
    }

    setTimeout(vmequel, 10);

    $(".canvasContent").mouseover(function () {
      $(this).find(".overview-btnc").css("display", "block");
    });

    $(".canvasContent").mouseout(function () {
      $(this).find(".overview-btnc").css("display", "none");
    });

    /* To show buttons while mouse over on rightside container */
    $(".canvasinputec_ovr").mouseover(function () {
      $(this).find(".overview-btnc").css("display", "block");
    });

    $(".canvasinputec_ovr").mouseout(function () {
      $(this).find(".overview-btnc").css("display", "none");
    });
  }

  public checkItemSelected() {
    for (let node of this.tempNodeData.columns) {
      if (node.selected == "dimension") return true;
    }
    return false;
  }
  public changeMeaasure() {
    // if(!this.tempNodeData.snapShoat){
    for (let param of this.tempNodeData.columns) {
      if (param.measure && param.selected == "measure") {
        param.measure = false;
      } else if (param.selected == "measure") {
        param.measure = true;
      }
      // }
    }
  }
  public AddTable(useStack) {
    if (!this.checkItemSelected()) {
      return;
    }
    $("#mangeNodeModel").modal("hide");
    for (let cols of this.tempNodeData.columns) {
      if (cols.selected == "dimension") {
        cols.status = true;
        cols.measure = false;
      } else if (cols.selected == "measure") {
        cols.measure = true;
        cols.status = false;
      } else {
        cols.measure = false;
        cols.status = false;
      }
    }
    this.tempNodeData.name = this.tempNodeData.name.trim();
    if (this.tempNodeData.name) {
      let kind = "";
      if (!this.data.nodes.length) {
        kind = "FACT";
        this.factTable = this.tempNodeData.name;
        this.factDb = this.tempNodeData.database;
      } else if (this.tempNodeData.snapShoat) {
        kind = "FACT";
        // this.factTable=this.tempNodeData.name;
        // this.factDb=this.tempNodeData.database;
      } else kind = "LOOKUP";
      if (this.tempNodeData.id == "") {
        this.tempNodeData.kind = kind;
        this.tempNodeData.name = this.tempNodeData.name.replace(" ", "_");
        this.tempNodeData.id = this.tempNodeData.alias.toLowerCase();
        // this.tempNodeData.id = this.tempNodeData.name.replace(" ", "_").toLocaleLowerCase()+'_'+(new Date()).getTime();
        for (let tables of this.data.nodes) {
          if (tables.alias === this.tempNodeData.alias) {
            swal(
              "ooops",
              "Table Alias" +
                " " +
                "[" +
                this.tempNodeData.alias.toUpperCase() +
                "]" +
                " " +
                "already exist!",
              "error"
            );
            this.tempNodeData = {
              left: 0,
              top: 0,
              name: "",
              type: "",
              columns: [],
              id: "",
              headerText: "",
              alias: "",
              status: false,
              database: "",
              join: "",
              kind: "",
              fromTable: "",
              snapShoat: false,
              measure: false,
              errorMsg: "",
              selected: "",
            };
            return;
          }
        }
        this.data.nodes.push(this.tempNodeData);

        this.addEventInUndo({
          type: "delete_node",
          node_id: this.tempNodeData.id,
        });

        this.tempNodeData = {
          left: 0,
          top: 0,
          name: "",
          type: "",
          columns: [],
          id: "",
          headerText: "",
          alias: "",
          status: false,
          database: "",
          join: "",
          kind: "",
          fromTable: "",
          snapShoat: false,
          measure: false,
          errorMsg: "",
          selected: "",
        };
      } else {
        if (this.tempNodeData.name.match(/^[0-9a-zA-Z _]+$/)) {
          let isThisAlreadyPresent = this.checkDuplicateNameByNodeid(
            this.tempNodeData.id,
            this.tempNodeData.name
          );
          if (isThisAlreadyPresent === false) {
            for (let update in this.data.nodes) {
              if (this.data.nodes[update].id == this.tempNodeData.id) {
                if (
                  useStack &&
                  this.data.nodes[update].name != this.tempNodeData.name
                ) {
                  this.addEventInUndo({
                    type: "update_node",
                    node_id: this.tempNodeData.id,
                    name: this.data.nodes[update].name,
                  });
                }
                this.data.nodes[update].name = this.tempNodeData.name;
                break;
              }
            }

            this.tempNodeData.errorMsg = "";
          } else {
            this.tempNodeData.errorMsg = "This table already exists";
          }
        } else {
          this.tempNodeData.errorMsg = "Only alphanumeric character allowed!";
        }
      }
    }
  }
  checkDuplicateNameByNodeid(node_id, name): boolean {
    for (let node in this.data.nodes) {
      if (
        this.data.nodes[node].name.toLocaleLowerCase() ==
          name.toLocaleLowerCase() &&
        this.data.nodes[node].id != node_id
      ) {
        return true;
      }
    }
    return false;
  }
  public addColumnpopup(node, column, isUpdate) {
    this.defaultColumn.duplicateIssue = "";
    if (isUpdate) {
      this.defaultColumn.id = column.id;
      this.defaultColumn.previousId = column.id;
      this.defaultColumn.primaryKey = column.primaryKey ? true : false;
      this.defaultColumn.node_id = node.id;
      this.defaultColumn.datatype = column.datatype;
    } else {
      this.defaultColumn.id = "";
      this.defaultColumn.primaryKey = false;
      this.defaultColumn.node_id = node.id;
      this.defaultColumn.datatype = "varchar";
    }
    this.defaultColumn.isUpdate = isUpdate;
    $("#addCoumn").modal("show");
  }
  public isThisAttributePresent(defaultColumn: any): boolean {
    for (let node in this.data.nodes) {
      if (this.data.nodes[node].id == defaultColumn.node_id) {
        for (let column in this.data.nodes[node].columns) {
          if (this.data.nodes[node].columns[column].id == defaultColumn.id) {
            if (defaultColumn.isUpdate) {
              if (defaultColumn.id == defaultColumn.previousId) {
                return false;
              } else {
                return true;
              }
            } else {
              return true;
            }
          }
        }
        break;
      }
    }
    return false;
  }
  public addColumn(useStack) {
    this.defaultColumn.id = this.defaultColumn.id.trim();
    if (this.defaultColumn.id.match(/^[0-9a-zA-Z _]+$/)) {
      let checkDuplicateAttribute = this.isThisAttributePresent(
        this.defaultColumn
      );
      if (checkDuplicateAttribute === false) {
        if (this.defaultColumn.id && this.defaultColumn.node_id) {
          for (let add in this.data.nodes) {
            if (this.data.nodes[add].id == this.defaultColumn.node_id) {
              if (this.defaultColumn.isUpdate) {
                for (let update in this.data.nodes[add].columns) {
                  if (
                    this.data.nodes[add].columns[update].id ==
                    this.defaultColumn.previousId
                  ) {
                    this.data.nodes[add].columns[update].id =
                      this.defaultColumn.id;
                    this.data.nodes[add].columns[update].datatype =
                      this.defaultColumn.datatype;
                    this.updateSourceAndTargetId(
                      this.defaultColumn.node_id +
                        "." +
                        this.defaultColumn.previousId,
                      this.defaultColumn.node_id + "." + this.defaultColumn.id
                    );
                    if (this.defaultColumn.primaryKey) {
                      this.data.nodes[add].columns[update].primaryKey = true;
                    } else {
                      delete this.data.nodes[add].columns[update]["primaryKey"];
                    }
                    if (useStack) {
                      this.addEventInUndo({
                        type: "update_column",
                        node_id: this.defaultColumn.node_id,
                        old_id: this.defaultColumn.previousId,
                        new_id: this.defaultColumn.id,
                      });
                    }
                    break;
                  }
                }
              } else {
                if (this.defaultColumn.primaryKey) {
                  this.data.nodes[add].columns.push({
                    id: this.defaultColumn.id,
                    datatype: this.defaultColumn.datatype,
                    primaryKey: true,
                  });
                } else {
                  this.data.nodes[add].columns.push({
                    id: this.defaultColumn.id,
                    datatype: this.defaultColumn.datatype,
                  });
                }
                if (useStack) {
                  this.addEventInUndo({
                    type: "delete_column",
                    node_id: this.defaultColumn.node_id,
                    column_id: this.defaultColumn.id,
                  });
                }
                // let element = document.getElementById(this.defaultColumn.node_id+'scroll');
                // element.scrollTop = element.scrollHeight;// - element.clientHeight;
                $("#" + this.defaultColumn.node_id + "scroll").animate(
                  { scrollTop: document.body.scrollHeight },
                  "fast"
                );
              }
              break;
            }
          }
        }
        $("#addCoumn").modal("hide");
      } else {
        this.defaultColumn.duplicateIssue = "This column already exists";
      }
      this.updateAllScrolledTable();
    } else {
      this.defaultColumn.duplicateIssue =
        "Only alphanumeric character allowed! ";
    }
  }
  public getArrowId(edge: any): string {
    let tableId = edge.target.split(".")[0];
    return edge.targetPort == ""
      ? "simpleArrow"
      : this.allScrolledTables.indexOf(tableId) > -1 && !edge.defaultArrow
      ? "scrollArrow"
      : "defaultArrow";
  }
  public deleteNodePopup(selectdNode) {
    if (selectdNode.name == this.factTable) {
      if (selectdNode.name == this.factTable) {
        swal({
          type: "warning",
          title:
            "You are trying delete 'FACT Table', it will loose all configuration",
          html: "Are you sure you want to delete " + selectdNode.alias + " ?",
          showCancelButton: true,
          confirmButtonText: "Yes",
          cancelButtonText: "No",
        }).then(
          (status) => {
            if (status.value == true) {
              this.data = { nodes: [], edges: [], ports: [], groups: [] };
              this.partition_desc = {
                partition_date_column: null,
                partition_time_column: null,
                partition_date_format: "yyyy-MM-dd",
                partition_time_format: "HH:mm:ss",
                partition_type: "APPEND",
                partition_date_start: 0,
                partition_condition_builder:
                  "org.apache.kylin.metadata.model.PartitionDesc$DefaultPartitionConditionBuilder",
              };
              this.partitionDateTable = "";
              this.partitionDateColumns = "";
              this.updateAllScrolledTable();
              setTimeout(() => {
                this.setCanvasPosition();
                this.drawAllLines();
              }, 100);
            }
          },
          (dismiss) => {
            if (dismiss === "cancel") {
            }
          }
        );

        return;
      }
    }
    swal({
      type: "warning",
      title: "Are you sure you want to delete " + selectdNode.alias + " ?",
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "No",
    }).then(
      (status) => {
        if (status.value == true) {
          this.confirmation.type = "node";
          this.confirmation.type_id = selectdNode.id;
          this.deleteItem(true);
          this.updateAllScrolledTable();
        }
      },
      (dismiss) => {
        if (dismiss === "cancel") {
        }
      }
    );
  }
  public getNodeById(node_id): any {
    for (let node in this.data.nodes) {
      if (this.data.nodes[node].id == node_id) {
        return this.data.nodes[node];
      }
    }
    return null;
  }
  public getColumnByidNodeIdAndColumnId(node_id, column_id): any {
    for (let node in this.data.nodes) {
      if (this.data.nodes[node].id == node_id) {
        for (let column in this.data.nodes[node].columns) {
          if (this.data.nodes[node].columns[column].id == column_id) {
            return this.data.nodes[node].columns[column];
          }
        }
      }
    }
    return null;
  }
  public addLineFromStack(line): void {
    this.data.edges.push(line);
    setTimeout(() => {
      this.drawAllLines();
    }, 100);
  }
  public changePositionOfNode(node_id, position) {
    for (let node in this.data.nodes) {
      if (this.data.nodes[node].id == node_id) {
        this.data.nodes[node].left = position.left;
        this.data.nodes[node].top = position.top;
        break;
      }
    }
  }
  public deleteItem(useStack) {
    if (this.confirmation.type == "node") {
      let allNodes = [];
      for (let node in this.data.nodes) {
        if (this.data.nodes[node].id == this.confirmation.type_id) {
          let deletedNode = this.data.nodes[node];
          if (useStack) {
            let allDeletedEdges = this.geAallEdgesFromNode(
              this.confirmation.type_id
            );
            this.addEventInUndo({
              type: "add_node",
              node: deletedNode,
              edges: allDeletedEdges,
            });
          }
        } else {
          allNodes.push(this.data.nodes[node]);
        }
      }
      this.data.nodes = allNodes;
      this.removeAllEdgesFromTbale(this.confirmation.type_id);
    } else {
      //for column
      for (let node in this.data.nodes) {
        if (this.data.nodes[node].id == this.confirmation.id) {
          for (let column in this.data.nodes[node].columns) {
            if (
              this.data.nodes[node].columns[column].id ==
              this.confirmation.type_id
            ) {
              if (useStack) {
                let allDeletedEdges = this.geAallEdgesFromColumn(
                  this.confirmation.id,
                  this.confirmation.type_id
                );
                let columnData = this.data.nodes[node].columns[column];
                this.addEventInUndo({
                  type: "add_column",
                  node_id: this.confirmation.id,
                  edges: allDeletedEdges,
                  column: columnData,
                });
              }
              this.data.nodes[node].columns = this.data.nodes[
                node
              ].columns.filter(
                (column) => column.id !== this.confirmation.type_id
              );
              this.removeAllEdgesFromColumn(
                this.confirmation.id + "." + this.confirmation.type_id
              );
              break;
            }
          }
        }
      }
    }
    this.updateAllScrolledTable();
  }
  public updateAllScrolledTable() {
    let allTable = [];
    for (let node in this.data.nodes) {
      if (this.data.nodes[node].columns.length > 4) {
        allTable.push(this.data.nodes[node].id);
      }
    }

    this.allScrolledTables = allTable;
    setTimeout(() => {
      this.drawAllLines();
    }, 100);
  }
  public deleteColumnPopup(selectdNode, column) {
    this.confirmation.text = "Delete column '" + column.id + "'?";
    this.confirmation.id = selectdNode.id;
    this.confirmation.type_id = "column";
    this.confirmation.type_id = column.id;
    $("#confirmationModel").modal("show");
  }

  ngOnInit() {
    this.projectName = this.route.snapshot.paramMap.get("projectId");
    this.modelName = this.route.snapshot.paramMap.get("modelId");
    this.showView();
    this.list();
    //this.saveLogs();
  }

  public setCanvasPosition() {
    let canvasOffset = $("#canvasOffset");
    this.canvasOffsetX = canvasOffset.position().left;
    this.canvasOffsetY = canvasOffset.position().top;
    this.canvasWidth = $("#canvaswidthId").position().left;
  }

  public updatePorts(): void {
    for (let row in this.data.nodes) {
      if (this.data.nodes[row].type == "table") {
        for (let col in this.data.nodes[row].columns) {
          if (this.data.nodes[row].columns[col].primaryKey) {
            this.data.ports.push({
              id:
                this.data.nodes[row].name.toLowerCase() +
                "" +
                this.data.nodes[row].columns[col].id.toLowerCase(),
              datatype: this.data.nodes[row].columns[col].datatype,
              primaryKey: true,
            });
          } else {
            this.data.ports.push({
              id:
                this.data.nodes[row].name.toLowerCase() +
                "" +
                this.data.nodes[row].columns[col].id.toLowerCase(),
              datatype: this.data.nodes[row].columns[col].datatype,
            });
          }
        }
      }
    }
  }

  public isEdgePresent(node, column, type): boolean {
    let id = (node.id + "-" + column.id).toLowerCase() + "-" + type;
    return this.allActivePorts.indexOf(id) > -1;
  }

  public getIdOfPort(node, column, type): string {
    return (node.id + "-" + column.id).toLowerCase() + "-" + type;
  }

  public onMoveTableEnd(event, node): void {
    if (event.x != node.left && event.y != node.top) {
      let initialLeft = node.left;
      let initialTop = node.top; //for stack use.
      this.addEventInUndo({
        type: "shift_node",
        node_id: node.id,
        fromPosition: { left: initialLeft, top: initialTop },
        toPosition: { left: event.x, top: event.y },
      });
      node.left = event.x;
      node.top = event.y; // changing the position.
    }
  }

  public onMovingTable(event): void {
    document.getSelection().removeAllRanges();
    this.setCanvasPosition();
    this.drawAllLines();
  }

  public onMovingPreview(event): void {
    let x = event.x;
    let y = event.y;
    this.canvasDataPosition = { x: x * 8, y: y * 8 };
    this.setCanvasPosition();
    this.drawAllLines();
  }

  public zoomToFitScreen(): void {
    this.canvasDataPosition = { x: 0, y: 0 };
    this.canvasPreviewPosition = { x: 0, y: 0 };
    this.canvasZoomIndex = 1;
    setTimeout(() => {
      this.setCanvasPosition();
      this.drawAllLines();
    }, 100);
  }

  public zoomInAndOut(zoomIn: boolean) {
    if (zoomIn && this.canvasZoomIndex < 1.5) {
      this.canvasZoomIndex += 0.1;
      setTimeout(() => {
        this.setCanvasPosition();
        this.drawAllLines();
      }, 1);
    }
    if (!zoomIn && this.canvasZoomIndex > 0.5) {
      this.canvasZoomIndex -= 0.1;
      setTimeout(() => {
        this.setCanvasPosition();
        this.drawAllLines();
      }, 1);
    }
  }

  public onScrollColumn(event) {
    this.updateAllScrolledTable();
    setTimeout(() => {
      this.drawAllLines();
    }, 10);
    this.onMovingTable(event);
  }
  toggleButton() {
    this.toggleState = !this.toggleState;
  }
  showContainer() {
    return {
      "show-o": this.toggleState,
    };
  }

  toggleButtonc() {
    this.toggleStatec = !this.toggleStatec;
    this.updateAllScrolledTable();
  }
  showContainerc() {
    this.updateAllScrolledTable();
    return {
      "show-canvas": this.toggleStatec,
    };
  }

  fullsc_canvas() {
    this.updateAllScrolledTable();
    this.toggleStatefs = !this.toggleStatefs;
  }

  toggleSwitchBtn() {
    this.toggleSwitchState = !this.toggleSwitchState;
    if (!this.toggleSwitchState) {
      this.partition_desc.partition_time_column = null;
      this.partition_desc.partition_time_format = null;
      this.partitionTimeTblName = null;
    }
  }
  showTimeC() {
    return {
      "show-switch": this.toggleSwitchState,
    };
  }
  onSelectionChange(entry, param) {
    this.activate = true;
    this.value = entry.value;
    if (entry == "measure") param.measure = true;
    else if (entry == "dimension") param.status = true;
    else {
      param.status = false;
      param.measure = false;
    }
    param.selected = entry;
  }
  public editNode(table) {
    this.nodeStatus = "edit";
    this.tableEditMode = true;
    this.tableObj = table;
    this.tempNodeData = table;

    console.log("Table in edit mode", table);
    this.tempNodeData.snapShoat = false;
    for (let param of table.columns) {
      param.defSelected = param.selected;
      if (param.defSelected == "measure") this.tempNodeData.snapShoat = true;
    }
    if (table.kind == "FACT") {
      this.tempNodeData.snapShoat = true;
    }
  }
  checkAlaias(f) {
    let newAlias = f.value.tableAlias;
    this.activate = true;
    let name = this.data.nodes.find(
      ({ alias }) => newAlias.toLowerCase() === alias.toLowerCase()
    );
    if (name && this.tempNodeData.alias != name.alias) {
      this.aliasError = true;
    } else {
      this.aliasError = false;
      this.tempNodeData.alias = newAlias;
    }
  }
  public cancelEdit() {
    this.nodeStatus = null;
    this.tableEditMode = false;
    this.tableObj = null;
    for (let param of this.tempNodeData.columns) {
      if (param.defSelected == "measure") {
        param.measure = true;
        param.selected = param.defSelected;
        param.status = false;
      } else if (param.defSelected == "dimension") {
        param.measure = false;
        param.selected = param.defSelected;
        param.status = true;
      } else {
        param.measure = false;
        param.selected = null;
        param.status = false;
      }
    }
    this.tempNodeData = {
      left: 0,
      top: 0,
      name: "",
      type: "",
      columns: [],
      id: "",
      headerText: "",
      alias: "",
      status: false,
      database: "",
      join: "",
      kind: "",
      fromTable: "",
      snapShoat: false,
      measure: false,
      errorMsg: "",
      selected: "",
    };

    this.aliasError = false;
  }

  public updateTable(f: NgForm) {
    let newAlias = f.value.tableAlias;
    let alias = this.tempNodeData.alias;

    if (f.value.tableAlias != alias) {
      this.tempNodeData.alias = f.value.tableAlias;
      for (let edges of this.data.edges) {
        if (
          alias.toLowerCase() == edges.source.split(".", 2)[0] &&
          f.value.tableName != this.factTable
        ) {
          edges.source =
            newAlias.toLowerCase() + "." + edges.source.split(".", 2)[1];
        }
        if (
          alias.toLowerCase() == edges.target.split(".", 2)[0] &&
          f.value.tableName != this.factTable
        ) {
          edges.target =
            newAlias.toLowerCase() + "." + edges.target.split(".", 2)[1];
        }
      }
      if (f.value.tableName != this.factTable)
        this.tempNodeData.alias = newAlias.toLowerCase();
      else this.tempNodeData.alias = this.tempNodeData.name.toLowerCase();
    }
    for (let cols of this.tempNodeData.columns) {
      if (cols.selected == "dimension" && cols.status) {
        cols.status = true;
        cols.measure = false;
      } else if (cols.selected == "measure" && cols.measure) {
        cols.measure = true;
        cols.status = false;
      } else {
        cols.measure = false;
        cols.status = false;
      }
    }
    this.tempNodeData.name = this.tempNodeData.name.trim();
    if (this.tempNodeData.name) {
      if (this.tempNodeData.name == this.factTable) {
        this.tempNodeData.kind = "FACT";
      } else {
        if (this.tempNodeData.snapShoat) this.tempNodeData.kind = "FACT";
        else this.tempNodeData.kind = "LOOKUP";
      }
      for (let nodes of this.data.nodes) {
        if (nodes.fromTable == this.tempNodeData.id) {
          nodes.fromTable = this.tempNodeData.alias.replace(" ", "_");
        }
      }

      this.tempNodeData.id = this.tempNodeData.alias.replace(" ", "_");
      this.tempNodeData.id = this.tempNodeData.id.toLowerCase();
      let index = this.data.nodes.indexOf(this.tableObj);
      this.data.nodes.splice(index, 1, this.tempNodeData);
      for (let edge of this.data.edges) {
        if (
          this.tempNodeData.alias.toLowerCase() == edge.target.split(".", 2)[0]
        ) {
          edge.data.type = this.tempNodeData.join;
        }
      }
      setTimeout(() => {
        this.drawAllLines();
      }, 1);
      this.tempNodeData = {
        left: 0,
        top: 0,
        name: "",
        type: "",
        columns: [],
        id: "",
        headerText: "",
        alias: "",
        status: false,
        database: "",
        join: "",
        kind: "",
        fromTable: "",
        snapShoat: false,
        measure: false,
        errorMsg: "",
        selected: "",
      };
      this.tableObj = null;
      this.tableEditMode = false;
      $("#mangeNodeModel").modal("hide");
      this.updateAllScrolledTable();
    }
  }
  getPartitionTables() {
    let tables = [];
    for (let param of this.data.nodes) {
      if (param.kind == "FACT")
        tables.push({ name: param.alias, value: param.alias });
    }
    return tables;
  }
  getPartitionDateColumns(table) {
    let columns = [];
    let tableObj = this.data.nodes.find(({ alias }) => table === alias);
    if (tableObj) {
      for (let cols of tableObj.columns) {
        if (
          !cols.measure &&
          this.partition_desc.partition_time_column != cols.name
        ) {
          columns.push({ name: cols.name, value: cols.name });
        }
      }
    }

    this.partitionDateColumnItems = columns;
  }
  clearValues(){
    this.partition_desc.partition_time_column=null
  }
  getPartitionTimeColumns(table) {
    let columns = [];
    let tableObj = this.data.nodes.find(({ alias }) => table === alias);
    for (let cols of tableObj.columns) {
      if (
        !cols.measure &&
        this.partition_desc.partition_date_column != cols.name
      ) {
        columns.push({ name: cols.name, value: cols.name });
      }
    }
    this.partitionTimeColumnItems = columns;
  }

  list() {
    const params = {
      ext: true,
      project: this.projectName,
    };
    this.tableService.list(params).subscribe((data: any[]) => {
      this.databases = data;
      this.dbList = [];
      for (var i = 0; i < data.length; i++) {
        this.tableList[i] = new Table(this.databases[i]);
        this.tableList[i].columns = this.databases[i].columns.map(
          (columns: any) => new Columns(columns)
        );
        if (!this.dbList.includes(this.databases[i].database)) {
          this.dbList.push(this.databases[i].database);
        }
      }

      sessionStorage.setItem("tablesInProject", JSON.stringify(this.tableList));
      this.load = false;
    });
  }

  checkAllItems(columns) {
    for (let param of columns) {
      param.status = this.checkAll;
    }
  }
  getFromTables() {
    let tables = [];
    for (let table of this.data.nodes) {
      tables.push({ name: table.name, value: table.name });
    }
    return tables;
  }
  getColumns(columns) {
    let column = [];
    for (let cols of columns) {
      if (cols.status) column.push(cols);
    }

    return column;
  }
  getMeasures(columns) {
    let column = [];
    for (let cols of columns) {
      if (cols.measure) column.push(cols);
    }
    return column;
  }
  checkCanvasStatus() {
    if (this.data.nodes.length == 0) return true;
  }
  saveModel() {
    //QUBZ-3956 - Create & Edit Visual Model designer Partition column and time format should be disable until select Partition table - 1/12/22
    if((this.partitionDateTable && (!this.partition_desc.partition_date_column || !this.partition_desc.partition_date_format)) || (this.partitionTimeTblName && (!this.partition_desc.partition_time_column || !this.partition_desc.partition_time_format))) {
      swal({
        type:'warning',
        title: 'Partition Column cant be null.<br/> Date Format cant be null.',
        html:'',
        confirmButtonText: 'OK',
        }).then((status) => {
        if(status.value==true){
        }
        }, (dismiss) => {
        if (dismiss === 'cancel') {
        }
    });
    }else{
    for (let node of this.data.nodes) {
      let status = false;
      if (node.alias.toLowerCase() != this.factTable.toLowerCase()) {
        for (let edge of this.data.edges) {
          let source = edge.target.split(".", 2);
          if (source[0].toLowerCase() == node.alias.toLowerCase()) {
            status = true;
            break;
          }
        }
        if (status == false) {
          swal(
            "Ooops",
            "No relationship defined for table " + node.alias + " ",
            "error"
          );
          return;
        }
      }
    }
    $("#saveModel").modal("show");
  }
  }
  submitModel() {
    $("#saveModel").modal("hide");
    let dimensions = [];
    let metrics = [];
    let lookups = [];
    for (let node of this.data.nodes) {
      let item = {
        table: node.alias,
        columns: [],
        top: node.top,
        left: node.left,
      };
      for (let cols of node.columns) {
        if (cols.status) item.columns.push(cols.name);
        if (cols.measure) metrics.push(node.alias + "." + cols.name);
      }
      dimensions.push(item);
      if (node.name != this.factTable) {
        if (node.alias != this.factTable) {
          let look = {
            table: node.database + "." + node.name,
            kind: node.kind,
            alias: node.alias,
            join: {
              type: node.join,
              primary_key: [],
              foreign_key: [],
            },
          };

          lookups.push(look);
        }
      }
    }

    for (let edge of this.data.edges) {
      let source = edge.target.split(".", 2);

      //let tables = this.data.nodes.find(({ alias }) => source[0] === alias.toLowerCase())

      // let look = lookups.find(({ table }) => tables.database + '.' + tables.name === table)
      let look = lookups.find(({ alias }) => source[0] === alias.toLowerCase());

      if (look) {
        look.join.primary_key.push(edge.target);
        look.join.foreign_key.push(edge.source);
      }
    }
    if (this.partition_desc.partition_time_column)
      this.partition_desc.partition_time_column =
        this.partitionTimeTblName +
        "." +
        this.partition_desc.partition_time_column;
    if (this.partition_desc.partition_date_column)
      this.partition_desc.partition_date_column =
        this.partitionDateTable +
        "." +
        this.partition_desc.partition_date_column;
    this.modelObj.lookups = lookups;
    this.modelObj.dimensions = dimensions;
    this.modelObj.metrics = metrics;
    this.modelObj.partition_desc = this.partition_desc;
    this.modelObj.filter_condition = this.filter_condition;
    this.modelObj.description = this.modelDescription;
    const modelRequest = {
      modelDescData: JSON.stringify(this.modelObj),
      modelName: this.modelName,
      project: this.projectName,
    };
    swal({
      type: "warning",
      title: "Are you sure you want to update the model?",
      text: "Please note: If model schema is changed, all cubes of the model will be affected.",
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "Cancel",
    }).then((status) => {
      let errors: any = null;
      if (status.value == true) {
        this.modelSerivce.update(modelRequest).subscribe(
          (data) => {
            $("#saveModel").modal("hide");
            swal({
              type: "success",
              title: "Success!",
              text: "Model successfully updated!",
              confirmButtonText: "OK",
            }).then(
              (status) => {
                if (status.value == true) {
                  this.router.navigate([
                    "/projects/",
                    this.projectName,
                    "models",
                  ]);
                }
              },
              (dismiss) => {
                if (dismiss === "cancel") {
                }
              }
            );
          },
          (error) => {
            swal({
              type: "error",
              title: " OOPS...",
              text: error.error.msg,
              confirmButtonText: "OK",
            }).then(
              (status) => {
                if (status.value == true) {
                  this.router.navigate([
                    "/projects/",
                    this.projectName,
                    "models",
                  ]);
                }
              },
              (dismiss) => {
                if (dismiss === "cancel") {
                }
              }
            );
          }
        );
      }
    });
  }
  isFact(tempNodeData) {
    if (
      this.nodeStatus == "edit" &&
      tempNodeData.name == this.factTable &&
      tempNodeData.alias.toLowerCase() == this.factTable.toLowerCase()
    ) {
      return true;
    } else return false;
  }
  public fullscreen() {
    this.isFullscreenvm = !this.isFullscreenvm;
    setTimeout(() => {
      this.setCanvasPosition();
      this.drawAllLines();
    }, 1);
    this.updateAllScrolledTable();
  }

  fontadj() {
    this.isfontadjust = !this.isfontadjust;
  }

  public relationCancel() {
    $("#editRelationship").modal("hide");
    let node = this.data.nodes.find(
      ({ id }) => this.tempRelationshipData.target.node === id
    );
    if (node) node.fromTable = "";
  }
  checkLinked(name) {
    let node = this.tempNodeData;
    if (
      this.data.edges.find(
        ({ source }) =>
          node.alias.toLowerCase() + "." + name.toLowerCase() === source
      ) ||
      this.data.edges.find(
        ({ target }) =>
          node.alias.toLowerCase() + "." + name.toLowerCase() === target
      )
    )
      return false;
    else return true;
  }
  showTable() {
    $("#mangeNodeModel").modal("show");
  }

  saveLogs() {
    var today = new Date();
    let param = {
      username: this.loggedUser.username,
      targetPage:
        "projects/" +
        this.projectName +
        "/visual-model/" +
        this.modelName +
        "/edit",
      day: today.getDay(),
      month: today.getMonth(),
      year: today.getFullYear(),
    };
    this.commonServiceService.saveLogs(param).subscribe((data) => {});
  }
}
