import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { HttpClient } from '@angular/common/http';
import { Config } from '../../config';
@Injectable({
  providedIn: 'root'
})
export class SegmentsService {
  static url = Config.url + '/segments';
  constructor(private http: HttpClient) { }
  deleteSegments(cube,segment) {
    return this.http.delete(SegmentsService.url+"/"+cube+"/"+segment);
  }
  getSegments(cube,page){
    return this.http.get(SegmentsService.url+"?cubeName="+cube+"&page="+page+"&size=10");
  }
  getCubeSegments(cube){
    return this.http.get(SegmentsService.url+"/cubeName");
  }
}
