import { Component, OnInit, Input , AfterViewInit} from '@angular/core';
import * as $ from 'jquery';

@Component({
  selector: 'app-model-view-step-four',
  templateUrl: './model-view-step-four.component.html',
  styleUrls: ['./model-view-step-four.component.scss']
})
export class ModelViewStepFourComponent implements OnInit,AfterViewInit {
  @Input() modelDesc;
  constructor() { }

  ngOnInit() {
  }

  ngAfterViewInit() {
    $(document).ready(function(){
          var contenth = $( window ).height() - 110 ;
          var sidebarh = $( window ).height() - 111 ;
          $(".pagec").css("height", contenth );
          $(".sidebar-wrapper").css("height", sidebarh );
        });
    }

}
