import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  AfterViewInit,
} from "@angular/core";
import { QueryService } from "src/app/services/query/query.service";
import { ActivatedRoute } from "@angular/router";
import * as d3 from "d3";
import * as moment from "moment";
import swal from "sweetalert2";
import { isInteger } from "@ng-bootstrap/ng-bootstrap/util/util";
import { Config } from "../../../config";
import { OrderPipe } from "ngx-order-pipe";
import { Router } from "@angular/router";
import * as $ from "jquery";
import { CommonServiceService } from "../../../services/common-services/common-service.service";
@Component({
  selector: "app-query",
  templateUrl: "./query.component.html",
  styleUrls: ["./query.component.scss"],
})
export class QueryComponent implements OnInit, AfterViewInit {
  @Input() result: any;
  @Input() savedQueriesList: any;
  @Output() savedQueriesEvent: EventEmitter<any> = new EventEmitter();
  @Output() queryEvent: EventEmitter<any> = new EventEmitter();
  @Output() resultEvent: EventEmitter<any> = new EventEmitter();
  @Output() errorEvent: EventEmitter<any> = new EventEmitter();
  query: string;
  autoCompleteList: any;
  queries = [];
  queries1 = [];
  error: string;
  page: number = 1;
  currentTab: number = 1;
  projectName;
  data;
  status;
  startTime;
  options;
  chart;
  chartSelected;
  selectedGraph;
  selectedDimensions = "";
  SelecetedMetrics = "";
  selectdate: number;
  jsonValue;//New changes to add cubez Drop down in Insight query 12/07/2022
  defaultDate = "CURRENT DAY";
  searchDate: string;
  // select option
  selectdays = [
    { name: "CURRENT DAY", value: "CURRENT DAY" },
    { name: "LAST ONE WEEK", value: "LAST ONE WEEK" },
    { name: "LAST ONE MONTH", value: "LAST ONE MONTH" },
    { name: "LAST SIX MONTH", value: "LAST SIX MONTH" },
    { name: "ALL", value: "ALL" },
  ];
  chartTypes = [
    {
      name: "Line Chart",
      value: "line",
      dimension: { types: ["date"], multiple: false },
      metrics: { multiple: false },
    },
    {
      name: "Bar Chart",
      value: "bar",
      dimension: { types: ["date", "string"], multiple: false },
      metrics: { multiple: false },
    },
    {
      name: "Pie Chart",
      value: "pie",
      dimension: { types: ["date", "string"], multiple: false },
      metrics: { multiple: false },
    },
  ];
  meta;
  dateTypes = [91, 92, 93];
  stringTypes = [-1, 1, 12];
  numberTypes = [-7, -6, -5, 3, 4, 5, 6, 7, 8];
  statusList = [
    { name: "All", value: "All" },
    { name: "Executing", value: "Executing" },
    { name: "Success", value: "Success" },
    { name: "Failed", value: "Failed" },
  ];
  classList;
  statusData: string;
  searchText1 = null;
  searchText = null;
  curQuery: boolean;
  cachedQueries = [];
  cachedQueries1 = [];
  currentQueries = [];
  projectId: string;
  isQuery: boolean = false;
  isFullscreen: boolean = false;
  isShowquery: boolean = false;
  isShowquerydiv: boolean = false;
  indexTab;
  indexSelect;
  isTablegraph: boolean = false;
  sql;
  statusActive;
  selectedDimension;
  showModal: boolean;
  queryDescription: any;
  queryName: any;
  queryList: [];
  exportSql = null;
  config_url;
  refresh;
  order: string = "columnMetas";
  reverse: boolean = false;
  sortedCollection;
  limit;
  moreLimit;
  history: any;
  loggedUser;
  current: boolean;
  dropdownList = [];
  selectedItems = [];
  dropdownSettings = {};
  queryresult: any;
  cancelResult: any;//QUBZ-3848 12/09/2022 QUBZ 6.0.0-Cancel query not working properly
  dimensionList;
  matricList;
  re = [];
  load: boolean;
  query1;
  queryView;
  newTab: boolean;
  QueryExceeded = [];
  savedQueryType;
  savedQueryDimensions;
  savedQueryMetrics;
  sqlRequest;
  querydtls;
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private queryService: QueryService,
    private orderPipe: OrderPipe,
    public commonServiceService: CommonServiceService
  ) {
    this.projectName = this.route.snapshot.paramMap.get("projectId");
    this.chart = {
      data: [],
      options: {},
    };
    this.sortedCollection = orderPipe.transform(this.result, "columnMetas");
    // this.router.routeReuseStrategy.shouldReuseRoute = function(){
    //   return false;
    // }
  }

  setOrder(value: string) {
    if (this.order === value) {
      this.reverse = !this.reverse;
    }

    this.order = value;
  }
  removeResult(queryInstance) {
    if (this.indexTab == queryInstance) {
      this.indexTab = null;
    }
    var index = this.queries1.indexOf(queryInstance);
    this.queries1.splice(index, 1);
    this.indexTab = this.queries1.length > 0 ? this.queries1[0] : null;
    if (this.queries1.length >= 1) {
      this.refreshCurQueryforTab(
        this.queries1[this.queries1.length - 1].sql,
        this.queries1[this.queries1.length - 1].project,
        this.queries1[this.queries1.length - 1]
      );
    }
    if (this.queries1.length == 0) {
      this.newTab = false;
    }
  }
  refreshCurQuery(sql, project, result) {
    console.log(this.queryService.ddlcubeName);
    if (!this.limit) {
      this.limit = 50000;
    }
    this.statusActive = "active";
    this.startTime = new Date();
    this.status = "Executing";
    localStorage.setItem("startTime", this.startTime);
    this.sql = sql;
    //New changes to add cubez Drop down in Insight query 12/07/2022
    let params;
    if (this.queryService.ddlcubeName != null && this.queryService.ddlcubeName != 'Auto') {
      //var qubzVal=result.SQLResponse.cube.split('=')[1];
      //qubzVal=qubzVal.slice(0, -1)
      this.jsonValue = { "DEBUG_TOGGLE_HIT_CUBE": "" + this.queryService.ddlcubeName + "" };
      params = {
        sql: sql,
        offset: 0,
        limit: this.limit,
        acceptPartial: true,
        project: project,
        backdoorToggles: this.jsonValue,

      };
    }
    else {
      params = {
        sql: sql,
        offset: 0,
        limit: this.limit,
        acceptPartial: true,
        project: project,
      };
    }
    //New changes to add cubez Drop down in Insight query 12/07/2022  
    // const params = {
    //   sql: sql,
    //   offset: 0,
    //   limit: this.limit,
    //   acceptPartial: true,
    //   project: project,
    // };
    this.load = true;
    if (result.pushdown == true) {
      this.currentTab = 4;
    } else {
      this.currentTab = 1;
    }
    this.queryService
      .getQueryDetails(params, this.currentTab)
      .subscribe((data: string) => {
        this.querydtls = data;
        this.queryService.getResult(this.querydtls.queryId).subscribe(
          (data) => {
            this.load = false;
            this.result = data;
            if (result.isTablegraph) {
              this.result.isTablegraph = true;
            }
            this.result.type = result.type;
            this.result.dimension = result.dimension;
            this.result.matrics = result.matrics;
            this.status = "Success";
            this.buildGraphMetadata(this.result);
            this.resetGraph(this.result);
            this.resultEvent.emit(this.result);
            this.curQuery = true;
            this.indexTab = this.result;
            this.current = true;
          },
          (error) => {
            this.load = false;
            this.error = error;
            this.status = "Failed";
            this.errorEvent.emit(this.error);
            this.refresh = this.error;
            this.indexTab = this.error;
          }
        );
      });
    // var element = document.getElementById("statustab");
    // element.classList.add("active");
  }
  refreshCurQueryforTab(sql, project, query) {
    this.result = query.result;
    if (this.result.totLimit) {
      this.limit = this.result.totLimit;
    }
    this.startTime = query.startTime;
    this.status = "Executing";

    if (query.status == "Success") {
      this.status = query.status;
      this.error = "";
      this.result = query.result;
      this.buildGraphMetadata(this.result);
      this.resetGraph(this.result);
      this.resultEvent.emit(this.result);
      this.curQuery = true;
      this.indexTab = this.result;
      this.queryView = sql;
      this.sql = sql;
      this.exportSql = this.sanitate(sql);
      //QUBZ-3942 QUBZ 6- Insights tad tab Graph having issue on 23/11/2022
      //if (this.result.type == "line" && this.result.dimension == "") {
      if (this.result.graph.meta.metrics.length == 0) {
        this.chart.data = "";
      }
    } else if (query.status == "Failed") {
      this.error = query.result;
      this.indexTab = query.result;
      this.status = query.status;
    }
  }
  getLimit(limit) {
    this.moreLimit = limit;
  }
  loadMore(sql, project) {
    this.load = true;
    let typedLimit = 50000;
    this.statusActive = "active";
    this.startTime = new Date();
    this.status = "Executing";
    if (!this.limit || !this.moreLimit) {
      if (!this.limit) {
        this.limit = this.moreLimit;
      }
      // if(this.moreLimit){
      //   this.limit=this.moreLimit*2;
      // }
    } else if (this.moreLimit) {
      this.limit = parseInt(this.limit) + parseInt(this.moreLimit);
    } else {
      this.limit = parseInt(this.limit) + typedLimit;
    }
    localStorage.setItem("startTime", this.startTime);
    this.sql = sql;
    const params = {
      sql: sql,
      offset: 0,
      limit: this.limit,
      acceptPartial: true,
      project: project,
    };
    this.queryService
      .getQueryDetails(params, this.currentTab)
      .subscribe((data: string) => {
        this.querydtls = data;
        this.queryService.getResult(this.querydtls.queryId).subscribe(
          (data) => {
            this.load = false;
            this.result = data;
            this.status = "Success";
            this.buildGraphMetadata(this.result);
            this.resetGraph(this.result);
            this.resultEvent.emit(this.result);
            this.curQuery = true;
            this.indexTab = this.result;
          },
          (error) => {
            this.error = error;
            this.status = "Failed";
            this.errorEvent.emit(this.error);
            this.refresh = this.error;
            this.indexTab = this.error;
          }
        );
      });
  }

  showresult() {
    var element = document.getElementById("showresult");
    element.classList.add("showresult");
  }
  getGraphSate(savedQuery) {
    this.savedQueryType = savedQuery.graphType ? savedQuery.graphType : "";
    this.savedQueryDimensions = parseInt(savedQuery.dimensions);
    this.savedQueryMetrics = parseInt(savedQuery.metrics);
  }
  showgraph(result) {
    var element = document.getElementById("graphsh");
    element.classList.add("result-cc");
  }

  hidegraph() {
    var element = document.getElementById("graphsh");
    element.classList.remove("result-cc");
  }
  setSavedQuery(event: string) {
    this.savedQueriesList = event;
  }
  setTab(tab: number) {
    this.currentTab = tab;
    if (this.currentTab == 2) {
      const params = {
        project: this.projectId,
      };
      this.queryService.getSavedQueries(params).subscribe((data) => {
        this.savedQueriesList = data;
      });
      this.savedQueriesEvent.emit(this.savedQueriesList);
    }
    if (this.currentTab == 3) {
      this.queries = JSON.parse(localStorage.getItem("queries_history"));
    }
  }
  setQuery(event: string) {
    this.query = event;
  }
  setResult(event: string) {
    this.result = event;
  }
  setSaved(event: string) {
    this.savedQueriesList = event;
  }
  setError(event: string) {
    this.error = event;
  }
  treeData(event) {
    this.autoCompleteList = event;
  }
  //New changes to add cubez Drop down in Insight query 12/07/2022
  public queryResult(query, limit, projectId, qubzName?: any) {
    this.status = "Executing";

    //this.savedQueryType = undefined;
    if (query == null) {
      return false;
    }
    this.queryView = query;
    this.newTab = true;
    this.currentQueries.push({
      sql: query,
      savedAt: new Date(),
      project: projectId,
      status: "Executing",
      startTime: new Date(),
      result: "",
      id: "test",
    });
    this.queries1 = this.currentQueries;
    this.limit = this.moreLimit;
    this.startTime = new Date();
    this.curQuery = false;
    //  this.query=query;
    localStorage.setItem("startTime", this.startTime);
    this.sql = query;
    // this.queryEvent.emit(this.query)
    //New changes to add cubez Drop down in Insight query 12/07/2022
    let params;
    if (qubzName != null && qubzName != 'Auto') {
      this.jsonValue = { "DEBUG_TOGGLE_HIT_CUBE": "" + qubzName + "" };
      params = {
        sql: query,
        offset: 0,
        limit: limit,
        acceptPartial: true,
        project: projectId,
        backdoorToggles: this.jsonValue,

      };

    }
    else {
      params = {
        sql: query,
        offset: 0,
        limit: limit,
        acceptPartial: true,
        project: projectId,


      };
    }
    //New changes to add cubez Drop down in Insight query 12/07/2022  


    this.exportSql = this.sanitate(query);
    //this.load=true;
    this.queryService
      .getQueryDetails(params, this.currentTab)
      .subscribe((data: string) => {
        this.querydtls = data;

        this.queryService.getResult(this.querydtls.queryId).subscribe(
          (data) => {
            const index = this.queries1.findIndex(
              (query) => query.id === "test"
            );
            this.queries1.splice(index, 1);
            // this.load=false;
            this.result = data;
            if (!this.result.SQLResponse.results) {
              this.status = "Success";
            } else {
              this.result.isTablegraph = false;
              this.result.totLimit = this.limit;
              if (this.currentTab == 4) {
                this.result.pushdown = true;
              } else {
                this.result.pushdown = false;
              }
              if (this.savedQueryType) {
                this.result.isTablegraph = true;
                this.result.type = this.savedQueryType;
              } else {
                this.result.type = "line";
              }
              if (this.savedQueryDimensions || this.savedQueryDimensions == 0) {
                this.result.dimension = this.savedQueryDimensions;
              } else {
                this.result.dimension = "";
              }

              if (this.savedQueryMetrics || this.savedQueryMetrics == 0) {
                this.result.metrics = this.savedQueryMetrics;
              } else {
                this.result.metrics = "";
              }
              this.chart.data = "";
              this.buildGraphMetadata(this.result);
              this.resetGraph(this.result);
              this.status = "Success";
              this.resultEvent.emit(this.result);
              // this.cachedQueries1 = JSON.parse(
              //   localStorage.getItem("queries_history")
              // );
              // this.cachedQueries = this.queryService.tabViewQuery;

              // if (this.cachedQueries) {
              //   if (this.cachedQueries.length > 0) {
              //     this.cachedQueries = this.cachedQueries;
              //   }
              // } else {
              //   this.cachedQueries = [];
              // }
              // if (this.cachedQueries1) {
              //   if (this.cachedQueries1.length > 0) {
              //     this.cachedQueries1 = this.cachedQueries1;
              //   }
              // } else {
              //   this.cachedQueries1 = [];
              // }

              // this.cachedQueries.push({
              //   sql: query,
              //   savedAt: new Date(),
              //   project: projectId,
              //   status: this.status,
              //   startTime: this.startTime,
              //   result: this.result,
              // });
              // this.cachedQueries1.push({
              //   sql: query,
              //   savedAt: new Date(),
              //   project: projectId,
              //   status: this.status,
              //   pushdown: this.result.pushdown,
              // });
              // if (this.currentQueries.length > 0) {
              //   this.currentQueries = this.currentQueries;
              // }
              // this.currentQueries.push({
              //   sql: query,
              //   savedAt: new Date(),
              //   project: projectId,
              //   status: this.status,
              //   startTime: this.startTime,
              //   result: this.result,
              // });
              // //alert(this.cachedQueries.length);
              // localStorage.setItem(
              //   "queries_history",
              //   JSON.stringify(this.cachedQueries1)
              // );
              // this.queryService.tabViewQuery = this.cachedQueries;
              // this.history = localStorage.getItem("queries_history");
              // if (
              //   this.currentTab == 1 ||
              //   this.currentTab == 2 ||
              //   this.currentTab == 3
              // ) {
              //   this.queryService.savehistory(this.history);
              // }
              // this.queries1 = this.currentQueries;
              // this.indexTab = this.result;
              this.mappingToChartype(this.result.graph.meta.dimensions);
              this.matricList = [];
              for (let i = 0; i < this.result.graph.meta.metrics.length; i++) {
                this.matricList.push({
                  name: this.result.graph.meta.metrics[i].column.label,
                  value: i,
                });
              }
            }

            this.cachedQueries1 = JSON.parse(
              localStorage.getItem("queries_history")
            );
            this.cachedQueries = this.queryService.tabViewQuery;

            if (!this.cachedQueries) {
              this.cachedQueries = [];
            }

            if (!this.cachedQueries1) {
              this.cachedQueries1 = [];
            }

            this.cachedQueries.push({
              sql: query,
              savedAt: new Date(),
              project: projectId,
              status: this.status,
              startTime: this.startTime,
              result: this.result,
            });
            this.cachedQueries1.push({
              sql: query,
              savedAt: new Date(),
              project: projectId,
              status: this.status,
              pushdown: this.result.pushdown,
            });

            this.currentQueries.push({
              sql: query,
              savedAt: new Date(),
              project: projectId,
              status: this.status,
              startTime: this.startTime,
              result: this.result,
            });
            //console.log(this.currentQueries);
            //alert(this.cachedQueries.length);
            localStorage.setItem(
              "queries_history",
              JSON.stringify(this.cachedQueries1)
            );
            this.queryService.tabViewQuery = this.cachedQueries;
            this.history = localStorage.getItem("queries_history");
            if (
              this.currentTab == 1 ||
              this.currentTab == 2 ||
              this.currentTab == 3
            ) {
              this.queryService.savehistory(this.history);
            }
            this.queries1 = this.currentQueries;
            this.indexTab = this.result;
          },
          (error) => {
            const index = this.queries1.findIndex(
              (query) => query.id === "test"
            );
            this.queries1.splice(index, 1);
            this.load = false;
            this.error = error;
            //QUBZ-3862 13/9/22 In insights page error message should be display with Executing wrong Query
            this.result = error;
            localStorage.setItem("status", "Failed");
            this.status = "Failed";
            this.errorEvent.emit(this.error);
            this.cachedQueries1 = JSON.parse(
              localStorage.getItem("queries_history")
            );
            this.cachedQueries = this.queryService.tabViewQuery;
            if (!this.cachedQueries) {
              this.cachedQueries = [];
            }

            if (!this.cachedQueries1) {
              this.cachedQueries = [];
            }
            this.cachedQueries.push({
              sql: query,
              savedAt: new Date(),
              project: projectId,
              status: this.status,
              startTime: this.startTime,
              result: this.error,
            });
            this.cachedQueries1.push({
              sql: query,
              savedAt: new Date(),
              project: projectId,
            });
            if (!this.currentQueries.length) {
              this.currentQueries = [];
            }
            this.currentQueries.push({
              sql: query,
              savedAt: new Date(),
              project: projectId,
              status: this.status,
              startTime: this.startTime,
              result: this.error,
            });
            localStorage.setItem(
              "queries_history",
              JSON.stringify(this.cachedQueries1)
            );
            this.queryService.tabViewQuery = this.cachedQueries;
            this.history = localStorage.getItem("queries_history");
            if (this.currentTab == 1 || this.currentTab == 2) {
              this.queryService.savehistory(this.history);
            }
            this.queries1 = this.currentQueries;
            this.indexTab = this.error;
          }
        );
      });
  }
  delay(ms: number) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }
  sanitate = function (sql) {
    return encodeURIComponent(sql.replace(/\n/g, " "));
  };
  buildGraphMetadata(result) {
    if (!result.showgrid) {
      // return;
    }
    result.graph = {};
    result.graph.meta = {
      dimensions: [],
      metrics: [],
    };
    // result.graph.meta.metrics = [];
    var datePattern = /_date|_dt/i;
    result.graph.type = this.chartTypes[0];

    //for(let meta of result.columnMetas) {
    result.SQLResponse.columnMetas.forEach((meta, index) => {
      if (
        this.dateTypes.indexOf(meta.columnType) > -1 ||
        datePattern.test(meta.name)
      ) {
        result.graph.type = this.chartTypes[0];
        result.graph.meta.dimensions.push({
          column: meta,
          index: index,
          type: "date",
        });
      }
      if (this.stringTypes.indexOf(meta.columnType) > -1) {
        result.graph.meta.dimensions.push({
          column: meta,
          index: index,
          type: "string",
        });
      }
      if (this.numberTypes.indexOf(meta.columnType) > -1) {
        result.graph.meta.metrics.push({
          column: meta,
          index: index,
        });
      }
    });
  }
  resetGraph(result) {
    result.graph.type = this.result.type ? this.result.type : result.graph.type;

    this.result.type = this.result.type
      ? this.result.type
      : result.graph.type.value;
    if (result.graph.type == "line") {
      result.graph.type = this.chartTypes[0];
    }
    if (result.graph.type == "pie") {
      result.graph.type = this.chartTypes[2];
    }
    if (result.graph.type == "bar") {
      result.graph.type = this.chartTypes[1];
    }
    var dimension =
      result.graph.meta.dimensions && result.graph.meta.dimensions.length > 0
        ? result.graph.meta.dimensions[0]
        : null;
    var metrics =
      result.graph.meta.metrics && result.graph.meta.metrics.length > 0
        ? result.graph.meta.metrics[0]
        : null;
    result.graph.state = {
      dimensions: dimension,
      metrics: result.graph.type.metrics.multiple ? [metrics] : metrics,
    };
    this.refreshGraphData(result);
  }
  refreshGraphData(result) {
    this.mappingToChartype(result.graph.meta.dimensions);
    this.matricList = [];
    for (let i = 0; i < result.graph.meta.metrics.length; i++) {
      this.matricList.push({
        name: result.graph.meta.metrics[i].column.label,
        value: i,
      });
    }
    result.graph.state.dimensions = this.result.dimension
      ? this.result.dimension
      : result.graph.state.dimensions;
    result.graph.state.metrics = this.result.metrics
      ? this.result.metrics
      : result.graph.state.metrics;
    if (!isNaN(result.graph.state.dimensions)) {
      result.graph.state.dimensions =
        result.graph.meta.dimensions[result.graph.state.dimensions];
    }
    if (!isNaN(result.graph.state.metrics)) {
      result.graph.state.metrics =
        result.graph.meta.metrics[result.graph.state.metrics];
    }

    if (result.graph.state.dimensions && result.graph.state.metrics) {
      this.selectedDimension = result.graph.state.dimensions;

      if (this.selectedDimension) {
        // this.chart = {
        //   data:[],
        // };

        var chartType = result.graph.type.value;
        var selectedMetric = result.graph.state.metrics;
        var dataValues = [];
        result.SQLResponse.results.forEach((res, ind) => {
          var data = {
            label: res[this.selectedDimension.index],
            value: parseFloat(res[selectedMetric.index]),
          };
          if (this.selectedDimension.type === "date" && chartType === "line") {
            data.label = parseInt(moment(data.label).format("X"));
          }
          dataValues.push(data);
        });

        // dataValues = _.sortBy(dataValues, 'label');
        dataValues = dataValues.sort((a, b) => a.label - b.label);
        //QUBZ-3903-Validation required in Query Execution with zero records in Partition Cube-20/10/22
        if (dataValues.length != 0) {
          var oldLabel = dataValues[0].label;
          var groupValues = [{ label: dataValues[0].label, value: 0 }];
          dataValues.forEach((data) => {
            if (data.label === oldLabel) {
              groupValues[groupValues.length - 1].value += data.value;
            } else {
              groupValues.push(data);
              oldLabel = data.label;
            }
          });
        }
        this.chart.data = [
          {
            key: selectedMetric.column.label,
            values: groupValues,
          },
        ];
        //console.log(this.chart.data);
        if (chartType === "line") {
          this.chart.options = this.options.lineChartOptions;
          if (this.selectedDimension.type === "date") {
            this.chart.options.chart.xAxis.tickFormat = function (d) {
              return d3.time.format("%Y-%m-%d")(moment.unix(d).toDate());
            };
          }
        } else if (chartType === "bar") {
          this.chart.options = this.options.barChartOptions;
          if (groupValues.length > 15) {
            this.chart.options.chart.showLegend = false;
            this.chart.options.chart.xAxis.height = 100;
            this.chart.options.chart.margin.bottom = 150;
            this.chart.options.chart.xAxis.rotateLabels = -90;
            if (groupValues.length > 50) {
              this.chart.options.chart.showXAxis = false;
            }
          }
        } else if (chartType === "pie") {
          this.chart.options = this.options.pieChartOptions;
          this.chart.data = groupValues;
          if (groupValues.length > 15) {
            this.chart.options.chart.showLegend = false;
            this.chart.options.chart.showLabels = false;
          }
        }
      }
    }
  }
  // export(sql, projectId){
  //   format,sql,project,username,offset,limit,acceptPartial
  // }
  ngAfterViewInit() {
    $(document).ready(function () {
      var contenth = $(window).height() - 110;
      var sidebarh = $(window).height() - 111;
      $(".pagec").css("height", contenth);
      $(".sidebar-wrapper").css("height", sidebarh);
    });
  }
  cubeNameAlert() {
    alert(this.queryService.ddlcubeName);
  }
  ngOnInit() {
    this.defaultDate = "CURRENT DAY";
    this.loggedUser = JSON.parse(sessionStorage.getItem("loggedUser"));
    this.projectId = this.route.snapshot.paramMap.get("projectId");
    this.queries = JSON.parse(localStorage.getItem("queries_history"));
    // this.searchText = this.statusList[0].value;
    this.config_url = Config.url;
    //this.limit=5000;
    this.startTime = localStorage.getItem("startTime");

    this.options = {
      lineChartOptions: {
        chart: {
          type: "lineChart",
          height: 500,
          margin: {
            top: 20,
            right: 55,
            bottom: 60,
            left: 55,
          },
          useInteractiveGuideline: true,
          interpolate: "cardinal",
          x: function (d) {
            return d.label;
          },
          y: function (d) {
            return d.value;
          },
          xAxis: {
            axisLabelDistance: 50,
            staggerLabels: false,
            tickFormat: function (d) {
              if (d.length > 10) {
                return d.substring(0, 10) + "...";
              } else {
                return d;
              }
            },
          },
          yAxis: {
            tickFormat: function (d) {
              if (d < 1000) {
                if (parseFloat(d) === d) {
                  return d3.format(".1")(d);
                } else {
                  return d3.format(".2f")(d);
                }
              } else {
                var prefix = d3.formatPrefix(d);
                return prefix.scale(d) + prefix.symbol;
              }
            },
            showMaxMin: true,
          },
          valueFormat: function (d) {
            return d3.format(".1")(d);
          },
          transitionDuration: 500,
          tooltipContent: function (key, x, y, e, graph) {
            return "<h3>" + e.point.label + "</h3>" + "<p>" + y + "</p>";
          },
        },
      },
      barChartOptions: {
        chart: {
          type: "discreteBarChart",
          height: 500,
          margin: {
            top: 20,
            right: 20,
            bottom: 60,
            left: 55,
          },
          x: function (d) {
            return d.label;
          },
          y: function (d) {
            return d.value;
          },
          xAxis: {
            axisLabelDistance: 50,
            staggerLabels: false,
            tickFormat: function (d) {
              if (d.length > 10) {
                return d.substring(0, 10) + "...";
              } else {
                return d;
              }
            },
          },
          yAxis: {
            tickFormat: function (d) {
              if (d < 1000) {
                if (parseFloat(d) === d) {
                  return d3.format(".1")(d);
                } else {
                  return d3.format(".2f")(d);
                }
              } else {
                var prefix = d3.formatPrefix(d);
                return prefix.scale(d) + prefix.symbol;
              }
            },
            showMaxMin: false,
          },
          valueFormat: function (d) {
            return d3.format(".1")(d);
          },
          transitionDuration: 500,
          tooltipContent: function (key, x, y, e, graph) {
            return "<h3>" + e.point.label + "</h3>" + "<p>" + y + "</p>";
          },
        },
      },
      pieChartOptions: {
        chart: {
          type: "pieChart",
          height: 500,
          showLabels: true,
          duration: 500,
          labelThreshold: 0.01,
          labelSunbeamLayout: true,
          legend: {
            margin: {
              top: 20,
              right: 20,
              bottom: 60,
              left: 55,
            },
          },
          x: function (d) {
            return d.label;
          },
          y: function (d) {
            return d.value;
          },
          valueFormat: function (d) {
            return d3.format(".1")(d);
          },
        },
      },
    };

    this.searchText = "All";
    this.searchText1 = "All";
    this.dropdownSettings = {
      singleSelection: true,
      idField: "value",
      textField: "name",
      closeDropDownOnSelection: true,
    };
    //this.saveLogs();
  }
  onChange($event): void {
    if ($event.value != "All") {
      //QUBZ-3879 In Insights page Results should be changed from Failed query to Executing query while choosing From Dropdown 19/9/22
      this.status = null;
      this.result = null;
    }
    for (let query of this.queries1) {
      if (query.status == $event.value && $event.value != "All") {
        this.refreshCurQueryforTab(query.sql, query.project, query);
      } else if ($event.value == "All") {
        this.refreshCurQueryforTab(query.sql, query.project, query);
      }
    }
  }
  mappingToChartype(dimension) {
    let dimensions = [];
    for (let dim of dimension) {
      if (this.result.graph.type.dimension.types.indexOf(dim.type) > -1)
        dimensions.push(dim);
    }

    this.dimensionList = [];
    for (let i = 0; i < dimensions.length; i++) {
      this.dimensionList.push({
        name: dimensions[i].column.label,
        value: dimensions[i].index,
      });
    }
    //this.selectedDimensions=this.dimensionList[0];
    //return this.dimensionList;
    // return this.result.graph.type.dimension.types.indexOf(dimension.type) > -1;
  }

  showSaveModal() {
    this.showModal = true;
    this.queryName = undefined;
    this.queryDescription = undefined;
  }

  postQuery() {
    let query: string;
    this.queryList = JSON.parse(localStorage.getItem("queries_history"));
    for (let [key, value] of Object.entries(this.queryList)) {
      let res = <any>{};
      res = value;
      query = res.sql;
    }
    const params = {
      project: this.projectId,
    };
    this.queryService.getSavedQueries(params).subscribe((data) => {
      this.savedQueriesList = data;
      if (
        this.savedQueriesList.content.find(
          (ob) => ob["name"].toLowerCase() === this.queryName.toLowerCase()
        )
      ) {
        //swal('Oops...', "Query with this name"  +    '['+ this.queryName+']'  +     "already exist!", 'error');
        swal({
          type: "error",
          title: "Oops...",
          html:
            "Query with this name" +
            "&nbsp" +
            "[" +
            this.queryName +
            "]" +
            "&nbsp" +
            "already exists!",
        });
        this.showModal = false;
      } else {
        if (this.result.isTablegraph) {
          this.sqlRequest = {
            description: this.queryDescription,
            id: 0,
            name: this.queryName,
            project: this.projectName,
            sql: query,
            graphType: this.result.type,
            dimensions: this.result.dimension,
            metrics: this.result.metrics,
          };
        } else {
          this.sqlRequest = {
            description: this.queryDescription,
            id: 0,
            name: this.queryName,
            project: this.projectName,
            sql: query,
          };
        }

        this.queryService.saveQueryByProject(this.sqlRequest).subscribe(
          (data) => {
            swal("Success!", "New query saved", "success");

            const params = {
              project: this.projectName,
            };
            this.queryService.getSavedQueries(params).subscribe((data) => {
              this.savedQueriesList = data;
              //this.setTab(2);
              //  setTimeout(() => {
              // this.router.navigated = false;
              // this.router.navigate([this.router.url]);
              //   }, 1000);
              this.savedQueriesEvent.emit(this.savedQueriesList);
            });

            this.showModal = false;
          },
          (error) => {
            swal({
              type: "error",
              title: " OOPS...",
              text: error.error.msg,
              confirmButtonText: "Ok",
            }).then(
              (status) => {
                if (status.value == true) {
                }
              },
              (dismiss) => {
                if (dismiss === "cancel") {
                }
              }
            );
          }
        );
      }
    });
  }

  close() {
    this.showModal = false;
  }
  isUserAdmin(authorities) {
    for (let authority of authorities) {
      if (authority["authority"] == "ROLE_ADMIN") {
        return true;
      }
    }
    return false;
  }

  saveLogs() {
    var today = new Date();
    let param = {
      username: this.loggedUser.username,
      targetPage: "projects/" + this.projectName + "/query",
      day: today.getDay(),
      month: today.getMonth(),
      year: today.getFullYear(),
    };
    this.commonServiceService.saveLogs(param).subscribe((data) => { });
  }

  dropAll() {
    swal({
      type: "warning",
      title: "Are you sure you want to delete all the queryhistory?",
      text: "All information associated to this queryhistory will be permanently deleted",
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "No",
    }).then(
      (status) => {
        if (status.value === true) {
          const params = {
            project: this.projectId,
          };
          this.queryService.deleteQueryHistoryAll(params).subscribe(
            (data) => {
              swal("Success!", "queryHistoryDeleted", "success");
              this.queryService.savehistory(this.history);
            },
            (error) => {
              swal({
                type: "error",
                title: " OOPS...",
                text: error.error.msg,
                confirmButtonText: "Ok",
              }).then(
                (status) => {
                  if (status.value == true) {
                  }
                },
                (dismiss) => {
                  if (dismiss === "cancel") {
                  }
                }
              );
            }
          );
        }
      },
      (dismiss) => {
        if (dismiss === "cancel") {
        }
      }
    );
  }

  cancelQuery() {
    this.queryService
      .stopQuery(this.querydtls.queryId, this.querydtls.query)
      .subscribe(
        (data) => {
          this.cancelResult = data;
          if (this.cancelResult.queryStatus != 'RUNNING')//QUBZ-3848 12/09/2022 QUBZ 6.0.0-Cancel query not working properly
            swal("Success!", "Query has been cancelled", "success");
        },
        (error) => {
          swal({
            type: "error",
            title: " OOPS...",
            text: error.error.msg,
            confirmButtonText: "Ok",
          }).then(
            (status) => {
              if (status.value == true) {
                // this.error = error.text;
                // this.status = 'Failed';
                // this.errorEvent.emit(this.error);
                // this.resultEvent.emit(this.result);
                // this.refresh=this.error;
                // this.indexTab=this.error;
              }
            },
            (dismiss) => {
              if (dismiss === "cancel") {
                // this.error = error;
                // this.status = 'Failed';
                // this.errorEvent.emit(this.error);
                // this.resultEvent.emit(this.result);
                // this.refresh=this.error;
                // this.indexTab=this.error;
              }
            }
          );
        }
      );
  }

  setDays(event) {
    this.searchDate = event;
    localStorage.setItem("frmQuery", "FromQuery");
  }
}
