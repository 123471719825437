import { Component, OnInit, AfterViewInit } from "@angular/core";
import { UserSettingsService } from "../../services/users/user-settings.service";
import { AbstractControl, NgForm, ValidatorFn } from "@angular/forms";
import {
  FormBuilder,
  FormGroup,
  FormArray,
  FormControl,
  Validators,
} from "@angular/forms";
import { Users } from "../../models/users/users.model";
import { User } from "../../models/users/user.model";
import * as $ from "jquery";
import swal from "sweetalert2";
import { Router } from "@angular/router";
import { NgbTooltipModule } from "@ng-bootstrap/ng-bootstrap";
import { stringify } from "querystring";
import { UserManagementService } from "../../services/users/user-management.service";
import { NavigationComponent } from "../../components/common/navigation/navigation.component";
import { AuthService } from "src/app/services/auth/auth.service";
import { TemplateServiceService } from "src/app/services/template/template-service.service";
@Component({
  providers: [NavigationComponent],
  selector: "app-profile",
  templateUrl: "./profile.component.html",
  styleUrls: ["./profile.component.scss"],
})
export class ProfileComponent implements OnInit, AfterViewInit {
  public user: User;
  public userDetails: Users[];
  userData;
  form: FormGroup;
  passwordForm: FormGroup;
  emailPattern = "^[a-zA-Z0-9._%+-]+@[a-z0-9.-]+[.][a-z]{2,4}$";
  imgURL: any;
  show_element: boolean;
  username: string;
  public firstName: string;
  lastName: string;
  displayName: string;
  emailId: string;
  imageData;
  message: string;
  errormsg: string;
  imagePreview: string | ArrayBuffer;
  public imagePath;
  icon: any;
  status: boolean;
  formvalues;
  userdetail;
  fname: string;
  lname: string;
  displayname: string;
  email: string;
  ldap;
  load;
  imgProfileURL: any;
  profileImgURL;
  imgURL1: any;
  imgURL2: any;
  imgURL3: any;
  imgURL4: any;
  imgURL5: any;
  imgName1;
  imgName2;
  imgName3;
  imgName4;
  imgName5;
  userAvatar;
  avatarName;
  avatar;
  colorTheme;
  typePassword;
  show_changepwd_element;
  userdata: User;
  show_pwd: boolean = true;
  constructor(
    private formBuilder: FormBuilder,
    private userSettingService: UserSettingsService,
    private router: Router,
    private comp: NavigationComponent,
    private authService: AuthService,
    private templateServiceService: TemplateServiceService,
    private userManagementService: UserManagementService
  ) {}
  ngAfterViewInit() {
    $(document).ready(function () {
      var contenth = $(window).height() - 110;
      var sidebarh = $(window).height() - 108;
      $(".pagec").css("height", contenth);
      $(".sidebar-wrapper").css("height", sidebarh);
    });
  }

  ngOnInit() {
    this.onRefresh();

    this.errormsg = "";
    this.passwordForm = this.formBuilder.group({
      userid: new FormControl(null),
      username: new FormControl(null),
      password: new FormControl(null, [
        Validators.required,
        this.passwordValidation(),
      ]),
    });
  }

  onEditBtn(userData) {
    //  this.userdetail={firstName:String,lastName:String, emailId:String}
    //this.userdetail.firstName=userData.firstName;
    this.fname = userData.firstName;
    this.lname = userData.lastName;
    this.displayname = userData.displayName;
    this.email = userData.emailId;
    this.ldap = userData.qubzLDAPUser;

    this.onRefresh();
    this.show_element = true;
  }
  togglePassword() {
    this.typePassword = !this.typePassword;
  }
  OnCancelChangepwd() {
    this.show_changepwd_element = false;
    $(".profilec").toggleClass("profileshow");
  }
  logout() {
    const credentials = {
      username: "",
      password: "",
    };
    sessionStorage.setItem("credentials", JSON.stringify(credentials));

    localStorage.clear();
    sessionStorage.clear();
    this.authService.logout();
  }
  onChangePassword(user) {
    this.passwordForm.reset();

    this.userSettingService.getUserDetails(user.username).subscribe((data) => {
      let res: any;
      res = data;
      this.show_changepwd_element = true;
      // sessionStorage.setItem('user', JSON.stringify(res.data));
      this.user = res.data;
    });
  }
  updatePassword(user) {
    user.password = this.passwordForm.value.password;
    this.userManagementService.updatePassword(user.uuid, user).subscribe(
      (data) => {
        swal(
          "Success!",
          "User password has been updated successfully",
          "success"
        );
        const credentials = {
          username: user.username,
          password: this.passwordForm.value.password,
        };

        sessionStorage.setItem("credentials", JSON.stringify(credentials));
        this.show_changepwd_element = false;
        $(".profilec").toggleClass("profileshow");
      },
      (error) => {
        swal("Error!", "Error occured!", "error");
      }
    );
  }
  profileUpdate(form: NgForm) {
    form.value.qubzLDAPUser = this.userData.qubzLDAPUser;
    if (
      form.value.username == null ||
      form.value.firstName == null ||
      form.value.lastName == null ||
      form.value.displayName == null ||
      (form.value.emailId == null && !this.userData.qubzLDAPUser)
    ) {
      this.errormsg = "Please fill all the required fields!";
    } else {
      this.formvalues = form.value;

      //if((this.imgProfileURL!=null)||(this.imgProfileURL!=undefined)){
      this.imageUpload(this.formvalues);
    }
    // else{
    //   this.errormsg="";
    //   this.userSettingService.updateProfile(form.value).subscribe(
    //     data=>{
    //       swal("Success!", "Profile details has been updated successfully", "success");
    //       this.show_element=false;

    //       this.onRefresh();
    //     },
    //     error=>{
    //       swal("Error!", error.error.msg, "error");
    //     });
    // }
    //}
  }
  onSelectAvathar(imageSelected, avatarName) {
    this.imgProfileURL = imageSelected;
    this.avatarName = avatarName;
  }
  OnCancel() {
    this.onRefresh();
    this.show_element = false;
  }
  preview(files) {
    if (files.length === 0) return;

    var mimeType = files[0].type;
    if (mimeType.match(/image\/*/) == null) {
      this.message = "Only images are supported.";
      swal("Error!", this.message, "error");
      return;
    }

    var reader = new FileReader();
    this.imagePath = files;
    reader.readAsDataURL(files[0]);
    reader.onload = (_event) => {
      this.imgURL = reader.result;
    };
    this.icon = files[0];
  }
  imageUpload(formvalues) {
    this.avatar = {
      fileName: this.avatarName,
    };
    this.formvalues.colorTheme = this.colorTheme;

    this.userSettingService.updateProfile(formvalues).subscribe(
      (data) => {
        this.userSettingService.updateProfileImage(this.avatar).subscribe(
          (data) => {
            swal(
              "Success!",
              "Profile details has been updated successfully",
              "success"
            );
            this.show_element = false;
            this.onRefresh();
          },
          (error) => {
            swal("Error!", error.error.msg, "error");
          }
        );
      },
      (error) => {
        swal("Error!", error.error.msg, "error");
      }
    );
  }

  onRefresh() {
    this.load = true;
    this.userSettingService.getProfile().subscribe((data) => {
      this.userData = data;
      this.userData = this.userData.userProfileDetails;
      this.username = this.userData.username;
      this.firstName = this.userData.firstName;
      this.lastName = this.userData.lastName;
      this.emailId = this.userData.emailId;
      this.displayName = this.userData.displayName;
      this.load = false;
      this.imgURL = "../../../../assets/images/";
      if (this.userData.avatarFileName == "default") {
        this.userData.avatarFileName = "usericon_other.png";
      }
      this.imgProfileURL = this.imgURL + this.userData.avatarFileName;
      this.profileImgURL = this.imgURL + this.userData.avatarFileName;
      this.imgURL1 = this.imgURL + "usericon_fw.png";
      this.imgURL2 = this.imgURL + "usericon_fm.png";
      this.imgURL3 = this.imgURL + "usericon_other.png";
      this.imgURL4 = this.imgURL + "usericon_cw.png";
      this.imgURL5 = this.imgURL + "usericon_cm.png";
      this.imgName1 = "usericon_fw.png";
      this.imgName2 = "usericon_fm.png";
      this.imgName3 = "usericon_other.png";
      this.imgName4 = "usericon_cw.png";
      this.imgName5 = "usericon_cm.png";
      this.templateServiceService.getSavedTemplate().subscribe((data) => {
        let res = <any>{};
        res = data;
        this.colorTheme = res.qubzUser.colorTheme;
      });
    });
  }

  passwordValidation(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const passPattern: RegExp =
        /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/;

      let pass: string = control.value;

      if (!pass) {
        return null;
      }

      if (!passPattern.test(pass) || pass.indexOf(" ") != -1) {
        return { invalidMail: { value: control.value } };
      } else {
        return null;
      }
    };
  }
}
export class CustomValidator {
  static username(control: FormGroup) {
    if (control.value) {
      if (
        !control.value.match(
          "^[A-Za-z0-9]+[a-zA-Z0-9_|+|.|-]{3,100}$|^[a-zA-Z0-9._%+-]+@[a-z0-9.-]+[.][a-z]{2,4}$"
        )
      ) {
        return { invaliduname: true };
      } else {
        return null;
      }
    }
  }

  static alphabetonly(control: FormGroup) {
    if (control.value) {
      if (!control.value.match("^[A-Za-z]*$")) {
        return { invalidalpha: true };
      } else {
        return null;
      }
    }
  }
  static AlphabetSpaceOnly(control: FormGroup) {
    if (control.value) {
      if (!control.value.match("^[a-zA-Z ]*$")) {
        return { InvalidAlphaSpace: true };
      } else {
        return null;
      }
    }
  }
  static alphabet(control: FormGroup) {
    if (control.value) {
      if (!control.value.match("(?=.*[A-Z])")) {
        return { invalidChar: true };
      } else {
        return null;
      }
    }
  }
  static number(control: FormGroup) {
    if (control.value) {
      if (!control.value.match("(?=.*[0-9])")) {
        return { invalidNum: true };
      } else {
        return null;
      }
    }
  }

  static len(control: FormGroup) {
    if (control.value) {
      if (!control.value.match(".{8,}")) {
        return { invalidLen: true };
      } else {
        return null;
      }
    }
  }
  static character(control: FormGroup) {
    if (control.value) {
      if (!control.value.match("([?=.*!@#$%^&*])")) {
        return { invalidCharacter: true };
      } else {
        return null;
      }
    }
  }
}
