import { Injectable } from '@angular/core';
import { HttpClient,HttpParams } from '@angular/common/http';
import { Config } from '../../config';

@Injectable({
  providedIn: 'root'
})
export class ProjectDataCatalogService {

  static url = Config.url + '/datacatalogs';

constructor(private http: HttpClient) { }

getVisibleDataCatalogs (page,count,search_key,searchType){
  let params;
  let status:any=true;
  params = new HttpParams()
  // .set('page', page)
  // .set('size', count)
  
  if(search_key==null){
   
     params = new HttpParams()
    .set('page', page)
    .set('size', count)
    .set('status', status)
  }else{
    if(searchType==null || searchType=='name'){
       params = new HttpParams()
      .set('page', page)
      .set('size', count)
      .set('name', search_key)
      .set('status', status)
    }
    else if(searchType=='sourceType'){
       params = new HttpParams()
      .set('page', page)
      .set('size', count)
      .set('sourceType', search_key)
      .set('status', status)
    }
    else if(searchType=='status'){
       params = new HttpParams()
      .set('page', page)
      .set('size', count)
      .set('status', status)
    }
   
  }
  // if(search_key!='' && )
  return this.http.get(ProjectDataCatalogService.url,{params});
}

getVisibleTablesByDataCatalogId(dataCatalogId, tableId){
  return this.http.get(ProjectDataCatalogService.url + '/' + dataCatalogId + '/tables' + '/' +  tableId);
  }

getVisibleColumnsByTableIdAndCatalogId(dataCatalogId, tableId){
  return this.http.get(ProjectDataCatalogService.url + '/' + dataCatalogId + '/tables' + '/' + tableId + '/columns');
  }
}
