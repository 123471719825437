import { Injectable } from '@angular/core';
import { HttpClient,HttpParams } from '@angular/common/http';
import { Config } from '../../config';

@Injectable({
  providedIn: 'root'
})
export class DashboardService {

   static url = Config.url + '/dashboard';
  constructor(private http: HttpClient) { }
  

  getCubeMetrics( params ) {
      return this.http.get(DashboardService.url + '/metric/cube', { params });
  }

   getQueryMetrics(params) {
      return this.http.get(DashboardService.url + '/metric/query', { params });
  }

   getJobMetrics(params) {
      return this.http.get(DashboardService.url + '/metric/job', {params});
  }

  getChartData(param) {
    const params = new HttpParams()
    .set('startTime', param.startTime)
    .set('endTime', param.endTime);
      return this.http.get(DashboardService.url+ '/chart/'+param.category+'/'+param.metric+'/'+param.dimension,{params});
  }
}
