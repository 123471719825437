import { Component, OnInit , AfterViewInit} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ModelDescService } from '../../../../services/model/model-desc.service';
import {ModelStepsService} from '../../../../services/model/model-steps.service';
import * as d3 from 'd3';
import * as $ from 'jquery'
import { CommonServiceService } from '../../../../services/common-services/common-service.service';



@Component({
  selector: 'app-model-detail',
  templateUrl: './model-detail.component.html',
  styleUrls: ['./model-detail.component.scss']
})
export class ModelDetailComponent implements OnInit,AfterViewInit {

  loggedUser;
  projectName:any;
  modelName:any;
  model;
  curStep = 1;
  jsonStep=1;
  modelDesc:any={};
  isGridEnabled:boolean;
  isJSONEnabled:boolean;
  myJSON;

  event:MouseEvent;
  modelObj:any=[];
  isVisualEnabled:boolean = true;
  options;
  data;
  tablesNodeList={};


  constructor(private router: Router, private route: ActivatedRoute, private  modelDescService :ModelDescService, private modelStepsService: ModelStepsService, public commonServiceService:CommonServiceService) {
    this.loggedUser = JSON.parse(sessionStorage.getItem("loggedUser"));
   }

  ngAfterViewInit() {
    $(document).ready(function(){
          var contenth = $( window ).height() - 110 ;
          var sidebarh = $( window ).height() - 111 ;
          $(".pagec").css("height", contenth );
          $(".sidebar-wrapper").css("height", sidebarh );
        });
    }
    
  ngOnInit() {
    this.getModelDesc();
    this.isJSONEnabled=true;
    //this.saveLogs();
  }
  getModelDesc(){
    this.projectName = this.route.snapshot.paramMap.get("projectId");
    this.model=this.route.snapshot.paramMap.get("modelId");
    this.modelName = this.modelStepsService.modelName;
    this.modelDescService.query(this.model).subscribe(data=>{
        this.modelDesc = data;

    })

  }

	setStep(step: any) {
   this.isGridEnabled = false;
   this.isJSONEnabled = true;
   this.isVisualEnabled= true;
		this.curStep = step;
  }

  showJSON() {
    this.isGridEnabled = true;
    this.isJSONEnabled = false;
    this.isVisualEnabled= true;
  //  this.myJSON =this.modelDesc;
  this.modelDescService.query(this.modelDesc.name).subscribe(data=>{
    this.myJSON = data;

})

  }

  showVisual(){
    this.isVisualEnabled= false; 
    this.isGridEnabled = true;
     this.isJSONEnabled = true;
    this.buildTree(this.modelDesc);
    
  }

  removeNameSpace(str){
    if(str){
       return str.replace(/([^.\s]+\.)+/,'');
    }else{
      return '';
    }
  }

  getNameSpaceAliasName(str){
    if(str){
       return str.replace(/\.[^.]+$/,'');
    }else{
      return '';
    }
  }

findColumns(name, obj){
    if(obj.dimensions)
{
    let column = obj.dimensions.find(item => item.table === name);
return column.columns;
}
}

  buildTree = function (model) {
    let margin = {top: 20, right: 100, bottom: 20, left: 100},
    width = 1100 - margin.right - margin.left,
    height = 600;
    $("#model_graph_" + model.name).empty();

   
    let tree = d3.layout.tree().size([height, width - 160]);
    let diagonal = d3.svg.diagonal().projection(function (d) {
        return [d.y, (d.x + 60)];
    });
  
    let svg = d3.select("#model_graph_" + model.name).append("svg:svg")
        .attr("width", '100%')
        .attr("height", height)
        .append("svg:g")
        .attr("transform", "translate(" + margin.left + "," + margin.top + ")");


    let graphData = {
        "type": "FACT",
        "name": this.removeNameSpace(model.fact_table),
        "columns": this.findColumns(this.removeNameSpace(model.fact_table), model),
        "children": []
    };
    
    this.tablesNodeList[graphData.name]=graphData;
  
    model.graph = (!!model.graph) ? model.graph : {};
    
        for(let lookup of model.lookups){
      
         if(!lookup.alias){
           lookup.alias=this.removeNameSpace(lookup.table);
         }
         if (lookup.join && lookup.join.primary_key && lookup.join.primary_key.length > 0) {
           let  dimensionNode={
               "type": lookup.kind,
               "name": lookup.alias,
               "columns": this.findColumns(lookup.alias,model),
               "join": lookup.join,
               "children": [],
               "_children": []
             }
            this.tablesNodeList[dimensionNode.name]=dimensionNode;
           }
          
       
       }

 
    for(let lookup of model.lookups){  
         if (lookup.join && lookup.join.primary_key && lookup.join.primary_key.length > 0) {
           let fkAliasName= this.getNameSpaceAliasName(lookup.join.foreign_key[0]);
           let  pkAliasName= this.getNameSpaceAliasName(lookup.join.primary_key[0]);  
           this.tablesNodeList[fkAliasName].children.push(this.tablesNodeList[pkAliasName]);
         
         }
   
       }



    model.graph.columnsCount = 0;

    model.graph.tree = tree;
    model.graph.root = graphData;
    model.graph.svg = svg;
    model.graph.diagonal = diagonal;
    model.graph.i = 0;

    model.graph.root.x0 = height / 2;
    model.graph.root.y0 = 0;

    this.update(model.graph.root, model);
}
update(source, model) {
    let margin = {top: 20, right: 100, bottom: 20, left: 100},
    width = 1100 - margin.right - margin.left,
    height = 600;
    let duration = 750;

    // Compute the new tree layout.
    let nodes = model.graph.tree.nodes(model.graph.root).reverse();
    // Update the nodes
    let node = model.graph.svg.selectAll("g.node")
        .data(nodes, function (d) {
            return d.id || (d.id = ++model.graph.i);
        });
    let nodeEnter = node.enter().append("svg:g")
        .attr("class", "node")
        .attr("transform", function (d) {
            return "translate(" + source.y0 + "," + source.x0 + ")";
        });
    // Enter any new nodes at the parent's previous position.
    let table = nodeEnter
            .append("foreignObject")
            .attr("width", "100px")
            .attr("height", "120px")
            .attr("class", "vm_block")
            .style('overflow-y', 'auto')
            .append("xhtml:table")
            .attr('id',function(d,i){
                return 'invokeTable-' + d.name;
            })
            .attr("width","100px")
            .attr("height","100px")
        .on("click", function (d) {
            if (d.children) {
                d._children = d.children;
                d.children = null;
                if (d.type == 'dimension') {
                    model.graph.columnsCount -= d._children.length;
                }
            } else {
                d.children = d._children;
                d._children = null;
                if (d.type == 'dimension') {
                    model.graph.columnsCount += d.children.length;
                }
            }

            let perColumn = 35;
            let newHeight = (((model.graph.columnsCount * perColumn > height) ? model.graph.columnsCount * perColumn : height));
            $("#model_graph_" + model.name + "svg").height(newHeight);

            model.graph.tree.size([newHeight, width - 160]);
            this.update(d, model);
        });

    let tablebody = table.append("tbody");
    let rows = tablebody
            .selectAll("tr")
            .data(d => {
               return d.columns;
            })
            .enter()
            .append("tr");
    let cells = rows
                .append("td")
                .style("background-color","white")
                .style('word-break','break-word')
                .style('border','1px solid #B0C4DE')
                .style("padding","0px 10px 0px 10px")
                .style("font-size", "10px")
                .style("color",'#333')
                .text(function(d) {
                    return d;
                });
    nodeEnter.append("svg:text")
        .attr("x", function (d) {
            return -90;
        })
        .attr("y", 3)
        .style("font-size", "50px")
        .text(function (d) {
            if (d.type == "dimension") {
                let joinTip = "";

                model.forEach(d.join.primary_key, function (pk, index) {
                    joinTip += ( model.graph.root.name + "." + d.join.foreign_key[index] + " = " + d.name + "." + pk + "<br>");
                });

                d.tooltip = d3.select("body")
                    .append("div")
                    .style("position", "absolute")
                    .style("z-index", "10")
                    .style("font-size", "11px")
                    .style("visibility", "hidden")
                    .html(joinTip);
                let joinType = (d.join) ? (d.join.type) : '';

                return joinType + " join";
            }
            else {
                return "";
            }
        })
        .on('mouseover', function (d) {
            return d.tooltip.style("visibility", "visible");
        })
        .on("mousemove", function (d) {
            return d.tooltip.style("top", (this.event.pageY + 30) + "px").style("left", (this.event.pageX - 50) + "px");
        })
        .on('mouseout', function (d) {
            return d.tooltip.style("visibility", "hidden");
        });

    nodeEnter.append("svg:text")
        .attr("y", -3)
        .attr("class", "vm_title")
        .style("width", "100px")
        .style("word-break","break-word")
        .style("display", "block")
        .text(function (d) {
            let dataType = (d.dataType) ? ('(' + d.dataType + ')') : '';

            return d.name + dataType;
        });

    // Transition nodes to their new position.
    nodeEnter.transition()
        .duration(duration)
        .attr("transform", function (d) {
            return "translate(" + d.y + "," + d.x + ")";
        })
        .style("opacity", 1)
        .select("circle")
        .style("fill", function (d) {
            switch (d.type) {
                case 'fact':
                    return '#fff';
                case 'dimension':
                    return '#B0C4DE';
                case 'column':
                    return 'black'
                default:
                    return '#B0C4DE';
            }
        });

    node.transition()
        .duration(duration)
        .attr("transform", function (d) {
            return "translate(" + d.y + "," + d.x + ")";
        })
        .style("opacity", 1);

    node.exit().transition()
        .duration(duration)
        .attr("transform", function (d) {
            return "translate(" + source.y + "," + source.x + ")";
        })
        .style("opacity", 1e-6)
        .remove();

    // Update the links…
    let link = model.graph.svg.selectAll("path.link")
        .data(model.graph.tree.links(nodes), function (d) {
            return d.target.id;
        });

    // Enter any new links at the parent's previous position.
    link.enter().insert("svg:path", "g")
        .attr("class", "link")
        .attr("d", function (d) {
            let o = {x: source.x0, y: source.y0};
            return model.graph.diagonal({source: o, target: o});
        })
        .transition()
        .duration(duration)
        .attr("d", model.graph.diagonal);

    // Transition links to their new position.
    link.transition()
        .duration(duration)
        .attr("d", model.graph.diagonal);

    // Transition exiting nodes to the parent's new position.
    link.exit().transition()
        .duration(duration)
        .attr("d", function (d) {
            let o = {x: source.x, y: source.y};
            return model.graph.diagonal({source: o, target: o});
        })
        .remove();

    // Stash the old positions for transition.
    nodes.forEach(function (d) {
        d.x0 = d.x;
        d.y0 = d.y;
    });
}

saveLogs(){
    var today = new Date();
    let param={
      username:this.loggedUser.username,
	  targetPage:'projects/'+this.projectName+'/models/'+this.model+'/detail',
      day:today.getDay(),
      month:today.getMonth(),
      year:today.getFullYear()
    };
    this.commonServiceService.saveLogs(param).subscribe(
      data => {
  
      });
  }


}
