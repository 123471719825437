import { Component, OnInit, AfterViewInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { TableService } from '../../../../../services/table/table.service';
import { FormGroup,  FormControl, FormBuilder,  Validators, FormArray } from '@angular/forms';
import {ModelStepsService} from '../../../../../services/model/model-steps.service';
import swal from 'sweetalert2';
import { NgSelectComponent } from '@ng-select/ng-select';





@Component({
  selector: 'app-data-model',
  templateUrl: './data-model.component.html',
  styleUrls: ['./data-model.component.scss']
})
export class DataModelComponent implements OnInit, AfterViewInit {
  projectId:any;
  factTable:any;
  tableServiceList:any=[];
  showAddlookupBtn: boolean = true;
  showNextButton: boolean = true;
  showModal : boolean;
  disableFromTable: boolean;
  fromtableList:any=[];
  disableLookUp:boolean = false;
  joinType:any;
  selectLookupList:any=[];
  selFromTable:string;
  selLookup:string;
  aliasName:any;
  showFromTableColumn: boolean = true;
  fromTableColumns:any=[];
  form:FormGroup;
  dropDownList:FormArray;
  selFromTableColumn:any;
  enableFromtable:boolean = true;
  selLookupColumn:any;
  showSelectLookUpColumn:boolean = true;
  enableSelectLookupTable:boolean = true;
  selectLookupColumns:any=[];
  showDeleteColumn:boolean = true;
  showjoinTable:boolean = true;
  tableInfoList:any=[];
  databaseName:any;
  aliasList:any=[];
  editMode:boolean;
  editList:any=[];
  factAliasList:any=[];
  factAliasName:any;
  showSaveButton:boolean = true;
  getFactTable:any;
  uuidList:any=[];
  Tbluuid:any;
  dataTypeList:any=[];
  pkType:any;
  fkType:any;
  databaseList:any;
  lookup=[];
  selectedLookUp;
  searchText:string;
  unamePattern = "^[A-Z0-9_]{1,100}$";
  showAlaisError:boolean = true;
  errorIndex;
  dataSourceName:any;
  kind:boolean = false;



  joinTypes= [
    {name: 'inner', value: 'Inner Join'},
    {name: 'left', value: 'Left Join'}
  ]

  @ViewChild('select') select: NgSelectComponent;


  constructor(private route: ActivatedRoute, private router: Router, private tableService: TableService, private fb:FormBuilder, private modelStepsService: ModelStepsService) {
    if(this.modelStepsService.modelName ===  undefined || this,modelStepsService.modelDescription === undefined){
      this.projectId = this.route.snapshot.paramMap.get("projectId");
      this.router.navigate(['/projects/',this.projectId ,'models','designer','info']);
    } 
    this.form = fb.group({
      dropDowns: fb.array([])
    });
    this.dropDownList = this.form.get('dropDowns') as FormArray;
   }

   ngAfterViewInit() {
    setTimeout(function(){ 
    /* custome select option starts*/
    var x, i, j, selElmnt, a, b, c;
    x = document.getElementsByClassName("select-custom");
    for (i = 0; i < x.length; i++) {
      selElmnt = x[i].getElementsByTagName("select")[0];
      a = document.createElement("DIV");
      a.setAttribute("class", "select-selected");
      a.innerHTML = selElmnt.options[selElmnt.selectedIndex].innerHTML;
      x[i].appendChild(a);
      b = document.createElement("DIV");
      b.setAttribute("class", "select-items select-hide");
      for (j = 1; j < selElmnt.length; j++) {
        c = document.createElement("DIV");
        c.innerHTML = selElmnt.options[j].innerHTML;
        c.addEventListener("click", function(e) {
            var y, i, k, s, h;
            s = this.parentNode.parentNode.getElementsByTagName("select")[0];
            h = this.parentNode.previousSibling;
            for (i = 0; i < s.length; i++) {
              if (s.options[i].innerHTML == this.innerHTML) {
                s.selectedIndex = i;
                h.innerHTML = this.innerHTML;
                y = this.parentNode.getElementsByClassName("same-as-selected");
                for (k = 0; k < y.length; k++) {
                  y[k].removeAttribute("class");
                }
                this.setAttribute("class", "same-as-selected");
                break;
              }
            }
            h.click();
        });
        b.appendChild(c);
      }
      x[i].appendChild(b);
      a.addEventListener("click", function(e) {
        e.stopPropagation();
        closeAllSelect(this);
        this.nextSibling.classList.toggle("select-hide");
        this.classList.toggle("select-arrow-active");
      });
    }
    
    function closeAllSelect(elmnt) {
      var x, y, i, arrNo = [];
      x = document.getElementsByClassName("select-items");
      y = document.getElementsByClassName("select-selected");
      for (i = 0; i < y.length; i++) {
        if (elmnt == y[i]) {
          arrNo.push(i)
        } else {
          y[i].classList.remove("select-arrow-active");
        }
      }
      for (i = 0; i < x.length; i++) {
        if (arrNo.indexOf(i)) {
          x[i].classList.add("select-hide");
        }
      }
    }
    document.addEventListener("click", closeAllSelect);
    /* custom select option end */
    }, 2000);
  }

  ngOnInit() {

    this.projectId = this.route.snapshot.paramMap.get("projectId");
    this.joinType = this.joinTypes[0].value;
    this.selFromTable = "-- From Table --";
    this.searchText=null;
    this.setFocus();
    if(this.modelStepsService.factTable!==undefined){
      this.factTable =  this.modelStepsService.factTable;
      this.tableServiceList = this.modelStepsService.tableList;
      this.tableServiceList.sort((a, b) => a.dataSourceName.localeCompare(b.dataSourceName));
      this.showAddlookupBtn= false;
      this.showNextButton = false;
      this.getFromTable(this.factTable);
      this.getSelectLookup();
      this.factTable =  this.modelStepsService.factTable;
    
    }else{
      this.list();
    }
    if(this.modelStepsService.lookUps!==undefined && this.modelStepsService.lookUps.length>0){
      this.lookup = this.modelStepsService.lookUps;
      this.showjoinTable = false;
      this.modelStepsService.joinTable =this.showjoinTable;
    }
    if(this.modelStepsService.editMode!==undefined){
      this.editMode =this.modelStepsService.editMode;
      
    }

    if(this.modelStepsService.aliasName!==undefined){
      this.aliasName = this.modelStepsService.aliasName;
    }

    if(this.modelStepsService.selFromTable!==undefined){
      this.selFromTable = this.modelStepsService.selFromTable;
    }

    if(this.modelStepsService.frmTableList!==undefined){
      this.fromtableList = this.modelStepsService.frmTableList;
    }

    if(this.modelStepsService.selLookup!==undefined){
      this.selLookup = this.modelStepsService.selLookup;
    }
  
  }


  list(){
    let params={
      ext:'true',
      project:this.projectId
    }

    this.tableService.list(params).subscribe(data=>{
          this.tableServiceList = data;
          for (let [key, value] of Object.entries(this.tableServiceList)) {
            let res= <any>{};   
            res=value;
            res.dataSourceName = res.database + '.' + res.name;
          }
          this.modelStepsService.tableList =this.tableServiceList;
          this.tableServiceList.sort((a, b) => a.dataSourceName.localeCompare(b.dataSourceName));
    })
  }

  showAllBtns(){
    if(this.factTable === '-- Select Fact Table --'){
         this.showAddlookupBtn = true;
         this.showNextButton = true;
         this.showjoinTable = true;
         this.lookup=[];
         this.modelStepsService.lookUps=[];
    } else{
       this.showAddlookupBtn = false;
       this.showNextButton = false;
       if(this.modelStepsService.factTable!== this.factTable){
         this.showjoinTable = true;
         this.lookup=[];
         this.modelStepsService.lookUps=[];
       }
      this.modelStepsService.lookUps = undefined;
      this.modelStepsService.joinTable = undefined;
      this.modelStepsService.dimensionColumns =undefined;
      this.modelStepsService.dimensionColumns1 = undefined;
      this.modelStepsService.dimensionName = undefined;
      this.modelStepsService.dimensionName1 = undefined;
      this.modelStepsService.metricName = undefined;
      this.modelStepsService.partitionDateTblName = undefined;
      this.modelStepsService.partitionDateColName = undefined;
      this.modelStepsService.checkBoxName = undefined;
      this.modelStepsService.partitionTimeTblName = undefined;
      this.modelStepsService.partitionDateFormatName = undefined;
      this.modelStepsService.paritionTimeColName = undefined;
      this.modelStepsService.partitionTimeFormatName = undefined;
      this.modelStepsService.partitionFilter = undefined;
      this.modelStepsService.aliasName = undefined;
      this.modelStepsService.factAliasName = undefined;
      this.modelStepsService.selFromTable = undefined;
      this.modelStepsService.selFromTableColumn = undefined;
      this.modelStepsService.selLookupColumn = undefined;
      this.modelStepsService.dimensionLookup =undefined;
      this.modelStepsService.frmTableList = undefined;
      this.modelStepsService.dimensionLookup = undefined;
      this.modelStepsService.measureLookup = undefined;
      this.modelStepsService.kindType = undefined;
      this.modelStepsService.kind = undefined;
      this.modelStepsService.metricName1 = undefined;
      
          
    }
   
  }

  
 
  openModal(){
    this.editMode = false;
    this.modelStepsService.editMode = this.editMode;
    this.showModal = true;
    this.disableFromTable =true;
    this.disableLookUp = true;
    this.showSaveButton = true;
    this.clearAddConditionValues();

    }

    hide()
    {
      
      this.showModal = false;
    }

    openEditModal(tableInfo){
     this.clearFormArray();
      this.editMode = true;
      this.modelStepsService.editMode = this.editMode;
      this.selectedLookUp=tableInfo;
      this.showModal = true;
      this.disableFromTable =false;
      this.disableLookUp = false;
      this.selFromTable= tableInfo.joinTable;
      this.joinType=tableInfo.join.type=='inner'?'Inner Join':'Left Join';
      this.selLookup=tableInfo.table;
      this.modelStepsService.selLookup = this.selLookup;
      this.aliasName=tableInfo.alias;

      if(tableInfo.kind=='LOOKUP' || tableInfo.kind=='FACT')
      this.kind=tableInfo.kind=='LOOKUP'?false:true
      else
      this.kind=tableInfo.kind=tableInfo.kind;
      this.getSelectLookupTableColumns(this.selLookup);
      this.getfromTableColumns(this.selFromTable);
      this.getFromTableValues();
      let dropdown=[];let i=0;
      this.dropDownList=this.form.get('dropDowns') as FormArray;
      
      for(let param of tableInfo.join.primary_key){
        if(this.dropDownList.length<tableInfo.join.primary_key.length)
          this.showAddJoin();
        let pkey=tableInfo.join.primary_key[i].split('.',2);
        let fkey=tableInfo.join.foreign_key[i].split('.',3);
        let x={
          
          "selFromTableColumn":fkey[1],
          "selLookupColumn": pkey[1]
        };
       
        dropdown.push(x);
        
        i++;
      }
      
      
        this.form.patchValue({
          dropDowns:dropdown
        });
        //QUBZ-3965 - in Create Model Data Model tab Update option should be Enable - 28/11/22
        this.getselectLookupTableValues();
        this.getFromTableValues();
      }


  
      getFromTable(factTable){
      this.fromtableList=[];
  
        for (let [key, value] of Object.entries(this.tableServiceList)) {
          let res= <any>{};   
          res=value;
          let databaseName =  res.database + '.' + res.name;
          if(databaseName == factTable){
          this.fromtableList.push(databaseName);
          }
        }         
     
      }

      getFactName(factTable){
        this.modelStepsService.kind = "NORMAL";
        this.factAliasList=[];
  
          for (let [key, value] of Object.entries(this.tableServiceList)) {
            let res= <any>{};
            res=value;
            let databaseName =  res.database + '.' + res.name;
            
            if(databaseName == factTable){
              this.factAliasName = res.name;
              this.factAliasList.push(this.factAliasName);
            }
            
            } 
            this.modelStepsService.factAliasName = this.factAliasName;
            this.modelStepsService.factTable = this.factTable;
        
      }

      
      getDataBaseName(factTable){
        this.databaseList=[];
  
        for (let [key, value] of Object.entries(this.tableServiceList)) {
          let res= <any>{};
          res=value;
          let databaseName =  res.database + '.' + res.name;
          
          if(databaseName == factTable){
            this.databaseName = res.database;
            this.databaseList.push(this.databaseName);
          }
          this.modelStepsService.databaseName = this.databaseName;
          } 
      }  

      getuuid(factTable){
       this.uuidList=[];
       for (let [key, value] of Object.entries(this.tableServiceList)) {
        let res= <any>{};
        res=value;
        let databaseName =  res.database + '.' + res.name;
        if(databaseName == factTable){
          this.Tbluuid = res.uuid;
          this.uuidList.push(this.Tbluuid);
        }
       
        }
        this.modelStepsService.uuid = this.Tbluuid;
      }


      getSelectLookup(){
        this.selectLookupList=[];
        for (let [key, value] of Object.entries(this.tableServiceList)) {
          let res= <any>{};
          res=value;
          let databaseName =  res.database + '.' + res.name;
          this.selectLookupList.push(databaseName);
          } 
          this.modelStepsService.selectLookupList = this.selectLookupList;
      }

      getAliasName(){
        this.aliasList=[];
        if(this.selLookup === '-- Select Lookup Table --'){
           this.aliasName = 'Input Table Alias';
           this.selLookupColumn ='';
           
        }
        
        else{
          this.showAlaisError = false;
          if(this.showSelectLookUpColumn === false && this.showSelectLookUpColumn === false){
            //QUBZ-3940 - Lookup table should not save without Join condition - 24/11/22
            this.showSaveButton = true;
          }
          
         
          for (let [key, value] of Object.entries(this.tableServiceList)) {
            let res= <any>{};
            res=value;
            let databaseName =  res.database + '.' + res.name;
            
            if(databaseName == this.selLookup){
              this.aliasName = res.name;
              this.aliasList.push(this.aliasName);
            }
            
            } 
       
        }
        
      }

      showAddJoin(){
        this.showFromTableColumn = false;
        this.dropDownList.push(this.createDropDownList());
        this.selFromTableColumn = undefined;
        this.selLookupColumn = undefined;
        this.enableFromtable = false;
        this.showSelectLookUpColumn = false;
        this.enableSelectLookupTable = false;
        this.showDeleteColumn = false;
        this.modelStepsService.selFromTableColumn = undefined;
        // this.modelStepsService.dimensionLookup = undefined;
        this.saveCheck();
     
        
      }


      getfromTableColumns(selFromTable){
          this.modelStepsService.kind = "LOOKUP";
          this.fromTableColumns=[];
          
          for (let [key, value] of Object.entries(this.tableServiceList)) {
         
              let res= <any>{};
              res=value;
              let databaseName =  res.database + '.' + res.name;
              if(databaseName == selFromTable){
                for (let param of res.columns){
                  this.fromTableColumns.push({"id":param.id,"name":param.name,"datatype":param.datatype});
                 
                }
              }
              else if(this.modelStepsService.lookUps!==undefined && this.modelStepsService.lookUps.length>0){
              for (let [key, value] of Object.entries(this.modelStepsService.lookUps)) {
                let res1= <any>{};
                res1=value;
                let table = res1.table.split(".",3);
                if(res1.alias == selFromTable && databaseName == res1.table){
                  for (let param of res.columns){
                    this.fromTableColumns.push({"id":param.id,"name":param.name,"datatype":param.datatype});
                   
                }
                }
              }

            } 
            
         }
      }  
      getPkValue(){
        if(this.selectLookupColumns!==undefined || null && this.selLookupColumn.length>0){
         this.selectLookupColumns.forEach(val=>{
           if(this.selLookupColumn == val.name){
             this.pkType = val.datatype;
           }
          });
         this.modelStepsService.pkType = this.pkType;
        }
        
      }
 

      getFkValue(){
        this.fromTableColumns.forEach(val=>{
         if(this.selFromTableColumn == val.name){
           this.fkType = val.datatype;
         }
        });
       this.modelStepsService.fkType = this.fkType;
      }
  




     
  getFromTableValues(){
    this.form.value.dropDowns.forEach(val => {
     this.selFromTableColumn =val.selFromTableColumn;
    });
     
   }


    getSelectLookupTableColumns(selLookup){
      this.selectLookupColumns=[];
    
      for (let [key, value] of Object.entries(this.tableServiceList)) {
     
        let res= <any>{};
        
        res=value;
  
        let databaseName =  res.database + '.' + res.name;
        
        if(databaseName == selLookup){
          for (let param of res.columns)
          {
            this.selectLookupColumns.push({"id":res.id,"name":param.name,"datatype":param.datatype});
     
        }
      
        }
      }
    
    } 

    getselectLookupTableValues(){
      this.form.value.dropDowns.forEach(val => {
       this.selLookupColumn =val.selLookupColumn;
      });
       
     }

     deleteColumn(index: number){
      this.dropDownList.removeAt(index);
      if(this.dropDownList.length <=0){
        this.showSaveButton = true;
      }
    }


     get dropDownFormGroups() {

      return this.form.get('dropDowns') as FormArray;
  
    }

    createDropDownList(): FormGroup {
      return this.fb.group({
        selFromTableColumn:[],
        selLookupColumn:[],
        datatypeIncompatibleMsg:[]
      });
    }

    updateJointTable(){
      let kind = 'Normal';
      if(this.kind)
      kind='FACT';
      else
      kind='LOOKUP';  
      let innerName;
      let lookup_table=[];
      let from_table=[];

      if(this.joinType==='Inner Join'){
         innerName = 'inner';
        this.tableInfoList.joinType = innerName;
        this.modelStepsService.joinTypes = this.tableInfoList.joinType;
      } else if (this.joinType==='Left Join') {
         innerName = 'left';
        this.tableInfoList.joinType = innerName;
        this.modelStepsService.joinTypes = this.tableInfoList.joinType;
      }
      let from;
         if(this.modelStepsService.selFromTable!== undefined){
          from=this.selFromTable.split('.',3);
         }else{
          from=this.selFromTable;
         }
      
      let compatible=[];
     for(let joins of this.form.value.dropDowns){
      if(this.modelStepsService.selFromTable!== undefined){
        if(from.length>1){
          from_table.push(from[1] + '.' + joins.selFromTableColumn);
        }else{
          from_table.push(from[0] + '.' + joins.selFromTableColumn);
        }
      
      }else{
       from_table.push(from[1] + '.' + joins.selFromTableColumn);
      }
      lookup_table.push(this.aliasName+'.'+joins.selLookupColumn);
      if(this.pkType==this.fkType)
       compatible.push(true);
      else
      compatible.push(false);
     }
   
            
               let updatedLookup={"table": this.selLookup,
                 "alias": this.aliasName,
                 "kind": this.kind?'FACT':'LOOKUP',
                 "joinTable":this.selFromTable,
                 "join": {
                   "type": innerName,
                    "primary_key": lookup_table,
                    "foreign_key": from_table,
                 "isCompatible": compatible,
                 "pk_type": [this.pkType],
                 "fk_type": [this.fkType]} };

    this.modelStepsService.kindType = this.kind;                  
    let index=this.modelStepsService.lookUps.indexOf(this.selectedLookUp);
    if(index!=-1){
      this.modelStepsService.lookUps.splice(index,1,updatedLookup);
    }
    let aliasIndex =  this.modelStepsService.frmTableList.indexOf(this.selectedLookUp.alias);
      if(aliasIndex!=-1){
        this.modelStepsService.frmTableList.splice(aliasIndex,1,updatedLookup.alias);
      }
    
      this.showModal=false;
    }
    getTableInfoList(){

        let kind = 'Normal';
        
        let innerName;
        let lookup_table=[];
        let from_table=[];
  
        if(this.joinType==='Inner Join'){
           innerName = 'inner';
          this.tableInfoList.joinType = innerName;
          this.modelStepsService.joinTypes = this.tableInfoList.joinType;
        } else if (this.joinType==='Left Join') {
           innerName = 'left';
          this.tableInfoList.joinType = innerName;
          this.modelStepsService.joinTypes = this.tableInfoList.joinType;
        }
        let from;
         if(this.modelStepsService.selFromTable!== undefined){
          from=this.selFromTable.split(".",3);
         }else{
          from=this.selFromTable.split('.',3);
         }

        let compatible=[];
       for(let joins of this.form.value.dropDowns){
         if(this.modelStepsService.selFromTable!== undefined){
          if(from.length>1){
            from_table.push(from[1] + '.' + joins.selFromTableColumn);
          }else{
            from_table.push(from + '.' + joins.selFromTableColumn);
          }
         }else{
          from_table.push(from[1] + '.' + joins.selFromTableColumn);
         }
        
        lookup_table.push(this.aliasName+'.'+joins.selLookupColumn);
        this.modelStepsService.joinCondition.push(joins.selFromTableColumn);
        this.modelStepsService.lookupCondition.push(joins.selLookupColumn);

        if(this.pkType==this.fkType)
         compatible.push(true);
        else
        compatible.push(false);
       }
     
      if(this.kind)
       kind='FACT';
       else
       kind='LOOKUP';

                 this.lookup.push({"table": this.selLookup,
                   "alias": this.aliasName,
                   "kind": kind,
                   "joinTable":this.selFromTable,
                   "join": {
                     "type": innerName,
                      "primary_key": lookup_table,
                      "foreign_key": from_table,
                   "isCompatible": compatible,
                   "pk_type": [this.pkType],
                   "fk_type": [this.fkType]} });

        this.modelStepsService.kindType = this.kind;                   
        this.fromtableList=[...this.fromtableList,this.aliasName];   
        this.modelStepsService.frmTableList = this.fromtableList;
        this.modelStepsService.lookUps=this.lookup;
        this.modelStepsService.selFromTable =this.selFromTable ;
        this.modelStepsService.selLookup = this.selLookup;
        this.modelStepsService.aliasName = this.aliasName;

    }

    showJoinedTable(){
     this.checkSameType(this.aliasName);
    }

    clearAddConditionValues(){
      this.selFromTable = '-- From Table --';
      this.selLookup  = '-- Select Lookup Table --';
      this.aliasName = undefined;
      this.fromTableColumns='';
      this.selectLookupColumns = '';
      this.showFromTableColumn = true;
      this.showSelectLookUpColumn = true;
      this.enableFromtable = true;
      this.enableSelectLookupTable = true;
      this.showDeleteColumn = true;
      this.clearFormArray();
      this.showAlaisError = false;
      this.kind = false;
     }
   
     
  deleteJoinTable(item){
    let index=this.modelStepsService.lookUps.indexOf(item);
    let fromIndex=this.modelStepsService.frmTableList.indexOf(item.alias);
    if(this.modelStepsService.dimensionLookup!==undefined && this.modelStepsService.dimensionLookup.length>0){
      swal({
        type:'warning',
        title: ' ',
        html:'Once it'+'s removed, all relative dimensions will be removed.<br/>Are you sure you want to remove the lookup table?',
        showCancelButton:true,
        cancelButtonText: 'Cancel',
        confirmButtonText: 'OK',
        
        }).then((status) => {
        if(status.value==true){
          if(index!=-1){
            this.modelStepsService.lookUps.splice(index,1);
          }
    
          if(fromIndex!=-1){
          this.modelStepsService.frmTableList.splice(fromIndex,1);
          this.fromtableList = [...this.modelStepsService.frmTableList];
          }
        }
        }, (dismiss) => {
        if (dismiss === 'cancel') {
        }
      
    });
    return;
   
    }else{
      if(index!=-1){
        this.modelStepsService.lookUps.splice(index,1);
        this.modelStepsService.dimensionLookup =undefined;
      }

      if(fromIndex!=-1){
      this.modelStepsService.frmTableList.splice(fromIndex,1);
      this.fromtableList = [...this.modelStepsService.frmTableList];
      }
    
    }

    }

     clearFormArray(){
      const control = <FormArray>this.form.controls['dropDowns'];
      for(let i = control.length-1; i >= 0; i--) {
          control.removeAt(i);
       }
       
       
    }

    savekeysAlert(){
      //QUBZ-3945 - Add lookup table error message pop-up style issue - 16/11/22
      for(let param of this.form.value.dropDowns){
        if(!param.selFromTableColumn && !param.selLookupColumn){
        swal({
        type:'warning',
        title: 'Primary Key cant be null.<br/> foreign Key cant be null.',
        html:' ',
        confirmButtonText: 'OK',
        }).then((status) => {
        if(status.value==true){
        }
        }, (dismiss) => {
        if (dismiss === 'cancel') {
        }
      
    });
    return;
        }
        else if(!param.selFromTableColumn){
          swal('Warning','Foreign key cant be null.','warning');
          return;
        }
        else if(!param.selLookupColumn){
          swal('Warning','Primary key cant be null.','warning');
          return;
        }
      }

          this.showjoinTable = false;
          this.getTableInfoList();
          this.showModal=false;
         

    }

    updateJoinTable(){
      this.updateCheckSameType(this.aliasName);
    }
   

    
    updateKeysAlert(){
      for(let param of this.form.value.dropDowns){
        if(!param.selFromTableColumn && !param.selLookupColumn){
        swal({
        type:'warning',
        title: ' ',
        html:'Primary Key cant be null.<br/> foreign key cant be null.',
        confirmButtonText: 'OK',
        }).then((status) => {
        if(status.value==true){
        }
        }, (dismiss) => {
        if (dismiss === 'cancel') {
        }
      
    });
    return;
        }
        else if(!param.selFromTableColumn){
          swal('Warning','Foreign key cant be null.','warning');
          return;
        }
        else if(!param.selLookupColumn){
          swal('Warning','Primary key cant be null.','warning');
          return;
        }
      }

          this.showjoinTable = false;
          this.updateJointTable();
          this.showModal=false;
         

    }

    
    navigateToDimensions(id){
      this.modelStepsService.factTable= this.factTable;
      this.modelStepsService.tableInfoList = this.tableInfoList;
      this.modelStepsService.editMode = this.editMode;
      this.modelStepsService.selFromTable = this.selFromTable;
      this.modelStepsService.selLookup = this.selLookup;
      this.modelStepsService.aliasName = this.aliasName;
      this.modelStepsService.selFromTableColumn = this.selFromTableColumn;
      this.modelStepsService.selLookupColumn = this.selFromTableColumn;
      this.modelStepsService.frmTableList = this.fromtableList;
      this.router.navigate(['/projects/',id,'models','designer','dimensions']);
    
      
    }

    loadModelDesigner(id){
        this.modelStepsService.factTable= this.factTable;
        this.showjoinTable = true;
        this.modelStepsService.tableInfoList = this.tableInfoList;
        this.modelStepsService.editMode = this.editMode;
        this.modelStepsService.selFromTable = this.selFromTable;
        this.modelStepsService.selLookup = this.selLookup;
        this.modelStepsService.aliasName = this.aliasName;
        this.modelStepsService.selFromTableColumn = this.selFromTableColumn;
        this.modelStepsService.selLookupColumn = this.selFromTableColumn;
        this.modelStepsService.frmTableList = this.fromtableList;
      this.router.navigate(['/projects/',id,'models','designer','info']);
    }



    
  checkSameType(alias){
   let frm = this.selFromTable.split(".",3);
   let look = this.selLookup.split(".",3);
    if(frm[1] == alias){
      swal({
        type:'warning',
        title: ' ',
        text:'Table' + " " + 'Alias' + " " + '[' + alias + ']' + " " + 'already' + " " + 'exist!',
        confirmButtonText: 'OK',
        }).then((status) => {
          if(status.value==true){
        }
        }, (dismiss) => {
      if (dismiss === 'cancel') {
      }
      
        });
        return;
    }
    else if(frm[1]==look[1] && frm[1] == alias && look[1] == alias){
      swal({
        type:'warning',
        title: ' ',
        text:'Table' + " " + 'Alias' + " " + '[' + alias + ']' + " " + 'already' + " " + 'exist!',
        confirmButtonText: 'OK',
        }).then((status) => {
          if(status.value==true){
        }
        }, (dismiss) => {
      if (dismiss === 'cancel') {
      }
      
        });
        return;
     }
     
   else if(this.modelStepsService.frmTableList!==undefined && this.modelStepsService.frmTableList.length>0){
     for(let param of this.modelStepsService.frmTableList){
       let frm = param.split(".",3);
        if(frm[1] == alias || frm[0] == alias){
          swal({
            type:'warning',
            title: ' ',
            text:'Table' + " " + 'Alias' + " " + '[' + alias + ']' + " " + 'already' + " " + 'exist!',
            confirmButtonText: 'OK',
            }).then((status) => {
              if(status.value==true){
            }
            }, (dismiss) => {
          if (dismiss === 'cancel') {
          }
          
            });
            return;
         }
      
    
     }
   }
 
    this.savekeysAlert();
    
  }


checkValueType(selectLookupColumns,fromTableColumns,i){
  if(this.form.value.dropDowns[i].selFromTableColumn && this.form.value.dropDowns[i].selLookupColumn){
      let from=this.form.value.dropDowns[i].selFromTableColumn
      let lookup=this.form.value.dropDowns[i].selLookupColumn
 

  if(from && lookup){
  let pkey
  let fkey
  let fact = this.selFromTable.split(".",3);
  if(fact.length>1){
  let looks = this.selLookup.split(".",3);
  for(let look of selectLookupColumns){
    if(look.name==lookup)
    {
      pkey=look.datatype;
      break;
    }
  }
  for(let frm of fromTableColumns){
    if(frm.name==from)
    {
      fkey=frm.datatype;
      break;
    }
  }
  if(fkey!=pkey){
    //QUBZ-3948 - Add join Condition should not allow to save while Column Type incompatible - 17/11/22
    this.showSaveButton=true;
  return  '<i class="fa fa-exclamation-triangle" aria-hidden="true"> </i>  Column Type incompatible' +'\n'+  fact[1] + '.' + from + '[' + fkey +']' + ', &nbsp;'  + looks[1] + '.'  + lookup + '[' + pkey +']';
  }else{
    return '';
  }
}else{
  let looks = this.selLookup.split(".",3);
  for(let look of selectLookupColumns){
    if(look.name==lookup)
    {
      pkey=look.datatype;
      break;
    }
  }
  for(let frm of fromTableColumns){
    if(frm.name==from)
    {
      fkey=frm.datatype;
      break;
    }
  }
  if(fkey!=pkey){
    //QUBZ-3948 - Add join Condition should not allow to save while Column Type incompatible - 17/11/22
    this.showSaveButton=true;
  return  '<i class="fa fa-exclamation-triangle" aria-hidden="true"> </i>  Column Type incompatible' +'\n'+  fact[0] + '.' + from + '[' + fkey +']' + ', &nbsp;'  + looks[1] + '.'  + lookup + '[' + pkey +']';
  }else{
    return '';
  }
} 
}else return '';
} }
//QUBZ-3940 - Lookup table should not save without Join condition - 2/12/22
//QUBZ-3968 - In Edit Model Add join Condition should not allow to save while Column Type incompatible - 2/12/22
  valueChanged(){
    this.form.reset();
    this.getselectLookupTableValues();
    this.getFromTableValues();
    this.showSaveButton = true;
  }
  saveCheck(){
    console.log(this.form.value);
    if(this.selFromTable !=='-- From Table --' && this.selLookup !=='-- Select Lookup Table --' 
    && this.showFromTableColumn === false && this.showSelectLookUpColumn === false){
      if(!this.aliasName){
        this.showAlaisError = false;
        this.showSaveButton = true;
      }else{
        let found = this.aliasName.match(this.unamePattern);
        if(!found){
          this.showAlaisError = true;
          this.showSaveButton = true;
        }else{
          this.showAlaisError = false;
          this.showSaveButton = false;
        }
      }
    
    
  }else{
    if(!this.aliasName){
      this.showAlaisError = false;
      this.showSaveButton = true;
    }else{
     
      let found = this.aliasName.match(this.unamePattern);
      if(found!== null){
        if(!found){
          this.showAlaisError = false;
          this.showSaveButton = true;
        }
        else{
          this.showAlaisError = false;
          this.showSaveButton = true;
        }
      }
     
    }
  }

  
  }

  
  saveAlaisCheck(aliasName){
        if(this.selFromTable !=='-- From Table --' && this.selLookup !=='-- Select Lookup Table --' 
      && this.showFromTableColumn === false && this.showSelectLookUpColumn === false){
        if(!aliasName){
          this.showAlaisError = false;
          this.showSaveButton = true;
        }else{
          let found = aliasName.match(this.unamePattern);
          if(!found){
            this.showAlaisError = true;
            this.showSaveButton = true;
          }else{
            this.showAlaisError = false;
            this.showSaveButton = false;
          }
        }
      
      
    }else{
      if(!aliasName){
        this.showAlaisError = true;
        this.showSaveButton = true;
      }else{
       
        let found = aliasName.match(this.unamePattern);
        if(!found){
          this.showAlaisError = true;
          this.showSaveButton = true;
        }else{
          this.showAlaisError = false;
          this.showSaveButton = true;
        }
      }
    }

  
  }

  updateCheckSameType(alias){
    let frm = this.selFromTable.split(".",3);
    let look = this.selLookup.split(".",3);
    if(frm[1] == alias){
      swal({
        type:'warning',
        title: ' ',
        text:'Table' + " " + 'Alias' + " " + '[' + alias + ']' + " " + 'already' + " " + 'exist!',
        confirmButtonText: 'OK',
        }).then((status) => {
          if(status.value==true){
        }
        }, (dismiss) => {
      if (dismiss === 'cancel') {
      }
      
        });
        return;
    }

     else if(frm[1]==look[1] && frm[1] == alias && look[1] == alias){
       swal({
         type:'warning',
         title: ' ',
         text:'Table' + " " + 'Alias' + " " + '[' + alias + ']' + " " + 'already' + " " + 'exist!',
         confirmButtonText: 'OK',
         }).then((status) => {
           if(status.value==true){
         }
         }, (dismiss) => {
       if (dismiss === 'cancel') {
       }
       
         });
         return;
      }
    
 
    else if(this.modelStepsService.frmTableList!==undefined && this.modelStepsService.frmTableList.length>0){
      for(let param of this.modelStepsService.frmTableList){
        let frm = param.split(".",3);
        if(frm[1]==look[1] && frm[1] == alias && look[1] == alias){
           swal({
             type:'warning',
             title: ' ',
             text:'Table' + " " + 'Alias' + " " + '[' + alias + ']' + " " + 'already' + " " + 'exist!',
             confirmButtonText: 'OK',
             }).then((status) => {
               if(status.value==true){
             }
             }, (dismiss) => {
           if (dismiss === 'cancel') {
           }
           
             });
             return;
          }
       
     
      }
    }
  
     this.updateKeysAlert();
    
   }

    setFocus(){
      this.select.focus();
    }

}
