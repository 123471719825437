import { Component, OnInit,Input } from '@angular/core';
import * as d3 from "d3";
import { CubesService } from 'src/app/services/cubes/cubes.service';
import swal from 'sweetalert2';
import { ActivatedRoute, Router } from '@angular/router';
import { CubeDescriptionService } from '../../../../../services/cubes/cube-description.service';
import { ModelService } from 'src/app/services/model/model.service';
@Component({
  selector: 'app-planner',
  templateUrl: './planner.component.html',
  styleUrls: ['./planner.component.scss']
})
export class PlannerComponent implements OnInit {
  tablesNodeList = [];
  aliasList = [];
  margin = { top: 20, right: 100, bottom: 20, left: 100 };
  width;
  height;
  enableRecommend;
  currentOptions;
  currentData;
  recommendData;
  currentChart = {
    api: []
  };
  chartOptions;
  recommendOptions;
  cube;
  @Input() modelDesc;
  @Input() cubeDesc;
  recommendCaption = {
    enable: true,
    html: '<div>New: <i class="fa fa-square" style="color:#3a5;"></i> Hottest '
      + '<i class="fa fa-square" style="color:#7c7;"></i> Hot '
      + '<i class="fa fa-square" style="color:#aea;"></i> Warm '
      + '<i class="fa fa-square" style="color:#cfc;"></i> Cold '
      + '<i class="fa fa-square" style="color:#f94;"></i> Mandatory</div>',
    css: {
      position: 'relative',
      top: '-35px',
      height: 0,
      'text-align': 'left',
      'left': '-12px'
    }
  }
  currentCaption = {
    enable: true,
    html: '<div>Existed: <i class="fa fa-square" style="color:#38c;"></i> Hottest '
      + '<i class="fa fa-square" style="color:#7bd;"></i> Hot '
      + '<i class="fa fa-square" style="color:#ade;"></i> Warm '
      + '<i class="fa fa-square" style="color:#cef;"></i> Cold '
      + '<i class="fa fa-square" style="color:#999;"></i> Retire</div>',
    css: {
      position: 'relative',
      top: '-35px',
      height: 0
    }
  }
  baseChartOptions = {
    chart: {
      type: 'sunburstChart',
      height: 500,
      duration: 250,
      groupColorByParent: false,
      tooltipContent: function(obj) {
          var preCalculatedStr = '';
          if (typeof obj.data.existed !== 'undefined' && obj.data.existed !== null) {
            preCalculatedStr = '<tr><td align="right"><b>Existed:</b></td><td>' + obj.data.existed + '</td></tr>';
          }
          var rowCountRateStr = '';
          if (obj.data.row_count) {
            rowCountRateStr = '<tr><td align="right"><b>Row Count:</b></td><td>' + obj.data.row_count + '</td></tr><tr><td align="right"><b>Rollup Rate:</b></td><td>' + (obj.data.row_count * 100 / obj.data.parent_row_count).toFixed(2) + '%</td></tr>';
          }
          return '<table><tbody>'
          + '<tr><td align="right"><i class="fa fa-square" style="color: ' + obj.color + '; margin-right: 15px;" aria-hidden="true"></i><b>Name:</b></td><td class="key"><b>' + obj.data.name +'</b></td></tr>'
          + '<tr><td align="right"><b>ID:</b></td><td>' + obj.data.cuboid_id + '</td></tr>'
          + '<tr><td align="right"><b>Query Count:</b></td><td>' + obj.data.query_count + '  [' + (obj.data.query_rate * 100).toFixed(2) + '%]</td></tr>'
          + '<tr><td align="right"><b>Exactly Match Count:</b></td><td>' + obj.data.exactly_match_count + '</td></tr>'
          + rowCountRateStr
          + preCalculatedStr
          + '</tbody></table>';
        }
    },
    title: {
      enable: true,
      text: '',
      className: 'h4',
      css: {
        position: 'relative',
        top: '30px'
      }
    },
    subtitle: {
      enable: true,
      text: '',
      className: 'h5',
      css: {
        position: 'relative',
        top: '40px'
      }
    }
  }
  cubeName;
  model;
  constructor(private route: ActivatedRoute, private cubesService: CubesService, private cubeDescriptionService: CubeDescriptionService, private modelService: ModelService) {
    this.cubeName = this.route.snapshot.paramMap.get('cubeId');
   
  }

  ngOnInit() {
    this.width = 1100 - this.margin.right - this.margin.left;
    this.height = 600;
   
  if(this.model)
    {
      this.buildTree(this.modelDesc)
      this.getCubePlanner(this.cubeDesc);
 
    }  
  
 
  }
  buildTree(model) {
 
    var tree = d3.layout.tree().size([this.height, this.width - 160]);
    var diagonal = d3.svg.diagonal().projection(function (d) {
      return [d.y, d.x];
    });
    var svg = d3.select("#model_graph_" + model.name).append("svg:svg")
      .attr("width", this.width + this.margin.right + this.margin.left)
      .attr("height", this.height)
      .append("svg:g")
      .attr("transform", "translate(" + this.margin.left + "," + this.margin.top + ")");

    var graphData = {
      "type": "FACT",
      "name": model.fact_table,
      "children": []
    };
    this.tablesNodeList[graphData.name] = graphData;
    //   aliasList.push(graphData.name);
    model.graph = (!!model.graph) ? model.graph : {};
 
    if(model.lookups.length>0){
      model.lookups.forEach((lookup, index) => {
        if (!lookup.alias) {
          lookup.alias = lookup.table;
        }
        if (lookup.join && lookup.join.primary_key.length > 0) {
          var dimensionNode = {
              "type": lookup.kind,
            "name": lookup.alias,
            "join": lookup.join,
            "children": [],
            "_children": []
          }
          //      aliasList.push(dimensionNode.name);
          this.tablesNodeList[dimensionNode.name] = dimensionNode;
        }

      });
    }

    model.lookups.forEach((joinTable, index) => {
      if (joinTable.join && joinTable.join.primary_key.length > 0) {
        var fkAliasName = joinTable.join.foreign_key[0];
        var pkAliasName = joinTable.join.primary_key[0];
        if (!this.tablesNodeList[fkAliasName])
          this.tablesNodeList[fkAliasName] = { children: [] }
        this.tablesNodeList[fkAliasName].children.push(this.tablesNodeList[pkAliasName]);
      }
    });


    model.graph.columnsCount = 0;
    model.graph.tree = tree;
    model.graph.root = graphData;
    model.graph.svg = svg;
    model.graph.diagonal = diagonal;
    model.graph.i = 0;

    model.graph.root.x0 = this.height / 2;
    model.graph.root.y0 = 0;
    this.update(model.graph.root, model);
  }
  update(source, model) {
    var duration = 750;

    // Compute the new tree layout.
    var nodes = model.graph.tree.nodes(model.graph.root).reverse();

    // Update the nodes
    var node = model.graph.svg.selectAll("g.node")
      .data(nodes, function (d) {
        return d.id || (d.id = ++model.graph.i);
      });

    var nodeEnter = node.enter().append("svg:g")
      .attr("class", "node")
      .attr("transform", function (d) {
        return "translate(" + source.y0 + "," + source.x0 + ")";
      });

    // Enter any new nodes at the parent's previous position.
    nodeEnter.append("svg:circle")
      .attr("r", 4.5)
      .style("fill", function (d) {
        switch (d.type) {
          case 'fact':
            return '#fff';
          case 'dimension':
            return '#B0C4DE';
          case 'column':
            return 'black'
          default:
            return '#B0C4DE';
        }
      })
      .on("click", function (d) {
        if (d.children) {
          d._children = d.children;
          d.children = null;

          if (d.type == 'dimension') {
            model.graph.columnsCount -= d._children.length;
          }
        } else {
          d.children = d._children;
          d._children = null;

          if (d.type == 'dimension') {
            model.graph.columnsCount += d.children.length;
          }
        }

        var perColumn = 35;
        var newHeight = (((model.graph.columnsCount * perColumn > this.height) ? model.graph.columnsCount * perColumn : this.height));
        // $("#model_graph_" + model.name + " svg").height(newHeight);
        model.graph.tree.size([newHeight, this.width - 160]);
        this.update(d, model);
      });

    nodeEnter.append("svg:text")
      .attr("x", function (d) {
        return -90;
      })
      .attr("y", 3)
      .style("font-size", "14px")
      .text(function (d) {
        if (d.type == "dimension") {
          var joinTip = "";

          d.join.primary_key.forEach((pk, index) => {
            joinTip += (model.graph.root.name + "." + d.join.foreign_key[index] + " = " + d.name + "." + pk + "<br>");
          });

          d.tooltip = d3.select("body")
            .append("div")
            .style("position", "absolute")
            .style("z-index", "10")
            .style("font-size", "11px")
            .style("visibility", "hidden")
            .html(joinTip);
          var joinType = (d.join) ? (d.join.type) : '';

          return joinType + " join";
        }
        else {
          return "";
        }
      })
      .on('mouseover', function (d) {
        return d.tooltip.style("visibility", "visible");
      })
      .on("mousemove", function (d) {
        // return d.tooltip.style("top", (event.pageY + 30) + "px").style("left", (event.pageX - 50) + "px");
      })
      .on('mouseout', function (d) {
        return d.tooltip.style("visibility", "hidden");
      });

    nodeEnter.append("svg:text")
      .attr("x", function (d) {
        return 8;
      })
      .attr("y", 3)
      .text(function (d) {
        var dataType = (d.dataType) ? ('(' + d.dataType + ')') : '';

        return d.name + dataType;
      });

    // Transition nodes to their new position.
    nodeEnter.transition()
      .duration(duration)
      .attr("transform", function (d) {
        return "translate(" + d.y + "," + d.x + ")";
      })
      .style("opacity", 1)
      .select("circle")
      .style("fill", function (d) {
        switch (d.type) {
          case 'fact':
            return '#fff';
          case 'dimension':
            return '#B0C4DE';
          case 'column':
            return 'black'
          default:
            return '#B0C4DE';
        }
      });

    node.transition()
      .duration(duration)
      .attr("transform", function (d) {
        return "translate(" + d.y + "," + d.x + ")";
      })
      .style("opacity", 1);

    node.exit().transition()
      .duration(duration)
      .attr("transform", function (d) {
        return "translate(" + source.y + "," + source.x + ")";
      })
      .style("opacity", 1e-6)
      .remove();

    // Update the links…
    var link = model.graph.svg.selectAll("path.link")
      .data(model.graph.tree.links(nodes), function (d) {
        return d.target.id;
      });

    // Enter any new links at the parent's previous position.
    link.enter().insert("svg:path", "g")
      .attr("class", "link")
      .attr("d", function (d) {
        var o = { x: source.x0, y: source.y0 };
        return model.graph.diagonal({ source: o, target: o });
      })
      .transition()
      .duration(duration)
      .attr("d", model.graph.diagonal);

    // Transition links to their new position.
    link.transition()
      .duration(duration)
      .attr("d", model.graph.diagonal);

    // Transition exiting nodes to the parent's new position.
    link.exit().transition()
      .duration(duration)
      .attr("d", function (d) {
        var o = { x: source.x, y: source.y };
        return model.graph.diagonal({ source: o, target: o });
      })
      .remove();

    // Stash the old positions for transition.
    nodes.forEach(function (d) {
      d.x0 = d.x;
      d.y0 = d.y;
    });
  }
  getCubePlanner(cube) {
    this.enableRecommend = 'READY';
 
    if (!cube.currentCuboids) {
      this.cubesService.getCurrentCuboids(cube.name).subscribe(
        data => {
          let res = <any>{};
          res = data;
          res = this.transformCuboidsResponse(data)
 
          if (data && res.nodeInfos) {
            this.createChart(res, 'current');
            cube.currentCuboids = res;
          } else {
            this.currentOptions = this.chartOptions;
            this.currentData = [];
          }
        }, function (e) {
           
           
        });
    } else {
      this.createChart(cube.currentCuboids, 'current');
    }
  };
  transformCuboidsResponse(data) {
    var cuboids = {
      nodeInfos: [],
      treeNode: data.root,
      totalRowCount: 0
    };

    cuboids = this.iterator(data.root, data.root.row_count, cuboids);
    return cuboids;
  };
  iterator(node, parentRowCount, cuboids) {
    node.parent_row_count = parentRowCount;
    cuboids.nodeInfos.push(node);
    cuboids.totalRowCount += node.row_count;
    if (node.children.length) {
      node.children.forEach((child) => {
        this.iterator(child, node.row_count, cuboids);
      });
    }
    return cuboids;
  };
  getRecommendCuboids(cube) {
    if (!cube.recommendCuboids) {
      
      this.cubesService.getRecommendCuboids(cube.name).subscribe(
        data => {
          let res = <any>{};
          res = data;
          res = this.transformCuboidsResponse(data)
          if (data && res.nodeInfos) {

            // recommending
            if (res.nodeInfos.length === 1 && !res.nodeInfos[0].cuboid_id) {
              swal('Loading', 'Please wait a minute, servers are recommending for you', 'success');
            } else {
              this.createChart(res, 'recommend');
              cube.recommendCuboids = res;
              // update current chart mark delete node gray.
              cube.currentCuboids.nodeInfos.forEach((nodeInfo) => {
                
                var tempNode = res.nodeInfos.find(({ cuboid_id }) => nodeInfo.cuboid_id === cuboid_id);
                //_.find(res.nodeInfos, function(o) { return o.cuboid_id == nodeInfo.cuboid_id; });
                if (!tempNode) {
                  nodeInfo.deleted = true;
                }
              });
              this.createChart(cube.currentCuboids, 'current');
              // this.currentChart.api.refresh();
            }
          } else {
            this.currentOptions = this.chartOptions;
            this.recommendData = [];
          }
        }, function (e) {
          swal('Oops...', 'Failed to get recommend cuboid.', 'error');
         
        });
    } else {
      this.createChart(cube.recommendCuboids, 'recommend');
    }
  };
  optimizeCuboids(cube) {
    swal({
      type: 'warning',
      text: 'Are you sure you want to optimize the cube?',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No'
    }).then((status) => {
      if (status.value == true) {
        var cuboidsRecommendArr = [];
        cube.recommendCuboids.nodeInfos.forEach((node) => {
          cuboidsRecommendArr.push(node.cuboid_id);
        });
        this.cubesService.optimize(cube.name, cuboidsRecommendArr).subscribe(
          data => {
            swal('Success', "Optimize cube job has been started", 'success');
          }, function (e) {
            if (e.status === 400) {
              swal('Oops...', e.data.exception, 'error');
            } else {
              swal('Oops...', "Failed to create optimize cube job.", 'error');
             
            }
          });
      }
    });
  };
  createChart(data, type) {
    var chartData = data.treeNode;
   
    if ('current' === type) {
      
      this.currentData = [chartData];
      this.currentOptions = this.baseChartOptions;
      // this.currentOptions.caption = this.currentCaption;
      // if (this.cube.recommendCuboids){
      //   this.currentOptions.caption.css['text-align'] = 'right';
      //   this.currentOptions.caption.css['right'] = '-12px';
      // }
      // this.currentOptions.chart.color = function(d) {
      //     var cuboid =data.nodeInfos.find(({name}) =>d===name);
      //     if (cuboid.deleted) {
      //         return d3.scale.category20c().range()[17];
      //     } else {
      //         return this.getColorByQuery(0, 1/data.nodeInfos.length, cuboid.query_rate);
      //     }
      // };
      // this.currentOptions.chart.sunburst = this.getSunburstDispatch();
      // this.currentOptions.title.text = 'Current Cuboid Distribution';
      // this.currentOptions.subtitle.text = '[Cuboid Count: ' + data.nodeInfos.length + '] [Row Count: ' + data.totalRowCount + ']';
    } else if ('recommend' === type) {
     
      this.recommendData = [chartData];
      this.recommendOptions = this.baseChartOptions;
      this.recommendOptions.caption = this.recommendCaption;
      this.recommendOptions.chart.color = function (d) {
        var cuboid = data.nodeInfos.find(({ name }) => d === name);
        if (cuboid.row_count < 0) {
          return d3.scale.category20c().range()[5];
        } else {
          var colorIndex = 0;
          if (!cuboid.existed) {
            colorIndex = 8;
          }
          return this.getColorByQuery(colorIndex, 1 / data.nodeInfos.length, cuboid.query_rate);
        }
      };
      this.recommendOptions.chart.sunburst = this.getSunburstDispatch();
      this.recommendOptions.title.text = 'Recommend Cuboid Distribution';
      this.recommendOptions.subtitle.text = '[Cuboid Count: ' + data.nodeInfos.length + '] [Row Count: ' + data.totalRowCount + ']';
    }
    
  };
  getColorByQuery(colorIndex, baseRate, queryRate) {
    if (queryRate > (3 * baseRate)) {
      return d3.scale.category20c().range()[colorIndex];
    } else if (queryRate > (2 * baseRate)) {
      return d3.scale.category20c().range()[colorIndex + 1];
    } else if (queryRate > baseRate) {
      return d3.scale.category20c().range()[colorIndex + 2];
    } else {
      return d3.scale.category20c().range()[colorIndex + 3];
    }
  }
  getSunburstDispatch() {
    return {
      dispatch: {
        elementMouseover: function (t, u) {
          this.selectCuboid = t.data.name;

        },
        renderEnd: function (t, u) {
          var chartElements = document.getElementsByClassName('nv-sunburst');
          // angular.element(chartElements).on('mouseleave', function() {
          //     this.selectCuboid = '0';

          // });
        }
      }
    };
  };
}
