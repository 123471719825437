import { Component, OnInit, Input } from '@angular/core';
import { StreamingService } from 'src/app/services/streaming/streaming.service';
import { FormGroup, FormArray, FormBuilder, Validators } from '@angular/forms';
import swal from 'sweetalert2';
@Component({
  selector: 'app-streaming-cluster',
  templateUrl: './streaming-cluster.component.html',
  styleUrls: ['./streaming-cluster.component.scss']
})
export class StreamingClusterComponent implements OnInit {

  @Input() projectId;
  @Input() selectedTable;
  @Input() kfkConfig;
  expand = {
    kafkaSettings: true,
    parserSettings: true,
    advancedSettings: true
  };
  showEditModal;
  config:any;
  kafkaForm: FormGroup;
  public serverList: FormArray;
  constructor(private streamingService: StreamingService,private fb: FormBuilder) {
    this.kafkaForm = fb.group({
      topic: ['', [Validators.required]],
      servers: this.fb.array([], [Validators.required]) ,
    });
    this.serverList = this.kafkaForm.get('servers') as FormArray;
   }

  ngOnInit() {

  }
  get server() {

    return this.kafkaForm.get('servers') as FormArray;

  }
  newServer(): FormGroup {
    return this.fb.group({
      host: ['', [Validators.required]],
      port: ['', [Validators.required, Validators.pattern('^[0-9]*$')]],
    });
  }
  addServer() {
    this.serverList.push(this.newServer());
  }

  removeServer(i: number) {
    this.serverList.removeAt(i);
  }
  isExpanded(section: string) {
    if (section === 'kafkaSettings') {
     this.expand.kafkaSettings = !this.expand.kafkaSettings;
    } else if ( section === 'parserSettings' ) {
     this.expand.parserSettings = !this.expand.parserSettings;
    } else if ( section === 'advancedSettings' ) {
     this.expand.advancedSettings = !this.expand.advancedSettings;
  }
 }
 editCluster(){
  while (this.serverList.length) {
    this.serverList.removeAt(0);
 }
if(this.kfkConfig){
  let servers=this.kfkConfig.properties['bootstrap.servers'].split(',');
  let server=[];
  for(let serv of servers){
    let host=serv.split(':',2);
    this.addServer();
    server.push({'host':host[0],'port':host[1]});
    
  }
  this.kafkaForm.patchValue({
    topic:this.kfkConfig.properties.topic,
    servers:server
  });

}

   this.showEditModal=true;
 }
 hide(){
   this.showEditModal=false;
 }
 updateConfig(){
   let host='';
   for(let server of this.kafkaForm.value.servers){
     if(host)
     host+=',';
     host+=server.host+':'+server.port;
   }
  let properties={
    "bootstrap.servers":host,
    "topic":this.kafkaForm.value.topic
  }
  this.kfkConfig.properties=properties;
  let body={
    project:this.projectId,
    streamingConfig:JSON.stringify(this.kfkConfig)
  }
  
  this.streamingService.updateConfig(body).subscribe( 
    result => {
     swal("Success","Streaming details successfully updated","success");
     this.showEditModal=false;
    },
    error => {
      swal("Ooops",error.error.msg,"error");
    }); 
 }
}
