import { Component, OnInit, AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Output, EventEmitter, Input } from '@angular/core';
import { CubeConfig } from '../../../../../models/cubes/cube-config';
import { CubesService } from '../../../../../services/cubes/cubes.service';
import { TableService } from '../../../../../services/table/table.service';
import { NgForm } from '@angular/forms';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ConstantPool } from '@angular/compiler';
import swal from 'sweetalert2';
import { ModelService } from '../../../../../services/model/model.service';
import { Router, ActivatedRoute } from '@angular/router';
import * as $ from 'jquery';
import { VisualCubeStep5Component } from '../../../../../components/projects/cubes/visual-cube/visual-cube-step5/visual-cube-step5.component';
import { Subscription }   from 'rxjs';
@Component({
  selector: 'app-visual-cube-step3',
  templateUrl: './visual-cube-step3.component.html',
  styleUrls: ['./visual-cube-step3.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers:[VisualCubeStep5Component] 
})
export class VisualCubeStep3Component implements OnInit, AfterViewInit {
  public cubeConfig: CubeConfig;
  measure: any;
 // defaultMeasure: any;
  measureExpressions: any;
  measureParamType: any;
  modelObj: any;
  metrics: any;
  allMetrics: any = [];
  returnType: any = [];
  dimensionAddStatus: boolean = false;
  expression: any;
  dimensions: any = [];
  dimensionDynamic: any = [];
  encoding: any = [];
  factTable: any = [];
  factTableName: String;
  form: FormGroup;
  html: String;
  ShowMeasureArea: boolean = false;
  topNOrder: String = '';
  topNGroup: String = '';
  showMeasure: boolean;
  pattern = "^[a-z0-9A-Z_]{3,100}$";
  editStatus = false;
  projectId;
  editCube;
  modelMessure;
  disableForm;
  tables;
  cubeStatus;
  allTables = [];
  // fb: FormBuilder;
  public columnList: FormArray;
  @Output() measureEvent = new EventEmitter<string>();
  @Input() defaultMeasure:any;
  subscription:Subscription;


  constructor(public cubesService: CubesService, private cdr: ChangeDetectorRef, private modelService: ModelService, private fb: FormBuilder, private route: ActivatedRoute, private router: Router, private tableService: TableService,private visStep5:VisualCubeStep5Component) {
    this.form = fb.group({
      expname: ['', [Validators.required, Validators.pattern(this.pattern)]],
      expression: ['', Validators.required],
      paramType: ['', Validators.required],
      paramValue: ['', Validators.required],
      addDimension: [],
      hostFactTable: [],
      extendedFactTable: [],
      lengthColumn: [],
      returnType: ['', Validators.required],
      columnName: [],
      index: [],
      paramValueConstant: [1],
      columns: fb.array([])
    })
    this.projectId = this.route.snapshot.paramMap.get("projectId");
    this.editCube = this.route.snapshot.paramMap.get("cubeId");
    this.cubeStatus = this.cubesService.cubeStatus;
    // if(this.cubesService.cubeMeta.dimensions.length==0)
    // {
    //   this.router.navigate(['/projects/'+this.projectId+'/cubes/designer/info']);
    // }
    this.columnList = this.form.get('columns') as FormArray;
    this.cubeConfig = new CubeConfig();
    let model = this.cubesService.model;
    if(model!==undefined){
      this.modelObj = this.cubesService.model;
      this.measureExpressions = this.cubeConfig.measureExpressions;
      this.measureParamType = this.cubeConfig.measureParamType;
      this.metrics = this.modelObj.metrics;
      this.encoding = this.cubeConfig.encoding;
      this.factTableName = model.fact_table.split('.');
      this.modelMessure = this.modelObj.metrics;
      this.cubeConfig = new CubeConfig();
    }


  }
  getTableDetails() {
    this.tableService.getTables(this.projectId).subscribe(
      data => {
        let res = <any>{};
        res = data;
        this.tables = res;

      });
  }
  ngAfterViewInit() {

    $(document).ready(function () {
      var contenth = $(window).height() - 110;
      var sidebarh = $(window).height() - 111;
      $(".pagec").css("height", contenth);
      $(".sidebar-wrapper").css("height", sidebarh);
    });


    setTimeout(function () {
      /* custome select option starts*/
      var x, i, j, selElmnt, a, b, c;
      x = document.getElementsByClassName("select-custom");
      for (i = 0; i < x.length; i++) {
        selElmnt = x[i].getElementsByTagName("select")[0];
        a = document.createElement("DIV");
        a.setAttribute("class", "select-selected");
        a.innerHTML = selElmnt.options[selElmnt.selectedIndex].innerHTML;
        x[i].appendChild(a);
        b = document.createElement("DIV");
        b.setAttribute("class", "select-items select-hide");
        for (j = 1; j < selElmnt.length; j++) {
          c = document.createElement("DIV");
          c.innerHTML = selElmnt.options[j].innerHTML;
          c.addEventListener("click", function (e) {
            var y, i, k, s, h;
            s = this.parentNode.parentNode.getElementsByTagName("select")[0];
            h = this.parentNode.previousSibling;
            for (i = 0; i < s.length; i++) {
              if (s.options[i].innerHTML == this.innerHTML) {
                s.selectedIndex = i;
                h.innerHTML = this.innerHTML;
                y = this.parentNode.getElementsByClassName("same-as-selected");
                for (k = 0; k < y.length; k++) {
                  y[k].removeAttribute("class");
                }
                this.setAttribute("class", "same-as-selected");
                break;
              }
            }
            h.click();
          });
          b.appendChild(c);
        }
        x[i].appendChild(b);
        a.addEventListener("click", function (e) {
          e.stopPropagation();
          closeAllSelect(this);
          this.nextSibling.classList.toggle("select-hide");
          this.classList.toggle("select-arrow-active");
        });
      }

      function closeAllSelect(elmnt) {
        var x, y, i, arrNo = [];
        x = document.getElementsByClassName("select-items");
        y = document.getElementsByClassName("select-selected");
        for (i = 0; i < y.length; i++) {
          if (elmnt == y[i]) {
            arrNo.push(i)
          } else {
            y[i].classList.remove("select-arrow-active");
          }
        }
        for (i = 0; i < x.length; i++) {
          if (arrNo.indexOf(i)) {
            x[i].classList.add("select-hide");
          }
        }
      }
      document.addEventListener("click", closeAllSelect);
      /* custom select option end */
    }, 3000);
  }
  getAllTables() {
    this.allTables.push(this.cubesService.model.fact_table);
    for (let tab of this.cubesService.model.lookups) {
      this.allTables.push(tab.table.split('.', 2)[0] + '.' + tab.alias);
    }
  }
  checkTable(tableName) {
    for (let tab of this.allTables) {
      if (tab.split('.', 2)[0] == tableName)
        return true;
    }
    return false;
  }
  ngOnInit() {
    this.getAllTables();
    this.getTableDetails();
    if (this.cubesService.cubeMeta.measures.length) {
      this.defaultMeasure = this.cubesService.cubeMeta.measures;
    }
    else {
      this.defaultMeasure = [{
        "name": "_COUNT_",
        "function": {
          "expression": "COUNT",
          "returntype": "bigint",
          "parameter": {
            "type": "constant",
            "value": "1"
          },
          "configuration": {}
        }
      }];
    }

    // this.measure.push(defaultMeasure);
    let cubeObj = this.cubesService.getCubeObj();

    for (let [key, value] of Object.entries(cubeObj)) {
      let res = <any>{};
      res = value;
      this.dimensions.push({ table: res.table, column: res.name, displayName: res.table + '.' + res.name });
      if (this.factTableName[1] == res.table)
        this.factTable.push({ table: res.table, column: res.name });
    }
    this.allmatrics();
    this.cubesService.cubeMeta.measures = this.defaultMeasure;
    this.cubesService.measure = this.defaultMeasure;
    this.measureEvent.emit(this.defaultMeasure);
    this.visStep5.step5data();
    //QUBZ-3900-In Redshift Data source Visual Cube creation Duplicate dimensions having in Measures-11/10/22
    // this.subscription = this.cubesService.dimensionUpdated.subscribe(
    //   dimensionUpdated =>this.open());

  }

  open(){
    let cubeObj = this.cubesService.getCubeObj();

    for (let [key, value] of Object.entries(cubeObj)) {
      let res = <any>{};
      res = value;
      this.dimensions.push({ table: res.table, column: res.name, displayName: res.table + '.' + res.name });
      if (this.factTableName[1] == res.table)
        this.factTable.push({ table: res.table, column: res.name });
    }
    this.allmatrics();
  }
  get columnFormGroup() {
    return this.form.get('columns') as FormArray;
  }
  createColumn(): FormGroup {
    return this.fb.group({
      expName: [],
      encode: [],
      length: ['', { disabled: true }],
    });
  }
  allmatrics() {
    let model = this.cubesService.model;
    let dimensions = model.dimensions;
    let cubeObj = [];
    for (let [key, value = <any>{}] of Object.entries(dimensions)) {
      for (let param of value.columns) {
        cubeObj.push({
          "table": value.table,
          "name": param.table ? param.table : param.name
        })
      }
    }
    this.allMetrics = this.modelObj.metrics;
    for (let [key, value] of Object.entries(cubeObj)) {
      let res = <any>{};
      res = value;
      this.allMetrics.push(res.table + '.' + res.name);
    }
  }
  includeDimension() {
    let model = this.cubesService.model;
    let dimensions = model.dimensions;
    let cubeObj = [];
    for (let [key, value = <any>{}] of Object.entries(dimensions)) {
      for (let param of value.columns) {
        cubeObj.push({
          "table": value.table,
          "name": param.table ? param.table : param.name
        })
      }
    }
    let factTable = model.fact_table.split('.');
    if (!this.form.value.addDimension) {
      for (let [key, value] of Object.entries(cubeObj)) {
        let res = <any>{};
        res = value;
        this.metrics.push(res.table + '.' + res.name);
      }
    }
    else {
      for (let [key, value] of Object.entries(cubeObj)) {
        let res = <any>{};
        res = value;
        var index = this.metrics.indexOf(res);
        this.metrics.splice(index, 1);
      }
    }
  }
  getReturnType() {
    let tables;
    this.form.patchValue({
      returnType: '',
    });
    if (this.form.value.paramType == 'constant') {
      this.returnType = [{
        name: 'BIGINT', value: 'bigint'
      }]
      this.form.patchValue({
        paramValue: '',
        returnType: 'bigint',
        paramValueConstant: 1,
      });
    }
    if(this.form.value.paramValue && (this.form.value.expression=='COUNT' || this.form.value.expression=='MAX' || this.form.value.expression=='SUM' || this.form.value.expression=='MIN')){
      this.tableService.getTables(this.projectId).subscribe(
        data => {
          let res = <any>{};
          res = data;
          tables = res;
          let paramValue = this.form.value.paramValue.split('.', 2);
          for (let tab of tables) {
            if (tab.name == paramValue[0] && this.checkTable(tab.database)) {
              for (let cols of tab.columns) {
                if (cols.name == paramValue[1]) {
                  this.form.patchValue({
                    returnType: cols.datatype,
                    paramValueConstant: 1,
                  });
                  return cols.datatype;
                }
              }
            }
          }
        });
    }
  }
  checkError(i) {
    if (this.form.value.columns[i].encode == 'integer') {
      if (this.form.value.columns[i].length < 1 || this.form.value.columns[i].length > 8) {
        this.disableForm = true;
        return "integer encoding column length <br>should between 1 and 8";
      }
      else {
        this.validateForm();
      }
    }
    else {
      this.validateForm();
    }
  }
  getValue(i) {
    if (this.form.value.columns[i].length == '' || this.form.value.columns[i].length == null) {
      return 0;
    }
    else
      return this.form.value.columns[i].length;
  }
  validateForm() {
    this.disableForm = false;
    if (this.form.value.expression === 'COUNT') {
      if (this.form.value.paramType === 'constant') {
        this.form.patchValue({
          returnType: 'bigint',
        });
        this.form.patchValue({
          returnType: 'bigint',
          paramValueConstant: 1,
        });

        this.disableForm = false;
      }
      else if (!this.form.value.expression || !this.form.value.paramValue) {
        //  if (!this.form.value.paramValueConstant || !this.form.value.returnType) {
        this.disableForm = true;
        return;
      }

    }
    if (this.form.value.expression === 'SUM' || this.form.value.expression === 'MIN' || this.form.value.expression === 'MAX') {
      if (!this.form.value.expression || !this.form.value.paramValue) {
        this.disableForm = true;
        return;
      }
      if (this.form.value.paramType == 'constant' && this.form.value.paramValueConstant && this.form.value.returnType) {
        this.disableForm = false;
      }
    }
    if (this.form.value.expression === 'COUNT_DISTINCT' || this.form.value.expression === 'TOP_N') {
      if (!this.form.value.expression || !this.form.value.paramValue || !this.form.value.returnType) {
        this.disableForm = true;
      }
      if (this.form.value.columns.length > 0) {
        for (let param in this.form.value.columns) {
          if (this.form.value.columns[param].expName == '' || this.form.value.columns[param].expName == null) {
            this.disableForm = true;
            return;
          }
          for (let params in this.form.value.columns) {
            if (this.form.value.columns[params].expName == this.form.value.columns[param].expName && param != params) {
              this.disableForm = true;
              return;
            }
          }
          if (this.form.value.columns[param].encode == 'integer' && (this.form.value.columns[param].length < 1 || this.form.value.columns[param].length > 8)) {
            this.disableForm = true;
            return;
          }
        }
      }
    }
    if (this.form.value.expression == 'COUNT_DISTINCT') {
      this.form.patchValue({
        paramType: 'column',
      });
    }
    if (this.form.value.expression === 'TOP_N' && this.form.value.paramType == 'constant') {
      if (this.form.value.expression || this.form.value.returnType) {
        this.disableForm = false;
      }
    }
  }
  checkDuplicate(i) {
    if (this.form.value.expression == 'COUNT_DISTINCT') {
      let exp = this.form.value.columns[i].expName;
      if (this.form.value.columns.length > 0) {
        for (let param in this.form.value.columns) {
          if (this.form.value.columns[param].expName == exp && i != param) {
            swal("Warning!", "The column named " + this.form.value.columns[param].expName + " already exits.", "warning");
            this.disableForm = true;
            return;
          }
          else {
            this.validateForm();
          }
        }
      }
    }
  }
  setReturnType() {
    if (this.form.value.expression === 'COUNT_DISTINCT') {
      this.returnType = this.cubeConfig.returnType.distinctDataTypes;
      this.form.patchValue({
        returnType: 'hllc(10)',
      });
    }
    // if (this.form.value.expression === 'COUNT') {
    //   this.form.get('paramType').setValidators([]);
    //   this.form.get('paramType').updateValueAndValidity();
    //   this.form.get('returnType').setValidators([]);
    //   this.form.get('returnType').updateValueAndValidity();
    //   this.form.get('paramValue').setValidators([]);
    //   this.form.get('paramValue').updateValueAndValidity();
    // }
    if (this.form.value.expression === 'TOP_N') {
      this.returnType = this.cubeConfig.returnType.topNTypes;
      if (this.form.value.paramType != 'constant') {
        this.form.patchValue({
          returnType: 'topn(100)',
        });
      } else {
        this.form.patchValue({
          returnType: '1',
        });
      }
      this.form.get('paramValue').setValidators([]);
      this.form.get('paramValue').updateValueAndValidity();
      this.form.get('returnType').setValidators([]);
      this.form.get('returnType').updateValueAndValidity();
    }
    if (this.form.value.expression === 'PERCENTILE') {
      this.form.get('paramValue').setValidators(Validators.required);
      this.form.get('paramValue').updateValueAndValidity();
      this.form.get('returnType').setValidators([]);
      this.form.get('returnType').updateValueAndValidity();
    } else {
      this.form.get('paramValue').setValidators([]);
      this.form.get('paramValue').updateValueAndValidity();
    }
    if(this.form.value.expression==='COUNT' || this.form.value.expression=='MAX' || this.form.value.expression=='SUM' || this.form.value.expression=='MIN'){
      this.returnType = [];
      this.form.get('returnType').setValidators(Validators.required);
      this.form.get('returnType').updateValueAndValidity();
      this.getReturnType();
    }
    else {
      this.form.get('returnType').setValidators([]);
      this.form.get('returnType').updateValueAndValidity();
    }
    if (this.form.value.expression == 'EXTENDED_COLUMN') {
      this.form.patchValue({
        lengthColumn: 100,
      });
      this.form.get('paramType').setValidators([]);
      this.form.get('paramType').updateValueAndValidity()
      this.form.get('returnType').setValidators([]);
      this.form.get('returnType').updateValueAndValidity();
      this.form.get('hostFactTable').setValidators(Validators.required);
      this.form.get('hostFactTable').updateValueAndValidity();
      this.form.get('extendedFactTable').setValidators(Validators.required);
      this.form.get('extendedFactTable').updateValueAndValidity();
    }
    else {
      this.form.get('paramType').setValidators(Validators.required);
      this.form.get('paramType').updateValueAndValidity()
      this.form.get('hostFactTable').setValidators([]);
      this.form.get('hostFactTable').updateValueAndValidity();
      this.form.get('extendedFactTable').setValidators([]);
      this.form.get('extendedFactTable').updateValueAndValidity();
    }
    // if (this.form.value.expression == 'COUNT') {
    //   this.returnType = [{
    //     name: 'BIGINT', value: 'bigint'
    //   }]
    //   this.form.patchValue({
    //     returnType: 'bigint',
    //     paramValueConstant: 1,
    //   });
    // }
    // if (this.form.value.expression == 'COUNT') {
    //   this.form.patchValue({
    //     paramType: 'constant',
    //     paramValueConstant: 1,
    //   });
    // }
    if (this.form.value.expression == 'PERCENTILE') {
      this.returnType = [{
        name: 'PERCENTILE(100)', value: 'PERCENTILE(100)'
      }]
      this.form.patchValue({
        paramType: 'column',
        returnType: 'PERCENTILE(100)',
      });
    }
  }
  addColumn() {
    this.columnList.push(this.createColumn());
  }
  removeColumn(index) {
    this.validateForm();
    this.columnList.removeAt(index);
  }
  findLastNull(arr, param, paramType) {
    if (arr.next_parameter == null) {
      arr.next_parameter = {
        "type": paramType,
        "value": param.expName,
        "next_parameter": null,
      };
    }
    else {
      this.findLastNull(arr.next_parameter, param, paramType);
    }
  }
  saveColumn() {
    if (this.form.value.expression == 'TOP_N' && this.form.value.columns.length < 1) {
      swal("Information", "TOP_N group by columns required");
      return;
      // this.showMeasure=false;
    }
    for (let checkMeasure in this.defaultMeasure) {
      if (this.defaultMeasure[checkMeasure].name == this.form.value.expname) {
        let index = this.form.value.index;
        if (this.defaultMeasure.indexOf(this.defaultMeasure[checkMeasure]) != index) {
          swal("failed!", "Name already exist!", "error");
          // this.showMeasure=false;
          return;
        }
      }
    }
    if (this.form.invalid) {
      return;
    }
    let arr = [];
    let config = {};
    let paramValue = '';
    if (this.form.value.paramType == 'constant') {
      paramValue = this.form.value.paramValueConstant ? this.form.value.paramValueConstant : 1;

    }
    else
      paramValue = this.form.value.paramValue;
    for (let param in this.form.value.columns) {
      let x = "topn.encoding." + this.form.value.columns[param].expName;
      let y = this.form.value.columns[param].encode + ':' + this.form.value.columns[param].length;
      config["topn.encoding." + this.form.value.columns[param].expName] = this.form.value.columns[param].encode + ':' + this.form.value.columns[param].length;
      config["topn.encoding_version." + this.form.value.columns[param].expName] = 1;
    }
    if (this.form.value.expression == 'COUNT_DISTINCT' || this.form.value.expression == 'TOP_N') {
      arr.push({
        "name": this.form.value.expname,
        "function": {
          "returntype": this.form.value.returnType,
          "expression": this.form.value.expression,
          "parameter": {
            "type": this.form.value.paramType,
            "value": paramValue,
            "next_parameter": null,
          },
          "configuration": config,
        },
        "showDim": false
      });
      for (let param in this.form.value.columns) {
        this.findLastNull(arr[0].function.parameter, this.form.value.columns[param], this.form.value.paramType);
      }
      if (this.editStatus) {
        let index = this.form.value.index;
        this.defaultMeasure[index] = arr[0];
      }
      else
        this.defaultMeasure.push(arr[0]);

    }
    else if (this.form.value.expression == 'EXTENDED_COLUMN') {
      let x = {
        "name": this.form.value.expname,
        "function": {
          "expression": this.form.value.expression,
          "returntype": "extendedcolumn(100)",
          "parameter": {
            "type": "column",
            "value": this.form.value.hostFactTable,
            "next_parameter": {
              "type": "column",
              "value": this.form.value.extendedFactTable,
              "next_parameter": null
            }
          },
          "configuration": null
        },
        "showDim": false
      };
      if (this.editStatus) {
        let index = this.form.value.index;
        this.defaultMeasure[index] = x;
      }
      else
        this.defaultMeasure.push(x);
    }
    else {
      let x = {
        "name": this.form.value.expname,
        "function": {
          "expression": this.form.value.expression,
          "parameter": {
            "type": this.form.value.paramType,
            "value": paramValue
          },
          "returntype": this.form.value.returnType
        }
      };
      if (this.editStatus) {
        let index = this.form.value.index;
        this.defaultMeasure[index] = x;
      }
      else
        this.defaultMeasure.push(x);
    }
    this.showMeasure = false;
    this.form.reset();
    this.cubesService.cubeMeta.measures = this.defaultMeasure;
    this.cubesService.measure = this.defaultMeasure;
    this.measureEvent.emit(this.defaultMeasure);
    this.visStep5.step5data();
    this.cubesService.listUpdated(this.cubesService.measure);
  }
  getEncodings(i) {
    // let encode=column.encoding.split(':',2);
    if (this.form.value.columns.length >= i) {
      let column = this.form.value.columns[i].expName;
      if (this.tables) {
        for (let value of this.tables) {
          for (let cols of value.columns) {
            if (value.name + '.' + cols.name == column) {
              for (let [key, value] of Object.entries(this.cubeConfig.columnTypeEncodingMap)) {

                if (cols.datatype.startsWith(key)) {
                  return value;
                }
              }
            }
          }
        }
      }
      return [];
    }
  }
  getAllParameter(param, expression) {
    if (expression == 'COUNT_DISTINCT') {
      this.html += '<li>Value <b>' + param.value + '</b>,&nbsp;Type<b> ' + param.type + '</b></li>';
      if (param.next_parameter != null) {
        this.getAllParameter(param.next_parameter, expression);
      }
    }
    else if (expression == 'EXTENDED_COLUMN') {
      this.html += '<li>Host Column <b> ' + param.value + '</b></li>';
      this.html += '<li>Extended column <b> ' + param.next_parameter.value + '</b></li>';
    }
    else if (expression == 'TOP_N') {
      if (this.topNOrder == '')
        this.topNOrder = param.value;
      else
        this.topNGroup += param.value + ",";
      if (param.next_parameter != null) {
        this.getAllParameter(param.next_parameter, expression);
      }
      else {
        this.html += '<li>SUM|ORDER BY <b> ' + this.topNOrder + '</b></li>';
        this.html += '<li>Group By <b> ' + this.topNGroup + '</b></li>';
      }
    }
  }
  getDistinct(parameter, expression) {
    this.topNGroup = '';
    this.topNOrder = '';
    this.html = '';
    this.getAllParameter(parameter, expression);
    return this.html;
  }
  deleteMeasure(measureObj) {
    swal(
      {
        type: 'warning',
        title: 'Are you sure you want to remove?',
        showCancelButton: true, confirmButtonText: 'Yes',
        cancelButtonText: 'No'
      }
    ).then((status) => {
      if (status.value == true) {
        var index = this.defaultMeasure.indexOf(measureObj);
        this.defaultMeasure.splice(index, 1);
        this.cdr.detectChanges();
        this.cubesService.cubeMeta.measures = this.defaultMeasure;
        this.cubesService.measure = this.defaultMeasure;
        this.measureEvent.emit(this.defaultMeasure);
        // this.visStep5.step5data();
        // this.visStep5.ngOnInit();
        this.cubesService.listUpdated(this.cubesService.measure);
      }
    }, (dismiss) => {
      if (dismiss === 'cancel') {
      }
    });
   
  }
  addMeasure() {
    this.form.reset();
    this.modelService.getModelByName(this.cubesService.cubeMeta.model_name).subscribe(
      data => {

        this.metrics = data[0].metrics;
      });
    if (this.form.value.columns.length > 0) {
      let i = 0;
      for (let param in this.form.value.columns) {
        this.columnList.removeAt(i);
        i++;
      }
    }
    this.editStatus = false;
    this.showMeasure = true;
  }
  cancelColumn() {
    this.showMeasure = false;
    if (this.form.value.columns.length > 0) {
      let i = 0;
      for (let param in this.form.value.columns) {

        this.columnList.removeAt(i);
        i++;
      }
    }
    this.form.reset();
  }
  getColumnElements(next, res, configuration) {

    if (!next)
      return res;

    if (configuration) {
      let x = 'topn.encoding.' + next.value;
      let topValues = [];
      for (let [key, value] of Object.entries(configuration)) {

        if (x == key) {
          topValues = String(value).split(':', 2);
          break;
        }
      }


      res.push({ expName: next.value, encode: topValues[0], length: topValues[1] });
    }
    else
      res.push({ expName: next.value, encode: null, length: null }); this.addColumn();
    if (next.next_parameter)
      this.getColumnElements(next.next_parameter, res, configuration)
    return res;
  }
  dimensionEdit(measure) {
    this.form.reset();
    this.editStatus = true;
    this.showMeasure = true;
    let paramValue = '';
    var index = this.defaultMeasure.indexOf(measure);
    let res = [];
    if (this.form.value.columns.length > 0) {
      let i = 0;
      for (let param in this.form.value.columns) {

        this.columnList.removeAt(i);
        i++;
      }
    }
    if (measure.function.expression == 'COUNT_DISTINCT' || measure.function.expression == 'TOP_N') {
      // let column=this.getColumnElements(measure.function.parameter.next_parameter,res);

      this.form.patchValue({
        index: index,
        expname: measure.name,
        expression: measure.function.expression,
        returnType: measure.function.returntype,
        paramValue: measure.function.parameter.value,
        paramValueConstant: measure.function.parameter.value,
        paramType: measure.function.parameter.type,
        columns: this.getColumnElements(measure.function.parameter.next_parameter, res, measure.function.configuration)
      });
      this.setReturnType();
      this.form.patchValue({
        returnType: measure.function.returntype,
      });
    }
    else if (measure.function.expression == 'EXTENDED_COLUMN') {
      this.form.patchValue({
        index: index,
        expname: measure.name,
        expression: measure.function.expression,
        hostFactTable: measure.function.parameter.value,
        extendedFactTable: measure.function.parameter.next_parameter.value,
        paramType: measure.function.parameter.type,

      });

    }
    else {
      this.form.patchValue({
        index: index,
        expname: measure.name,
        expression: measure.function.expression,
        returnType: measure.function.returnType,
        paramValue: measure.function.parameter.value,
        paramValueConstant: measure.function.parameter.value,
        paramType: measure.function.parameter.type,
      });
      this.getReturnType();
      this.form.patchValue({
        returnType: measure.function.returntype,
      });
      if (measure.function.expression == 'PERCENTILE') {
        this.returnType = [{
          name: 'PERCENTILE(100)', value: 'PERCENTILE(100)'
        }]
        this.form.patchValue({
          paramType: 'column',
          returnType: 'PERCENTILE(100)',
        });
      }
    }

  }
  checkCountExpression() {
    let stat = true;
    for (let param of this.defaultMeasure) {

      if (param.function.expression == 'COUNT') {
        stat = false;
        break;
      }

    }
    return stat;
  }
  nextPage(page) {
    if (this.defaultMeasure.length == 0 || this.checkCountExpression()) {
      swal("Warning!", "Please define your metrics.<br>[COUNT] metric is required.", "warning");
      return;
    }
    this.cubesService.cubeMeta.measures = this.defaultMeasure;
    this.cubesService.measure = this.defaultMeasure;
    this.measureEvent.emit(this.defaultMeasure);
  }
}

