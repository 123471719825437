import { Component, OnInit, Output, AfterViewInit, EventEmitter, Input} from '@angular/core';
import { FormBuilder, FormGroup, FormArray, Validators } from '@angular/forms';
import { Location } from '@angular/common';
import * as $ from 'jquery';

@Component({
  selector: 'app-json-parse',
  templateUrl: './json-parse.component.html',
  styleUrls: ['./json-parse.component.scss']
})
export class JsonParseComponent implements OnInit,AfterViewInit {
  @Input() jsonArray: any[];
  @Input() jsonCodeSnippetString: string;
  @Input() jsonTableName: string;
  @Input() jsonForm: FormGroup;
  @Output() jsonArrayEvent: EventEmitter<any>= new EventEmitter();
  @Output() currentTabEvent: EventEmitter<number>= new EventEmitter();
  @Output() jsonCodeEvent: EventEmitter<any>= new EventEmitter();
  @Output() jsonFormEvent: EventEmitter<any>= new EventEmitter();
  //Variables to check json
  jsonCodeSnippet: string;
  jsonCheck:boolean = true;
  jsonInput:boolean = true;
  text:string = "";
  options: any = {maxLines: 1000, printMargin: false};
  timestampExists:boolean = false;
  
  // from validation variables
  nameInput:boolean = true;
  submitted:boolean = false;

  constructor(private fb: FormBuilder, private location: Location) { }

  ngAfterViewInit() {
      $(document).ready(function(){
          var contenth = $( window ).height() - 110 ;
          var sidebarh = $( window ).height() - 111 ;
          $(".pagec").css("height", contenth );
          $(".sidebar-wrapper").css("height", sidebarh );
          /* editor height */
          //var json_editor_h = $( window ).height() - 440 ;
          //$("#json_editor").css("height", json_editor_h); 
          /* tbody height */
          var tbody_h = $( window ).height() - 530 ;
          $(".tbodyc").css("height", tbody_h); 
      });

      $(window).resize(function(){
        //var json_editor_h = $( window ).height() - 440 ;
        //$("#json_editor").css("height", json_editor_h);

        var tbody_h = $( window ).height() - 530 ;
        $(".tbodyc").css("height", tbody_h); 
      })
  }
  
    
    ngOnInit() {
    if (this.jsonCodeSnippetString != undefined) {
      this.jsonCodeSnippet = this.jsonCodeSnippetString
    }
    this.createJsonForm()
  }
  createJsonForm() {
    this.jsonForm = this.fb.group({
      tableName: [this.jsonTableName,Validators.required],
      tableData: this.fb.array([])
    });
    this.jsonForm.valueChanges.subscribe(console.log)
  }
  get tableNameForm() {
    return this.jsonForm.controls;
  }
  get columnForms() {
    return this.jsonForm.get('tableData') as FormArray
  }
  addColumn() {
    const newColumn = this.fb.group({
      id:[],
      colName:[],
      datatype:[],
      comment:[],
    })
    this.columnForms.push(newColumn);
  }
  parseJSON() { 
    if (this.jsonArray !== []) {
      this.jsonArray = []
    }
    if (this.jsonCodeSnippet != undefined) {
      this.jsonCheck = true
    } else {
      this.jsonCheck = false
    }
    let table;
    this.jsonInput = false;
    try {
      table = JSON.parse(this.jsonCodeSnippet)
      this.jsonInput= true;
    } catch(e) {
      this.jsonInput = false;
    }

    if (this.jsonInput) {
      this.flattenTables(table, "",false)
    }
  }
  flattenTables(table, sourceCol,recursiveCall) {
    let index =0;
      for (let colName in table) {
        let dType:string='';
        let comment ='';
        if (typeof table[colName] === 'object') {
          this.flattenTables(table[colName], colName,true)         
        }  
        if(typeof table[colName] === 'number') {
          if( table[colName] % 1 === 0 && table[colName].toString().length <= 12) {
            dType = 'int'
          }else if ( table[colName] % 1 !== 0 && table[colName].toString().length <= 12) {
            dType= 'decimal'
          }else if ( table[colName].toString().length > 12) {
            dType='timestamp',
            comment='timestamp'
          }
        } else if (typeof table[colName] === 'string' && table[colName].length <= 256) {
          //this is where we will check for date string
            let isDate = new Date(table[colName])
            var regexp = new RegExp("^(012[1-9]|3[0-1]){1}[/-]?(0[1-9]|1[0-2]){1}[/-]?((19|20)?[0-9][0-9])")
            var regexp2 = new RegExp("^(0[1-9]|1[0-2]){1}[/-]?(012[1-9]|3[0-1]){1}[/-]?((19|20)?[0-9][0-9])")
            if(isDate.toString() != 'Invalid Date'|| regexp.test(table[colName]) || regexp2.test(table[colName])) { 
              dType='date';
            } else {
              dType = 'varchar(256)'
            }
          } else if ((typeof table[colName] === 'string'&& table[colName].length > 256)) {
            dType = 'string'
          }
        if ( dType !=='') {
          if(recursiveCall) {
            this.jsonArray.push({id:index, name:sourceCol+'_'+colName, comments:sourceCol+'|'+colName + ' '+ comment, datatype: dType, isChecked:'true'})
            index++   
          } else {
            this.jsonArray.push({id:index, name:colName, comments:comment, datatype: dType, isChecked:'true'})
            index++
          }
        }
      }
      if(!recursiveCall) {
        this.jsonArray.push({id:++index, name:'year_start', comments:"derived time dimension", datatype:'date', isChecked:'true'})
        this.jsonArray.push({id:++index, name:'quarter_start', comments:"derived time dimension", datatype:'date', isChecked:'true'})
        this.jsonArray.push({id:++index, name:'month_start', comments:"derived time dimension", datatype:'date', isChecked:'true'})
        this.jsonArray.push({id:++index, name:'week_start', comments:"derived time dimension", datatype:'date', isChecked:'true'})
        this.jsonArray.push({id:++index, name:'day_start', comments:"derived time dimension", datatype:'date', isChecked:'true'})
        this.jsonArray.push({id:++index, name:'hour_start', comments:"derived time dimension", datatype:'timestamp', isChecked:'true'})
        this.jsonArray.push({id:++index, name:'minute_start', comments:"derived time dimension", datatype:'timestamp', isChecked:'true'})
      }
  }
  setColumnType(event, colId) {
    if ( this.jsonArray[colId].datatype=== 'timestamp') {
      this.jsonArray[colId].comments=''
    }
    if (event === 'timestamp') {
      this.jsonArray[colId].comments='timestamp'
    }
    this.jsonArray[colId].datatype=event
    this.timestampCheck()
  }
  changeCheckStatus(colId) {
    this.jsonArray[colId].isChecked=!this.jsonArray[colId].isChecked
  }
  createTableData() {
    if (this.columnForms != undefined) {
      while (this.columnForms.length !== 0 ) {
        this.columnForms.removeAt(0)
      }
    }
    this.timestampExists = false;
    this.submitted=true;
    let index: number=0;
    let tmpComments: string;  
    for (let col of this.jsonArray) {
      if ( col.isChecked ) {
        if ( col.comments === 'derived time dimension') {
          tmpComments = ''
        } else {
          tmpComments = col.comments
        }
        const newColumn = this.fb.group({
          id:[index],
          colName:[col.name],
          datatype:[col.datatype],
          comment:[tmpComments]
        })
        this.columnForms.push(newColumn)
        index++
      }
      if ( tmpComments === 'timestamp'){
        this.timestampExists = true

      }
    }
    if(this.jsonForm.invalid) {
      return;
    }else if (!this.jsonForm.invalid && this.timestampCheck()) {
      this.currentTabEvent.emit(2);
    }
    this.jsonCodeEvent.emit(this.jsonCodeSnippet)
    this.jsonFormEvent.emit(this.jsonForm)
    this.jsonArrayEvent.emit(this.jsonArray)
  }
  timestampCheck() {
    return this.timestampExists
  }
  cancel() {
    this.location.back();
  }
}
