import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-cube-view-step-five',
  templateUrl: './cube-view-step-five.component.html',
  styleUrls: ['./cube-view-step-five.component.scss']
})
export class CubeViewStepFiveComponent implements OnInit {
  @Input() cubeDesc;
  constructor() { }

  ngOnInit() {
  }

	getEngineType(num: any) {
		// if(num == 2) {
		// 	return "MapReduce";
		// }
		if(num == 6) {
			return "Spark";
		}
		// if(num == 5){
		// 	return "Flink";
		// }
	}
}
