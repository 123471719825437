import { Component, OnInit, AfterViewInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ModelService } from '../../../../../services/model/model.service';
import {ModelStepsService} from '../../../../../services/model/model-steps.service';
import swal from 'sweetalert2';
import { TouchSequence } from 'selenium-webdriver';
import { NgSelectComponent } from '@ng-select/ng-select';



@Component({
  selector: 'app-conditions-settings',
  templateUrl: './conditions-settings.component.html',
  styleUrls: ['./conditions-settings.component.scss']
})
export class ConditionsSettingsComponent implements OnInit, AfterViewInit {
  projectId:any;
  partitionDateTableList:any=[];
  partitonDateColumnList:any=[];
  isPartitionDateColEnabled:boolean = true;
  partitionDateTblName:any;
  partitionDateColName:any;
  partitionDateFormats=[
    {name:"yyyy-MM-dd"},
    {name:"yyyyMMdd"},
    {name:"yyyy-MM-dd HH:mm:ss"},
    {name:"yyyy-MM-dd HH:mm"},
    {name:"yyyy-MM-dd HH"},
    {name:"yyyyMMddHH"},
    {name:"yyyyMMddHHMM"},
    {name:"yyyyMMddHHMMSS"},

  ]
  isPartitionDateFormatEnabled:boolean = true;
  partitionDateFormatName:any;
  isCheckBoxShow:boolean = true;
  checkBoxName:boolean = false;
  isPartitionTimeDetailsEnabled:boolean = true;
  partitionTimeTblName:any;
  isPartitionTimeColEnabled:boolean = true;
  paritionTimeColName:any;
  partitonTimeColumnList:any=[];
  partitionTimeFormats=[
    {name:"HH:mm:ss"},
    {name:"HH:mm"},
    {name:"HH"}

  ]

  partitionTimeFormatName:any;
  modelRequest:any;
  modelData:any;
  partitionFilter:any;
  @ViewChild('select') select: NgSelectComponent;

  constructor(private route: ActivatedRoute, private router: Router, private modelSerivce: ModelService, private modelStepsService: ModelStepsService) { 
    if(this.modelStepsService.modelName ===  undefined || this,modelStepsService.modelDescription === undefined){
      this.projectId = this.route.snapshot.paramMap.get("projectId");
      this.router.navigate(['/projects/',this.projectId ,'models','designer','info']);
    } 
  }

  ngAfterViewInit() {
    
  }

  ngOnInit() {
    console.log(this.partitionDateTblName);
    console.log(this.partitionDateColName);
    console.log(this.partitionDateFormatName);
    this.setFocus();
    this.projectId = this.route.snapshot.paramMap.get("projectId");
    this.partitionDateTblName = 'Select Partition Table';
    this.partitionDateColName = 'Select Partition Column';
    //this.partitionTimeTblName = 'Select Partition Table';
    //QUBZ-3941 - Settings Tab Date Format should be Disable - 17/11/22
    //this.partitionDateFormatName = this.partitionDateFormats[0].name;
    this.partitionDateTableList = this.modelStepsService.measureList;

    for(let param of this.modelStepsService.lookUps){
      if(param.kind=='FACT'){
        if(this.partitionDateTableList.indexOf(param.alias)==-1)
          this.partitionDateTableList.push(param.alias);
      }
    }
    // this.partitonDateColumnList = this.modelStepsService.dimensionColumns;

    this.partitionTimeTblName = 'Select Partition Table';
    this.paritionTimeColName ='Select Partition Column';
    this.partitionTimeFormatName = 'Select Time Format';
    if(this.modelStepsService.partitionDateTblName!==undefined){
      this.partitionDateTblName = this.modelStepsService.partitionDateTblName;
    }

    if(this.modelStepsService.partitionDateColName!==undefined){
      this.partitionDateColName = this.modelStepsService.partitionDateColName;
      this.isPartitionDateColEnabled = false;
      this.getDimensionsOnLoad(this.partitionDateTblName);
    }

    if(this.modelStepsService.checkBoxName!==undefined){
      this.checkBoxName = this.modelStepsService.checkBoxName;
      if(this.checkBoxName === true){
        this.isPartitionTimeDetailsEnabled = false;
      } else{
        this.isPartitionTimeDetailsEnabled = true;
      }
     
    }

    if(this.modelStepsService.partitionTimeTblName!==undefined){
      this.partitionTimeTblName = this.modelStepsService.partitionTimeTblName;
      this.isPartitionTimeColEnabled = false; 
      //this.showPartitionTimeCols();
    }

    if(this.modelStepsService.paritionTimeColName!==undefined){
      this.paritionTimeColName = this.modelStepsService.paritionTimeColName;
      this.getPartitionTimeColumns();
      this.getDimensionsTimeOnLoad(this.partitionTimeTblName);

     // this.isPartitionTimeFormatEnabled = false;
    }

    if(this.modelStepsService.partitionDateFormatName!==undefined){
      this.partitionDateFormatName = this.modelStepsService.partitionDateFormatName;
      this.isPartitionDateFormatEnabled = false;
      this.isCheckBoxShow = false;
    }

    if(this.modelStepsService.partitionTimeFormatName!==undefined){
      this.partitionTimeFormatName = this.modelStepsService.partitionTimeFormatName;

    }

    if(this.modelStepsService.partitionFilter!==undefined){
      this.partitionFilter = this.modelStepsService.partitionFilter;

    }
  }

  showDateColumns(){
    if(!this.partitionDateTblName)
      {
        this.partitionDateColName=null;
        this.modelStepsService.partitionDateTblName = null;
        this.modelStepsService.partitionDateColName=null;
        this.isPartitionDateColEnabled = true;
        this.isPartitionDateFormatEnabled = true;
        this.isCheckBoxShow = true;
      }
    if(this.partitionDateTblName!== 'Select Partition Table'){
      this.modelStepsService.partitionDateTblName = this.partitionDateTblName;
      this.isPartitionDateColEnabled = false;
      this.partitionDateColName='';
      this.getDimensions(this.partitionDateTblName);
    } else {
      this.partitionDateColName = 'Select Partition Column';
      this.isPartitionDateColEnabled = true;
      this.isPartitionDateFormatEnabled = true;
      this.isCheckBoxShow = true;
    }
    
  }
  getDimensionsOnLoad(tableName){
    this.partitonDateColumnList=[];
    let name;
    if(tableName==this.modelStepsService.factTable.split('.',2)[1]){
      name=this.modelStepsService.factTable;
    }else{
       let item=this.modelStepsService.lookUps.find(({alias}) =>tableName===alias)

       if(item)
        name=item.table;
        else
        name='';
    }
      let dims=this.modelStepsService.tableList.find(({dataSourceName}) =>name===dataSourceName)
      if(dims)
      {
        for(let cols of dims.columns){
          // QUBZ-3852 : 06/09/2022 QUBZ 6.0.0-In Synapse DB model creation Transaction_Date disappear in Partition column Added fix by cols.datatype=='date'
        if((cols.datatype.startsWith('varchar') || cols.datatype=='timestamp'  || cols.datatype=='date' || cols.datatype=='integer') && cols.name!=this.paritionTimeColName)
        this.partitonDateColumnList.push(cols.name);
      }
    }

  }
  getDimensions(tableName){
    this.partitonDateColumnList=[];
    this.partitionTimeTblName='';
    this.paritionTimeColName='';
    this.isPartitionTimeDetailsEnabled = true;
    this.checkBoxName=false;
    let name;
    
    
    if(tableName==this.modelStepsService.factTable.split('.',2)[1]){
      name=this.modelStepsService.factTable;
    }else{
       let item=this.modelStepsService.lookUps.find(({alias}) =>tableName===alias)

       if(item)
        name=item.table;
        else
        name='';
    }
      let dims=this.modelStepsService.tableList.find(({dataSourceName}) =>name===dataSourceName)
      if(dims)
      {
        for(let cols of dims.columns){
          // QUBZ-3852 : 06/09/2022 QUBZ 6.0.0-In Synapse DB model creation Transaction_Date disappear in Partition column Added fix by cols.datatype=='date'
        if((cols.datatype.startsWith('varchar')||  cols.datatype=='date' || cols.datatype=='timestamp' || cols.datatype=='integer')  && cols.name!=this.paritionTimeColName)
        this.partitonDateColumnList.push(cols.name);
      }
    }

  }
  getDimensionsTimeOnLoad(tableName){
    this.partitonTimeColumnList=[];
    let name;
    this.isPartitionTimeColEnabled=false;
    if(tableName==this.modelStepsService.factTable.split('.',2)[1]){
      name=this.modelStepsService.factTable;
    }else{
       let item=this.modelStepsService.lookUps.find(({alias}) =>tableName===alias)

       if(item)
        name=item.table;
        else
        name='';
    }
      let dims=this.modelStepsService.tableList.find(({dataSourceName}) =>name===dataSourceName)
      if(dims)
      {
        for(let cols of dims.columns){
          // QUBZ-3852 : 06/09/2022 QUBZ 6.0.0-In Synapse DB model creation Transaction_Date disappear in Partition column Added fix by cols.datatype=='date'
        if((cols.datatype.startsWith('varchar') || cols.datatype=='timestamp' || cols.datatype=='date' || cols.datatype=='integer')&&cols.name!=this.partitionDateColName)
        this.partitonTimeColumnList.push(cols.name);
      }
    }

  }
  getDimensionsTime(tableName){
    this.paritionTimeColName='';
    this.partitonTimeColumnList=[];
    let name;
    this.isPartitionTimeColEnabled=false;
    if(tableName==this.modelStepsService.factTable.split('.',2)[1]){
      name=this.modelStepsService.factTable;
    }else{
       let item=this.modelStepsService.lookUps.find(({alias}) =>tableName===alias)

       if(item)
        name=item.table;
        else
        name='';
    }
      let dims=this.modelStepsService.tableList.find(({dataSourceName}) =>name===dataSourceName)
      if(dims)
      {
        for(let cols of dims.columns){
          // QUBZ-3852 : 06/09/2022 QUBZ 6.0.0-In Synapse DB model creation Transaction_Date disappear in Partition column Added fix by cols.datatype=='date'
        if((cols.datatype.startsWith('varchar') || cols.datatype=='timestamp'  || cols.datatype=='date' || cols.datatype=='integer')&&cols.name!=this.partitionDateColName)
        this.partitonTimeColumnList.push(cols.name);
      }
    }

  }
  showDateFormat(){
    if(this.partitionDateColName!== 'Select Partition Column'){
      this.modelStepsService.partitionDateColName = this.partitionDateColName;

      this.isPartitionDateFormatEnabled = false;
      this.isCheckBoxShow = false;

    } else {
      this.isPartitionDateFormatEnabled = true;
      this.isCheckBoxShow = true;
    }
  }

  showPartitionTimeDetails(){
    if(this.checkBoxName === true){
      this.modelStepsService.checkBoxName = this.checkBoxName;
      this.isPartitionTimeDetailsEnabled = false;
    }else{
      this.modelStepsService.checkBoxName = this.checkBoxName;
      this.isPartitionTimeDetailsEnabled = true;
    }
  }

  showPartitionTimeCols(){
    if(this.partitionTimeTblName!== 'Select Partition Table'){
      this.modelStepsService.partitionTimeTblName = this.partitionTimeTblName;

      this.isPartitionTimeColEnabled = false;
    } else{
      this.paritionTimeColName ='Select Partition Column';
      this.isPartitionTimeColEnabled = true;
    }
  }

  showparitionTimeColName(){
    if(this.paritionTimeColName!== 'Select Partition Column'){
      this.modelStepsService.paritionTimeColName = this.paritionTimeColName;

      
    } 
  }

  getPartitionDateColumns(){
    this.partitonDateColumnList= [];
    this.partitionDateColName='Select Partition Column';
    for (let param of this.partitonTimeColumnList) {
     if(this.paritionTimeColName!== param){
       this.partitonDateColumnList.push(param);
      }
      
      } 
    
  }


  getPartitionTimeColumns(){
    this.partitonTimeColumnList= [];
    if(this.modelStepsService.paritionTimeColName){
      for (let param of this.partitonDateColumnList) {
        if(this.partitionDateColName!== param){
          this.partitonTimeColumnList.push(param);
         }
         
         } 
    }else{
      this.paritionTimeColName='Select Partition Column';
    }
  
    
  }

  saveModelStepsService(){


    let column=[];
    for(let param of this.modelStepsService.dimensionName){
      column.push(param.name);
    }
    let partitionDateCols;
    if(this.partitionDateTblName && this.partitionDateTblName && this.partitionDateTblName!==undefined && this.partitionDateTblName!=='Select Partition Table' && this.partitionDateColName!==undefined && this.partitionDateColName!=='Select Partition Column'){
     partitionDateCols = this.partitionDateTblName + '.' + this.partitionDateColName;
    }

    let partitionDateFormatName;
    if(this.partitionDateFormatName!==undefined){
      partitionDateFormatName = this.partitionDateFormatName;
      this.modelStepsService.partitionDateFormatName = this.partitionDateFormatName;

    }

    let partitionTimeCols;
    if(this.partitionTimeTblName!==undefined && this.partitionTimeTblName!== '' && this.partitionTimeTblName!=='Select Partition Table' && this.paritionTimeColName!==undefined && this.partitionTimeTblName!==null && this.paritionTimeColName!==null && this.paritionTimeColName!=='Select Partition Column'){
     
      partitionTimeCols = this.partitionTimeTblName + '.' + this.paritionTimeColName;
      
    }

    let partitionTimeFormatName;
    if(this.partitionTimeFormatName!==undefined || this.partitionTimeFormatName!=='Select Time Format'){
      this.partitionTimeFormatName = this.modelStepsService.partitionTimeFormatName;

    }
   
  
   if(this.modelStepsService.kind === 'LOOKUP'){
   
    let  fact_table;
    let table;
    if(fact_table!==undefined){
      fact_table  = this.modelStepsService.factTable;
      if(table!==undefined){
        table = fact_table.split(".");
        table= fact_table[1];
      }
     
    }
   
    let from_table;
    let selFromTable = this.modelStepsService.selFromTable.split(".");
    //if(from_table!==undefined && from_table!==null){
      from_table= selFromTable[1] + '.' + this.modelStepsService.selFromTableColumn;

   // }
    let lookup_table;
    //if(lookup_table!==undefined && lookup_table!==null){
      lookup_table =  this.modelStepsService.aliasName + '.' + this.modelStepsService.selLookupColumn;

    //}

    let joinTypes;
    //if(joinTypes!==undefined && joinTypes!==null){
      joinTypes = this.modelStepsService.joinTypes;
    //}

    let pkType;
   // if(pkType!==undefined && pkType!==null){
      pkType = this.modelStepsService.pkType;
    //}

    let fkType;
  //  if(fkType!==undefined && fkType!==null){
      fkType = this.modelStepsService.fkType;
   // }

  if(this.modelStepsService.metricName || this.modelStepsService.measureLookup){
    
   let measureColumn=[];

   let metricName;

   if(this.modelStepsService.metricName){
    for(let param of this.modelStepsService.metricName){
      measureColumn.push( this.modelStepsService.factAliasName + '.' + param.name);
 
      
    }
   }
  

   if(this.modelStepsService.measureLookup){
   for(let param of this.modelStepsService.measureLookup){
     if(param.measureName){
      for(let col of param.measureName){
        let item=param.alias+'.'+col.name;
        if(measureColumn.indexOf(item)==-1)
         measureColumn.push(item);
      }
     }
   
    
   }
  }

 //  if(metricName!==undefined && measureColumn!== undefined){
     

     
  // }
    this.modelData={
      "name": this.modelStepsService.modelName,
      "description": this.modelStepsService.modelDescription,
      "fact_table": this.modelStepsService.factTable,
      "lookups": this.modelStepsService.lookUps,
      "filter_condition": this.partitionFilter,
      "dimensions": this.modelStepsService.dimension,
                     "metrics": measureColumn,
                       "partition_desc": {
                       "partition_date_column": partitionDateCols,     
                       "partition_type": "APPEND",
                       "partition_date_format": partitionDateFormatName,
                       "partition_time_column":partitionTimeCols,
                       "partition_time_format":partitionTimeFormatName
                      },
                       "last_modified": 0
   }
  }
  else{
    this.modelData={
      "name": this.modelStepsService.modelName,
      "description": this.modelStepsService.modelDescription,
      "fact_table": this.modelStepsService.factTable,
      "lookups": this.modelStepsService.lookUps,
      "filter_condition": this.partitionFilter,
      "dimensions": this.modelStepsService.dimension,
      "metrics": [],
                       "partition_desc": {
                       "partition_date_column": partitionDateCols,   
                       "partition_type": "APPEND",
                       "partition_date_format": partitionDateFormatName,
                       "partition_time_column":partitionTimeCols,
                       "partition_time_format":partitionTimeFormatName
                      },
                       "last_modified": 0
   }
  }

 } else{

 
    if(this.modelStepsService.checkMetricAdded ==="MetricAdded"){
      let measureColumn=[];

      let metricName;
      
      for(let param of this.modelStepsService.metricName){
        measureColumn.push( this.modelStepsService.factAliasName + '.' + param.name);
   

      }
      
  
    //  if(metricName!==undefined && measureColumn!== undefined){
        metricName = this.modelStepsService.factAliasName + '.' + measureColumn;
     // }


      this.modelData={
        "name": this.modelStepsService.modelName,
        "description": this.modelStepsService.modelDescription,
        "fact_table": this.modelStepsService.factTable,
        "lookups": [],
        "filter_condition": this.partitionFilter,
        "dimensions": [  {"table": this.modelStepsService.factAliasName,
                          "columns": column
                          }
                       ],"metrics": measureColumn,
                         "partition_desc": {
                         "partition_date_column": partitionDateCols,   
                         "partition_type": "APPEND",
                         "partition_date_format": partitionDateFormatName,
                         "partition_time_column":partitionTimeCols,
                         "partition_time_format":partitionTimeFormatName
                        },
                         "last_modified": 0
     }
    
    } else{
   
   
           
      this.modelData={
        "name": this.modelStepsService.modelName,
        "description": this.modelStepsService.modelDescription,
        "fact_table": this.modelStepsService.factTable,
        "lookups": [],
        "filter_condition": this.partitionFilter,
        "dimensions": [  {"table": this.modelStepsService.factAliasName,
                          "columns": column
                          }
                       ],"metrics": [],
                         "partition_desc": {
                         "partition_date_column": partitionDateCols,  
                         "partition_type": "APPEND",
                         "partition_date_format": partitionDateFormatName,
                         "partition_time_column":partitionTimeCols,
                         "partition_time_format":partitionTimeFormatName
                        },
                         "last_modified": 0
     }
    }

   }
    
  
    this.modelSerivce.save({"modelDescData":JSON.stringify(this.modelData),"project":this.projectId}).subscribe(data=>{
      swal("Success!", "Model created successfully", "success"); 
       this.router.navigate(['/projects/',this.projectId,'models']);
    },(error) => {
      swal({
       type:'error',
       title: ' OOPS...',
       text:error.error.msg,
       confirmButtonText: 'OK',
     }).then((status) => {
       if(status.value==true){
         this.router.navigate(['/projects/',this.projectId,'models']);
         }
     }, (dismiss) => {
       if (dismiss === 'cancel') {
       }
       
     });
   });
  }

  saveModelSteps(){
    //QUBZ-3937-In Model creation setting tab warning message should be displayed while saving without Partition column and date format
    //QUBZ-3951 - For Create Model Partition column and Date Format Not mandatory - 18/11/22
    //QUBZ-3953 - Creating Model Getting Oops error - 21/11/22
    if(((this.partitionDateTblName != 'Select Partition Table' && this.partitionDateTblName != null) && (!this.partitionDateColName || !this.partitionDateFormatName)) || ((this.partitionTimeTblName != "" && this.partitionTimeTblName != null) && (!this.paritionTimeColName || (!this.partitionTimeFormatName && this.partitionTimeFormatName!='Select Time Format')))) {
      swal({
        type:'warning',
        title: 'Partition Column cant be null.<br/> Date Format cant be null.',
        html:'',
        confirmButtonText: 'OK',
        }).then((status) => {
        if(status.value==true){
        }
        }, (dismiss) => {
        if (dismiss === 'cancel') {
        }
    });
    }else{
    swal(
      { 
      type:'warning', 
      title: 'Are you sure you want to save the model?', 
      showCancelButton: true, confirmButtonText: 'Yes', 
      cancelButtonText: 'No'
      } 
      ).then((status) => {
      if(status.value==true){
       this.saveModelStepsService();     
       }
      }, (dismiss) => {
      if (dismiss === 'cancel') {
      }
      });
    }
  }


   // This method navigate to model info page

  loadModelDesigner(id){
   this.router.navigate(['/projects/',id,'models','designer','info']);
    }

    
  loadDataModel(id){
   this.router.navigate(['/projects/',id,'models','designer','data']);
  }

  navigateToDimensions(id){
      this.router.navigate(['/projects/',id,'models','designer','dimensions']); 
  }

  loadMeasures(id){
    this.modelStepsService.partitionDateTblName = this.partitionDateTblName;
    this.modelStepsService.partitionDateColName = this.partitionDateColName;
    this.modelStepsService.checkBoxName = this.checkBoxName;
    this.modelStepsService.partitionTimeTblName = this.partitionTimeTblName;
    this.modelStepsService.partitionDateFormatName = this.partitionDateFormatName;
    this.modelStepsService.paritionTimeColName = this.paritionTimeColName;
    this.modelStepsService.partitionTimeFormatName = this.partitionTimeFormatName;

    this.modelStepsService.partitionFilter = this.partitionFilter;
    this.router.navigate(['/projects/',id,'models','designer','measures']);
  }
  
  showTimeFormats(){
    if(this.partitionTimeFormatName!=="Select Time Format"){
      this.modelStepsService.partitionTimeFormatName = this.partitionTimeFormatName;
    }
  }

  setFocus(){
    this.select.focus();
  }


}
