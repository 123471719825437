import { Component, OnInit, Input, AfterViewInit } from '@angular/core';
import * as $ from 'jquery';

@Component({
  selector: 'app-model-view-step-one',
  templateUrl: './model-view-step-one.component.html',
  styleUrls: ['./model-view-step-one.component.scss']
})
export class ModelViewStepOneComponent implements OnInit,AfterViewInit {
  @Input() modelDesc;
  constructor() { }

  ngOnInit() {

  }

  ngAfterViewInit() {
    $(document).ready(function(){
          var contenth = $( window ).height() - 110 ;
          var sidebarh = $( window ).height() - 111 ;
          $(".pagec").css("height", contenth );
          $(".sidebar-wrapper").css("height", sidebarh );
        });
    }

}
