import { Component, OnInit } from '@angular/core';
import { DataCatalogTablesService } from '../../../services/data-catalog/data-catalog-tables.service'
import { DataCatalogService } from '../../../services/data-catalog/data-catalog.service';
import swal from 'sweetalert2';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-catalog-columns',
  templateUrl: './catalog-columns.component.html',
  styleUrls: ['./catalog-columns.component.scss']
})
export class CatalogColumnsComponent implements OnInit {
  tableDetails:any;
  page=1;
  dataCatalogDetails:any;
  columns:any;
  editMode:boolean;
  catalogId:any;
  tableId:any;
  catalogEdit:boolean;
  displayName:any;
  description:any;
  catalogDetails;
  order: string = 'name';
  reverse: boolean = true;
  constructor(private dataCatalogService:DataCatalogService,private dataCatalogTablesService:DataCatalogTablesService,private router:Router,private route: ActivatedRoute) {  }

  ngOnInit() {
    this.getColumns(1);
  }
getColumns(page){
  page=page-1;
  this.catalogId=this.route.snapshot.paramMap.get("id");
  this.tableId=this.route.snapshot.paramMap.get("tableId");
  this.dataCatalogService.list(this.catalogId).subscribe(
    data=>{
      let res= <any>{};
      res=data;
     
      this.catalogDetails=res.dataCatalog;
    });
  this.dataCatalogTablesService.getTableDetails(this.catalogId,this.tableId).subscribe(
    data=>{
      let res= <any>{};
      res=data;
      this.dataCatalogDetails=res.dataCatalogTable;

     this.displayName=this.dataCatalogDetails.displayName;
     this.description=this.dataCatalogDetails.description;
  });
  this.dataCatalogTablesService.listColumns(this.catalogId,this.tableId,page,10).subscribe(
    data=>{
      let res= <any>{};
      res=data;
      this.tableDetails=res.page; 
      this.columns=res.page.content;
     
    });
}
selectAll(column,status){
  for(let param of column){
    if(!param.deleteFlag)
      param.status=!status;
  }
   this.dataCatalogTablesService.updateGroupColumnStatus(this.catalogId,this.tableId,column).subscribe(
    data =>{

    });
}
editDescription(){
  this.editMode=true;
}
closeDescription(){
  this.editMode=false;
  this.ngOnInit();
}
saveDescription(tables){
  
  this.dataCatalogTablesService.updateColumnDescription(this.catalogId,this.tableId,tables).subscribe(
    data =>{
      swal("Success!",'Details successfully updated', "success");
      this.editMode=false;
      this.getColumns(this.page);
  }, (error) => {

    swal("Failed",error.error.msg, "error");
  }); 
  
}
updateStatus(column){
  column.status=!column.status;
 this.dataCatalogTablesService.updateColumnStatus(this.catalogId,this.tableId,column.uuid,column).subscribe(
    data =>{

    });
}
editCatalog(){
  this.catalogEdit=true;
}
closeCatalog(){
  this.catalogEdit=false;
  this.ngOnInit();
}
saveCatalog(table){
  table.displayName=this.displayName;
  table.description=this.description;
  let arr=[];
  arr.push(table);
  this.dataCatalogTablesService.updateTableDescription(this.catalogId,arr).subscribe(
    data =>{
      swal("Success!","changes has been saved", "success");
      // this.getTables(this.page);
  }, (error) => {
    swal("Failed",error.error.msg, "error");
  }); 
  this.catalogEdit=false;
}
backToTables(catalog){

    this.router.navigate(['/manage-catalog/tables/'+catalog]);

}

setOrder(value: string) {
  if (this.order === value) {
    this.reverse = !this.reverse;
  }

  this.order = value;
}
}
