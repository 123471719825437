import { Component, OnInit } from '@angular/core';
import { DataAccessService } from '../../../services/data-access/data-access.service';
@Component({
  selector: 'app-data-catalog-access',
  templateUrl: './data-catalog-access.component.html',
  styleUrls: ['./data-catalog-access.component.scss']
})
export class DataCatalogAccessComponent implements OnInit {
  page=1;
  accessList;
  totalElements;
  constructor(private dataAccessService:DataAccessService) { }

  ngOnInit() {
    this.getList(this.page);
  }
  getList(page){
    page=page-1;
    this.dataAccessService.getAccess(page, 10).subscribe(data => {
     
      let res= <any>{};
      res=data;
      this.accessList=res.page.content;
      this.totalElements=res.page.totalElements;
    }
    );
  }
}
