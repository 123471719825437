import { Component, OnInit, AfterViewInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CommonServiceService } from '../../../../services/common-services/common-service.service';
import * as $ from 'jquery';
@Component({
  selector: 'app-job-scheduler',
  templateUrl: './job-scheduler.component.html',
  styleUrls: ['./job-scheduler.component.scss']
})
export class JobSchedulerComponent implements OnInit,AfterViewInit {

  projectId: string;
  loggedUser;
  qubzLicenseExpire:any;
  constructor(private route: ActivatedRoute,public commonServiceService:CommonServiceService) { 
    this.loggedUser=JSON.parse(sessionStorage.getItem("loggedUser"));
  }

  ngAfterViewInit() {
    $(document).ready(function(){
          var contenth = $( window ).height() - 110 ;
          var sidebarh = $( window ).height() - 111 ;
          $(".pagec").css("height", contenth );
          $(".sidebar-wrapper").css("height", sidebarh );
        });
    }

  ngOnInit() {
    this.projectId = this.route.snapshot.paramMap.get("projectId");
    this.qubzLicenseExpire = JSON.parse(sessionStorage.getItem("qubzLicenseExpire"));
    //this.saveLogs();
  }

  
  saveLogs(){
    var today = new Date();
    let param={
      username:this.loggedUser.username,
      targetPage:'projects/'+this.projectId+'/scheduler',
      day:today.getDay(),
      month:today.getMonth(),
      year:today.getFullYear()
    };
    this.commonServiceService.saveLogs(param).subscribe(
      data => {

      });
  }
 
}
