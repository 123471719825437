import { Columns } from './columns/columns.model';
import { Snapshot } from './snapshot/snapshot.model';
import { Exd } from './exd/exd.model';

export class Table {
    dbName: string;
    tableName: string;
    columns?: Columns[];
    snapshot?: Snapshot[];
    cardinality?: string[];
    exd: Exd[];
    tableType: string;
    sourceType: number;

    constructor(tableResponse: any) {
        this.dbName = tableResponse.database;
        this.tableName = tableResponse.name;
        this.columns = tableResponse.columns;
        this.sourceType = tableResponse.source_type;
        this.snapshot = tableResponse.snapshot;
        this.tableType = tableResponse.table_type;
        this.cardinality = tableResponse.cardinality;
        this.exd = tableResponse.exd;
    }
}
