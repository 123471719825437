import { Component, OnInit,AfterViewInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SidebarService } from '../../../services/sidebar/sidebar.service';
import * as $ from 'jquery';
@Component({
  selector: 'app-side-nav',
  templateUrl: './side-nav.component.html',
  styleUrls: ['./side-nav.component.scss']
})
export class SideNavComponent implements OnInit {
  items;
  project;
  menuType;
  constructor(private route: ActivatedRoute,private router:Router,private sidebarService:SidebarService) {
  
  }

  

  ngAfterViewInit() {
    setTimeout(function(){
      $('.leftnavtxt:contains(Profile)').each(function(){
        $(this).parents(".sidebar-list").append("<div class='profilefix profiletoggle'> <div class='tt_arrow'> </div><span class='tt_static'>Profile</span>   <a> <i class='fa fa-user profileClick'></i> <span class='leftnavtxt'>Profile</span></a> </div>");
      });
    }, 200);

  
    $(document).on("click", ".profiletoggle", function(){
      $(".profilec").toggleClass("profileshow");
    });

    $(document).on("mouseover", ".profiletoggle", function(){
      $(this).addClass("showtt");
    })

    $(document).on("mouseout", ".profiletoggle" , function(){
      $(this).removeClass("showtt");
    }) 
  
  

    /* 
    $(document).on("mouseover", "#themeicoec", function(){
      $(this).addClass("showbtn")
    })

    $(document).on("mouseout", "#themeicoec", function(){
      $(this).removeClass("showbtn")
    }) */
  }

 

  ngOnInit() {

    this.menuType=this.sidebarService.menuType;
    if(this.menuType=='project'){
     
      this.items = this.sidebarService.projectItems;
    }
    else
    {
      if (this.hasRole('ROLE_ADMIN')=='admin') {
          this.items = this.sidebarService.adminItems;
      }
      else{
          this.items = this.sidebarService.items;
      }
    }

  }
  
  hasRole(roles){
    let user=JSON.parse(sessionStorage.getItem("loggedUser"));
    for(let role of user.authorities){
      if(role.authority==roles)
        return 'admin';
    }
    return 'user';
  }
/*Key down event for Tab and press enter.
QUBZ-3736 07/07/2022 QUBZ-Profile Tab getting 404 ERROR
*/
  onKeyDownEvent(e,item){
    if(item.title == 'Profile')
    {
    if( e.which == 13 )//enter
      $(".profilec").toggleClass("profileshow");
      e.preventDefault();
      e.stopPropagation();
    }

  }
}


