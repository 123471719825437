import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Config } from '../../config';
import {
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
  HttpHandler,
  HttpHeaders,
  HttpEvent,
  HttpErrorResponse
} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ProjectExportService {
  static url = Config.url + '/metabackups';
  constructor(private http: HttpClient) { }
  exportAll() {
    const options:any = {
      headers: new HttpHeaders({'Content-Type': 'application/zip'}),
      withCredentials: true,
      responseType:'arraybuffer'
    }; 
    return this.http.post(ProjectExportService.url,{},options);
  }
  exportById(uuid) {
    // const options:any = {
    //   headers: new HttpHeaders({'Content-Type': 'application/zip'}),
    //   withCredentials: true,
    //   responseType:'arraybuffer'
    // }; 
    return this.http.get(ProjectExportService.url+"/export/download/"+uuid);
    
  }
  import(formData){
    return this.http.post(ProjectExportService.url+'/import/uploadfile',formData);
  }
}
