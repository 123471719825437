import { Component, OnInit, AfterViewInit } from '@angular/core';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import * as $ from 'jquery';
import { StreamingService } from 'src/app/services/streaming/streaming.service';
import { ActivatedRoute } from '@angular/router';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { analyzeAndValidateNgModules } from '@angular/compiler';
import swal from 'sweetalert2';

@Component({
  selector: 'app-assignment',
  templateUrl: './assignment.component.html',
  styleUrls: ['./assignment.component.scss']
})
export class AssignmentComponent implements OnInit, AfterViewInit {


  public cubeName: string;
  public projectName: string;
  public partitions: any[] = [];
  public assignments: any;
  public replicaSets: any[] = [];
  public load: boolean = false;
  public selectedReplica: any;
  public activeReceiver: any;
  public clusterData: any;


  constructor(
    private streamingService: StreamingService,
    private activatedRoute: ActivatedRoute,
    private modalService: NgbModal
  ) { }
  ngAfterViewInit() {
    $(document).ready(function () {
      var contenth = $(window).height() - 110;
      var sidebarh = $(window).height() - 111;
      $(".pagec").css("height", contenth);
      $(".sidebar-wrapper").css("height", sidebarh);
    });
  }

  ngOnInit() {
    this.cubeName = this.activatedRoute.snapshot.params['cubeName'];

    this.projectName = this.activatedRoute.snapshot.params['projectId']

    this.getCubeAssignmentDetails();
  }


  deleteAssignment() {
    Swal.fire({
      type: 'warning',
      title: 'Are you sure you want to delete this Assignment?',
      text: 'The Receiver Cluster association will also be removed from the Cube',
      showCancelButton: true, 
      confirmButtonText: 'Yes',
      cancelButtonText: 'No'
    }).then(
      result => {
        console.log(result)
        if(result.value == true) {
          this.load = true;
          this.streamingService.deleteAssignment(this.cubeName).subscribe(
            res => {
              this.load = false;
              this.partitions = [];
            },
            err => {
              this.load = false;
            }
          )
        } else {
          this.load = false;
        }
      }
    )
  }

  getCubeAssignmentDetails() {
    this.load = true;
    this.streamingService.assignment(this.cubeName).subscribe(
      (res: any) => {

        this.assignments = res.map(item => item.assignments);

        this.assignments.forEach((item: any[]) => {

          let replicaIds = Object.keys(item);

          replicaIds.forEach((value: any) => {
            if (item[value]) {
              this.activeReceiver = value;
              this.partitions = item[value].map(part => {
                return part.partition_id
              })
            }
          })
        })
        this.load = false;
      },
      error => {
        this.partitions = [];
        this.load = false;
      }
    )
  }

  getReciverId(content) {
    this.load = true;
    this.streamingService.getClusters().subscribe(
      (res: any[]) => {
        this.replicaSets = res.map(item => item.rs_id);
        this.selectedReplica = this.replicaSets.find(f => f == this.activeReceiver);
        this.load = false;
        this.openModal(content);
      },
      error => {
        this.load = false;
        Swal.fire({
          type: 'error',
          title: 'Error Occured',
          text: 'While Fetching Replica Ids for Cube',
          showCancelButton: false,
          confirmButtonText: 'Ok'
        })
      }
    )
  }

  changeCluster() {
    this.load = true;
    const data = this.assignments[0];

    this.replicaSets.forEach(item => {

      if (data[item]) {
        this.clusterData = {
          assignments: {
            [this.selectedReplica]: data[item]
          },
          cube_name: this.cubeName
        }
      }
    })

    this.streamingService.reAssign(this.cubeName, this.clusterData).subscribe(
      res => {
        this.load = false;
        Swal.fire({
          type: 'success',
          title: 'Success',
          text: 'Receiver Cluster Changed Successfully for the Cube',
          confirButtonText: 'Ok'
        }).then(result => {
          this.getCubeAssignmentDetails();
        })
       
      },
      error => {
        this.load = false;
        Swal.fire({
          type: 'error',
          title: 'Error Occured',
          text: error.error.msg,
          showCancelButton: false,
          confirmButtonText: 'Ok'
        })
      }
    )
  }

  openModal(content) {

    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
      this.changeCluster();
    }, (reason) => {
      console.log(`Dismissed ${this.getDismissReason(reason)}`)
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

}
