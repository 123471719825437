import { Component, OnInit } from '@angular/core';
import { CommonServiceService } from '../../services/common-services/common-service.service';


@Component({
  selector: 'app-administration',
  templateUrl: './administration.component.html',
  styleUrls: ['./administration.component.scss']
})
export class AdministrationComponent implements OnInit {
  loggedUser;

  constructor(public commonServiceService:CommonServiceService) {
    this.loggedUser = JSON.parse(sessionStorage.getItem("loggedUser"));
   }

  ngOnInit() {
    //this.saveLogs();
  }

  
  saveLogs(){
    var today = new Date();
    let param={
      username:this.loggedUser.username,
      targetPage:'administration',
      day:today.getDay(),
      month:today.getMonth(),
      year:today.getFullYear()
    };
    this.commonServiceService.saveLogs(param).subscribe(
      data => {
  
      });
  }

}
