export class CubeConfig {
  modelObj:Object;
  measureParamType= ['column', 'constant'];
  measureExpressions= ['SUM', 'MIN', 'MAX', 'COUNT', 'COUNT_DISTINCT',"TOP_N",'EXTENDED_COLUMN','PERCENTILE'];
  dimensionDataTypes= ["string", "tinyint", "int", "bigint", "date"];
  cubePartitionTypes= ['APPEND'];
  columnTypeEncodingMap={
    "numeric": [
      "dict"
    ],
    "bigint": [
      "boolean",
      "date",
      "time",
      "dict",
      "integer"
    ],
    "char": [
      "boolean",
      "date",
      "time",
      "dict",
      "fixed_length",
      "fixed_length_hex",
      "integer"
    ],
    "integer": [
      "boolean",
      "date",
      "time",
      "dict",
      "integer"
    ],
    "int4": [
      "boolean",
      "date",
      "time",
      "dict",
      "integer"
    ],
    "tinyint": [
      "boolean",
      "date",
      "time",
      "dict",
      "integer"
    ],
    "double": [
      "dict"
    ],
    "date": [
      "date",
      "time",
      "dict"
    ],
    "float": [
      "dict"
    ],
    "decimal": [
      "dict"
    ],
    "timestamp": [
      "date",
      "time",
      "dict"
    ],
    "real": [
      "dict"
    ],
    "time": [
      "date",
      "time",
      "dict"
    ],
    "long8": [
      "boolean",
      "date",
      "time",
      "dict",
      "integer"
    ],
    "datetime": [
      "date",
      "time",
      "dict"
    ],
    "smallint": [
      "boolean",
      "date",
      "time",
      "dict",
      "integer"
    ],
    "varchar": [
      "boolean",
      "dict",
      "fixed_length",
      "fixed_length_hex",
      "integer"
    ]
  };
  encoding=[      "boolean",
                  "dict",
                  "fixed_length",
                  "fixed_length_hex",
                  "integer"
              ];
  encoding_advanced=[      
    "boolean",                
    "dict",
    "date",
    "time",
    "integer"
          ];
  returnType= {
      measureDataTypes: [
      {name: 'INT', value: 'int'},
      {name: 'BIGINT', value: 'bigint'},
      {name: 'DECIMAL', value: 'decimal'},
      {name: 'DOUBLE', value: 'double'},
      {name: 'DATE', value: 'date'},
      {name: 'STRING', value: 'string'}
    ],
    distinctDataTypes: [
      {name: 'Error Rate < 9.75%', value: 'hllc(10)'},
      {name: 'Error Rate < 4.88%', value: 'hllc(12)'},
      {name: 'Error Rate < 2.44%', value: 'hllc(14)'},
      {name: 'Error Rate < 1.72%', value: 'hllc(15)'},
      {name: 'Error Rate < 1.22%', value: 'hllc(16)'},
      {name: 'Precisely (More Memory And Storage Needed)', value: 'bitmap'}
    ],
    topNTypes: [
      {name: 'Top 10', value: "topn(10)"},
      {name: 'Top 100', value: "topn(100)"},
      {name: 'Top 500', value: "topn(500)"},
      {name: 'Top 1000', value: "topn(1000)"},
      {name: 'Top 5000', value: "topn(5000)"},
      {name: 'Top 10000', value: "topn(10000)"}
    ]
  };

}
