import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import {ModelStepsService} from '../../../../../services/model/model-steps.service';
import swal from 'sweetalert2';


@Component({
  selector: 'app-model-dimensions',
  templateUrl: './model-dimensions.component.html',
  styleUrls: ['./model-dimensions.component.scss']
})
export class ModelDimensionsComponent implements OnInit {
projectId:any;
aliasName:any;
dimensionColumns=[];
dimensionColumns1=[];
tableServiceList:any=[];
dimensionList:any=[];
dimensionList1:any=[];
factTable:any=[];
factAliasName:any;
dimensionName:any=[];
editMode:any;
dimensionName1:any=[];
dropdownSettings = {};
dimensionLookup=[];
lookUps;
dbName:any;
col:any=[];
col1:any=[];
tableAlias:any=[];
lookupSettings = {};
hideNextButton: boolean = true;
  constructor(private modelStepsService: ModelStepsService, private route: ActivatedRoute, private router: Router) {
    if(this.modelStepsService.modelName ===  undefined || this,modelStepsService.modelDescription === undefined){
      this.projectId = this.route.snapshot.paramMap.get("projectId");
      this.router.navigate(['/projects/',this.projectId ,'models','designer','info']);
    } 
    
   }

  ngOnInit() {

    this.projectId = this.route.snapshot.paramMap.get("projectId");
    this.editMode = this.modelStepsService.editMode;
    this.factTable = this.modelStepsService.factTable;
    this.factAliasName = this.modelStepsService.factAliasName;
    this.aliasName = this.modelStepsService.aliasName;
    this.tableServiceList = this.modelStepsService.tableList;
    this.dbName =  this.modelStepsService.databaseName;
    this.getDimensionColumns(this.factTable);
    this.getDimensionDetails();
    this.getDimensionDetails1();
    

 this.dropdownSettings = {
  singleSelection: false,
  textField: "name",
  selectAllText: 'Select All',
  unSelectAllText: 'Unselect All',
  allowSearchFilter: true
};


this.lookupSettings = {
  singleSelection: false,
  textField: "name",
  selectAllText: 'Select All',
  unSelectAllText: 'Unselect All',
  allowSearchFilter: true
};

if(this.modelStepsService.dimensionName!==undefined){
  this.dimensionName = this.modelStepsService.dimensionName;
}

 if(this.modelStepsService.aliasName!==undefined){
  this.aliasName = this.modelStepsService.aliasName;
 }
  
  
 if(this.modelStepsService.lookUps!==undefined && this.modelStepsService.lookUps.length>0){
  this.lookUps=this.modelStepsService.lookUps;
  if(this.modelStepsService.dimensionLookup!==undefined && this.modelStepsService.dimensionLookup.length>0){

      this.dimensionLookup=this.modelStepsService.dimensionLookup;
      for(let param of this.modelStepsService.lookUps){
        if(!this.dimensionLookup.find(({alias}) =>param.alias===alias))
        {
        this.dimensionLookup.push({
          "alias":param.alias,
          "dimensionColumns1":this.getDimensionColumns1(param.alias)
        })
      }
      }
  }else{
  for(let param of this.modelStepsService.lookUps){

    this.dimensionLookup.push({
      "alias":param.alias,
      "dimensionColumns1":this.getDimensionColumns1(param.alias)
    })
    this.getDimensionColumns1(param.alias);
  }
  }
 }

if(this.modelStepsService.measureLookup){
  this.checkDims();
}
  }
  

 

  dimensionValiadation(){
    let dimension=[];
    let col=[];
    if(this.dimensionName !== undefined && this.dimensionName.length > 0){
     for(let param of this.dimensionName){
       if(param.name!==undefined){
        col.push(param.name);
       }
      
     }
     dimension.push({
      "table": this.modelStepsService.factAliasName,
      "columns": col
    });
   }
   
    for(let param of this.dimensionLookup){
      let col1=[];
      if(param.dimesions){

      for(let item of param.dimesions){
        if(item.name!==undefined){
          col1.push(item.name);
         }
      }
      
    }
    if(col1.length>0){
      dimension.push({
        "table": param.alias,
        "columns": col1
      });

      this.modelStepsService.dimensionName1 = dimension;

    }
    }
    if(dimension.length>0 || this.modelStepsService.lookUps){
      this.modelStepsService.dimension=dimension;
      this.loadMeasures(this.projectId);
    } else{
      swal({
        type:'warning',
        title: ' OOPS...',
        text:'No dimensions defined.',
        confirmButtonText: 'Ok',
      }).then((status) => {
      if(status.value==true){
        }
    }, (dismiss) => {
      if (dismiss === 'cancel') {
      }
      
    });
    }
  }

  

 getDimensionColumns(factTable){
 this.dimensionColumns= [];

  for (let [key, value] of Object.entries(this.tableServiceList)) {
    let res= <any>{};
    res=value;
    let databaseName = res.database + '.' + res.name;
    if(databaseName == factTable){
    
       for (let param of res.columns)
       {
        if(this.modelStepsService.metricName){
          let isMeasureColSame = this.modelStepsService.metricName.find(({name}) => param.name === name);
          if(!isMeasureColSame){
            this.dimensionColumns.push({"id":param.id,"name":param.name});
          }
        }
       else{
        this.dimensionColumns.push({"id":param.id,"name":param.name});
       }
        
       }
    }
    
    
 }

 if(this.dimensionColumns!==undefined){
  this.modelStepsService.dimensionColumns = this.dimensionColumns;
}

 }
checkDims(){

  for(let param of this.modelStepsService.measureLookup){
    let table=this.modelStepsService.dimensionLookup.find(({alias}) => param.alias === alias);
    if(param.measureName){
         for(let item of param.measureName){
      if(table.dimensionColumns1){
        let look=table.dimensionColumns1.find(({name}) => item.name === name);
        if(look)
        {
          let index=table.dimensionColumns1.indexOf(look)
          table.dimensionColumns1.splice(index,1);
        }
      }
     
    }
    }
 
  }

}
 getDimensionColumns1(aliasName){
  this.dimensionColumns1= [];
for (let [key, value] of Object.entries(this.tableServiceList)) {
let res= <any>{};
res=value;

let db = res.database + "." + res.name;
if(res.name == aliasName && res.database == this.modelStepsService.databaseName){

  for (let params of res.columns){
 
    
   this.dimensionColumns1.push({"id":params.id,"name":params.name});
     }
}else if(this.modelStepsService.lookUps!==undefined && this.modelStepsService.lookUps.length>0){
  
  for(let param of this.modelStepsService.lookUps){
    if(param.alias == aliasName){
      if(param.table == db){
        for (let params of res.columns){
 
          // let cols=this.modelStepsService.dimensionLookup.find(({alias}) =>tableName===alias)
          this.dimensionColumns1.push({"id":params.id,"name":params.name});
            }
      }
   
    }
  }
 
}
 
}

return this.dimensionColumns1;
}



 

 
//  getDimensionColumns1(aliasName){
//   this.dimensionColumns1= [];
// for (let [key, value] of Object.entries(this.tableServiceList)) {
// let res= <any>{};
// res=value;
// let db = res.database + "." + res.name;
// if(this.modelStepsService.lookUps!==undefined){
//   for(let param of this.modelStepsService.lookUps){
//     if(param.alias == aliasName && param.table == db){
//         for (let params of res.columns){
//           if(this.modelStepsService.metricName1!== undefined){
//             let isMeasureColSame = this.modelStepsService.metricName1.find(({name}) => params.name === name);
//             if(!isMeasureColSame){
//               this.dimensionColumns1.push({"id":params.id,"name":params.name});
//             }
//           }else{
//             this.dimensionColumns1.push({"id":params.id,"name":params.name});
//           }
//             }
     
//     }
//   }
 
// }
 
// }
// this.modelStepsService.dimensionColumns1 = this.dimensionColumns1;
// return this.dimensionColumns1;
// }



 getDimensionDetails(){
    this.dimensionList.push({"factAliasName":this.factAliasName});
  
}

getDimensionDetails1(){
  this.dimensionList1.push({"aliasName":this.aliasName});

}

loadMeasures(id){
  this.modelStepsService.aliasName =  this.aliasName;
  this.modelStepsService.dimensionColumns1 = this.dimensionColumns1;
  this.modelStepsService.dimensionLookup = this.dimensionLookup;
  this.modelStepsService.dimensionName = this.dimensionName;
  this.router.navigate(['/projects/',id,'models','designer','measures']);
}

    // This method navigate to model info page

    loadModelDesigner(id){
      this.modelStepsService.dimensionName = this.dimensionName;
      this.router.navigate(['/projects/',id,'models','designer','info']);
       }
   
       
     loadDataModel(id){
      this.modelStepsService.dimensionColumns1 = this.dimensionColumns1;
      this.modelStepsService.dimensionLookup = this.dimensionLookup;
      this.modelStepsService.aliasName =  this.aliasName;
      this.modelStepsService.dimensionName = this.dimensionName;
      this.router.navigate(['/projects/',id,'models','designer','data']);
     }
     //QUBZ-3728-In Model page inside dimension tab Next option enable 5/7/2022
     showNextBtn() {
      if(this.dimensionName.length>0){
        console.log(this.dimensionName);
        //console.log(this.dimensionColumns1);
        this.hideNextButton = false;
      }
      else {
        console.log(this.dimensionName);
        this.hideNextButton=true;
      }
     }
     //QUBZ-3728-In Model page inside dimension tab Next option enable 5/7/2022

 

}
