import { Injectable } from '@angular/core';
import { HttpClient,HttpParams } from '@angular/common/http';
import { Config } from '../../config';

@Injectable({
  providedIn: 'root'
})
export class DataCatalogTablesService {

  static url = Config.url + '/datacatalogs';
  constructor(private http: HttpClient) { }

  list(catalogId,page,size){
    const params = new HttpParams()
    .set('page', page)
    .set('size', size)
    return this.http.get(DataCatalogTablesService.url+'/'+catalogId+'/tables',{params});
    }
    listColumns(catalogId,tableId,page,size){
      const params = new HttpParams()
      .set('page', page)
      .set('size', size)
      return this.http.get(DataCatalogTablesService.url+'/'+catalogId+'/tables/'+tableId+'/columns',{params});
      }
      list_Userpage(catalogId,page,size){
        const params = new HttpParams()
        .set('page', page)
        .set('size', size)
        // .set('status', 'true')
        return this.http.get(DataCatalogTablesService.url+'/'+catalogId+'/tables',{params});
        }
        listColumns_Userpage(catalogId,tableId,page,size){
          const params = new HttpParams()
          .set('page', page)
          .set('size', size)
          // .set('status', 'true')
          return this.http.get(DataCatalogTablesService.url+'/'+catalogId+'/tables/'+tableId+'/columns',{params});
          }
      listAllColumns(catalogId,tableId){
      return this.http.get(DataCatalogTablesService.url+'/'+catalogId+'/tables/'+tableId+'/action-columns');
      }
      listAllColumns_userpage(catalogId,tableId){
      return this.http.get(DataCatalogTablesService.url+'/'+catalogId+'/tables/'+tableId+'/action-columns');
      }
      
      getTableDetails(catalogId,tableId){
       
        return this.http.get(DataCatalogTablesService.url+'/'+catalogId+'/tables/'+tableId);
        }
   updateTableDescription(dataCatalogId, dataCatalogTables) {
      return this.http.put(DataCatalogTablesService.url + '/' + dataCatalogId + '/tables/description' ,dataCatalogTables);
  }

  updateColumnDescription(dataCatalogId, tableId, dataCatalogColumns) {
      return this.http.put(DataCatalogTablesService.url + '/' + dataCatalogId + '/tables'  + '/' + tableId  + '/columns/description' ,dataCatalogColumns);
  }

  updateColumnStatus(dataCatalogId, tableId, columnId, dataCatalogColumns) {
      return this.http.put(DataCatalogTablesService.url + '/' + dataCatalogId + '/tables/'  + tableId + '/columns/' + columnId + '/status' ,dataCatalogColumns);
  }
  updateGroupColumnStatus(dataCatalogId, tableId, dataCatalogColumns) {
    return this.http.put(DataCatalogTablesService.url + '/' + dataCatalogId + '/tables/'  + tableId + '/columns/status' ,dataCatalogColumns);
}
  checkTableStatus(dataCatalogId, tableId, dataCatalogTable){
      return this.http.put(DataCatalogTablesService.url + '/' + dataCatalogId + '/tables' + '/' + tableId + '/status' ,dataCatalogTable);
  }
}
