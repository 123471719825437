import {
  Component,
  OnInit,
  AfterViewInit,
  ChangeDetectorRef,
} from "@angular/core";
import { CubesService } from "../../../../../services/cubes/cubes.service";
import { Router, ActivatedRoute } from "@angular/router";
import * as $ from "jquery";
import { TableService } from "../../../../../services/table/table.service";
import {
  CdkDragDrop,
  moveItemInArray,
  transferArrayItem,
} from "@angular/cdk/drag-drop";
import { NgSelectModule } from "@ng-select/ng-select";
import { FormArray, FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ValueTransformer } from "@angular/compiler/src/util";
import { CubeConfig } from "../../../../../models/cubes/cube-config";
import swal from "sweetalert2";
@Component({
  selector: "app-streaming-config",
  templateUrl: "./streaming-config.component.html",
  styleUrls: ["./streaming-config.component.scss"],
})
export class StreamingConfigComponent implements OnInit, AfterViewInit {
  projectId;
  editCube;
  errorShard;
  error;
  streamingConfig = {
    algorithm: "INMEM",
    window: 3600,
    duration: 3600,
    interval: 300,
    policy: 1,
    purgeretention: 86400,
  };
  tableInfo: any;
  constructor(
    private cubesService: CubesService,
    private cdr: ChangeDetectorRef,
    private tableService: TableService,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private router: Router
  ) {}

  ngAfterViewInit() {
    this.cdr.detectChanges();

    $(document).ready(function () {
      var contenth = $(window).height() - 110;
      var sidebarh = $(window).height() - 111;
      $(".pagec").css("height", contenth);
      $(".sidebar-wrapper").css("height", sidebarh);
    });

    function progressli_check() {
      $(".progresslic ").each(function () {
        if ($(".progressbar li ").length > 6) {
          $(".menublock").addClass("tabw_seven");
        }
      });
    }
    setTimeout(progressli_check, 100);
  }

  ngOnInit() {
    this.projectId = this.route.snapshot.paramMap.get("projectId");
    this.editCube = this.route.snapshot.paramMap.get("cubeId");
    if (this.cubesService.cubeMeta.dimensions.length == 0) {
      this.router.navigate([
        "/projects/" + this.projectId + "/cubes/designer/info",
      ]);
    }
    this.tableInfo = this.cubesService.tableInfo;
    if (
      this.cubesService.cubeMeta.override_qubz_properties["qubz.cube.algorithm"]
    ) {
      this.streamingConfig = {
        algorithm:
          this.cubesService.cubeMeta.override_qubz_properties[
            "qubz.cube.algorithm"
          ],
        window:
          this.cubesService.cubeMeta.override_qubz_properties[
            "qubz.stream.cube.window"
          ],
        duration:
          this.cubesService.cubeMeta.override_qubz_properties[
            "qubz.stream.cube.duration"
          ],
        interval:
          this.cubesService.cubeMeta.override_qubz_properties[
            "qubz.stream.index.checkpoint.intervals"
          ],
        policy:
          this.cubesService.cubeMeta.override_qubz_properties[
            "qubz.stream.segment.retention.policy"
          ] == "fullBuild"
            ? 1
            : 0,
        purgeretention:
          this.cubesService.cubeMeta.override_qubz_properties[
            "qubz.stream.segment.retention.policy.purge.retentionTimeInSec"
          ],
      };
    }
  }

  previousPage(page) {
    this.saveConfig();
    if (this.cubesService.editMode)
      this.router.navigate([
        "/projects/" +
          this.projectId +
          "/cubes/" +
          this.editCube +
          "/designer/" +
          page,
      ]);
    else
      this.router.navigate([
        "/projects/" + this.projectId + "/cubes/designer/" + page,
      ]);
  }
  buttonNext(page) {
    this.saveConfig();
    if (this.cubesService.editMode)
      this.router.navigate([
        "/projects/" +
          this.projectId +
          "/cubes/" +
          this.editCube +
          "/designer/overview",
      ]);
    else
      this.router.navigate([
        "/projects/" + this.projectId + "/cubes/designer/overview",
      ]);
  }
  saveConfig() {
    let override_qubz_properties = {};
    if (this.tableInfo.source_type == 20) {
      override_qubz_properties = {
        "qubz.stream.cube.window": this.streamingConfig.window,
        "qubz.stream.cube.duration": this.streamingConfig.duration,
        "qubz.stream.index.checkpoint.intervals": this.streamingConfig.interval,
        "qubz.cube.algorithm": this.streamingConfig.algorithm,
        "qubz.stream.segment.retention.policy": this.streamingConfig.policy
          ? "fullBuild"
          : "purge",
        "qubz.stream.segment.retention.policy.purge.retentionTimeInSec":
          this.streamingConfig.purgeretention,
      };
      if (this.streamingConfig.policy == 1)
        delete override_qubz_properties[
          "qubz.stream.segment.retention.policy.purge.retentionTimeInSec"
        ];
    }

    this.cubesService.cubeMeta.override_qubz_properties =
      override_qubz_properties;
  }
}
