import { Component, OnInit } from '@angular/core';
import { AuditService } from '../../../services/audit/audit.service';
import { CommonServiceService } from '../../../services/common-services/common-service.service';

@Component({
  selector: 'app-user-trail',
  templateUrl: './user-trail.component.html',
  styleUrls: ['./user-trail.component.scss']
})
export class UserTrailComponent implements OnInit {
  loggedUser;
  public logs:any;
  constructor(private auditService:AuditService, public commonServiceService:CommonServiceService) {
    this.loggedUser = JSON.parse(sessionStorage.getItem("loggedUser"));
   }

  ngOnInit() {
    this.getPageTrails();
    //this.saveLogs();
  }
 getPageTrails(){
  this.auditService.getLogs('admin').subscribe(
    (data) => {
      this.logs=data;
      console.log(this.logs)
    });
 }

 saveLogs(){
  var today = new Date();
  let param={
    username:this.loggedUser.username,
    targetPage:'administration/page-trails',
    day:today.getDay(),
    month:today.getMonth(),
    year:today.getFullYear()
  };
  this.commonServiceService.saveLogs(param).subscribe(
    data => {

    });
}
}
