import { Component, OnInit } from '@angular/core';
import { JobSchedulerService } from 'src/app/services/job-scheduler/job-scheduler.service';
import { ActivatedRoute } from '@angular/router';
import { ProjectService } from 'src/app/services/project/project.service';
import swal from 'sweetalert2';

@Component({
  selector: 'app-job-scheduler-view',
  templateUrl: './job-scheduler-view.component.html',
  styleUrls: ['./job-scheduler-view.component.scss']
})
export class JobSchedulerViewComponent implements OnInit {

  load: boolean = true;
  projectId: string;
  projectUUID: string;
  projectList: any[];
  jobSchList: Object;

  show_view_element;
  viewData;

  page: number = 0;
  totalPageSize: number = 10;
  jobScheCount: number;

  constructor(private jobSchedulerService: JobSchedulerService, private project: ProjectService, private route: ActivatedRoute) { }

  ngOnInit() {
    this.page = 1;
    this.projectId = this.route.snapshot.paramMap.get("projectId");
    this.getProjectUUID()

  }
  list(projectUUID: string, page, totalPageSize) {
    this.load = true;
    const params = {
      projectId: projectUUID,
      page: page,
      size: totalPageSize
    }
    this.jobSchedulerService.listAllScheduler(params).subscribe(
      (data: any) => {
        this.jobSchList = data.page.content;
        this.jobScheCount = data.page.totalElements;
        sessionStorage.setItem('JobList', JSON.stringify(this.jobSchList));
        for (let value of data.page.content) {
          //for (let param of value) {
          this.jobSchedulerService.validateCronString(value.cronString).subscribe(
            (data: any) => {
              let res = <any>{};
              res = data;
              value.nextSchedule = res.longList[0];
            });

          //}
        }
        this.load = false;
      })
  }
  /** 
   * getProjectUUID
   * Params: None
   * Objective: to retrieve a project uuid given the project name.
   * How: Iterates throught a project list created by calling the project service, makes a comparason with name and sets uuid
   * Returns: Nothing 
  */
  getProjectUUID() {
    this.project.list().subscribe((data: any[]) => {
      this.projectList = data;
      for (let proj of data) {
        if (proj.name === this.projectId) {
          this.projectUUID = proj.uuid;
        }
      }
      this.list(this.projectUUID, this.page - 1, this.totalPageSize)
    })
  }
  delete(schId: string) {
    swal({
      type: 'warning',
      title: 'Are you sure you want to delete this job schedule?',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No'
    }).then((status) => {
      if (status.value == true) {
        const params = {
          schedulerId: schId
        }
        this.jobSchedulerService.deleteScheduler(params).subscribe(
          data => {
            swal("Success!", 'Scheduled job was deleted.', "success");
            this.list(this.projectUUID, this.page - 1, this.totalPageSize)
          },
          error => {
            swal("Operation failed!!", 'Reason:' + '\n' + error.statusText, "error")
          }
        )
      }
    }, (dismiss) => {
      if (dismiss === 'cancel') { }
    });
  }
  onView(scheduler) {
    this.show_view_element = true;
    this.viewData = scheduler;

  }
  OnCancelView() {
    this.show_view_element = false;
  }

  toUTCDate(date) {
    var _utc = new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate(), date.getUTCHours(), date.getUTCMinutes(), date.getUTCSeconds());
    return _utc;
  };
  millisToUTCDate() {
    return this.toUTCDate(new Date());
  };
  millisToUTCDateS(scheduledTime) {
    return this.toUTCDate(new Date(scheduledTime));
  }
  status(schId: string, currentStatus: string) {
    let currStatus: string;
    if (currentStatus === "Enable") {
      currStatus = "ENABLED"
    } else {
      currStatus = "DISABLED"
    }
    swal({
      type: 'warning',
      title: '<span class="displayinlineb"> Are you sure you want to &nbsp;</span>' + '<span class="lowercase-imp displayinlineb">' + currentStatus + '</span>' + ' <span class="displayinlineb">&nbsp; this job schedule?</span>',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No'
    }).then((status) => {
      if (status.value == true) {
        const params = {
          status: [currStatus]
        }
        this.jobSchedulerService.updateShedulerStatus(schId, params).subscribe(
          data => {
            swal("Success!", 'Scheduled job was ' + currentStatus + 'd.', "success");
            this.list(this.projectUUID, this.page - 1, this.totalPageSize)
          },
          error => {
            swal("Operation failed!!", 'Reason:' + '\n' + error.statusText, "error")
          }
        )
      }
    }, (dismiss) => {
      if (dismiss === 'cancel') { }
    });
  }
  getJobSchePage(event: any) {

    this.page = event;
    this.list(this.projectUUID, this.page - 1, this.totalPageSize)
  }
}
