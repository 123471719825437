import { Injectable } from '@angular/core';
import { HttpClient,HttpParams } from '@angular/common/http';
import { Config } from '../../config';


@Injectable({
  providedIn: 'root'
})
export class AuditService {
  static url = Config.url + '/useraudit';

  constructor(private http: HttpClient) { }
  saveLogs(params) {
    return this.http.post(AuditService.url,params)
  }
  getLogs(user){
    return this.http.get(AuditService.url+'/username?username='+user)
  }
}
