import { Component, OnInit, AfterViewInit, NgZone } from '@angular/core';
import * as $ from 'jquery';
import { NotificationService } from '../../../../services/notifications/notification.service'

@Component({
  selector: 'app-basic-layout',
  templateUrl: './basic-layout.component.html',
  styleUrls: ['./basic-layout.component.scss']
})
export class BasicLayoutComponent implements OnInit,AfterViewInit {
  isSidenav: boolean=false;
  loggedUser: any;
  notifications: any;
  notificationCount: number = 0;
  accessNotification: any[] = [];
  jobNotification: any[] = [];
  constructor( private notificationService: NotificationService,private _ngZone: NgZone) { 
    window['angularComponentRef'] = {component: this, zone: _ngZone};

  }
  ngOnDestroy() {
    // window['angularComponentRef'] = null;
  }

  ngOnInit() {
    this.isSidenav=true;
    this.loggedUser=JSON.parse(sessionStorage.getItem("loggedUser"));
    this.userNotification();
    // this.notificationService.dataSource.subscribe(val=>{
    //   this.userNotification();
    // })
    // document.addEventListener("click", function(){
    //   window['angularComponentRef'].zone.run(() => {window['angularComponentRef'].component.userNotification();
    // });

    // });
    // setInterval(() => { this.userNotification(); }, 1000 * 30 );


  }
  userNotification =() =>{
    let params = {
      username: this.loggedUser.username
    }
    this.notificationService.getNotifications(params).subscribe(data => { 
      this.jobNotification = [];
      this.accessNotification = [];
      this.notifications = data;
      this.notificationCount = this.notifications.qubzNotificationList.length;
      this.bindNotification();
     });
    }
bindNotification(){
 if(this.notifications.qubzNotificationList){
  this.notifications.qubzNotificationList.filter(item=>{
    if(item.view === false){
      if(item.projectAccess === true && item.projectName){
        this.accessNotification = [...this.accessNotification,item];
      }
      if(item.jobId  && item.jobName ){
        this.jobNotification = [...this.jobNotification,item];
      }

    }
  })
 }

}
notificationClick(item){
  document.getElementById('closeNotificationBtn').click();

  this.notificationService.viewedNotifications(item.id).subscribe(data => { 
    this.userNotification();
  });
}
clearAllClick(){
  let params = {
    username: this.loggedUser.username,
    viewStatus: true
  };
  this.notificationService.clearAllNotifications(params).subscribe(data =>{
    this.userNotification();
  })
}
  ngAfterViewInit() {
    $(document).on("click", ".closeprofile", function(){
      $(".profilec").removeClass("profileshow");
    });

    $(document).ready(function(){
      var contenth = $( window ).height() - 110 ;
      var sidebarh = $( window ).height() - 111 ;
      $(".pagec").css("height", contenth );
      $(".sidebar-wrapper").css("height", sidebarh );
    });
  }

}
