import { Component, OnInit, Input, AfterViewInit} from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import swal from 'sweetalert2';
import {ModelStepsService} from '../../../../../services/model/model-steps.service';
import * as $ from 'jquery';

@Component({
	selector: 'app-model-edit-step-three',
	templateUrl: './model-edit-step-three.component.html',
	styleUrls: ['./model-edit-step-three.component.scss']
})
export class ModelEditStepThreeComponent implements OnInit,AfterViewInit {
	@Input() modelObj: any;
	@Input() tablesList: any;
	@Input () curStep:any;
	projectId: any;
	aliasName: any;
	dimensionColumns = [];
	dimensionColumns1 = [];
	tableServiceList: any = [];
	dimensionList: any = [];
	dimensionList1: any = [];
	factTable: any = [];
	factAliasName: any;
	dimensionName: any = [];
	dimensionName1: any = [];
	dropdownSettings = {};
	dbName: any;

	
	constructor(private route: ActivatedRoute, private modelStepsService: ModelStepsService) { }

	ngAfterViewInit() {
		$(document).ready(function(){
			  var contenth = $( window ).height() - 110 ;
			  var sidebarh = $( window ).height() - 111 ;
			  $(".pagec").css("height", contenth );
			  $(".sidebar-wrapper").css("height", sidebarh );
			});
		}
		
	ngOnInit() {

		this.projectId = this.route.snapshot.paramMap.get("projectId");
		this.factTable = this.modelObj.fact_table;
		this.factAliasName = this.removeNamespace(this.factTable);
		this.getDimensionTables(this.factTable);
		/*this.getDimensionDetails();*/
		this.dropdownSettings = {
  			singleSelection: false,
  			textField: "name",
  			selectAllText: 'Select All',
  			unSelectAllText: 'Unselect All',
  			allowSearchFilter: true
		};

	this.checkDims();
	}


	checkDims(){

		for(let param of this.dimensionList1){

			for(let cols of param.columns){
				let index=this.modelObj.metrics.indexOf(param.tableAlias+'.'+cols.name)
				
				if(index!=-1)
					{

						param.columns.splice(param.columns.indexOf(param.columns.find(({name}) =>cols.name===name)),1);
					}
			}
		}

	  
	  }
	getDimensionTables(factTable) {
		// adding to fact table columns
		this.dimensionList.push(
			{
				tableAlias: this.removeNamespace(factTable), 
				columns: this.getDimensionColumns(factTable)
			} 
		);
		// adding lookup table columns
		for (let table of this.modelObj.lookups) {
			let dims: any = this.getDimensionColumns1(table.table,table.alias);

						this.dimensionList1.push(
							{
								tableAlias: table.alias,
								columns: dims.list,
								selected: dims.selected
							}
						)
					
			
		}
		this.modelStepsService.dimensionList1 = this.dimensionList1;
	}


	getDimensionColumns(tableName) {
		let list = [];
		for (let table of this.tablesList) {
			if (tableName === table.name) {
				for (let col of table.columns) {
					if(!this.inMetrics(col)){
						if(this.isDimension(col, tableName)) {
							this.dimensionName.push({"id":col.id,"name":col.name});
						}
						list.push({ "id": col.id, "name": col.name });
					}	
				}
				return list;
			}
		}
		return [];
	}
	inMetrics(col){
		if(this.modelObj.metrics!== undefined && this.modelObj.metrics.length>0){
			for (let c of this.modelObj.metrics){
				if (this.removeNamespace(c) === col.name) {
					return true;
				}
			}
			return false
		}
	
		
	}
	getDimensionColumns1(tableName,alias) {
		let dims = {
			list: [],
			selected: []
		};
		for (let table of this.tablesList) {
			let name = table.name.split(".",3);
			let tableNm = tableName.split(".",3);
			if (tableName === table.name  && name[0]== tableNm[0]) {
				for (let col of table.columns) {
					if(this.isDimension(col, alias)) {
						dims.selected.push({"id":col.id,"name":col.name});
					
					}
					dims.list.push({ "id": col.id, "name": col.name });
					
				}
			
				
				return dims;
				
			}
		
		}
		return [];
	}
	isDimension(col, tableName) {
		for(let dim of this.modelObj.dimensions) {
			console.log(dim.table+'-'+this.removeNamespace(tableName));
			
			if	(dim.table === this.removeNamespace(tableName)) {
				for(let c of dim.columns) {
					if(col.name === c) {
						return true;
					}
				}
			}
		}
		return false;
	}
	getDimensionValues(event: any, tableAlias: string) {
		
	}
	updateModelObj(event: any,dimension, tableAlias: string){
		if(event!== undefined){
			if(this.modelObj.dimensions.length===0)
			this.modelObj.dimensions.push({
				table:tableAlias
			})
		
			for (let index = 0; index < this.modelObj.dimensions.length; index++ ) {
				if (this.modelObj.dimensions[index].table === tableAlias) {

					this.modelObj.dimensions[index].columns = [];
				
						for(let col of dimension) {
							this.modelObj.dimensions[index].columns.push(col.name);
							
					
					}
					
				}
			}
		}
	
	}
	updateModelObj2(event: any,dimension, tableAlias: string){
		if(event!== undefined){
			for (let index =0,j=1; index < this.dimensionList1.length; index++,j++ ) {
				this.modelObj.dimensions[j]={
					columns:this.modelObj.dimensions[j]?this.modelObj.dimensions[j].columns:[],
					table:this.dimensionList1[index].tableAlias
				}
			}
			for (let index =0 ;index < this.modelObj.dimensions.length; index++) {
				
				if (this.modelObj.dimensions[index].table === tableAlias) {

					this.modelObj.dimensions[index].columns = [];
					
					for(let col of dimension.selected) {
						this.modelObj.dimensions[index].columns.push(col.name);
						
					}
				}
				
			}
			
		}
	
	}
	 removeNamespace(str: string) {
		if (str) {
			return str.replace(/([^.\s]+\.)+/, '');
		} else {
			return ''
		}
	}


}
