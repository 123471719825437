import { Component, OnInit, Input , AfterViewInit} from '@angular/core';
import { TableService } from '../../../../../services/table/table.service';
import { Router, ActivatedRoute } from '@angular/router';
import * as $ from 'jquery';
@Component({
	selector: 'app-model-edit-step-four',
	templateUrl: './model-edit-step-four.component.html',
	styleUrls: ['./model-edit-step-four.component.scss']
})
export class ModelEditStepFourComponent implements OnInit,AfterViewInit {
	@Input() modelObj: any;
	@Input() tablesList: any;
	factTable: string;
	factAliasName: string;
	dropdownSettings: any;
	lookupMeasure = [];
	measuresList: any = {};
	tableServiceList;
	constructor(private tableService: TableService, private route: ActivatedRoute) { }

	ngAfterViewInit() {
		$(document).ready(function(){
			  var contenth = $( window ).height() - 110 ;
			  var sidebarh = $( window ).height() - 111 ;
			  $(".pagec").css("height", contenth );
			  $(".sidebar-wrapper").css("height", sidebarh );
			});
		}

	ngOnInit() {

		this.factTable = this.modelObj.fact_table;
		this.factAliasName = this.removeNamespace(this.factTable);
		this.getMeasureList();
		this.dropdownSettings = {
			singleSelection: false,
			textField: "name",
			selectAllText: 'Select All',
			unSelectAllText: 'Unselect All',
			allowSearchFilter: true
		};
		let params = {
			ext: 'true',
			project: this.route.snapshot.paramMap.get("projectId")
		}


		if (this.modelObj.lookups) {

			for (let param of this.modelObj.lookups) {

				if (param.kind == 'FACT') {
					this.lookupMeasure.push({
						"alias": param.alias,
						"columns": this.getMeasureColumns1(param.alias),
						"selected": this.getSelected(param.alias, param)
					})
				}
			}
		}
		this.updateModelObj();
	}

	getSelected(tableName, obj) {

		let messsure = [];
		for (let param of this.modelObj.metrics) {

			if (param.split('.', 2)[0] == tableName) {
				for (let table of this.tablesList) {
					if (obj.table == table.name) {

						let item = table.columns.find(({ name }) => param.split('.', 2)[1] === name)

						if (item) {
							messsure.push(item);
						}
					}
				}
				// messsure.push(param.split('.',2)[1]);
			}

		}
		return messsure;
	}
	getMeasureColumns1(tableName) {

		let measureColumns1 = [];
		let name;
		let primary_key1 = [];
		let primary_key = [];

		let item = this.modelObj.lookups.find(({ alias }) => tableName === alias)

		if (item) {
			name = item.table;
			primary_key1 = item.join.primary_key;
			for (let key of primary_key1) {
				primary_key.push(key.split('.', 2)[1]);
			}
		}
		let dims;

		for (let tables of this.tablesList) {
			if (tables.name == name) {
				dims = tables;
				break;
			}
		}

		let index = null
		if (dims) {


			for (let cols of dims.columns) {
				let look = this.modelObj.dimensions.find(({ table }) => tableName === table)

				if (look && look.columns) {
					if (look.columns.indexOf(cols.name) == -1 && primary_key.indexOf(cols.name) == -1) {
						measureColumns1.push({ "id": cols.id, "name": cols.name });
					}

				}

			}
		}
		return measureColumns1;
	}
	getMeasureList() {
		this.measuresList.selected = [];
		this.measuresList.columns = [];

		let list = this.setMeasures(this.factTable);
		this.measuresList.selected = list.selected;
		this.measuresList.columns = list.columns;
	}
	setMeasures(tableAlias: string) {
		let list = {
			selected: [],
			columns: []
		}
		for (let table of this.tablesList) {

			if (table.name === tableAlias) {
				for (let col of table.columns) {
					if (!this.inDimensions(col)) {
						if (this.isMeasure(col)) {
							list.selected.push({ "id": col.id, "name": col.name });
						}
						list.columns.push({ "id": col.id, "name": col.name });
					}
				}
			}


		}
		return list;
	}
	inDimensions(col: any) {

		for (let index = 0; index < this.modelObj.dimensions.length; index++) {
			if (this.modelObj.dimensions[index].table === this.factAliasName) {
				for (let dim of this.modelObj.dimensions[index].columns) {
					if (dim === col.name) {
						return true;
					}
				}
			}
		}
		return false;
	}
	isMeasure(col: any) {
		for (let measure of this.modelObj.metrics) {
			if (this.removeNamespace(measure) === col.name) {
				return true;
			}
		}
		return false;
	}
	removeNamespace(str: string) {
		if (str) {
			return str.replace(/([^.\s]+\.)+/, '');
		} else {
			return ''
		}
	}

	//update model object
	updateModelObj() {
		this.modelObj.metrics = [];
		for (let measure of this.measuresList.selected) {
			if (this.modelObj.metrics.indexOf(this.factAliasName + '.' + measure.name) == -1)
				this.modelObj.metrics.push(this.factAliasName + '.' + measure.name)
		}
		for (let lookup of this.lookupMeasure) {
			for (let measure of lookup.selected) {
				if (this.modelObj.metrics.indexOf(lookup.alias + '.' + measure.name) == -1)
					this.modelObj.metrics.push(lookup.alias + '.' + measure.name)
			}
		}

	}


}
