import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { map, catchError, tap } from 'rxjs/operators';
import { Config } from '../../config';

@Injectable({
  providedIn: 'root'
})
export class ProjectaccessService {
  constructor(private http: HttpClient) { }
  getProjectPermission(project): Observable<any> {
    return this.http.get(Config.url+'/access/user/permission/'+project
    ).pipe(
      map(res => res));
  }
  grandPermission(permission,type,uuid){
    return this.http.delete(Config.url+'/access/batch/'+type+'/'+uuid+'/'+permission);
  }
  revokeProjectPermission(permission,type,uuid){
    return this.http.delete(Config.url+'/access/'+type+'/'+uuid+'?accessEntryId='+permission.id+'&sid='+permission.sid.principal);
  }
  renewProjectAccessPermission(permission,type,uuid){
    return this.http.delete(Config.url+'/access/'+type+'/'+uuid+'/'+permission);
  }

  createProjectAccessPermission (permission,type,uuid): Observable<any> {
    return this.http.post(Config.url+'/access/'+type+'/'+uuid,permission);
  }
  updateProjectAccessPermission (permission,type,uuid): Observable<any> {
    return this.http.put(Config.url+'/access/'+type+'/'+uuid,permission);
  }
  getAccessList(type,uuid){
    return this.http.get(Config.url+'/access/'+type+'/'+uuid
    ).pipe(
      map(res => res));
  }
}
