import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-extend-information',
  templateUrl: './extend-information.component.html',
  styleUrls: ['./extend-information.component.scss']
})
export class ExtendInformationComponent implements OnInit {

  @Input() selectedTable;

  constructor() { }

  ngOnInit() {
  }

}
