import { Injectable } from '@angular/core';
import { HttpClient,HttpParams } from '@angular/common/http';
import { Config } from '../../config';

@Injectable({
  providedIn: 'root'
})
export class DataAccessService {

  static url = Config.url + '/dataaccess';

  constructor(private http: HttpClient) { }

  deleteAllAccess(){
    return this.http.delete(DataAccessService.url);
  }
  getAccessList(page,size,projectName,databaseName,tableName,columnName){
    const params = new HttpParams()
      .set('page', page)
      .set('size', size)
      .set('projectName', projectName)
      .set('databaseName', databaseName)
      .set('tableName', tableName)
      .set('columnName', columnName);
      return this.http.get(DataAccessService.url,{params});
  }
  getAccess(page,size){
    const params = new HttpParams()
      .set('page', page)
      .set('size', size);
     
      return this.http.get(DataAccessService.url,{params});
  }
  deleteAccessById(accessId){
    return this.http.delete(DataAccessService.url+'/'+accessId);
  }
  getAccessById(accessId){
    const params = new HttpParams()
      .set('accessId', accessId);
      return this.http.get(DataAccessService.url,{params});
  }
  updateAccessStatus(accessId,dataAccess){
      return this.http.put(DataAccessService.url+'/'+accessId+'/status',dataAccess);
  }
}
