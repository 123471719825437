import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SidebarService } from '../../../services/sidebar/sidebar.service';
@Component({
  selector: 'app-project-side-nav',
  templateUrl: './project-side-nav.component.html',
  styleUrls: ['./project-side-nav.component.scss']
})
export class ProjectSideNavComponent implements OnInit {
  items;
  project;
  menuType;
  constructor(private route: ActivatedRoute,private router:Router,private sidebarService:SidebarService) {
  
  }

  ngOnInit() {
    this.items = this.sidebarService.projectItems;
    this.project=this.route.snapshot.paramMap.get("projectId");
  }
  
  hasRole(roles){
    let user=JSON.parse(sessionStorage.getItem("loggedUser"));
    for(let role of user.authorities){
      if(role.authority==roles)
        return 'admin';
    }
    return 'user';
  }
}
