import {
  AfterViewInit,
  Component,
  EventEmitter,
  OnInit,
  Output,
} from "@angular/core";
import { QueryService } from "src/app/services/query/query.service";
import { ActivatedRoute } from "@angular/router";
import * as $ from "jquery";

@Component({
  selector: "app-cube-tree",
  templateUrl: "./cube-tree.component.html",
  styleUrls: ["./cube-tree.component.scss"],
})
export class CubeTreeComponent implements OnInit, AfterViewInit {
  projectId: string;
  databases: any[];
  dbList: string[] = [];
  tableList: any[] = [];
  isActive: any[] = [];
  tableIsActive: any[] = [];
  load;
  @Output() treeData: EventEmitter<any> = new EventEmitter();
  constructor(
    private queryService: QueryService,
    private route: ActivatedRoute
  ) {}

  ngAfterViewInit() {
    $(document).ready(function () {
      function treeheight() {
        var pageheight = $(window).height() - 220;
        var menuheight = $(window).height() - 320;
        $(".menublock_scroll").css("height", menuheight);
      }
      setTimeout(treeheight, 1000);
    });

    $("body").on("click", ".menublock_scroll", function () {
      var pageheight = $(window).height() - 220;
      var menuheight = $(window).height() - 320;
      $(".menublock_scroll").css("height", menuheight);
    });

    $(window).resize(function () {
      function treeheight() {
        var pageheight = $(window).height() - 220;
        $(".menublock_scroll").css("height", pageheight);
      }
      setTimeout(treeheight, 4);
    });
  }

  ngOnInit() {
    this.projectId = this.route.snapshot.paramMap.get("projectId");
    this.getTableMetaData();
  }
  getTableMetaData() {
    this.load = true;
    const params = {
      project: this.projectId,
    };
    this.queryService.getTableMetaData(params).subscribe((data: any[]) => {
      this.databases = data;
      this.treeData.emit(data);
      this.databases.forEach((item) => {
        item.columns.map((ele) => {
          if (ele.type_NAME.includes("VARCHAR(256)") === true)
            ele.new_TYPE = "VARCHAR(256)";
          else ele.new_TYPE = ele.type_NAME;
        });
      });
      for (var i = 0; i < data.length; i++) {
        if (!this.dbList.includes(this.databases[i].table_SCHEM)) {
          this.dbList.push(this.databases[i].table_SCHEM);
        }
      }
      this.load = false;
    });
  }
}
