import { Component, OnInit, AfterViewInit } from "@angular/core";
import { QueryService } from "src/app/services/query/query.service";
import { OrderPipe } from "ngx-order-pipe";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { Router } from "@angular/router";
import { ProjectService } from "src/app/services/project/project.service";
import { UserManagementService } from "src/app/services/users/user-management.service";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import * as $ from "jquery";

interface Param {
  timeFilter: number;
  page: number;
  size: string;
  queryStatus?: string[];
  projectName?: string;
  userName?: string;
  duration?: any;
}

const URL = "/administration/query";
@Component({
  selector: "app-query-admin",
  templateUrl: "./query-admin.component.html",
  styleUrls: ["./query-admin.component.scss"],
})
export class QueryAdminComponent implements OnInit, AfterViewInit {
  expand: boolean = false;
  collapse: boolean = false;
  isLoading: boolean = false;
  public queryResponse: any;
  public queryList: any[] = [];
  public page: number;
  public timeFilter: number;
  public allChecked: boolean = false;
  public isSlowQuery: boolean = false;
  public deleteQueryList: any[] = [];
  public selectedQuery: any;

  public order: string = "name";
  public reverse: boolean = false;
  public sortedCollection: any[] = [];

  public form = new FormGroup({
    durationValue: new FormControl(null, [
      Validators.pattern(/^-?(0|[1-9]\d*)?$/),
      Validators.required,
    ]),
  });

  public requestParam: Param;
  duration_dd = [
    { value: 0, label: "CURRENT DAY" },
    { value: 1, label: "LAST ONE WEEK" },
    { value: 2, label: "LAST ONE MONTH" },
    { value: 3, label: "LAST SIX MONTH" },
    { value: 4, label: "ALL" },
  ];

  selectedProjectName: string;
  projectnames: any;

  selectedUserName: string;
  usernameList: any;

  durationList: number;
  durations = [
    { id: 1, name: "Duration one" },
    { id: 2, name: "Duration Two" },
    { id: 3, name: "Duration Three" },
    { id: 4, name: "Duration Four" },
  ];

  selectedStatus: string[] = [];
  statusList: any[] = [];

  constructor(
    private queryService: QueryService,
    private orderPipe: OrderPipe,
    private router: Router,
    private projectService: ProjectService,
    private userService: UserManagementService
  ) {
    this.sortedCollection = orderPipe.transform(this.queryList, "query");
    this.sortedCollection = orderPipe.transform(this.queryList, "username");
    this.sortedCollection = orderPipe.transform(this.queryList, "project");
    this.sortedCollection = orderPipe.transform(this.queryList, "queryStatus");
    this.sortedCollection = orderPipe.transform(this.queryList, "startTime");
    this.sortedCollection = orderPipe.transform(this.queryList, "endTime");
  }

  ngAfterViewInit() {
    $(document).ready(function () {
      var contenth = $(window).height() - 110;
      var sidebarh = $(window).height() - 111;
      $(".pagec").css("height", contenth);
      $(".sidebar-wrapper").css("height", sidebarh);

      var maxLength = 50;
      $(".tooltip_adj").each(function () {
        var myStr = $(this).text();
        if ($.trim(myStr).length > maxLength) {
          var newStr = myStr.substring(0, maxLength);
          var removedStr = myStr.substring(maxLength, $.trim(myStr).length);
          $(this).empty().html(newStr);
          $(this).append(' <span class="read-more">...</span>');
          $(this).parent(".tooltip_adj_c").addClass("show_tt");
        }
      });
      $(".read-more").click(function () {
        $(this).siblings(".more-text").contents().unwrap();
        $(this).remove();
      });
    });
  }

  ngOnInit() {
    this.page = 1;
    this.timeFilter = 0;
    this.requestParam = {
      page: this.page - 1,
      size: "10",
      timeFilter: this.timeFilter,
    };

    this.statusList = [
      { id: 0, name: "CANCELLED", count: 0, check: false },
      { id: 1, name: "COMPLETED", count: 0, check: false },
      { id: 2, name: "FAILED", count: 0, check: false },
      { id: 3, name: "RUNNING", count: 0, check: false },
    ];
    this.getQueryList(this.requestParam);
    this.getProjectList();
    this.getUserList();
  }

  get f() {
    return this.form.controls;
  }

  getQueryList(params: Param) {
    this.isLoading = true;
    this.queryService.getAllApplicationQuery(params).subscribe(
      (response) => {
        this.queryResponse = response;
        this.queryList = response.content.map((item) => {
          return {
            ...item,
            endTime: item.startTime + item.duration,
            duration: (item.duration % 60000) / 1000,
          };
        });

        params = {
          ...params,
          queryStatus: [],
        };

        this.queryService.getQueryOverView(params).subscribe((res) => {
          this.statusList = Object.keys(res).map((item) => {
            const status = this.statusList.find((f) => f.name === item);
            return {
              ...status,
              count: res[item],
            };
          });

          this.isLoading = false;
        });
      },
      (error) => {
        this.isLoading = false;
        this.queryList = [];
      }
    );
  }

  refreshTable() {
    this.getQueryList(this.requestParam);
  }

  viewQuery(query: any) {
    this.router.navigateByUrl(`${URL}/${query.queryId}`);
  }

  periodFilter(event) {
    console.log(event);
    this.timeFilter = event.value;
    this.page = 1;
    this.requestParam = {
      ...this.requestParam,
      timeFilter: this.timeFilter,
      page: 0,
    };

    this.getQueryList(this.requestParam);
  }

  statusFilter(event, status: string) {
    const i = this.statusList.findIndex((f) => f.name === status);
    if (event.target.checked) {
      this.selectedStatus.push(status);
      this.statusList[i].check = true;
    } else {
      this.selectedStatus = this.selectedStatus.filter((f) => f !== status);
      this.statusList[i].check = false;
    }

    this.page = 1;
    this.requestParam = {
      ...this.requestParam,
      page: this.page - 1,
      queryStatus: this.selectedStatus,
    };

    this.getQueryList(this.requestParam);
  }

  projectFilter(event: any) {
    this.requestParam = {
      ...this.requestParam,
      page: 0,
      projectName: event.name,
    };
    this.page = 1;
    this.getQueryList(this.requestParam);
  }

  userFilter(event: any) {
    this.requestParam = {
      ...this.requestParam,
      page: 0,
      userName: event.name,
    };
    this.page = 1;
    this.getQueryList(this.requestParam);
  }

  clearValue(key: string) {
    delete this.requestParam[key];

    this.getQueryList(this.requestParam);
  }

  enableDurationFilter(event) {
    if (event.target.checked) {
      this.isSlowQuery = true;
    } else {
      if (this.form.valid) {
        this.page = 1;
        delete this.requestParam.duration;
        this.requestParam.page = this.page - 1;
        this.form.reset();
        this.isSlowQuery = false;
        this.getQueryList(this.requestParam);
      } else {
        this.form.reset();
        this.isSlowQuery = false;
      }
    }
  }

  durationFilter() {
    if (this.form.valid) {
      this.requestParam = {
        ...this.requestParam,
        page: 0,
        duration: this.form.controls.durationValue.value,
      };
      this.page = 1;
      if (this.requestParam.duration) {
        this.getQueryList(this.requestParam);
      }
    }
  }

  getProjectList() {
    this.projectService.listReadable().subscribe((data: any[]) => {
      this.projectnames = data.map((item) => {
        const value = {
          id: item.uuid,
          name: item.name,
        };

        return value;
      });
    });
  }

  getUserList() {
    this.userService.userList(0, "10").subscribe((user: any) => {
      this.usernameList = user.qubzUserList.map((item: any) => {
        const value = {
          id: item.uuid,
          name: item.username,
        };

        return value;
      });
    });
  }

  getList(nextPage: any) {
    if (nextPage && nextPage <= this.queryResponse.totalPages) {
      this.page = nextPage;
      this.requestParam.page = nextPage - 1;
      this.getQueryList(this.requestParam);
    }

    $(document).ready(function () {
      setTimeout(function () {
        var maxLength = 50;
        $(".tooltip_adj").each(function () {
          var myStr = $(this).text();
          if ($.trim(myStr).length > maxLength) {
            var newStr = myStr.substring(0, maxLength);
            var removedStr = myStr.substring(maxLength, $.trim(myStr).length);
            $(this).empty().html(newStr);
            $(this).append(' <span class="read-more">...</span>');
            $(this).parent(".tooltip_adj_c").addClass("show_tt");
          }
        });
        $(".read-more").click(function () {
          $(this).siblings(".more-text").contents().unwrap();
          $(this).remove();
        });
      }, 5000);
    });
  }

  cancelApi(event, query: any) {
    if (query.queryStatus === "RUNNING") {
      this.queryService.cancelQueryStatus(query.queryId).subscribe(
        (res) => {
          Swal.fire({
            type: "success",
            title: "Query Cancellation Successfully",
            text: "Query Has Been Cancelled Successfully",
            confirmButtonText: "Ok",
          }).then((status) => {
            if (status.value) {
              this.getQueryList(this.requestParam);
            }
          });
        },
        (error) => {
          Swal.fire({
            type: "error",
            title: " OOPS...",
            text: error.error.msg,
            confirmButtonText: "Ok",
          }).then(
            (status) => {
              if (status.value == true) {
              }
            },
            (dismiss) => {
              if (dismiss === "cancel") {
              }
            }
          );
        }
      );
    } else {
      event.preventDefault();
    }
  }

  selectAllQuery(event) {
    this.allChecked = !this.allChecked;
    if (event.target.checked) {
      this.deleteQueryList = this.queryList.map((item) => item.queryId);
    } else {
      this.deleteQueryList = [];
    }
  }

  selectQuery(event, queryId: any) {
    if (event.target.checked) {
      this.deleteQueryList.push(queryId);
    } else {
      this.deleteQueryList = this.deleteQueryList.filter((q) => q !== queryId);
    }
  }

  deleteSelectedQuery(event: any) {
    if (this.deleteQueryList.length) {
      Swal.fire({
        type: "warning",
        title: "Do you want to Delete Selected Queries ?",
        showCancelButton: true,
        cancelButtonText: "No",
        confirmButtonText: "Yes",
      }).then(
        (result) => {
          if (result.value == true) {
            this.isLoading = true;
            this.queryService
              .deleteQueryAdministration(this.deleteQueryList)
              .subscribe((res) => {
                this.deleteQueryList.forEach((id) => {
                  const index = this.queryList.findIndex(
                    (item) => item.queryId === id
                  );
                  this.queryList.splice(index, 1, 0);
                });
                this.isLoading = false;
                //QUBZ-3859 7/9/22 In query administration page Parent check box should be unselect
                this.allChecked = false;
                this.getQueryList(this.requestParam);
                Swal("Success!", "Query has been Deleted", "success");
              });
          }
        },
        (dismiss) => {
          if (dismiss == "cancel") {
            return;
          }
        }
      );
    } else {
      event.preventDefault();
    }
  }

  setOrder(value: string) {
    if (this.order === value) {
      this.reverse = !this.reverse;
    }

    this.order = value;
  }
}
