import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { HttpClient, HttpParams, HttpEvent, HttpRequest } from '@angular/common/http';
import { Config } from '../../config';
import { Observable } from 'rxjs';
import { map } from "rxjs/operators";
@Injectable({
  providedIn: 'root'
})
export class QueryService {
  private _queryChanged = new BehaviorSubject<boolean>(false);
  queryUpdated$ = this._queryChanged.asObservable();
  static url = Config.url + '/query';
  private userSource = new BehaviorSubject({});
  tabViewQuery: any[];
  history = this.userSource.asObservable();
  public ddlcubeName: any;
  constructor(private http: HttpClient) { }

  cancelQueryStatus(queryId: any): Observable<any> {
    return this.http.put(Config.url + '/query/' + queryId + '/cancel', {})
  }

  deleteQueryAdministration(queryIds: any[]): Observable<any> {
    return this.http.delete(Config.url + '/queries_history/' + queryIds)
  }
  getQueryHistory(params) {
    //return this.http.get(Config.url + '/queries_history', { params });
    return this.http.get(Config.url + '/paginated_queries_history', { params });
  }

  getAllApplicationQuery(params): Observable<any> {
    return this.http.get(Config.url + '/queries_history_application', { params })
  }

  getQueryByID(id: string): Observable<any> {
    const params = {
      queryId: id
    };

    return this.http.get(Config.url + '/getQueryDetails', { params })
  }

  getQueryOverView(params): Observable<any> {
    return this.http.get(Config.url + '/queries_history_overview', { params })
  }

  getHistory() {
    return this.history;
  }
  savehistory(history) {

    this.userSource.next(localStorage.getItem('saved_queries'));
  }

  getQueryDetails(params, currentTab) {
    if (currentTab == 4) {
      return this.http.post(QueryService.url + '/pushdown', params);
    } else {
      return this.http.post(QueryService.url + '/generate_id', { ...params, source: 'REST' });
      //return this.http.post(QueryService.url , {...params, source: 'REST'});
    }
  }

  getResult(queryId) {
    return this.http.get(QueryService.url + '/' + queryId + '/execute');
  }

  // getQueryResult(params,currentTab){
  //   if(currentTab==4){
  //     return this.http.post(QueryService.url+'/pushdown',params);
  //   }else{
  //     return this.http.post(QueryService.url,params);
  //   }


  // }
  getQueryPushdownResult(params, getQueryResult) {
    return this.http.post(QueryService.url + '/pushdown', params);
  }
  /** 
  downloadQueryResult(format,sql,project,username,offset,limit,acceptPartial){
    const params = new HttpParams()
      .set('format', format)
      .set('sql', sql)
      .set('project', project)
      .set('username', username)
      .set('offset', offset)
      .set('limit', limit)
      .set('acceptPartial', acceptPartial);
    return this.http.get(QueryService.url,{params});
  }*/
  downloadQueryResult(params) {
    return this.http.get(QueryService.url, { params });
  }
  prepareQuery(query) {
    return this.http.post(QueryService.url + '/prestate', query);
  }
  getRunningQueries(time) {
    const params = new HttpParams()
      .set('runTimeMoreThan', time);
    return this.http.get(QueryService.url + '/runningQueries', { params });
  }
  stopQuery(queryId, query) {
    return this.http.put(QueryService.url + '/' + queryId + '/cancel', query);
  }
  getSavedQueries(params) {
    //return this.http.get(Config.url + '/queries_saved', { params });
    return this.http.get(Config.url + '/paginated_queries_saved', { params });
  }
  // getSavedQueries(params){
  //   return this.http.get(Config.url+'/saved_queries',{params});
  // }
  saveQueryByProject(query) {
    return this.http.post(Config.url + '/queries_saved', query).pipe(map((response: Response) => {
      this._queryChanged.next(true);
    }));
  }
  // saveQueryByProject(query){
  //   return this.http.post(Config.url+'/saved_queries',query).pipe(map((response: Response) => {
  //     this._queryChanged.next(true);
  //           }));
  // }
  removeQuery(queryId) {
    return this.http.delete(Config.url + '/queries_saved/' + queryId);
  }
  // removeQuery(queryId){
  //   return this.http.delete(Config.url+'/saved_queries/'+queryId);
  // }
  getTableMetaData(params) {
    return this.http.get(Config.url + '/tables_and_columns', { params });
  }

  deleteQueryHistoryAll(params) {
    return this.http.delete(Config.url + '/queries_history/dropall', { params });
  }

  deleteQueryHistory(queryIds) {
    return this.http.delete(Config.url + '/queries_history/' + queryIds);
  }


}
