import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Config } from '../../config';

@Injectable({
  providedIn: 'root'
})
export class JobService {

  static url = Config.url + '/jobs';

  constructor(private http: HttpClient) { }

  getJobs(params) {
    return this.http.get(JobService.url + '/pagination', { params })
  }
  getJobCount(params) {
    return this.http.get(JobService.url + '/overview', { params })
  }
  getJobById(jobId) {
    return this.http.get(JobService.url + '/' + jobId);
  }

  getDiagnosis(jobId) {
    this.http.get(Config.url + '/diag/job/' + jobId + '/download')
  }

  cancelJob(jobId) {
    return this.http.put(JobService.url + '/' + jobId + '/cancel/', {});
  }

  deleteJob(jobId) {
    return this.http.delete(JobService.url + '/' + jobId + '/drop/', {});
  }
  pauseJob(jobId) {
    return this.http.put(JobService.url + '/' + jobId + '/pause/', {});
  }
  resumeJob(jobId) {
    return this.http.put(JobService.url + '/' + jobId + '/resume/', {});
  }
  getJobStepOut(jobId, stepId) {
    return this.http.get(JobService.url + '/' + jobId + '/steps/' + stepId + '/output');
  }
  rollbackJob(jobId, stepId, jobDetails) {
    return this.http.put(JobService.url + '/' + jobId + '/steps/' + stepId + '/rollback', jobDetails);
  }
  deleteMultipleJobs(jobs) {
    return this.http.delete(JobService.url + '/' + jobs + '/dropall/', {});
  }
}
