import { Component, OnInit } from '@angular/core';
import { DataRequestService } from '../../../services/data-access/data-request.service';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, FormArray, FormControl, Validators } from "@angular/forms";
import swal from 'sweetalert2';
import { NgForm } from "@angular/forms";
import { FormsModule } from '@angular/forms';
import { OrderPipe } from 'ngx-order-pipe';
@Component({
  selector: 'app-data-catalog-requests',
  templateUrl: './data-catalog-requests.component.html',
  styleUrls: ['./data-catalog-requests.component.scss']
})
export class DataCatalogRequestsComponent implements OnInit {
  dataList;
  currentPage = 1;
  contentList:any;
  dataRequest;
  page = 1;
  pageNumber:number;
  p: number = 1;
  totalElements;
  order: string = 'dataRequest.requestedDate';  
  reverse: boolean = false;
  sortedCollection;
  constructor(private formBuilder: FormBuilder, private dataRequestService: DataRequestService, private router: Router,private orderPipe: OrderPipe,) {

  }
  viewDataRequestDetails(dataRequest){
    sessionStorage.setItem('dataRequest', JSON.stringify(dataRequest));
    this.router.navigate(["requests/details"]);
  }
  ngOnInit() {
    this.listRequests(this.page);
  }
  setOrder(value: string) {
    if (this.order === value) {
      this.reverse = !this.reverse;
    }

    this.order = value;
  }
  listRequests(pageNumber){
    pageNumber=pageNumber-1;
    let param={
      page: pageNumber,
      size:'10',
      isCompleted:'',
      projectId:''
    }
    this.dataRequestService.getRequestList(param.page,param.size,param.isCompleted,param.projectId).subscribe(data => {
      this.dataList = data;
      this.contentList = this.dataList.page.content;
      this.totalElements=this.dataList.page.totalElements;
     
    });
  }
}
