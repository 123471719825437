import { Component, OnInit } from '@angular/core';
import { DataCatalogService } from '../../../services/data-catalog/data-catalog.service';
import { ProjectDataCatalogService } from '../../../services/data-catalog/project-data-catalog.service';
import { Router } from '@angular/router';
import swal from 'sweetalert2';
import { Config } from '../../../config';
@Component({
  selector: 'app-catalog-user-dash-board',
  templateUrl: './catalog-user-dash-board.component.html',
  styleUrls: ['./catalog-user-dash-board.component.scss']
})
export class CatalogUserDashBoardComponent implements OnInit {
  catalogs:any;
  catalogDetails:any;
  searchType:any;
  search_key:any;
  search_filter_key:any;
  cartCount;
  page=1;
  load;
  configUrl=Config.url;
  loggedUser;
  totalSorce=0;
  constructor(private dataCatalogService:DataCatalogService,private projectDataCatalogService:ProjectDataCatalogService,private router:Router) { }

  ngOnInit() {
    this.loggedUser=JSON.parse(sessionStorage.getItem("loggedUser"));
    this.getCatalogs(1);
    this.search_filter_key='Filter By Name';
    this.cartCount=this.dataCatalogService.cart.length;
 
  }
  getCatalogs(pageNumber){
    pageNumber=pageNumber-1;
    this.load=true;
      this.dataCatalogService.listAll(pageNumber,6,this.search_key,this.searchType).subscribe(
        data=>{
          let res= <any>{};
          res=data;
          this.catalogs=res.page.content;
          this.catalogDetails=res.page;
          this.totalSorce= this.catalogDetails.totalElements;
          this.load=false;
        });
  }
  syncDatabase(){
    this.load=true;
     this.dataCatalogService.synchronize(null).subscribe(
      data=>{
        this.load=false;
        swal("Success!",'Data Catalog has been synchronized', "success");
    }, (error) => {
     
      swal("Failed",error.error.msg, "error");
    }); 
  }
  isUserAdmin(authorities) {
    
    for (let authority of authorities){
      if ((authority["authority"] == "ROLE_ADMIN")) {
      return true;
      } 
    }
    return false;
    
  }
  openCatalogDetails(uuid)
  {
    this.router.navigate(['/data-catalog/catalog-tables/'+uuid]);
  }

  setFilterKey(type,value){
    this.searchType=type;
    this.search_filter_key=value;
    if(type=='status')
      this.search_key=true;
    else if(type=='sourceType')
    this.search_key='hive';
    else
    this.search_key=null;
  }
  clearSearch(){
    this.search_filter_key='Filter By Name';
    this.searchType=null;
    this.search_key='';
    this.getCatalogs(1);
  }
  viewCart(){
    this.router.navigate(['/data-catalog/cart']);
  }
}
