import { Component, Input, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { UserSettingsService } from "../../../services/users/user-settings.service";
import { AuthService } from "src/app/services/auth/auth.service";
import { Subscription } from "rxjs";
import { TemplateServiceService } from "../../../services/template/template-service.service";
@Component({
  selector: "app-navigation",
  templateUrl: "./navigation.component.html",
  styleUrls: ["./navigation.component.scss"],
})
export class NavigationComponent implements OnInit {
  @Input() data: number;
  userData;
  imgURL: any;
  isActive: boolean = false;
  subscription: Subscription;
  qubzLicenseExpire;
  expiry;
  @Input() notificationCount: number;
  constructor(
    private router: Router,
    private userSettingService: UserSettingsService,
    private authService: AuthService,
    private templateServiceService: TemplateServiceService
  ) {
    let user = sessionStorage.getItem("loggedUser");
  }

  onEdit() {
    this.router.navigate(["/profile"]);
  }

  logout() {
    const credentials = {
      username: "",
      password: "",
    };
    sessionStorage.setItem("credentials", JSON.stringify(credentials));

    localStorage.clear();
    sessionStorage.clear();
    this.authService.logout();
  }
  ngOnInit() {
    this.qubzLicenseExpire = JSON.parse(
      sessionStorage.getItem("qubzLicenseExpire")
    );
    this.getExpiryStatus();
    this.subscription = this.userSettingService.pictureUpdated$.subscribe(
      (pictureUpdated) => this.profiledata()
    );
  }
  profiledata() {
    this.userSettingService.getProfile().subscribe((data) => {
      this.userData = data;
      this.userData = this.userData.userProfileDetails;
      this.imgURL = "../../../../assets/images/";
      if (this.userData.avatarFileName == "default")
        this.userData.avatarFileName = "usericon_other.png";
      this.imgURL = this.imgURL + this.userData.avatarFileName;
    });
  }
  getExpiryStatus() {
    this.authService.ping().subscribe((data) => {
      let res = <any>{};
      res = data;
      this.expiry = res.userDetails;
      this.qubzLicenseExpire = res.userDetails.qubzLicenseExpire;
    });
  }
  setColorTheme(color) {
    this.templateServiceService
      .saveColorTemplate(color)
      .subscribe((data) => {});
  }
}
