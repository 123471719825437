import {
  Component,
  OnInit,
  HostListener,
  AfterViewInit,
  EventEmitter,
} from "@angular/core";
import { UserManagementService } from "../../services/users/user-management.service";
import { Router } from "@angular/router";
import {
  FormBuilder,
  FormGroup,
  FormArray,
  FormControl,
  Validators,
  ValidatorFn,
  AbstractControl,
} from "@angular/forms";
import { User } from "../../models/users/user.model";
import * as $ from "jquery";
import swal from "sweetalert2";
import { NgForm } from "@angular/forms";
import { FormsModule } from "@angular/forms";
import { UserSettingsService } from "../../services/users/user-settings.service";
import { OrderPipe } from "ngx-order-pipe";
import { CommonServiceService } from "../../services/common-services/common-service.service";

@Component({
  selector: "app-users",
  templateUrl: "./users.component.html",
  styleUrls: ["./users.component.scss"],
})
export class UsersComponent implements OnInit, AfterViewInit {
  userData;
  form: FormGroup;
  editform: FormGroup;
  passwordForm: FormGroup;
  user: User;
  userdata: User;
  page = 0;
  pageNumber: number;
  userDetails;
  authoritiesArray = [];
  qubzUserList: any;
  message: string;
  name: String;
  radioSelected: string;
  typePassword;
  totalElements;
  ldap;
  authorities = [
    { authority: "ROLE_ADMIN", name: "ADMIN" },
    { authority: "ROLE_ANALYST", name: "USER" },
    // { authority: "ROLE_DATA_ADMIN", name: "DATA ADMIN" },
  ];
  show_element: boolean;
  show_editElement: boolean;
  show_changepwd_element: boolean;
  show_view_element: boolean;
  dropdownList = [];
  selectedItems = [];
  dropdownSettings = {};
  show_email: boolean = true;
  show_uname: boolean = true;
  show_pwd: boolean = true;
  email;
  loggedUser;
  uuid;
  uname;
  displayname: string;
  emailid: string;
  roles;
  role1;
  pwd: string;
  enabled: Boolean = true;
  emailPattern = "^[a-zA-Z0-9._%+-]+@[a-z0-9.-]+[.][a-z]{2,4}$";
  order: string = "username";
  reverse: boolean = false;
  sortedCollection;
  userRoles;
  load;
  authoritySettings = {};
  qubzLicenseExpire;
  public focusSettingEventEmitter = new EventEmitter<boolean>();
  constructor(
    private formBuilder: FormBuilder,
    private userManagementService: UserManagementService,
    private router: Router,
    private userSettingService: UserSettingsService,
    private orderPipe: OrderPipe,
    public commonServiceService: CommonServiceService
  ) {
    this.loggedUser = JSON.parse(sessionStorage.getItem("loggedUser"));

    //this.sortedCollection = orderPipe.transform(this.userData, 'username');
  }
  setOrder(value: string) {
    if (this.order === value) {
      this.reverse = !this.reverse;
    }

    this.order = value;
  }
  addNewUser() {
    this.enabled = true;
    // if (this.authoritiesArray.length == 0) {
    //   this.message = "Please select atleast one role.";
    // } else
    if (
      this.form.value.emailId == null ||
      this.form.value.username == null ||
      this.editform.value.displayName == "" ||
      this.form.value.password == null
    ) {
      return;
    } else {
      const user = new User(
        this.form.value.role1,
        false,
        true,
        this.form.value.disabled,
        this.form.value.displayName,
        this.form.value.emailId,
        "",
        "",
        "",
        0,
        false,
        0,
        this.form.value.password,
        "",
        this.form.value.username,
        "",
        "",
        0
      );

      this.userManagementService.addUser(user).subscribe(
        (data) => {
          swal("Success!", "New user has been created successfully", "success");
          this.show_element = false;
          // this.authoritiesArray=[];
          this.listUsers(this.page);
        },
        (error) => {
          //  this.authoritiesArray=[];
          swal("Error!", error.error.msg, "error");
        }
      );
    }
  }
  onAddBtn() {
    this.focusSettingEventEmitter.emit(true);
    this.show_element = true;
    this.form.reset();
  }
  OnCancel(f) {
    f.reset();
    this.show_element = false;
  }
  OnCancelEdit() {
    this.show_editElement = false;
  }
  onEditUser(user) {
    this.uuid = user.uuid;
    this.uname = user.username;
    this.emailid = user.emailId;
    this.displayname = user.displayName;
    this.roles = this.selectedItems;
    this.ldap = user.qubzLDAPUser;
    this.selectedItems = [];
    this.email = user.email;

    if (user.qubzLDAPUser) {
      this.editform.get("emailId").setValidators([]);
      this.editform.get("emailId").updateValueAndValidity();
    } else {
      this.editform
        .get("emailId")
        .setValidators([
          Validators.required,
          Validators.pattern("^[a-zA-Z0-9._%+-]+@[a-z0-9.-]+[.][a-z]{2,4}$"),
        ]);
      this.editform.get("emailId").updateValueAndValidity();
    }

    this.getSelectedData(user);
    this.show_editElement = true;
    sessionStorage.setItem("user", JSON.stringify(user));
    this.user = JSON.parse(sessionStorage.getItem("user"));
  }
  onView(user) {
    this.selectedItems = [];
    //  this.getSelectedData(user);
    this.userRoles = [];
    for (let i = 0; i < user.authorities.length; i++) {
      if (user.authorities[i].authority == "ROLE_ADMIN") {
        this.name = "ADMIN";
      }
      if (user.authorities[i].authority == "ROLE_MODELER") {
        this.name = "MODELER";
      }
      if (user.authorities[i].authority == "ROLE_ANALYST") {
        this.name = "USER";
      }
      this.userRoles.push(this.name);
    }
    this.show_view_element = true;
    sessionStorage.setItem("user", JSON.stringify(user));
    this.user = JSON.parse(sessionStorage.getItem("user"));
  }
  OnCancelView() {
    this.show_view_element = false;
  }
  updateUser(user) {
    if (
      this.editform.value.roles.length == 0 ||
      this.editform.value.displayName == "" ||
      (this.editform.value.emailId == "" && !this.ldap)
    ) {
      return;
    } else {
      for (let i = 0; i < this.editform.value.roles.length; i++) {
        this.authoritiesArray.push({
          authority: this.editform.value.roles[i].authority,
        });
      }
      this.userdata = new User(
        this.authoritiesArray,
        this.ldap,
        true,
        user.disabled,
        this.editform.value.displayName,
        this.editform.value.emailId,
        "",
        "",
        "",
        0,
        false,
        0,
        this.editform.value.password,
        "",
        this.editform.value.username,
        "",
        "",
        0
      );
      this.userManagementService
        .updateUser(this.user.uuid, this.userdata)
        .subscribe(
          (data) => {
            swal("Success!", "User has been updated successfully", "success");
            this.selectedItems = [];
            this.show_editElement = false;
            this.authoritiesArray = [];
            this.listUsers(this.page);
          },
          (error) => {
            swal("Error!", "Error occured!", "error");
          }
        );
    }
  }
  isUserAdmin(authorities) {
    for (let authority of authorities) {
      if (authority["authority"] == "ROLE_ADMIN") {
        return true;
      }
    }
    return false;
  }
  deleteUser(user) {
    let loggedUser = JSON.parse(sessionStorage.getItem("loggedUser"));
    if (loggedUser.username == user.username) {
      swal("Warning!", "You can't delete your own userID", "warning");
      return;
    }

    swal({
      type: "warning",
      title: " ",
      text: "Are you sure you want to delete the user?",
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "No",
    }).then(
      (status) => {
        if (status.value == true) {
          this.userManagementService.deleteUser(user.uuid).subscribe(
            (data) => {
              this.listUsers(this.page);
              swal("Success!", "User has been deleted", "success");
            },
            (error) => {
              swal("Failed", error.error.msg, "error");
            }
          );
        }
      },
      (dismiss) => {
        if (dismiss === "cancel") {
        }
      }
    );
  }
  disableOrEnableUser(user) {
    let loggedUser = JSON.parse(sessionStorage.getItem("loggedUser"));
    if (loggedUser.username == user.username) {
      swal("Warning!", "You can't disable your own userID", "warning");
      return;
    }
    swal({
      type: "warning",
      title: " ",
      text: "Are you sure you want to change the status?",
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "No",
    }).then(
      (status) => {
        if (status.value == true) {
          this.userManagementService
            .updateStatus(user.uuid, { disabled: !user.disabled })
            .subscribe(
              (data) => {
                this.listUsers(this.page);
                swal("Success!", "Status has been changed", "success");
              },
              (error) => {
                swal("Failed", error.error.msg, "error");
              }
            );
        }
      },
      (dismiss) => {
        if (dismiss === "cancel") {
        }
      }
    );
  }
  onChangePassword(user) {
    this.show_changepwd_element = true;
    sessionStorage.setItem("user", JSON.stringify(user));
    this.user = JSON.parse(sessionStorage.getItem("user"));
    this.passwordForm.controls['password'].reset();
  }
  updatePassword(user) {
    this.userdata = new User(
      user.authoritiesArray,
      user.qubzLDAPUser,
      true,
      user.disabled,
      user.displayName,
      user.emailId,
      user.firstName,
      "",
      "",
      0,
      false,
      0,
      this.passwordForm.value.password,
      "",
      user.username,
      "",
      "",
      0
    );
    this.userManagementService
      .updatePassword(user.uuid, this.userdata)
      .subscribe(
        (data) => {
          swal(
            "Success!",
            "User password has been updated successfully",
            "success"
          );
          this.show_changepwd_element = false;
        },
        (error) => {
          swal("Error!", "Error occured!", "error");
        }
      );
      this.passwordForm.controls['password'].reset();
  }
  OnCancelChangepwd() {
    this.show_changepwd_element = false;
  }
  onChange(authority: string, isChecked: boolean) {
    if (isChecked == false) {
      this.message = "Please select at least one role.";
    }
    const idFormArray = <FormArray>this.form.controls.authorities;
    if (isChecked) {
      idFormArray.push(new FormControl(authority));
    } else {
      let index = idFormArray.controls.findIndex((x) => x.value == authority);
      idFormArray.removeAt(index);
    }
    this.authoritiesArray = [];
    for (let i = 0; i < idFormArray.length; i++) {
      this.authoritiesArray.push({ authority: idFormArray.value[i] });
    }

    if (this.authoritiesArray.length > 0) {
      this.message = "";
    }
  }
  togglePassword() {
    this.typePassword = !this.typePassword;
  }
  hasRole(role) {
    var hasRole = false;
    if (this.userData) {
      this.userData.authorities.forEach(
        this.userData.authorities,
        function (authority, index) {
          if (authority.authority == role) {
            hasRole = true;
          }
        }
      );
    }

    return hasRole;
  }

  ngAfterViewInit() {
    $(document).ready(function () {
      var contenth = $(window).height() - 110;
      var sidebarh = $(window).height() - 111;
      $(".pagec").css("height", contenth);
      $(".sidebar-wrapper").css("height", sidebarh);
    });
  }

  ngOnInit() {
    this.loggedUser = JSON.parse(sessionStorage.getItem("loggedUser"));
    this.qubzLicenseExpire = JSON.parse(
      sessionStorage.getItem("qubzLicenseExpire")
    );
    // Add User Form
    this.form = new FormGroup({
      username: new FormControl(null, [
        Validators.required,
        CustomValidator.username,
      ]),
      password: new FormControl(null, [
        Validators.required,
        this.passwordValidation(),
        // CustomValidator.alphabet,
        // CustomValidator.number,
        // CustomValidator.len,
        // CustomValidator.character,
      ]),
      emailId: new FormControl(null, [
        Validators.required,
        Validators.pattern("^[a-zA-Z0-9._%+-]+@[a-z0-9.-]+[.][a-z]{2,4}$"),
      ]),
      displayName: new FormControl(null, [Validators.required]),
      disabled: new FormControl(null, Validators.required),
      role1: new FormControl(null, Validators.required),
    });

    this.editform = this.formBuilder.group({
      uuid: new FormControl(null, [Validators.required]),
      username: new FormControl(null, [Validators.required]),
      displayName: new FormControl(null, [Validators.required]),
      emailId: new FormControl(null, [
        Validators.required,
        Validators.pattern("^[a-zA-Z0-9._%+-]+@[a-z0-9.-]+[.][a-z]{2,4}$"),
      ]),
      roles: new FormControl(null, [Validators.required]),
    });

    this.passwordForm = new FormGroup({
      userid: new FormControl(null),
      username: new FormControl(null),
      password: new FormControl(null, [
        Validators.required,
        this.passwordValidation(),
        // CustomValidator.alphabet,
        // CustomValidator.number,
        // CustomValidator.len,
        // CustomValidator.character,
      ]),
    });

    this.listUsers(this.page);
    // this.message = "Please select atleast one role.";
    this.radioSelected = "false";
    this.user = JSON.parse(sessionStorage.getItem("user"));
    this.getSelectedData(this.user);
    this.userSettingService.getProfile().subscribe((data) => {
      this.userData = data;
      this.userData = this.userData.userProfileDetails;
    });

    /* custome select option starts*/
    var x, i, j, selElmnt, a, b, c;
    x = document.getElementsByClassName("select-custom");
    for (i = 0; i < x.length; i++) {
      selElmnt = x[i].getElementsByTagName("select")[0];
      a = document.createElement("DIV");
      a.setAttribute("class", "select-selected");
      a.innerHTML = selElmnt.options[selElmnt.selectedIndex].innerHTML;
      x[i].appendChild(a);
      b = document.createElement("DIV");
      b.setAttribute("class", "select-items select-hide");
      for (j = 1; j < selElmnt.length; j++) {
        c = document.createElement("DIV");
        c.innerHTML = selElmnt.options[j].innerHTML;
        c.addEventListener("click", function (e) {
          var y, i, k, s, h;
          s = this.parentNode.parentNode.getElementsByTagName("select")[0];
          h = this.parentNode.previousSibling;
          for (i = 0; i < s.length; i++) {
            if (s.options[i].innerHTML == this.innerHTML) {
              s.selectedIndex = i;
              h.innerHTML = this.innerHTML;
              y = this.parentNode.getElementsByClassName("same-as-selected");
              for (k = 0; k < y.length; k++) {
                y[k].removeAttribute("class");
              }
              this.setAttribute("class", "same-as-selected");
              break;
            }
          }
          h.click();
        });
        b.appendChild(c);
      }
      x[i].appendChild(b);
      a.addEventListener("click", function (e) {
        e.stopPropagation();
        closeAllSelect(this);
        this.nextSibling.classList.toggle("select-hide");
        this.classList.toggle("select-arrow-active");
      });
    }

    function closeAllSelect(elmnt) {
      var x,
        y,
        i,
        arrNo = [];
      x = document.getElementsByClassName("select-items");
      y = document.getElementsByClassName("select-selected");
      for (i = 0; i < y.length; i++) {
        if (elmnt == y[i]) {
          arrNo.push(i);
        } else {
          y[i].classList.remove("select-arrow-active");
        }
      }
      for (i = 0; i < x.length; i++) {
        if (arrNo.indexOf(i)) {
          x[i].classList.add("select-hide");
        }
      }
    }
    document.addEventListener("click", closeAllSelect);
    /* custom select option end */
    this.authoritySettings = {
      singleSelection: true,
      textField: "name",
      idField: "authority",
      closeDropDownOnSelection: true,
    };

    //this.saveLogs();
  }

  get f() {
    return this.form.controls;
  }

  getSelectedData(user) {
    this.selectedItems = [];
    if (user != null) {
      for (let i = 0; i < user.authorities.length; i++) {
        if (user.authorities[i].authority == "ROLE_ADMIN") {
          this.name = "ADMIN";
        }
        if (user.authorities[i].authority == "ROLE_MODELER") {
          this.name = "MODELER";
        }
        if (user.authorities[i].authority == "ROLE_ANALYST") {
          this.name = "USER";
        }
        // if(user.authorities[i].authority=="ROLE_DATA_ADMIN"){
        //   this.name="DATA ADMIN";
        // }
        this.selectedItems.push({
          authority: user.authorities[i].authority,
          name: this.name,
        });
      }
    }
    this.dropdownSettings = {
      singleSelection: true,
      textField: "name",
      idField: "authority",
      closeDropDownOnSelection: true,
    };
  }
  listUsers(pageNumber) {
    this.load = true;
    this.userManagementService.userList(pageNumber, 10).subscribe((data) => {
      this.userData = data;
      this.qubzUserList = this.userData.qubzUserList;
      this.load = false;
    });
  }
  getImageUrl(user) {
    return (
      UserSettingsService.url +
      user.username +
      "/profile/" +
      user.last_modified +
      "/image.png"
    );
  }

  saveLogs() {
    var today = new Date();
    let param = {
      username: this.loggedUser.username,
      targetPage: "administration/users",
      day: today.getDay(),
      month: today.getMonth(),
      year: today.getFullYear(),
    };
    this.commonServiceService.saveLogs(param).subscribe((data) => {});
  }

  passwordValidation(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const passPattern: RegExp =
        /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/;

      let pass: string = control.value;

      if (!pass) {
        return null;
      }

      if (!passPattern.test(pass) || pass.indexOf(" ") != -1) {
        return { invalidMail: { value: control.value } };
      } else {
        return null;
      }
    };
  }
}
export class CustomValidator {
  static username(control: FormGroup) {
    if (control.value) {
      if (
        !control.value.match(
          "^[A-Za-z0-9]+[a-zA-Z0-9_|+|.|-]{3,100}$|^[a-zA-Z0-9._%+-]+@[a-z0-9.-]+[.][a-z]{2,4}$"
        )
      ) {
        return { invaliduname: true };
      } else {
        return null;
      }
    }
  }
  static alphabetonly(control: FormGroup) {
    if (control.value) {
      if (!control.value.match("^[A-Za-z]*$")) {
        return { invalidalpha: true };
      } else {
        return null;
      }
    }
  }
  static AlphabetSpaceOnly(control: FormGroup) {
    if (control.value) {
      if (!control.value.match("^[a-zA-Z ]*$")) {
        return { InvalidAlphaSpace: true };
      } else {
        return null;
      }
    }
  }
  static alphabet(control: FormGroup) {
    if (control.value) {
      if (!control.value.match("(?=.*[a-zA-Z])")) {
        return { invalidChar: true };
      } else {
        return null;
      }
    }
  }
  static number(control: FormGroup) {
    if (control.value) {
      if (!control.value.match("(?=.*[0-9])")) {
        return { invalidNum: true };
      } else {
        return null;
      }
    }
  }

  static len(control: FormGroup) {
    if (control.value) {
      if (!control.value.match(".{8,}")) {
        return { invalidLen: true };
      } else {
        return null;
      }
    }
  }
  static character(control: FormGroup) {
    if (control.value) {
      if (!control.value.match("([?=.*!@#$%^&*])")) {
        return { invalidCharacter: true };
      } else {
        return null;
      }
    }
  }
}
