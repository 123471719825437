import { Component, OnInit, AfterViewInit, EventEmitter } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthService } from '../../../../../services/auth/auth.service';
import { FormGroup,  FormControl, FormBuilder,  Validators } from '@angular/forms';
import {ModelStepsService} from '../../../../../services/model/model-steps.service';
import swal from 'sweetalert2';
import { ModelService } from '../../../../../services/model/model.service';
import * as $ from 'jquery';
import { CommonServiceService } from '../../../../../services/common-services/common-service.service';




@Component({
  selector: 'app-model-info',
  templateUrl: './model-info.component.html',
  styleUrls: ['./model-info.component.scss']
})
export class ModelInfoComponent implements OnInit,AfterViewInit {
  loggedUser;
  projectId:any;
  unamePattern = "^[A-Za-z0-9_]{1,100}$";
  modelinfoForm: FormGroup;
  modelInfoObj:any={};
  public focusSettingEventEmitter = new EventEmitter<boolean>();

  constructor(private route: ActivatedRoute, private router: Router, private fb: FormBuilder,private authService: AuthService, private modelStepsService: ModelStepsService,private modelSerivce: ModelService, public commonServiceService:CommonServiceService) {
    this.loggedUser = JSON.parse(sessionStorage.getItem("loggedUser"));
   }

  ngAfterViewInit() {
    $(document).ready(function(){
          var contenth = $( window ).height() - 110 ;
          var sidebarh = $( window ).height() - 111 ;
          $(".pagec").css("height", contenth );
          $(".sidebar-wrapper").css("height", sidebarh );
        });

        this.focusSettingEventEmitter.emit(true);
   
    }
    
  ngOnInit() {
    this.projectId = this.route.snapshot.paramMap.get("projectId");
    this.createForm();
    

    if(this.modelStepsService.modelName!==undefined || this.modelStepsService.modelDescription!==undefined){
      this.retainPrevmodelInfoValue();
    }else{
      this.modelinfoForm.value.modelName = undefined;
      this.modelinfoForm.value.modelDescription = undefined;
      this.modelStepsService.factTable = undefined;
      this.modelStepsService.lookUps = undefined;
      this.modelStepsService.joinTable = undefined;
      this.modelStepsService.dimensionColumns =undefined;
      this.modelStepsService.dimensionColumns1 = undefined;
      this.modelStepsService.dimensionName = undefined;
      this.modelStepsService.dimensionName1 = undefined;
      this.modelStepsService.metricName = undefined;
      this.modelStepsService.partitionDateTblName = undefined;
      this.modelStepsService.partitionDateColName = undefined;
      this.modelStepsService.checkBoxName = undefined;
      this.modelStepsService.partitionTimeTblName = undefined;
      this.modelStepsService.partitionDateFormatName = undefined;
      this.modelStepsService.paritionTimeColName = undefined;
      this.modelStepsService.partitionTimeFormatName = undefined;
      this.modelStepsService.partitionFilter = undefined;
      this.modelStepsService.aliasName = undefined;
      this.modelStepsService.factAliasName = undefined;
      this.modelStepsService.selFromTable = undefined;
      this.modelStepsService.selLookup = undefined;
      this.modelStepsService.selFromTableColumn = undefined;
      this.modelStepsService.selLookupColumn = undefined;
      this.modelStepsService.dimensionLookup =undefined;
      this.modelStepsService.lookUps = undefined;
      this.modelStepsService.frmTableList = undefined;
      this.modelStepsService.dimensionLookup = undefined;
      this.modelStepsService.measureLookup = undefined;
      this.modelStepsService.kindType = undefined;
      this.modelStepsService.kind = undefined;
      this.modelStepsService.metricName1 = undefined;
    }
   //this.saveLogs();
  }

  createForm() {
    this.modelinfoForm = this.fb.group({
       modelName: ['', [Validators.required, Validators.pattern(this.unamePattern)]],
       modelDescription: ['']
    });
   
  }

  retainPrevmodelInfoValue(){
      this.modelinfoForm.patchValue({
        modelName:this.modelStepsService.modelName,
        modelDescription:this.modelStepsService.modelDescription
      
      });
     
  }

  loadDataModel(){
    this.CheckModelAlreadyExist(this.projectId);
  }

  CheckModelAlreadyExist(id){
    const param ={
      modelName:this.modelinfoForm.value['modelName']
    }
    this.modelSerivce.validateModelName(param.modelName).subscribe(data =>{
     this.modelInfoObj = data;
       if(this.modelInfoObj.data == false){
        swal("Warning!", 'Model'+" " +'named'+" "+ '['+this.modelinfoForm.value['modelName'] +']'+" "+'already'+" "+'exists', "warning");
        return;
       }else{
        this.modelStepsService.modelName=this.modelinfoForm.value['modelName'];
        this.modelStepsService.modelDescription=this.modelinfoForm.value['modelDescription'];
        this.router.navigate(['/projects/',id,'models','designer','data']);
       }
    });
  }

  setFocus(): void {
    this.focusSettingEventEmitter.emit(true);
  }

  saveLogs(){
    var today = new Date();
    let param={
      username:this.loggedUser.username,
      targetPage:'projects/'+this.projectId+'/models/designer/info',
      day:today.getDay(),
      month:today.getMonth(),
      year:today.getFullYear()
    };
    this.commonServiceService.saveLogs(param).subscribe(
      data => {

      });
  }

}
