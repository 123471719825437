import { Component, OnInit, AfterViewInit, OnDestroy } from '@angular/core';
import { TableService } from 'src/app/services/table/table.service';
import { ActivatedRoute } from '@angular/router';
import { Table } from 'src/app/models/table/table.model';
import { Columns } from 'src/app/models/table/columns/columns.model';
import { Snapshot } from 'src/app/models/table/snapshot/snapshot.model';
import swal from 'sweetalert2';
import * as $ from 'jquery';
import * as lodash from 'lodash';
import { StreamingService } from 'src/app/services/streaming/streaming.service';
import { FormGroup } from '@angular/forms';
import { Location } from '@angular/common';
import { CommonServiceService } from '../../../../services/common-services/common-service.service';

@Component({
  selector: 'app-config-streaming-vtwo',
  templateUrl: './config-streaming-vtwo.component.html',
  styleUrls: ['./config-streaming-vtwo.component.scss']
})
export class ConfigStreamingVtwoComponent implements OnInit, AfterViewInit,OnDestroy {
  loggedUser;
  load;

   // config streaming v2 container 
  databases: any[];
  dbList: string[] = [];
  tableColumns: any[];
  tableSnapshot: any[];
  tableName: any;
  kfkConfig: any[];
  streamingConfig: any[]
  isActive: any[] = [];
  tableIsActive: any[] = [];
  tabIsActive: any[] =[];
  projectId: string;
  cardinality: string[];
  body = {calculate : true};
  tableList: Table[] = [];
  snapTable: Table;
  selectedTable: Table;
  filteredColumns: Columns[];
  //kfkConfig: any[];
  cardIsChecked: boolean;
  currentTab: number = 1;
  //Pagination vars
  col:{[i:number]: number} ={}
  p: { [id: string]: number} = {};
  activeColumn;
  qubzLicenseExpire:any;
  searchText:string;
  dbs;
  kafkaForm:any;
  jsonForm:FormGroup;
  // config streaming v2 container 
  showDiv = {
    puopen : false,
    puopenbg : false ,
    stepone : true,
    configstreaming : false,
    cancelbtn : true,
    nextbtn :true,
    backbtn :false,
    submitbtn : false
  }
  isKafkaFormValid: boolean;
  isJsonFormValid: boolean = true;
  constructor(private tableService: TableService, private route: ActivatedRoute, private streamingService: StreamingService,private location: Location, public commonServiceService:CommonServiceService) { }

  ngOnInit() {
    this.loggedUser=JSON.parse(sessionStorage.getItem("loggedUser"));
    this.projectId = this.route.snapshot.paramMap.get('projectId');
    //this.saveLogs();
  }
  ngOnDestroy() {
    this.streamingService.setFormData(null);
  }
  next(){
   
  }
  kafkaFormValid(status:boolean){
    this.isKafkaFormValid = status;
  }

  kafkaFormData(form:any){
    this.kafkaForm = form;
  }
  jsonFormData(form:FormGroup){
    this.jsonForm = form;
  }
  jsonFormDataValid(status:boolean){
    this.isJsonFormValid = status;
  }
  getConfig(db,table){
    this.streamingService.getConfig(db,table)
    .subscribe( (data: any[]) => {
      if(data)
      this.kfkConfig=data[0];
    });
  }
  ngAfterViewInit() {
    /*
    $(document).ready(function () {
      function openpu() {
        $("#configbtn").on("click", function(){
        var puconetntheight = $(window).height() - 95;
          $("#pucontent").css("height", puconetntheight);
        });
      }

      setTimeout(openpu,2); 
    })      
    
    $( window ).resize(function() {
      var puconetntheight = $(window).height() - 95;
      $("#pucontent").css("height", puconetntheight);
    }); 
    */

    function tabconfig() {
      $(document).on("click", ".btnnext", function(){
          $(".streamingli").addClass("active");
          $(".kafkaconfigli").addClass("active");   
      });
      
    }
    setTimeout(tabconfig,100); 

    $(document).on("click", "#backtofirst", function(){
      $(".streamingli").removeClass("active");
      $(".streamingli").removeClass("completetab");
    }) 

    $(document).on("click", ".kafkaconfigli", function(){
      $(".streamingli").removeClass("active");
      $(".streamingli").removeClass("completetab");
    }) 

  }
  submitData(){
    let body;
    swal({
      type:'warning',
      title: 'Are you sure to save the streaming table ?',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No'
    }).then((status) => {
      if(status.value==true){
        let errors: any = null;

        let server=[];
        this.kafkaForm.servers.forEach(element => {
          server.push(element.host+":"+element.port)
        });
        let table = {};
        this.jsonForm.value.tableColumns.forEach(ele => {
          if (ele.comment !== 'derived time dimension') {
            table = {
              ...table,
              [ele.columnName]: ele.columnName
            }
          }
        });
        let colData = [];
        this.jsonForm.value.tableColumns.map(item=>{
          if(item.comment !== 'derived time dimension' )
          colData = [...colData,{name: item.columnName, datatype: item.columnType, comment: item.comment, id: item.id}];
          else colData = [...colData,{name: item.columnName, datatype: item.columnType, id: item.id}];
        })
                body = {
                  message:null,
                  project: this.route.snapshot.paramMap.get("projectId"),
                  streamingConfig: JSON.stringify({
                    name: this.jsonForm.value.tableName,
                    properties:{
                      topic:this.kafkaForm.topic,
                      "bootstrap.servers": server.join(',')
                    },
                    parser_info:{
                      ts_col_name:this.jsonForm.value.tsColumn,
                      ts_parser: this.jsonForm.value.tsParser,
                      ts_pattern: this.jsonForm.value.tsPattern,
                      field_mapping:table
                    }}),
                    successful:true,
                    tableData: JSON.stringify({
                      source_type:20,
                      name:this.jsonForm.value.tableName,
                      columns:colData
                    })

                }
        this.streamingService.saveStreamingForV2(body).subscribe( 
          result => {
           
          },
          error => {
            errors=error;
            swal("Failed to save Stream",errors.error.msg, "error")
          },
          ()=> {
            if(!errors) {
              swal("Success!","Stream was saved", "success").then(
                (status)=> {
                  if(status.value == true) {
                    this.location.back();
                  }
                }
              )
            }
          }
        ) 
        } 
    }, (dismiss) => {
      if (dismiss === 'cancel') {
      }
    });
  }

  saveLogs(){
    var today = new Date();
    let param={
      username:this.loggedUser.username,
      targetPage:'projects/'+this.projectId+'/realtime-streaming',
      day:today.getDay(),
      month:today.getMonth(),
      year:today.getFullYear()
    };
    this.commonServiceService.saveLogs(param).subscribe(
      data => {

      });
  }
}
