import { Component, OnInit, AfterViewInit } from "@angular/core";
import { CubeConfig } from "../../../../../models/cubes/cube-config";
import { CubesService } from "../../../../../services/cubes/cubes.service";
import { NgForm } from "@angular/forms";
import { FormArray, FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ConstantPool, IfStmt } from "@angular/compiler";
import swal from "sweetalert2";
import { Router, ActivatedRoute } from "@angular/router";
import * as $ from "jquery";

@Component({
  selector: "app-overview",
  templateUrl: "./overview.component.html",
  styleUrls: ["./overview.component.scss"],
})
export class OverviewComponent implements OnInit, AfterViewInit {
  cubeMeta;
  model;
  mode;
  projectId;
  editCube;
  buttonDisabled;
  factTableInfo: any;
  constructor(
    public cubesService: CubesService,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private router: Router
  ) {
    this.projectId = this.route.snapshot.paramMap.get("projectId");
    this.editCube = this.route.snapshot.paramMap.get("cubeId");
    if (this.cubesService.cubeMeta.dimensions.length == 0) {
      this.router.navigate([
        "/projects/" + this.projectId + "/cubes/designer/info",
      ]);
    }
    this.mode = this.cubesService.editMode;
  }

  ngAfterViewInit() {
    $(document).ready(function () {
      var contenth = $(window).height() - 110;
      var sidebarh = $(window).height() - 111;
      $(".pagec").css("height", contenth);
      $(".sidebar-wrapper").css("height", sidebarh);
    });

    function progressli_check() {
      $(".progresslic ").each(function () {
        if ($(".progressbar li ").length > 6) {
          $(".menublock").addClass("tabw_seven");
        }
      });
    }
    setTimeout(progressli_check, 100);
  }

  ngOnInit() {
    this.factTableInfo = this.cubesService.tableInfo;
    this.cubeMeta = this.cubesService.cubeMeta;
    this.model = this.cubesService.model;
  }
  getCounts(flag) {
    let lookup = [];
    if (flag == "lookup") {
      for (let param of this.cubeMeta.dimensions) {
        if (lookup.indexOf(param.table) == -1) lookup.push(param.table);
      }
      return lookup.length;
    }
    if (flag == "dimensions") {
      return this.cubeMeta.dimensions.length;
    }
    if (flag == "measures") {
      return this.cubeMeta.measures.length;
    }
  }
  previousPage(page) {
    if (this.cubesService.editMode)
      this.router.navigate([
        "/projects/" +
          this.projectId +
          "/cubes/" +
          this.editCube +
          "/designer/" +
          page,
      ]);
    else
      this.router.navigate([
        "/projects/" + this.projectId + "/cubes/designer/" + page,
      ]);
  }
  saveCube() {
    this.buttonDisabled = true;
    let dimension = [];
    for (let param of this.cubesService.cubeMeta.dimensions) {
      let name = param.name.split(".", 2);
      if (param.column == null) {
        dimension.push({
          name: name[1] ? name[1] : param.name,
          derived: param.derived,
          table: param.table,
        });
      } else {
        dimension.push({
          name: name[1] ? name[1] : param.name,
          column: param.column,
          table: param.table,
        });
      }
    }

    let obj = {
      name: this.cubesService.cubeMeta.name,
      model_name: this.cubesService.cubeMeta.model_name,
      description: this.cubesService.cubeMeta.description,
      dimensions: dimension,
      measures: this.cubesService.cubeMeta.measures,
      dictionaries: this.cubesService.cubeMeta.dictionaries,
      rowkey: this.cubesService.cubeMeta.rowkey,
      aggregation_groups: this.cubesService.cubeMeta.aggregation_groups,
      mandatory_dimension_set_list:
        this.cubesService.cubeMeta.mandatory_dimension_set_list,
      partition_date_start: this.cubesService.cubeMeta.partition_date_start,
      notify_list: this.cubesService.cubeMeta.notify_list,
      hbase_mapping: this.cubesService.cubeMeta.hbase_mapping,
      volatile_range: this.cubesService.cubeMeta.volatile_range,
      retention_range: this.cubesService.cubeMeta.retention_range,
      status_need_notify: this.cubesService.cubeMeta.status_need_notify,
      auto_merge_time_ranges: this.cubesService.cubeMeta.auto_merge_time_ranges,
      engine_type: this.cubesService.cubeMeta.engine_type
        ? this.cubesService.cubeMeta.engine_type
        : 6,
      storage_type: this.factTableInfo.source_type == 20 ? 3 : 4,
      realtime_cube:
        this.factTableInfo && this.factTableInfo.source_type == 20
          ? true
          : false,
      snapshot_table_desc_list:
        this.cubesService.cubeMeta.snapshot_table_desc_list,
      override_qubz_properties:
        this.cubesService.cubeMeta.override_qubz_properties,
    };
    this.cubesService
      .saveCubeDesc({
        cubeDescData: JSON.stringify(obj),
        project: this.cubesService.projectName,
      })
      .subscribe(
        (data) => {
          swal("Success!", "New cube successfully created", "success");
          this.router.navigate(["/projects/" + this.projectId + "/cubes/"]);
          this.buttonDisabled = false;
        },
        (error) => {
          this.buttonDisabled = false;
          swal("Failed", error.error.msg, "error");
        }
      );
  }
  updateCube() {
    let timestamp: number = Date.now();
    this.buttonDisabled = true;

    let dimension = [];
    for (let param of this.cubesService.cubeMeta.dimensions) {
      let name = param.name.split(".", 2);
      if (param.column == null) {
        dimension.push({
          name: name[1] ? name[1] : param.name,
          derived: param.derived,
          table: param.table,
        });
      } else {
        dimension.push({
          name: name[1] ? name[1] : param.name,
          column: param.column,
          table: param.table,
        });
      }
    }

    let obj = {
      uuid: this.cubesService.cubeMeta.uuid,
      last_modified: this.cubesService.cubeMeta.last_modified,
      version: this.cubesService.cubeMeta.version,
      name: this.cubesService.cubeMeta.name,
      is_draft: this.cubesService.cubeMeta.is_draft,
      model_name: this.cubesService.cubeMeta.model_name,
      description: this.cubesService.cubeMeta.description,
      null_string: null,
      dimensions: dimension,
      measures: this.cubesService.cubeMeta.measures,
      dictionaries: [],
      rowkey: this.cubesService.cubeMeta.rowkey,
      hbase_mapping: this.cubesService.cubeMeta.hbase_mapping,
      aggregation_groups: this.cubesService.cubeMeta.aggregation_groups,
      signature: this.cubesService.cubeMeta.signature,
      notify_list: this.cubesService.cubeMeta.notify_list,
      status_need_notify: this.cubesService.cubeMeta.status_need_notify,
      partition_date_start: this.cubesService.cubeMeta.partition_date_start,
      partition_date_end: this.cubesService.cubeMeta.partition_date_end,
      auto_merge_time_ranges: this.cubesService.cubeMeta.auto_merge_time_ranges,
      volatile_range: this.cubesService.cubeMeta.volatile_range,
      retention_range: this.cubesService.cubeMeta.retention_range,
      engine_type: this.cubesService.cubeMeta.engine_type,
      storage_type:
        this.factTableInfo && this.factTableInfo.source_type == 20 ? 3 : 4,
      realtime_cube:
        this.factTableInfo && this.factTableInfo.source_type == 20
          ? true
          : false,
      override_qubz_properties:
        this.cubesService.cubeMeta.override_qubz_properties,
      cuboid_black_list: [],
      parent_forward: this.cubesService.cubeMeta.parent_forward,
      mandatory_dimension_set_list:
        this.cubesService.cubeMeta.mandatory_dimension_set_list,
      snapshot_table_desc_list:
        this.cubesService.cubeMeta.snapshot_table_desc_list,
    };
    this.cubesService
      .updateCubeDesc({
        cubeDescData: JSON.stringify(obj),
        cubeName: this.cubesService.cubeName,
        project: this.cubesService.projectName,
      })
      .subscribe(
        (data) => {
          let res = <any>{};
          res = data;
          if (res.successful) {
            swal("Success!", "Cube successfully updated", "success");
            this.router.navigate(["/projects/" + this.projectId + "/cubes/"]);
            this.buttonDisabled = false;
          } else {
            swal("Information!", res.message, "info");
          }
        },
        (error) => {
          this.buttonDisabled = false;
          swal("Failed", error.error.msg, "error");
        }
      );
  }
}
