import { Component, OnInit, Input } from '@angular/core';

@Component({
	selector: 'app-cube-view-step-four',
	templateUrl: './cube-view-step-four.component.html',
	styleUrls: ['./cube-view-step-four.component.scss']
})
export class CubeViewStepFourComponent implements OnInit {
	@Input() cubeDesc;
	constructor() { }

	ngOnInit() {
	}

	timeRangeFormat(time: any) {
		let day = Math.floor(time / 86400000);
		let hour = (time % 86400000) / 3600000;
		if (day == 0) {
			return hour + " (Hours)";
		} else {
			return day + " (Days)";
		}
	}
	millisecondsToDay(time: any) {
		return time/86400000;
	}
	reverseToGMT0(time: any) {
		if (time || time == 0) {
			time += new Date().getTimezoneOffset() * 60000;
			return time;
		} else {
			return "";	
		}
	}
	
}
