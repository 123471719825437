export class Columns {

    columnName: string;
    comment?: string;
    ccExpr?: string;
    dataGen?: string;
    datatype: string;
    columnId: string;
    index?: string;
    visible: boolean;
    cardinality?:number;

    constructor(columnResponse: any) {
        this.columnName = columnResponse.name;
        this.comment = columnResponse.comment;
        this.ccExpr = columnResponse.cc_expr;
        this.dataGen = columnResponse.data_gen;
        this.datatype = columnResponse.datatype;
        this.columnId = columnResponse.id;
        this.index = columnResponse.index;
        this.visible = columnResponse.visible;
        this.cardinality = columnResponse.cardinality;
    }
}
