import { Component, OnInit, AfterViewInit, EventEmitter } from "@angular/core";
import { ActivatedRoute, Router, ParamMap } from "@angular/router";
import { FormGroup, FormControl, FormBuilder } from "@angular/forms";
import { JobService } from "src/app/services/job/job.service";
import { Jobs } from "../../../models/jobs/jobs.model";
import { interval } from "rxjs";
import swal from "sweetalert2";
import { OrderPipe } from "ngx-order-pipe";
import * as $ from "jquery";
import { Config } from "../../../config";
import { CubeDescriptionService } from "../../../services/cubes/cube-description.service";
import { CommonServiceService } from "../../../services/common-services/common-service.service";

@Component({
  selector: "app-jobs",
  templateUrl: "./jobs.component.html",
  styleUrls: ["./jobs.component.scss"],
})
export class JobsComponent implements OnInit, AfterViewInit {
  loggedUser;
  jobForm: FormGroup;

  tableSize: number = 10;
  page: number;
  count: number = 0;


  jobs: Jobs[];
  filteredJobs: Jobs[];
  tempJobs: Jobs[];
  selectedJob: Jobs[];
  temp: string;
  projectId: string;
  cubeId: string = "";
  jobId: string;
  stepId: string;
  jobDetails: string;
  deletedJob: any;
  diagnosis: any;
  today: number = new Date().getTime();
  p: number = 1;
  totalJobs = 0;
  expand: boolean = false;
  collapse: boolean = false;
  stepLog: any[];
  parameters: string;
  status: number[] = [];
  offset: number = 0;
  limit: number = 10000;
  timeStatus = 1;
  private searchMode: string = "ALL";
  private searchTime: string;
  order: string = "last_modified";
  reverse: boolean = false;
  sortedCollection;
  load: boolean = true;
  loadCounter = 10000;
  current_job_id;
  existJob: boolean;
  current_job: boolean;
  selectAll = false;
  jobSelected = false;
  time = [
    { name: "CURRENT DAY", value: "CURRENT DAY" },
    { name: "LAST ONE DAY", value: "LAST ONE DAY" },
    { name: "LAST ONE WEEK", value: "LAST ONE WEEK" },
    { name: "LAST ONE MONTH", value: "LAST ONE MONTH" },
    { name: "LAST ONE YEAR", value: "LAST ONE YEAR" },
    { name: "ALL", value: "ALL" },
  ];
  mode = [
    { name: "CUBING", value: "CUBING_ONLY" },
    { name: "CHECK POINT", value: "CHECKPOINT_ONLY" },
    { name: "CARDINALITY", value: "CARDINALITY_ONLY" },
    { name: "SNAPSHOT", value: "SNAPSHOT_ONLY" },
    { name: "ALL", value: "ALL" },
  ];
  defaultTime = "LAST ONE DAY";
  defaultmode = "ALL";
  loadingstatus: boolean;
  loadSidebar: boolean;
  noJobs: boolean;
  flag: boolean;
  loadFull;
  jobCount = {
    FINISHED: 0,
    DISCARDED: 0,
    STOPPED: 0,
    ERROR: 0,
    NEW: 0,
    RUNNING: 0,
    PENDING: 0,
  };
  public focusSettingEventEmitter = new EventEmitter<boolean>();
  cubes: any;
  engineName: any;
  constructor(
    private route: ActivatedRoute,
    private jobService: JobService,
    private fb: FormBuilder,
    private orderPipe: OrderPipe,
    private router: Router,
    private cubeDescService: CubeDescriptionService,
    public commonServiceService: CommonServiceService
  ) {
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.loggedUser = JSON.parse(sessionStorage.getItem("loggedUser"));
  }

  ngAfterViewInit() {
    $(document).ready(function () {
      var contenth = $(window).height() - 110;
      var sidebarh = $(window).height() - 111;
      $(".pagec").css("height", contenth);
      $(".sidebar-wrapper").css("height", sidebarh);
    });
    this.focusSettingEventEmitter.emit(true);
  }

  ngOnInit() {
    this.defaultTime = "LAST ONE DAY";
    this.defaultmode = "ALL";
    this.route.paramMap.subscribe((params: ParamMap) => {
      this.projectId = params.get("projectId");
    });
    this.jobForm = this.fb.group({
      cubeName: [""],
      statusNew: [false],
      statusDiscarded: [false],
      statusStopped: [false],
      statusError: [false],
      statusFinished: [false],
      statusPending: [false],
      statusRunning: [false],
      defaultTime: [false],
      defaultmode: [false],
    });
    this.page = 1;
    this.getJobs(this.page, this.tableSize);
    this.setOrder("last_modified");
    this.load = false;
    //this.saveLogs();
  }
  getJobCount() {
    const params = {
      status: [],
      cubeName: this.jobForm.get("cubeName").value,
      projectName: this.projectId,
      offset: this.offset,
      limit: this.limit,
      timeFilter: this.timeStatus,
      jobSearchMode: this.searchMode,
    };
    this.jobService.getJobCount(params).subscribe((data: any) => {
      this.jobCount = data;
    });
  }
  setOrder(value: string) {
    if (this.order === value) {
      this.reverse = !this.reverse;
    }

    this.order = value;
  }
  getJobs(page: number, pageSize: number) {
    this.loadFull = true;
    this.loadingstatus = true;
    this.cubeId = this.jobForm.get("cubeName").value;
    this.setStatusFilters();
    this.setTimeFilter(this.searchTime);
    const params = {
      status: [],
      cubeName: this.cubeId,
      projectName: this.projectId,
      offset: this.offset,
      limit: this.limit,
      timeFilter: this.timeStatus,
      jobSearchMode: this.searchMode,
      page: page==-1?0:page - 1,
      size: pageSize


    };
    this.jobService.getJobs(params).subscribe((data: any) => {

      this.loadingstatus = false;
      this.filteredJobs = data.content;
      this.jobs = data;
      this.count = data.totalElements;
      this.totalJobs = this.filteredJobs.length;
      this.loadFull = false;
      this.getJobCount();
    });
  }
  refreshTable() {
    //this.getQueryList(this.requestParam);
    this.page = 1;
    this.getJobs(this.page - 1, this.tableSize);
  }
  applyFilters(flag, page: number, pageSize: number) {
    //page = page=-1?0:page; 
    this.setStatusFilters();
    this.setTimeFilter(this.searchTime);
    const params = {
      status: this.status,
      cubeName: this.jobForm.get("cubeName").value,
      projectName: this.projectId,
      offset: this.offset,
      limit: this.limit,
      timeFilter: this.timeStatus,
      jobSearchMode: this.searchMode,
      page: page==-1?0:page,
      size: pageSize
    };
    this.load = true;
    // this.noJobs=true;
    this.current_job = false;
    this.jobService.getJobs(params).subscribe((data: any) => {
      this.load = false;
      this.filteredJobs = data.content;
      this.jobs = data;
      this.count = data.totalElements;
      this.totalJobs = this.filteredJobs.length;
      
      if (flag) {
        this.selectedJob = null;
        this.current_job_id = null;
      }
      //this.selectAll = false;
      this.jobSelected = false;
      this.getJobCount();
      if(this.selectAll===true){
        for(let param of this.filteredJobs){
          param.selected = true;
          this.jobSelected = true;
        }
      }
    });
    if (this.current_job_id) {
      this.getJobById(this.current_job_id);
    }
     
      

  }
  setTime(event) {
    this.searchTime = event;
    this.page = 0;
    this.applyFilters(true, this.page, this.tableSize);
  }

  setMode(event) {
    this.searchMode = event;
    this.page = 0;
    this.applyFilters(true, this.page, this.tableSize);
  }

  setStatusFilters() {
    this.status = [];
    if (this.jobForm.get("statusNew").value) {
      this.status.push(0);
    }
    if (this.jobForm.get("statusPending").value) {
      this.status.push(1);
    }
    if (this.jobForm.get("statusRunning").value) {
      this.status.push(2);
    }
    if (this.jobForm.get("statusStopped").value) {
      this.status.push(32);
    }
    if (this.jobForm.get("statusFinished").value) {
      this.status.push(4);
    }
    if (this.jobForm.get("statusError").value) {
      this.status.push(8);
    }
    if (this.jobForm.get("statusDiscarded").value) {
      this.status.push(16);
    }
  }

  setTimeFilter(time: string) {
    if (time === "CURRENT DAY") {
      this.timeStatus = 0;
    }
    if (time === "LAST ONE DAY") {
      this.timeStatus = 1;
    }
    if (time === "LAST ONE WEEK") {
      this.timeStatus = 2;
    }
    if (time === "LAST ONE MONTH") {
      this.timeStatus = 3;
    }
    if (time === "LAST ONE YEAR") {
      this.timeStatus = 4;
    }
    if (time === "ALL") {
      this.timeStatus = 5;
    }
  }

  setJobAction(event, id) {
    this.jobId = id;
    if (event === "Pause") {
      swal({
        type: "warning",
        title: "Are you sure you want to pause this job?",
        showCancelButton: true,
        confirmButtonText: "Yes",
        cancelButtonText: "No",
      }).then(
        (status) => {
          if (status.value == true) {
            this.pauseJob(this.jobId);
          }
        },
        (dismiss) => {
          if (dismiss === "cancel") {
          }
        }
      );
    }
    if (event === "Resume") {
      swal({
        type: "warning",
        title: "Are you sure you want to resume this job?",
        showCancelButton: true,
        confirmButtonText: "Yes",
        cancelButtonText: "No",
      }).then(
        (status) => {
          if (status.value == true) {
            this.resumeJob(this.jobId);
          }
        },
        (dismiss) => {
          if (dismiss === "cancel") {
          }
        }
      );
    }
    if (event === "Drop") {
      swal({
        type: "warning",
        title: "Are you sure you want to drop this job ?",
        showCancelButton: true,
        confirmButtonText: "Yes",
        cancelButtonText: "No",
      }).then(
        (status) => {
          if (status.value == true) {
            this.deleteJob(this.jobId);
          }
        },
        (dismiss) => {
          if (dismiss === "cancel") {
          }
        }
      );
    }
    if (event === "Discard") {
      swal({
        type: "warning",
        title: "Are you sure you want to discard this job ?",
        showCancelButton: true,
        confirmButtonText: "Yes",
        cancelButtonText: "No",
      }).then(
        (status) => {
          if (status.value == true) {
            this.cancelJob(this.jobId);
          }
        },
        (dismiss) => {
          if (dismiss === "cancel") {
          }
        }
      );
    }
    if (event === "Diagnosis") {
      this.getDiagnosis(this.jobId);
    }
    this.selectAll = false;
    this.jobSelected = false;
  }

  isExpanded() {
    this.expand = !this.expand;
  }

  isCollapsed() {
    this.collapse = !this.collapse;
  }

  getJobById(id: string) {
    this.current_job_id = id;
    this.loadSidebar = true;
    this.noJobs = false;
    this.jobService.getJobById(id).subscribe((data: Jobs[]) => {
      this.selectedJob = data;
      this.loadSidebar = false;
    });
  }

  cancelJob(id) {
    this.jobService.cancelJob(id).subscribe((data) => {
      this.applyFilters(false, this.page - 1, this.tableSize);
      swal("Success!", "Job has been cancelled", "success");
    });
  }

  deleteJob(id) {
    this.jobService.deleteJob(id).subscribe((data) => {
      this.applyFilters(false, this.page - 1, this.tableSize);
      swal("Success!", "Job has been dropped", "success");
    });
  }

  pauseJob(id) {
    this.jobService.pauseJob(this.jobId).subscribe((data) => {
      this.applyFilters(false, this.page - 1, this.tableSize);
      swal("Success!", "Job has been paused", "success");
    });
  }

  resumeJob(id: string) {
    this.jobService.resumeJob(id).subscribe((data) => {
      this.applyFilters(false, this.page - 1, this.tableSize);
      swal("Success!", "Job has been resumed", "success");
    });
  }

  setParameters(parameter: string) {
    this.parameters = parameter;
  }

  getDiagnosis(id: string) {
    this.jobService.getDiagnosis(id);
    var downloadUrl = Config.url + "/diag/job/" + id + "/download";
    window.open(downloadUrl, "_blank");
  }

  externalLink(url: string) {
    window.open(url, "_blank");
  }

  getJobStepOut(jobId: string, stepId: string) {
    this.jobService.getJobStepOut(jobId, stepId).subscribe((data: any[]) => {
      this.stepLog = data;
    });
  }

  rollbackJob(id: string, currentStep: string) {
    this.jobService
      .rollbackJob(id, currentStep, this.jobDetails)
      .subscribe((data: Jobs[]) => (this.jobs = data));
  }
  loading() {
    this.load = !this.load;
  }
  secToTime(res) {
    var days = Math.floor(Math.floor(res / 86400));
    var hours = Math.floor(Math.floor(res / 3600) % 24);
    var minutes = Math.floor(Math.floor(res / 60) % 60);
    var seconds = Math.floor(res % 60);
    let output = "";

    if (days > 0) output = days + (days > 1 ? " days," : " day, ");
    if (hours > 0) output += hours + (hours > 1 ? " hrs, " : " hr,");
    if (minutes > 0 || hours > 0)
      output += minutes + (minutes > 1 ? " mins, " : " min, ");

    output += seconds + (seconds != 1 ? " secs " : " sec ");
    return output;
  }
  getDuration(end, start) {
    let res = Math.abs(end - start) / 1000;
    var days = Math.floor(Math.floor(res / 86400));
    var hours = Math.floor(Math.floor(res / 3600) % 24);
    var minutes = Math.floor(Math.floor(res / 60) % 60);
    var seconds = Math.floor(res % 60);
    let output = "";

    if (days > 0) output = days + (days > 1 ? " days," : " day, ");
    if (hours > 0) output += hours + (hours > 1 ? " hrs, " : " hr,");
    if (minutes > 0 || hours > 0)
      output += minutes + (minutes > 1 ? " mins, " : " min, ");

    output += seconds + (seconds != 1 ? " secs " : " sec ");
    return output;
  }
  setFocus(): void {
    this.focusSettingEventEmitter.emit(true);
  }
  selectAllBoxes() {
    let jobs: any;
    for (jobs of this.filteredJobs) {
      if (
        jobs.job_status === "DISCARDED" ||
        jobs.job_status === "ERROR" ||
        jobs.progress === 100
      )
        jobs.selected = this.selectAll;
    }
    this.jobSelected = this.selectAll;
  }
  checkAnyJobSelected() {
    let selected = true;
    let jobSelected = false;
    let jobs: any;
    for (jobs of this.filteredJobs) {
      if (!jobs.selected) selected = false;
      else jobSelected = true;
    }
    this.jobSelected = jobSelected;
    this.selectAll = selected;
  }
  deleteSelctedJobs() {
    swal({
      type: "warning",
      title: "Are you sure you want to delete this jobs ?",
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "No",
    }).then((status) => {
      if (status.value == true) {
        let delJobs = [];
        let jobs: any;
        for (jobs of this.filteredJobs) {
          if (jobs.selected) {
            delJobs.push(jobs.uuid);
          }
        }
        this.jobService.deleteMultipleJobs(delJobs).subscribe(
          (data: any[]) => {
            swal("Success", "Selected jobs deleted", "success");
            this.jobSelected = false;
            // this.getJobs(this.page, this.tableSize);
            //if(this.page>0){
              this.applyFilters(false, this.page - 1, this.tableSize)
            //}
          },
          (error) => {
            swal("Ooops", error.error.msg, "error");
          }
        );
      }
    });
  }

  getCubeDetails(cubeName) {
    this.cubeDescService.getCubeDetails(cubeName).subscribe((data) => {
      this.cubes = data[0];
      this.getEngineType(this.cubes.engine_type);
    });
  }

  getEngineType(engineType) {
    //if (engineType == 2) {
    //this.engineName = "MR";
    //}
    if (engineType == 6) {
      this.engineName = "Spark";
    }
    //if (engineType == 5) {
    //this.engineName = "Flink";
    //}

    return this.engineName;
  }
  getSize(data) {
    if (!data) {
      return "0 KB";
    }
    var size;
    if (data / 1024 / 1024 / 1024 / 1024 >= 1) {
      size = (data / 1024 / 1024 / 1024 / 1024).toFixed(2) + " TB";
    } else if (data / 1024 / 1024 / 1024 >= 1) {
      size = (data / 1024 / 1024 / 1024).toFixed(2) + " GB";
    } else if (data / 1024 / 1024 >= 1) {
      size = (data / 1024 / 1024).toFixed(2) + " MB";
    } else {
      size = (data / 1024).toFixed(2) + " KB";
    }
    return size;
  }

  saveLogs() {
    var today = new Date();
    let param = {
      username: this.loggedUser.username,
      targetPage: "projects/" + this.projectId + "/jobs",
      day: today.getDay(),
      month: today.getMonth(),
      year: today.getFullYear(),
    };
    this.commonServiceService.saveLogs(param).subscribe((data) => { });
  }
  //Back end code implemetation for pagination 
  getList(event: any) {
    
    this.page = event;
    // this.getJobs(this.page - 1, this.tableSize);
    this.applyFilters(false, this.page - 1, this.tableSize)

  }
}
