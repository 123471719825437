import { NgModule } from "@angular/core";
import { Routes, RouterModule, ChildrenOutletContexts } from "@angular/router";

//App Components
import { ProjectsComponent } from "./components/projects/projects.component";
import { DashboardComponent } from "./components/dashboard/dashboard.component";
import { UsersComponent } from "./components/users/users.component";
import { SettingsComponent } from "./components/settings/settings.component";
import { ProfileComponent } from "./components/profile/profile.component";
import { QueryComponent } from "./components/projects/query/query.component";
import { JobsComponent } from "./components/projects/jobs/jobs.component";
import { CubesComponent } from "./components/projects/cubes/cubes.component";
import { AdvancedSettingsComponent } from "./components/projects/cubes/designer/advanced-settings/advanced-settings.component";

import { DimensionsComponent } from "./components/projects/cubes/designer/dimensions/dimensions.component";
import { InfoComponent } from "./components/projects/cubes/designer/info/info.component";

import { MeasuresComponent } from "./components/projects/cubes/designer/measures/measures.component";
import { OverviewComponent } from "./components/projects/cubes/designer/overview/overview.component";
import { RefreshSettingsComponent } from "./components/projects/cubes/designer/refresh-settings/refresh-settings.component";
import { StreamingConfigComponent } from "./components/projects/cubes/designer/streaming-config/streaming-config.component";
import { ConditionsSettingsComponent } from "./components/projects/models/designer/conditions-settings/conditions-settings.component";
import { DataModelComponent } from "./components/projects/models/designer/data/data-model.component";
import { ModelDimensionsComponent } from "./components/projects/models/designer/dimensions/model-dimensions.component";
import { ModelInfoComponent } from "./components/projects/models/designer/info/model-info.component";
import { ModelMeasuresComponent } from "./components/projects/models/designer/measures/model-measures.component";

import { CubeDetailComponent } from "./components/projects/cubes/detail/cube-detail.component";

import { VisualCubeComponent } from "./components/projects/cubes/visual-cube/visual-cube.component";
import { SegmentsComponent } from "./components/projects/cubes/segments/segments.component";
import { AssignmentComponent } from "./components/projects/cubes/assignment/assignment.component";
import { HybridEditComponent } from "./components/projects/hybrid-edit/hybrid-edit.component";

import { CreateSchedulerComponent } from "./components/projects/jobs/scheduler/create/create-scheduler.component";

import { JobSchedulerComponent } from "./components/projects/jobs/scheduler/job-scheduler.component";
// import { SchedulerStatusComponent } from './components/projects/jobs/scheduler/scheduler-status/scheduler-status.component';

import { QueryDetailComponent } from "./components/projects/query/detail/query-detail.component";
import { ModelCloneComponent } from "./components/projects/models/clone/model-clone.component";
import { ModelDetailComponent } from "./components/projects/models/detail/model-detail.component";
import { ModelEditComponent } from "./components/projects/models/edit/model-edit.component";
import { ModelJsonEditComponent } from "./components/projects/models/json-edit/model-json-edit.component";

import { ProjectAccessComponent } from "./components/projects/access/project-access.component";

import { ProjectDataSourcesComponent } from "./components/projects/data-sources/project-data-sources.component";

import { UserManagementViewComponent } from "./components/users/view/user-management-view.component";

import { AuthComponent } from "./components/auth/auth.component";
import { AuthGuard } from "./guard/auth/auth.guard";
import { MenuComponent } from "./components/menu/menu.component";
import { ProjectDashboardComponent } from "./components/projects/dashboard/project-dashboard.component";

import { CubeDesignerComponent } from "./components/projects/cubes/designer/cube-designer.component";
import { ModelsComponent } from "./components/projects/models/models.component";

import { ConfigStreamingComponent } from "./components/projects/data-sources/config-streaming/config-streaming.component";
import { ConfigStreamingVtwoComponent } from "./components/projects/data-sources/config-streaming-vtwo/config-streaming-vtwo.component";
import { StreamingVtwoComponent } from "./components/projects/data-sources/streaming-vtwo/streaming-vtwo.component";
import { JsonParseComponent } from "./components/projects/data-sources/config-streaming/json-parse/json-parse.component";
import { KafkaSettingsComponent } from "./components/projects/data-sources/config-streaming/kafka-settings/kafka-settings.component";
import { SqlQueryComponent } from "./components/projects/query/sql-query/sql-query.component";

import { CatalogTablesComponent } from "./components/data-catalog/catalog-tables/catalog-tables.component";
import { DashBoardComponent } from "./components/data-catalog/dash-board/dash-board.component";
import { CatalogColumnsComponent } from "./components/data-catalog/catalog-columns/catalog-columns.component";
import { CatalogUserDashBoardComponent } from "./components/data-catalog/catalog-user-dash-board/catalog-user-dash-board.component";
import { CatalogUserTablesComponent } from "./components/data-catalog/catalog-user-tables/catalog-user-tables.component";
import { CartComponent } from "./components/data-catalog/cart/cart.component";
import { BlankLayoutComponent } from "./components/common/layouts/blank-layout/blank-layout.component";
import { BasicLayoutComponent } from "./components/common/layouts/basic-layout/basic-layout.component";
import { EditUserComponent } from "./components/users/edit-user/edit-user.component";
import { DataCatalogRequestsComponent } from "./components/data-catalog/data-catalog-requests/data-catalog-requests.component";
import { DataCatalogDetailsComponent } from "./components/data-catalog/data-catalog-details/data-catalog-details.component";
import { JobSchedulerViewComponent } from "./components/projects/jobs/scheduler/view/job-scheduler-view.component";
import { ColumnsComponent } from "./components/projects/data-sources/details/columns/columns.component";
import { ExtendInformationComponent } from "./components/projects/data-sources/details/extend-information/extend-information.component";
import { SnapshotComponent } from "./components/projects/data-sources/details/snapshot/snapshot.component";
import { DataCatalogAccessComponent } from "./components/data-catalog/data-catalog-access/data-catalog-access.component";
import { DataCatalogRequestDetailsComponent } from "./components/projects/data-catalog/data-catalog-request-details/data-catalog-request-details.component";
import { DataCatalogRequestListComponent } from "./components/projects/data-catalog/data-catalog-request-list/data-catalog-request-list.component";
import { ProjectLayoutComponent } from "./components/common/layouts/project-layout/project-layout.component";
import { PasswordResetComponent } from "./components/auth/password-reset/password-reset.component";
import { PasswordResetPageComponent } from "./components/auth/password-reset-page/password-reset-page.component";
import { CatalogHistoryComponent } from "./components/data-catalog/catalog-history/catalog-history.component";
import { AdministrationComponent } from "./components/administration/administration.component";
import { DataSourceComponent } from "./components/administration/data-source/data-source.component";
import { VisualModelComponent } from "./components/projects/models/visual-model/designer/visual-model.component";
import { VisualModelViewComponent } from "./components/projects/models/visual-model/view/visual-model-view.component";
import { LicenceComponent } from "./components/licences/licence/licence.component";
import { LicenceDetailsComponent } from "./components/licences/licence-details/licence-details.component";
import { VisualModelUpdateComponent } from "./components/projects/models/visual-model/update/visual-model-update.component";
import { CoreServicesComponent } from "./components/core-services/core-services.component";
import { PageNotFoundComponent } from "./components/page-not-found/page-not-found.component";
import { UserTrailComponent } from "./components/administration/user-trail/user-trail.component";
import { QueryAdminComponent } from "./components/query-admin/query-admin.component";
import { ViewQueryComponent } from "./components/view-query/view-query.component";
import { StreamConfigComponent } from "./components/projects/cubes/designer/streaming-config-new/stream-config.component";

const routes: Routes = [
  { path: "", redirectTo: "auth", pathMatch: "full" },
  {
    path: "",
    component: BlankLayoutComponent,
    children: [
      {
        path: "auth",
        children: [
          { path: "", component: AuthComponent },
          { path: "password-reset", component: PasswordResetComponent },
        ],
      },
    ],
  },
  { path: "password-reset-page", component: PasswordResetPageComponent },
  // { path: 'j_spring_security_logout',
  //resolve: {
  //url:"localhost:4200/j_spring_security_logout"
  //}
  //},
  {
    path: "menu",
    component: MenuComponent,
    canActivate: [AuthGuard],
    data: { role: ["ROLE_ADMIN", "ROLE_MODELER", "ROLE_ANALYST"] },
  },
  {
    path: "eula",
    component: LicenceComponent,
    canActivate: [AuthGuard],
    data: { role: ["ROLE_ADMIN", "ROLE_MODELER", "ROLE_ANALYST"] },
  },
  {
    path: "license",
    component: LicenceDetailsComponent,
    canActivate: [AuthGuard],
    data: { role: ["ROLE_ADMIN", "ROLE_MODELER", "ROLE_ANALYST"] },
  },
  {
    path: "",
    component: BasicLayoutComponent,
    children: [
      {
        path: "dashboard",
        component: DashboardComponent,
        canActivate: [AuthGuard],
        data: { role: ["ROLE_ADMIN", "ROLE_MODELER", "ROLE_ANALYST"] },
      },
      {
        path: "projects/:projectId",
        component: ProjectLayoutComponent,
        children: [
          {
            path: "",
            canActivate: [AuthGuard],
            data: { role: ["ROLE_ADMIN", "ROLE_MODELER", "ROLE_ANALYST"] },
            children: [
              { path: "", component: ProjectsComponent },
              { path: "dashboard", component: ProjectDashboardComponent },
              {
                path: "project-access",
                children: [{ path: "", component: ProjectAccessComponent }],
              },

              {
                path: "data-sources",
                children: [
                  { path: "", component: ProjectDataSourcesComponent },
                  {
                    path: "configure-data-source",
                    component: DataSourceComponent,
                  },
                  { path: "columns", component: ColumnsComponent },
                  {
                    path: "extend-information",
                    component: ExtendInformationComponent,
                  },
                  { path: "stream-details", component: SnapshotComponent },
                  {
                    path: ":streamTableId/edit",
                    component: KafkaSettingsComponent,
                  },
                  {
                    path: "realtime-streaming",
                    component: ConfigStreamingVtwoComponent,
                  },

                  {
                    path: "config-streaming",
                    children: [
                      { path: "", component: ConfigStreamingComponent },
                      { path: "json-parse", component: JsonParseComponent },
                      {
                        path: "kafka-settings",
                        component: KafkaSettingsComponent,
                      },
                    ],
                  },
                ],
              },

              {
                path: "query",
                children: [
                  { path: "", component: QueryComponent },
                  { path: "detail", component: QueryDetailComponent },
                  { path: "sql", component: SqlQueryComponent },
                ],
              },
              {
                path: "data-catalog",
                canActivate: [AuthGuard],
                data: { role: ["ROLE_ADMIN", "ROLE_MODELER", "ROLE_ANALYST"] },
                children: [
                  { path: "", component: DataCatalogRequestListComponent },
                  {
                    path: "requests",
                    component: DataCatalogRequestListComponent,
                  },
                  {
                    path: "requests/:requestId",
                    component: DataCatalogRequestDetailsComponent,
                  },
                ],
              },
              //{ path:'scheduler/:schedulerId',
              //canActivate: [AuthGuard],
              //data: { role: ['ROLE_ADMIN', 'ROLE_MODELER', 'ROLE_ANALYST'] },
              //children: [
              //{ path: '', component: JobSchedulerComponent }//,
              //{ path: 'edit', component: CreateSchedulerComponent }
              //]
              //},
              {
                path: "scheduler",
                canActivate: [AuthGuard],
                data: { role: ["ROLE_ADMIN", "ROLE_MODELER", "ROLE_ANALYST"] },
                children: [
                  { path: "", component: JobSchedulerComponent },
                  {
                    path: "create",
                    component: CreateSchedulerComponent,
                    canActivate: [AuthGuard],
                    // data: { role: ['ROLE_ADMIN'] }
                  },
                  {
                    path: ":schedulerId/edit",
                    component: CreateSchedulerComponent,
                  },
                  // ,
                  // { path: 'view-job-status', component: SchedulerStatusComponent }
                ],
              },
              { path: "hybrid/:mode", component: HybridEditComponent },
              {
                path: "hybrid/:mode/:hybridId",
                component: HybridEditComponent,
              },
              {
                path: "jobs",
                canActivate: [AuthGuard],
                data: { role: ["ROLE_ADMIN", "ROLE_MODELER", "ROLE_ANALYST"] },
                children: [
                  { path: "", component: JobsComponent },

                  {
                    path: "scheduler",
                    canActivate: [AuthGuard],
                    data: {
                      role: ["ROLE_ADMIN", "ROLE_MODELER", "ROLE_ANALYST"],
                    },
                    children: [
                      {
                        path: "create",
                        component: CreateSchedulerComponent,
                        canActivate: [AuthGuard],
                        data: { role: ["ROLE_ADMIN"] },
                      },
                    ],
                  },
                ],
              },
              {
                path: "visual-model",
                children: [
                  { path: "", component: VisualModelComponent },
                  {
                    path: ":modelId/details",
                    component: VisualModelViewComponent,
                  },
                  {
                    path: ":modelId/edit",
                    component: VisualModelUpdateComponent,
                  },
                ],
              },
              {
                path: "models/:modelId",
                canActivate: [AuthGuard],
                children: [
                  { path: "", component: ModelsComponent },
                  { path: "cubes", component: CubesComponent },
                  { path: "clone", component: ModelCloneComponent },
                  { path: "detail", component: ModelDetailComponent },
                  { path: "edit", component: ModelEditComponent },
                  { path: "json-edit", component: ModelJsonEditComponent },

                  {
                    path: "designer",
                    children: [
                      {
                        path: "conditions-settings",
                        component: ConditionsSettingsComponent,
                      },
                      { path: "data", component: DataModelComponent },
                      {
                        path: "dimensions",
                        component: ModelDimensionsComponent,
                      },
                      { path: "info", component: ModelInfoComponent },
                      { path: "measures", component: ModelMeasuresComponent },
                    ],
                  },
                ],
              },
              {
                path: "models",
                children: [
                  { path: "", component: ModelsComponent },
                  {
                    path: "designer",
                    children: [
                      {
                        path: "conditions-settings",
                        component: ConditionsSettingsComponent,
                      },
                      { path: "data", component: DataModelComponent },
                      {
                        path: "dimensions",
                        component: ModelDimensionsComponent,
                      },
                      { path: "info", component: ModelInfoComponent },
                      { path: "measures", component: ModelMeasuresComponent },
                    ],
                  },
                ],
              },
              {
                path: "cubes/:cubeId",
                canActivate: [AuthGuard],
                data: { role: ["ROLE_ADMIN", "ROLE_MODELER", "ROLE_ANALYST"] },
                children: [
                  { path: "", component: CubesComponent },
                  {
                    path: "designer",
                    children: [
                      { path: "", component: CubeDesignerComponent },
                      {
                        path: "advanced-settings",
                        component: AdvancedSettingsComponent,
                      },

                      { path: "dimensions", component: DimensionsComponent },
                      { path: "info", component: InfoComponent },

                      { path: "measures", component: MeasuresComponent },
                      { path: "overview", component: OverviewComponent },
                      {
                        path: "refresh-settings",
                        component: RefreshSettingsComponent,
                      },
                      {
                        path: "streaming-config",
                        component: StreamConfigComponent,
                      },
                    ],
                  },

                  { path: "detail", component: CubeDetailComponent },
                  { path: "visual-cube", component: VisualCubeComponent },
                  { path: "segments", component: SegmentsComponent },
                ],
              },
              {
                path: "cubes",
                children: [
                  { path: "", component: CubesComponent },
                  {
                    path: "designer",
                    children: [
                      { path: "", component: InfoComponent },
                      {
                        path: "advanced-settings",
                        component: AdvancedSettingsComponent,
                      },

                      { path: "dimensions", component: DimensionsComponent },
                      { path: "info", component: InfoComponent },

                      { path: "measures", component: MeasuresComponent },
                      { path: "overview", component: OverviewComponent },
                      {
                        path: "refresh-settings",
                        component: RefreshSettingsComponent,
                      },
                      {
                        path: "streaming-config",
                        component: StreamConfigComponent,
                      },
                      { path: "visual-cube", component: VisualCubeComponent },
                      {
                        path: ":cubeName/cube-assignment",
                        component: AssignmentComponent,
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        path: "projects",
        component: ProjectsComponent,
        canActivate: [AuthGuard],
        data: { role: ["ROLE_ADMIN", "ROLE_MODELER", "ROLE_ANALYST"] },
      },
      {
        path: "users",
        component: UsersComponent,
        // canActivate: [AuthGuard],
        // data: { role: ['ROLE_ADMIN', 'ROLE_MODELER', 'ROLE_ANALYST'] },
        children: [
          { path: "users/view", component: UserManagementViewComponent },
        ],
      },
      {
        path: "users/edit",
        component: EditUserComponent,
        canActivate: [AuthGuard],
        data: { role: ["ROLE_ADMIN"] },
      },
      {
        path: "users/view",
        component: UserManagementViewComponent,
        canActivate: [AuthGuard],
        data: { role: ["ROLE_ADMIN"] },
      },

      {
        path: "administration",
        canActivate: [AuthGuard],
        data: { role: ["ROLE_ADMIN"] },
        children: [
          { path: "", component: AdministrationComponent },
          { path: "query", component: QueryAdminComponent },
          // {
          //   path: 'query',
          //   children: [
          //     {
          //       path: '',
          //       component: QueryAdminComponent,
          //     },
          //     {
          //       path: 'view-query/:queryId',
          //       component: ViewQueryComponent
          //     }
          //   ]
          // },
          { path: "query/:queryId", component: ViewQueryComponent },
          {
            path: "settings",
            component: SettingsComponent,
            canActivate: [AuthGuard],
            data: { role: ["ROLE_ADMIN"] },
          },
          {
            path: "streaming-receivers",
            component: StreamingVtwoComponent,
            canActivate: [AuthGuard],
            data: { role: ["ROLE_ADMIN"] },
          },
          // {
          //   path: 'core-services', component: CoreServicesComponent,
          //   canActivate: [AuthGuard],
          //   data: { role: ['ROLE_ADMIN'] }
          // },
          {
            path: "page-trails",
            component: UserTrailComponent,
            canActivate: [AuthGuard],
            data: { role: ["ROLE_ADMIN"] },
          },
          {
            path: "users",
            component: UsersComponent,
            // canActivate: [AuthGuard],
            // data: { role: ['ROLE_ADMIN', 'ROLE_MODELER', 'ROLE_ANALYST'] },
            children: [
              { path: "users/view", component: UserManagementViewComponent },
            ],
          },
        ],
      },
      //{
      //path: 'profile', component: ProfileComponent,
      // canActivate: [AuthGuard],
      // data: { role: ['ROLE_ADMIN', 'ROLE_MODELER', 'ROLE_ANALYST'] }
      //},
      {
        path: "data-catalog",
        canActivate: [AuthGuard],
        data: { role: ["ROLE_ADMIN", "ROLE_MODELER", "ROLE_ANALYST"] },
        children: [
          { path: "", component: CatalogUserDashBoardComponent },
          {
            path: "catalog-dash-board",
            component: CatalogUserDashBoardComponent,
          },
          { path: "catalog-tables/:id", component: CatalogUserTablesComponent },
          { path: "cart", component: CartComponent },
        ],
      },
      /*menu changes for denodo */
      {
        path: "requests",
        component: DataCatalogRequestsComponent,
        canActivate: [AuthGuard],
        data: { role: ["ROLE_ADMIN"] },
      },
      {
        path: "history",
        component: CatalogHistoryComponent,
        canActivate: [AuthGuard],
        data: { role: ["ROLE_ADMIN"] },
      },
      {
        path: "requests/details",
        component: DataCatalogDetailsComponent,
        canActivate: [AuthGuard],
        data: { role: ["ROLE_ADMIN"] },
      },
      {
        path: "access",
        component: DataCatalogAccessComponent,
        canActivate: [AuthGuard],
        data: { role: ["ROLE_ADMIN"] },
      },
      /*ended menu changes for denodo */
    ],
  },
  { path: "404", component: PageNotFoundComponent },
  { path: "**", redirectTo: "404" },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      enableTracing: false,
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
