import { Component, OnInit, AfterViewInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Location } from "@angular/common";
import { ModelService } from "../../../services/model/model.service";
import { ModelDescService } from "../../../services/model/model-desc.service";
import { CubesService } from "../../../services/cubes/cubes.service";
import { HybridService } from "../../../services/hybrid/hybrid.service";
import swal from "sweetalert2";
import {
  CdkDragDrop,
  moveItemInArray,
  transferArrayItem,
} from "@angular/cdk/drag-drop";

@Component({
  selector: "app-hybrid-edit",
  templateUrl: "./hybrid-edit.component.html",
  styleUrls: ["./hybrid-edit.component.scss"],
})
export class HybridEditComponent implements OnInit, AfterViewInit {
  mode: any;
  projectName: string;
  models: any;
  selectedModel: string;
  leftCubes: CubeObj[];
  rightCubes: CubeObj[];
  hybridName: string;
  hybrid: any = {};
  cubeList: any = [];
  invalidName;
  errorStatus;
  constructor(
    private route: ActivatedRoute,
    private modelService: ModelService,
    private modelDescService: ModelDescService,
    private cubeService: CubesService,
    private hybridService: HybridService,
    private location: Location,
    private router: Router
  ) {}

  ngAfterViewInit() {
    setTimeout(function () {
      /* custome select option starts*/
      var x, i, j, selElmnt, a, b, c;
      x = document.getElementsByClassName("select-custom");
      for (i = 0; i < x.length; i++) {
        selElmnt = x[i].getElementsByTagName("select")[0];
        a = document.createElement("DIV");
        a.setAttribute("class", "select-selected");
        a.innerHTML = selElmnt.options[selElmnt.selectedIndex].innerHTML;
        x[i].appendChild(a);
        b = document.createElement("DIV");
        b.setAttribute("class", "select-items select-hide");
        for (j = 1; j < selElmnt.length; j++) {
          c = document.createElement("DIV");
          c.innerHTML = selElmnt.options[j].innerHTML;
          c.addEventListener("click", function (e) {
            var y, i, k, s, h;
            s = this.parentNode.parentNode.getElementsByTagName("select")[0];
            h = this.parentNode.previousSibling;
            for (i = 0; i < s.length; i++) {
              if (s.options[i].innerHTML == this.innerHTML) {
                s.selectedIndex = i;
                h.innerHTML = this.innerHTML;
                y = this.parentNode.getElementsByClassName("same-as-selected");
                for (k = 0; k < y.length; k++) {
                  y[k].removeAttribute("class");
                }
                this.setAttribute("class", "same-as-selected");
                break;
              }
            }
            h.click();
          });
          b.appendChild(c);
        }
        x[i].appendChild(b);
        a.addEventListener("click", function (e) {
          e.stopPropagation();
          closeAllSelect(this);
          this.nextSibling.classList.toggle("select-hide");
          this.classList.toggle("select-arrow-active");
        });
      }

      function closeAllSelect(elmnt) {
        var x,
          y,
          i,
          arrNo = [];
        x = document.getElementsByClassName("select-items");
        y = document.getElementsByClassName("select-selected");
        for (i = 0; i < y.length; i++) {
          if (elmnt == y[i]) {
            arrNo.push(i);
          } else {
            y[i].classList.remove("select-arrow-active");
          }
        }
        for (i = 0; i < x.length; i++) {
          if (arrNo.indexOf(i)) {
            x[i].classList.add("select-hide");
          }
        }
      }
      document.addEventListener("click", closeAllSelect);
      /* custom select option end */
    }, 3000);
  }

  ngOnInit() {
    this.selectedModel = "";
    this.projectName = this.route.snapshot.paramMap.get("projectId");
    this.mode = this.route.snapshot.paramMap.get("mode");
    this.getModels();
    if (this.mode === "edit") {
      this.hybridName = this.route.snapshot.paramMap.get("hybridId");
      this.hybridService.getHybrid(this.hybridName).subscribe((data) => {
        this.hybrid = data;
        this.selectedModel = this.hybrid.modelName;
        this.getCubeList();
      });
    }
  }
  getModels() {
    return this.modelService.list(this.projectName).subscribe((data) => {
      this.models = data;
    });
  }
  selectChangeHandler(event) {
    this.selectedModel = event.name;
    this.updateCubeList(this.selectedModel);
  }
  updateCubeList(modelName: string) {
    this.cubeService
      .list({
        projectName: this.projectName,
        modelName: this.selectedModel,
      })
      .subscribe((data) => {
        this.rightCubes = [];
        this.leftCubes = [];
        const cubes = data;
        // tslint:disable-next-line:only-arrow-functions
        this.leftCubes = Object.keys(cubes).map(function (c) {
          const cube = cubes[c];
          return new CubeObj(cube.name, cube.status);
        });
      });
  }
  getCubeList() {
    this.cubeService
      .list({
        projectName: this.projectName,
        modelName: this.selectedModel,
      })
      .subscribe((data) => {
        this.rightCubes = [];
        this.leftCubes = [];
        this.cubeList = data;
        for (let r of this.hybrid.hybridInstance.realizations) {
          let cube = {
            name: "",
            status: "",
          };
          cube.name = r.realization;

          for (let c of this.cubeList) {
            if (c.name === r.realization) {
              cube.status = c.status;
            }
          }
          this.rightCubes.push(new CubeObj(cube.name, cube.status));
        }
        let found = false;
        for (let c of this.cubeList) {
          found = false;
          for (let r of this.hybrid.hybridInstance.realizations) {
            if (c.name === r.realization) {
              found = true;
            }
          }
          if (!found) {
            this.leftCubes.push(new CubeObj(c.name, c.status));
          }
        }
      });
  }

  cubeCount(cubeArr: string) {
    if (cubeArr === "R") {
      if (!this.rightCubes || this.rightCubes.length === 0) {
        return 0;
      }
      return this.rightCubes.length;
    }
    if (cubeArr === "L") {
      if (!this.leftCubes || this.leftCubes.length === 0) {
        return 0;
      }
      return this.leftCubes.length;
    }
  }

  isActive() {
    if (!!this.rightCubes) {
      if (this.rightCubes.length > 1 && !!this.hybridName) {
        return true;
      }
    }
    return false;
  }

  update() {
    let nameArr: string[] = [];
    for (let cube of this.rightCubes) {
      nameArr.push(cube.name);
    }
    const hybridObj = {
      cubes: nameArr,
      hybrid: this.hybridName,
      model: this.selectedModel,
      project: this.projectName,
    };
    swal({
      type: "warning",
      title: "Are you sure you want to update the hybrid?",
      text: "",
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "No",
    }).then(
      (status) => {
        let errors: any = null;
        if (status.value == true) {
          this.hybridService.updateHybrids(hybridObj).subscribe(
            (result) => {
              swal("Success!", "Hybrid updated successfully", "success");
              this.router.navigate(["projects", this.projectName, "models"]);
            },
            (error) => {
              let e = error;
              errors = error;
              swal({
                type: "error",
                title: "Error",
                text: e.error.msg,
                showCancelButton: false,
                confirmButtonText: "Ok",
              });
            }
          );
        }
      },
      (dismiss) => {
        return;
      }
    );
  }

  submit() {
    let nameArr: string[] = [];
    for (let cube of this.rightCubes) {
      nameArr.push(cube.name);
    }
    const hybridObj = {
      cubes: nameArr,
      hybrid: this.hybridName,
      model: this.selectedModel,
      project: this.projectName,
    };
    swal({
      type: "warning",
      title: "Are you sure you want to save the hybrid?",
      text: "",
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "No",
    }).then(
      (status) => {
        let errors: any = null;
        if (status.value == true) {
          this.hybridService.createHybrids(hybridObj).subscribe(
            (result) => {
              swal("Success!", "Hybrid created successfully", "success");
              this.router.navigate(["projects", this.projectName, "models"]);
            },
            (error) => {
              let e = error;
              errors = error;
              swal({
                type: "error",
                title: "Error",
                text: e.error.msg,
                showCancelButton: false,
                confirmButtonText: "Ok",
              });
            }
          );
        }
      },
      (dismiss) => {
        return;
      }
    );
  }

  cancel() {
    this.location.back();
  }
  drop(event: CdkDragDrop<CubeObj[]>) {
    if (event.previousContainer === event.container) {
      moveItemInArray(
        event.container.data,
        event.previousIndex,
        event.currentIndex
      );
    } else {
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex
      );
    }
  }

  validateName(hybridName) {
    let pattern = "^[a-z0-9A-Z_]{3,100}$";
    if (!hybridName.match(pattern)) {
      this.invalidName = true;
      this.errorStatus = true;
    } else {
      this.invalidName = false;
      this.errorStatus = false;
    }
  }
}
class CubeObj {
  name: string;
  status: string;
  constructor(name, status) {
    this.name = name;
    this.status = status;
  }
}
