import { Component, OnInit,ViewChild, AfterViewInit} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ModelService } from '../../../../services/model/model.service';
import { ModelDescService } from '../../../../services/model/model-desc.service';
import swal from 'sweetalert2';
import * as $ from 'jquery';

@Component({
  selector: 'app-model-json-edit',
  templateUrl: './model-json-edit.component.html',
  styleUrls: ['./model-json-edit.component.scss']
})
export class ModelJsonEditComponent implements OnInit,AfterViewInit {
  projectName;
  modelName;
  jsonData:any;
  error:boolean=false;
  options:any = {printMargin: false};

  @ViewChild('editor') editor;

  constructor(private modelSerivce: ModelService,private  modelDescService :ModelDescService,private router: Router, private route: ActivatedRoute) { 
    this.projectName = this.route.snapshot.paramMap.get("projectId");
    this.modelName = this.route.snapshot.paramMap.get("modelId");
    
  }

  ngAfterViewInit() {
    $(document).ready(function(){
          var contenth = $( window ).height() - 110 ;
          var sidebarh = $( window ).height() - 111 ;
          $(".pagec").css("height", contenth );
          $(".sidebar-wrapper").css("height", sidebarh );
        });
    }

  ngOnInit() {
    this.getModelDetails();
  }
  getModelDetails(){
    this.modelDescService.query(this.modelName).subscribe(
      data=>{
        this.jsonData = JSON.stringify(data, null, '\t');

    })
    
  }
checkError(){
  try {
    JSON.parse(this.jsonData)
    this.error=false;
  }catch (e) {
    this.error=true;
  }
}
  updateJson(){
    if(!this.jsonData)
      return;

    let model={
      "modelDescData":this.jsonData,
      "modelName":this.modelName,
      "project":this.projectName
    }
    swal({
      type:'warning',
      title: 'Are you sure you want to update the model?',
      html:' <p>Please note: If model schema is changed,</p><p> all cubes of the model will be affected.</p> ',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No'
    }).then((status) => {
      if(status.value==true){
        this.modelSerivce.update(model).subscribe(
        data=>{
          swal("Success!","Json successfully updated", "success");
        }, (error) =>{
              
          swal("Failed",error.error.msg, "error");
        
        });
      }
    }, (dismiss) => {
      if (dismiss === 'cancel') {
      }
    });
  }
}
