import { Component, OnInit, AfterViewInit, EventEmitter } from "@angular/core";
import { CubesService } from "../../../services/cubes/cubes.service";
import { ModelService } from "../../../services/model/model.service";
import { TableService } from "../../../services/table/table.service";
import { SegmentsService } from "../../../services/segment/segments.service";
import { AdminService } from '../../../services/admin/admin.service';//To enable Mdx flage this code integrated
import { DatePipe } from "@angular/common";
import swal from "sweetalert2";
import { ActivatedRoute, Router } from "@angular/router";
import {
  FormGroup,
  FormControl,
  FormBuilder,
  Validators,
  FormArray,
} from "@angular/forms";
import { CalendarModule } from "primeng/calendar";
import { OrderPipe } from "ngx-order-pipe";
import { PARAMETERS } from "@angular/core/src/util/decorators";
import * as moment from "moment";
import { OWL_DATE_TIME_LOCALE } from "ng-pick-datetime";
import { DateTimeAdapter } from "ng-pick-datetime";
import { OwlDateTimeModule, OWL_DATE_TIME_FORMATS } from "ng-pick-datetime";
import { OwlMomentDateTimeModule } from "ng-pick-datetime-moment";
import { StreamingService } from "src/app/services/streaming/streaming.service";
import { CommonServiceService } from "../../../services/common-services/common-service.service";
import * as $ from "jquery";

@Component({
  selector: "app-cubes",
  templateUrl: "./cubes.component.html",
  styleUrls: ["./cubes.component.scss"],
  providers: [DatePipe],
})
export class CubesComponent implements OnInit, AfterViewInit {
  loggedUser;
  cubeDetails: any[] = [];
  cubesAll;
  showModel: boolean = false;
  showDelete: boolean = false;
  showMerge: boolean = false;
  showSegment: boolean = true;
  closebtnMo: boolean;
  cubeName;
  cubeCount;
  modelDetails;
  modelName;
  projectName;
  project;
  cubeList;
  cName;
  showClone: boolean;
  getTotalSize: number;
  descriptor: any;
  segment: any[];
  cube: any = [];
  segementSelect: any;
  html: any;
  segementEnd: any;
  errorMsg: String;
  startDate;
  endDate;
  showUpdate;
  lookup;
  lookupTables;
  allSegment;
  lookUpSelect;
  lookupSegment = [];
  dropdownList = [];
  selectedItems = [];
  dropdownSettings = {};
  showSubmit;
  endTime;
  startTime;
  filterValue;
  order: string = "cube.create_time_utc";
  reverse: boolean = false;
  sortedCollection;
  load;
  searchText;
  startSegment;
  endSegment;
  page = 1;
  segmentpage = 1;
  qubzLicenseExpire: any;
  cubeBuildForm: FormGroup;
  model;
  selectedCube;
  checkAll = false;
  anySegmentSelected = false;
  cubeSegments = [];
  projectModels: any;
  assignments: any;
  assign;
  kafkaForm: FormGroup;
  public serverList: FormArray;
  availableClusters: any;
  nodeList = [];
  rs_id = [];
  partitions = [];
  partitionList = [];
  public focusSettingEventEmitter = new EventEmitter<boolean>();
  cubeSelectAll = false;
  cubeSelected = false;
  cubeNames = [];
  cubeRecords: number[] = [];
  //To enable Mdx flage this code integrated
  config: any;
  ismdxEnable = false;
  configSplitted: any;
  pageSize = 10;
  count: any;
  cnamePattern = "^[A-Za-z0-9_]{1,100}$";

  //getTotalSize;

  // showModal : boolean;
  // showAlert : boolean;
  constructor(
    private cubesService: CubesService,
    private adminService: AdminService,
    private modelService: ModelService,
    private tableService: TableService,
    private route: ActivatedRoute,
    private router: Router,
    private orderPipe: OrderPipe,
    private datePipe: DatePipe,
    private streamingService: StreamingService,
    private segmentsService: SegmentsService,
    private fb: FormBuilder,
    public commonServiceService: CommonServiceService,
    dateTimeAdapter: DateTimeAdapter<any>
  ) {
    this.closebtnMo = false;
    this.kafkaForm = fb.group({
      servers: this.fb.array([], [Validators.required]),
    });
    this.serverList = this.kafkaForm.get("servers") as FormArray;
    //this.sortedCollection = orderPipe.transform(this.cubeDetails, 'create_time_utc');
    this.loggedUser = JSON.parse(sessionStorage.getItem("loggedUser"));
  }
  get server() {
    return this.kafkaForm.get("servers") as FormArray;
  }
  newServer(): FormGroup {
    return this.fb.group({
      cluster: ["", [Validators.required]],
      partition: ["", [Validators.required]],
    });
  }
  addServer() {
    this.serverList.push(this.newServer());
  }
  removeServer(i: number) {
    this.serverList.removeAt(i);
  }
  settings = {
    bigBanner: true,
    timePicker: true,
    format: "dd-MMM-yyyy HH:mm:ss",
    defaultOpen: false,
  };

  ngAfterViewInit() {
    $(document).ready(function () {
      var contenth = $(window).height() - 110;
      var sidebarh = $(window).height() - 111;
      $(".pagec").css("height", contenth);
      $(".sidebar-wrapper").css("height", sidebarh);
    });
    this.getmdxEnableConfig();//To enable Mdx flage this code integrated
    this.focusSettingEventEmitter.emit(true);

    $(document).on("click", ".segment_cb", function () {
      function checkboxactive() {
        if ($(".segment_cb").is(":checked")) {
          $(".delete_disabled").addClass("displaynone");
          $(".delete_segment.all").removeClass("disabledbtn");
          $(".merge_disabled").addClass("displaynone");
          $(".merge_segment").removeClass("disabledbtn");
        } else {
          $(".delete_disabled").removeClass("displaynone");
          $(".delete_segment.all").addClass("disabledbtn");
          $(".merge_disabled").removeClass("displaynone");
          $(".merge_segment").addClass("disabledbtn");
        }
      }

      setTimeout(checkboxactive, 2);
    });
  }

  ngOnInit() {
    //QUBZ-3887 Cubes Storage size was not adding initially 23/9/22
    //called the list function which loads the cubes details inside the call which loads the model details 
    //also set load as true to fix the no cubes message issue initailly
    this.load = true;
    this.showSegment = false;
    this.project = this.route.snapshot.paramMap.get("projectId");
    this.modelService.list(this.project).subscribe((data) => {
      this.projectModels = data;
      this.getmdxEnableConfig();//To enable Mdx flage this code integrated
      this.list(this.page);

    });
    this.model = this.route.snapshot.paramMap.get("modelId");
    //this.cloneCube(this.cube);
    //this.startRefresh(this.cube);
    //this.jobsubmitctrl(this.cube);
    this.dropdownSettings = {
      singleSelection: false,
      idField: "id",
      textField: "name",
      selectAllText: "Select All",
      unSelectAllText: "Unselect All",
      itemsShowLimit: 7,
      allowSearchFilter: true,
    };

    this.showModel = false;
    this.qubzLicenseExpire = JSON.parse(
      sessionStorage.getItem("qubzLicenseExpire")
    );

    this.cubeBuildForm = new FormGroup({
      startTime: new FormControl("", [DateTimeValidator]),
      endTime: new FormControl("", [DateTimeValidator]),
    });

    this.cubesService.visualCubeEditMode = undefined;
    //this.saveLogs();
  }
  setOrder(value: string) {
    if (this.order === value) {
      this.reverse = !this.reverse;
    }

    this.order = value;
  }
  checkTableType(cube) {
    let model = this.projectModels.find(({ name }) => name === cube.model);
    let sourceType = 0;
    if (model) {
      this.tableService.get(this.project, model.fact_table).subscribe(
        (data: any[]) => {
          let res = <any>{};
          res = data;
          sourceType = res.source_type;
          return sourceType;
        },
        (error) => {
          return sourceType;
        }
      );
    }
    return sourceType;
  }
  pause(cube) {
    this.streamingService.suspendConsume(cube).subscribe(
      (data: any[]) => {
        let res = <any>{};
        res = data;
        swal("Success", "Cube pause Successfull!", "success");
        this.list(this.page);
      },
      (error) => {
        swal("Ooops", error.error.msg, "error");
      }
    );
  }
  resume(cube) {
    this.streamingService.resumeConsume(cube).subscribe(
      (data: any[]) => {
        let res = <any>{};
        res = data;
        swal("Success", "Cube Resumed!", "success");
        this.list(this.page);
      },
      (error) => {
        swal("Ooops", error.error.msg, "error");
      }
    );
  }
  checkReadySegement(cube) {
    if (cube.segments) {
      for (let param of cube.segments) {
        if (param.status == "READY") return true;
      }
    }
    return false;
  }
  viewCube(cubeName: string, cubeStatus) {
    this.streamingService.showStatus = cubeStatus;
    this.router.navigate([
      "projects",
      this.project,
      "cubes",
      cubeName,
      "detail",
    ]);
  }
  getSegments(cube) {
    this.segmentsService.getCubeSegments(cube).subscribe((data) => {
      let res: any;
      res = data;
      this.cubeSegments = res;
    });
  }

  modelList(model) {
    this.modelService.get(model).subscribe((data) => {
      this.modelDetails = data[0];
    });
  }

  list(page) {
    const params = {
      modelName: this.model,
      page: page - 1,
      pageSize: this.pageSize
    }
    if (this.model) {
      this.cubesService
        .list({ params })
        .subscribe((data: any[]) => {
          this.load = false;
          this.cubeDetails = data;
          // for (let obj of this.cubeDetails) {
          //   if (obj.status == 'READY') {
          //     this.cubesService
          //       .listMDX(obj.name, obj.projectName)
          //       .subscribe((data: any) => {
          //         obj.mdxStatus = data;

          //       });
          //   }


          // }

          this.cubesAll = data;
          this.cubeCount = Object.keys(data).length;

          this.getTotalSize = 0;
          for (let [keys, values] of Object.entries(data)) {
            let size = +values.size_kb;
            this.getTotalSize = this.getTotalSize + size;
            let model = this.projectModels.find(
              ({ name }) => name === values.model
            );
            this.tableService
              .get(this.project, model.fact_table)
              .subscribe((data: any[]) => {
                let res = <any>{};
                res = data;
                values.sourceType = res.source_type;
              });
            //Qubz 6 UI - comment unwanted API calls for Realtime Streaming
            // this.streamingService
            //   .consumeState(values.name)
            //   .subscribe((data: any[]) => {
            //     values.resumeState = data;
            //   });
          }
          //QUBZ-3883 In Cubes tab initially KB storage size showing MB 21/9/22
          //this.getTotalSize = this.getTotalSize / 1024;
        });
    } else {
      const params = {
        projectName: this.project,
        page: page - 1,
        pageSize: this.pageSize
      }

      this.cubesService
        .getProjectCubesPaginated(params.projectName, params.page, params.pageSize)
        .subscribe((data: any) => {
          console.log(data);
          this.count = data.totalElements;
          // this.cubeDetails = data;
          this.cubesAll = data.content;
          this.cubeCount = Object.keys(data.content).length;
          this.cubeDetails = data.content.map((item) => {
            let size = this.getLastBuild(item.segments, "size");
            let unit: string;
            if (size > 1024) {
              size = size / 1024;
              unit = "MB";
              //QUBZ-3878 cubes page can show cube size in KB, MB, GB and TB. 21/9/22
              if (size > 1024) {
                size = size / 1024;
                unit = "GB";
                if (size > 1024) {
                  size = size / 1024;
                  unit = "TB";
                }
              }
            } else {
              unit = "KB";
            }

            item = {
              ...item,
              sizeData: {
                unit: unit,
                size: size,
              },
            };
            //Qubz 6 UI - comment unwanted API calls for Realtime Streaming

            // this.streamingService.consumeState(item.name).subscribe(
            //   (data: any[]) => {
            //     item.resumeState = data;
            //   },
            //   (error) => {
            //     item.resumeState = error.error.text;
            //   }
            // );
            //Mdx Code integration 
            //To enable Mdx flage this code integrated
            if (item.status == 'READY' && this.ismdxEnable) {
              this.cubesService
                .listMDX(item.name, item.project)
                .subscribe(
                  (data: any[]) => {
                    item.mdxStatus = data;
                  },
                  (error) => {
                    item.mdxStatus = error.error.text;
                  }
                );
            }

            return item;
          });

          this.cubeDetails.forEach((item, index) => {
            if (
              item.realtime_cube &&
              item.status === "READY" &&
              !item.size_kb
            ) {
              this.fetchRecords(item.name, index);

              this.load = false;
            } else {
              this.cubeRecords[index] = this.getLastBuild(
                item.segments,
                "count"
              );
              this.load = false;
            }
          });
          //QUBZ-3904-In Cubes tab records was not changed by using up & down arrow.-13/10/22
          this.cubeDetails.forEach((item, index) => {
            item['records'] = this.cubeRecords[index];
          })
          if (!this.cubeDetails.length) {
            this.load = false;
            return;
          }
          this.getTotalSize = 0;
          for (let values of data.content) {
            let size = +values.size_kb;
            this.getTotalSize = this.getTotalSize + size;
            let model = this.projectModels.find(
              ({ name }) => name === values.model
            );
            this.tableService
              .get(this.project, model.fact_table)
              .subscribe((data: any[]) => {
                let res = <any>{};
                res = data;
                values.sourceType = res.source_type;
              });
          }
          //QUBZ-3883 In Cubes tab initially KB storage size showing MB 21/9/22
          //this.getTotalSize = this.getTotalSize / 1024;
        });
    }
  }
  getList(event) {
    this.page = event;
    this.list(this.page);
  }

  async fetchRecords(name: string, index) {
    let records = await this.cubesService
      .getCubeRecordDetails(name)
      .subscribe((res) => {
        this.cubeRecords[index] = res;
      });

    return true;
  }

  disable(cube) {
    swal({
      type: "warning",
      title: "Are you sure you want to disable the cube?",
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "No",
    }).then(
      (status) => {
        if (status.value == true) {
          if (this.ismdxEnable) //To enable Mdx flage this code integrated
            this.DeleteMDXDS(cube.name, cube.project);//delete mdx data set while disable
          this.cubesService.disable(cube.name, this.cubeDetails).subscribe(
            (data) => {

              this.list(this.page);

              //QUBZ-3804 QUBZ 6.0.0-In Cubes tab Parent Check box should be unselected once action completed 09/08/2022
              this.cubeSelectAll = false;
              this.cubeSelected = false;
              //To enable Mdx flage this code integrated
              if (this.ismdxEnable)
                swal("Success!", "Disable job/MDX has been submitted!", "success");
              else
                swal("Success!", "Disable job has been submitted!", "success");
            },
            (error) => {
              swal("Ooops", error.error.msg, "error");
              //QUBZ-3804 QUBZ 6.0.0-In Cubes tab Parent Check box should be unselected once action completed 09/08/2022
              //QUBZ-3809 in Cubs Tab validation required Parent check box("Parent Check Box was cleared 29/8/22
              if (cube.mdxStatus == false && this.ismdxEnable)//To enable Mdx flage this code integrated
                this.CreateMDXDS(cube.name, cube.project)//Create MDX dataset if error occur while disable cubes  
              this.list(this.page);
              this.cubeSelectAll = false;
              this.cubeSelected = false;
            }
          );

        }
      },
      (dismiss) => {
        if (dismiss === "cancel") {
        }
      }
    );

  }
  openLookupRefresh(cube) {
    this.lookupSegment = null;
    this.lookUpSelect = null;
    this.errorMsg = "";
    this.cube = cube;
    let flag = true;
    this.modelService.get(cube.model).subscribe((data) => {
      this.lookupTables = data[0].lookups;
    });

    this.dropdownList = [];
    for (let [key, value] of Object.entries(cube.segments)) {
      let res = <any>{};
      res = value;
      this.dropdownList.push({ id: res.uuid, name: res.name });
    }
    this.allSegment = false;
    this.lookup = true;
  }
  lookUpRefreshSubmit(cube) {
    if (this.lookupSegment == null) {
      swal("Success!", "Lookup segment should not be empty!", "error");
      return;
    }
    if (this.lookUpSelect == null) {
      swal("Ooops!", "Lookup table should not be empty!", "error");
      return;
    }
    let segmentIDs = [];
    for (let param of this.lookupSegment) {
      for (let [key, value] of Object.entries(this.dropdownList)) {
        if (value.name === param) segmentIDs.push(value.id);
      }
    }
    let lookupSnapshotBuildRequest = {
      lookupTableName: this.lookUpSelect,
      segmentIDs: segmentIDs,
    };
    this.cubesService
      .lookupRefresh(cube.name, lookupSnapshotBuildRequest)
      .subscribe(
        (data) => {
          this.lookup = false;
          //QUBZ-3804 QUBZ 6.0.0-In Cubes tab Parent Check box should be unselected once action completed 09/08/2022
          //QUBZ-3809 in Cubs Tab validation required Parent check box("Parent Check Box was cleared 29/8/22
          this.list(this.page);
          this.cubeSelectAll = false;
          this.cubeSelected = false;
          swal(
            "Success!",
            "Lookup refresh job was submited successfully!",
            "success"
          );
        },
        (error) => {
          swal("Ooops", error.error.msg, "error");
          this.showClone = false;
          //QUBZ-3804 QUBZ 6.0.0-In Cubes tab Parent Check box should be unselected once action completed 09/08/2022
          //QUBZ-3809 in Cubs Tab validation required Parent check box("Parent Check Box was cleared 29/8/22
          this.list(this.page);
          this.cubeSelectAll = false;
          this.cubeSelected = false;
        }
      );
  }
  purge(cube) {
    swal({
      type: "warning",
      title: "Are you sure you want to purge the cube?",
      text: " ",
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "No",
    }).then(
      (status) => {
        if (status.value == true) {
          this.cubesService.purge(cube.name, this.cubeDetails).subscribe(
            (data: any) => {
              // const index = this.cubeDetails.findIndex(item => item.uuid === cube.uuid);
              // this.cubeDetails[index] = {
              //   ...data
              // };
              // this.cubeRecords[index] = 0;
              // this.cubeSize[index] = { size: 0, unit: 'KB' }
              const index = this.cubeDetails.findIndex(
                (c) => c.uuid === data.uuid
              );
              this.cubeRecords[index] = 0;
              this.list(this.page);
              //QUBZ-3804 QUBZ 6.0.0-In Cubes tab Parent Check box should be unselected once action completed 09/08/2022
              this.cubeSelectAll = false;
              this.cubeSelected = false;
              swal(
                "Success!",
                "Purge job was submitted successfully",
                "success"
              );
            },
            (error) => {
              this.load = false;
              swal("Ooops", error.error.msg, "error");
              //QUBZ-3804 QUBZ 6.0.0-In Cubes tab Parent Check box should be unselected once action completed 09/08/2022
              //QUBZ-3809 in Cubs Tab validation required Parent check box("Parent Check Box was cleared 29/8/22
              this.list(this.page);
              this.cubeSelectAll = false;
              this.cubeSelected = false;
            }
          );
        }
      },
      (dismiss) => {
        if (dismiss === "cancel") {
        }
      }
    );
  }

  enable(cube) {
    let model = this.projectModels.find(({ name }) => name === cube.model);
    if (model) {
      this.tableService
        .get(this.project, model.fact_table)
        .subscribe((data: any[]) => {
          let res = <any>{};
          res = data;
          if (res.source_type != 20 && !this.checkReadySegement(cube)) {
            swal(
              "Information!",
              "Cube can be enabled only after building",
              "info"
            );
            return;
          }
          swal({
            type: "warning",
            title:
              "Are you sure you want to enable the cube? Please note: if cube schema is changed in the disabled period, all segments of the cube will be discarded due to data and schema mismatch.",
            text: " ",
            showCancelButton: true,
            confirmButtonText: "Yes",
            cancelButtonText: "No",
          }).then(
            (status) => {
              if (status.value == true) {
                this.cubesService.enable(cube.name, this.cubeDetails).subscribe(
                  (data) => {
                    this.list(this.page);
                    //QUBZ-3804 QUBZ 6.0.0-In Cubes tab Parent Check box should be unselected once action completed 09/08/2022
                    this.cubeSelectAll = false;
                    this.cubeSelected = false;
                    swal(
                      "Success!",
                      "Enable job was submitted successfully",
                      "success"
                    );

                  },
                  (error) => {
                    swal("Ooops", error.error.msg, "error");
                    this.showClone = false;
                    //QUBZ-3804 QUBZ 6.0.0-In Cubes tab Parent Check box should be unselected once action completed 09/08/2022
                    //QUBZ-3809 in Cubs Tab validation required Parent check box("Parent Check Box was cleared 29/8/22
                    this.list(this.page);
                    this.cubeSelectAll = false;
                    this.cubeSelected = false;
                  }
                );
              }
            },
            (dismiss) => {
              if (dismiss === "cancel") {
              }
            }
          );
        });
    }

  }

  deleteCube(cube) {
    if (cube.status == "READY") {
      swal("Information!", "Ready cubes cannot be deleted", "info");
      return;
    }
    swal({
      type: "warning",
      text: "Once it's dropped, your cube's metadata and data will be cleaned up and can't be restored back.",
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "No",
    }).then(
      (status) => {
        if (status.value == true) {
          this.cubesService.drop(cube.name).subscribe(
            (data) => {
              this.list(this.page);
              //QUBZ-3809 in Cubs Tab validation required Parent check box("Parent Check Box was cleared 29/8/22
              this.cubeSelectAll = false;
              this.cubeSelected = false;
              swal("Success!", "Cube has been dropped", "success");
            },
            (error) => {
              //QUBZ-3809 in Cubs Tab validation required Parent check box("Parent Check Box was cleared 29/8/22
              this.list(this.page);
              this.cubeSelectAll = false;
              this.cubeSelected = false;
              swal("Ooops", error.error.msg, "error");
            }
          );
        }
      },
      (dismiss) => {
        if (dismiss === "cancel") {
        }
      }
    );
  }
  buidSubmit(cube) {
    this.cube = cube;
    this.load = true;
    this.modelService
      .getListByModelNameProject({
        modelName: cube.model,
        projectName: cube.project,
      })
      .subscribe((data) => {
        this.modelDetails = data[0];
        this.tableService
          .get(cube.project, this.modelDetails.fact_table)
          .subscribe((data) => {
            this.load = false;
            let res = <any>{};
            res = data;
            if (res && res.source_type == 1) {
              swal({
                type: "warning",
                title: "Are you sure you want to start the build?",
                text: " ",
                showCancelButton: true,
                confirmButtonText: "Yes",
                cancelButtonText: "No",
              }).then(
                (status) => {
                  if (status.value == true) {
                    this.load = true;
                    this.cubesService
                      .buildStreamingCube(cube.name, {
                        sourceOffsetStart: 0,
                        sourceOffsetEnd: "9223372036854775807",
                        buildType: "BUILD",
                      })
                      .subscribe(
                        (data) => {
                          this.load = false;
                          swal(
                            "Success!",
                            "Rebuild job was submitted successfully!",
                            "success"
                          );
                          this.showSubmit = false;
                        },
                        (error) => {
                          this.load = false;
                          swal("Ooops", error.error.msg, "error");
                          this.showSubmit = false;
                        }
                      );
                  }
                },
                (dismiss) => {
                  if (dismiss === "cancel") {
                    this.load = false;
                  }
                }
              );
            } else {
              let lastBuild = this.getendDate(cube.segments);
              if (!lastBuild) lastBuild = cube.partitionDateStart;

              var dt = new Date(lastBuild);
              this.startTime = new Date(
                dt.getUTCFullYear(),
                dt.getUTCMonth(),
                dt.getUTCDate(),
                dt.getUTCHours(),
                dt.getUTCMinutes(),
                dt.getUTCSeconds()
              );
              this.endTime = new Date();

              this.cubeBuildForm.controls["startTime"].setValue(this.startTime);
              this.cubeBuildForm.controls["endTime"].setValue(this.endTime);

              if (this.modelDetails.partition_desc.partition_date_column) {
                this.showSubmit = true;
                this.cube = cube;
                //  this.startTime=this.getdate(cube.segments[0].date_range_end);
              } else {
                this.showSubmit = false;
                swal({
                  type: "warning",
                  title: "Are you sure you want to start the build?",
                  text: " ",
                  showCancelButton: true,
                  confirmButtonText: "Yes",
                  cancelButtonText: "No",
                }).then(
                  (status) => {
                    if (status.value == true) {
                      this.build(cube);
                      this.showSubmit = false;
                    }
                  },
                  (dismiss) => {
                    if (dismiss === "cancel") {
                    }
                  }
                );
              }
              // this.showSubmit=false;
              this.load = false;
            }
          });
      });
  }
  build(cube) {
    this.load = true;
    let obj;
    if (this.endTime && this.startTime) {
      obj = {
        buildType: "BUILD",
        endTime: this.toTimeStamp(this.cubeBuildForm.controls["endTime"].value),
        startTime: this.toTimeStamp(
          this.cubeBuildForm.controls["startTime"].value
        ),
      };

      if (obj.endTime <= obj.startTime) {
        //QUBZ-3919-End time should be latter than the Start time for Partition cube building-27/10/22
        this.load = false;
        swal(
          "Ooops!",
          "End time should be later than the start time.",
          "error"
        );
        return;
      }
    } else {
      obj = {
        buildType: "BUILD",
        endTime: 0,
        startTime: 0,
      };
    }
    this.cubesService.rebuildCube(cube.name, obj).subscribe(
      (data) => {
        this.list(this.page);
        swal("Success!", "Rebuild job was submitted successfully", "success");
        this.endTime = "";
        this.startTime = "";
        this.hide();
        this.load = false;
        //QUBZ-3744 QUBZ-In Cubes tab parent check box should be unselect 18/07/2022
        this.cubeSelectAll = false;
        this.cubeSelected = false;
        //QUBZ-3744 QUBZ-In Cubes tab parent check box should be unselect 18/07/2022
      },
      (error) => {
        this.load = false;
        swal("Ooops", error.error.msg, "error");
        this.showClone = false;

        //QUBZ-3809 in Cubs Tab validation required Parent check box("Parent Check Box was cleared 29/8/22//QUBZ-3744 QUBZ-In Cubes tab parent check box should be unselect 18/07/2022
        this.list(this.page);
        this.cubeSelectAll = false;
        this.cubeSelected = false;
        //QUBZ-3744 QUBZ-In Cubes tab parent check box should be unselect 18/07/2022
      }
    );
    this.showSubmit = true;
  }
  toTimeStamp(date) {
    date = new Date(date);
    return Date.UTC(
      date.getFullYear(),
      date.getMonth(),
      date.getDate(),
      date.getHours(),
      date.getMinutes(),
      date.getSeconds()
    );
  }

  mergeSubmit() {
    let cube = this.cube;
    this.cubeName = cube.name;
    this.errorMsg = "";
    // if(this.segementSelect == this.segementEnd){
    //    this.errorMsg="WARNING: Segments should be different.";
    //    return;
    // }
    this.projectName = cube.project;
    this.segment = cube.segments;
    let startSegement = cube.segments.find(
      ({ uuid }) => this.segementSelect === uuid
    );
    let endSegment = cube.segments.find(
      ({ uuid }) => this.segementEnd === uuid
    );

    let obj = {
      buildType: "MERGE",
      endTime: endSegment.date_range_end,
      startTime: startSegement.date_range_start,
    };

    this.cubesService.rebuildCube(this.cubeName, obj).subscribe(
      (data) => {
        this.list(this.page);
        //QUBZ-3809 in Cubs Tab validation required Parent check box("Parent Check Box was cleared 29/8/22
        this.cubeSelectAll = false;
        this.cubeSelected = false;
        swal("Success", "Rebuild job was submitted successfully", "success");
      },
      (error) => {
        var message = error.error.msg;

        if (message.indexOf("Empty cube segment found") != -1) {
          swal({
            type: "warning",
            title: error.error.msg + ", Do you want to continue?",
            text: " ",
            showCancelButton: true,
            confirmButtonText: "Yes",
            cancelButtonText: "No",
          }).then(
            (status) => {
              if (status.value == true) {
                let obj = {
                  buildType: "MERGE",
                  endTime: endSegment.date_range_end,
                  startTime: startSegement.date_range_start,
                  forceMergeEmptySegment: true,
                };
                this.cubesService
                  .rebuildCube(this.cubeName, obj)
                  .subscribe((data) => {
                    this.list(this.page);
                    //QUBZ-3809 in Cubs Tab validation required Parent check box("Parent Check Box was cleared 29/8/22
                    this.cubeSelectAll = false;
                    this.cubeSelected = false;
                    swal(
                      "Success",
                      "Rebuild job was submitted successfully",
                      "success"
                    );
                  });
              }
            },
            (dismiss) => {
              if (dismiss === "cancel") {
              }
            }
          );
        } else {
          swal("Error", error.error.msg, "error");
        }

        this.showClone = false;
      }
    );
    this.hide();
  }

  startMergeSegment(cube) {
    //let name;
    this.cubeName = cube.name;
    this.startSegment = null;
    this.endSegment = null;
    this.segementEnd = null;
    this.segementSelect = null;
    this.projectName = cube.project;
    this.segment = cube.segments;
    if (this.segment.length == 0 || this.segment.length == 1) {
      this.showMerge = false;
      swal("Information", "No segment to merge", "info");
      return;
    }
  }

  newCube() {
    this.cubesService.cubeMeta = {
      name: "",
      uuid: "",
      version: "",
      is_draft: 0,
      last_modified: "",
      signature: "",
      partition_date_end: "",
      parent_forward: 0,
      snapshot_table_desc_list: [],
      model_name: "",
      description: "",
      dimensions: [],
      measures: [],
      dictionaries: [],
      rowkey: {
        rowkey_columns: [],
      },
      aggregation_groups: [],
      mandatory_dimension_set_list: [],
      partition_date_start: 0,
      notify_list: [],
      hbase_mapping: {
        column_family: [],
      },
      volatile_range: "",
      retention_range: "",
      status_need_notify: [],
      auto_merge_time_ranges: [],
      engine_type: "",
      storage_type: "",
      override_qubz_properties: {},
    };
    this.cubesService.editMode = false;
    this.cubesService.cubeStatus = null;
    this.cubesService.tableInfo = null;
    this.router.navigate([
      "/projects/" + this.project + "/cubes/designer/info",
    ]);
  }
  editCube(cubeName, cube) {
    this.cubesService.cubeMeta = {
      name: "",
      uuid: "",
      version: "",
      is_draft: 0,
      last_modified: "",
      signature: "",
      partition_date_end: "",
      parent_forward: 0,
      snapshot_table_desc_list: [],
      model_name: "",
      description: "",
      dimensions: [],
      measures: [],
      dictionaries: [],
      rowkey: {
        rowkey_columns: [],
      },
      aggregation_groups: [],
      mandatory_dimension_set_list: [],
      partition_date_start: 0,
      notify_list: [],
      hbase_mapping: {
        column_family: [],
      },
      volatile_range: "",
      retention_range: "",
      status_need_notify: [],
      auto_merge_time_ranges: [],
      engine_type: "",
      storage_type: "",
      override_qubz_properties: {},
    };
    this.cubesService.editMode = true;
    this.cubesService.cubeStatus = cube.status;
    this.cubesService.tableInfo = null;
    this.router.navigate([
      "/projects/" + this.project + "/cubes/" + cubeName + "/designer/info",
    ]);
  }

  assignment(cube: any) {
    const url = `/projects/${this.project}/cubes/designer`;
    this.router.navigateByUrl(`${url}/${cube.name}/cube-assignment`);
  }

  reAssign() {
    let cubeObj = {
      cube_name: this.selectedCube.name,
      assignments: {},
    };
    while (this.serverList.length) {
      this.serverList.removeAt(0);
    }
    for (let items of this.kafkaForm.value.servers) {
      let assignment = [];
      for (let part of items.partition) {
        assignment.push({
          partition_id: part.id ? part.id : part,
          partition_info: null,
        });
      }
      cubeObj.assignments[items.cluster] = assignment;
    }

    this.streamingService.reAssign(this.selectedCube.name, cubeObj).subscribe(
      (data) => {
        swal(
          "Success",
          "Partition successfully assigned to cluster",
          "success"
        );
        this.hide();
      },
      (error) => {
        swal("Ooops", error.error.msg, "error");
        this.hide();
      }
    );
  }
  startMerge(cube) {
    this.cube = cube;
    this.cubeName = cube.name;

    this.projectName = cube.project;
    this.segment = cube.segments;
    if (this.segment.length == 0 || this.segment.length == 1) {
      this.showMerge = false;
      swal("Information!", "No segment to merge", "info");
      return;
    }

    this.showMerge = true;
  }

  refreshSubmit() {
    this.cubesService.listCubes().subscribe((data: any[]) => {
      this.cubeDetails = data;
      this.modelName = this.cube.model;
      //this.jobsubmitctrl(this.cube);

      this.showModel = false;
    });
  }

  startRefreshSegment(cube) {
    //let name;
    this.cubeName = cube.name;

    this.projectName = cube.project;
    this.segment = cube.segments;
  }

  startRefresh(cube) {
    this.cube = cube;
    if (!cube.partitionDateColumn) {
      swal(
        "Information!",
        "No partition date column defined. If you want to rebuild the cube, please click build",
        "info"
      );
      return;
    } else if (!cube.segments.length) {
      swal("Information!", "No segment to refresh", "info");
      return;
    } else {
      this.startRefreshSegment(cube);
      this.showModel = true;
      this.segementSelect = "";
    }
  }

  startDeleteSegment(cube) {
    this.cubeName = cube.name;
    this.projectName = cube.project;
    this.segment = cube.segments;
  }
  //Mdx Code start
  CreateMDXDS(cubeName, projectName) {

    this.cubesService.createMDXDS(cubeName, projectName).subscribe(
      (data: any) => {
        //swal("MDX Dataset created success", data, "info");
        this.list(this.page);
      },
      (error) => {
        swal("Ooops", error.message, "error");
      })



  }
  // CreateEnableMDXDS(cubeName, projectName) {
  //   this.cubesService.createEnableMDXDS(cubeName, projectName).subscribe(
  //     (data: any) => {
  //       swal("MDX DataSet created success", data, "info");
  //     },
  //     (error) => {
  //       swal("Ooops", error.message, "error");
  //     })

  // }
  DeleteMDXDS(cubeName, projectName) {
    this.cubesService.deleteDisableMDXDS(cubeName, projectName).subscribe(
      (data: any) => {
        //swal("MDX Dataset deleted success", data, "info");
        this.list(this.page);
      },
      (error) => {
        swal("Ooops", error.message, "error");
      })

  }
  CreateMDXDSMenu(cube) {
    swal({
      type: "warning",
      title: "Are you sure you want to create the MDX Dataset?",
      text: " ",
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "No",
    }).then((status) => {

      if (status.value == true) {
        this.cubeName = cube.name;

        this.projectName = cube.project;
        this.cubesService.createMDXDS(this.cubeName, this.projectName).subscribe(
          (data: any) => {
            swal("MDX Dataset created successful", data, "info");
            this.list(this.page);
          },
          (error) => {
            swal("Ooops", error.message, "error");
          })
      }

    })

  }
  DeleteMDXDSMenu(cube) {
    swal({
      type: "warning",
      title: "Are you sure you want to delete the MDX Dataset?",
      text: " ",
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "No",
    }).then((status) => {

      if (status.value == true) {
        this.cubeName = cube.name;
        this.projectName = cube.project;
        this.cubesService.deleteDisableMDXDS(this.cubeName, this.projectName).subscribe(
          (data: any) => {
            swal("MDX Dataset deleted successful", data, "info");
            this.list(this.page);
          },
          (error) => {
            swal("Ooops", error.message, "error");
          })
      }

    })

  }
  //To enable Mdx flage this code integrated
  getmdxEnableConfig() {

    this.adminService.config().subscribe(
      data => {
        let res = <any>{};
        res = data;
        this.config = res.config;
        this.configSplitted = this.config.split("\n");
        let i = 0;
        for (i = 0; i < this.configSplitted.length; i++) {
          if (this.configSplitted[i].split("=")[0] === 'qubz.mdx.enabled' && this.configSplitted[i].split("=")[1] === 'true') {
            //console.log(this.configSplitted[i].split("="));
            this.ismdxEnable = true;
            break;
          } else {
            this.ismdxEnable = false;
          }
        }

      }
    );
  }

  //Mdx Code end 

  delSubmit(segementSelect) {
    swal({
      type: "warning",
      title: "Are you sure you want to delete the segment?",
      text: " ",
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "No",
    }).then((status) => {
      if (status.value == true) {
        this.cubesService
          .deleteSegment(this.cubeName, segementSelect)
          .subscribe(
            (data) => {
              this.list(this.page);
              //QUBZ-3809 in Cubs Tab validation required Parent check box("Parent Check Box was cleared 29/8/22
              this.cubeSelectAll = false;
              this.cubeSelected = false;
              swal("Success!", "Segment deleted successfully", "success");
              if (this.selectedCube) {
                this.cubeSegments = [];
                this.getSegments(this.selectedCube.name);
              }
              this.segementSelect = "";
            },
            (error) => {
              this.list(this.page);
              this.cubeSelectAll = false;
              this.cubeSelected = false;
              swal("Ooops", error.error.msg, "error");
              this.showClone = false;
            }
          );
        this.showDelete = false;
      }
    });
  }

  startDelete(cube) {
    if (!cube.partitionDateColumn) {
      swal(
        "Oops!",
        "No partition date column defined. If you want to delete the cube, please click 'Delete'.",
        "error"
      );
      return;
    } else {
      this.cubeName = cube.name;

      this.projectName = cube.project;
      this.segment = cube.segments;
      this.startRefreshSegment(cube);

      this.showDelete = true;
    }
  }
  checkSelectedStatus(allSegment) {
    if (allSegment) this.allSegment = !allSegment;
  }
  selectAll(allSegment) {
    if (allSegment) this.allSegment = true;
    else if (this.lookupSegment.length == this.dropdownList.length)
      this.allSegment = true;
  }
  selectAllSegments(cube, allSegment) {
    this.lookupSegment = [];
    if (allSegment) {
      for (let [key, value] of Object.entries(cube.segments)) {
        let res = <any>{};
        res = value;
        this.lookupSegment.push({
          id: res.uuid,
          name: res.name,
          isDisabled: undefined,
        });
      }
    }
  }

  hide() {
    this.showModel = false;
    this.showDelete = false;
    this.showMerge = false;
    this.showClone = false;
    this.lookup = false;
    this.showSubmit = false;
    this.startSegment = null;
    this.endSegment = null;
    this.segementEnd = null;
    this.segementSelect = null;
    this.cubeBuildForm.markAsPristine();
    this.cubeBuildForm.markAsUntouched();
    this.assign = false;
  }
  cloneCube(cube) {
    this.cubeName = cube.descriptor + "_clone";
    this.projectName = cube.project;
    this.descriptor = cube.descriptor;
    this.showClone = true;
  }
  clone() {
    let cubeRequest = { cubeName: this.cubeName, project: this.projectName };
    swal({
      type: "warning",
      title: "Are you sure you want to clone the cube?",
      text: " ",
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "No",
    }).then(
      (status) => {
        if (status.value == true) {
          this.cubesService.clone(this.descriptor, cubeRequest).subscribe(
            (data) => {
              this.list(this.page);
              //QUBZ-3809 in Cubs Tab validation required Parent check box("Parent Check Box was cleared 29/8/22
              this.cubeSelectAll = false;
              this.cubeSelected = false;
              swal("Success", "Clone cube successfully", "success");
              this.showClone = false;
            },
            (error) => {
              //QUBZ-3809 in Cubs Tab validation required Parent check box("Parent Check Box was cleared 29/8/22
              this.list(this.page);
              this.cubeSelectAll = false;
              this.cubeSelected = false;
              swal("Ooops", error.error.msg, "error");
              this.showClone = false;
            }
          );
        }
      },
      (dismiss) => {
        if (dismiss == "cancel") {
        }
      }
    );
  }

  jobsubmitctrl(cube) {
    this.cubeName = cube.name;
    this.errorMsg = "";
    // if(this.segementSelect == this.segementEnd){
    //    this.errorMsg="WARNING: Segments should be different.";
    //    return;
    // }
    let segment;

    for (let seg of cube.segments) {
      if (seg.name == this.segementSelect) segment = seg;
    }
    this.projectName = cube.project;
    this.segment = cube.segments;
    let obj = {
      buildType: "REFRESH",
      endTime: segment.date_range_end,
      startTime: segment.date_range_start,
    };
    let starTime;

    // if(cube.segments.length == 0){
    //   starTime = (!!cube.detail.partition_date_start)?cube.detail.partition_date_start:0;
    // }else{
    //   starTime = cube.segments[cube.segments.length-1].date_range_end;
    // }
    // obj.startTime = starTime;
    if (obj.startTime >= obj.endTime) {
      this.errorMsg = "WARNING: End time should be later than the start time.";
    }
    swal({
      type: "warning",
      title: "Are you sure you want to start the build? ",
      text: " ",
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "No",
    }).then((status) => {
      if (status.value == true) {
        this.cubesService.rebuildCube(this.cubeName, obj).subscribe(
          (data) => {
            this.list(this.page);
            //QUBZ-3809 in Cubs Tab validation required Parent check box("Parent Check Box was cleared 29/8/22
            this.cubeSelectAll = false;
            this.cubeSelected = false;
            swal(
              "Success",
              "Rebuild job was submitted successfully",
              "success"
            );
          },
          (error) => {
            this.list(this.page);
            this.cubeSelectAll = false;
            this.cubeSelected = false;
            swal("Ooops", error.error.msg, "error");
            this.showClone = false;
          }
        );
      }
    });

    this.hide();
  }
  getdate(date_input, paramType) {
    if (paramType == "buildTime") {
      return this.datePipe.transform(
        new Date(date_input),
        "yyyy-MM-dd h:mm:ss zzzz"
      );
    } else {
      var dt = new Date(date_input);
      let startTime = new Date(
        dt.getUTCFullYear(),
        dt.getUTCMonth(),
        dt.getUTCDate(),
        dt.getUTCHours(),
        dt.getUTCMinutes(),
        dt.getUTCSeconds()
      );
      return this.datePipe.transform(startTime, "yyyy-MM-dd h:mm:ss");
    }
  }
  getStartSegmentDetails(segment) {
    if (
      this.segementEnd &&
      segment.find(({ uuid }) => this.segementSelect === uuid).date_range_end >=
      segment.find(({ uuid }) => this.segementEnd === uuid).date_range_end
    ) {
      swal(
        "Error",
        "Second segment end date should be greater than first segment ",
        "error"
      );
      this.endSegment = null;
      this.segementSelect = null;
      return;
    }
    this.startSegment = segment.find(
      ({ uuid }) => this.segementSelect === uuid
    );
  }
  getEndSegmentDetails(segment) {
    if (
      this.segementSelect &&
      segment.find(({ uuid }) => this.segementSelect === uuid).date_range_end >=
      segment.find(({ uuid }) => this.segementEnd === uuid).date_range_end
    ) {
      swal(
        "Error",
        "Second segment end date should be greater than first segment ",
        "error"
      );
      this.endSegment = null;
      this.segementEnd = null;
      return;
    }

    this.endSegment = segment.find(({ uuid }) => this.segementEnd === uuid);
  }
  getSegementDetailsMerge(segment, status) {
    // if(!this.segementSelect)
    // return;
    this.errorMsg = "";
    let segemntSelected;

    if (status == "end") {
      segemntSelected = this.segementEnd;
    } else segemntSelected = this.segementSelect;

    let html;
    let param = segment[segemntSelected];

    if (param) {
      if (param.date_range_start < param.date_range_end) {
        if (status == "end") this.endDate = param.date_range_start;
        else if (status == "start") this.startDate = param.date_range_end;

        html = '<section class="inputtextb segmenteo">';
        html +=
          '<div class="inputc fieldtctext"><label class="labeltext">Start Date (Include)</label><aside class="inputtext">' +
          this.getdate(param.date_range_start, "start") +
          "</aside></div>";
        html +=
          '<div class="inputc fieldtctext"><label class="labeltext">End Date (Exclude)</label><aside class="inputtext">' +
          this.getdate(param.date_range_end, "end") +
          "</aside></div>";
        html +=
          '<div class="inputc fieldtctext"><label class="labeltext">Last build Time</label><aside class="inputtext">' +
          this.getdate(param.last_build_time, "buildTime") +
          "</aside></div>";
        html +=
          '<div class="inputc fieldtctext"><label class="labeltext">Last build ID</label><aside class="inputtext">' +
          param.last_build_job_id +
          "</aside></div></section>";

        return html;
      } else {
        html =
          '<span>No partition date column defined. If you want to drop the cube, please click "Drop".</span>';
        return html;
      }
    }
  }
  getSegementDetails(segment, status) {
    // if(!this.segementSelect)
    // return;
    this.errorMsg = "";
    let segemntSelected;

    if (status == "end") {
      segemntSelected = this.segementEnd;
    } else segemntSelected = this.segementSelect;

    let html;
    if (segment) {
      for (let param of segment) {
        if (param.name == segemntSelected) {
          if (param.date_range_start < param.date_range_end) {
            if (status == "end") this.endDate = param.date_range_start;
            else if (status == "start") this.startDate = param.date_range_end;

            html = '<table class="table table-condensed">';
            html +=
              "<tr><td>Start Date (Include)</td><td >" +
              this.getdate(param.date_range_start, "start") +
              "</td></tr>";
            html +=
              "<tr><td>End Date (Exclude)</td><td>" +
              this.getdate(param.date_range_end, "end") +
              "</td></tr>";
            html +=
              "<tr><td>Last build Time</td><td>" +
              this.getdate(param.last_build_time, "buildTime") +
              "</td></tr>";
            html +=
              " <tr><td>Last build ID</td><td>" +
              param.last_build_job_id +
              "</td></tr></table>";

            return html;
          } else {
            html =
              '<span>No partition date column defined. If you want to drop the cube, please click "Drop".</span>';
            return html;
          }
        }
      }
    }
  }
  mergeSubmitCtrl(cube) {
    this.cubeName = cube.name;

    this.projectName = cube.project;
    this.segment = cube.segments;
    let obj = {
      buildType: "MERGE",
      endTime: 1591709100000,
      startTime: 1513056600000,
    };
    let starTime;
    let message = "";

    if (cube.segments.length == 0) {
      starTime = !!cube.detail.partition_date_start
        ? cube.detail.partition_date_start
        : 0;
    } else {
      starTime = cube.segments[cube.segments.length - 1].date_range_end;
    }
    obj.startTime = starTime;
    if (this.startDate <= this.endDate) {
      this.errorMsg = "WARNING: End time should be later than the start time.";
      return;
    }

    this.cubesService.rebuildCube(this.cubeName, obj).subscribe(
      (data) => {
        this.list(this.page);
        swal("Success", "Rebuild job was submitted successfully", "success");
      },
      (error) => {
        swal("Ooops", error.error.msg, "error");
        this.showClone = false;
      }
    );
    this.startSegment = null;
    this.endSegment = null;
    this.segementEnd = null;
    this.segementSelect = null;
  }
  filterCube() {
    let cube = [];
    if (this.filterValue) {
      for (let param of this.cubesAll) {
        if (
          param.name.toLowerCase().indexOf(this.filterValue.toLowerCase()) != -1
        ) {
          // if(param.name.match(txt)){
          cube.push(param);
        }
      }
      this.cubeDetails = cube;
    } else this.cubeDetails = this.cubesAll;
  }
  getendDate(segments) {
    let last = 0;
    for (let seg of segments) {
      if (seg.date_range_end > last) last = seg.date_range_end;
    }
    return last;
  }
  getLastBuild(segments, type) {
    let last = 0;
    if (segments) {
      for (let seg of segments) {
        if (type == "time") {
          if (seg.last_build_time > last) last = seg.last_build_time;
        }
        if (type == "count") {
          last += seg.input_records;
        }
        if (type == "size") {
          last += seg.size_kb;
        }
      }

      return last;
    }
  }

  getCubeRecord(cubeName: string): number {
    let records: number;
    this.cubesService.getCubeRecordDetails(cubeName).subscribe((res) => {
      records = res;
    });

    return records ? records : null;
  }

  setFocus(): void {
    this.focusSettingEventEmitter.emit(true);
  }
  showsegment(cube) {
    this.router.navigate([
      "/projects/" + this.project + "/cubes/" + cube.name + "/segments",
    ]);
    localStorage.setItem("selectedCube", JSON.stringify(cube));
  }

  createVisualCube() {
    this.cubesService.visualCubeEditMode = false;
    this.router.navigate([
      "/projects/" + this.project + "/cubes/designer/visual-cube",
    ]);
  }
  editVisualCube(name, cube) {
    this.cubesService.visualCubeEditMode = true;
    let cubeName;
    this.cubesService.cubeName = name;
    cubeName = this.cubesService.cubeName;
    this.cubesService.cubeStatus = cube.status;
    this.router.navigate([
      "/projects/" + this.project + "/cubes/" + cubeName + "/visual-cube",
    ]);
  }

  saveLogs() {
    var today = new Date();
    let param = {
      username: this.loggedUser.username,
      targetPage:
        "projects/" + this.route.snapshot.paramMap.get("projectId") + "/cubes",
      day: today.getDay(),
      month: today.getMonth(),
      year: today.getFullYear(),
    };
    this.commonServiceService.saveLogs(param).subscribe((data) => { });
  }

  selectAllBoxes() {
    let cubes: any;
    for (cubes of this.cubeDetails) {
      // if(jobs.job_status ==='DISCARDED' || jobs.job_status ==='ERROR' || jobs.progress === 100)
      cubes.selected = this.cubeSelectAll;
    }
    this.cubeSelected = this.cubeSelectAll;
  }

  checkAnyCubeSelected() {
    let selected = true;
    let cubeSelected = false;
    let cubes: any;
    for (cubes of this.cubeDetails) {
      if (!cubes.selected) selected = false;
      else cubeSelected = true;
    }
    this.cubeSelected = cubeSelected;
    this.cubeSelectAll = selected;
  }

  deleteSelectedCubes() {
    swal({
      type: "warning",
      title: "Are you sure you want to delete this cubes ?",
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "No",
    }).then((status) => {
      if (status.value == true) {
        let delCubes;
        delCubes = this.cubeDetails
          .filter(
            (cubes) => cubes.selected === true && cubes.status !== "READY"
          )
          .map((x) => x.name)
          .join(",");
        let readyCubes;
        readyCubes = this.cubeDetails
          .filter((cubes) => cubes.selected === true && cubes.status == "READY")
          .map((x) => x.name)
          .join(",");
        if (readyCubes) {
          swal("Information!", "Ready cubes cannot be deleted", "info");
        }
        if (delCubes) {
          this.cubesService.deleteMultipleCubes(delCubes).subscribe(
            (data: any) => {
              swal("Success", "Selected cubes deleted", "success");
              this.cubeSelectAll = false;
              this.cubeSelected = false;
              this.list(this.page);
            },
            (error) => {
              swal("Ooops", error.error.msg, "error");
              this.cubeSelectAll = false;
              this.cubeSelected = false;
              this.list(this.page);
            }
          );
        }
      }
    });
  }
}

export const DateTimeValidator = (fc: FormControl) => {
  const date = new Date(fc.value);
  const isValid = !isNaN(date.valueOf());
  return isValid
    ? null
    : {
      isValid: {
        valid: false,
      },
    };
};
