import { Component, OnInit, Input, Output, EventEmitter, AfterViewInit } from '@angular/core';
import { QueryService } from 'src/app/services/query/query.service';
import { ActivatedRoute } from '@angular/router';
import { QueryComponent } from '../query.component';
import swal from 'sweetalert2';
import { Subscription } from 'rxjs';
import * as $ from 'jquery';
@Component({
  selector: 'app-saved-queries',
  templateUrl: './saved-queries.component.html',
  styleUrls: ['./saved-queries.component.scss']
})
export class SavedQueriesComponent implements OnInit, AfterViewInit {
  @Input() result: string;
  @Input() savedQueriesList: any;
  @Output() resultEvent: EventEmitter<any> = new EventEmitter();
  @Output() savedQueriesEvent: EventEmitter<any> = new EventEmitter();
  @Output() errorEvent: EventEmitter<any> = new EventEmitter();
  error: string;
  projectId: string;
  collapsed: any[] = [];
  limit: number = 50000;

  p: number = 1;

  page: number = 0;
  totalPageSize: number = 10;
  savedQueriesCount: number;

  subscription: Subscription;
  constructor(private queryService: QueryService, private route: ActivatedRoute, private comp: QueryComponent) { }

  ngAfterViewInit() {
    $(document).ready(function () {
      var contenth = $(window).height() - 110;
      var sidebarh = $(window).height() - 111;
      $(".pagec").css("height", contenth);
      $(".sidebar-wrapper").css("height", sidebarh);
    })
  }
  ngOnInit() {
    this.page = 1;
    this.projectId = this.route.snapshot.paramMap.get("projectId");
    this.savedQueriesEvent.emit(this.savedQueriesList);
    this.subscription = this.queryService.queryUpdated$.subscribe(queryUpdated => this.savedQueries(this.page - 1, this.totalPageSize));
    //this.savedQueries()
  }
  savedQueries(page, totalPageSize) {
    const params = {
      project: this.projectId,
      page: page,
      size: totalPageSize
    }
    this.queryService.getSavedQueries(params)
      .subscribe((data: any) => {
        this.savedQueriesList = data.content;
        this.savedQueriesCount = data.totalElements;
        //this.savedQueriesEvent.emit(this.savedQueriesList);
      })
  }
  runQuery(query: string, savedQuery) {
    this.comp.getGraphSate(savedQuery);
    this.comp.queryResult(query, 50000, this.projectId);
    this.comp.showresult();
  }
  deleteQuery(id: string) {
    swal({
      type: 'warning',
      title: 'Are you sure you want to delete query?',
      text: 'All information associated to this query will be permanently deleted',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No'
    }).then((status) => {
      if (status.value === true) {
        this.queryService.removeQuery(id).subscribe(
          data => {
            swal("Success!", "Query has been deleted", "success");
            this.savedQueries(this.page - 1, this.totalPageSize);
          },
          error => {
            swal('Oops...', error.error.msg, 'error');
          }
        );
      }
    }, (dismiss) => {
      if (dismiss === 'cancel') {
      }
    });

  }
  getSaveQueryPage(event: any) {

    this.page = event;

    this.savedQueries(this.page - 1, this.totalPageSize);


  }
}
