import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Config } from '../../config';

@Injectable({
  providedIn: 'root'
})
export class HybridService {

  static url = Config.url + '/hybrids';

  constructor(private http: HttpClient) { }

  removeQuery(project, hybrid) {
    const params = new HttpParams()
    .set('project', project)
    .set('hybrid', hybrid);
    return this.http.delete(HybridService.url, {params});
  }
  getHybrids(params) {
 
    return this.http.get(HybridService.url, {params});
  }
  createHybrids(hybridInstance) {
    return this.http.post(HybridService.url, hybridInstance);
  }
  updateHybrids(hybridInstance) {
    return this.http.put(HybridService.url, hybridInstance);
  }
  getHybrid(modelName: string) {
	return this.http.get(HybridService.url + '/' + modelName);
  }
}
