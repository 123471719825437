import { Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef, AfterViewInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CubeDescriptionService } from '../../../../../services/cubes/cube-description.service';
import { CubesService } from 'src/app/services/cubes/cubes.service';
import { StreamingService } from 'src/app/services/streaming/streaming.service';
import swal from 'sweetalert2';
import { ThrowStmt } from '@angular/compiler';

@Component({
  selector: 'app-streaming',
  templateUrl: './streaming.component.html',
  styleUrls: ['./streaming.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class StreamingComponent implements OnInit {
  cubeName;
  cubeData: any;
  streamStatus: any;
  node;
  rsId;
  segments = [];
  segmentModel;
  receiver_cube_real_time_states: any;
  latest_event_ingest_time;
  latest_event_time;
  total_ingests;
  offsetInfo;
  partitonInfo;
  consume_lag;
  interval;
  clusterInfo = [];
  load: boolean = false;
  showStatusMessage: boolean = false;
  project;

  constructor(private route: ActivatedRoute, 
    private cd: ChangeDetectorRef, 
    private cubeDescriptionService: CubeDescriptionService, 
    private cubesService: CubesService,
    private streamingService: StreamingService, 
    private router: Router) {  
  }

  ngOnInit() {
    this.project = this.route.snapshot.paramMap.get("projectId");
    this.cubeName = this.route.snapshot.paramMap.get('cubeId');
    if (this.streamingService.showStatus != "") {
      this.load = false;
      this.showStatusMessage = false;
    } else {
      this.router.navigate(['/projects/' + this.project + '/cubes']);
    }

    this.getStreamingDetails();

  }

  getStreamingDetails() {
    this.load = true;
    this.streamingService.getStreamingStatus(this.cubeName).subscribe(
      data => {
          this.load = false;
          this.clusterInfo = [];
          this.streamStatus = data;
          let data1 = JSON.parse(JSON.stringify(this.streamStatus));
          for (var rsId in data1.receiver_cube_real_time_states) {
            this.rsId = rsId;
            var receiverCount = Object.keys(this.streamStatus.receiver_cube_real_time_states[rsId]).length;
            let receiver_state = [];
            let rec_name = '';
            let segment = [];
            let partition = [];
            for (var node in this.streamStatus.receiver_cube_real_time_states[rsId]) {
              segment = [];
              partition = [];
              if (this.streamStatus.receiver_cube_real_time_states[rsId][node].receiver_cube_stats)
                for (let [keys, values] of Object.entries(this.streamStatus.receiver_cube_real_time_states[rsId][node].receiver_cube_stats.segment_stats)) {
                  segment.push({ "segment_name": keys, "segment": values });
                }
              if (this.streamStatus.receiver_cube_real_time_states[rsId][node].receiver_cube_stats && this.streamStatus.receiver_cube_real_time_states[rsId][node].receiver_cube_stats.consumer_stats != null)
                for (let [keys, values] of Object.entries(this.streamStatus.receiver_cube_real_time_states[rsId][node].receiver_cube_stats.consumer_stats.partition_consume_stats)) {

                  partition.push({ "partition_name": keys, "partition": values });

                }
              receiver_state.push({ "receiver": node, "state": this.streamStatus.receiver_cube_real_time_states[rsId][node], "segment": segment, "partition": partition });
              rec_name = node;
            }
            this.clusterInfo.push({
              "custer_id": rsId,
              "receiver_state": receiver_state,

            })
            this.cd.detectChanges();
          }
      },
      error => {
        this.load = false;
        this.showStatusMessage = true;  
        this.clusterInfo = [];
      },

      )

  }
  ngOnDestroy() {
    if (this.interval) {
      clearInterval(this.interval);
    }
  }
  clearInterval(interval) {
    this.interval = setInterval(() => {

    }, 0);
  }
  hide() {
    this.segmentModel = false;
  }
  showSegements() {
    this.segmentModel = true;
  }
}
