import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Config } from '../../config';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { Observable } from 'rxjs';
@Injectable({
   providedIn: 'root'
})
export class CubesService {
   private measureChanged = new BehaviorSubject<boolean>(false);
   measureUpdated = this.measureChanged.asObservable();
   public cubeObj: any;
   public dimensions: any;
   public cubeInfo: any;
   public model: any;
   public measure: any;
   public cubeStatus: any;
   public refreshSettings: any;
   public projectName: any;
   public cubeName: any;
   public editMode = false;
   public tableInfo: any;
   public cubeMeta = {
      "name": "",
      "uuid": "",
      "version": "",
      "is_draft": 0,
      "last_modified": "",
      "signature": "",
      "partition_date_end": "",
      "parent_forward": 0,
      "snapshot_table_desc_list": [],
      "model_name": "",
      "description": "",
      "dimensions": [],
      "measures": [{
         "name": "_COUNT_",
         "function": {
            "expression": "COUNT",
            "returntype": "bigint",
            "parameter": {
               "type": "constant",
               "value": "1"
            },
            "configuration": {}
         }
      }],
      "dictionaries": [],
      "rowkey": {
         "rowkey_columns": []
      },
      "aggregation_groups": [],
      "mandatory_dimension_set_list": [],
      "partition_date_start": 0,
      "notify_list": [],
      "hbase_mapping": {
         "column_family": []
      },
      "volatile_range": "",
      "retention_range": "",
      "status_need_notify": [],
      "auto_merge_time_ranges": [],
      "engine_type": "",
      "storage_type": "",
      "override_qubz_properties": {}
   }

   public visCubeNodeDataOnCreate: any;
   public visCubeSavedDimension = [];

   public dimensionChanged = new BehaviorSubject<boolean>(false);
   public dimensionUpdated = this.dimensionChanged.asObservable();
   public visualCubeEditMode: boolean;
   public visCubeNodeDataOnEdit: any;

   static url = Config.url + '/cubes';
   constructor(private http: HttpClient) {
      this.cubeObj = {
         dimensions: {}
      }
   }

   listUpdated(measure) {
      this.measureChanged.next(true);
   }


   listDimensionUpdated(dimension) {
      this.dimensionChanged.next(true);
   }
   list(params) {
      return this.http.get(CubesService.url, { params });
   }
   listCubes() {
      return this.http.get(CubesService.url);
   }

   getCubeRecordDetails(cubeName: any): Observable<any> {

      return this.http.get(CubesService.url + '/realtime/' + cubeName + '/records')
   }
   getProjectCubes(projectName) {
      return this.http.get(CubesService.url + '?projectName=' + projectName);
   }
   getProjectCubesPaginated(projectName,page,pageSize) {
      return this.http.get(CubesService.url + '/pagination?projectName=' + projectName + '&page=' + page + '&size=' + pageSize);
   }

   getValidEncodings() {
      return this.http.get(CubesService.url + '/validEncodings');
   }

   getCube() {
      return this.http.get(CubesService.url);
   }
   getCubeByName(cubeName) {
      return this.http.get(CubesService.url + '/?cubeName=' + cubeName);
   }

   getSql(cubeName) {
      return this.http.get(CubesService.url + '/' + cubeName + '/sql');
   }

   updateNotifyList(cubeName, notifyList) {
      return this.http.put(CubesService.url + '/' + cubeName + '/notify_list', notifyList);
   }

   cost(cubeName, cost) {
      return this.http.put(CubesService.url + '/' + cubeName + '/cost', cost);
   }

   rebuildLookUp(cubeName, segmentName, lookupTable) {
      return this.http.put(CubesService.url + '/' + cubeName + '/segs' + '/' + segmentName + '/refresh_lookup', lookupTable);
   }

   rebuildCube(cubeName, body) {
      return this.http.put(CubesService.url + '/' + cubeName + '/rebuild', body);
   }

   rebuildStreamingCube(cubeName, body) {
      return this.http.put(CubesService.url + '/' + cubeName + '/rebuild2', body);
   }

   disable(cubeName, body) {
      return this.http.put(CubesService.url + '/' + cubeName + '/disable', body);
   }

   enable(cubeName, body) {
      return this.http.put(CubesService.url + '/' + cubeName + '/enable', body);
   }

   purge(cubeName, body) {
      return this.http.put(CubesService.url + '/' + cubeName + '/purge', body);
   }

   clone(cubeName, cubeReq) {
      return this.http.put(CubesService.url + '/' + cubeName + '/clone', cubeReq);
   }

   deleteSegment(cubeName, segmentName) {
      return this.http.delete(CubesService.url + '/' + cubeName + '/segs' + '/' + segmentName);
   }
   /*Create and delete Mdx dataset Saan on 22/12/2022*/
   listMDX(cubeName, projectName) {
      return this.http.get(CubesService.url + '/isMdxDatasetExists/' + cubeName + '/' + projectName);
   }
   createMDXDS(cubeName, projectName) {
      return this.http.get(CubesService.url + '/createMdxDataset/' + cubeName + '/' + projectName);

   }
   //createEnableMDXDS(cubeName, projectName) {
   // return this.http.get(CubesService.url + '/createMDXdataset/enable/' + cubeName + '/' + projectName);
   //}
   deleteDisableMDXDS(cubeName, projectName) {
      return this.http.get(CubesService.url + '/deleteMdxDataset/' + cubeName + '/' + projectName);

   }
   /*End Create and delete Mdx dataset Saan on 22/12/2022*/

   drop(cubeName) {
      return this.http.delete(CubesService.url + '/' + cubeName);
   }
   buildStreamingCube(cubeName, body) {
      return this.http.put(CubesService.url + '/' + cubeName + '/build2', body);
   }

   getCubebyModel(modelName, projectName) {
      return this.http.get(CubesService.url + '?modelName=' + modelName + '&projectName=' + projectName);
   }
   saveCubeDesc(cubeReq) {
      return this.http.post(CubesService.url, cubeReq);
   }

   updateCubeDesc(cubeReq) {
      return this.http.put(CubesService.url, cubeReq);
   }

   getHbaseInfo(cubeName, projectName) {
      //QUBZ-3849 cube storage tab data is not correct 6/9/22
      return this.http.get(CubesService.url + '/' + cubeName + '/storage');
   }

   getCurrentCuboids(cubeName) {
      return this.http.get(CubesService.url + '/' + cubeName + '/cuboids' + '/current');

   }

   getRecommendCuboids(cubeName) {
      return this.http.get(CubesService.url + '/' + cubeName + '/cuboids' + '/recommend');
   }

   optimize(cubeName, jobOptimizeRequest) {
      return this.http.put(CubesService.url + '/' + cubeName + '/optimize', { 'cuboidsRecommend': jobOptimizeRequest });
   }

   autoMigrate(cube, project) {
      return this.http.post(CubesService.url + '/' + cube, project + '/migrate');
   }

   lookupRefresh(cubeName, body) {
      return this.http.put(CubesService.url + '/' + cubeName + '/refresh_lookup', body);
   }

   setCubeObj(cube) {
      this.cubeObj = cube;
   }
   getCubeObj() {
      return this.cubeObj;
   }
   setModelObj(model) {
      this.model = model;
   }
   getModelObj() {
      return this.model;
   }
   getCubeInfo() {
      return this.cubeInfo;
   }
   setCubeInfo(cube) {
      this.cubeInfo = cube;
   }


   validateCubeName(cubeName) {
      return this.http.get(CubesService.url + '/' + 'validate' + '/' + cubeName);
   }

   deleteMultipleCubes(cubeNames) {
      return this.http.delete(CubesService.url + '/mutlidelete' + '/' + cubeNames);
   }


}