import { Component, OnInit, Input, Output, EventEmitter, AfterViewInit } from '@angular/core';
import * as $ from 'jquery';

@Component({
	selector: 'app-model-edit-step-one',
	templateUrl: './model-edit-step-one.component.html',
	styleUrls: ['./model-edit-step-one.component.scss']
})
export class ModelEditStepOneComponent implements OnInit,AfterViewInit {
	@Input() modelObj: any;
	public focusSettingEventEmitter = new EventEmitter<boolean>();

	constructor() { }

	ngOnInit() {
	
	}

	ngAfterViewInit() {
		$(document).ready(function(){
			  var contenth = $( window ).height() - 110 ;
			  var sidebarh = $( window ).height() - 111 ;
			  $(".pagec").css("height", contenth );
			  $(".sidebar-wrapper").css("height", sidebarh );
			});

	      this.focusSettingEventEmitter.emit(true);

		}

		setFocus(): void {
			this.focusSettingEventEmitter.emit(true);
		  }

}
