import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Config } from '../../config';

@Injectable({
  providedIn: 'root'
})
export class JobSchedulerService {
  static url = Config.url + '/job_schedulers';
  constructor(private http: HttpClient) { }
  deleteScheduler(params) {
    return this.http.delete(JobSchedulerService.url, { params });
  }
  listAllScheduler(params) {

    return this.http.get(JobSchedulerService.url + '/pagination', { params });
  }
  createSheduler(params, jobDetails) {
    return this.http.post(JobSchedulerService.url, jobDetails, { params });
  }
  updateScheduler(jobDetails) {

    return this.http.put(JobSchedulerService.url, jobDetails);
  }
  validateCronString(cronString) {
    return this.http.get(JobSchedulerService.url + '/cron/schedules?cronString=' + cronString);
  }
  paginatedSchedulerList(projectId, page, count) {
    return this.http.get(Config.url + 'pagination?projectId=' + projectId + '&page=' + page + '&size=' + count);
  }
  getScheduler(schedulerId) {
    return this.http.get(JobSchedulerService.url + '/' + schedulerId);
  }
  updateShedulerStatus(schedulerId, params) {
    return this.http.put(JobSchedulerService.url + '/' + schedulerId + '/status', {}, { params });
  }
  getCronScheduledTimes(cronString) {
    return this.http.get(JobSchedulerService.url + '/cron/schedules', cronString);
  }
  // getCronScheduledTimes: { method: 'GET', params: { cron: 'cron', schedules: 'schedules' }, isArray: false },
}
