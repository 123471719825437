import { Component, OnInit,ChangeDetectionStrategy,ChangeDetectorRef  } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CubeDescriptionService } from '../../../../../services/cubes/cube-description.service';
import { CubesService } from 'src/app/services/cubes/cubes.service';
import swal from 'sweetalert2';
@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush

})
export class NotificationComponent implements OnInit {
  cubeName;
  notification;
  enable=true;
  oldValue;
  emailError;
  constructor(private route: ActivatedRoute,private cd: ChangeDetectorRef,private cubeDescriptionService:CubeDescriptionService,private cubesService:CubesService) { 
    this.cubeName = this.route.snapshot.paramMap.get('cubeId');
  }

  ngOnInit() {
   
  }
  ngAfterViewInit() {
    this.cubeDescriptionService.getCubeDetails(this.cubeName).subscribe(
			data => {
        this.oldValue=data[0].notify_list;
        this.notification=data[0].notify_list;
        this.cd.detectChanges();
      });
  }
  isEmail(search:string):boolean
  {
      var  serchfind:boolean;

      let regexp = new RegExp(/^((\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*)*([,])*([\s])*)*$/);

      serchfind = regexp.test(search);

 
      return serchfind
  }
  validateEmail(notifyList){
    // let pattern=
    let pattern =/^[\W]*([\w+\-.%]+@[\w\-.]+\.[A-Za-z]{2,4}[\W]*,{1}[\W]*)*([\w+\-.%]+@[\w\-.]+\.[A-Za-z]{2,4})[\W]*$/;
    if(!notifyList.match(pattern))
    {
      this.emailError=true;


    }
    else{
    this.emailError=false;

    }
  }
  enableButton(){
   
    if(this.oldValue!=this.notification)
      this.enable=false;
    else
      this.enable=true;
  }
  saveNotifyList(){
    if(!this.isEmail(this.notification)){
      this.emailError=true;
      return;
    }else{
      this.emailError=false;
    }
    let notify;
   
    if(this.notification=='')
    notify=[];
    else
    notify=this.notification.split(",");
    this.enable=true;
    this.cubesService.updateNotifyList(this.cubeName,notify).subscribe(
			data => {
        swal("Success!","Notify list updated",'success');
      },(error)=>{
        swal("Ooops",error.error.msg, "error");
      });
  }
}
