import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ModelStepsService {
  public dataSourceName:any;
  public modelName;
  public modelDescription;
  public  aliasName;
  public tableList:any=[];
  public factAliasName;
  public editMode;
  public factTable;
  public uuid;
  public measureList:any=[];
  public dimensionName;
  public tableName;
  public joinTypes;
  public selFromTable:any=[];
  public selFromTableColumn;
  public selLookup:any=[];
  public selLookupColumn;
  public pkType;
  public fkType;
  public kind;
  public dimensionColumns;
  public metricName;
  public checkMetricAdded;
  public databaseName;
  public dimensionName1;
  public dimensionColumns1;
  public metricName1;
  public projectName;
  public oldModelName;
  public modelOwner;
  public tableInfoList;
  public partitionDateTblName;
  public partitionDateColName;
  public partitionDateFormatName;
  public checkBoxName;
  public partitionTimeTblName;
  public paritionTimeColName;
  public partitionTimeFormatName;
  public partitionFilter;
  public joinTable;
  public lookUps:any=[];
  public dimension;
  public frmTableList:any=[];
  public joinCondition:any=[];
  public prevModel:any=[];
  public modelList:any=[];
  public dimensionLookup:any=[];
  public selectLookupList:any=[];

  public editFirstSelect:any;
  public editFirstTable:any=[];
  public measureColumns;
  public dimensionList1;
  public kindType;
  public measureLookup;
  public measureColumns1;
  public lookupCondition:any=[];
  constructor() { }



}


