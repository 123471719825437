import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Config } from '../../config';
@Injectable({
  providedIn: 'root'
})
export class ProjectService {

  static url = Config.url + '/projects';
  constructor(private http: HttpClient) { }

  // tslint:disable-next-line:ban-types
  list() {
    return this.http.get(ProjectService.url);
  }
  listReadable() {
    return this.http.get(ProjectService.url + '/readable');
  }
  listReadablePage(page, pageSize) {
    return this.http.get(ProjectService.url + '/paginationreadable?page=' + page + '&size=' + pageSize);

  }
  save(project) {
    return this.http.post(ProjectService.url, project);
  }
  update(project) {
    return this.http.put(ProjectService.url, project);
  }
  delete(project) {
    return this.http.delete(ProjectService.url + '/' + project);
  }
  getProjectByName(project) {
    return this.http.get(ProjectService.url + '/' + project);
  }
}
