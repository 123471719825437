import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-designer',
  templateUrl: './cube-designer.component.html',
  styleUrls: ['./cube-designer.component.scss']
})
export class CubeDesignerComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
