import { Component, OnInit, AfterViewInit, EventEmitter } from '@angular/core';
import { ModelService } from '../../../services/model/model.service';
import { ModelDescService } from '../../../services/model/model-desc.service';
import { ActivatedRoute, Router } from '@angular/router';
import { HybridService } from '../../../services/hybrid/hybrid.service';
import { ModelStepsService } from '../../../services/model/model-steps.service';
import { CubesService } from '../../../services/cubes/cubes.service'
import swal from 'sweetalert2';
import { OrderPipe } from 'ngx-order-pipe';
import * as $ from 'jquery';
import { CommonServiceService } from '../../../services/common-services/common-service.service';

@Component({
  selector: 'app-model',
  templateUrl: './models.component.html',
  styleUrls: ['./models.component.scss']
})
export class ModelsComponent implements OnInit,AfterViewInit {
  loggedUser;
  projectId: string; 
  projectName:any;
  modelList$: any=[];
  hybridList$: any=[];
  showMessage:boolean = false;
  showModelMessage: boolean = false;
  showModal:boolean;
  selectedModel;
  modelName;
  order: string = 'name';
  reverse: boolean = false;
  sortedCollection;
  total;
  load;
  page = 1;
  pageSize = 10;
  pageNumber:number;
  totalElements;
  qubzLicenseExpire:any;
  modelSelected = false;
  modelSelectAll = false;
  searchText;
  public focusSettingEventEmitter = new EventEmitter<boolean>();
  count: any;
  unamePattern = "^[A-Za-z0-9_]{1,100}$";
  constructor(private modelSerivce: ModelService, private modelDescService: ModelDescService, private route: ActivatedRoute,
  private hybridService: HybridService, private router: Router, private modelStepsService: ModelStepsService, private cubesService: CubesService,private orderPipe: OrderPipe, public commonServiceService:CommonServiceService) {
    
    this.sortedCollection = orderPipe.transform(this.modelList$, 'name');
    this.sortedCollection = orderPipe.transform(this.modelList$, 'owner');
    this.sortedCollection = orderPipe.transform(this.modelList$, 'lookups');
    this.sortedCollection = orderPipe.transform(this.modelList$, 'dimensions');
    this.sortedCollection = orderPipe.transform(this.modelList$, 'metrics');
    this.loggedUser = JSON.parse(sessionStorage.getItem("loggedUser"));

   }

   ngAfterViewInit() {
    $(document).ready(function(){
          var contenth = $( window ).height() - 110 ;
          var sidebarh = $( window ).height() - 111 ;
          $(".pagec").css("height", contenth );
          $(".sidebar-wrapper").css("height", sidebarh );
        });
        this.focusSettingEventEmitter.emit(true);
    }

  ngOnInit() {
    this.projectName = this.route.snapshot.paramMap.get("projectId");
    this.listModels(this.page);
    this.loadHybridList();
    this.getModelDesc();
    this.qubzLicenseExpire = JSON.parse(sessionStorage.getItem("qubzLicenseExpire"));
    //this.saveLogs();
  }
  setOrder(value: string) {
    if (this.order === value) {
      this.reverse = !this.reverse;
    }

    this.order = value;
  }
  // This method is to get the list of models.
  	createHybrid(mode: string) {
		this.router.navigate(['projects', this.projectName,'hybrid', mode]);
	}
	editHybrid(mode: string, hybrid: string) {
		this.router.navigate(['projects', this.projectName,'hybrid', mode, hybrid]);
	}
	viewCubes(modelId){
    this.router.navigate(['projects', this.projectName, 'models', modelId, 'cubes']);
  }
	editModel(model) {
		let cubesList = null;
		let readyCubes = [];
		this.cubesService.list({modelName: model.name,projectName:this.projectName}).subscribe(
			data => {
				cubesList = data;
				for (let cube of cubesList) {
					if (cube.status === 'READY') {
						readyCubes.push(cube);
					}
				}
				if (readyCubes.length == 0) {
					this.router.navigate(['projects', this.projectName, 'models', model.name, 'edit'])		
				} else {
					let cubesString = readyCubes[0].name;
					if(readyCubes.length > 1 ) {
						for (let i = 1; i < readyCubes.length; i+=1 ) {
							cubesString += ", "
							cubesString += readyCubes[i].name; 
						}
					}
					swal(
        				{
      						type:'warning',
      						title: 'This model is being used by: ',
      						text: cubesString,
      						showCancelButton: false, confirmButtonText: 'Ok'
    					}
					)
				}
			}
		)
		
  }
  cancel(){
    this.showModal = false;
   }

  editVisual(projectName,modelName){
    let cubesList = null;
		let readyCubes = [];
		this.cubesService.list({modelName: modelName,projectName:this.projectName}).subscribe(
			data => {
				cubesList = data;
				for (let cube of cubesList) {
					if (cube.status === 'READY') {
						readyCubes.push(cube);
					}
				}
				if (readyCubes.length == 0) {
					this.router.navigate(["/projects/"+projectName+"/visual-model/"+modelName+"/edit"]);	
				} else {
					let cubesString = readyCubes[0].name;
					if(readyCubes.length > 1 ) {
						for (let i = 1; i < readyCubes.length; i+=1 ) {
							cubesString += ", "
							cubesString += readyCubes[i].name; 
						}
					}
					swal(
        				{
      						type:'warning',
      						title: 'This model is being used by: ',
      						text: cubesString,
      						showCancelButton: false, confirmButtonText: 'Ok'
    					}
					)
				}
			}
		)
    
    }

  cloneModel(model){
    this.showModal = true;
    this.selectedModel=model;
    this.modelName=model.name+'_clone';
  }
  checkModelNameEmpty(){
    if(this.selectedModel.name === undefined || this.selectedModel.name === null || this.selectedModel.name=== ''){
      this.selectedModel.name='';
      this.showModal= false;
      this.router.navigate(['/projects/',this.projectId,'models']);
    } else{
     
      this.showModal= true;
    }
  }

  cloneService(){
    let params={
      "ccInCheck": "string",
      "isSeekingExprAdvice": true,
      "message": "string",
      "modelDescData": "string",
      "modelName": this.modelName,
      "project": this.projectName,
      "seekingExprAdvice": true,
      "successful": true,
      "uuid": this.modelStepsService.uuid
    }
    this.modelSerivce.clone(this.selectedModel.name,params).subscribe(data=>{

        swal("Success!", "Model cloned successfully", "success"); 
        this.showModal= false;
        this.listModels(this.page);
        this.modelSelected = false;
        this.modelSelectAll = false;
    }, (error) => {
                   swal({
                    type:'error',
                    title: ' OOPS...',
                    text:error.error.msg,
                    confirmButtonText: 'Ok',
                  }).then((status) => {
                    if(status.value==true){
                      this.showModal= false;
                      // this.router.navigate(['/projects/',this.projectId,'models']);
                      }
                  }, (dismiss) => {
                    if (dismiss === 'cancel') {
                    }
                    
                  });
                }); 
 
  }

  saveCloneModel(){
    swal(
      { 
      type:'warning', 
      title: 'Are you sure you want to clone the model?', 
      showCancelButton: true, confirmButtonText: 'Yes', 
      cancelButtonText: 'No'
      } 
      ).then((status) => {
      if(status.value==true){
        this.cloneService();
      }
      }, (dismiss) => {
      if (dismiss === 'cancel') {
      }
      });
  }
  editJson(model){
    this.cubesService.getCubebyModel(model.name,this.projectName).subscribe(data =>{
      if(data){
        let cubes=[];
        if(data[0]){
          for(let [key, value] of Object.entries(data)){
            cubes.push(value.name);
          }
        swal("Ooops","The model is still used by "+cubes,"info");
        return;
        }
        else{
          this.router.navigate(['/projects/',this.projectName,'models',model.name,'json-edit']);
        }
      }
     
    });
    
  }
  getlist(event: any){
    this.page = event;
    this.listModels(this.page);
  }
  listModels(page) {
    this.load=true;
	const params = {
		projectName: this.projectName,
    page: page - 1,
    pageSize: this.pageSize
	}
    this.modelSerivce.listModels(params.projectName,params.page,params.pageSize).subscribe(data =>{
      this.load=false;
      this.modelList$=data.content;
      this.total=this.modelList$.length;
      this.count = data.totalElements;
      if (this.modelList$.length>0){
     this.showModelMessage = false;
    } else {
     this.showModelMessage = true;
    }
    if(this.modelSelectAll == true){
      for(let model in this.modelList$){
        this.modelList$[model].selected = true;
      }
    }
    });
  }

  // This method is to get the list of hybrids.

  loadHybridList(){
	const params = {
		project: this.projectName
	}
    this.hybridService.getHybrids(params).subscribe(data=>{
    this.hybridList$ = data;
    if (this.hybridList$.length>0){
     this.showMessage = false;
    } else {
     this.showMessage = true;
    }
    });
  }

   // This method is to delete the model selected.

  dropModel(delModel) {
  swal(
        {
      type:'warning',
      title: 'Are you sure you want to drop this model?',
      text: '',
      showCancelButton: true, confirmButtonText: 'Yes',
      cancelButtonText: 'No'
    }
    ).then((status) => {
    if(status.value==true){
    this.modelSerivce.drop(delModel).subscribe(
    data =>
    { 
      this.listModels(this.page);
      this.modelSelected = false;
      this.modelSelectAll = false;
      swal("Success!", "Model is dropped successfully", "success"); 
    }, (error) => {
      swal({
       type:'error',
       title: ' OOPS...',
       text:error.error.msg,
       confirmButtonText: 'Ok',
     }).then((status) => {
       if(status.value==true){
         }
     }, (dismiss) => {
       if (dismiss === 'cancel') {
       }
       
     });
   }); 


    }
  },(dismiss) => {

  });

  }

  // This method is to delete the hybrid selected.

  dropHybrid(delHybrid) {
  swal(
        {
      type:'warning',
      title: 'Are you sure you want to drop this hybrid?',
      text: '',
      showCancelButton: true, confirmButtonText: 'Yes',
      cancelButtonText: 'No'
    }
    ).then((status) => {
    if(status.value==true){
    this.hybridService.removeQuery(this.projectName,delHybrid).subscribe(
    data =>
    { 
      
      this.loadHybridList();
      swal("Success!", "Hybrid is dropped successfully", "success"); 
    }
    , (error) => {
      swal({
       type:'error',
       title: ' OOPS...',
       text:error.error.msg,
       confirmButtonText: 'Ok',
     }).then((status) => {
       if(status.value==true){
         }
     }, (dismiss) => {
       if (dismiss === 'Cancel') {
       }
       
     });
   }); 


    }
  },(dismiss) => {
   
  });

  }


  loadModelInfo(id){
    this.modelStepsService.modelName=undefined;
    this.modelStepsService.modelDescription = undefined;
    this.router.navigate(['/projects/',id,'models','designer','info']);
  }

  clone() {
    const model = 'NewModelA';
    const modelDesc = {
        modelName: 'NewModelA_clone',
        project: 'NewAdminProj'
    };
    this.modelSerivce.clone(model, modelDesc).subscribe();
  }

  update() {
    this.modelSerivce.update({}).subscribe();
  }
  save() {
    this.modelSerivce.save({}).subscribe();
  }
  getModelDesc() {
    this.modelDescService.query('NewModelA').subscribe();
  }
  openInVisualCanvas(model){
    this.router.navigate(['projects', this.projectName, 'visual-model', model.name, 'details'])		
  }
  loadVisualModelInfo(id){
    this.modelStepsService.modelName=undefined;
    this.modelStepsService.modelDescription = undefined;
    this.router.navigate(['/projects/',id,'visual-model']);
  }
  loadCloneModel(id,name){
    this.modelStepsService.modelName = name;
    this.router.navigate(['/projects/',id,'models',id,'clone']);
  }

  loadviewModelInfo(id,name){
    this.modelStepsService.modelName = name;
    this.router.navigate(['/projects/',id,'models',name,'detail']);
  }

  saveLogs(){
    var today = new Date();
    let param={
      username:this.loggedUser.username,
      targetPage:'projects/'+this.projectName+'/models',
      day:today.getDay(),
      month:today.getMonth(),
      year:today.getFullYear()
    };
    this.commonServiceService.saveLogs(param).subscribe(
      data => {

      });
  }
  //QUBZ-3825- multiple module delete UI 22/8/22
  deleteSelectedModels(){
    swal({
      type: "warning",
      title: "Are you sure you want to delete this models ?",
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "No",
    }).then((status) => {
      if (status.value == true) {
        let delModels;
        delModels = this.modelList$
          .filter(
            (cubes) => cubes.selected === true
          )
          .map((x) => x.name)
          .join(",");
          if(delModels){
            console.log(delModels);
            this.modelSerivce.dropAll(delModels).subscribe(
              (data: any) => {
                swal("Success", "Selected models deleted", "success");
                this.modelSelectAll = false;
                this.modelSelected = false;
                this.listModels(this.page);
              },
              (error) => {
                swal("Ooops", error.error.msg, "error");
                this.modelSelectAll = false;
                this.modelSelected = false;
                this.listModels(this.page);
              }
            );
          }
      }
    });
  }
  selectAllBoxes() {
    let model: any;
    for (model of this.modelList$) {
      model.selected = this.modelSelectAll;
    }
    this.modelSelected = this.modelSelectAll;
  }
  checkAnyCubeSelected() {
    let selected = true;
    let modelSelected = false;
    let cubes: any;
    for (cubes of this.modelList$) {
      if (!cubes.selected) selected = false;
      else modelSelected = true;
    }
    this.modelSelected = modelSelected;
    this.modelSelectAll = selected;
  }
  //QUBZ-3825- multiple module delete UI 22/8/22
}

