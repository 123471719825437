import { Component, OnInit, AfterViewInit } from '@angular/core';
import { TableService } from 'src/app/services/table/table.service';
import { ActivatedRoute } from '@angular/router';
import { Table } from 'src/app/models/table/table.model';
import { Columns } from 'src/app/models/table/columns/columns.model';
import { Snapshot } from 'src/app/models/table/snapshot/snapshot.model';
import swal from 'sweetalert2';
import * as $ from 'jquery';
import * as lodash from 'lodash';
import { StreamingService } from 'src/app/services/streaming/streaming.service';
import { FormGroup } from '@angular/forms';
import { Location } from '@angular/common';
import { CommonServiceService } from '../../../services/common-services/common-service.service';

@Component({
  selector: 'app-project-data-sources',
  templateUrl: './project-data-sources.component.html',
  styleUrls: ['./project-data-sources.component.scss']
})

export class ProjectDataSourcesComponent implements OnInit, AfterViewInit {
  loggedUser;
  databases: any[];
  dbList: string[] = [];
  tableColumns: any[];
  tableSnapshot: any[];
  tableName: any;
  kfkConfig: any[];
  streamingConfig: any[]
  isActive: any[] = [];
  tableIsActive: any[] = [];
  tabIsActive: any[] =[];
  projectId: string;
  cardinality: string[];
  body = {calculate : true};
  tableList: Table[] = [];
  snapTable: Table;
  selectedTable: Table;
  filteredColumns: Columns[];
  //kfkConfig: any[];
  cardIsChecked: boolean;
  currentTab: number = 1;
  //Pagination vars
  col:{[i:number]: number} ={}
  p: { [id: string]: number} = {};
  activeColumn;
  load;
  qubzLicenseExpire:any;
  searchText:string;
  dbs;
  kafkaForm:any;
  jsonForm:FormGroup;
  // config streaming v2 container 
  showDiv = {
    puopen : false,
    puopenbg : false ,
    stepone : true,
    configstreaming : false,
    cancelbtn : true,
    nextbtn :true,
    backbtn :false,
    submitbtn : false
  }
  isKafkaFormValid: boolean;
  isJsonFormValid: boolean = true;
  constructor(private tableService: TableService, private route: ActivatedRoute, private streamingService: StreamingService,private location: Location, public commonServiceService:CommonServiceService) { }

  ngAfterViewInit() {
    $(document).ready(function () {
      function openpu() {
        $("#configbtn").on("click", function(){
        var puconetntheight = $(window).height() - 95;
          $("#pucontent").css("height", puconetntheight);
        });
      }
      //setTimeout(openpu,2); 

      function treeheight() {
        var pageheight = $(".pagec").height() - 250;
        $(".tabletree_parent").css("height", pageheight);
      }
      setTimeout(treeheight, 2);

    })
    
      
    
    $( window ).resize(function() {
      var puconetntheight = $(window).height() - 95;
      $("#pucontent").css("height", puconetntheight);

      function treeheight() {
        var pageheight = $(".pagec").height() - 250;
        $(".tabletree_parent").css("height", pageheight);
      }
      setTimeout(treeheight, 2);


    });
  }
  submitData(){
    let body;
    swal({
      type:'warning',
      title: 'Are you sure to save the streaming table ?',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No'
    }).then((status) => {
      if(status.value==true){
        let errors: any = null;

        let server=[];
        this.kafkaForm.servers.forEach(element => {
          server.push(element.host+":"+element.port)
        });
        let table = {};
        this.jsonForm.value.tableColumns.forEach(ele => {
          if (ele.comment !== 'derived time dimension') {
            table = {
              ...table,
              [ele.columnName]: ele.columnName
            }
          }
        });
        let colData = [];
        this.jsonForm.value.tableColumns.map(item=>{
          if(item.comment !== 'derived time dimension' )
          colData = [...colData,{name: item.columnName, datatype: item.columnType, comment: item.comment, id: item.id}];
          else colData = [...colData,{name: item.columnName, datatype: item.columnType, id: item.id}];
        })
                body = {
                  message:null,
                  project: this.route.snapshot.paramMap.get("projectId"),
                  streamingConfig: JSON.stringify({
                    name: this.jsonForm.value.tableName,
                    properties:{
                      topic:this.kafkaForm.topic,
                      "bootstrap.servers": server.join(',')
                    },
                    parser_info:{
                      ts_col_name:this.jsonForm.value.tsColumn,
                      ts_parser: this.jsonForm.value.tsParser,
                      ts_pattern: this.jsonForm.value.tsPattern,
                      field_mapping:table
                    }}),
                    successful:true,
                    tableData: JSON.stringify({
                      source_type:20,
                      name:this.jsonForm.value.tableName,
                      columns:colData
                    })

                }
        this.streamingService.saveStreamingForV2(body).subscribe( 
          result => {
           
          },
          error => {
            errors=error;
            swal("Failed to save Stream",errors.error.msg, "error")
          },
          ()=> {
            if(!errors) {
              swal("Success!","Stream was saved", "success").then(
                (status)=> {
                  if(status.value == true) {
                    this.location.back();
                  }
                }
              )
            }
          }
        ) 
        } 
    }, (dismiss) => {
      if (dismiss === 'cancel') {
      }
    });
  }
  cancel() {
    this.location.back();
  }


  kafkaFormValid(status:boolean){
    this.isKafkaFormValid = status;
  }

  kafkaFormData(form:any){
    this.kafkaForm = form;
  }
  jsonFormData(form:FormGroup){
    this.jsonForm = form;
  }
  jsonFormDataValid(status:boolean){
    this.isJsonFormValid = status;
  }
  ngOnInit() {
    this.loggedUser=JSON.parse(sessionStorage.getItem("loggedUser"));
    this.projectId = this.route.snapshot.paramMap.get("projectId");
    this.list();
    this.qubzLicenseExpire = JSON.parse(sessionStorage.getItem("qubzLicenseExpire"));
    //this.saveLogs();
  }
  getActive(activeColumn){
      this.activeColumn=activeColumn;
      this.tableService.setActiveColumn(this.activeColumn);
      this.currentTab =1;
  }
  authority() {
    let user = JSON.parse(sessionStorage.getItem('loggedUser'))
    for(let authority of user.authorities) {
      if(authority.authority === 'ROLE_ADMIN') {
        return true;
      }
    }
  }
  setTab(tab:number) {
    this.currentTab = tab
  }
  reload(db : string, tName: string) {
      let table =db +'.'+ tName;
      if(this.cardIsChecked) {
        this.genCardinality(table)
      }
      this.get(db, tName)
  }

  searchFilter(filter: string) {
    let filterText = filter.toUpperCase();
    this.selectedTable = JSON.parse(sessionStorage.getItem('currentTable'))
    this.filteredColumns = this.selectedTable.columns
    if(filterText !== '') {
      this.filteredColumns = lodash.filter(this.selectedTable.columns, { 'columnName': filterText})
      this.selectedTable.columns = this.filteredColumns
    }
  }

  list(){
    this.load=true;
    const params = {
      ext: true,
      project: this.projectId
    };
    this.tableService.list(params)
      .subscribe( 
        (data: any[]) => {
          this.databases = data;
          this.dbs=[];
          this.dbList=[];
          for (var i = 0; i < data.length ;i++) {
            this.tableList[i] = new Table(this.databases[i])
            this.tableList[i].columns = this.databases[i].columns.map((columns:any) => new Columns(columns))
            if((!this.dbList.includes(this.databases[i].database))&&(this.databases[i].name!="")) {
              this.dbList.push(this.databases[i].database);
              this.dbs.push(this.databases[i]);
            }
          };
          this.databases =  this.databases.filter((value,index)=>this.databases.indexOf(value===index));
          sessionStorage.setItem("tablesInProject",JSON.stringify(this.tableList))
         this.load=false;
        }
      );
  }
  createTableObject(db: string , tableName: string) {
    this.get(db, tableName);
    
  }
  getConfig(db,table){
    this.streamingService.getConfig(db,table)
    .subscribe( (data: any[]) => {
      if(data)
      this.kfkConfig=data[0];
    });
  }
  get(databaseName:string, tableName: string) {
    let table: string = databaseName + '.' + tableName;
    this.getSnapShots(databaseName, tableName);
    this.tableService.get(this.projectId, table)
      .subscribe( (data: any[]) => {
        this.tableColumns = data;
        this.tableName = this.tableColumns['name'];
        this.tableColumns = this.tableColumns['columns'];
        let tempTable = new Table(data);
        let tempTableList = JSON.parse(sessionStorage.getItem("tablesInProject"));
        for(let table of tempTableList) {
          if(tempTable.dbName === table.dbName && tempTable.tableName === table.tableName) {
            tempTable.exd = table.exd
            tempTable.cardinality = table.cardinality
          }
        }
        tempTable.columns = this.tableColumns.map( (columns:any) => new Columns(columns));
        this.selectedTable = tempTable;
        if ( this.selectedTable.sourceType ===1) {
          this.getKfkConfig()
        }else if(this.selectedTable.sourceType ===20){
          this.getConfig(this.selectedTable.dbName,this.selectedTable.tableName);
        }
        sessionStorage.setItem("currentTable",JSON.stringify(this.selectedTable));
        this.currentTab =1;
      }
    );
  }

  getSnapShots(db: string, tableName: string) {
    this.tableService.getSnapshots( this.projectId, db+'.'+tableName)
      .subscribe( (data: any[]) => {
        this.tableSnapshot = data;
        this.snapTable = JSON.parse(sessionStorage.getItem("currentTable"));
        this.selectedTable.snapshot = this.tableSnapshot.map((snapshot:any)=> new Snapshot(snapshot))
        sessionStorage.setItem('currentTable',JSON.stringify(this.selectedTable))
      })
  }

  genCardinality(table) {
    let body = {calculate:true}
    this.tableService.genCardinality( table, this.projectId, body)
      .subscribe( (data: any[]) => {
        this.cardinality = data;
      })
  }

  unloadTable(db, tName) {
    let table = db + '.' + tName;
    swal({
      type:'warning',
      title: 'Are you sure you want to unload this table?',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No'
    }).then((status) => {
      if(status.value==true){
        this.tableService.unLoadHiveTable(this.projectId, table)
        .subscribe( data => {
          this.list();
          this.selectedTable = null;
          this.tableName = null;
          swal("Success!", "The table " + table + " has been successfully unloaded", "success");
        }, (error) =>{
          
          swal("Ooops",error.error.msg, "error");
         
        });
      }
    }, (dismiss) => {
      if (dismiss === 'cancel') {
      }
    });
  }
  getKfkConfig() {
    const params = {
      kafkaConfigName: this.selectedTable.dbName + "." + this.selectedTable.tableName,
      project: this.projectId
    }
    this.streamingService.getKfkConfig(params)
      .subscribe((data:any[])=> {
        this.kfkConfig = data;
      })
  }

  saveLogs(){
    var today = new Date();
    let param={
      username:this.loggedUser.username,
      targetPage:'projects/'+this.projectId+'/data-sources',
      day:today.getDay(),
      month:today.getMonth(),
      year:today.getFullYear()
    };
    this.commonServiceService.saveLogs(param).subscribe(
      data => {

      });
  }
  
}

