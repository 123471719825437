import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  OnInit,
} from "@angular/core";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { CubesService } from "src/app/services/cubes/cubes.service";
import { TableService } from "src/app/services/table/table.service";
import * as $ from "jquery";

@Component({
  selector: "app-stream-config",
  templateUrl: "./stream-config.component.html",
  styleUrls: ["./stream-config.component.scss"],
})
export class StreamConfigComponent implements OnInit, AfterViewInit {
  projectId;
  editCube;
  errorShard;
  error;
  purgeCube: boolean = true;
  streamingConfig = {
    algorithm: "INMEM",
    window: 3600,
    duration: 3600,
    interval: 300,
    policy: 1,
    purgeretention: 86400,
  };
  purgeForm: FormGroup;
  fbForm: FormGroup;
  cubeWindow: number;
  cubeRetention: number;
  tableInfo: any;
  goToNext: boolean = false;
  disableNext: boolean = false;
  constructor(
    private cubesService: CubesService,
    private cdr: ChangeDetectorRef,
    private tableService: TableService,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private router: Router
  ) {}

  ngOnInit() {
    this.projectId = this.route.snapshot.paramMap.get("projectId");
    this.editCube = this.route.snapshot.paramMap.get("cubeId");
    if (this.cubesService.cubeMeta.dimensions.length == 0) {
      this.router.navigate([
        "/projects/" + this.projectId + "/cubes/designer/info",
      ]);
    }
    //QUBZ-3746 QUBZ-In Realtime Streaming Tab Next button should be disable
    if(this.editCube)
    {
      const value = localStorage.getItem('valueChanged');

      if(value === 'true'){
        this.disableNext=true;
      }
      else
      {
        this.disableNext=false;
      }

      localStorage.setItem('valueChanged','false');
    }
    else{
      this.disableNext=true;
    }
 //QUBZ-3746 QUBZ-In Realtime Streaming Tab Next button should be disable
    this.tableInfo = this.cubesService.tableInfo;

    this.fbForm = new FormGroup(
      {
        window: new FormControl(null, [
          Validators.required,
          Validators.min(1),
          Validators.pattern("^[0-9]*$"),
        ]),
        retention: new FormControl(null, [
          Validators.required,
          Validators.min(1),
          Validators.pattern("^[0-9]*$"),
        ]),
      },
      validateWindow
    );

    this.purgeForm = new FormGroup(
      {
        window: new FormControl(null, [
          Validators.required,
          Validators.min(1),
          Validators.pattern("^[0-9]*$"),
        ]),
        retention: new FormControl(null, [
          Validators.min(1),
          Validators.max(24),
          Validators.pattern("^[0-9]*$"),
          Validators.required,
        ]),
      },
      validateWindow
    );

    if (
      this.cubesService.cubeMeta.override_qubz_properties["qubz.cube.algorithm"]
    ) {
      this.streamingConfig = {
        algorithm:
          this.cubesService.cubeMeta.override_qubz_properties[
            "qubz.cube.algorithm"
          ],
        window:
          this.cubesService.cubeMeta.override_qubz_properties[
            "qubz.stream.cube.window"
          ],
        duration:
          this.cubesService.cubeMeta.override_qubz_properties[
            "qubz.stream.cube.duration"
          ],
        interval:
          this.cubesService.cubeMeta.override_qubz_properties[
            "qubz.stream.index.checkpoint.intervals"
          ],
        policy:
          this.cubesService.cubeMeta.override_qubz_properties[
            "qubz.stream.segment.retention.policy"
          ] == "fullBuild"
            ? 1
            : 0,
        purgeretention:
          this.cubesService.cubeMeta.override_qubz_properties[
            "qubz.stream.segment.retention.policy.purge.retentionTimeInSec"
          ],
      };

      if (
        this.cubesService.cubeMeta.override_qubz_properties[
          "qubz.stream.segment.retention.policy"
        ] == "fullBuild"
      ) {
        this.purgeCube = false;
        this.cubeRetention = this.streamingConfig.duration
          ? this.streamingConfig.duration / 3600
          : null;
        this.cubeWindow = this.streamingConfig.window
          ? this.streamingConfig.window / 3600
          : null;

        this.fbForm.setValue({
          window: this.cubeWindow,
          retention: this.cubeRetention,
        });
      } else {
        this.purgeCube = true;
        this.cubeWindow = this.streamingConfig.window
          ? this.streamingConfig.window / 3600
          : null;
        this.cubeRetention = this.streamingConfig.purgeretention
          ? this.streamingConfig.purgeretention / 3600
          : null;

        this.purgeForm.setValue({
          window: this.cubeWindow,
          retention: this.cubeRetention,
        });
      }
    }
  }

  get pf() {
    return this.purgeForm.controls;
  }

  get f() {
    return this.fbForm.controls;
  }

  switchCubeType(type: string) {
    if (type === "purge") {
      this.purgeCube = true;
      this.fbForm.reset();
    } else {
      this.purgeCube = false;
      this.purgeForm.reset();
    }

    this.cubeRetention = null;
    this.cubeWindow = null;
  }

  previousPage(page) {
    this.saveConfig();
    if (this.cubesService.editMode)
      this.router.navigate([
        "/projects/" +
          this.projectId +
          "/cubes/" +
          this.editCube +
          "/designer/" +
          page,
      ]);
    else
      this.router.navigate([
        "/projects/" + this.projectId + "/cubes/designer/" + page,
      ]);
  }
  
  onSaveData(type: string) {
    if (this.purgeCube) {
      this.cubeWindow = this.pf.window.value * 3600;
      this.cubeRetention = this.pf.retention.value * 3600;
    } else {
      this.cubeWindow = this.f.window.value * 3600;
      this.cubeRetention = this.f.retention.value * 3600;
    }
  }

  buttonNext(page) {
    this.saveConfig();
    if (this.cubesService.editMode)
      this.router.navigate([
        `projects/${this.projectId}/cubes/${this.editCube}/designer/${page}`,
      ]);
    else
      this.router.navigateByUrl(
        `projects/${this.projectId}/cubes/designer/${page}`
      );
  }

  saveConfig() {
    let override_qubz_properties = {};

    let windowValue = this.purgeCube
      ? +this.purgeForm.value.window * 3600
      : +this.fbForm.value.window * 3600;
    let retentionValue = this.purgeCube
      ? +this.purgeForm.value.retention * 3600
      : +this.fbForm.value.retention * 3600;

    if (this.tableInfo.source_type == 20) {
      override_qubz_properties = {
        "qubz.stream.cube.window": windowValue,
        "qubz.stream.cube.duration": this.purgeCube
          ? windowValue
          : retentionValue,
        "qubz.stream.index.checkpoint.intervals": this.streamingConfig.interval,
        "qubz.cube.algorithm": this.streamingConfig.algorithm,
        "qubz.stream.segment.retention.policy": this.purgeCube
          ? "purge"
          : "fullBuild",
        "qubz.stream.segment.retention.policy.purge.retentionTimeInSec":
          retentionValue,
      };

      if (!this.purgeCube) {
        delete override_qubz_properties[
          "qubz.stream.segment.retention.policy.purge.retentionTimeInSec"
        ];
      }
    }

    this.cubesService.cubeMeta.override_qubz_properties =
      override_qubz_properties;
  }

  ngAfterViewInit() {
    this.cdr.detectChanges();
    $(document).ready(function () {
      var contenth = $(window).height() - 110;
      var sidebarh = $(window).height() - 111;
      $(".pagec").css("height", contenth);
      $(".sidebar-wrapper").css("height", sidebarh);
    });

    function progressli_check() {
      $(".progresslic ").each(function () {
        if ($(".progressbar li ").length > 6) {
          $(".menublock").addClass("tabw_seven");
        }
      });
    }
    setTimeout(progressli_check, 100);

    $("body").on("click", ".radio_fb", function () {
      $("#myBar").css("width", "0%");
    });

    $("body").on("click", "#fb_save", function () {
      $(".pagec").animate({ scrollTop: $(document).height() }, 1200);
      $("#myBar").css("width", "0%");
      if (
        $.trim($("#cubevalue").val()) == "" ||
        $.trim($("#retention_fb").val()) == ""
      ) {
        $(".show_fb_error").show();
        $(".show_fb_text, .animation_ec").hide();
      } else {
        var cubeValue = $("#cubevalue").val();
        var retentionFbValues = $("#retention_fb").val();
        $("#buildcube").html(cubeValue);
        $("#buildretention").html(retentionFbValues);
        $(".show_fb_text, .animation_ec").show();
        $(".show_fb_error").hide();
      }
    });

    /* progress function */
    /* $("body").on("click", "#f_save", function () {
      $(".pagec").animate({ scrollTop: $(document).height() }, 1200);
      $("#cubevalue_p").attr("disabled", "disabled");
      $(".save_disable_p, .stackimg_ec").css("display", "block");
      $(".save_disable_fb, .start_again").css("display", "none");

      if ($("#cubevalue_p").val() == "1") {
        $("#stackimg").attr("src", "../../../../assets/images/segment_24.gif");
      } else if ($("#cubevalue_p").val() == "2") {
        $("#stackimg").attr("src", "../../../../assets/images/segment_12.gif");
      } else if ($("#cubevalue_p").val() == "3") {
        $("#stackimg").attr("src", "../../../../assets/images/segment_8.gif");
      } else if ($("#cubevalue_p").val() == "4") {
        $("#stackimg").attr("src", "../../../../assets/images/segment_4.gif");
      } else if ($("#cubevalue_p").val() == "5") {
        $("#stackimg").attr("src", "../../../../assets/images/segment_5.gif");
      } else if ($("#cubevalue_p").val() == "6") {
        $("#stackimg").attr("src", "../../../../assets/images/segment_6.gif");
      } else if ($("#cubevalue_p").val() == "7") {
        $("#stackimg").attr("src", "../../../../assets/images/segment_7.gif");
      } else if ($("#cubevalue_p").val() == "8") {
        $("#stackimg").attr("src", "../../../../assets/images/segment_3.gif");
      } else if ($("#cubevalue_p").val() == "9") {
        $("#stackimg").attr("src", "../../../../assets/images/segment_9.gif");
      } else if ($("#cubevalue_p").val() == "10") {
        $("#stackimg").attr("src", "../../../../assets/images/segment_10.gif");
      } else if ($("#cubevalue_p").val() == "11") {
        $("#stackimg").attr("src", "../../../../assets/images/segment_11.gif");
      } else if ($("#cubevalue_p").val() == "12") {
        $("#stackimg").attr("src", "../../../../assets/images/segment_2.gif");
      } else if ($("#cubevalue_p").val() == "13") {
        $("#stackimg").attr("src", "../../../../assets/images/segment_13.gif");
      } else if ($("#cubevalue_p").val() == "14") {
        $("#stackimg").attr("src", "../../../../assets/images/segment_14.gif");
      } else if ($("#cubevalue_p").val() == "15") {
        $("#stackimg").attr("src", "../../../../assets/images/segment_15.gif");
      } else if ($("#cubevalue_p").val() == "16") {
        $("#stackimg").attr("src", "../../../../assets/images/segment_16.gif");
      } else if ($("#cubevalue_p").val() == "17") {
        $("#stackimg").attr("src", "../../../../assets/images/segment_17.gif");
      } else if ($("#cubevalue_p").val() == "18") {
        $("#stackimg").attr("src", "../../../../assets/images/segment_18.gif");
      } else if ($("#cubevalue_p").val() == "19") {
        $("#stackimg").attr("src", "../../../../assets/images/segment_19.gif");
      } else if ($("#cubevalue_p").val() == "20") {
        $("#stackimg").attr("src", "../../../../assets/images/segment_20.gif");
      } else if ($("#cubevalue_p").val() == "21") {
        $("#stackimg").attr("src", "../../../../assets/images/segment_21.gif");
      } else if ($("#cubevalue_p").val() == "22") {
        $("#stackimg").attr("src", "../../../../assets/images/segment_22.gif");
      } else if ($("#cubevalue_p").val() == "23") {
        $("#stackimg").attr("src", "../../../../assets/images/segment_23.gif");
      } else if ($("#cubevalue_p").val() == "24") {
        $("#stackimg").attr("src", "../../../../assets/images/segment_1.gif");
      } else {
        $("#error_no").css("display", "block");
      }

      var elem = document.getElementById("myBar");
      var width = 1;
      var id = setInterval(frame, 240.38);
      function frame() {
        if (width >= 100) {
          clearInterval(id);
          $(".save_disable_p").css("display", "none");
          $("#cubevalue_p").removeAttr("disabled");
          $("#myBar").css("width", "0%");
          $(".start_again").css("display", "block");
        } else {
          width++;
          elem.style.width = width + "%";
        }
      }
    }); */

    /* $("body").on("click", ".show_fb_save", function () {
      $(".show_help").css("display", "none");
    }); */

    $("body").on("click", ".helpbtn", function () {
      $(".pagec").animate({ scrollTop: $(document).height() }, 1200);
      $(".show_help").css("display", "block");
      $("#stackimg").attr(
        "src",
        "../../../../assets/images/segment_4_fast.gif"
      );
      $(".stackimg_ec").css("display", "block");
      $(".hide_help").show();
      var elem = document.getElementById("myBar");
      var width = 1;
      var id = setInterval(frame, 120.19);
      function frame() {
        if (width >= 100) {
          clearInterval(id);
          //$(".save_disable_p").css("display", "none");
          //$("#cubevalue_p").removeAttr("disabled");
          $("#myBar").css("width", "0%");
          $(".hide_help").hide();
          //$(".start_again").css("display", "block");
        } else {
          width++;
          elem.style.width = width + "%";
        }
      }
    });

    $("body").on("click", ".cancel_btn", function () {
      $("#cubevalue_p").removeAttr("disabled");
      $("#myBar").css("width", "0%");
    });
  }
  // QUBZ-3730-In Streaming page Next Option should be disable until select save 7/07/2022
  enableNextBtn(){
    this.disableNext=false;
     //QUBZ-3746 QUBZ-In Realtime Streaming Tab Next button should be disable
    localStorage.setItem('valueChanged','true');
  }
  valueChanged(){
    this.disableNext = true;
     //QUBZ-3746 QUBZ-In Realtime Streaming Tab Next button should be disable
    localStorage.setItem('valueChanged','true');
  }
  // QUBZ-3730-In Streaming page Next Option should be disable until select save 7/07/2022
}

function validateWindow(cubeForm: FormGroup): { [key: string]: boolean } {
  if (
    cubeForm.controls["window"].value &&
    cubeForm.controls["retention"].value
  ) {
    if (
      +cubeForm.controls["window"].value > +cubeForm.controls["retention"].value
    ) {
      return { incorrect: true };
    } else {
      return null;
    }
  }
}
