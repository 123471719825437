import { Component, OnInit, AfterViewInit } from '@angular/core';
import { CoreServicesService } from 'src/app/services/core-services/core-services.service';
import swal from 'sweetalert2';
import { interval } from 'rxjs';
import * as $ from 'jquery';
import { CommonServiceService } from '../../services/common-services/common-service.service';


@Component({
  selector: 'app-core-services',
  templateUrl: './core-services.component.html',
  styleUrls: ['./core-services.component.scss']
})
export class CoreServicesComponent implements OnInit, AfterViewInit {
loggedUser;
serviceStatus;
load;
interval;
  constructor(private coreServicesService:CoreServicesService, public commonServiceService:CommonServiceService) {
    this.loggedUser = JSON.parse(sessionStorage.getItem("loggedUser"));
   }

  ngOnInit() {
    this.interval = setInterval(() => {
      this.getStatus();
    }, 60000);
    this.getStatus();
    //this.saveLogs();
  }
  ngOnDestroy() {
    if (this.interval) {
      clearInterval(this.interval);
    }
  }
  clearInterval(interval) {
    this.interval = setInterval(() => {

    }, 0);
  }
  getStatus(){
    this.load=true;
    this.coreServicesService.getServiceStatus().subscribe(
      (data:any) => {
        this.load=false;
        this.serviceStatus = data;
    },
    error=>{
      this.load=false;
    }
    );
  }
  startService(service){
    this.load=true;
    let param ={
      "serviceName": service.serviceName,
      "serviceStatus": service.serviceStatus
    }
    this.coreServicesService.startService(param).subscribe(
      (data:any) => {
        this.load=false;
        swal("Success!", service.serviceName + " is successfully started", "success");
        service.serviceStatus=data.serviceStatus;
        // this.getStatus();
    },
    error=>{
      this.load=false;
      swal('Oops...', "Some error occured", 'error');
    }
    );
  }
  stopService(serviceName,serviceStatus){
    this.load=true;
    let param ={
      "serviceName": serviceName,
      "serviceStatus": serviceStatus
    }
    this.coreServicesService.stopService(param).subscribe(
      (data:any) => {
        this.load=false;
        swal("Success!", serviceName + " is successfully stopped", "success");
        this.getStatus();
    },
    error=>{
      this.load=false;
      swal('Oops...', "Some error occured", 'error');
    }
    );
  }

  ngAfterViewInit() {
    $(document).ready(function () {
      var contenth = $(window).height() - 110;
      var sidebarh = $(window).height() - 111;
      $(".pagec").css("height", contenth);
      $(".sidebar-wrapper").css("height", sidebarh);

      $(".stop_play").on("click", function(){
        $(this).toggleClass("stop")
      })
    });
  }

  saveLogs(){
    var today = new Date();
    let param={
      username:this.loggedUser.username,
      targetPage:'administration/core-services',
      day:today.getDay(),
      month:today.getMonth(),
      year:today.getFullYear()
    };
    this.commonServiceService.saveLogs(param).subscribe(
      data => {
  
      });
  }
}
