import { Component, OnInit } from '@angular/core';
import { DataRequestService } from '../../../../services/data-access/data-request.service';
import { UserSettingsService } from '../../../../services/users/user-settings.service';
import { DataAccessService } from '../../../../services/data-access/data-access.service';
import { Router,ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, FormArray, FormControl, Validators } from "@angular/forms";
import swal from 'sweetalert2';
import { NgForm } from "@angular/forms";
import { FormsModule } from '@angular/forms';
@Component({
  selector: 'app-data-catalog-request-details',
  templateUrl: './data-catalog-request-details.component.html',
  styleUrls: ['./data-catalog-request-details.component.scss']
})
export class DataCatalogRequestDetailsComponent implements OnInit {
  dataRequest;
  dataList;
  dataListall;
  authUser;
  currentPage = 1;
  selectAll: boolean;
  allSelected: boolean;
  remarks: String;
  contentListall: any;
  columns = [];
  requestObj = [];
  uuid: string;
  checkStatus: boolean;
  remarksError: boolean;
  showRequestcheck: false;
  p:number=1;
  page=1;
  totalCount;
  projectId;
  constructor(private formBuilder: FormBuilder,private route: ActivatedRoute, private dataRequestService: DataRequestService, private dataAccessService: DataAccessService, private userSettingService: UserSettingsService, private router: Router) {
    this.projectId = this.route.snapshot.paramMap.get("projectId");
   }


  onGoBack() {
    this.router.navigate(["projects/"+this.route.snapshot.paramMap.get("projectId")+"/data-catalog/requests"]);
  }
  ngOnInit() {
   this.getList(this.page);
 
  }
getList(page){
  page=page-1;
  this.remarksError = false;
  this.allSelected = false;
  let requestId=this.route.snapshot.paramMap.get("requestId");
  this.authUser = JSON.parse(sessionStorage.getItem("loggedUser"));
  
  this.dataRequestService.getRequest(requestId).subscribe(data => {
    let res= <any>{};
    res=data;
    this.dataRequest = res.dataAccessRequest;
 

  });

  this.dataRequestService.getRequestByRequestId(requestId,page,10).subscribe(data => {
    this.dataListall = data;
    this.contentListall = this.dataListall.dataRequestDetailList;
 
  });
 
}
}
