import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormArray, Validators } from '@angular/forms';
import { Location } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { CommonServiceService } from '../../../../services/common-services/common-service.service';


@Component({
  selector: 'app-config-streaming',
  templateUrl: './config-streaming.component.html',
  styleUrls: ['./config-streaming.component.scss']
})
export class ConfigStreamingComponent implements OnInit {
  loggedUser;
  projectId: string;
  jsonTableName: string;
  jsonForm: FormGroup;
  sample:number =123;
  jsonArray=[];
  currentTab: number = 1;
  json: any={};
  jsonCheck:boolean = true;
  jsonInput:boolean = true;
  jsonCodeSnippetString: string;
  streamForm: FormGroup;

  constructor(private fb: FormBuilder,private route: ActivatedRoute, private location: Location, public commonServiceService:CommonServiceService) { }

  ngOnInit() {
    this.loggedUser=JSON.parse(sessionStorage.getItem("loggedUser"));
    this.projectId = this.route.snapshot.paramMap.get("projectId");
    this.streamForm = this.fb.group({})
    //this.saveLogs();
  }

  saveLogs(){
    var today = new Date();
    let param={
      username:this.loggedUser.username,
      targetPage:'projects/'+this.projectId+'/config-streaming',
      day:today.getDay(),
      month:today.getMonth(),
      year:today.getFullYear()
    };
    this.commonServiceService.saveLogs(param).subscribe(
      data => {

      });
  }

  formInitialized(name:string, form: FormGroup) {
    this.streamForm.setControl(name, form);
  }
  tabMark(tab: number) {
    this.currentTab = tab
  }
  jsonSetCodeSnippet(event: string) {
    this.jsonCodeSnippetString = event
  }
  jsonSetForm(json: FormGroup) {
    this.jsonForm = json
    this.jsonTableName = this.jsonForm.value.tableName
  }
  jsonSetArray(json: any[]) {
    this.jsonArray = json
  }
  saveStreaming(){
   
  }
  cancel() {
    this.location.back();
  }
}
