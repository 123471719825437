import {
  Component,
  OnInit,
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  EventEmitter,
  Input,
  Output,
  ɵConsole,
  AfterViewChecked,
} from "@angular/core";
import { NgSelectModule } from "@ng-select/ng-select";
import { FormArray, FormBuilder, FormGroup, Validators } from "@angular/forms";
import { CubesService } from "../../../../../services/cubes/cubes.service";
import { ValueTransformer } from "@angular/compiler/src/util";
import { Router, ActivatedRoute } from "@angular/router";
import { CubeConfig } from "../../../../../models/cubes/cube-config";
import swal from "sweetalert2";
import * as $ from "jquery";
import { TableService } from "../../../../../services/table/table.service";
import {
  CdkDragDrop,
  moveItemInArray,
  transferArrayItem,
} from "@angular/cdk/drag-drop";
import { Subscription } from "rxjs";
@Component({
  selector: "app-visual-cube-step5",
  templateUrl: "./visual-cube-step5.component.html",
  styleUrls: ["./visual-cube-step5.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VisualCubeStep5Component
  implements OnInit, AfterViewInit, AfterViewChecked
{
  showModel: boolean;
  public cubeConfig: CubeConfig;
  aggregation: boolean;
  cuboids: boolean;
  advancedDictionaries: boolean;
  dictionaryEditMode: boolean;
  dictionary;
  advanceSnapShotValues = [];
  advanceType;
  advanceGlobal;
  advanceSnapshot;
  dropdownList = [];
  includeItems = [];
  measureDropdown = [];
  form: FormGroup;
  formRowKeys: FormGroup;
  dimensions: any;
  factTable: any;
  factTableName: any;
  advancedSettings: any;
  encoding_advanced: any;
  column;
  builder;
  reuse;
  type;
  errorShard;
  snap;
  disabled = false;
  encodeStatus = [false, true];
  maxDimCombination = 0;
  columMeasure: any;
  snapShotModel: boolean;
  snapShotEditMode: boolean;
  currentFamily = [];
  public hierarchyDimensionsList: FormArray;
  public jointList: FormArray;
  public cuboidList: FormArray;
  measure = [];
  errorShow;
  currentAggregation;
  error: boolean;
  measureModel: boolean;
  columnFamilyEdit: boolean;
  dropdownSettings;
  aggregationEdit: boolean;
  projectId;
  editCube;
  fKey;
  tables;
  cubeStatus;
  dim_cap;
  advanceTypes = ["metaStore", "hbase"];
  engineType: any;
  buildDictionaries = [
    {
      name: "Global Dictionary",
      value: "com.ustglobal.qubz.dict.GlobalDictionaryBuilder",
    },
    {
      name: "Segment Dictionary",
      value: "com.ustglobal.qubz.dict.global.SegmentAppendTrieDictBuilder",
    },
  ];
  @Input() toSave: any;
  @Output() toSaveHandlerEvent = new EventEmitter<any>();
  subscription: Subscription;
  subscription1: Subscription;
  @Input() addDimension: any;
  @Input() tableInfo: any;
  allStatic: boolean;
  lookupTables: any = [];

  constructor(
    public cubesService: CubesService,
    private cdr: ChangeDetectorRef,
    private tableService: TableService,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private router: Router
  ) {
    this.advancedSettings = {
      aggregation: [],
      advancedDictionaries: [],
      advanceSnapshot: [],
      columnFamily: [],
      rowKeys: {
        rowkey_columns: [],
      },
      mandatory_dimension_set_list: [],
      engine_type: 6,//To add default spark on load(Engine type).
    };
    this.form = fb.group({
      includes: [],
      mandatoryDimensions: [],
      hierarchyDimensions: fb.array([]),
      joint: fb.array([]),
      rowKeys: fb.array([]),
    });
    this.formRowKeys = fb.group({
      rowKeys: fb.array([]),
      cuboids: fb.array([]),
    });
    this.projectId = this.route.snapshot.paramMap.get("projectId");
    // if(this.cubesService.cubeMeta.dimensions.length==0)
    // {
    //   this.router.navigate(['/projects/'+this.projectId+'/cubes/designer/info']);
    // }
    if (this.cubesService.cubeMeta.snapshot_table_desc_list)
      this.advancedSettings.advanceSnapshot =
        this.cubesService.cubeMeta.snapshot_table_desc_list;
    this.editCube = this.route.snapshot.paramMap.get("cubeId");
    this.hierarchyDimensionsList = this.form.get(
      "hierarchyDimensions"
    ) as FormArray;
    this.jointList = this.form.get("joint") as FormArray;
    this.cuboidList = this.formRowKeys.get("cuboids") as FormArray;
    this.cubeConfig = new CubeConfig();
    this.encoding_advanced = this.cubeConfig.encoding_advanced;
    this.measure = this.cubesService.measure;

    if (this.cubesService.model && this.cubesService.model.lookups.length > 0)
      this.fKey = this.cubesService.model.lookups[0].join.foreign_key[0];
  }
  drop(event: CdkDragDrop<string[]>) {
    if (event.previousContainer === event.container) {
      moveItemInArray(
        event.container.data,
        event.previousIndex,
        event.currentIndex
      );
    } else {
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex
      );
    }
  }
  ngAfterViewInit() {
    this.cdr.detectChanges();

    $(document).ready(function () {
      var contenth = $(window).height() - 110;
      var sidebarh = $(window).height() - 111;
      $(".pagec").css("height", contenth);
      $(".sidebar-wrapper").css("height", sidebarh);
    });
  }

  step5data() {
    this.advancedSettings.columnFamily = [];
    this.measureDropdown = [];
    this.getTableDetails();
    this.cubeStatus = this.cubesService.cubeStatus;
    this.measure = this.cubesService.measure;
    let family1 = [];
    let family2 = [];
    for (let [key, value] of Object.entries(this.measure)) {
      this.measureDropdown.push(value.name);
      this.cdr.detectChanges();
      if (value.function.expression != "COUNT_DISTINCT")
        family1.push(value.name);
      else family2.push(value.name);
    }
    if (family1) this.advancedSettings.columnFamily.push(family1);
    if (family2.length > 0) this.advancedSettings.columnFamily.push(family2);
    if (this.cubesService.cubeMeta.hbase_mapping.column_family.length) {
    }
    let cubeObj = this.cubesService.getCubeObj();

    this.dimensions = this.cubesService.cubeMeta.dimensions;

    let fact = this.cubesService.model.fact_table;
    for (let key of this.cubesService.model.lookups) {
      if (key.table != fact) {
        if (this.advanceSnapShotValues.indexOf(key.table) == -1) {
          let dims = this.cubesService.cubeMeta.dimensions.find(
            ({ table }) => table === key.table.split(".")[1]
          );
          if (dims) this.advanceSnapShotValues.push(key.table);
        }
      }
    }

    this.factTableName = this.cubesService.model.factTable;
    this.dropdownList = [];

    for (let res of this.dimensions) {
      if (res.table == this.factTableName || res.derived == null) {
        let name;
        if (res.derived) name = res.derived;
        else name = res.column;
        let val = {
          column: res.column,
          derived: res.derived,
          name: res.table + "." + name,
          status: res.status,
          table: res.table,
        };

        this.dropdownList.push(val);
      }
    }
    //code to check whether all the dimensions are static and set the boolean based on which foreign key is not added to agg group and rowkeys
    console.log(this.cubesService.cubeObj);
    console.log(this.cubesService.model.lookups);
    this.lookupTables = [];
    for(let lookup in this.cubesService.model.lookups){
      console.log(this.cubesService.model.lookups);
      this.lookupTables.push(this.cubesService.cubeObj.filter(arr => arr.table === this.cubesService.model.lookups[lookup].alias));
    }
    console.log(this.lookupTables);
    for(let table in this.lookupTables){
      //console.log(this.lookupTables[table]);
      for(let column in this.lookupTables[table]){
        //console.log(this.lookupTables[table][column]);
        if(this.lookupTables[table][column].derived === null){
          this.cubesService.model.lookups[table].allStatic = true;
        }else{
          this.cubesService.model.lookups[table].allStatic = false;
          break;
        }
      }
    }
    console.log(this.cubesService.model.lookups);
    // for(let param in this.cubesService.cubeObj){
    //   if(this.cubesService.cubeObj[param].derived === null)
    //     this.allStatic = true;
    //   else{
    //     this.allStatic = false;
    //     break;
    //   }
    // }
    for (let look of this.cubesService.model.lookups) {
      for (let fkeys in look.join.foreign_key) {
        //QUBZ-3950 - In Visual Cube Creation Aggregation having Duplicate Dimensions - 21/11/22
        //&& fkey!=this.cubesService.model.fact_table_alias
        let fkey=look.join.foreign_key[fkeys].split(".", 2)[0];
        if (
          //QUBZ-3950 - In Visual Cube Creation Aggregation having Duplicate Dimensions - 21/11/22
          this.checkLookUp(look.join.primary_key[fkeys])  &&
          look.kind != "FACT" && !look.allStatic
        ) {
          let x = look.join.foreign_key[fkeys];
          let val = {
            column: x.split(".", 2)[1],
            derived: true,
            name: x,
            status: true,
            table: look.alias,
          };

          this.dropdownList.push(val);
        }
      }
    }
    //code to check whether all the dimensions are static and set the boolean based on which foreign key is not added to agg group and rowkeys
    for(let param in this.cubesService.cubeObj){
      if(this.cubesService.cubeObj[param].derived === null)
        this.allStatic = true;
      else{
        this.allStatic = false;
        break;
      }
    }
    this.dropdownSettings = {
      singleSelection: false,
      idField: "name",
      textField: "name",
      enableCheckAll: false,
      selectAllText: "Select All",
      allowSearchFilter: true,
    };
    if (this.cubesService.cubeMeta.engine_type)
      this.advancedSettings.engine_type =
        this.cubesService.cubeMeta.engine_type;
    if (
      this.cubesService.cubeMeta.rowkey.rowkey_columns.length &&
      this.cubesService.cubeMeta.rowkey.rowkey_columns.length ==
        this.dropdownList.length
    ) {
      this.advancedSettings.rowKeys.rowkey_columns = [];
      for (let param of this.cubesService.cubeMeta.rowkey.rowkey_columns) {
        let encode = param.encoding.split(":", 2);
        let req = {
          column: param.column,
          encoding: encode.length > 1 ? encode[0] : param.encoding,
          isShardBy: param.isShardBy,
          encoding_version: param.encoding_version,
          length: encode.length > 1 ? encode[1] : 0,
        };
        this.advancedSettings.rowKeys.rowkey_columns.push(req);
      }
    } else {
      this.advancedSettings.rowKeys.rowkey_columns = [];
      let cols = [];
      if (
        this.cubesService.model &&
        this.cubesService.model.lookups.length > 0
      ) {
        for (let look of this.cubesService.model.lookups) {
          for (let fkeys in look.join.foreign_key) {
            if (
              this.checkLookUp(look.join.primary_key[fkeys]) &&
              look.kind != "FACT" && !look.allStatic
            ) {
              let x = look.join.foreign_key[fkeys];
              let req = {
                column: x,
                encoding: "dict",
                isShardBy: false,
                encoding_version: 1,
                length: 0,
              };

              if (cols.indexOf(x) == -1 && !look.allStatic) {
                this.advancedSettings.rowKeys.rowkey_columns.push(req);
                cols.push(x);
              }
            }
          }
        }
      }
      for (let param of this.dropdownList) {
        let req = {
          column: param.name,
          encoding: "dict",
          isShardBy: false,
          encoding_version: 1,
          length: 0,
        };
        if (cols.indexOf(param.name) == -1) {
          this.advancedSettings.rowKeys.rowkey_columns.push(req);
        }
      }
      let rowkey_columns = this.cubesService.cubeMeta.rowkey.rowkey_columns;
      for (let rowkeys of this.advancedSettings.rowKeys.rowkey_columns) {
        if (!rowkey_columns.find(({ column }) => rowkeys.column === column)) {
          rowkey_columns.push(rowkeys);
        }
      }
      for (let keys of rowkey_columns) {
        if (!this.dropdownList.find(({ name }) => keys.column === name)) {
          let ind = rowkey_columns.indexOf(keys);
          if (ind != -1) {
            rowkey_columns.splice(ind, 1);
          }
        }
      }
      this.advancedSettings.rowKeys.rowkey_columns = rowkey_columns;
    }
    let agg = [];
    let jointColumn = [];
    if (this.cubesService.model && this.cubesService.model.lookups.length > 0) {
      for (let look of this.cubesService.model.lookups) {
        for (let fkeys in look.join.foreign_key) {
          if (
            this.checkLookUp(look.join.primary_key[fkeys]) &&
            look.kind != "FACT" && !look.allStatic
          ) {
            if (agg.indexOf(look.join.foreign_key[fkeys]) == -1) {
              agg.push(look.join.foreign_key[fkeys]);
            }
          }
        }
      }
    }
    let i = 1;
    for (let res of this.dropdownList) {
      if (agg.indexOf(res.name) == -1) {
        agg.push(res.name);
        let table = res.name.split(".", 2);
        let fact = this.cubesService.model.fact_table.split(".", 2);

        if (i > 11) jointColumn.push(res.name);
      }
      i++;
    }
    var finalJoint = [];
    var jointArray = [];
    if (jointColumn.length > 0) {
      for (let i = 0; i < jointColumn.length; i++) {
        jointArray.push(jointColumn[i]);
        if (jointArray.length == 11) {
          finalJoint.push(jointArray);
          jointArray = [];
        }
      }
      if (jointArray.length > 1) {
        finalJoint.push(jointArray);
        jointArray = [];
      } else {
        jointArray = [];
      }
    }

    //  if(jointColumn.length<2)
    //   jointColumn=[];

    this.advancedSettings.aggregation = [];
    let aggregation = {
      includes: agg,
      select_rule: {
        hierarchy_dims: [],
        mandatory_dims: [],
        joint_dims: finalJoint,
        dim_cap: this.dim_cap,
      },
    };
    if (this.cubesService.cubeMeta.aggregation_groups.length == 0) {
      this.advancedSettings.aggregation.push(aggregation);
      this.cubesService.cubeMeta.aggregation_groups.push(aggregation);
    } else {
      if (
        this.addDimension !== undefined &&
        this.addDimension.length !== this.dimensions.length
      ) {
        this.advancedSettings.aggregation = [];
        this.cubesService.cubeMeta.aggregation_groups = [];
        //this.advancedSettings.aggregation=this.cubesService.cubeMeta.aggregation_groups;
        this.advancedSettings.aggregation.push(aggregation);
        this.cubesService.cubeMeta.aggregation_groups.push(aggregation);
      } else {
        this.advancedSettings.aggregation =
          this.cubesService.cubeMeta.aggregation_groups;
      }

      for (let param of this.advancedSettings.aggregation) {
        for (let include of param.includes) {
          let item = this.advancedSettings.rowKeys.rowkey_columns.find(
            ({ column }) => include === column
          );
          if (!item) {
            let index = param.includes.indexOf(include);
            param.includes.splice(index, 1);
          }
        }
        for (let joint of param.select_rule.joint_dims) {
          if (joint) {
            for (let jointItem of joint) {
              let jnt = this.advancedSettings.rowKeys.rowkey_columns.find(
                ({ column }) => jointItem === column
              );
              if (!jnt) {
                let index = joint.indexOf(jointItem);
                joint.splice(index, 1);
              }
            }
          }
        }
      }

      // this.dim_cap=this.cubesService.cubeMeta.aggregation_groups[0].select_rule.dim_cap;

      // this.advancedSettings.aggregation[0].includes=aggregation.includes;
      // this.cubesService.cubeMeta.aggregation_groups[0].includes=aggregation.includes;
    }

    // this.advancedSettings.rowKeys.push(rowkey_columns);
    // }

    this.addCuboids();

    this.checkShardStatus(this.advancedSettings.rowKeys.rowkey_columns);
    this.cdr.detectChanges();

    // for(let rkeys of this.advancedSettings.rowKeys.rowkey_columns){
    //   includeItems
    // }
    if (this.errorShard || this.error) return;
    this.saveAdvanceSettings();
    this.toSave = "from visualCube";
    this.toSaveHandlerEvent.emit(this.toSave);
    let obj = JSON.stringify(this.cubesService.cubeMeta);
  }
  ngOnInit() {
    this.advancedSettings = {
      aggregation: [],
      advancedDictionaries: [],
      advanceSnapshot: [],
      columnFamily: [],
      rowKeys: {
        rowkey_columns: [],
      },
      mandatory_dimension_set_list: [],
      engine_type: 6,//To add default spark on load(Engine type).
    };

    this.projectId = this.route.snapshot.paramMap.get("projectId");
    // if(this.cubesService.cubeMeta.dimensions.length==0)
    // {
    //   this.router.navigate(['/projects/'+this.projectId+'/cubes/designer/info']);
    // }
    if (this.cubesService.cubeMeta.snapshot_table_desc_list)
      this.advancedSettings.advanceSnapshot =
        this.cubesService.cubeMeta.snapshot_table_desc_list;
    this.editCube = this.route.snapshot.paramMap.get("cubeId");
    this.hierarchyDimensionsList = this.form.get(
      "hierarchyDimensions"
    ) as FormArray;
    this.jointList = this.form.get("joint") as FormArray;
    this.cuboidList = this.formRowKeys.get("cuboids") as FormArray;
    this.cubeConfig = new CubeConfig();
    this.encoding_advanced = this.cubeConfig.encoding_advanced;
    this.measure = this.cubesService.measure;

    if (this.cubesService.model && this.cubesService.model.lookups.length > 0)
      this.fKey = this.cubesService.model.lookups[0].join.foreign_key[0];
    this.getTableDetails();
    this.measureDropdown = [];
    this.cubeStatus = this.cubesService.cubeStatus;
    this.measure = this.cubesService.measure;
    let family1 = [];
    let family2 = [];
    for (let [key, value] of Object.entries(this.measure)) {
      this.measureDropdown.push(value.name);
      if (value.function.expression != "COUNT_DISTINCT")
        family1.push(value.name);
      else family2.push(value.name);
    }
    if (family1) this.advancedSettings.columnFamily.push(family1);
    if (family2.length > 0) this.advancedSettings.columnFamily.push(family2);
    if (this.cubesService.cubeMeta.hbase_mapping.column_family.length) {
    }
    let cubeObj = this.cubesService.getCubeObj();
    this.dimensions = this.cubesService.cubeMeta.dimensions;

    let fact = this.cubesService.model.fact_table;
    for (let key of this.cubesService.model.lookups) {
      if (key.table != fact) {
        if (this.advanceSnapShotValues.indexOf(key.table) == -1) {
          let dims = this.cubesService.cubeMeta.dimensions.find(
            ({ table }) => table === key.table.split(".")[1]
          );
          if (dims) this.advanceSnapShotValues.push(key.table);
        }
      }
    }

    this.factTableName = this.cubesService.model.fact_table.split('.',2)[1];
    this.dropdownList = [];

    for (let res of this.dimensions) {
      if (res.table == this.factTableName || res.derived == null) {
        let name;
        if (res.derived) name = res.derived;
        else name = res.column;
        let val = {
          column: res.column,
          derived: res.derived,
          name: res.table + "." + name,
          status: res.status,
          table: res.table,
        };

        this.dropdownList.push(val);
      }
    }
    for(let lookup in this.cubesService.model.lookups){
      console.log(this.cubesService.model.lookups);
      this.lookupTables.push(this.cubesService.cubeObj.filter(arr => arr.table === this.cubesService.model.lookups[lookup].alias));
    }
    console.log(this.lookupTables);
    for(let table in this.lookupTables){
      //console.log(this.lookupTables[table]);
      for(let column in this.lookupTables[table]){
        //console.log(this.lookupTables[table][column]);
        if(this.lookupTables[table][column].derived === null){
          this.cubesService.model.lookups[table].allStatic = true;
        }else{
          this.cubesService.model.lookups[table].allStatic = false;
          break;
        }
      }
    }
    for (let look of this.cubesService.model.lookups) {
      for (let fkeys in look.join.foreign_key) {
        //QUBZ-3950 - In Visual Cube Creation Aggregation having Duplicate Dimensions - 21/11/22
        //&& fkey!=this.cubesService.model.fact_table_alias
        let fkey=look.join.foreign_key[fkeys].split(".", 2)[0];
        if (
          //QUBZ-3950 - In Visual Cube Creation Aggregation having Duplicate Dimensions - 21/11/22
          this.checkLookUp(look.join.primary_key[fkeys])  &&
          look.kind != "FACT" && !look.allStatic
        ) {
          console.log('1');
          
          let x = look.join.foreign_key[fkeys];
          let val = {
            column: x.split(".", 2)[1],
            derived: true,
            name: x,
            status: true,
            table: look.alias,
          };

          this.dropdownList.push(val);
        }
      }
    }
    this.dropdownSettings = {
      singleSelection: false,
      idField: "name",
      textField: "name",
      enableCheckAll: false,
      selectAllText: "Select All",
      allowSearchFilter: true,
    };
    if (this.cubesService.cubeMeta.engine_type)
      this.advancedSettings.engine_type =
        this.cubesService.cubeMeta.engine_type;
    if (
      this.cubesService.cubeMeta.rowkey.rowkey_columns.length &&
      this.cubesService.cubeMeta.rowkey.rowkey_columns.length ==
        this.dropdownList.length
    ) {
      this.advancedSettings.rowKeys.rowkey_columns = [];
      for (let param of this.cubesService.cubeMeta.rowkey.rowkey_columns) {
        let encode = param.encoding.split(":", 2);
        let req = {
          column: param.column,
          encoding: encode.length > 1 ? encode[0] : param.encoding,
          isShardBy: param.isShardBy,
          encoding_version: param.encoding_version,
          length: encode.length > 1 ? encode[1] : 0,
        };
        this.advancedSettings.rowKeys.rowkey_columns.push(req);
      }
    } else {
      this.advancedSettings.rowKeys.rowkey_columns = [];
      let cols = [];
      if (
        this.cubesService.model &&
        this.cubesService.model.lookups.length > 0
      ) {
        for (let look of this.cubesService.model.lookups) {
          for (let fkeys in look.join.foreign_key) {
            if (
              this.checkLookUp(look.join.primary_key[fkeys]) &&
              look.kind != "FACT" && !look.allStatic
            ) {
              let x = look.join.foreign_key[fkeys];
              let req = {
                column: x,
                encoding: "dict",
                isShardBy: false,
                encoding_version: 1,
                length: 0,
              };

              if (cols.indexOf(x) == -1 && !look.allStatic) {
                this.advancedSettings.rowKeys.rowkey_columns.push(req);
                cols.push(x);
              }
            }
          }
        }
      }
      for (let param of this.dropdownList) {
        let req = {
          column: param.name,
          encoding: "dict",
          isShardBy: false,
          encoding_version: 1,
          length: 0,
        };
        if (cols.indexOf(param.name) == -1) {
          this.advancedSettings.rowKeys.rowkey_columns.push(req);
        }
      }
      let rowkey_columns = this.cubesService.cubeMeta.rowkey.rowkey_columns;
      for (let rowkeys of this.advancedSettings.rowKeys.rowkey_columns) {
        if (!rowkey_columns.find(({ column }) => rowkeys.column === column)) {
          rowkey_columns.push(rowkeys);
        }
      }
      for (let keys of rowkey_columns) {
        if(this.dropdownList.length===0){
          this.advancedSettings.rowKeys.rowkey_columns = rowkey_columns;
        }else{
          if (!this.dropdownList.find(({ name }) => keys.column === name)) {
            let ind = rowkey_columns.indexOf(keys);
            if (ind != -1) {
              rowkey_columns.splice(ind, 1);
            }
          }
        }
        this.advancedSettings.rowKeys.rowkey_columns = rowkey_columns;
      }
    }
    let agg = [];
    let jointColumn = [];
    //code to check whether all the dimensions are static and set the boolean based on which foreign key is not added to agg group and rowkeys
    
    if (this.cubesService.model && this.cubesService.model.lookups.length > 0) {
      for (let look of this.cubesService.model.lookups) {
        for (let fkeys in look.join.foreign_key) {
          if (
            this.checkLookUp(look.join.primary_key[fkeys]) &&
            look.kind != "FACT" && !look.allStatic
          ) {
            if (agg.indexOf(look.join.foreign_key[fkeys]) == -1) {
              agg.push(look.join.foreign_key[fkeys]);
            }
          }
        }
      }
    }
    let i = 1;
    for (let res of this.dropdownList) {
      if (agg.indexOf(res.name) == -1) {
        agg.push(res.name);
        let table = res.name.split(".", 2);
        let fact = this.cubesService.model.fact_table.split(".", 2);

        if (i > 11) jointColumn.push(res.name);
      }
      i++;
    }
    var finalJoint = [];
    var jointArray = [];
    if (jointColumn.length > 0) {
      for (let i = 0; i < jointColumn.length; i++) {
        jointArray.push(jointColumn[i]);
        if (jointArray.length == 11) {
          finalJoint.push(jointArray);
          jointArray = [];
        }
      }
      if (jointArray.length > 1) {
        finalJoint.push(jointArray);
        jointArray = [];
      } else {
        jointArray = [];
      }
    }

    //  if(jointColumn.length<2)
    //   jointColumn=[];

    this.advancedSettings.aggregation = [];
    let aggregation = {
      includes: agg,
      select_rule: {
        hierarchy_dims: [],
        mandatory_dims: [],
        joint_dims: finalJoint,
        dim_cap: this.dim_cap,
      },
    };
    if (this.cubesService.cubeMeta.aggregation_groups.length == 0) {
      this.advancedSettings.aggregation.push(aggregation);
      this.cubesService.cubeMeta.aggregation_groups.push(aggregation);
    } else {
      this.advancedSettings.aggregation =
        this.cubesService.cubeMeta.aggregation_groups;

      for (let param of this.advancedSettings.aggregation) {
        for (let include of param.includes) {
          let item = this.advancedSettings.rowKeys.rowkey_columns.find(
            ({ column }) => include === column
          );
          if (!item) {
            let index = param.includes.indexOf(include);
            param.includes.splice(index, 1);
          }
        }
        for (let joint of param.select_rule.joint_dims) {
          if (joint) {
            for (let jointItem of joint) {
              let jnt = this.advancedSettings.rowKeys.rowkey_columns.find(
                ({ column }) => jointItem === column
              );
              if (!jnt) {
                let index = joint.indexOf(jointItem);
                joint.splice(index, 1);
              }
            }
          }
        }
      }

      // this.dim_cap=this.cubesService.cubeMeta.aggregation_groups[0].select_rule.dim_cap;

      // this.advancedSettings.aggregation[0].includes=aggregation.includes;
      // this.cubesService.cubeMeta.aggregation_groups[0].includes=aggregation.includes;
    }

    // this.advancedSettings.rowKeys.push(rowkey_columns);
    // }

    this.addCuboids();

    this.checkShardStatus(this.advancedSettings.rowKeys.rowkey_columns);
    this.cdr.detectChanges();

    // for(let rkeys of this.advancedSettings.rowKeys.rowkey_columns){
    //   includeItems
    // }
    if (this.errorShard || this.error) return;
    this.saveAdvanceSettings();
    this.toSave = "from visualCube";
    this.toSaveHandlerEvent.emit(this.toSave);
    let obj = JSON.stringify(this.cubesService.cubeMeta);
    this.subscription = this.cubesService.measureUpdated.subscribe(
      (measureUpdated) => this.step5data()
    );

    // this.subscription1 = this.cubesService.dimensionUpdated.subscribe(
    //   (dimensionUpdated) => this.step5data()
    // );
  }

  checkLookUp(key) {
    let primary = key.split(".", 2);
    for (let param of this.dimensions) {
      if (param.table == primary[0]) return true;
    }
    return false;
  }
  openModel(type) {
    this.showModel = true;
    if (type == "aggregation") {
      this.aggregationEdit = false;
      this.aggregation = true;
      this.form.reset();
      for (let i = 0; i < this.jointList.length; i++)
        this.jointList.removeAt(i);
      for (let i = 0; i < this.hierarchyDimensionsList.length; i++)
        this.hierarchyDimensionsList.removeAt(i);
    }
    if (type == "cuboids") {
      this.cuboids = true;
    }
  }
  closeModel() {
    this.form.reset();
    this.showModel = false;
    this.advancedDictionaries = false;
    this.snapShotModel = false;
    this.measureModel = false;
  }
  get hierarchyFormGroup() {
    return this.form.get("hierarchyDimensions") as FormArray;
  }
  get cuboidFormGroup() {
    return this.formRowKeys.get("cuboids") as FormArray;
  }

  get jointFormGroup() {
    return this.form.get("joint") as FormArray;
  }
  createHierarchy(): FormGroup {
    return this.fb.group({
      hierarchyColumn: [],
    });
  }
  createJoint(): FormGroup {
    return this.fb.group({
      jointColumn: [],
    });
  }
  createCuboids(): FormGroup {
    return this.fb.group({
      cuboid: [],
    });
  }
  addHierarchy() {
    this.hierarchyDimensionsList.push(this.createHierarchy());
    document.getElementById("hierarchylabel").style.display = "block";
  }
  addJoint() {
    this.jointList.push(this.createJoint());
    document.getElementById("jointdlabel").style.display = "block";
  }
  addCuboids() {
    this.cuboidList.push(this.createCuboids());
  }
  removeHierarchy(index) {
    this.hierarchyDimensionsList.removeAt(index);
  }
  removeJoint(index) {
    this.jointList.removeAt(index);
  }
  deleteAggregation(aggregation) {
    let aggregationGroup = {
      includes: aggregation.includes,
      select_rule: {
        hierarchy_dims: aggregation.select_rule.mandatoryDimensions,
        mandatory_dims: aggregation.select_rule.hierarchyDimensions,
        joint_dims: aggregation.select_rule.joint,
        dim_cap: this.dim_cap,
      },
    };

    let index = this.advancedSettings.aggregation.indexOf(aggregation);

    if (index != -1) {
      this.advancedSettings.aggregation.splice(index, 1);
      this.cubesService.cubeMeta.aggregation_groups =
        this.advancedSettings.aggregation;
    }
  }
  dimCapChange() {
    for (let param of this.cubesService.cubeMeta.aggregation_groups) {
      if (this.dim_cap > 0) {
        param.select_rule.dim_cap = this.dim_cap;
      }
    }
    this.saveAdvanceSettingsPage();
  }
  saveAggregation() {
    if (this.aggregationEdit) {
      let joint = [];
      if (this.form.value.joint.length > 0) {
        for (let param of this.form.value.joint) {
          let temp = [];
          for (let item of param.jointColumn) {
            if (item.name) temp.push(item.name);
            else temp.push(item);
          }
          joint.push(temp);
        }
      }
      let hierarchy = [];
      if (this.form.value.hierarchyDimensions.length > 0) {
        for (let param of this.form.value.hierarchyDimensions) {
          let temp = [];
          for (let item of param.hierarchyColumn) {
            if (item.name) temp.push(item.name);
            else temp.push(item);
          }
          hierarchy.push(temp);
        }
      }
      var includes = [];
      if (this.form.value.includes.length > 0) {
        for (let include of this.form.value.includes) {
          if (include.name) includes.push(include.name);
          else includes.push(include);
        }
      }
      var mandatory = [];
      if (this.form.value.mandatoryDimensions) {
        for (let mand of this.form.value.mandatoryDimensions) {
          if (mand.name) mandatory.push(mand.name);
          else mandatory.push(mand);
        }
      }
      let aggregationGroup = {
        includes: this.currentAggregation.includes,
        select_rule: {
          hierarchy_dims: this.currentAggregation.hierarchyDimensions,
          mandatory_dims: this.currentAggregation.mandatoryDimensions,
          joint_dims: this.currentAggregation.joint,
          dim_cap: this.dim_cap,
        },
      };
      let index =
        this.cubesService.cubeMeta.aggregation_groups.indexOf(aggregationGroup);
      if (index != -1) {
        this.cubesService.cubeMeta.aggregation_groups.splice(index, 1, {
          includes: includes,
          select_rule: {
            hierarchy_dims: hierarchy,
            mandatory_dims: mandatory,
            joint_dims: joint,
            dim_cap: this.dim_cap,
          },
        });
      }
      let index1 = this.advancedSettings.aggregation.indexOf(
        this.currentAggregation
      );
      this.advancedSettings.aggregation.splice(index1, 1, {
        includes: includes,
        select_rule: {
          hierarchy_dims: hierarchy,
          mandatory_dims: mandatory,
          joint_dims: joint,
          dim_cap: this.dim_cap,
        },
      });
      this.currentAggregation = [];

      this.cubesService.cubeMeta.aggregation_groups =
        this.advancedSettings.aggregation;
      this.aggregationEdit = false;
      this.closeModel();
    } else {
      let joint = [];
      if (this.form.value.joint.length > 0) {
        for (let param of this.form.value.joint) {
          let temp = [];
          for (let item of param.jointColumn) {
            if (item.name) temp.push(item.name);
            else temp.push(item);
          }
          joint.push(temp);
        }
      }
      let hierarchy = [];
      if (this.form.value.hierarchyDimensions.length > 0) {
        for (let param of this.form.value.hierarchyDimensions) {
          let temp = [];
          for (let item of param.hierarchyColumn) {
            if (item.name) temp.push(item.name);
            else temp.push(item);
          }
          hierarchy.push(temp);
        }
      }
      var includes = [];
      if (this.form.value.includes.length > 0) {
        for (let include of this.form.value.includes) {
          if (include.name) includes.push(include.name);
          else includes.push(include);
        }
      }
      var mandatory = [];
      if (this.form.value.mandatoryDimensions) {
        for (let mand of this.form.value.mandatoryDimensions) {
          if (mand.name) mandatory.push(mand.name);
          else mandatory.push(mand);
        }
      }

      let aggregation = {
        includes: includes,
        select_rule: {
          hierarchy_dims: hierarchy,
          mandatory_dims: mandatory,
          joint_dims: joint,
          dim_cap: this.dim_cap,
        },
      };

      this.cubesService.cubeMeta.aggregation_groups.push(aggregation);
      this.advancedSettings.aggregation =
        this.cubesService.cubeMeta.aggregation_groups;

      this.closeModel();
    }

    this.showModel = false;
    this.saveAdvanceSettingsPage();
  }
  editAggregation(aggregation) {
    this.form.reset();
    for (let i = 0; i < this.jointList.length; i++) this.jointList.removeAt(i);
    for (let i = 0; i < this.hierarchyDimensionsList.length; i++)
      this.hierarchyDimensionsList.removeAt(i);
    this.openModel("aggregation");
    this.aggregationEdit = true;
    this.currentAggregation = aggregation;
    // this.addHierarchy();
    let joint = [];
    for (let param of aggregation.select_rule.joint_dims) {
      joint.push({ jointColumn: param });
      this.addJoint();
    }
    let hierarchy = [];
    for (let param of aggregation.select_rule.hierarchy_dims) {
      hierarchy.push({ hierarchyColumn: param });
      this.addHierarchy();
    }
    for (let inc of aggregation.includes) {
      if (!inc.name) {
        inc = { name: inc, isDisabled: false };
      }
    }
    this.form.patchValue({
      includes: aggregation.includes,
      mandatoryDimensions: aggregation.select_rule.mandatory_dims,
      hierarchyDimensions: hierarchy,
      joint: joint,
    });
  }
  openAdvancedDictionaries() {
    this.type = "";
    this.builder = "";
    this.reuse = "";
    this.column = "";
    this.dictionaryEditMode = false;
    this.advancedDictionaries = true;
  }
  deleteDictionary(param) {
    let index = this.advancedSettings.advancedDictionaries.indexOf(param);
    this.advancedSettings.advancedDictionaries.splice(index, 1);
  }
  editDictionary(param) {
    this.dictionaryEditMode = true;
    this.advancedDictionaries = true;
    this.dictionary = param;
    this.column = param.column;
    let index = this.advancedSettings.advancedDictionaries.indexOf(param);

    if (param.builder) {
      this.builder = param.builder;
      this.type = "builder";
    }
    if (param.reuse) {
      this.reuse = param.reuse;
      this.type = "reuse";
    }
    this.advancedDictionaries = true;
  }
  saveDictionary() {
    if (!this.column && !this.reuse && !this.builder) return;
    if (this.dictionaryEditMode) {
      let index = this.advancedSettings.advancedDictionaries.indexOf(
        this.dictionary
      );

      this.advancedSettings.advancedDictionaries.splice(index, 1, {
        column: this.column,
        builder: this.type == "builder" ? this.builder : "",
        reuse: this.type == "reuse" ? this.reuse : "",
      });

      this.advancedDictionaries = false;
      this.dictionary = "";
    } else {
      this.advancedSettings.advancedDictionaries.push({
        column: this.column,
        builder: this.type == "builder" ? this.builder : "",
        reuse: this.type == "reuse" ? this.reuse : "",
      });
      this.advancedDictionaries = false;
    }
    this.saveAdvanceSettingsPage();
  }
  openSnapShot() {
    this.snapShotModel = true;
    this.advanceSnapshot = "";
    this.advanceType = "";
    this.advanceGlobal = false;
    this.snapShotEditMode = false;
  }
  saveSnapShaot() {
    if (!this.advanceSnapshot || !this.advanceType) return;
    if (this.snapShotEditMode) {
      let index = this.advancedSettings.advanceSnapshot.indexOf(this.snap);
      this.advancedSettings.advanceSnapshot.splice(index, 1, {
        table_name: this.advanceSnapshot,
        storage_type: this.advanceType,
        global: this.advanceType == "hbase" ? true : this.advanceGlobal,
      });

      this.snapShotModel = false;
      this.snap = "";
    } else {
      if (
        this.advancedSettings.advanceSnapshot.find(
          ({ table_name }) => this.advanceSnapshot === table_name
        )
      ) {
        swal("Ooops!", "Snapshot table already added", "error");
        return;
      }
      this.advancedSettings.advanceSnapshot.push({
        table_name: this.advanceSnapshot,
        storage_type: this.advanceType,
        global: this.advanceType == "hbase" ? true : this.advanceGlobal,
      });
      this.snapShotModel = false;
    }
    this.cubesService.cubeMeta.snapshot_table_desc_list =
      this.advancedSettings.advanceSnapshot;
    this.saveAdvanceSettingsPage();
  }
  getReUseItems(dimensions) {
    let res = [];
    for (let dims of dimensions) {
      res.push({
        name: dims.table + "." + dims.name,
        value: dims.table + "." + dims.name,
      });
    }
    return res;
  }

  deleteSnapShot(param) {
    let index = this.advancedSettings.advanceSnapshot.indexOf(param);
    this.advancedSettings.advanceSnapshot.splice(index, 1);
    this.cubesService.cubeMeta.snapshot_table_desc_list =
      this.advancedSettings.advanceSnapshot;
  }
  editSnapShot(param) {
    this.snapShotModel = true;
    this.advanceSnapshot = param.table_name;
    this.advanceType = param.storage_type;
    this.advanceGlobal = param.global;
    this.snapShotEditMode = true;
    this.snap = param;
  }
  openMeasureModel() {
    this.measureModel = true;
    this.columMeasure = "";
    this.columnFamilyEdit = false;
    this.currentFamily = [];
    this.measureDropdown = [];

    for (let [key, value] of Object.entries(this.measure)) {
      let index;
      for (let param of this.advancedSettings.columnFamily) {
        index = param.indexOf(value.name);
        if (index != -1) break;
      }
      if (index == -1)
        this.measureDropdown.push({ id: value.name, name: value.name });
    }
  }
  deleteColumnFamily(param) {
    swal({
      type: "warning",
      title: "Are you sure you want to remove?",
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "No",
    }).then(
      (status) => {
        if (status.value == true) {
          let index = this.advancedSettings.columnFamily.indexOf(param);

          this.advancedSettings.columnFamily.splice(index, 1);
        }
      },
      (dismiss) => {
        if (dismiss === "cancel") {
        }
      }
    );
  }
  editColumnFamily(param) {
    this.openMeasureModel();
    this.columMeasure = param;
    for (let res of param) {
      this.measureDropdown.push(res);
    }
    this.currentFamily = param;
    this.columnFamilyEdit = true;
  }
  saveColumnFamily() {
    if (!this.columMeasure) return;
    let tempcolumMeasure = [];
    for (let measure of this.columMeasure) {
      if (measure.name) tempcolumMeasure.push(measure.name);
      else tempcolumMeasure.push(measure);
    }
    this.columMeasure = tempcolumMeasure;
    if (this.columnFamilyEdit == true) {
      let index = this.advancedSettings.columnFamily.indexOf(
        this.currentFamily
      );
      this.advancedSettings.columnFamily.splice(index, 1, this.columMeasure);
    } else {
      this.advancedSettings.columnFamily.push(this.columMeasure);
    }

    this.measureModel = false;
    this.saveAdvanceSettingsPage();
  }
  addLength(param) {
    if (
      param.encoding == "fixed_length" ||
      param.encoding == "fixed_length_hex"
    ) {
      param.errorShow = false;
      this.error = this.checkErrorStatus();
      return;
    } else if (param.encoding != "integer") {
      param.length = 0;
      param.errorShow = false;
      this.error = this.checkErrorStatus();
      return;
    } else if (param.length <= 0 || param.length >= 9) {
      param.length = null;
      param.errorShow = true;
      this.error = true;
    } else {
      param.errorShow = false;
      this.error = this.checkErrorStatus();
    }
    this.saveAdvanceSettingsPage();
  }
  checkErrorStatus() {
    for (let param of this.advancedSettings.rowKeys.rowkey_columns) {
      if (
        param.encoding == "integer" &&
        (param.length <= 0 || param.length >= 9)
      )
        return true;
    }
    return false;
  }
  onFileChange(event) {
    let reader = new FileReader();
    if (event.target.files && event.target.files.length > 0) {
      let file = event.target.files[0];
      reader.readAsDataURL(file);
      reader.onload = () => {
        this.advancedSettings.mandatory_dimension_set_list = event.result;
      };
    }
  }
  saveAdvanceSettings() {
    let rowkey_columns = [];

    for (let param of this.advancedSettings.rowKeys.rowkey_columns) {
      let encoding;
      if (param.encoding == "integer")
        encoding = param.encoding + ":" + param.length;
      else encoding = param.encoding;

      rowkey_columns.push({
        column: param.column,
        encoding: encoding,
        isShardBy: param.isShardBy,
        encoding_version: 1,
      });
    }
    this.advancedSettings.rowKeys.rowkey_columns = rowkey_columns;
    this.cubesService.cubeMeta.rowkey.rowkey_columns = rowkey_columns;

    this.cubesService.cubeMeta.engine_type = this.advancedSettings.engine_type;
    let i = 1;
    let columnFamily;
    this.cubesService.cubeMeta.hbase_mapping.column_family = [];
    for (let param of this.advancedSettings.columnFamily) {
      this.cubesService.cubeMeta.hbase_mapping.column_family.push({
        name: "F" + i++,
        columns: [
          {
            qualifier: "M",
            measure_refs: param,
          },
        ],
      });
    }
  }
  trimming_fn(x) {
    return x ? x.replace(/^\s+|\s+$/gm, "") : "";
  }
  saveAdvanceSettingsPage() {
    if (this.errorShard || this.error) return;
    this.saveAdvanceSettings();
    this.toSave = "from visualCube";
    this.toSaveHandlerEvent.emit(this.toSave);
    let obj = JSON.stringify(this.cubesService.cubeMeta);
  }
  checkValidity(formValues) {
    for (let joint of formValues.joint) {
      if (!joint.jointColumn || joint.jointColumn.length < 2) return true;
    }
    for (let joint of formValues.hierarchyDimensions) {
      if (!joint.hierarchyColumn || joint.hierarchyColumn.length < 1)
        return true;
    }
    return false;
  }
  checkAggItems(formValues) {
    if (formValues.joint) {
      for (let joint of formValues.joint) {
        for (let item of joint.jointColumn) {
          if (item.name) {
            if (
              formValues.includes.find(({ name }) => item.name === name) == null
            ) {
              let index = joint.jointColumn.indexOf(item);
              joint.jointColumn.splice(index, 1);
            }
          } else {
            if (formValues.includes.find(({ name }) => item === name) == null) {
              let index = joint.jointColumn.indexOf(item);
              joint.jointColumn.splice(index, 1);
            }
          }
        }
      }
    }
    if (formValues.mandatoryDimensions) {
      for (let high of formValues.mandatoryDimensions) {
        if (high.name) {
          if (
            formValues.includes.find(({ name }) => high.name === name) == null
          ) {
            let index = formValues.mandatoryDimensions.indexOf(high);
            formValues.mandatoryDimensions.splice(index, 1);
          }
        } else {
          if (formValues.includes.find(({ name }) => high === name) == null) {
            let index = formValues.mandatoryDimensions.indexOf(high);
            formValues.mandatoryDimensions.splice(index, 1);
          }
        }
      }
    }
    if (formValues.hierarchyDimensions) {
      for (let high of formValues.hierarchyDimensions) {
        for (let item of high.hierarchyColumn) {
          if (item.name) {
            if (
              formValues.includes.find(({ name }) => item.name === name) == null
            ) {
              let index = high.hierarchyColumn.indexOf(item);
              high.hierarchyColumn.splice(index, 1);
            }
          } else {
            if (formValues.includes.find(({ name }) => item === name) == null) {
              let index = high.hierarchyColumn.indexOf(item);
              high.hierarchyColumn.splice(index, 1);
            }
          }
        }
      }
    }
    this.form.patchValue({
      // includes:aggregation.includes,
      mandatoryDimensions: formValues.mandatoryDimensions,
      hierarchyDimensions: formValues.hierarchyDimensions,
      joint: formValues.joint,
    });
  }
  buttonPrevious() {
    // if(this.errorShard || this.error)
    // return;
    // let cubeObj=this.cubesService.getCubeObj();

    this.saveAdvanceSettings();

    if (this.cubesService.editMode)
      this.router.navigate([
        "/projects/" +
          this.projectId +
          "/cubes/" +
          this.editCube +
          "/designer/refresh-settings",
      ]);
    else
      this.router.navigate([
        "/projects/" + this.projectId + "/cubes/designer/refresh-settings",
      ]);
  }
  checkShardStatus(columns) {
    let count = 0;

    for (let param of columns) {
      if (param.isShardBy == true) count++;
      if (count > 1) {
        this.errorShard = true;
        return;
      }
    }
    this.errorShard = false;
    this.saveAdvanceSettingsPage();
  }
  AfterViewInit() {
    for (let column of this.advancedSettings.rowKeys.rowkey_columns) {
      let encode = "";
      if (column.encoding) encode = column.encoding.split(":", 2);
      if (encode.length > 1) {
        column.encoding = encode[0];
        column.length = encode[1];
      }
    }
  }
  getEncodings(column) {
    this.checkEncode(column);
    if (this.tables) {
      for (let value of this.tables) {
        for (let cols of value.columns) {
          if (value.name + "." + cols.name == column.column) {
            for (let [key, value] of Object.entries(
              this.cubeConfig.columnTypeEncodingMap
            )) {
              if (cols.datatype.startsWith(key)) {
                return value;
              }
            }
          }
        }
      }
    }
    return [];
  }
  checkEncode(param) {
    if (
      param.encoding == "integer" ||
      param.encoding == "fixed_length" ||
      param.encoding == "fixed_length_hex"
    ) {
      param.disable = true;
    } else param.disable = false;
  }
  getTableDetails() {
    this.tableService.getTables(this.projectId).subscribe((data) => {
      let res = <any>{};
      res = data;
      this.tables = res;
    });
  }
  ngAfterViewChecked() {
    this.getEngineType(this.tableInfo);
  }

  getEngineType(tableInfo) {
    if (tableInfo && tableInfo.source_type == 20) {
      this.engineType = [{ id: 2, name: "MapReduce" }];
      this.advancedSettings.engine_type = 2;
    } else {
      this.engineType = [
        { id: 6, name: "Spark" },
        //{ id: 2, name: "MapReduce" },
        //{ id: 5, name: "Flink" },
      ];
    }
  }
}
