export class Snapshot {
    
    snapshotId: string
    snapshotStorageType: string;
    snapshotLastBuild: string;
    snapshotTableLastModifyTime: string;
    snapshotTableSize: string;
    snapshotCubesAndSegmentUsage: string;

    constructor(snapshotResponse: any) {
        this.snapshotId = snapshotResponse.snapshotID;
        this.snapshotStorageType = snapshotResponse.storageType;
        this.snapshotLastBuild = snapshotResponse.lastBuildTime;
        this.snapshotTableLastModifyTime = snapshotResponse.sourceTableLastModifyTime;
        this.snapshotTableSize = snapshotResponse.sourceTableSize;
        this.snapshotCubesAndSegmentUsage = snapshotResponse.cubesAndSegmentsUsage;
    }
}
