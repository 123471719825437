import { Component, OnInit } from '@angular/core';
import { DataCatalogTablesService } from '../../../services/data-catalog/data-catalog-tables.service'
import { DataCatalogService } from '../../../services/data-catalog/data-catalog.service';
import swal from 'sweetalert2';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { Columns } from 'src/app/models/table/columns/columns.model';
import { OrderPipe } from 'ngx-order-pipe';
@Component({
  selector: 'app-catalog-user-tables',
  templateUrl: './catalog-user-tables.component.html',
  styleUrls: ['./catalog-user-tables.component.scss']
})
export class CatalogUserTablesComponent implements OnInit {
  tables:any;
  catalog:any;
  page = 1;
  page1=1;
  pageNumber:number;
  dataCatalogDetails:any;
  editMode:boolean;
  dataCatalog:any;
  catalogEdit:boolean;
  catalogId;
  tableDetails;
  columns;
  cartCount:number;
  cart=[];
  totalCount;
  order: string = 'name';
  reverse: boolean = false;
  sortedCollection;
  load;
  constructor( private dataCatalogService:DataCatalogService,private dataCatalogTablesService:DataCatalogTablesService,private router:Router,private route: ActivatedRoute,private orderPipe: OrderPipe) { 
    this.getTables(this.page);
    this.sortedCollection = orderPipe.transform(this.columns, 'name');
    this.sortedCollection = orderPipe.transform(this.columns, 'displayName');
    this.sortedCollection = orderPipe.transform(this.columns, 'dataType');
    this.sortedCollection = orderPipe.transform(this.columns, 'description');
     
  }
  setOrder(value: string) {
    if (this.order === value) {
      this.reverse = !this.reverse;
    }

    this.order = value;
  }
  ngOnInit() {
   
    this.cartCount=this.dataCatalogService.cart.length;
    this.cart=this.dataCatalogService.cart;
  }

getTables(pageNumber){
  this.load = true;
  pageNumber=pageNumber-1;
  this.catalogId=this.route.snapshot.paramMap.get("id");
  let catalogId=this.route.snapshot.paramMap.get("id");
  this.editMode=false;
  // this.dataCatalogService.list(catalogId).subscribe(
    this.dataCatalogService.list(catalogId).subscribe(
    data=>{
      let res= <any>{};
      res=data;
       this.dataCatalogDetails=res.dataCatalog;
       this.load = false;
      //  let res= <any>{};
      // res=data;
     // this.dataCatalogDetails=res.content;
    
    });
  this.dataCatalogTablesService.list_Userpage(catalogId,pageNumber,10).subscribe(
    data=>{
    
      let res= <any>{};
      res=data;
      this.catalog=res.page;
      this.tables=res.page.content;
     
    });
}
restNumber(){
  this.page=1;
}
getTableDetails(catalogId,tableId){

  this.dataCatalogTablesService.getTableDetails(catalogId,tableId).subscribe(
    data=>{
      let res= <any>{};
      res=data;
      this.tableDetails=res.dataCatalogTable;
     
  });
  let pageNo=this.page-1;
  this.dataCatalogTablesService.listColumns_Userpage(catalogId,tableId,pageNo,10).subscribe(
    data=>{
      let res= <any>{};
      res=data;
      let request;
      this.columns=res.page.content;
      this.totalCount=res.page.totalElements;
      for(let column of this.columns){
        if(!this.containsObject(column.uuid)){
          column.addstatus=true;
        }
        else
          column.addstatus=false;
      }
    });
    
}

containsObject(id) {
  var i;
  for (i = 0; i < this.cart.length; i++) {
      if (this.cart[i].columnId === id) {
          return true;
      }
  }

  return false;
}
addColumn(catalogId,table,column){
  let request;
  request = {
    "columnId": column.uuid,
    "columnName": column.name,
    "columnDescription": column.description,
    "databaseId": catalogId,
    "databaseName": this.dataCatalogDetails.name,
    "tableId": table.uuid,
    "tableName": table.name
};
 
  if(!this.containsObject(column.uuid)){
    this.cart.push(request);
    this.cartCount++;
    column.addstatus=false;
  }
  this.dataCatalogService.cartCount=this.cartCount;
  this.dataCatalogService.cart=this.cart;
}
removeColumn(column){
  for (let i = 0; i < this.cart.length; i++) {
    if (this.cart[i].columnId === column.uuid) {
      this.cart.splice(i,1);
      column.addstatus=true;
      this.cartCount--;
      return;
    }
}
this.dataCatalogService.cartCount=this.cartCount;
this.dataCatalogService.cart=this.cart;
return;
}
addAllColumns(catalogId,table){
 // this.getTableDetails(catalogId,table.uuid);

  let request;
  swal({
    type:'warning',
    title: 'Do you want to add all columns from this '+table.name+' table?',
    showCancelButton: true,
    confirmButtonText: 'Yes',
    cancelButtonText: 'No'
  }).then((status) => {
    if(status.value==true){
      let columns;
     
     
      this.dataCatalogTablesService.listAllColumns_userpage(catalogId,table.uuid).subscribe(
        data=>{
          let res= <any>{};
          res=data;
         
         columns=res.dataCatalogColumnList;
         for(let column of columns){
        
          request = {
           "columnId": column.uuid,
           "columnName": column.name,
           "columnDescription": column.description,
           "databaseId": catalogId,
          "databaseName": this.dataCatalogDetails.name,
           "tableId": table.uuid,
           "tableName": table.name
       };
        
         if(!this.containsObject(request.columnId)){
           this.cart.push(request);
           this.cartCount++;
           column.addstatus=false;
         }
       }
       this.getTableDetails(catalogId,table.uuid)
        });
        
     
       this.dataCatalogService.cartCount=this.cartCount;
       this.dataCatalogService.cart=this.cart;
       this.getTableDetails(catalogId,table.uuid);
      }
  }, (dismiss) => {
    if (dismiss === 'cancel') {
    }
  });
}
// removeAllColumns(catalogId,table){
//   // this.getTableDetails(catalogId,table.uuid);
 
//    let request;
//    swal({
//      type:'warning',
//      title: 'Do you want to remove all columns of '+table.name+' from cart?',
//      showCancelButton: true,
//      confirmButtonText: 'Yes!',
//      cancelButtonText: 'No'
//    }).then((status) => {
//      if(status.value==true){
//        let columns;
//        table.addStatus=false;
//        this.dataCatalogTablesService.listAllColumns(catalogId,table.uuid).subscribe(
//          data=>{
//            let res= <any>{};
//            res=data;
//      
//           columns=res.dataCatalogColumnList;
//           for(let column of columns){
         
//            request = {
//             "columnId": column.uuid,
//             "columnName": column.name,
//             "columnDescription": column.description,
//             "databaseId": catalogId,
//           //  "databaseName": this.dataCatalogDetails.name,
//           "databaseName": column.name,
//             "tableId": table.uuid,
//             "tableName": table.name
//         };
         
//           if(this.containsObject(request.columnId)){
//             this.cart.splice(request, 1);
//             this.cartCount=this.cart.length;
//             column.addstatus=true;
//           }
//         }
//      
//         this.dataCatalogService.cartCount=this.cartCount;
//         this.dataCatalogService.cart=this.cart;
//         this.getTableDetails(catalogId,table.uuid)
//          });       
//        }
//    }, (dismiss) => {
//      if (dismiss === 'cancel') {
//      }
//    });
//  }
viewCart(){
  this.router.navigate(['/data-catalog/cart']);
}
}
